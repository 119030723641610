import { Box, styled, Typography } from "@mui/material";

export const StyledCard = styled(Box)(() => ({
  height: "100%",
}));
export const StyledImage = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  paddingTop: 48,
  "@media only screen and (max-width: 1200px)": {
    paddingTop: 0,
    "& svg": {
      paddingTop: 0,
      width: 150,
    },
  },
}));

export const StyledEmptyTypography = styled(Typography)(({ theme }) => ({
  width: 413,
  margin: "auto",
  fontSize: 18,
  fontFamily: "Rubik",
  fontWeight: 500,
  display: "baseline",
  color: theme.palette.neutral.dark1,
  textAlign: "center",
  paddingTop: 36,
  paddingBottom: 34,
  whiteSpace: "pre-wrap",
  "@media only screen and (max-width: 1200px)": {
    fontSize: 14,
    paddingTop: 0,
    paddingBottom: 0,
  },
}));
