import { ListItem, ListItemText, Typography } from '@mui/material';
import { styled as Styled } from '@mui/material/styles';
import { Box } from '@mui/system';

import { ReactComponent as GreenDot } from '../../../assets/images/greenDot.svg';

interface ListItemStyledProp {
	isSelected: boolean;
	isPlan?: boolean;
	isListHovered?: boolean;
}

export const TypographyText = Styled(Typography)(({ theme }) => ({
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 500,
	display: 'baseline',
	color: theme.palette.neutral.light,
	'@media only screen and (max-width: 1700px)': {
		fontSize: 15,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 12,
	},
}));

export const ListItemStyledOpen = Styled(ListItem, {
	shouldForwardProp: (prop) =>
		prop !== 'isSelected' && prop !== 'isPlan' && prop !== 'isListHovered',
})<ListItemStyledProp>(({ theme, isSelected, isPlan, isListHovered }) => ({
	width: isListHovered ? 200 : undefined,
	height: 46,
	padding: '11px 16px 11px 16px',
	borderRadius: 8,
	backgroundColor: isSelected ? theme.palette.neutral.light5 : 'transparent',
	color: isSelected ? theme.palette.primary.dark : theme.palette.neutral.light,
	cursor: isPlan ? 'not-allowed' : 'pointer',
	justifyContent: 'center',
	'&:hover': {
		backgroundColor: theme.palette.neutral.light5,
	},
	'@media only screen and (max-width: 1700px)': {
		width: '170px',
	},
	'@media only screen and (max-width: 1200px)': {
		width: '110px',
	},
	'@media only screen and (max-height: 700px)': {
		marginBottom: 0,
		paddingBlock: 0,
	},
}));

export const ListItemStyled = Styled(ListItem, {
	shouldForwardProp: (prop) => prop !== 'isSelected',
})<ListItemStyledProp>(({ isSelected, theme }) => ({
	width: '200px',
	height: 55,
	marginBottom: 6,
	borderRadius: '8px',
	backgroundColor: isSelected ? theme.palette.primary.light : '',
	color: isSelected ? 'none' : theme.palette.primary.light,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	'@media only screen and (max-width: 1700px)': {
		width: '170px',
	},
	'@media only screen and (max-width: 1200px)': {
		width: '110px',
	},
}));

export const BoxStyled = Styled(Box)(({ theme }) => ({
	position: 'relative',
	height: 40,
	width: 30,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	color: theme.palette.neutral.light,
	'@media only screen and (max-width: 1200px)': {
		height: 20,
		width: 15,
	},
}));

export const ListItemTextOpen = Styled(ListItemText)(() => ({
	textTransform: 'initial',
	marginLeft: 22,
	display: 'block',
	'@media only screen and (max-width: 1700px)': {
		marginLeft: 15,
	},
	'@media only screen and (max-width: 1200px)': {
		marginLeft: 5,
	},
}));

export const ListItemTextClosed = Styled(ListItemText)(() => ({
	display: 'block',
}));

export const BoxSoonStyled = Styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'absolute',
	fontSize: 12,
	fontFamily: 'Rubik',
	fontWeight: 400,
	lineHeight: '16px',
	width: 72,
	height: 24,
	right: 22,
	borderRadius: 30,
	backgroundColor: theme.palette.secondary.main,
	color: theme.palette.neutral.light,
	textTransform: 'none',
	cursor: 'not-allowed',
	'@media only screen and (max-width: 1700px)': {
		width: 60,
		fontSize: 10,
	},
	'@media only screen and (max-width: 1200px)': {
		width: 60,
		height: 22,
		fontSize: 10,
		top: 0,
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		zIndex: 3,
	},
}));

export const StyledOrangeDot = Styled(GreenDot)(({ theme }) => ({
	position: 'absolute',
	alignSelf: 'center',
	width: 10,
	height: 10,
	top: 8,
	right: 3,
	color: theme.palette.secondary.main,
}));
