import { Box, styled, Typography } from '@mui/material';

interface ExpíredPromotion {
	isExpired: boolean;
}

export const StyledPromotionContainer = styled(Box)(() => ({
	display: 'flex',
	position: 'relative',
	width: '220px',
	height: '100%',
	zIndex: 1,
	'@media only screen and (max-width: 1500px)': {
		width: '180px',
	},
	'@media only screen and (max-width: 1200px)': {
		width: '120px',
		height: 124,
	},
}));

export const StyledPromotion = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'isExpired',
})<ExpíredPromotion>(({ isExpired }) => ({
	width: '220px',
	height: '100%',
	borderRadius: 20,
	overflow: 'hidden',
	boxShadow: '4px 6px 15px rgba(0, 0, 0, 0.1)',
	paddingBottom: 1,

	opacity: isExpired ? 0.6 : 1,
	cursor: 'pointer',
	'@media only screen and (max-width: 1500px)': {
		width: '180px',
	},
	'@media only screen and (max-width: 1200px)': {
		width: '120px',
	},
}));

export const StyledPromotionExpired = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'isExpired',
})<ExpíredPromotion>(({ theme, isExpired }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	width: 108,
	height: 36,
	display: isExpired ? 'flex' : 'none',
	zIndex: 1,
	backgroundColor: theme.palette.neutral.dark1,
	borderRadius: '20px 0px',
	color: theme.palette.neutral.light,
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 14,
	justifyContent: 'center',
	alignItems: 'center',
	boxShadow: '10px 5px 5px rgba(0, 0, 0, 0.1)',
	'@media only screen and (max-width: 1200px)': {
		width: 60,
		height: 26,
		fontSize: 8,
	},
}));

export const StyledPromotionImg = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
	height: 148,
	'& img': {
		height: '100%',
		width: '100%',
		objectFit: 'scale-down',
	},
	'@media only screen and (max-width: 1200px)': {
		height: '80px',
	},
}));
export const StyledPromotionTitle = styled(Typography)(({ theme }) => ({
	marginTop: 8,
	fontSize: 14,
	width: 'calc(100% - 12px)',
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.neutral.dark,
	marginLeft: 12,
	display: 'inline-block',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	'@media (max-width: 1500px)': {
		width: '168px',
		fontSize: 10,
	},
}));
export const StyledPromotionValue = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.success.main,
	marginLeft: 12,
	'@media (max-width: 1500px)': {
		fontSize: 10,
	},
}));

export const StyledBestPromotionsTitle = styled(Box)(({ theme }) => ({
	display: 'flex',
	height: 36,
	width: 'calc(100% - 64px)',
	borderBottom: '1px solid',
	borderColor: theme.palette.neutral.light2,
	padding: '0px 32px 0px 32px',
	justifyContent: 'space-between',
}));
