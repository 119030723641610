import { Box, Typography, styled } from '@mui/material';
import { Form } from 'formik';
import { ReactComponent as SideImage } from '../../assets/images/pcThirdRow.svg';

export const Container = styled(Box)(({ theme }) => ({
	flexGrow: 1,
	maxWidth: '100vw',
	height: '100vh',
	overflow: 'hidden',
	'@media (max-witdh: 1300px)': {
		height: 'unset',
	},
	[theme.breakpoints.down('md')]: {
		overflow: 'scroll',
	},
}));

export const StyledTopBar = styled(Box)(({ theme }) => ({
	zIndex: 2,
	top: 0,
	width: '100%',
	display: 'flex',
	maxheight: 132,
	paddingTop: 52,
	paddingBottom: 32,
	justifyContent: 'center',
	backgroundColor: theme.palette.neutral.main2,
	position: 'relative',
}));

export const StyledIconBack = styled(Box)(() => ({
	display: 'flex',
	marginLeft: 48,
	cursor: 'pointer',
	zIndex: 1,
	position: 'absolute',
	top: 30,
	left: -20,
}));

export const StyledTopBarLogo = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
	width: '100%',
	marginRight: 88,
}));

export const FirstGrid = styled(Box)(({ theme }) => ({
	gridColumn: '1 / 4',
	boxSizing: 'border-box',
	gridTemplateColumns: '1.6fr 1.6fr',
	backgroundColor: theme.palette.neutral.main2,
	display: 'grid',
	height: '100%',
	position: 'relative',
	maxWidth: '100vw',
	[theme.breakpoints.down('md')]: {
		display: 'flex',
		height: '100%',
	},
}));

export const FirstGridFirstColumn = styled(Box)(({ theme }) => ({
	display: 'flex',
	backgroundColor: theme.palette.neutral.light,
	alignItems: 'center',
	justifyContent: 'center',
	position: 'relative',
	[theme.breakpoints.down('md')]: {
		display: 'none',
	},
}));

export const StyledBottomElipse = styled(Box)(() => ({
	position: 'absolute',
	bottom: -2,
	left: 0,
}));

export const StyledTopElipse = styled(Box)(() => ({
	overflow: 'hidden',
	position: 'absolute',
	top: 0,
	right: 0,
	zIndex: -1,
}));

export const CenterBox = styled(Box)(() => ({
	height: 550,
	width: 850,
	display: 'block',
	position: 'relative',
	alignItems: 'center',
	justifyContent: 'center',
	'@media only screen and (max-width: 1400px)': {
		width: 700,
		marginLeft: -70,
	},
	'@media only screen and (max-width: 1200px)': {
		width: 600,
		marginLeft: -70,
	},
}));

export const StyledFireEmoji = styled(Box)(() => ({
	position: 'absolute',
	top: 25,
	left: -60,
}));

export const StyledMoneyEmoji = styled(Box)(() => ({
	position: 'absolute',
	top: 346,
	right: -60,
}));

export const FirstGridSecondColumn = styled(Box)(({ theme }) => ({
	display: 'flex',
	position: 'relative',
	zIndex: 1,
	backgroundColor: theme.palette.neutral.main2,
	width: '100%',
	alignItems: 'center',
	justifyContent: 'center',
	[theme.breakpoints.down('md')]: {
		alignItems: 'flex-start',
		padding: '5px 5px 50px 5px',
	},
}));
export const ItemBox = styled(Box)(() => ({
	width: '100%',
	height: '100%',
	display: 'flex',
	alignItems: 'stretch',
}));
export const NotebookSVG = styled(SideImage)(() => ({
	'@media only screen and (max-width: 1400px)': {
		width: 600,
	},
}));

export const StyledSecondColumnTitle = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontSize: 28,
	lineHeight: '36px',
	color: theme.palette.neutral.light,
	fontFamily: 'Rubik',
	fontWeight: 700,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 28,
	},
}));

export const StyledSecondColumnTextBox = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	marginTop: '2%',
}));

export const StyledSecondColumnText = styled(Typography)(({ theme }) => ({
	textAlign: 'center',
	fontSize: 16,
	lineHeight: '26px',
	color: theme.palette.neutral.light,
	fontFamily: 'Rubik',
	fontWeight: 400,
	width: '52%',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 28,
	},
}));

export const StyledForm = styled(Form)(() => ({
	'@media (orientation: portrait)': {
		width: '100%',
	},
}));
