import CloseIcon from '@mui/icons-material/Close';
import {
	Autocomplete,
	Box,
	FormControlLabel,
	InputAdornment,
	Radio,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { ReactComponent as BigImg } from '../../../../assets/images/bigEmptyImg.svg';
import { ReactComponent as TipsIcon } from '../../../../assets/images/tipsIcon.svg';
import {
	IAddItemForm,
	ITag,
} from '../../../../pages/AddItem/Interfaces/interfaces';
import { getItemCategory } from '../../../../utils/api/get/getItemCategory';
import { IMenu } from '../../../../utils/api/interfaces';

import { convertToBase64 } from '../../../../shared/helpers/convertToBase64.helper';
import { moneyMask } from '../../../../utils/mask.util';
import ImageModal from '../../../Promotion/ImageModal/ImageModal';
import { DetailsProps } from '../CreateComplement/interfaces';
import { amountOfPeople } from './constantes';
import {
	ButtonText,
	ChoseButton,
	DeliveryPrice,
	FormControlBox,
	Input,
	ItemDescription,
	ItemName,
	PhotoContainer,
	PhotoItem,
	RadioGroupBox,
	RightSide,
	ScreeenTitles,
	ScreenSubtitle,
	StyledBigEmptyIcon,
	StyledCategoriesTextField,
	StyledFieldCount,
	StyledLabel,
	StyledPreviewImg,
	StyledRemoveImageIcon,
	SubtitlePhoto,
	TextButton,
	TipsButton,
	TitleAndButton,
	WrapperInputs,
	WrapperRadioGroup,
} from './styles';

const Details: React.FC<DetailsProps> = ({ isEditting }): JSX.Element => {
	const {
		values: {
			name,
			description,
			itemImage,
			tag_name,
			portionSize,
			categoryId,
			price,
		},
		setFieldValue,
		handleChange,
		touched,
		errors,
	} = useFormikContext<IAddItemForm>();

	const findNameById = (
		id: string,
		dataArray: Pick<IMenu, 'name' | 'id'>[]
	): void => {
		const person = dataArray.find((item) => item.id === id);
		person ? setSelectedTag({ name: person.name }) : null;
		person ? setFieldValue('categoryId', person?.id) : null;
		person ? setFieldValue('tag_name', person.name) : null;
	};

	const [category, setCategory] = useState<Pick<IMenu, 'name' | 'id'>[]>([]);
	const [toggleModal, setToggleModal] = useState(false);
	const [selectedTag, setSelectedTag] = useState<ITag>({
		name: tag_name || '',
	});

	const [imageState, setImageState] = useState({
		img: itemImage ? itemImage : '',
		path: itemImage ? itemImage : '',
	});

	const handleModalImage = () => {
		setToggleModal(!toggleModal);
	};

	const handleFileChange = async (e: any) => {
		const file = e.target.files[0];
		const base64 = await convertToBase64(file);
		setImageState({
			img: base64 as string,
			path: URL.createObjectURL(e.target.files[0]),
		});
	};

	const handleImagePreview = () => {
		setImageState({
			img: '',
			path: '',
		});
	};

	const getCategoryItem = () => {
		getItemCategory().then((res) => {
			setCategory(res?.data);
		});
	};

	useEffect(() => {
		setFieldValue('itemImage', imageState?.img);
	}, [imageState?.img]);

	useEffect(() => {
		if (!itemImage) {
			setImageState({
				img: '',
				path: '',
			});
		}
	}, [itemImage]);

	useEffect(() => {
		getCategoryItem();
	}, []);

	useEffect(() => {
		setSelectedTag({ name: tag_name || '' });
	}, [tag_name]);

	useEffect(() => {
		findNameById(categoryId, category);
	}, [category, setCategory]);

	return (
		<>
			<ImageModal
				handleClosePromotionImageModal={handleModalImage}
				openPromotionImageModal={toggleModal}
			/>
			<TitleAndButton>
				<Box>
					<ScreeenTitles>
						{!isEditting ? 'Adicionar item' : 'Editando item'}
					</ScreeenTitles>
					<SubtitlePhoto>Crie um novo item para a categoria</SubtitlePhoto>
				</Box>
				<TipsButton onClick={handleModalImage}>
					<TipsIcon />
					<ButtonText>Dicas de foto</ButtonText>
				</TipsButton>
			</TitleAndButton>

			<PhotoContainer>
				{!imageState.path && (
					<PhotoItem>
						<StyledLabel>
							<BigImg />
							<ChoseButton>
								<Input
									type="file"
									name="itemImage"
									onChange={handleFileChange}
									accept="image/*"
									multiple
								/>
								<TextButton>Clique para selecionar a imagem</TextButton>
							</ChoseButton>
						</StyledLabel>
					</PhotoItem>
				)}
				{imageState.path && (
					<>
						<StyledPreviewImg isEmpty={!imageState.path}>
							{!imageState.path && <StyledBigEmptyIcon />}
							{imageState.path && <img src={imageState?.path} />}
							<StyledRemoveImageIcon onClick={handleImagePreview}>
								<CloseIcon sx={{ color: '#747476' }} />
							</StyledRemoveImageIcon>
						</StyledPreviewImg>
					</>
				)}
				<RightSide>
					<WrapperInputs>
						<ItemName
							value={name}
							inputProps={{ maxLength: 80 }}
							onChange={handleChange('name')}
							id="outlined-basic"
							label="Nome do item"
							variant="outlined"
							error={touched.name && Boolean(errors.name)}
							helperText={touched.name && errors.name}
						/>
						<Autocomplete
							value={selectedTag}
							options={category}
							autoHighlight
							getOptionLabel={(option) => option?.name}
							onChange={(e, value) => {
								setFieldValue('categoryId', value?.id || '');
								setFieldValue('tag_name', value?.name || '');
								setSelectedTag({ name: tag_name || '' });
							}}
							includeInputInList
							isOptionEqualToValue={(option, value) =>
								value.name === '' || option.name === value.name
							}
							renderInput={(params) => (
								<StyledCategoriesTextField
									{...params}
									id="outlined-basic"
									label="Categoria"
									variant="outlined"
									error={touched.categoryId && Boolean(errors.categoryId)}
									helperText={touched.categoryId && errors.categoryId}
								/>
							)}
						/>
						<DeliveryPrice
							id="outlined-basic"
							value={moneyMask(price)}
							label="Valor do item"
							placeholder="ex: R$ 987,00"
							variant="outlined"
							onChange={handleChange('price')}
							inputProps={{ maxLength: 15 }}
							error={touched.price && Boolean(errors.price)}
							helperText={touched.price && errors.price}
						/>
					</WrapperInputs>
					<ItemDescription
						value={description}
						inputProps={{ maxLength: 1000 }}
						onChange={handleChange('description')}
						multiline={true}
						placeholder="Descrição"
						error={touched.description && Boolean(errors.description)}
						helperText={touched.description && errors.description}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<StyledFieldCount>
										{`${Number(500) - description.length} caracteres restantes`}
									</StyledFieldCount>
								</InputAdornment>
							),
						}}
					/>
				</RightSide>
			</PhotoContainer>
			<ScreeenTitles>Este item serve quantas pessoas?</ScreeenTitles>
			<ScreenSubtitle>
				Dê mais detalhes para que o cliente possa planejar a refeição
			</ScreenSubtitle>
			<FormControlBox>
				<RadioGroupBox
					row
					aria-labelledby="demo-row-radio-buttons-group-label"
					name="row-radio-buttons-group"
					defaultChecked
					defaultValue={portionSize ? portionSize : amountOfPeople[0].value}
				>
					{amountOfPeople.map((item, key) => (
						<WrapperRadioGroup key={key}>
							<FormControlLabel
								value={item.value}
								control={
									<Radio
										onClick={() => setFieldValue('portionSize', item.value)}
									/>
								}
								label={item.label}
								labelPlacement="bottom"
							/>
						</WrapperRadioGroup>
					))}
				</RadioGroupBox>
			</FormControlBox>
		</>
	);
};

export default Details;
