import { FormControl, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { ReactComponent as BackArrow } from '../../../../assets/images/iconReturn.svg';
import { EmailProps } from '../../../../utils/api/interfaces';
import ContinueButton from '../../../Buttons/ContinueLoginButton/ContinueLoginButton';
import {
	BodyGrid,
	FormBody,
	FormBox,
	FormFooter,
	FormHeader,
	ItemGrid,
	StyledFormSubText,
	StyledFormText,
} from '../styles';
import { FORM_HELPER_STYLE } from './constants/ForgotPassword.constants';
import { ForgotPasswordFormProps } from './interface/forgotPassword.interface';
import { StyledBackArrow, StyledSendButtonContainer } from './styles';
const customTextFieldStyles = () => ({
	width: '100%',
	fontFamily: 'Rubik',

	'& .MuiOutlinedInput-root ': {
		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	[`& fieldset`]: {
		borderRadius: 2,
	},
	[`& label.Mui-focused`]: {
		color: '#6029B1',
	},
});

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (
	props: ForgotPasswordFormProps
): JSX.Element => {
	const {
		values: { email = '' },
		handleChange,
		touched,
		errors,
	} = useFormikContext<EmailProps>();

	return (
		<FormBox>
			<FormHeader>
				<StyledBackArrow onClick={props.handleFormPage}>
					<BackArrow />
				</StyledBackArrow>
			</FormHeader>
			<FormBody>
				<BodyGrid>
					<ItemGrid xs={12}>
						<StyledFormText>Esqueceu sua senha?</StyledFormText>
						<StyledFormSubText>
							Insira o seu email cadastrado. Enviaremos um código para você.
						</StyledFormSubText>
					</ItemGrid>
					<ItemGrid xs={12}>
						<FormControl fullWidth>
							<TextField
								id="outlined-basic"
								label="Email"
								value={email}
								variant="outlined"
								onChange={handleChange('email')}
								error={touched.email && Boolean(errors.email)}
								helperText={touched.email && errors.email}
								FormHelperTextProps={{
									style: FORM_HELPER_STYLE,
								}}
								sx={customTextFieldStyles}
							/>
						</FormControl>
					</ItemGrid>
				</BodyGrid>
			</FormBody>
			<FormFooter>
				<StyledSendButtonContainer>
					<ContinueButton>ENVIAR CÓDIGO</ContinueButton>
				</StyledSendButtonContainer>
			</FormFooter>
		</FormBox>
	);
};

export default ForgotPasswordForm;
