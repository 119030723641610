import React from "react";
import { ReactComponent as IconReturn } from "../../assets/images/iconReturn.svg";
import IconBackInterface from "./interfaces/icon-back.interface";

const IconBack: React.FC<IconBackInterface> = ({ onClick }: IconBackInterface): JSX.Element => {

  const iconClicked = (): void => onClick?.();

  return <IconReturn onClick={iconClicked} />;
};

export default IconBack;
