import { Box, Switch, TableRow } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { ReactComponent as NoImageIcon } from '../../../../assets/images/noImageIcon.svg';
import AutoSave from '../../../../shared/helpers/autoSaveFormik';
import { IItemOptions } from '../../../../utils/api/interfaces';
import { patchOptionItemById } from '../../../../utils/api/patch/patchOptionItemById';
import { moneyMask, unMaskReais } from '../../../../utils/mask.util';
import { SwitchText } from '../../../Forms/AddItem/ComplementaryItems/ComplementaryComponent/styles';
import {
	ImageContent,
	ImageInput,
	StyledValueTextField,
} from '../../ItemsList/Item/styles';
import {
	StyledFirstCell,
	StyledSecondCell,
	StyledThirdCell,
} from '../../styles';
import {
	OptionName,
	PreviewDetails,
	SwitchButton,
	WrapperOptionName,
} from './styles';

interface IMenuListItem {
	handleRefreshData: () => void;
	optionItem: IItemOptions;
}

const customTextFieldStyles = () => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 2,
		height: 48,
		fontFamily: 'Rubik',
		'@media only screen and (max-width: 1400px)': {
			height: 38,
			fontSize: 12,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px',
	},
	'& .MuiOutlinedInput-input': {
		'&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
			WebkitAppearance: 'none',
		},
	},
});

const formHelperStyle = {
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 400,
};

const OptionsItem: React.FC<IMenuListItem> = ({
	optionItem,
}: IMenuListItem): JSX.Element => {
	const [newOptionItem, setNewOptionItem] = useState<IItemOptions>();

	const { enqueueSnackbar } = useSnackbar();

	const { price } = optionItem;

	const handleOptionItemPatch = (
		values: FormikValues,
		setSubmit: (isSubmitting: boolean) => void
	): void => {
		const { priceId, available, itemImage } = values;
		const price = values.price
			? unMaskReais(values.price.original_value)
			: undefined;

		patchOptionItemById(optionItem.id, {
			available,
			priceId,
			price: price,
			itemImage,
		})
			.then((res) => {
				setNewOptionItem(res?.data);
				enqueueSnackbar('Complemento atualizado com sucesso!', {
					variant: 'success',
					autoHideDuration: 1500,
				});
			})
			.finally(() => {
				setSubmit(false);
			});
	};

	return (
		<TableRow>
			<StyledFirstCell component="th" scope="row">
				<Formik
					initialValues={{
						...optionItem,
						available: newOptionItem
							? newOptionItem.available
							: optionItem.available,
					}}
					enableReinitialize
					onSubmit={(values, { setSubmitting }) => {
						handleOptionItemPatch(values, setSubmitting);
					}}
				>
					{({ values }) => (
						<Box display={'flex'} alignItems={'center'}>
							{optionItem.image?.url ? (
								<ImageContent
									width={102}
									height={62}
									sx={{
										'& img': {
											height: '100%',
											width: '100%',
											objectFit: 'scale-down',
										},
									}}
								>
									<img
										src={values.image?.url}
										alt={values.name}
										style={{
											width: '65px',
											height: '65px',
											borderRadius: '8px',
											marginRight: '8px',
										}}
									/>
								</ImageContent>
							) : (
								<>
									<ImageInput>
										<NoImageIcon />
									</ImageInput>
								</>
							)}
							<WrapperOptionName>
								<OptionName>{values.name}</OptionName>
								<PreviewDetails>{values.description}</PreviewDetails>
							</WrapperOptionName>
						</Box>
					)}
				</Formik>
			</StyledFirstCell>
			<StyledSecondCell>
				{price && (
					<Box width={'fit-content'}>
						<Formik
							initialValues={{
								...optionItem,
								priceId: price.id,
								price: price.value.value,
								available: newOptionItem
									? newOptionItem.available
									: optionItem.available,
							}}
							enableReinitialize
							onSubmit={(values, { setSubmitting }) => {
								handleOptionItemPatch(values, setSubmitting);
							}}
						>
							{({ submitForm, values, handleChange, isSubmitting }) => (
								<Form>
									<StyledValueTextField
										id="outlined-basic"
										value={values?.price ? moneyMask(values.price) : ''}
										placeholder="ex: R$ 987,00"
										variant="outlined"
										onChange={(e) => {
											handleChange('price')(e);
										}}
										disabled={isSubmitting}
										size="small"
										inputProps={{ maxLength: 15 }}
										FormHelperTextProps={{
											style: formHelperStyle,
										}}
										sx={customTextFieldStyles}
									/>
									<AutoSave onSubmit={submitForm} />
								</Form>
							)}
						</Formik>
					</Box>
				)}
			</StyledSecondCell>
			<StyledThirdCell>
				<Box width={'fit-content'}>
					<Formik
						initialValues={{
							available: newOptionItem
								? newOptionItem.available
								: optionItem.available,
						}}
						enableReinitialize
						onSubmit={(values, { setSubmitting }) => {
							handleOptionItemPatch(values, setSubmitting);
						}}
					>
						{({
							handleSubmit,
							setFieldValue,
							isSubmitting,
							submitForm,
							values: { available },
						}) => {
							return (
								<Form
									onSubmit={(e) => {
										e.preventDefault();
										handleSubmit();
									}}
								>
									<SwitchButton>
										<SwitchText>
											{available ? 'Ativado' : 'Desativado'}
										</SwitchText>
										<Switch
											checked={available}
											disabled={isSubmitting}
											onChange={() => {
												setFieldValue('available', !available);
											}}
											inputProps={{ 'aria-label': 'status switch' }}
										/>
									</SwitchButton>
									<AutoSave onSubmit={submitForm} />
								</Form>
							);
						}}
					</Formik>
				</Box>
			</StyledThirdCell>
		</TableRow>
	);
};

export default OptionsItem;
