import { FetchItemResponseProps } from '../../Interfaces/interfaces';

export const initialDefaultValues = (newCategoryId: string): any => {
	return {
		name: '',
		description: '',
		hasOptionGroup: false,
		available: true,
		type: '',
		tag_name: '',
		categoryId: newCategoryId ?? '',
		price: 0,
		tag_id: '',
		itemImage: '',
		portionSize: 0,
		optionGroups: [
			{
				name: '',
				description: '',
				minimum: 0,
				maximum: 1,
				optional: true,
				available: true,
				options: [
					{
						name: '',
						description: '',
						available: true,
						optionImage: '',
						optionPrice: 0,
					},
				],
			},
		],
	};
};

export const initialEdittingValues = (
	item: FetchItemResponseProps | undefined
): any => {
	return {
		name: item?.name ?? '',
		description: item?.description ?? '',
		hasOptionGroup: item?.has_option_group ?? false,
		available: item?.available ?? true,
		type: item?.type ?? '',
		tag_name: item?.category_id ?? '',
		categoryId: item?.category_id ?? '',
		price: item?.price.value
			? item?.price.value.value
			: item?.price.original_value ?? 0,
		tag_id: item?.tag_id ?? '',
		itemImage: item?.item_image_url ?? '',
		portionSize: item?.portion_size ?? 0,
		optionGroups: item?.optionGroup
			? item?.optionGroup?.map((optionGroup) => ({
					id: optionGroup.id ?? undefined,
					name: optionGroup?.name ?? '',
					description: optionGroup?.description ?? '',
					minimum: optionGroup?.minimum ?? 0,
					maximum: optionGroup?.maximum ?? 1,
					optional: optionGroup?.minimum > 0 ? false : true ?? false,
					available: optionGroup?.available ?? true,
					options: optionGroup?.options?.map((option) => ({
						id: option.id ?? undefined,
						name: option?.name ?? '',
						description: option?.description ?? '',
						available: option?.available ?? true,
						optionImage: '',
						optionPrice: option?.price ? option?.price.value.value : 0,
					})),
			  }))
			: [
					{
						name: '',
						description: '',
						minimum: 0,
						maximum: 1,
						optional: true,
						available: true,
						options: [
							{
								name: '',
								description: '',
								available: true,
								optionImage: '',
								optionPrice: 0,
							},
						],
					},
			  ],
	};
};
