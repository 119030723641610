import { Chart as ChartJS, registerables } from "chart.js";
import React from "react";
import LineChart from "../../LineChart/LineChart";
import { StatsChart } from "./interface/chart-stats.interface";
import { StyledContainer } from "./styles";
ChartJS.register(...registerables);

const LikesChart: React.FC<StatsChart> = ({
  data,
}: StatsChart): JSX.Element => {
  const labels: string[] = [];

  return (
    <StyledContainer>
      <LineChart data={data} labels={labels} label={"Curtidas"} />
    </StyledContainer>
  );
};

export default LikesChart;
