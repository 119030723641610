import { Box, Button } from '@mui/material';

import { ReactComponent as DoubtIcon } from '../../../assets/images/DoubtIcon.svg';
import { ReactComponent as GoldIconChosenPlan } from '../../../assets/images/GoldIconChosenPlan.svg';
import { ReactComponent as XIcon } from '../../../assets/images/XIcon.svg';
import { IChosenPlan } from '../interfaces';

import {
	AlignCloseButton,
	AlignTypeAndeValue,
	AlignValue,
	ButtonChangePlan,
	ChangePlanText,
	ChargeText,
	ChosenPlanContent,
	CloseButton,
	Container,
	DividerStyled,
	ForwardButton,
	ForwardText,
	InfoContent,
	InfoContentText,
	InfoTitle,
	NameAndValidity,
	NewValue,
	OldValue,
	PlanValue,
	SubscriptionCharge,
	SubscriptionContent,
	SubscriptionHeader,
	SubscriptionInformation,
	SubscriptionText,
	TextValue,
	TitleChosenPlan,
	TypePlan,
	ValidityPlan,
	ValuesAndCharge,
} from './styles';

const ChosenPlan: React.FC<IChosenPlan> = ({
	navigateToPlans,
	navigateToConfirmPlan,
	handleOpen,
}: IChosenPlan): JSX.Element => (
	<Container>
		<AlignCloseButton>
			<CloseButton onClick={navigateToPlans}>
				<XIcon color="#747476" />
			</CloseButton>
		</AlignCloseButton>

		<ChosenPlanContent>
			<TitleChosenPlan>Plano escolhido</TitleChosenPlan>
			<AlignTypeAndeValue>
				<GoldIconChosenPlan />
				<NameAndValidity>
					<TypePlan>Gold</TypePlan>
					<ValidityPlan>Válido por 30 dias</ValidityPlan>
				</NameAndValidity>
				<AlignValue>
					<PlanValue> R$ 149,90</PlanValue>
					<TextValue>/mes</TextValue>
				</AlignValue>
			</AlignTypeAndeValue>
			<DividerStyled />
			<InfoContent>
				<InfoTitle>Informações</InfoTitle>
				<InfoContentText>
					Os créditos adquiridos no plano não são acumulativos
				</InfoContentText>
				<InfoContentText>
					O plano será renovado no dia 20/08/22. Para não ser cobrado cancele
					antes desse prazo.
				</InfoContentText>
				<ButtonChangePlan onClick={handleOpen}>
					<ChangePlanText>Trocar plano</ChangePlanText>
				</ButtonChangePlan>
			</InfoContent>
		</ChosenPlanContent>
		<SubscriptionInformation>
			<SubscriptionContent>
				<SubscriptionHeader>
					<SubscriptionText>Assinatura mensal</SubscriptionText>
					<Button>
						<DoubtIcon />
					</Button>
				</SubscriptionHeader>
				<ValuesAndCharge>
					<Box>
						<OldValue>R$ 279,90</OldValue>
						<NewValue>R$ 149,90</NewValue>
					</Box>
					<SubscriptionCharge>
						<ChargeText>Assinatura recorrente</ChargeText>
					</SubscriptionCharge>
				</ValuesAndCharge>
			</SubscriptionContent>
		</SubscriptionInformation>
		<ForwardButton onClick={navigateToConfirmPlan}>
			<ForwardText>Avançar</ForwardText>
		</ForwardButton>
	</Container>
);

export default ChosenPlan;
