import { Box, Typography, styled } from '@mui/material';
import { ReactComponent as CalendarIcon } from '../../../../assets/images/couponCalendarIcon.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/images/couponCheck.svg';
import { ReactComponent as TagIcon } from '../../../../assets/images/couponTag.svg';

export const StyledCouponListCard = styled(Box)(({ theme }) => ({
	display: 'flex',
	padding: '20px 0px',
	borderBottom: '1px solid',
	borderColor: theme.palette.neutral.light2,
	height: 60,
	'@media only screen and (max-width: 1700px)': {
		height: 42,
	},
	'@media only screen and (max-width: 1200px)': {
		height: 32,
	},
}));

export const StyledImageContainer = styled(Box)(() => ({
	display: 'flex',
}));

export const StyledUserImage = styled(Box)(() => ({
	display: 'flex',
	height: 60,
	width: 60,
	borderRadius: 50,
	overflow: 'hidden',
	zIndex: 1,
	boxShadow: '4px 6px 15px rgba(0, 0, 0, 0.1)',
	pointerEvents: 'none',
	'@media only screen and (max-width: 1700px)': {
		height: 42,
		width: 42,
	},
	'@media only screen and (max-width: 1200px)': {
		height: 32,
		width: 32,
	},
}));

export const StyledPromotionImage = styled(Box)(({ theme }) => ({
	display: 'flex',
	height: 60,
	width: 60,
	borderRadius: 50,
	overflow: 'hidden',
	marginLeft: -23,
	boxShadow: '4px 6px 15px rgba(0, 0, 0, 0.1)',
	pointerEvents: 'none',
	'&:before': {
		content: "'‎'",
		position: 'absolute',
		backgroundColor: theme.palette.neutral.light,
		display: 'block',
		height: 60,
		width: 28,
		borderRadius: '0 50px 50px 0',
	},
	'@media only screen and (max-width: 1700px)': {
		height: 42,
		width: 42,

		'&:before': {
			height: 42,
			width: 18,
		},
	},
	'@media only screen and (max-width: 1200px)': {
		height: 32,
		width: 32,

		'&:before': {
			height: 32,
			width: 18,
		},
	},
}));

export const StyledCouponInformationContainer = styled(Box)(() => ({
	display: 'block',
	marginLeft: '20px',
	marginTop: 4,
	minWidth: 370,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	'@media only screen and (max-width: 1700px)': {
		minWidth: 210,
		maxWidth: 210,
		marginTop: 2,
	},
	'@media only screen and (max-width: 1200px)': {
		minWidth: 180,
		maxWidth: 180,
	},
}));

export const StyledPromotionTitle = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	marginBottom: 5,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	width: 330,
	textOverflow: 'ellipsis',
	'@media only screen and (max-width: 1700px)': {
		marginBottom: 2,
	},
}));
export const StyledPromotionTitleTypography = styled(Typography)(
	({ theme }) => ({
		fontFamily: 'Rubik',
		fontWeight: 500,
		fontSize: 20,
		lineHeight: '24px',
		color: theme.palette.neutral.dark,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'@media only screen and (max-width: 1700px)': {
			fontSize: 14,
			lineHeight: '20px',
		},
		'@media only screen and (max-width: 1200px)': {
			fontSize: 12,
			lineHeight: '14px',
		},
	})
);

export const StyledUsername = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 16,
	lineHeight: '20px',
	color: theme.palette.neutral.dark1,
	maxWidth: 330,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	'@media only screen and (max-width: 1700px)': {
		fontSize: 10,
		lineHeight: '20px',
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 10,
		lineHeight: '14px',
	},
}));

export const StyledInformationContainer = styled(Box)(() => ({
	display: 'flex',
	marginLeft: '16px',
	marginRight: '60px',
	'@media only screen and (max-width: 1700px)': {
		marginRight: 20,
		marginLeft: 20,
	},
	'@media only screen and (max-width: 1300px)': {
		marginRight: 10,
		marginLeft: 10,
	},
}));

export const StyledIconContainer = styled(Box)(({ theme }) => ({
	width: 52,
	height: 52,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginLeft: '20px',
	border: '1px solid',
	borderColor: theme.palette.primary.contrastText,
	borderRadius: 15,
	'@media only screen and (max-width: 1700px)': {
		width: 42,
		height: 42,
		marginLeft: 0,
	},
	'@media only screen and (max-width: 1200px)': {
		width: 32,
		height: 32,
		borderRadius: 10,
	},
}));

export const StyledCalendarIcon = styled(CalendarIcon)(() => ({
	maxWidth: 40,
	maxHeight: 40,
	color: '#C5C5C5',
	'@media only screen and (max-width: 1700px)': {
		maxWidth: 20,
		maxHeight: 20,
	},
	'@media only screen and (max-width: 1200px)': {
		maxWidth: 18,
		maxHeight: 18,
	},
}));
export const StyledCheckIcon = styled(CheckIcon)(() => ({
	maxWidth: 40,
	maxHeight: 40,
	'@media only screen and (max-width: 1700px)': {
		maxWidth: 20,
		maxHeight: 20,
	},
	'@media only screen and (max-width: 1200px)': {
		maxWidth: 18,
		maxHeight: 18,
	},
}));
export const StyledTagIcon = styled(TagIcon)(() => ({
	maxWidth: 40,
	maxHeight: 40,
	'@media only screen and (max-width: 1700px)': {
		maxWidth: 20,
		maxHeight: 20,
	},
	'@media only screen and (max-width: 1200px)': {
		maxWidth: 18,
		maxHeight: 18,
	},
}));

export const StyledInformationContainerText = styled(Box)(() => ({
	display: 'block',
	marginLeft: '20px',
	marginTop: 4,
	'@media only screen and (max-width: 1700px)': {
		marginTop: 2,
		marginLeft: 15,
	},
}));

export const StyledInformationTitle = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 16,
	lineHeight: '20px',
	color: theme.palette.neutral.dark,
	marginBottom: 4,
	whiteSpace: 'nowrap',
	'@media only screen and (max-width: 1700px)': {
		fontSize: 12,
		lineHeight: '20px',
		marginBottom: 2,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 10,
		lineHeight: '14px',
		marginBottom: 2,
	},
}));

export const StyledInformationText = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	fontFamily: 'Rubik',
	fontWeight: 400,
	fontSize: 18,
	lineHeight: '24px',
	color: theme.palette.neutral.dark1,
	whiteSpace: 'nowrap',
	'@media only screen and (max-width: 1700px)': {
		fontSize: 14,
		lineHeight: '20px',
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 12,
		lineHeight: '14px',
	},
}));

export const StyledUseCouponButtonContainer = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
	justifyContent: 'end',
}));

export const StyledUseCouponButton = styled(Box)(({ theme }) => ({
	display: 'flex',
	width: 266,
	height: 56,
	border: '1px solid',
	borderColor: theme.palette.primary.contrastText,
	alignItems: 'center',
	justifyContent: 'center',
	fontFamily: 'Rubik',
	fontWeight: 700,
	fontSize: 16,
	lineHeight: '20px',
	color: theme.palette.primary.main,
	borderRadius: 6,
	cursor: 'pointer',
	'@media only screen and (max-width: 1700px)': {
		height: 42,
		width: 166,
		fontSize: 14,
		lineHeight: '16px',
	},
	'@media only screen and (max-width: 1200px)': {
		height: 32,
		width: 166,
		fontSize: 12,
		lineHeight: '16px',
	},
}));
