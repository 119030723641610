import { Box, Dialog, IconButton, styled } from "@mui/material";

export const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
    padding: "20px 0px 48px 0px",
    width: "100%",
    maxWidth: "1000px",
    "@media (max-height: 850px)": {
      maxWidth: "750px",
      maxHeight: "500px",
    },

    "& .MuiDialogContent-root": {
      display: "flex",
    },

    "& .MuiDialogActions-root": {
      display: "flex",
    },
  },
}));
export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 8,
  top: 8,
  color: theme.palette.neutral.dark1,
}));

export const StyledPagination = styled(Box)(() => ({
  display: "flex",
  marginTop: 52,
  "@media (max-height: 850px)": {
    marginTop: 30,
  },
}));

export const StyledCommentsModal = styled(Box)(() => ({
  display: "block",
  width: "100%",
  height: "100%",
  minHeight: 600,
  "@media (max-width: 1400px)": {
    minHeight: 200,
    width: "100%",
  },
}));
