import { AxiosResponse } from "axios";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";
import { CreateAdvertiserProps } from "../interfaces";

export const createAdvertiser = async ({
  advertiser,
}: CreateAdvertiserProps): Promise<AxiosResponse<any> | undefined> => {
  try {
    return await request({
      url: `${BASE_URL}/clients`,
      method: "post",
      data: advertiser,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
