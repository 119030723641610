import { TOGGLE_SWITCH } from '../constants/toggle-config.constant';
import { IToggle, IToggleAction } from '../interface/toggle.interface';

const initialState: IToggle = {
	menu: true,
	menuId: '',
	item: true,
	itemId: '',
};

const toggleReducer = (
	state: IToggle = initialState,
	action: IToggleAction
): IToggle => {
	switch (action.type) {
		case TOGGLE_SWITCH: {
			return {
				...state,
				...action.payload,
			};
		}

		default:
			return state;
	}
};

export default toggleReducer;
