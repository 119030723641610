import { AxiosResponse } from "axios";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";

export const makeLogin = async (
  email: string,
  password: string,
  device: string
): Promise<AxiosResponse<any>> => {
  try {
    const response = await request({
      url: `${BASE_URL}/login/`,
      data: { email, password, device },
      method: "POST",
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
