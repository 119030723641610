import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { ModalConfirmLogoutProps } from './interfaces/modal-confirm-logout-props.interface';
import {
	StyledButtonsContainer,
	StyledCancelButton,
	StyledDialog,
	StyledDisableButton,
	StyledIconBox,
	StyledIconButton,
	StyledLogoutIcon,
	StyledModalText,
	StyledModalTitle,
} from './styles';

const ConfirmResetFormModal: React.FC<ModalConfirmLogoutProps> = ({
	close,
	confirm,
}: ModalConfirmLogoutProps): JSX.Element => {
	return (
		<>
			<StyledDialog open={true} onClose={close} maxWidth="sm">
				<StyledIconButton onClick={close}>
					<CloseIcon />
				</StyledIconButton>
				<StyledIconBox>
					<StyledLogoutIcon />
				</StyledIconBox>
				<StyledModalTitle>Você deseja limpar os campos?</StyledModalTitle>
				<StyledModalText>
					Todos os campos desta tela serão limpos e você precisará preenchê-los
					outra vez.
				</StyledModalText>
				<StyledButtonsContainer>
					<StyledCancelButton onClick={close}>Cancelar</StyledCancelButton>
					<StyledDisableButton onClick={confirm}>Limpar</StyledDisableButton>
				</StyledButtonsContainer>
			</StyledDialog>
		</>
	);
};

export default ConfirmResetFormModal;
