import React from 'react';
import { useNavigate } from 'react-router-dom';
import NavButton from '../../Buttons/NavButton/NavButton';
import { StyledButtonBox } from '../Introduction/styles';
import {
	StyledContainer,
	StyledText,
	StyledTextContainer,
	StyledThirdGridImg,
	StyledThirdGridImgCellphone,
	StyledTitleText,
	SvgContainer,
} from './styles';

const ThirdAnnouncement: React.FC = (): JSX.Element => {
	const navigate = useNavigate();
	return (
		<StyledContainer>
			<SvgContainer>
				<StyledThirdGridImg />
				<StyledThirdGridImgCellphone />
			</SvgContainer>
			<StyledTextContainer>
				<StyledTitleText>
					Gerencie todos os seus pedidos através do nosso aplicativo!
				</StyledTitleText>
				<StyledText>
					Facilite sua gestão com o gestor de pedidos, exclusivamente pensado
					para sua loja!
				</StyledText>
				<StyledButtonBox onClick={() => navigate('/sign-up')}>
					<NavButton isPurple={true}>Cadastrar loja</NavButton>
				</StyledButtonBox>
			</StyledTextContainer>
		</StyledContainer>
	);
};

export default ThirdAnnouncement;
