import { Box, Button, styled, Typography } from "@mui/material";

export const ContainerModal = styled(Box)(({ theme }) => ({
  width: "800px",
  height: "500px",
  borderRadius: "20px",
  top: "50%",
  left: "50%",
  position: "absolute",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.common.white,
}));

export const AlignButton = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "flex-end",
}));

export const AlignCheckIcon = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "50px",
}));

export const AlignConcludeButton = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
}));

export const AlignText = styled(Box)(() => ({
  display: "flex",
  width: "55%",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "40px",
  marginLeft: "184px",
}));

export const ButtonClose = styled(Button)(() => ({
  width: "5px",
  height: "5px",
  marginTop: "18px",
}));

export const ConcludeButton = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "432px",
  height: "56px",
  borderRadius: "8px",
  marginTop: "50px",
  backgroundColor: theme.palette.primary.main,
}));

export const ConcludeText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 700,
  color: theme.palette.common.white,
  textTransform: "none",
}));

export const TextComplete = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fontFamily: "Rubik",
  fontWeight: 500,
  textAlign: "center",
  color: theme.palette.common.black,
}));

export const SubtitleComplete = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontFamily: "Rubik",
  fontWeight: 500,
  marginTop: "12px",
  textAlign: "center",
  color: theme.palette.neutral.dark1,
}));
