import { Box, Button, styled, Typography } from "@mui/material";

export const CloseModal = styled(Button)(() => ({
  width: "50px",
  height: "80px",
}));

export const ContainerModal = styled(Box)(({ theme }) => ({
  width: "1172px",
  height: "584px",
  borderRadius: "20px",
  top: "50%",
  left: "50%",
  padding: "0px 0px 0px 44px",
  position: "absolute",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.common.white,
}));

export const ModalSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontFamily: "Rubik",
  fontWeight: 500,
  marginTop: "12px",
  color: theme.palette.neutral.dark1,
}));

export const ModalTitle = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fontFamily: "Rubik",
  marginTop: "44px",
  fontWeight: 500,
  color: theme.palette.common.black,
}));

export const TitleAndIcon = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));
