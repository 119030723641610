import { Switch } from '@mui/material';
import {
	FieldArray,
	FieldArrayRenderProps,
	getIn,
	useFormikContext,
} from 'formik';
import React, { useEffect, useState } from 'react';
import { moneyMask } from '../../../../utils/mask.util';
import {
	StyledDelivery,
	StyledDeliveryContainer,
	StyledNameTextField,
	StyledNameTextFieldContainer,
	StyledSwitchContainer,
	StyledTimeTextField,
	StyledTimeTextFieldContainer,
	StyledValueTextField,
	StyledValueTextFieldContainer,
} from './styles';

export interface DataProps {
	districtId: string;
	name: string;
	price: number;
	deliveryTime: string;
	deliveryPermission: boolean;
}

export interface DataPropsSend {
	id?: string;
	advertiserId?: string;
	districtId: string;
	name: string;
	price: string;
	deliveryTime: string;
	deliveryPermission: boolean;
}

export interface DeliveryProps {
	data: DataProps[];
}

interface DeliveryFormProps {
	index: number;
	handleChange: {
		(e: React.ChangeEvent<any>): void;
		<T = string | React.ChangeEvent<any>>(
			field: T
		): T extends React.ChangeEvent<any>
			? void
			: (e: string | React.ChangeEvent<any>) => void;
	};
	delivery: DataProps;
	districtId: string;
	name: string;
	price: string;
	deliveryTime: string;
	deliveryPermission: string;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
	deliveryArrayHelpers: FieldArrayRenderProps;
}

const formHelperStyle = {
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	lineHeight: '20px',
};

const DeliveryForm: React.FC<DeliveryFormProps> = ({
	handleChange,
	delivery,
	deliveryPermission,
	deliveryTime,
	name,
	price,
	setFieldValue,
	deliveryArrayHelpers,
}: DeliveryFormProps): JSX.Element => {
	const [checked, setChecked] = useState(delivery.deliveryPermission);

	const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		setChecked(event.target.checked);

	const touchedDeliveryTime = getIn(
		deliveryArrayHelpers.form.touched,
		deliveryTime
	);
	const errorDeliveryTime = getIn(
		deliveryArrayHelpers.form.errors,
		deliveryTime
	);

	useEffect(() => {
		setFieldValue(deliveryPermission, checked);
	}, [checked, setChecked]);
	return (
		<StyledDeliveryContainer>
			<StyledDelivery>
				<StyledNameTextFieldContainer>
					<StyledNameTextField
						id="outlined-basic"
						type="text"
						name={name}
						value={delivery.name}
						variant="standard"
						InputProps={{
							disableUnderline: true,
							readOnly: true,
						}}
						inputProps={{ style: formHelperStyle }}
					/>
				</StyledNameTextFieldContainer>
				<StyledValueTextFieldContainer>
					<StyledValueTextField
						id="outlined-basic"
						name={price}
						value={moneyMask(delivery.price)}
						placeholder="R$ 0,00"
						variant="outlined"
						onChange={handleChange}
					/>
				</StyledValueTextFieldContainer>
				<StyledTimeTextFieldContainer>
					<StyledTimeTextField
						id="outlined-basic"
						name={deliveryTime}
						value={delivery.deliveryTime}
						placeholder="0 minutos"
						variant="outlined"
						onChange={handleChange}
						error={Boolean(touchedDeliveryTime && errorDeliveryTime)}
					/>
				</StyledTimeTextFieldContainer>
				<StyledSwitchContainer>
					<Switch
						checked={checked}
						onChange={handleSwitchChange}
						size="small"
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</StyledSwitchContainer>
			</StyledDelivery>
		</StyledDeliveryContainer>
	);
};

const DistrictList: React.FC = (): JSX.Element => {
	const {
		values: { data },
		handleChange,
		errors,
		setFieldValue,
		isValid,
	} = useFormikContext<DeliveryProps>();

	useEffect(() => {
		if (errors.data?.length) {
			alert('Verifique o formulário por erros');
		}
	}, [isValid]);

	return (
		<FieldArray name="data">
			{(deliveryArrayHelpers) => (
				<React.Fragment>
					{data?.map((delivery, index) => {
						const districtId = `data[${index}].districtId`;
						const name = `data[${index}].name`;

						const price = `data[${index}].price`;

						const deliveryTime = `data[${index}].deliveryTime`;
						const deliveryPermission = `data[${index}].deliveryPermission`;

						return (
							<DeliveryForm
								handleChange={handleChange}
								key={index}
								delivery={delivery}
								districtId={districtId}
								name={name}
								price={price}
								deliveryTime={deliveryTime}
								deliveryPermission={deliveryPermission}
								setFieldValue={setFieldValue}
								index={index}
								deliveryArrayHelpers={deliveryArrayHelpers}
							/>
						);
					})}
				</React.Fragment>
			)}
		</FieldArray>
	);
};

export default DistrictList;
