import { ReactComponent as BiggerGoldIcon } from "../../../assets/images/BiggerGoldIcon.svg";
import { ReactComponent as BiggerPlatinumIcon } from "../../../assets/images/BiggerPlatinumIcon.svg";
import { ReactComponent as BiggerSilverIcon } from "../../../assets/images/BiggerSilverIcon.svg";

export const PlansProps = [
  {
    id: 0,
    iconPlan: <BiggerSilverIcon />,
    typePlan: "Silver",
    oldPrice: "139,90",
    newPrice: "69,90",
    benefits: [
      {
        id: 0,
        benefitText: "5 promoções limitadas até 3 dias;",
      },
      {
        id: 1,
        benefitText: "Acesso a todos os dados estatísticos;",
      },
      {
        id: 2,
        benefitText: "Limitar promoção com cupons;",
      },
      {
        id: 3,
        benefitText: "Uma promoção em destaque na aba da categoria do produto;",
      },
    ],
  },
  {
    id: 1,
    iconPlan: <BiggerGoldIcon />,
    typePlan: "Gold",
    oldPrice: "249,90",
    newPrice: "149,90",
    benefits: [
      {
        id: 0,
        benefitText: "15 promoções limitadas até 5 dias;",
      },
      {
        id: 1,
        benefitText: "Acesso a todos os dados estatísticos;",
      },
      {
        id: 2,
        benefitText: "Limitar promoção com cupons;",
      },
      {
        id: 3,
        benefitText: "Programar início da promoção;",
      },
      {
        id: 4,
        benefitText: "3 promoções em destaque na aba da categoria do produto;",
      },
    ],
  },
  {
    id: 2,
    iconPlan: <BiggerPlatinumIcon />,
    typePlan: "Platinum",
    oldPrice: "379,90",
    newPrice: "299,90",
    benefits: [
      {
        id: 0,
        benefitText: "30 promoções limitadas até 7 dias;",
      },
      {
        id: 1,
        benefitText: "Acesso a todos os dados estatísticos;",
      },
      {
        id: 2,
        benefitText: "Limitar promoção com cupons;",
      },
      {
        id: 3,
        benefitText: "Programar início da promoção;",
      },
      {
        id: 4,
        benefitText: "5 promoções em destaque na aba da categoria do produto;",
      },
      {
        id: 5,
        benefitText: "Uma promoção em destaque na tela inicial;",
      },
      {
        id: 6,
        benefitText: "Loja em destaque na tela inicial;",
      },
    ],
  },
];
