import { AxiosResponse } from "axios";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";

export const patchProfileHeader = async (
  image_header: string
): Promise<AxiosResponse<any>> => {
  try {
    const response = await request({
      url: `${BASE_URL}/clients/header`,
      data: { image_header },
      method: "PATCH",
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
