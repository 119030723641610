import { Box, Skeleton, styled, Typography } from "@mui/material";

export const StyledAllCouponsContainer = styled(Box)(() => ({}));

export const StyledCouponList = styled(Box)(() => ({
  display: "block",
  flexWrap: "wrap",
  minHeight: 510,
  gap: 16,
  marginTop: 20,
  "& > :last-of-type": {
    borderBottom: 0,
  },
  "@media only screen and (max-width: 1200px)": {
    minHeight: 364,
  },
}));

export const StyledEmptyList = styled(Box)(() => ({
  boxSizing: "border-box",
  marginTop: 113,
}));

export const StyledPagination = styled(Box)(() => ({
  display: "flex",
  marginTop: 42,
  minHeight: 42,
  "@media only screen and (max-width: 1700px)": {
    marginTop: 42,
  },
}));

export const StyledCouponListCard = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "20px 0px",
  borderBottom: "1px solid",
  borderColor: theme.palette.neutral.light2,
  height: 60,
  "@media only screen and (max-width: 1700px)": {
    height: 42,
  },
  "@media only screen and (max-width: 1200px)": {
    height: 32,
  },
}));

export const StyledImageContainer = styled(Box)(() => ({
  display: "flex",
}));

export const StyledUserImage = styled(Box)(() => ({
  display: "flex",
  height: 60,
  width: 60,
  borderRadius: 50,
  overflow: "hidden",
  zIndex: 1,
  boxShadow: "4px 6px 15px rgba(0, 0, 0, 0.1)",
  "@media only screen and (max-width: 1700px)": {
    height: 42,
    width: 42,
    boxShadow: "none",
  },
  "@media only screen and (max-width: 1200px)": {
    height: 32,
    width: 32,
  },
}));

export const StyledPromotionImage = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 60,
  width: 60,
  borderRadius: 50,
  overflow: "hidden",
  marginLeft: -23,
  boxShadow: "4px 6px 15px rgba(0, 0, 0, 0.1)",
  "&:before": {
    content: "'‎'",
    position: "absolute",
    backgroundColor: theme.palette.neutral.light,
    display: "block",
    height: 60,
    width: 28,
    borderRadius: "0 50px 50px 0",
  },
  "@media only screen and (max-width: 1700px)": {
    height: 42,
    width: 42,
    boxShadow: "none",
  },
  "@media only screen and (max-width: 1200px)": {
    height: 32,
    width: 32,
    boxShadow: "none",
    "&:before": {
      content: "'‎'",
      position: "absolute",
      backgroundColor: theme.palette.neutral.light,
      display: "block",
      height: 32,
      width: 28,
      borderRadius: "0 50px 50px 0",
    },
  },
}));

export const StyledCouponInformationContainer = styled(Box)(() => ({
  display: "block",
  marginLeft: "20px",
  marginTop: 4,
  minWidth: 370,
  overflow: "hidden",
  textOverflow: "ellipsis",
  "@media only screen and (max-width: 1700px)": {
    minWidth: 220,
    maxWidth: 220,
  },
  "@media only screen and (max-width: 1200px)": {
    minWidth: 180,
    maxWidth: 180,
    marginTop: 2,
  },
}));

export const StyledPromotionTitle = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginBottom: 5,
  whiteSpace: "nowrap",
  overflow: "hidden",
  width: 350,
  textOverflow: "ellipsis",
  "@media only screen and (max-width: 1700px)": {
    marginBottom: 2,
  },
  "@media only screen and (max-width: 1200px)": {
    width: 180,
    marginBottom: 3,
  },
}));
export const StyledPromotionTitleTypography = styled(Typography)(
  ({ theme }) => ({
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "24px",
    color: theme.palette.neutral.dark,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media only screen and (max-width: 1700px)": {
      fontSize: 14,
      lineHeight: "20px",
    },
    "@media only screen and (max-width: 1200px)": {
      fontSize: 12,
      lineHeight: "14px",
    },
  })
);

export const StyledUsername = styled(Box)(({ theme }) => ({
  fontFamily: "Rubik",
  fontWeight: 500,
  fontSize: 16,
  lineHeight: "20px",
  color: theme.palette.neutral.dark1,
  maxWidth: 350,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "@media only screen and (max-width: 1700px)": {
    fontSize: 10,
    lineHeight: "20px",
  },
  "@media only screen and (max-width: 1200px)": {
    fontSize: 10,
    lineHeight: "14px",
  },
}));

export const StyledInformationContainer = styled(Box)(() => ({
  display: "flex",
  marginLeft: "16px",
  marginRight: "60px",
  "@media only screen and (max-width: 1700px)": {
    marginRight: 10,
    marginLeft: 10,
  },
  "@media only screen and (max-width: 1300px)": {
    marginRight: 10,
    marginLeft: 10,
  },
}));

export const StyledIconContainer = styled(Box)(() => ({
  width: 52,
  height: 52,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: "20px",
  overflow: "hidden",
  borderRadius: 15,
  "@media only screen and (max-width: 1700px)": {
    width: 42,
    height: 42,
    marginLeft: 0,
  },
  "@media only screen and (max-width: 1200px)": {
    width: 32,
    height: 32,
    borderRadius: 10,
  },
}));

export const StyledInformationContainerText = styled(Box)(() => ({
  display: "block",
  marginLeft: "20px",
  marginTop: 4,
  "@media only screen and (max-width: 1200px)": {
    marginTop: 2,
  },
}));

export const StyledInformationTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontFamily: "Rubik",
  fontWeight: 500,
  fontSize: 16,
  lineHeight: "20px",
  color: theme.palette.neutral.dark,
  marginBottom: 4,
  whiteSpace: "nowrap",
  height: 20,
  width: 151,
  borderRadius: 5,
  overflow: "hidden",
  "@media only screen and (max-width: 1700px)": {
    width: 100,
    height: 16,
    borderRadius: 5,
  },
  "@media only screen and (max-width: 1200px)": {
    height: 14,
    marginBottom: 2,
  },
}));

export const StyledInformationText = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontFamily: "Rubik",
  fontWeight: 400,
  fontSize: 18,
  lineHeight: "24px",
  color: theme.palette.neutral.dark1,
  whiteSpace: "nowrap",
  height: 24,
  width: 151,
  borderRadius: 5,
  overflow: "hidden",
  "@media only screen and (max-width: 1700px)": {
    width: 100,
    height: 16,
    borderRadius: 5,
  },
  "@media only screen and (max-width: 1200px)": {
    height: 14,
  },
}));

export const StyledUseCouponButtonContainer = styled(Box)(() => ({
  display: "flex",
  width: 341,
  justifyContent: "end",
  height: 60,
  "@media only screen and (max-width: 1700px)": {
    height: 42,
    width: "100%",
    marginLeft: 10,
  },
}));

export const StyledUseCouponButton = styled(Box)(() => ({
  display: "flex",
  width: 265,
  height: 56,
  borderRadius: 6,
  "@media only screen and (max-width: 1700px)": {
    height: 42,
    width: 166,
    fontSize: 14,
    lineHeight: "16px",
  },
  "@media only screen and (max-width: 1200px)": {
    height: 32,
  },
}));

export const StyledSkeletonTitle = styled(Skeleton)(() => ({
  height: 16,
  width: 320,
  borderRadius: 5,
  "@media only screen and (max-width: 1700px)": {
    width: 200,
  },
  "@media only screen and (max-width: 1200px)": {
    height: 14,
  },
}));

export const StyledSkeletonUsername = styled(Skeleton)(() => ({
  height: 16,
  width: 200,
  borderRadius: 5,
  "@media only screen and (max-width: 1700px)": {
    width: 150,
  },
  "@media only screen and (max-width: 1200px)": {
    height: 12,
  },
}));
