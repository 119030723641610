import { AxiosError } from "axios";
import { FormikValues } from "formik";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router";
import { createNewCategory } from "../../utils/api/post/createNewCategory";
import NewCategorySecoundStep from "./NewCategoryFirstStep/NewCategorySecoundStep";

const NewCategory: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const hadleNavigateToMenu = (): void => {
    navigate("/menu");
  };

  const handleCreateNewCategory = (
    values: FormikValues,
    setSubmitting: (isSubmitting: boolean) => void
  ): void => {
    const { name } = values;
    createNewCategory(name)
      .then(() => {
        setSubmitting(false);
        hadleNavigateToMenu?.();
        enqueueSnackbar("Categoria criada com sucesso", {
          variant: "success",
          autoHideDuration: 1500,
        });
      })
      .catch((err: AxiosError) => {
        if (err) {
          setSubmitting(false);
          enqueueSnackbar("Nome da categoria invalido!", {
            variant: "error",
            autoHideDuration: 1500,
          });
        }
      });
  };

  return (
    <NewCategorySecoundStep
      hadleNavigateToMenu={hadleNavigateToMenu}
      handleCreateNewCategory={handleCreateNewCategory}
    />
  );
};

export default NewCategory;
