import { AxiosResponse } from "axios";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";

export const patchMenuById = async (
  menuId: string,
  available?: boolean,
  name?: string
): Promise<AxiosResponse<any> | undefined> => {
  try {
    return request({
      url: `${BASE_URL}/showcaseCategories/${menuId}`,
      method: "patch",
      data: { available, name },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
