import { Box, Typography, styled } from '@mui/material';

export const Container = styled(Box)(() => ({
	width: '100%',
}));

export const TableHeader = styled(Box)(() => ({
	display: ' flex',
	width: '100%',
	marginTop: '5%',
	padding: '0px 0px 10px 0px',
}));

export const AlignQuantity = styled(Box)(() => ({
	display: ' flex',
	width: '24%',
	alignItems: 'center',
	justifyContent: ' center',
}));

export const AlignValues = styled(Box)(() => ({
	display: ' flex',
	width: '18%',
	alignItems: 'center',
	justifyContent: ' center',
}));

export const AlignTextItem = styled(Box)(() => ({
	display: ' flex',
	width: '40%',
	alignItems: 'center',
}));

export const TitleText = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.common.black,
}));

export const TextContent = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
}));

export const ContentTable = styled(Box)(() => ({
	width: '100%',
}));

export const BoxItem = styled(Box)(() => ({
	display: ' flex',
	width: '100%',
	paddingTop: '10px',
}));

export const TextComplement = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark2,
}));

export const BoxComplement = styled(Box)(() => ({
	display: ' flex',
	width: '100%',
	padding: '5px 0px 5px 0px',
}));
