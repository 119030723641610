import { AxiosError } from 'axios';
import { Formik, FormikValues } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { ReactComponent as BottomEllipse } from '../../assets/images/bottomEllipse.svg';
import { ReactComponent as TopEllipse } from '../../assets/images/topEllipse.svg';
import { addAuth } from '../../auth/actions/auth.action';
import { AuthAction, IAuth } from '../../auth/interface/auth.interface';
import ChangePasswordForm from '../../components/Forms/Login/ChangePasswordForm/ChangePasswordForm';
import ForgotPasswordForm from '../../components/Forms/Login/ForgotPasswordForm/ForgotPasswordForm';
import { ForgotPasswordInitialValues } from '../../components/Forms/Login/ForgotPasswordForm/constants/ForgotPasswordInitialValues';
import validationForgotPasswordSchema from '../../components/Forms/Login/ForgotPasswordForm/constants/validationForgotPasswordSchema';
import InsertTokenForm from '../../components/Forms/Login/InsertTokenForm/InsertTokenForm';
import LoginForm from '../../components/Forms/Login/LoginForm/LoginForm';
import VALIDATION_LOGIN_SCHEMA from '../../components/Forms/Login/LoginForm/constants/login-validationSchema';
import { getAdvertiser } from '../../utils/api/get/getAdvertiser';
import { createNewPassword } from '../../utils/api/post/createNewPassword';
import { forgotPassword } from '../../utils/api/post/forgotPassword';
import { makeLogin } from '../../utils/api/post/makeLogin';
import { tokenConfirmation } from '../../utils/api/post/tokenConfirmation';
import SideImage from './SideImage/SideImage';
import { FORM_STEP } from './enum/formStep.enum';

import IconBack from '../../components/IconBack/IconBack';
import {
	CenterBox,
	Container,
	FirstGrid,
	FirstGridFirstColumn,
	FirstGridSecondColumn,
	StyledBottomElipse,
	StyledIconBack,
	StyledSecondColumnText,
	StyledSecondColumnTextBox,
	StyledSecondColumnTitle,
	StyledTopElipse,
} from './styles';

const Login: React.FC = (): JSX.Element => {
	const [formPage, setFormPage] = useState(0);
	const [tokenCode, setTokenCode] = useState('');
	const [userEmail, setUserEmail] = useState('');
	const [isTokenCodeValid, setIsTokenCodeValid] = useState(true);

	const [email, setEmail] = useState('');
	const [rememberMe, setRememberMe] = useState(false);

	const handleRememberMeChange = () => {
		setRememberMe(!rememberMe);
	};

	const navigate = useNavigate();

	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const setAuth = (auth: IAuth): AuthAction => dispatch(addAuth(auth));

	const backPage = (): void => {
		navigate('/');
	};

	const handleNextFormPage = (): void =>
		setFormPage((prevState) => prevState + 1);

	const handlePreviousFormPage = (): void =>
		setFormPage((prevState) => prevState - 1);

	const handleTokenChange = (tokenCode: string): void =>
		setTokenCode(tokenCode);

	const handleLogin = (
		values: FormikValues,
		setSubmitting: (isSubmitting: boolean) => void
	): void => {
		const { email, password } = values;
		const device = 'desktop';
		makeLogin(email, password, device)
			.then(async (res) => {
				const { accessToken, advertiser_id, refreshToken } = res.data;

				setAuth({
					accessToken,
					advertiser_id,
					refresh_token: refreshToken,
				});

				const response = await getAdvertiser(advertiser_id);
				if (response) {
					const { description, address, advertiserOpeningHours, profile } =
						response.data;
					const validateAdvertiser =
						!description || !address || !advertiserOpeningHours.length;

					setAuth({
						accessToken,
						advertiser_id,
						refresh_token: refreshToken,
						advertiserComplete: !validateAdvertiser,
						advertiserImg: profile.avatar,
						advertiserName: profile.name,
					});
					localStorage.setItem('refresh_token', refreshToken);
				}
				if (advertiser_id === null) {
					throw new Error();
				}
				setSubmitting(false);
				if (rememberMe) {
					localStorage.setItem('savedEmail', email);
				} else {
					localStorage.removeItem('savedEmail');
				}
			})
			.catch((err: AxiosError) => {
				if (err) {
					setSubmitting(false);
					enqueueSnackbar('E-mail ou senha inválidos!', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	const handleForgotPassword = (
		values: FormikValues,
		setSubmitting: (isSubmitting: boolean) => void
	): void => {
		const { email } = values;
		setUserEmail(email);
		forgotPassword(email)
			.then(() => {
				handleNextFormPage();
			})
			.catch((err: AxiosError) => {
				if (err) {
					setSubmitting(false);
					enqueueSnackbar('Email inválido!', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};
	const handleInsertCode = (
		values: FormikValues,
		setSubmitting: (isSubmitting: boolean) => void
	): void => {
		const { tokenCode } = values;
		const token = tokenCode;
		tokenConfirmation(token)
			.then(() => {
				setIsTokenCodeValid(true);
				handleNextFormPage();
			})
			.catch((err: AxiosError) => {
				if (err) {
					setSubmitting(false);
					setIsTokenCodeValid(false);
					enqueueSnackbar('Token inválido!', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};
	const handleNewPassword = (
		values: FormikValues,
		setSubmitting: (isSubmitting: boolean) => void
	): void => {
		const { token, password, password_confirm } = values;
		createNewPassword(token, password, password_confirm)
			.then(() => {
				setFormPage(0);
				enqueueSnackbar('Senha alterada com sucesso!.', {
					variant: 'success',
					autoHideDuration: 1500,
				});
			})
			.catch((err: AxiosError) => {
				if (err) {
					setSubmitting(false);
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	useEffect(() => {
		let isMounted = true;

		const savedEmail = localStorage.getItem('savedEmail');
		if (savedEmail && isMounted) {
			setEmail(savedEmail);
			setRememberMe(true);
		}

		return () => {
			isMounted = false;
		};
	}, []);

	return (
		<Container>
			<FirstGrid>
				<FirstGridFirstColumn>
					{formPage === FORM_STEP.FORM_LOGIN_DATA && (
						<StyledIconBack>
							<IconBack onClick={backPage} />
						</StyledIconBack>
					)}
					{formPage === FORM_STEP.FORM_LOGIN_DATA && (
						<Formik
							initialValues={{
								email: email ? email : '',
								password: '',
							}}
							validationSchema={VALIDATION_LOGIN_SCHEMA}
							enableReinitialize
							onSubmit={(values, { setSubmitting }) => {
								handleLogin(values, setSubmitting);
							}}
						>
							{({ handleSubmit }) => (
								<form
									style={{ width: '100%' }}
									onSubmit={(e) => {
										e.preventDefault();
										handleSubmit();
									}}
								>
									<LoginForm
										handleFormPage={handleNextFormPage}
										rememberMe={rememberMe}
										handleRememberMeChange={handleRememberMeChange}
									/>
								</form>
							)}
						</Formik>
					)}
					{formPage === FORM_STEP.FORM_FORGOT_PASSWORD_DATA && (
						<Formik
							initialValues={ForgotPasswordInitialValues}
							validationSchema={validationForgotPasswordSchema}
							onSubmit={(values, { setSubmitting }) => {
								handleForgotPassword(values, setSubmitting);
							}}
						>
							{({ handleSubmit }) => (
								<form
									style={{ width: '100%' }}
									onSubmit={(e) => {
										e.preventDefault();
										handleSubmit();
									}}
								>
									<ForgotPasswordForm handleFormPage={handlePreviousFormPage} />
								</form>
							)}
						</Formik>
					)}
					{formPage === FORM_STEP.FORM_INPUT_TOKEN_DATA && (
						<Formik
							initialValues={{ tokenCode: '' }}
							onSubmit={(values, { setSubmitting }) => {
								values = { tokenCode };
								handleInsertCode(values, setSubmitting);
							}}
						>
							{({ handleSubmit }) => (
								<form
									onSubmit={(e) => {
										e.preventDefault();
										handleSubmit();
									}}
									style={{ width: '100%' }}
								>
									<InsertTokenForm
										handleTokenChange={handleTokenChange}
										tokenCode={tokenCode}
										handleFormPage={handlePreviousFormPage}
										userEmail={userEmail}
										forgotPassword={forgotPassword}
										isTokenCodeValid={isTokenCodeValid}
									/>
								</form>
							)}
						</Formik>
					)}
					{formPage === FORM_STEP.FORM_CHANGE_PASSWORD_DATA && (
						<Formik
							initialValues={{
								password: '',
								password_confirm: '',
								token: tokenCode,
							}}
							onSubmit={(values, { setSubmitting }) => {
								handleNewPassword(values, setSubmitting);
							}}
						>
							{({ handleSubmit }) => (
								<form
									onSubmit={(e) => {
										e.preventDefault();
										handleSubmit();
									}}
								>
									<ChangePasswordForm handleFormPage={handlePreviousFormPage} />
								</form>
							)}
						</Formik>
					)}
				</FirstGridFirstColumn>
				<FirstGridSecondColumn>
					<StyledTopElipse>
						<TopEllipse />
					</StyledTopElipse>
					<StyledBottomElipse>
						<BottomEllipse />
					</StyledBottomElipse>
					<CenterBox>
						<StyledSecondColumnTitle>
							Tudo em um único lugar
						</StyledSecondColumnTitle>
						<StyledSecondColumnTextBox>
							<StyledSecondColumnText>
								Acesse seu painel personalizado, gerencie produtos, receba
								pedidos, crie promoções e acompanhe estatísticas.
							</StyledSecondColumnText>
						</StyledSecondColumnTextBox>
						<SideImage />
					</CenterBox>
				</FirstGridSecondColumn>
			</FirstGrid>
		</Container>
	);
};

export default Login;
