import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getCategoriesIcons } from '../../../utils/getCategoriesIcons/getCategoriesIcons';
import { PromotionFieldsProps } from './interface/promotionFields-props.interface';
import {
	BigSkeleton,
	DiscountPercent,
	MediumSkeleton,
	NewValue,
	OldValue,
	OldValueContainer,
	StyledActivePromotion,
	StyledExpiredPromotion,
	StyledPromotionBottomContainer,
	StyledPromotionDate,
	StyledPromotionDescription,
	StyledPromotionDescriptionTitle,
	StyledPromotionPriceContainer,
	StyledPromotionTag,
	StyledPromotionTagIcon,
	StyledPromotionText,
	StyledPromotionTitle,
	StyledPromotionTitleContainer,
	StyledPromotionValuesContainer,
	StyledPurpleDot,
	SyledPromotionImage,
	Value,
} from './styles';

const PromotionFieldsSkeleton: React.FC = (): JSX.Element => {
	return (
		<>
			<SyledPromotionImage>
				<BigSkeleton animation="wave" variant="rectangular" />
			</SyledPromotionImage>
			<StyledPromotionValuesContainer>
				<StyledPromotionTitle>
					<BigSkeleton animation="wave" variant="rectangular" />
				</StyledPromotionTitle>
				<StyledPromotionText>
					<MediumSkeleton animation="wave" variant="rectangular" />
				</StyledPromotionText>
				<StyledPromotionDescription>
					<BigSkeleton animation="wave" variant="rectangular" />
				</StyledPromotionDescription>
				<StyledPromotionDescription>
					<BigSkeleton animation="wave" variant="rectangular" />
				</StyledPromotionDescription>
				<StyledPromotionDescription>
					<BigSkeleton animation="wave" variant="rectangular" />
				</StyledPromotionDescription>
			</StyledPromotionValuesContainer>
		</>
	);
};

const PromotionFields: React.FC<PromotionFieldsProps> = ({
	promotion,
	loader,
}: PromotionFieldsProps): JSX.Element => {
	const [isBig, setIsBig] = useState(false);

	const amountOfDiscount = promotion
		? (
				((Number(promotion.initial_price) -
					Number(promotion.promotional_price)) /
					Number(promotion.initial_price)) *
				100
		  ).toFixed()
		: '';

	useEffect(() => {
		if (promotion) {
			promotion.description.length > 300 ? setIsBig(true) : setIsBig(false);
		}
	}, [promotion]);

	return (
		<>
			{loader ? (
				<PromotionFieldsSkeleton />
			) : (
				<>
					<SyledPromotionImage>
						{promotion?.is_expired ? (
							<StyledExpiredPromotion>Desativada</StyledExpiredPromotion>
						) : (
							<StyledActivePromotion>Ativo</StyledActivePromotion>
						)}
						<img src={promotion?.image.url} />
					</SyledPromotionImage>
					<StyledPromotionValuesContainer>
						<StyledPromotionTitleContainer>
							<StyledPromotionTitle>{promotion?.title}</StyledPromotionTitle>
							<StyledPromotionTag>
								<StyledPurpleDot />
								<StyledPromotionTagIcon>
									{promotion
										? getCategoriesIcons(promotion.tag.name, 24, 20)
										: ''}
								</StyledPromotionTagIcon>
								{promotion?.tag.name}
							</StyledPromotionTag>
						</StyledPromotionTitleContainer>
						<StyledPromotionDate>
							Criado em{' '}
							{moment(promotion?.promotion_initial_date).format('LLL')}
						</StyledPromotionDate>
						<StyledPromotionBottomContainer>
							<StyledPromotionPriceContainer>
								<OldValueContainer>
									<OldValue>
										{promotion
											? new Intl.NumberFormat('pt-BR', {
													style: 'currency',
													currency: 'BRL',
											  }).format(Number(promotion.initial_price))
											: ''}
									</OldValue>
									<DiscountPercent>{amountOfDiscount}%</DiscountPercent>
								</OldValueContainer>
								<NewValue>
									<Value>
										{promotion
											? new Intl.NumberFormat('pt-BR', {
													style: 'currency',
													currency: 'BRL',
											  }).format(Number(promotion.promotional_price))
											: ''}
									</Value>
								</NewValue>
							</StyledPromotionPriceContainer>
						</StyledPromotionBottomContainer>
						<StyledPromotionDescriptionTitle>
							Descrição
						</StyledPromotionDescriptionTitle>
						<StyledPromotionDescription isBig={isBig}>
							{promotion?.description}
						</StyledPromotionDescription>
					</StyledPromotionValuesContainer>
				</>
			)}
		</>
	);
};

export default PromotionFields;
