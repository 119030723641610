import { Box, styled } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import { ReactComponent as FacebookIcon } from "../../../assets/images/facebookIcon.svg";
import { ReactComponent as WhatsAppIcon } from "../../../assets/images/whatsappIcon.svg";

import { ReactComponent as InstagramIcon } from "../../../assets/images/instagramIcon.svg";
export const StyledContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  "@media (orientation: portrait)": {
    display: "block",
  },
}));

export const SixthGridFirstColumn = styled(Box)(() => ({
  display: "block",
  "@media (orientation: portrait)": {
    display: "none",
  },
}));

export const StyledSixthGridText = styled(Box)(({ theme }) => ({
  fontFamily: "Rubik",
  fontWeight: 400,
  fontSize: 16,
  color: theme.palette.neutral.light,
  marginBottom: 24,
  marginTop: 24,
  cursor: "pointer",
  "@media (orientation: portrait)": {
    textAlign: "center",
  },
}));

export const StyledSixthGridHashLink = styled(HashLink)(({ theme }) => ({
  fontFamily: "Rubik",
  fontWeight: 400,
  fontSize: 16,
  color: theme.palette.neutral.light,
  textDecoration: "none",
}));

export const StyledBottomLogo = styled(Box)(() => ({
  marginBottom: 44,
}));

export const SixthGridSecondColumn = styled(Box)(() => ({
  display: "block",
}));

export const StyledSixthGridTitle = styled(Box)(({ theme }) => ({
  fontFamily: "Rubik",
  fontWeight: 500,
  fontSize: 18,
  color: theme.palette.neutral.light,
  marginBottom: 56,
  "@media (orientation: portrait)": {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
    marginBottom: 35,
  },
}));

export const SixthGridThirdColumn = styled(Box)(() => ({
  display: "block",
}));

export const SixthGridFourthColumn = styled(Box)(() => ({
  display: "block",
  width: 100,
  "@media (orientation: portrait)": {
    width: "100%",
  },
}));

export const StyledSocialsContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: 100,
  "@media (orientation: portrait)": {
    width: "100%",
    justifyContent: "center",
  },
}));

export const StyledSocialsIcons = styled(Box)(({ theme }) => ({
  color: theme.palette.neutral.light,
  cursor: "pointer",
}));

export const StyledFacebookIcon = styled(FacebookIcon)(() => ({}));

export const StyledWhatsappIcon = styled(WhatsAppIcon)(() => ({
  "@media (orientation: portrait)": {
    marginLeft: 15,
  },
}));

export const StyledInstagramIcon = styled(InstagramIcon)(() => ({
  "@media (orientation: portrait)": {
    marginLeft: 15,
  },
}));
