import { Box, styled } from '@mui/material';

export const StyledDashboard = styled(Box)(() => ({
	display: 'flex',
	height: '100vh',
	'@media only screen and (max-width: 1880px)': {
		height: 'unset',
	},
	marginLeft: 80,
}));
