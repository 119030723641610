import { ButtonsInterface } from './interface/button.interface';
import { StyledButton, StyledTypography } from './styles';

const NavButton: React.FC<ButtonsInterface> = ({
	children,
	isPurple,
}: ButtonsInterface): JSX.Element => {
	return (
		<StyledButton type="submit" variant="contained" isPurple={isPurple}>
			<StyledTypography variant="body1" isPurple={isPurple}>
				{children}
			</StyledTypography>
		</StyledButton>
	);
};

export default NavButton;
