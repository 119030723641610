import { Box, Button, styled, Typography } from "@mui/material";
import { IButtonsProps } from "../interfaces";

export const ButtonsContainer = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  height: "100px",
  alignItems: "self-end",
}));

export const MyPlan = styled(Button)<IButtonsProps>(
  ({ theme, buttonselected }) => ({
    width: "274px",
    height: "60px",
    marginLeft: "44px",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    backgroundColor: !buttonselected
      ? theme.palette.neutral.light
      : theme.palette.neutral.dark3,
  })
);

export const TextMyPlan = styled(Typography)<IButtonsProps>(
  ({ theme, buttonselected }) => ({
    fontSize: "20px",
    fontFamily: "Rubik",
    fontWeight: 500,
    color: !buttonselected
      ? theme.palette.primary.main
      : theme.palette.neutral.dark1,
    textTransform: "none",
  })
);
export const TextAllPlans = styled(Typography)<IButtonsProps>(
  ({ theme, buttonselected }) => ({
    fontSize: "20px",
    fontFamily: "Rubik",
    fontWeight: 500,
    color: buttonselected
      ? theme.palette.primary.main
      : theme.palette.neutral.dark1,
    textTransform: "none",
  })
);

export const AllPlans = styled(Button)<IButtonsProps>(
  ({ theme, buttonselected }) => ({
    width: "274px",
    height: "60px",
    marginLeft: "32px",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    backgroundColor: buttonselected
      ? theme.palette.neutral.light
      : theme.palette.neutral.dark3,
  })
);
