import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ModalItemDeleteProps } from '../../../interfaces';
import {
	StyledButtonsContainer,
	StyledCancelButton,
	StyledDialog,
	StyledDisableButton,
	StyledIconButton,
	StyledLogoutIcon,
	StyledModalText,
	StyledModalTitle,
} from './styles';

const ItemDeleteModal: React.FC<ModalItemDeleteProps> = ({
	close,
	confirm,
	type,
}: ModalItemDeleteProps): JSX.Element => {
	const renderTextModal = (): string => {
		switch (type) {
			case 'categoria':
				return 'Ao confirmar, todos os itens e complementos cadastrados nessa categoria serão excluídos automaticamente.';

			case 'item':
				return ' Ao confirmar, esse item será excluído e não poderá ser recuperado.';

			case 'grupo de complementos':
				return 'Ao confirmar, todos os complementos cadastrados neste grupo serão excluídos permanentemente.';

			default:
				return '';
		}
	};

	return (
		<>
			<StyledDialog open={true} onClose={close} maxWidth="sm">
				<StyledIconButton onClick={close}>
					<CloseIcon />
				</StyledIconButton>
				<StyledLogoutIcon />
				<StyledModalTitle>Excluir {type} ?</StyledModalTitle>
				<StyledModalText>{renderTextModal()}</StyledModalText>
				<StyledButtonsContainer>
					<StyledCancelButton onClick={close}>Cancelar</StyledCancelButton>
					<StyledDisableButton onClick={confirm}>Confirmar</StyledDisableButton>
				</StyledButtonsContainer>
			</StyledDialog>
		</>
	);
};

export default ItemDeleteModal;
