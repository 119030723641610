import React from "react";
import { IStatisticsCard } from "../interface/card-interface";
import {
  StyledNumber,
  StyledNumberContainer,
  StyledNumberSkeleton,
  StyledPeriod,
  StyledViewText,
} from "./styles";

const CouponsCard: React.FC<IStatisticsCard> = ({
  itens,
  index,
  indexButton,
}: IStatisticsCard): JSX.Element => {
  return (
    <>
      {itens.loader ? (
        <StyledNumber isSelected={index === indexButton}>
          <StyledNumberSkeleton animation="wave" />
        </StyledNumber>
      ) : itens.coupons ? (
        <StyledNumberContainer>
          <StyledNumber isSelected={index === indexButton}>
            {itens.coupons}
          </StyledNumber>
          <StyledPeriod isSelected={index === indexButton}>
            Total de cupons utilizados
          </StyledPeriod>
        </StyledNumberContainer>
      ) : (
        <StyledViewText isSelected={index === indexButton}>
          Sem estatísticas disponíveis
        </StyledViewText>
      )}
    </>
  );
};

export default CouponsCard;
