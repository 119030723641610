import React from 'react';

import { StyledContainer, StyledLogo, StyledText } from './styles';

const Footer: React.FC = (): JSX.Element => (
	<StyledContainer>
		<StyledLogo />
		<StyledText>
			CNPJ 37.373.547/0001-42 Dub I.T. Serviços Tecnológicos LTDA
		</StyledText>
		<StyledText>
			Powered by dubit - Copyright 2022 Dub I. T. - Todos os direitos reservados
		</StyledText>
	</StyledContainer>
);

export default Footer;
