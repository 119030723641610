import { Box, styled, Typography } from '@mui/material';

export const StyledDashboard = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
}));

export const StyledDashboardContainer = styled(Box)(({ theme }) => ({
	display: 'block',
	backgroundColor: theme.palette.neutral.light2,
	width: '100%',
}));

export const StyledDashboardTopBar = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
}));

export const StyledDashboardContent = styled(Box)(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns:
		'minmax(100px, 420px) minmax(250px, 880px) minmax(100px, 500px)',
	gridTemplateRows: '1fr 1fr',
	gap: 32,
	height: 'calc(100% - 216px)',
	padding: '39px 40px 52px 44px',
	backgroundColor: theme.palette.neutral.light2,
	'@media (max-width: 1400px)': {
		height: '80%',
	},
	'@media (max-height: 625px)': {
		gridTemplateRows: '250px 250px',
	},
	'@media (max-width: 1200px)': {
		gridTemplateRows: '250px 250px',
		height: '84%',
		padding: '24px 20px 32px 24px',
		gap: 22,
	},
}));

export const StyledContentCards = styled(Box)(() => ({
	gridRow: 1,
	gridColumn: 1,
	display: 'inline-grid',
	height: '100%',
	width: '100%',
	marginBottom: '-12%',
}));

export const StyledDashboardChart = styled(Box)(() => ({
	gridRow: 1,
	gridColumn: 2,
	display: 'flex',
	height: '100%%',
	width: '100%',
	borderRadius: 20,
	'& canvas': {
		height: '270px!important',
		width: '100%!important',
	},
	'@media only screen and (max-width: 1400px)': {
		'& canvas': {
			height: '160px!important',
		},
	},
}));

export const StyledRightContainer = styled(Box)(() => ({
	gridRow: 1,
	gridColumn: 3,
	display: 'block',
	zIndex: 0,
}));

export const StyledCardflat = styled(Box)(({ theme }) => ({
	height: 296,
	position: 'relative',
	backgroundColor: theme.palette.neutral.light,
	borderRadius: 20,
	width: '100%',
	'@media only screen and (max-width: 1400px)': {
		height: 159,
	},
}));

export const StyledStoreRatingsTitle = styled(Typography)(({ theme }) => ({
	marginBottom: 28,
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 500,
	display: 'baseline',
	color: theme.palette.primary.dark,
	textAlign: 'center',
}));

export const StyledStoreRatingsNumber = styled(Typography)(({ theme }) => ({
	fontSize: 32,
	fontFamily: 'Rubik',
	fontWeight: 500,
	display: 'baseline',
	color: theme.palette.neutral.dark,
	textAlign: 'center',
	marginBottom: 10,
}));

export const StyledStoreRatingsStars = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
	marginBottom: 34,
}));

export const StyledStoreRatingsGoogle = styled(Typography)(({ theme }) => ({
	width: '100%',
	fontSize: 12,
	fontFamily: 'Rubik',
	fontWeight: 400,
	display: 'baseline',
	color: theme.palette.neutral.dark1,
	textAlign: 'center',
	borderTop: '1px solid',
	borderColor: theme.palette.neutral.light2,
	paddingTop: 10,
}));

export const StyledBottomRow = styled(Box)(() => ({
	gridRow: 2,
	gridColumn: '1 / 4',
	display: 'grid',
	gap: 32,
	height: '100%',
	gridTemplateColumns: 'minmax(100px, 772px) minmax(100px, 930px)',
	'@media (max-width: 1880px)': {
		gridTemplateColumns: 'minmax(100px, 600px) minmax(100px, 930px)',
	},
	'@media (max-width: 1200px)': {
		gap: 22,
	},
}));
export const StyledBestPromotions = styled(Box)(({ theme }) => ({
	gridColumn: 1,
	width: '100%',
	backgroundColor: theme.palette.neutral.light,
	padding: '24px 0px 44px 0px',
	borderRadius: 24,
	height: '79%',
}));

export const StyledLastComments = styled(Box)(({ theme }) => ({
	gridColumn: 2,
	backgroundColor: theme.palette.neutral.light,
	padding: '24px 0px 44px 0px',
	borderRadius: 24,
	height: '79%',
}));
