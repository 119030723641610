import { Box, styled, Typography } from "@mui/material";

export const StyledTitleContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: 28,
  "@media only screen and (max-width: 1400px)": {
    padding: "0px 12px 0px 12px",
    marginBottom: 40,
  },
}));

export const StyledChartTitle = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontFamily: "Rubik",
  fontWeight: 600,
  display: "baseline",
  color: theme.palette.primary.dark,
  "@media only screen and (max-width: 1400px)": {
    fontSize: 14,
  },
}));

export const StyledChartTitleTime = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontFamily: "Rubik",
  fontWeight: 400,
  display: "baseline",
  color: theme.palette.neutral.dark1,
  "@media only screen and (max-width: 1400px)": {
    fontSize: 12,
  },
}));
