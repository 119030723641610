import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import {
	Box,
	FormControl,
	InputAdornment,
	MenuItem,
	Switch,
} from '@mui/material';
import {
	ErrorMessage,
	FieldArray,
	FieldArrayRenderProps,
	getIn,
	useFormikContext,
} from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { ContactFormikProps } from '../../../../utils/api/interfaces';
import { celularMask, telefoneMask } from '../../../../utils/mask.util';
import SaveButton from '../../../Buttons/SaveButton/SaveButton';
import {
	StyledTextContainer,
	TypographyText,
	TypographyTitle,
} from '../AddressForm/styles';
import ConfirmResetFormModal from '../ConfirmResetFormModal/ConfirmResetFormModal';
import { INITIAL_VALUES_PROFILE_INFO_FORM } from './constants/initial-values-form.constant';
import {
	ContactFormProps,
	ContactFormSocialsProps,
} from './interface/form-socials.interface';
import { IProfileSocials } from './interface/socials.interface';
import {
	ContactFormContainer,
	StyledAddNewSocial,
	StyledAddNewSocialText,
	StyledButton,
	StyledButtonsContainer,
	StyledErrorMessage,
	StyledPhoneContainer,
	StyledResetFormButton,
	StyledSocialMedia,
	StyledSocialMediaContainer,
	StyledSocialsLinkTextField,
	StyledSocialsTextField,
	StyledTextField,
} from './styles';

type TSocialIcon = Partial<{
	startAdornment: JSX.Element;
	endAdornment: JSX.Element;
}>;

const ContactFormSocials: React.FC<ContactFormSocialsProps> = ({
	handleChange,
	social,
	social_media,
	link,
	setFieldValue,
	is_active,
	socialArrayHelpers,
	profileSocials,
}: ContactFormSocialsProps): JSX.Element => {
	const [checked, setChecked] = useState(social.is_active);
	const [mask, setMask] = useState('');

	const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
		setChecked(event.target.checked);

	const touchedLink = getIn(socialArrayHelpers.form.touched, link);
	const errorLink = getIn(socialArrayHelpers.form.errors, link);
	const touchedSocialMedia = getIn(
		socialArrayHelpers.form.touched,
		social_media
	);
	const errorsSocialMedia = getIn(socialArrayHelpers.form.errors, social_media);

	const socialIconAdornment = (): TSocialIcon => {
		if (social.social_media === '0') {
			return {
				startAdornment: (
					<InputAdornment position="start">
						<AlternateEmailIcon />
					</InputAdornment>
				),
				endAdornment: (
					<InputAdornment position="end">
						<Switch
							checked={checked}
							onChange={handleSwitchChange}
							size="small"
							inputProps={{ 'aria-label': 'controlled' }}
						/>
					</InputAdornment>
				),
			};
		} else {
			return {
				endAdornment: (
					<InputAdornment position="end">
						<Switch
							checked={checked}
							onChange={handleSwitchChange}
							size="small"
							inputProps={{ 'aria-label': 'controlled' }}
						/>
					</InputAdornment>
				),
			};
		}
	};

	const replaceMask = (): void => {
		let socialMask = '';

		switch (social.social_media) {
			case '0': {
				socialMask = social.link.replace(/\//g, '');
				break;
			}
			case '1': {
				socialMask = social.link;
				break;
			}
			case '2': {
				socialMask = celularMask(social.link);
				break;
			}
			case '3': {
				socialMask = social.link;
				break;
			}
			default:
				break;
		}

		setMask(socialMask);
	};

	const maxLengthSocial = (): Record<string, unknown> => {
		if (social.social_media === '2') {
			return { maxLength: 15 };
		} else {
			return {};
		}
	};

	useEffect(() => {
		replaceMask();
	}, [social, social.social_media, setMask, mask]);

	useEffect(() => {
		setFieldValue(is_active, checked);
	}, [checked, setChecked]);

	return (
		<StyledSocialMediaContainer>
			<StyledSocialMedia>
				<StyledSocialsTextField
					select
					id="outlined-basic"
					type="text"
					name={social_media}
					value={social.social_media}
					label="Rede Social"
					variant="outlined"
					onChange={handleChange}
					helperText={
						touchedSocialMedia && errorsSocialMedia ? touchedSocialMedia : ''
					}
					error={Boolean(touchedSocialMedia && errorsSocialMedia)}
					FormHelperTextProps={{
						style: formHelperStyle,
					}}
					SelectProps={{
						MenuProps: {
							sx: { maxHeight: '20%' },
						},
					}}
				>
					{profileSocials.map((socials: IProfileSocials) => (
						<MenuItem key={socials.id} value={socials.id}>
							{socials.social}
						</MenuItem>
					))}
				</StyledSocialsTextField>
				<StyledErrorMessage>
					<ErrorMessage name={social_media} />
				</StyledErrorMessage>
			</StyledSocialMedia>
			<StyledSocialMedia>
				<StyledSocialsLinkTextField
					id="outlined-basic"
					type="text"
					name={link}
					value={mask}
					onChange={handleChange}
					placeholder="Cole o link aqui"
					variant="outlined"
					FormHelperTextProps={{
						style: formHelperStyle,
					}}
					disabled={!social.social_media}
					inputProps={maxLengthSocial()}
					InputLabelProps={{ shrink: false }}
					helperText={touchedLink && errorLink ? touchedLink : ''}
					error={Boolean(touchedLink && errorLink)}
					InputProps={socialIconAdornment()}
				/>
				<StyledErrorMessage>
					<ErrorMessage name={link} />
				</StyledErrorMessage>
			</StyledSocialMedia>
		</StyledSocialMediaContainer>
	);
};

const formHelperStyle = {
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 400,
};

const ContactForm: React.FC<ContactFormProps> = ({
	profileSocials,
}: ContactFormProps): JSX.Element => {
	const [showModalConfirmResetForm, setShowModalConfirmResetForm] =
		useState(false);
	const {
		values: {
			advertiserContacts: { phone, cellphone, socialMedias },
		},
		handleChange,
		touched,
		errors,
		setFieldValue,
		resetForm,
	} = useFormikContext<ContactFormikProps>();

	const openModalConfirmResetForm = (): void => {
		setShowModalConfirmResetForm(true);
	};

	const closeModalConfirmResetForm = (): void => {
		setShowModalConfirmResetForm(false);
	};

	const handleResetForm = (): void => {
		resetForm({ values: INITIAL_VALUES_PROFILE_INFO_FORM });
		closeModalConfirmResetForm();
	};

	const handleNewSocialMedia = useCallback(
		(socialArrayHelpers: FieldArrayRenderProps): void => {
			if (socialMedias && socialMedias.length <= 2) {
				socialArrayHelpers.push({
					social_media: '',
					link: '',
					is_active: true,
				});
			}
		},
		[socialMedias]
	);

	return (
		<ContactFormContainer>
			<StyledTextContainer>
				<TypographyTitle>Meios de contato</TypographyTitle>
				<TypographyText>
					Os contatos ficarão disponíveis no aplicativo
				</TypographyText>
			</StyledTextContainer>
			<StyledPhoneContainer>
				<FormControl style={{ marginRight: 36 }}>
					<StyledTextField
						id="outlined-basic"
						value={celularMask(cellphone)}
						label={'Celular'}
						variant="outlined"
						onChange={handleChange('advertiserContacts.cellphone')}
						error={
							touched.advertiserContacts?.cellphone &&
							Boolean(errors.advertiserContacts?.cellphone)
						}
						helperText={
							touched.advertiserContacts?.cellphone &&
							errors.advertiserContacts?.cellphone
						}
						inputProps={{ maxLength: 15 }}
						FormHelperTextProps={{
							style: formHelperStyle,
						}}
					/>
				</FormControl>

				<FormControl>
					<StyledTextField
						id="outlined-basic"
						value={telefoneMask(phone)}
						label={'Telefone'}
						variant="outlined"
						onChange={handleChange('advertiserContacts.phone')}
						error={
							touched.advertiserContacts?.phone &&
							Boolean(errors.advertiserContacts?.phone)
						}
						helperText={
							touched.advertiserContacts?.phone &&
							errors.advertiserContacts?.phone
						}
						inputProps={{ maxLength: 15 }}
						FormHelperTextProps={{
							style: formHelperStyle,
						}}
					/>
				</FormControl>
			</StyledPhoneContainer>

			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<FieldArray name="advertiserContacts.socialMedias">
					{(socialArrayHelpers) => (
						<React.Fragment>
							<Box sx={{ display: 'flex', flexDirection: 'row' }}>
								{socialMedias?.slice(0, 2).map((social, index) => (
									<ContactFormSocials
										key={index}
										handleChange={handleChange}
										social={social}
										social_media={`advertiserContacts.socialMedias[${index}].social_media`}
										link={`advertiserContacts.socialMedias[${index}].link`}
										setFieldValue={setFieldValue}
										is_active={`advertiserContacts.socialMedias[${index}].is_active`}
										socialArrayHelpers={socialArrayHelpers}
										index={index}
										profileSocials={profileSocials}
									/>
								))}
							</Box>

							{socialMedias &&
								socialMedias.length > 2 &&
								socialMedias.slice(2).map(
									(
										social,
										index // Provide a fallback empty array
									) => (
										<Box key={index + 2} sx={{ marginTop: 2 }}>
											<ContactFormSocials
												handleChange={handleChange}
												social={social}
												social_media={`advertiserContacts.socialMedias[${
													index + 2
												}].social_media`}
												link={`advertiserContacts.socialMedias[${
													index + 2
												}].link`}
												setFieldValue={setFieldValue}
												is_active={`advertiserContacts.socialMedias[${
													index + 2
												}].is_active`}
												socialArrayHelpers={socialArrayHelpers}
												index={index + 2}
												profileSocials={profileSocials}
											/>
										</Box>
									)
								)}

							<StyledAddNewSocial
								cursor={(socialMedias && socialMedias.length <= 2) as boolean}
								onClick={() => handleNewSocialMedia(socialArrayHelpers)}
							>
								+&nbsp;
								<StyledAddNewSocialText>
									Adicionar nova rede social
								</StyledAddNewSocialText>
							</StyledAddNewSocial>
						</React.Fragment>
					)}
				</FieldArray>
			</Box>

			<StyledButtonsContainer>
				<StyledResetFormButton onClick={openModalConfirmResetForm}>
					Limpar
				</StyledResetFormButton>
				<StyledButton>
					<SaveButton>Salvar alterações</SaveButton>
				</StyledButton>
			</StyledButtonsContainer>
			{showModalConfirmResetForm ? (
				<ConfirmResetFormModal
					close={closeModalConfirmResetForm}
					confirm={handleResetForm}
				/>
			) : null}
		</ContactFormContainer>
	);
};

export default ContactForm;
