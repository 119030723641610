import { AxiosResponse } from 'axios';
import { BASE_URL } from '../api';
import { request } from '../base-request/base-request';
import {
	AdvertiserAddress,
	AdvertiserContacts,
	AdvertiserInfos,
} from '../interfaces';

export const patchProfileInfo = async (
	advertiser_id: string,
	advertiserInfos: AdvertiserInfos,
	advertiserAddress: AdvertiserAddress,
	advertiserContacts: AdvertiserContacts
): Promise<AxiosResponse<any>> => {
	try {
		const response = await request({
			url: `${BASE_URL}/clients/data/${advertiser_id}`,
			data: {
				advertiserInfos,
				advertiserAddress,
				advertiserContacts,
			},
			method: 'PATCH',
		});
		return response;
	} catch (error) {
		return Promise.reject(error);
	}
};
