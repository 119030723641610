import { Box, styled } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
	width: '583px',
	height: '100%',
	paddingBottom: '5%',
	overflow: 'auto',
	backgroundColor: theme.palette.common.white,
}));

export const Top = styled(Box)(() => ({
	padding: 20,
}));

export const Cards = styled(Box)(() => ({
	display: 'flex',
	maxWidth: '100%',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: '20px 80px',
}));

export const Middle = styled(Box)(() => ({
	padding: 20,
}));

export const List = styled(Box)(() => ({
	padding: 20,
}));

export const Footer = styled(Box)(() => ({
	padding: '0px 20px',
}));

export const AlignSkeleton = styled(Box)(() => ({
	padding: 20,
}));
