import { Skeleton } from "@mui/material";
import "moment/locale/pt-br";
import React from "react";
import { ReactComponent as ChevronIcon } from "../../../assets/images/chevronIcon.svg";
import { ILastComments } from "../../../pages/Dashboard/interface/lastComments.interface";
import { dateDaysFormat } from "../../../utils/mask.util";
import EmptyCard from "../../Dashboard/EmptyCard/EmptyCard";

import {
  ILastCommentsProps,
  LastCommentsProps,
} from "../../Dashboard/LastCommentsCard/interface/lastComments-data.interface";
import {
  StyledAvatar,
  StyledChevronIcon,
  StyledComment,
  StyledCommentContainer,
  StyledCommentContent,
  StyledCommentText,
  StyledCommentsList,
  StyledCommentsTitle,
  StyledCommentsTitleTypography,
  StyledDot,
  StyledTitle,
  StyledTitleContainer,
  StyledTitleTypography,
} from "./styles";

const CommentsSkeleton: React.FC = (): JSX.Element => {
  return (
    <StyledCommentContainer>
      <StyledCommentContent>
        <StyledAvatar>
          <Skeleton
            animation="wave"
            variant="circular"
            width={40}
            height={40}
          />
        </StyledAvatar>
        <StyledCommentText>
          <StyledTitle>
            <StyledTitleTypography>
              <Skeleton
                animation="wave"
                height={15}
                width="100px"
                style={{ marginBottom: 6, marginTop: 3 }}
              />
            </StyledTitleTypography>
          </StyledTitle>
          <StyledComment>
            <Skeleton animation="wave" height={15} width="300px" />
          </StyledComment>
        </StyledCommentText>
      </StyledCommentContent>
    </StyledCommentContainer>
  );
};

const Comments: React.FC<ILastCommentsProps> = ({ data }): JSX.Element => {
  return (
    <StyledCommentContainer>
      <StyledCommentContent>
        <StyledAvatar>
          <img src={data.profile.avatar} alt="Avatar do usuario" />
        </StyledAvatar>
        <StyledCommentText>
          <StyledTitle>
            <StyledTitleTypography>{data.profile.name}</StyledTitleTypography>
          </StyledTitle>
          <StyledComment>{data.comment}</StyledComment>
        </StyledCommentText>
      </StyledCommentContent>
      <StyledDot>{dateDaysFormat(String(data.created_at))}</StyledDot>
    </StyledCommentContainer>
  );
};

const CommentsCard: React.FC<LastCommentsProps> = ({
  comments,
  loader,
  totalComments,
  handleOpenCommentsModal,
}: LastCommentsProps): JSX.Element => {
  const commentsArray = comments.slice(0, 4);
  return (
    <>
      <StyledCommentsTitle>
        {loader ? (
          <StyledTitleContainer>
            <Skeleton animation="wave" height={30} width={150} />
          </StyledTitleContainer>
        ) : totalComments === 0 && totalComments <= 1 ? (
          <StyledTitleContainer>
            <StyledCommentsTitleTypography>{`${totalComments} comentário`}</StyledCommentsTitleTypography>
          </StyledTitleContainer>
        ) : (
          <StyledTitleContainer>
            <StyledCommentsTitleTypography>{`${totalComments} comentários`}</StyledCommentsTitleTypography>
          </StyledTitleContainer>
        )}
        <StyledChevronIcon onClick={handleOpenCommentsModal}>
          <ChevronIcon />
        </StyledChevronIcon>
      </StyledCommentsTitle>
      {loader ? (
        <StyledCommentsList>
          <CommentsSkeleton />
          <CommentsSkeleton />
          <CommentsSkeleton />
          <CommentsSkeleton />
        </StyledCommentsList>
      ) : commentsArray.length > 0 ? (
        <StyledCommentsList>
          {commentsArray.map((data: ILastComments, index) => (
            <Comments data={data} key={index} />
          ))}
        </StyledCommentsList>
      ) : (
        <EmptyCard
          text={"Você ainda não recebeu nenhum comentário."}
          image={"comments"}
        />
      )}
    </>
  );
};

export default CommentsCard;
