import { Box } from '@mui/material';
import { ICardDetails } from '../interfaces/interfaces';
import { Container, Subtitle, Time, Title } from './styles';

const StatusDetailsCard: React.FC<ICardDetails> = ({
	icon,
	title,
	subtitle,
	time,
	handleOpenOrderRefundModal,
}: ICardDetails): JSX.Element => {
	return (
		<Container>
			<Box>{icon}</Box>
			<Title>{title}</Title>
			<Subtitle
				isUnderlined={subtitle === 'Saiba mais'}
				onClick={handleOpenOrderRefundModal}
			>
				{subtitle}
			</Subtitle>
			{time && <Time>às {time}</Time>}
		</Container>
	);
};

export default StatusDetailsCard;
