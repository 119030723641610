import { Box, Divider } from '@mui/material';
import {
	Container,
	TableHeader,
	AlignValues,
	AlignQuantity,
	TitleText,
	AlignTextItem,
	ContentTable,
	TextContent,
	BoxItem,
	BoxComplement,
	TextComplement,
} from './styles';
import { IDetailsTable } from '../interfaces/interfaces';

const DetailsTable: React.FC<IDetailsTable> = ({
	itemOrder,
}: IDetailsTable): JSX.Element => {
	return (
		<Container>
			<TableHeader>
				<AlignQuantity>
					<TitleText>Quantidade</TitleText>
				</AlignQuantity>
				<AlignTextItem>
					<TitleText>Item</TitleText>
				</AlignTextItem>
				<AlignValues>
					<TitleText>Valor unitário</TitleText>
				</AlignValues>
				<AlignValues>
					<TitleText>SubTotal</TitleText>
				</AlignValues>
			</TableHeader>
			<Divider />
			<ContentTable>
				{itemOrder.map((item, index) => (
					<Box key={index}>
						<BoxItem>
							<AlignQuantity>
								<TextContent>{item.quantity}</TextContent>
							</AlignQuantity>
							<AlignTextItem>
								<TextContent>{item.item.name}</TextContent>
							</AlignTextItem>
							<AlignValues>
								<TextContent>R${item.unit_price}</TextContent>
							</AlignValues>
							<AlignValues>
								<TextContent>
									R${Number(item.unit_price) * item.quantity}
								</TextContent>
							</AlignValues>
						</BoxItem>
						{item.item.optionGroup.map((infoOption, index) => (
							<Box key={index}>
								{infoOption.option.map((infoComplement, index) => (
									<Box
										key={index}
										sx={{
											width: '100%',
										}}
									>
										{infoComplement.itemOrderOption.map((infoItem, index) => (
											<>
												<BoxComplement key={index}>
													<AlignQuantity>
														<TextComplement>{infoItem.quantity}</TextComplement>
													</AlignQuantity>
													<AlignTextItem>
														<TextComplement>
															{infoComplement.name}
														</TextComplement>
													</AlignTextItem>
													<AlignValues>
														<TextComplement>
															R${infoComplement.price.value.value}
														</TextComplement>
													</AlignValues>
													<AlignValues>
														<TextComplement>
															R$
															{infoComplement.price.value.value *
																infoItem.quantity}
														</TextComplement>
													</AlignValues>
												</BoxComplement>
											</>
										))}
									</Box>
								))}
							</Box>
						))}
					</Box>
				))}
			</ContentTable>
		</Container>
	);
};

export default DetailsTable;
