import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from "@mui/material";

import { ReactComponent as CouponCheck } from "../../../assets/images/couponModalIcon.svg";
import { ReactComponent as ErrorCheck } from "../../../assets/images/errorCheck.svg";
import { ReactComponent as SucessCheck } from "../../../assets/images/sucessCheck.svg";
import { ReactComponent as WarningCheck } from "../../../assets/images/warningCheck.svg";

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 8,
  top: 8,
  color: theme.palette.neutral.dark1,
}));

export const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    borderRadius: 15,
    width: 495,
    "@media only screen and (max-width: 1200px)": {
      width: 395,
    },

    "& .MuiDialogContent-root": {
      display: "flex",
    },

    "& .MuiDialogActions-root": {
      display: "flex",
    },
  },
}));

export const StyledModalContainer = styled(Box)(({ theme }) => ({
  display: "block",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.neutral.light,
  borderRadius: 20,
  border: "none",
  alignItems: "center",
  justifyContent: "center",
  padding: "36px 156px 48px 156px",
}));

export const StyledModalTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: 20,
  fontFamily: "Rubik",
  fontWeight: 600,
  color: theme.palette.neutral.dark,
  marginTop: 8,
  lineHeight: "24px",
  marginLeft: 12,
  "@media only screen and (max-width: 1400px)": {
    fontSize: 18,
  },
  "@media only screen and (max-width: 1200px)": {
    fontSize: 16,
    marginTop: 0,
    lineHeight: "20px",
  },
}));

export const StyledModalText = styled(DialogContentText)(({ theme }) => ({
  width: "100%",
  fontSize: 16,
  fontFamily: "Rubik",
  fontWeight: 400,
  color: theme.palette.neutral.dark1,
  lineHeight: "20px",
  marginBottom: 12,
  textAlign: "start",
  marginLeft: 12,
  "@media only screen and (max-width: 1400px)": {
    fontSize: 14,
  },
  "@media only screen and (max-width: 1200px)": {
    fontSize: 12,
    lineHeight: "16px",
  },
}));

export const StyledDialogActions = styled(DialogActions)(() => ({
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  padding: 0,
  paddingBottom: 32,
  "@media only screen and (max-width: 1200px)": {
    paddingBottom: 16,
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  height: 56,
  width: 423,
  borderRadius: 8,
  backgroundColor: theme.palette.primary.main,
  alignSelf: "center",
  padding: "16px 32px",
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
  "@media only screen and (max-width: 1400px)": {
    width: 232,
  },
  "@media only screen and (max-width: 1200px)": {
    height: "unset",
    fontSize: 16,
    width: 232,
    padding: "12px 32px",
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  textTransform: "none",
  width: "100%",
  fontSize: 16,
  color: theme.palette.neutral.light,
  fontFamily: "Rubik",
  fontWeight: 700,
  lineHeight: "20px",
  "@media only screen and (max-width: 1400px)": {
    fontSize: 14,
  },
}));

export const StyledSucessCheckIcon = styled(SucessCheck)(() => ({
  marginTop: 34,
  marginLeft: 36,
  "@media only screen and (max-width: 1200px)": {
    width: 50,
    marginTop: 24,
  },
}));

export const StyleCouponCheckIcon = styled(CouponCheck)(() => ({
  marginTop: 34,
  marginLeft: 36,
  "@media only screen and (max-width: 1200px)": {
    width: 50,
    marginTop: 24,
  },
}));

export const StyledErrorCheckIcon = styled(ErrorCheck)(() => ({
  marginTop: 34,
  marginLeft: 36,
  "@media only screen and (max-width: 1200px)": {
    width: 50,
    marginTop: 24,
  },
}));
export const StyledWarningCheckIcon = styled(WarningCheck)(() => ({
  marginTop: 34,
  marginLeft: 36,
  "@media only screen and (max-width: 1200px)": {
    width: 50,
    marginTop: 24,
  },
}));
