import { Box, Button, styled, Typography } from "@mui/material";

import { ReactComponent as ChevronIcon } from "../../../assets/images/whiteChevronIcon.svg";

interface DisableProp {
  disable: boolean | undefined;
}

export const StyledPromotionDetails = styled(Box)(() => ({
  display: "flex",
  width: "100%",
}));

export const StyledPromotionDetailsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutral.light2,
  width: "100%",
}));

export const StyledPromotionDetailsTopBar = styled(Box)(() => ({
  display: "flex",
  width: "100%",
}));

export const StyledTopBarContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: 56,
  alignItems: "center",
  backgroundColor: theme.palette.neutral.light,
  boxShadow: "4px 6px 15px rgba(0, 0, 0, 0.1)",
  padding: "32px 47px 32px 47px",
  justifyContent: "space-between",
  "@media only screen and (max-width: 1200px)": {
    padding: "11px 47px 11px 47px",
  },
}));

export const StyledTopBarTextContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const StyledTopBarTypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontFamily: "Rubik",
  fontWeight: 500,
  display: "baseline",
  color: theme.palette.neutral.dark,
  "@media only screen and (max-width: 1400px)": {
    fontSize: 20,
  },
  "@media only screen and (max-width: 1200px)": {
    fontSize: 16,
  },
}));

export const StyledTopBarTextIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 32,
  height: 32,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 16,
  marginRight: 24,
  cursor: "pointer",
  "@media only screen and (max-width: 1200px)": {
    width: 26,
    height: 26,
  },
}));

export const StyleChevronIcon = styled(ChevronIcon)(() => ({
  "@media only screen and (max-width: 1200px)": {
    width: 26,
    height: 12,
  },
}));

export const StyledTopBarButtonsContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const StyledTopBarIcon = styled(Box)(() => ({
  paddingTop: 6,
}));

export const StyledEditButton = styled(Box, {
  shouldForwardProp: (prop) => prop !== "disable",
})<DisableProp>(({ theme, disable }) => ({
  fontSize: 14,
  fontWeight: 700,
  borderRadius: 4,
  fontFamily: "Rubik",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.neutral.light,
  padding: "12px 28px",
  cursor: disable ? "not-allowed" : "pointer",
  marginRight: 32,
  marginLeft: 20,
  "@media only screen and (max-width: 1200px)": {
    fontSize: 12,
    padding: "12px 18px",
  },
}));

export const StyledRemoveButton = styled(Box, {
  shouldForwardProp: (prop) => prop !== "disable",
})<DisableProp>(({ theme, disable }) => ({
  fontSize: 14,
  fontWeight: 700,
  borderRadius: 4,
  fontFamily: "Rubik",
  border: "1px solid",
  borderColor: theme.palette.neutral.dark2,
  color: theme.palette.primary.main,
  padding: "12px 23px",
  cursor: disable ? "not-allowed" : "pointer",
  "@media only screen and (max-width: 1200px)": {
    fontSize: 12,
    padding: "12px 18px",
  },
}));

export const StyledCancelButton = styled(Box)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  borderRadius: 4,
  fontFamily: "Rubik",
  border: "1px solid",
  borderColor: theme.palette.neutral.dark2,
  color: theme.palette.primary.main,
  padding: "12px 25px",
  cursor: "pointer",
  userSelect: "none",
  "@media only screen and (max-width: 1200px)": {
    fontSize: 12,
    padding: "12px 18px",
  },
}));

export const StyledSaveButton = styled(Button)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  borderRadius: 4,
  fontFamily: "Rubik",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.neutral.light,
  marginLeft: 20,
  marginRight: 32,
  lineHeight: "normal",
  padding: "12px 26px",
  cursor: "pointer",
  userSelect: "none",
  textTransform: "none",
}));

export const StyledPromotionDetailsContent = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "minmax(400px, 1000px) minmax(302px, 532px)",
  gridTemplateRows: "2fr 1.8fr",
  gap: 32,
  height: "calc(100% - 216px)",
  padding: "36px 40px 40px 44px",

  "@media only screen and (max-width: 1400px)": {
    gridTemplateColumns: "minmax(400px, 1000px) minmax(302px, 532px)",
    gridTemplateRows: "250px 280px",
    padding: "24px 20px 32px 24px",
    gap: 20,
  },
  "@media (max-width: 1200px)": {
    gridTemplateRows: "250px 300px",
    padding: "24px 20px 32px 24px",
  },
}));

export const StyledPromotionDetailsFields = styled(Box)(({ theme }) => ({
  gridRow: 1,
  gridColumn: 1,
  display: "flex",
  backgroundColor: theme.palette.neutral.light,
  padding: "20px 24px 20px 20px",
  borderRadius: 20,
  gap: 24,
}));

export const SyledPromotionImage = styled(Box)(() => ({
  borderRadius: 20,
  width: 376,
  overflow: "hidden",
  boxSizing: "border-box",
  "& img": {
    height: "100%",
    width: "100%",
    objectFit: "scale-down",
  },
}));

export const StyledPromotionValuesContainer = styled(Box)(() => ({
  display: "block",
  maxWidth: "calc(100% - 376px)",
}));

export const StyledPromotionTitle = styled(Box)(({ theme }) => ({
  fontSize: 20,
  fontFamily: "Rubik",
  fontWeight: 500,
  height: 24,
  color: theme.palette.neutral.dark,
  textAlign: "justify",
  marginTop: 20,
  width: "100%",
  overflow: "hidden",
  marginBottom: 48,
}));

export const StyledPromotionText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontFamily: "Rubik",
  fontWeight: 500,
  height: 20,
  marginBottom: 14,
  color: theme.palette.neutral.dark,
  whiteSpace: "nowrap",
  "@media (max-width: 1200px)": {
    marginBottom: 10,
  },
}));

export const StyledPromotionDescription = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontFamily: "Rubik",
  fontWeight: 500,
  height: 80,
  color: theme.palette.neutral.dark1,
}));

export const StyledPromotionBottomContainer = styled(Box)(() => ({
  display: "flex",
  gap: 20,
  marginTop: "5%",
}));

export const StyledPromotionPriceContainer = styled(Box)(() => ({
  display: "block",
}));

export const StyledPromotionPrice = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  fontSize: 14,
  fontFamily: "Rubik",
  fontWeight: 400,
  height: 20,
  color: theme.palette.neutral.dark1,
  border: "1px solid",
  borderColor: theme.palette.neutral.dark1,
  borderRadius: 8,
  padding: "10px 25px 10px 10px",
  width: 88,
  overflow: "hidden",
}));

export const StyledPromotionTagContainer = styled(Box)(() => ({
  display: "block",
  marginLeft: 30,
}));

export const StyledPromotionTag = styled(Box)(({ theme }) => ({
  fontSize: 14,
  fontFamily: "Rubik",
  fontWeight: 400,
  height: 20,
  color: theme.palette.neutral.dark1,
  border: "1px solid",
  textAlign: "center",
  borderColor: theme.palette.neutral.dark1,
  borderRadius: 8,
  padding: "10px 25px 10px 10px",
  width: 146,
  overflow: "hidden",
}));

export const StyledPromotionDateCouponContainer = styled(Box)(() => ({
  gridRow: 1,
  gridColumn: 2,

  display: "block",
}));

export const StyledPromotionDate = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "calc(37% - 64px)",
  backgroundColor: theme.palette.neutral.light,
  borderRadius: 12,
  justifyContent: "space-between",
  padding: "32px 28px 30px 28px",
  "@media only screen and (max-width: 1400px)": {
    height: "calc(40% - 60px)",
    padding: "10px 28px 30px 28px",
    "& .MuiFormLabel-root": {
      fontSize: "13px",
    },
  },
}));

export const StyledPromotionDateContainer = styled(Box)(() => ({
  display: "block",
}));
export const StyledPromotionDateFieldContainer = styled(Box)(() => ({
  display: "flex",
}));

export const StyledPromotionDateField = styled(Box)(({ theme }) => ({
  fontSize: 14,
  fontFamily: "Rubik",
  fontWeight: 400,
  height: 20,
  width: 90,
  color: theme.palette.neutral.dark1,
  border: "1px solid",
  borderColor: theme.palette.neutral.dark1,
  borderRadius: "8px 0px 0px 8px",
  padding: "10px 22px 10px 22px",
  overflow: "hidden",
}));

export const StyledPromotionHourField = styled(Box)(({ theme }) => ({
  fontSize: 14,
  fontFamily: "Rubik",
  fontWeight: 400,
  height: 20,
  width: 35,
  color: theme.palette.neutral.dark1,
  border: "1px solid",
  borderColor: theme.palette.neutral.dark1,
  borderRadius: "0px 8px 8px 0px",
  padding: "10px 25px 10px 10px",
  overflow: "hidden",
}));

export const StyledPromotionCoupon = styled(Box)(({ theme }) => ({
  marginTop: 20,
  height: "calc(63% - 84px)",
  backgroundColor: theme.palette.neutral.light,
  borderRadius: 16,
  padding: "34px 28px 31px 28px",
  "@media only screen and (max-width: 1400px)": {
    height: "calc(76.2% - 54px)",
    padding: "14px 18px 0px 18px",
  },
}));

export const StyledBottomRow = styled(Box)(() => ({
  gridRow: 2,
  gridColumn: "1 / 3",
  display: "grid",
  gridTemplateColumns:
    "minmax(200px, 330px) minmax(200px, 570px)  minmax(300px, 600px)",
  gap: 32,
  "@media only screen and (max-width: 1400px)": {
    gridTemplateColumns:
      "minmax(180px, 180px) minmax(300px, 570px)  minmax(310px, 600px)",
    gap: 20,
    gridTemplateRows: "300px",
  },
}));

export const StyledPromotionStats = styled(Box)(() => ({
  gridRow: 1,
  gridColumn: 1,
  display: "inline-grid",
  borderRadius: 20,
  opacity: 1,
  pointerEvents: "all",
  marginTop: 0,
}));

export const StyledPromotionChart = styled(Box)(({ theme }) => ({
  gridRow: 1,
  gridColumn: 2,
  display: "flex",
  backgroundColor: theme.palette.neutral.light,
  borderRadius: 20,
  width: "100%",
  opacity: 1,
  pointerEvents: "all",
  marginTop: 0,
  "& canvas": {
    height: "212px!important",
    width: "100%!important",
  },
}));

export const StyledPromotionComments = styled(Box)(({ theme }) => ({
  gridRow: 1,
  gridColumn: 3,
  display: "block",
  backgroundColor: theme.palette.neutral.light,
  borderRadius: 20,
  opacity: 1,
  marginTop: 0,
  pointerEvents: "all",
}));
