import { Box } from "@mui/material";
import {
  AddressText,
  AddressTitle,
  AlignContentAndButton,
  ChangeButton,
  ChangeButtonText,
  Container,
} from "./styles";

const InfoAddressConfirmPlan: React.FC = (): JSX.Element => (
  <Container>
    <AlignContentAndButton>
      <Box>
        <AddressTitle>Endereço de cobrança</AddressTitle>
        <AddressText>
          Rua Prefeito João Costa, 000, Centro, Unaí, Minas Gerais, 38610-000
        </AddressText>
      </Box>
      <ChangeButton>
        <ChangeButtonText>Alterar</ChangeButtonText>
      </ChangeButton>
    </AlignContentAndButton>
  </Container>
);

export default InfoAddressConfirmPlan;
