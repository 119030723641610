import ClearIcon from '@mui/icons-material/Clear';
import MenuIcon from '@mui/icons-material/Menu';
import {
	CircularProgress,
	FormControl,
	IconButton,
	MenuItem,
	Paper,
	TableContainer,
} from '@mui/material';
import { AxiosError } from 'axios';
import { FormikValues } from 'formik';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { ReactComponent as MagnifyingGlassIcon } from '../../assets/images/magnifyingGlass.svg';
import DashboardTopBar from '../../components/DashboardTopBar/DashboardTopBar';
import DeliveryModal from '../../components/Menu/DeliveryModal/DeliveryModal';
import ItemListHeader from '../../components/Menu/ItemListHeader/ItemListHeader';
import ItemDeleteModal from '../../components/Menu/ItemsList/Item/ItemDeleteModal/ItemDeleteModal';
import MenuOrderModal from '../../components/Menu/MenuOrderModal/MenuOrderModal';
import categoriesFormat from '../../shared/helpers/categoriesFormat';
import { deleteMenuCategory } from '../../utils/api/delete/deleteMenuCategory';
import { deleteMenuItem } from '../../utils/api/delete/deleteMenuItem';
import { getAdvertiserMenu } from '../../utils/api/get/getAdvertiserMenu';
import { getItemByName } from '../../utils/api/get/getItemByName';
import { getItemCategory } from '../../utils/api/get/getItemCategory';
import { IMenu, IMenuItem } from '../../utils/api/interfaces';
import { patchItemById } from '../../utils/api/patch/patchItemById';
import { patchMenuById } from '../../utils/api/patch/patchMenuById';
import { unMaskReais } from '../../utils/mask.util';
import { ICategories } from '../Profile/interface/profileCategories.interface';
import {
	AddCategoryButton,
	HeaderMenu,
	InnerListContainer,
	ReorderButton,
	StyledButtonContainer,
	StyledCategoriesTextField,
	StyledLoadingContainer,
	StyledMenu,
	StyledMenuContent,
	StyledMenuListContainer,
	StyledMenuSubTitleTypography,
	StyledMenuTable,
	StyledMenuTitleContainer,
	StyledMenuTitleTypography,
	StyledMenuTopBar,
	StyledSearchMenu,
	StyledSearchTextField,
	TitleAndButtonsWrapper,
	WrapperContent,
} from './styles';

const Menu: React.FC = (): JSX.Element => {
	const [menu, setMenu] = useState<IMenu[]>();
	const [itemName, setItemName] = useState('');
	const [loaderMenu, setLoaderMenu] = useState<boolean>(true);
	const [selectedCategory, setSelectedCategory] = useState('');
	const [openDeliveryModal, setOpenDeliveryModal] = useState(false);
	const [openMenuOrderModal, setOpenMenuOrderModal] = useState(false);
	const [itemImageModalIsVisible, setItemImageModalIsVisible] = useState(false);
	const [itemToDelete, setItemToDelete] = useState<undefined | IMenuItem>(
		undefined
	);
	const [deleteItemModalIsVisible, setDeleteItemModalIsVisible] =
		useState(false);
	const [deleteCategoryModalIsVisible, setDeleteCategoryModalIsVisible] =
		useState(false);
	const [categories, setCategories] = useState<Pick<IMenu, 'name' | 'id'>[]>(
		[]
	);

	const navigate = useNavigate();
	const unsubscribe = useRef<boolean>();
	const { enqueueSnackbar } = useSnackbar();

	const debounce = useCallback(
		_.debounce(async (itemName: string): Promise<void> => {
			if (itemName) {
				setLoaderMenu(true);
				getItemByName(selectedCategory, itemName).then((res) => {
					setMenu(res);
					setLoaderMenu(false);
				});
			} else {
				getMenu();
			}
		}, 500),
		[selectedCategory, itemName]
	);

	const handleCloseCategoryDeleteModal = (): void =>
		setDeleteCategoryModalIsVisible(false);

	const handleOpenCategoryDeleteModal = (): void =>
		setDeleteCategoryModalIsVisible(true);

	const handleCloseMenuOrderModal = (): void => {
		getMenu();
		setOpenMenuOrderModal(false);
	};

	const handleOpenMenuOrderModal = (): void => setOpenMenuOrderModal(true);

	const handleCloseDeliveryModal = (): void => setOpenDeliveryModal(false);

	const handleOpenDeliveryModal = (): void => setOpenDeliveryModal(true);

	const handleCloseDeleteItemModal = (): void =>
		setDeleteItemModalIsVisible(false);

	const handleOpenDeleteItemModal = (item: IMenuItem): void => {
		setItemToDelete(item);
		setDeleteItemModalIsVisible(true);
	};

	const handleOpenItemImageModal = (): void => setItemImageModalIsVisible(true);

	const handleCloseItemImageModal = (): void =>
		setItemImageModalIsVisible(false);

	const handleSearchChange = (value: string): void => {
		setItemName(value);
		debounce(value);
	};

	const handleCategoryChange = (value: string): void => {
		setSelectedCategory(value);
	};

	const handleClearField = () => {
		setSelectedCategory('');
	};

	const handleEditItemOption = (itemId: string): void =>
		navigate('/menu/preparedItem', { state: { itemId } });

	const handleCreateItemOption = (categoryId: string) =>
		navigate('/menu/preparedItem', { state: { categoryId } });

	const getProfileCategories = (): void => {
		getItemCategory()
			.then((res) => {
				setCategories(res?.data);
			})
			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};
	const getMenu = async (): Promise<void> => {
		setLoaderMenu(true);
		await getAdvertiserMenu()
			.then((res) => {
				setMenu(res);
				setLoaderMenu(false);
			})
			.catch((err: AxiosError) => {
				setLoaderMenu(false);
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	const handleCategoryAvailability = (
		values: FormikValues,
		categoryActiveToggle: boolean,
		setSubmitting: (isSubmitting: boolean) => void,
		setCategoryActiveToggle: (value: React.SetStateAction<boolean>) => void
	): void => {
		const { menuId, available } = values;

		patchMenuById(menuId, available)
			.then(() => {
				setCategoryActiveToggle(!categoryActiveToggle);
				enqueueSnackbar('Categoria atualizado com sucesso.', {
					variant: 'success',
					autoHideDuration: 1500,
				});
			})
			.finally(() => setSubmitting(false));
	};

	const handleCategoryDelete = (categoryId: string): void => {
		deleteMenuCategory(categoryId)
			.then(() => {
				getMenu();
				enqueueSnackbar('Item excluido com sucesso!', {
					variant: 'success',
					autoHideDuration: 1500,
				});
			})
			.catch((err) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			})
			.finally(() => setDeleteCategoryModalIsVisible(false));
	};

	const handleItemPatch = (
		values: FormikValues,
		setSubmitting: (isSubmitting: boolean) => void,
		setNewItem: React.Dispatch<React.SetStateAction<IMenuItem | undefined>>
	): void => {
		const { itemId, available, itemImage } = values;
		const price = values.price ? unMaskReais(values.price) : undefined;

		patchItemById(itemId, {
			available: available,
			price: price,
			itemImage: itemImage,
		})
			.then((res) => {
				setNewItem(res?.data);
				enqueueSnackbar('Item atualizado com sucesso.', {
					variant: 'success',
					autoHideDuration: 1500,
				});
			})
			.catch((err) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			})
			.finally(() => {
				setItemImageModalIsVisible(false);
				setSubmitting(false);
			});
	};

	const handleItemDelete = (): void => {
		deleteMenuItem(itemToDelete?.id ?? '')
			.then(() => {
				getMenu();
				enqueueSnackbar('Item excluido com sucesso!', {
					variant: 'success',
					autoHideDuration: 1500,
				});
			})
			.catch((err) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			})
			.finally(() => setDeleteItemModalIsVisible(false));
	};

	useEffect(() => {
		unsubscribe.current = true;
		if (unsubscribe) {
			getProfileCategories();
			getMenu();
			return () => {
				unsubscribe.current = false;
			};
		}
	}, []);

	return (
		<StyledMenu>
			<StyledMenuTopBar>
				<DashboardTopBar
					title={'Cardápio'}
					text={'Gerencia os itens do cardápio'}
					menu={true}
					handleOpenDeliveryModal={handleOpenDeliveryModal}
				/>
				{openDeliveryModal && (
					<DeliveryModal
						handleCloseDeliveryModal={handleCloseDeliveryModal}
						openDeliveryModal={openDeliveryModal}
					/>
				)}

				{deleteItemModalIsVisible && (
					<ItemDeleteModal
						type={'item'}
						itemName={itemToDelete?.name ?? ''}
						confirm={handleItemDelete}
						close={handleCloseDeleteItemModal}
					/>
				)}
			</StyledMenuTopBar>
			<StyledMenuContent>
				<StyledMenuListContainer>
					<HeaderMenu>
						<TitleAndButtonsWrapper>
							<StyledMenuTitleContainer>
								<StyledMenuTitleTypography>
									Itens do cardápio
								</StyledMenuTitleTypography>
								<StyledMenuSubTitleTypography>
									Gerencie e cadastre itens no seu cardápio
								</StyledMenuSubTitleTypography>
							</StyledMenuTitleContainer>
							<StyledButtonContainer>
								<ReorderButton
									onClick={() => {
										handleOpenMenuOrderModal();
									}}
								>
									<MenuIcon /> &nbsp; &nbsp;Reordenar
								</ReorderButton>
								<AddCategoryButton
									variant="contained"
									onClick={() => {
										navigate('newCategory');
									}}
								>
									Adicionar categoria
								</AddCategoryButton>
								{openMenuOrderModal && (
									<MenuOrderModal
										handleCloseMenuOrderModal={handleCloseMenuOrderModal}
										openMenuOrderModal={openMenuOrderModal}
									/>
								)}
							</StyledButtonContainer>
						</TitleAndButtonsWrapper>

						<StyledSearchMenu>
							<FormControl style={{ width: '82%' }}>
								<StyledSearchTextField
									InputLabelProps={{ shrink: false }}
									placeholder="Buscar item..."
									value={itemName}
									onChange={(e) => handleSearchChange(e.target.value)}
									InputProps={{
										endAdornment: (
											<IconButton onClick={() => handleSearchChange(itemName)}>
												<MagnifyingGlassIcon />
											</IconButton>
										),
									}}
								/>
							</FormControl>
							<FormControl>
								<StyledCategoriesTextField
									select
									id="outlined-basic"
									type="text"
									value={selectedCategory}
									defaultValue={''}
									label="Categoria"
									variant="outlined"
									onChange={(e) => handleCategoryChange(e.target.value)}
									SelectProps={{
										MenuProps: {
											sx: { maxHeight: '20%' },
										},
									}}
									InputProps={{
										endAdornment: (
											<IconButton
												onClick={handleClearField}
												edge="end"
												size="large"
												style={{
													visibility: selectedCategory ? 'visible' : 'hidden',
													marginRight: '10%',
												}}
											>
												<ClearIcon fontSize="small" />
											</IconButton>
										),
									}}
								>
									{categories.map((categories: ICategories) => (
										<MenuItem key={categories.id} value={categories.id}>
											{categoriesFormat(categories.name)}
										</MenuItem>
									))}
								</StyledCategoriesTextField>
							</FormControl>
						</StyledSearchMenu>
					</HeaderMenu>
					{loaderMenu ? (
						<StyledLoadingContainer>
							<CircularProgress color="primary" />
						</StyledLoadingContainer>
					) : (
						<InnerListContainer>
							{menu?.map((category, index) => (
								<WrapperContent key={category.id}>
									<TableContainer id={'menuTable'} component={Paper}>
										<StyledMenuTable aria-label="Tabela de cardápio">
											<ItemListHeader
												id={category.id}
												indexMenu={index}
												name={category.name}
												items={category.items}
												available={category.available}
												handleItemPatch={handleItemPatch}
												handleItemDelete={handleItemDelete}
												handleEditItemOption={handleEditItemOption}
												handleCreateItemOption={handleCreateItemOption}
												itemImageModalIsVisible={itemImageModalIsVisible}
												handleOpenItemImageModal={handleOpenItemImageModal}
												deleteItemModalIsVisible={deleteItemModalIsVisible}
												handleOpenDeleteItemModal={handleOpenDeleteItemModal}
												handleCloseItemImageModal={handleCloseItemImageModal}
												handleCloseDeleteItemModal={handleCloseDeleteItemModal}
												handleCategoryAvailability={handleCategoryAvailability}
												handleOpenCategoryDeleteModal={
													handleOpenCategoryDeleteModal
												}
											/>
										</StyledMenuTable>
									</TableContainer>

									{deleteCategoryModalIsVisible && (
										<ItemDeleteModal
											type={'categoria'}
											itemName={category.name}
											close={handleCloseCategoryDeleteModal}
											confirm={() => handleCategoryDelete(category.id)}
										/>
									)}
								</WrapperContent>
							))}
						</InnerListContainer>
					)}
				</StyledMenuListContainer>
			</StyledMenuContent>
		</StyledMenu>
	);
};

export default Menu;
