import React from "react";
import { ReactComponent as EmptyComments } from "../../../assets/images/emptyComments.svg";
import { ReactComponent as EmptyStats } from "../../../assets/images/emptyStats.svg";
import { EmptyCardProps } from "./interface/emptyCard.interface";
import {
  EmptyPromotions,
  StyledCard,
  StyledCardContainer,
  StyledEmptyTypography,
  StyledImage,
} from "./styles";

const EmptyCard: React.FC<EmptyCardProps> = ({
  text,
  image,
  item,
}: EmptyCardProps): JSX.Element => {
  return (
    <StyledCard>
      <StyledCardContainer>
        <StyledImage>
          {image === "promotion" && <EmptyPromotions />}
          {image === "stats" && <EmptyStats />}
          {image === "comments" && <EmptyComments />}
        </StyledImage>
        <StyledEmptyTypography item={item}>{text}</StyledEmptyTypography>
      </StyledCardContainer>
    </StyledCard>
  );
};

export default EmptyCard;
