import { AxiosResponse } from 'axios';
import { BASE_URL } from '../api';
import { request } from '../base-request/base-request';

export const getInfoCardsById = async (
	itemId: string
): Promise<AxiosResponse<any>> => {
	try {
		return request({
			url: `${BASE_URL}/orders/client/financial/${itemId}`,
			method: 'get',
		});
	} catch (error) {
		return Promise.reject(error);
	}
};
