import React from "react";
import CouponsCard from "./Cards/CouponsCard";
import LikesCard from "./Cards/LikesCard";
import ViewCard from "./Cards/ViewCard";
import { IStatisticsCard } from "./interface/card-interface";
import {
  StyledCardTitle,
  StyledIcon,
  StyledStatisticsCard,
  StyledText,
  StyledTextContainer,
  StyledTextSkeleton,
} from "./styles";

const StatisticsCard: React.FC<IStatisticsCard> = ({
  itens,
  index,
  indexButton,
  from,
}: IStatisticsCard): JSX.Element => {
  return (
    <StyledStatisticsCard isSelected={index === indexButton}>
      {itens.loader ? (
        <>
          <StyledCardTitle>
            <StyledIcon from={from === "details"}>{itens.icon}</StyledIcon>
            <StyledText isSelected={index === indexButton}>
              <StyledTextSkeleton animation="wave" />
            </StyledText>
          </StyledCardTitle>
          <StyledTextContainer>
            {index === 0 && (
              <ViewCard itens={itens} index={index} indexButton={indexButton} />
            )}
            {index === 1 && (
              <LikesCard
                itens={itens}
                index={index}
                indexButton={indexButton}
              />
            )}
            {index === 2 && (
              <CouponsCard
                itens={itens}
                index={index}
                indexButton={indexButton}
              />
            )}
          </StyledTextContainer>
        </>
      ) : (
        <>
          <StyledCardTitle>
            <StyledIcon from={from === "details"}>{itens.icon}</StyledIcon>
            <StyledText isSelected={index === indexButton}>
              {itens.text}
            </StyledText>
          </StyledCardTitle>
          <StyledTextContainer>
            {index === 0 && (
              <ViewCard itens={itens} index={index} indexButton={indexButton} />
            )}
            {index === 1 && (
              <LikesCard
                itens={itens}
                index={index}
                indexButton={indexButton}
              />
            )}
            {index === 2 && (
              <CouponsCard
                itens={itens}
                index={index}
                indexButton={indexButton}
              />
            )}
          </StyledTextContainer>
        </>
      )}
    </StyledStatisticsCard>
  );
};

export default StatisticsCard;
