import { AxiosResponse } from 'axios';
import { BASE_URL } from '../api';
import { request } from '../base-request/base-request';
import { OpeningHoursProps } from '../interfaces';

export const patchProfileOpeningHours = async ({
	advertiser_id,
	openingHours,
	deliveryOptions,
}: OpeningHoursProps): Promise<AxiosResponse<any> | undefined> => {
	try {
		return await request({
			url: `${BASE_URL}/clients/operation/${advertiser_id}`,
			method: 'patch',
			data: { openingHours, deliveryOptions },
		});
	} catch (error) {
		return Promise.reject(error);
	}
};
