export const SIGN_UP_INITIAL_VALUES = {
  name: "",
  cnpj: "",
  cellphone: "",
  email: "",
  password: "",
  confirmPassword: "",
  tandc: false,
};

export default SIGN_UP_INITIAL_VALUES;
