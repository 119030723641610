import { Box, Button, styled, Typography } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  width: "1072px",
  height: "176px",
  marginTop: "24px",
  padding: "0px 24px 0px 24px",
  borderRadius: "12px",
  border: `1px solid ${theme.palette.neutral.light2}`,
}));

export const HeaderPlan = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "28px",
  marginBottom: "10px",
}));

export const IconAndPlan = styled(Box)(() => ({
  display: "flex",
}));

export const TypeContent = styled(Box)(() => ({
  marginLeft: "12px",
}));

export const PlanText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.neutral.dark1,
}));

export const PlanType = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.common.black,
}));

export const OldAndNewPrice = styled(Box)(() => ({}));

export const OldPrice = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontFamily: "Rubik",
  fontWeight: 500,
  marginLeft: "65px",
  textDecoration: "line-through",
  color: theme.palette.neutral.dark1,
}));

export const NewPriceAndText = styled(Box)(() => ({
  display: "flex",
}));

export const NewPrice = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.success.main,
}));

export const NewPriceText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.neutral.dark1,
}));

export const BenefitsAndButton = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const Benefits = styled(Box)(() => ({}));

export const AlignIconAndText = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginTop: "12px",
}));

export const FirstBenefitText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 400,
  marginLeft: "8px",
  color: theme.palette.neutral.dark1,
}));

export const SecondBefitText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 400,
  marginLeft: "8px",
  color: theme.palette.neutral.dark1,
}));

export const ButtonChoosePlan = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "276px",
  height: "44px",
  marginTop: "18px",
  borderRadius: "8px",
  backgroundColor: theme.palette.primary.main,
}));

export const ButtonText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 700,
  textTransform: "initial",
  color: theme.palette.common.white,
}));
