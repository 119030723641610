import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { ConfirmCouponModalProps } from "./interface/modalConfirmCoupon.interface";
import {
  StyledButtonsContainer,
  StyledCancelButton,
  StyledConfirmButton,
  StyledDialog,
  StyledErrorCheckIcon,
  StyledIconButton,
  StyledModalText,
  StyledModalTitle,
} from "./styles";

const CouponConfirmModal: React.FC<ConfirmCouponModalProps> = ({
  handleCloseConfirmCouponModal,
  handleConfirmCoupon,
  openConfirmCouponModal,
  promotionTitle,
}: ConfirmCouponModalProps): JSX.Element => (
  <StyledDialog
    open={openConfirmCouponModal}
    onClose={handleCloseConfirmCouponModal}
    fullWidth
    maxWidth="sm"
  >
    <StyledIconButton onClick={handleCloseConfirmCouponModal}>
      <CloseIcon />
    </StyledIconButton>
    <StyledErrorCheckIcon />
    <StyledModalTitle>Confirmar uso do cupom?</StyledModalTitle>
    <StyledModalText>
      Você irá confirmar o uso do cupom para o produto {`"${promotionTitle}".`}{" "}
      Essa ação não poderá ser desfeita.
    </StyledModalText>
    <StyledButtonsContainer>
      <StyledCancelButton onClick={handleCloseConfirmCouponModal}>
        Cancelar
      </StyledCancelButton>
      <StyledConfirmButton onClick={handleConfirmCoupon}>
        Confirmar
      </StyledConfirmButton>
    </StyledButtonsContainer>
  </StyledDialog>
);

export default CouponConfirmModal;
