import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addNav } from '../../auth/actions/sideNav.action';
import { IAuth } from '../../auth/interface/auth.interface';
import { ISideNav } from '../../auth/interface/sideNav.interface';
import { IReducers } from '../../auth/store/store';
import CouponsChart from '../../components/Dashboard/DashboardChart/CouponsChart';
import LikesChart from '../../components/Dashboard/DashboardChart/LikesChart';
import ViewsChart from '../../components/Dashboard/DashboardChart/ViewsChart';
import { DataStatsProps } from '../../components/Dashboard/DashboardChart/interface/chart-data.interface';
import DashboardModal from '../../components/Dashboard/DashboardModal/DashboardModal';
import LastCommentsCard from '../../components/Dashboard/LastCommentsCard/LastCommentsCard';
import BestPromotionsCard from '../../components/Dashboard/MostViewPromotion/MostViewPromotion';
import PlanCard from '../../components/Dashboard/PlanCard/PlanCard';
import PromotionButton from '../../components/Dashboard/PromotionButton/PromotionButton';
import StatisticsCard from '../../components/Dashboard/StatisticsCard/StatisticsCard';
import { CARDICONS } from '../../components/Dashboard/StatisticsCard/constants/statisticsCard-value.constants';
import { ICard } from '../../components/Dashboard/StatisticsCard/interface/card-interface';
import DashboardTopBar from '../../components/DashboardTopBar/DashboardTopBar';
import { getAdvertiserStats } from '../../utils/api/get/getAdvertiserStats';
import { getLastComments } from '../../utils/api/get/getLastComments';
import { getPromotionsLikes } from '../../utils/api/get/getPromotionsLikes';
import { getPromotionsViews } from '../../utils/api/get/getPromotionsViews';
import { getRansomedCouponsByAdvertiser } from '../../utils/api/get/getRansomedCouponsByAdvertiser';
import { StatsProps } from './interface';
import { ILastComments } from './interface/lastComments.interface';
import {
	StyledBestPromotions,
	StyledBottomRow,
	StyledCardflat,
	StyledContentCards,
	StyledDashboard,
	StyledDashboardChart,
	StyledDashboardContainer,
	StyledDashboardContent,
	StyledDashboardTopBar,
	StyledLastComments,
	StyledRightContainer,
} from './styles';

const Dashboard: React.FC = (): JSX.Element => {
	const [indexButton, setindexButton] = useState(0);
	const [lastComments, setLastComments] = useState<ILastComments[]>([]);
	const [stats, setStats] = useState<StatsProps>();
	const [view, setView] = useState<DataStatsProps>();
	const [like, setLikes] = useState<DataStatsProps>();
	const [coupons, setCoupons] = useState<DataStatsProps>();
	const [openDashboardModal, setOpenDashboardModal] = useState(false);
	const [loaderComments, setLoaderComments] = useState(true);
	const [loaderChart, setLoaderChart] = useState(true);
	const [loaderStats, setLoaderStats] = useState(true);
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();

	const unsubscribe = useRef<boolean>();

	const { advertiser_id }: IAuth = useSelector(
		(state: IReducers) => state.auth
	);

	const setIndexOfSideNavButton = (sideNav: ISideNav): void => {
		dispatch(addNav(sideNav));
	};

	const handleCloseDashboardModal = (): void => setOpenDashboardModal(false);

	const handleOpenDashboardModal = (): void => setOpenDashboardModal(true);

	const handleSelection = (index: number): void => {
		if (index === 0) {
			setindexButton(0);
		} else if (index === 1) {
			setindexButton(1);
		} else if (index === 2) {
			setindexButton(2);
		}
	};

	const getAdvertiserLastComments = (): void => {
		getLastComments(advertiser_id)
			.then((res) => {
				setLastComments(res?.data);
				setLoaderComments(false);
			})
			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	const getAdvertiserStatistics = (): void => {
		getAdvertiserStats(advertiser_id)
			.then((res) => {
				setStats(res?.data);
				setLoaderStats(false);
			})
			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};
	const getAdvertiserTotalViews = (): void => {
		getPromotionsViews(advertiser_id)
			.then((res) => {
				setView(res?.data);
				setLoaderChart(false);
			})
			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};
	const getAdvertiserTotalLikes = (): void => {
		getPromotionsLikes(advertiser_id)
			.then((res) => {
				setLikes(res?.data);
			})
			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};
	const getAdvertiserTotalRansomedCoupons = (): void => {
		getRansomedCouponsByAdvertiser(advertiser_id)
			.then((res) => {
				setCoupons(res?.data);
			})
			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	useEffect(() => {
		unsubscribe.current = true;
		if (unsubscribe) {
			getAdvertiserLastComments();
			getAdvertiserStatistics();
			getAdvertiserTotalViews();
			getAdvertiserTotalLikes();
			getAdvertiserTotalRansomedCoupons();

			setIndexOfSideNavButton({
				showSide: true,
				buttonIndex: 0,
			});
		}
		return () => {
			unsubscribe.current = false;
		};
	}, []);

	return (
		<StyledDashboard>
			<StyledDashboardContainer>
				<StyledDashboardTopBar>
					<DashboardTopBar
						title={'Dashboard'}
						text={'Essas são as estatísticas das suas ofertas :)'}
					/>
				</StyledDashboardTopBar>
				<StyledDashboardContent>
					<StyledContentCards>
						{CARDICONS.map((itens: ICard, index) => (
							<Button
								key={index}
								onClick={() => handleSelection(index)}
								disableTouchRipple
								disableFocusRipple
								disableRipple
								sx={{
									position: 'relative',
									height: '110px',
									width: '100%',
									paddingTop: '25px',
									marginBottom: '5%',
									paddingRight: '0px',
									paddingLeft: '0px',
									'&:last-child': {
										marginBottom: '0px',
									},
									'@media (max-width: 1401px)': {
										height: '70px',
										marginBottom: '7%',
									},
								}}
							>
								<StatisticsCard
									itens={{
										...itens,
										...{
											views: stats?.totalViews,
											likes: stats?.totalLikes,
											coupons: stats?.totalUsedCoupons,
											loader: loaderStats,
										},
									}}
									index={index}
									indexButton={indexButton}
								/>
							</Button>
						))}
					</StyledContentCards>
					<StyledDashboardChart>
						{indexButton === 0 && (
							<ViewsChart data={view} loader={loaderChart} />
						)}
						{indexButton === 1 && (
							<LikesChart data={like} loader={loaderChart} />
						)}
						{indexButton === 2 && (
							<CouponsChart data={coupons} loader={loaderChart} />
						)}
					</StyledDashboardChart>
					<StyledRightContainer>
						<PromotionButton
							handleOpenDashboardModal={handleOpenDashboardModal}
						/>

						<DashboardModal
							handleCloseDashboardModal={handleCloseDashboardModal}
							openDashboardModal={openDashboardModal}
						/>
						<StyledCardflat>
							<PlanCard />
						</StyledCardflat>
					</StyledRightContainer>
					<StyledBottomRow>
						<StyledBestPromotions>
							<BestPromotionsCard />
						</StyledBestPromotions>
						<StyledLastComments>
							<LastCommentsCard
								comments={lastComments}
								loader={loaderComments}
							/>
						</StyledLastComments>
					</StyledBottomRow>
				</StyledDashboardContent>
			</StyledDashboardContainer>
		</StyledDashboard>
	);
};

export default Dashboard;
