import React from "react";
import { ReactComponent as CouponIcon } from "../../../assets/images/couponFilledIconPromotion.svg";
import {
  StyledCouponIcon,
  StyledEmptyCouponText,
  StyledEmptyCouponTitleSkeleton,
} from "./styles";

const EmptyCouponCard: React.FC = (): JSX.Element => (
  <>
    <StyledCouponIcon>
      <CouponIcon />
    </StyledCouponIcon>
    <StyledEmptyCouponTitleSkeleton>
      Nenhum cupom ativo
    </StyledEmptyCouponTitleSkeleton>
    <StyledEmptyCouponText>
      Você não ativou cupons para essa promoção.
    </StyledEmptyCouponText>
  </>
);

export default EmptyCouponCard;
