import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, IconButton, Table, TableRow } from '@mui/material';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as ComplementsGroupIcon } from '../../../../assets/images/complementsGroup.svg';
import { ReactComponent as NoImageIcon } from '../../../../assets/images/noImageIcon.svg';
import { addOptionGroupId } from '../../../../auth/actions/editOptionGroup.action';
import {
	IEditOptionGroup,
	IEditOptionGroupAction,
} from '../../../../auth/interface/editOptionGroup.interface';
import { ICreateComplementProps } from '../../../../pages/AddItem/Interfaces/interfaces';
import { moneyMask, unMaskReais } from '../../../../utils/mask.util';
import ItemDeleteModal from '../../../Menu/ItemsList/Item/ItemDeleteModal/ItemDeleteModal';
import CreatePopperButton from './CreatePopperButton';
import {
	AccordionStyled,
	AccordionSummaryStyled,
	BoxContent,
	BoxTitle,
	ButtonText,
	ComplementName,
	ComplementsGroupTitle,
	ContentName,
	ContentSubName,
	CreateButton,
	EndBox,
	ImageAndText,
	ImageItem,
	Img,
	InitialBox,
	InitialMenuIcon,
	LinkText,
	NameAndSubtitle,
	OptionButton,
	OptionButtonText,
	Price,
	PriceText,
	StyledHeaderOptionsCell,
	SubName,
	TextButton,
	WrapperButton,
	WrapperContent,
	WrapperIcons,
	WrapperPrice,
	WrapperTitle,
} from './styles';

const CreateComplement: React.FC<ICreateComplementProps> = ({
	optionGroups,
	handleRenderCreateGroup,
	handleOptionGroupChange,
}: ICreateComplementProps): JSX.Element => {
	const [openPopperIndex, setOpenPopperIndex] = useState<number | null>(null);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
	const [expandAccordion, setExpandAccordion] = useState<
		Record<number, boolean>
	>({});
	const anchorRefs = useRef<(HTMLButtonElement | null)[]>([]);

	const { setFieldValue } = useFormikContext();

	const handleExpandChange =
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		(panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
			setExpandAccordion((prevExpanded) => ({
				...prevExpanded,
				[panel]: !prevExpanded[panel],
			}));
		};
	const dispatch = useDispatch();

	const setOptionGroupEdit = (
		optionGroupId: IEditOptionGroup
	): IEditOptionGroupAction => dispatch(addOptionGroupId(optionGroupId));

	const handleTogglePopper = (index: number): void => {
		setOpenPopperIndex((prevIndex) => (prevIndex === index ? null : index));
	};

	const handleClosePopper = (): void => {
		setOpenPopperIndex(null);
	};

	const menuOptionsGroup = [
		{ action: 'edit', label: 'Editar grupo de complemento' },
		{ action: 'delete', label: 'Excluir grupo de complemento' },
	];

	const handleDeleteComplementGroup = () => {
		if (optionGroups === undefined || deleteIndex === null) {
			return;
		}

		const newOptionGroups = [...optionGroups];
		newOptionGroups.splice(deleteIndex, 1);

		setFieldValue('optionGroups', newOptionGroups);

		if (!newOptionGroups.length) {
			handleOptionGroupChange();
		}

		handleCloseDeleteModal();
	};

	const handleCloseDeleteModal = (): void => setOpenDeleteModal(false);

	const handleItemClick = (action: string, index: number): void => {
		if (optionGroups === undefined) {
			return;
		}

		const actionFunctions: { [key: string]: () => void } = {
			edit: () => {
				if (handleRenderCreateGroup) {
					setOptionGroupEdit({
						optionGroupEditId: index,
					});
					handleRenderCreateGroup();
				}
			},
			delete: () => {
				setDeleteIndex(index);
				setOpenDeleteModal(true);
			},
		};

		actionFunctions[action]?.();
		handleClosePopper();
	};

	const handleListKeyDown = (event: React.KeyboardEvent): void => {
		if (event.key === 'Tab') {
			event.preventDefault();
			handleClosePopper();
		}
	};

	useEffect(() => {
		setOptionGroupEdit({
			optionGroupEditId: undefined,
		});
	}, [optionGroups]);

	return (
		<>
			<Table>
				{optionGroups && optionGroups[0]?.name ? (
					<TableRow>
						<StyledHeaderOptionsCell>
							<WrapperTitle>
								<ComplementsGroupIcon />
								<ComplementsGroupTitle>
									Grupo(s) de complemento do item
								</ComplementsGroupTitle>
							</WrapperTitle>
							<TextButton onClick={handleRenderCreateGroup}>
								<LinkText>+ Adicionar grupo de complementos</LinkText>
							</TextButton>
						</StyledHeaderOptionsCell>
						{optionGroups.map((optionGroup, index) => {
							return (
								<AccordionStyled
									key={index}
									expanded={expandAccordion[index] || false}
									onChange={handleExpandChange(index)}
								>
									<AccordionSummaryStyled sx={{ pointerEvents: 'none' }}>
										<BoxTitle>
											<InitialBox>
												<WrapperIcons>
													<InitialMenuIcon />
												</WrapperIcons>
												<NameAndSubtitle>
													<ComplementName>{optionGroup.name}</ComplementName>
													<SubName>{optionGroup.options.length} opção</SubName>
												</NameAndSubtitle>
											</InitialBox>
											<EndBox>
												<OptionButton>
													<OptionButtonText>
														{optionGroup.optional ? 'Opcional' : 'Obrigatório'}
													</OptionButtonText>
												</OptionButton>
											</EndBox>
											<Box display={'flex'}>
												<IconButton
													aria-label="expand row"
													size="small"
													onClick={() => handleExpandChange(index)}
													sx={{ pointerEvents: 'auto' }}
												>
													{expandAccordion[index] ? (
														<KeyboardArrowUpIcon />
													) : (
														<KeyboardArrowDownIcon />
													)}
												</IconButton>
												<Box
													onClick={(e) => e.stopPropagation()}
													sx={{ pointerEvents: 'auto' }}
													ref={(el) => {
														anchorRefs.current[index] =
															el as HTMLButtonElement | null;
													}}
												>
													<CreatePopperButton
														open={openPopperIndex === index}
														anchorRef={anchorRefs}
														menuItems={menuOptionsGroup}
														handleItemClick={(action) =>
															handleItemClick(action, index)
														}
														handleClose={handleClosePopper}
														handleListKeyDown={handleListKeyDown}
														handleToggle={handleTogglePopper}
														index={index}
													/>

													{openDeleteModal && (
														<ItemDeleteModal
															confirm={handleDeleteComplementGroup}
															close={handleCloseDeleteModal}
															itemName={optionGroup.name}
															type={'grupo de complementos'}
														/>
													)}
												</Box>
											</Box>
										</BoxTitle>
									</AccordionSummaryStyled>
									<AccordionDetails>
										{optionGroup.options.map((option, idx) => {
											return (
												<BoxContent key={idx}>
													<WrapperContent>
														<ImageAndText>
															<ImageItem>
																{option.optionImage ? (
																	<Img
																		src={option.optionImage}
																		alt="Imagem do Complemento"
																	/>
																) : (
																	<NoImageIcon />
																)}
															</ImageItem>
															<NameAndSubtitle>
																<ContentName>{option.name}</ContentName>
																<ContentSubName>
																	{option.description}
																</ContentSubName>
															</NameAndSubtitle>
														</ImageAndText>
														{unMaskReais(
															option.optionPrice as unknown as string
														) > 0 && (
															<WrapperPrice>
																<PriceText>à partir de</PriceText>
																<Price>{moneyMask(option.optionPrice)}</Price>
															</WrapperPrice>
														)}
													</WrapperContent>
												</BoxContent>
											);
										})}
									</AccordionDetails>
								</AccordionStyled>
							);
						})}
					</TableRow>
				) : null}
			</Table>
			{optionGroups && optionGroups[0]?.name ? null : (
				<WrapperButton>
					<CreateButton onClick={handleRenderCreateGroup}>
						<ButtonText>Adicionar grupo de complementos</ButtonText>
					</CreateButton>
				</WrapperButton>
			)}
		</>
	);
};

export default CreateComplement;
