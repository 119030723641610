import {
	Box,
	Button,
	Dialog,
	IconButton,
	TextField,
	styled,
} from '@mui/material';

export const StyledPromotionListContainer = styled(Box)(() => ({
	display: 'flex',
	height: 30,
	backgroundColor: 'yellow',
	width: '100%',
}));

export const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 15,
		padding: '24px 0px 29px 0px',
		position: 'absolute',
		right: 0,
		top: 100,

		'@media only screen and (max-width: 1400px)': {
			width: 800,
			height: 450,
		},

		'& .MuiDialogContent-root': {
			display: 'flex',
		},

		'& .MuiDialogActions-root': {
			display: 'flex',
		},
	},
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
	position: 'absolute',
	right: 8,
	top: 8,
	color: theme.palette.neutral.dark1,
}));

export const StyledSvgContainer = styled(Box)(() => ({
	display: 'flex',
	marginBottom: 28,
}));

export const StyledModalTitleContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	boxSizing: 'border-box',
	minWidth: '100%',
	padding: '0px 24px 20px 24px',
	alignItems: 'center',
	marginBottom: 32,
	borderBottom: '1px solid',
	borderColor: theme.palette.neutral.dark2,
}));
export const StyledTitleTextContainer = styled(Box)(() => ({
	display: 'block',
	boxSizing: 'border-box',
	marginLeft: 16,
}));

export const StyledTitleContainer = styled(Box)(() => ({
	display: 'block',
	boxSizing: 'border-box',
	marginLeft: 16,
	width: '34.3%',
}));

export const StyledModalTitle = styled(Box)(({ theme }) => ({
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.neutral.dark,
	lineHeight: '28px',
}));
export const StyledModalSubtitle = styled(Box)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
	lineHeight: '20px',
}));

export const StyledButtonsContainer = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	marginTop: 32,
}));

export const StyledIconBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: '16px 12px',
	border: '1px solid',
	borderColor: theme.palette.primary.contrastText,
	borderRadius: 8,
}));

export const StyledDisableButton = styled(Button)(({ theme }) => ({
	fontSize: 18,
	fontWeight: 700,
	borderRadius: 8,
	fontFamily: 'Rubik',
	backgroundColor: theme.palette.primary.main,
	color: theme.palette.neutral.light,
	marginLeft: 20,
	lineHeight: '24px',
	padding: '16px 53px',
	cursor: 'pointer',
	userSelect: 'none',
	textTransform: 'none',
}));

export const StyledDeliveryValue = styled(Box)(() => ({
	display: 'block',
	width: '16.8%',
}));

export const StyledModalText = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 16,
	color: theme.palette.neutral.dark,
	marginBottom: 12,
}));

export const StyledModalTextCenter = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 16,
	color: theme.palette.neutral.dark,
	marginBottom: 12,
}));

export const StyledValueTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 44,
		fontFamily: 'Rubik',
		fontSize: '13px',
		marginLeft: 8,

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'@media only screen and (max-width: 1400px)': {
		marginRight: 20,
		'& .MuiFormLabel-root': {
			fontSize: '13px',
		},
		'& .MuiFormHelperText-root': {
			fontSize: '9px!important',
			whiteSpace: 'nowrap',
		},
	},
}));

export const StyledTimeTextFieldContainer = styled(Box)(() => ({
	display: 'block',
	alignItems: 'center',
	width: '17.2%',
}));

export const StyledTimeTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 44,
		fontFamily: 'Rubik',
		fontSize: '13px',
		marginLeft: 10,

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'@media only screen and (max-width: 1400px)': {
		marginRight: 20,
		'& .MuiFormLabel-root': {
			fontSize: '13px',
		},
		'& .MuiFormHelperText-root': {
			fontSize: '9px!important',
			whiteSpace: 'nowrap',
		},
	},
}));

export const StyledDistrictContainer = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '25%',
}));

export const StyledModalTextHead = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 16,
	color: theme.palette.neutral.dark,
}));

export const StyledModalTextHeadInitial = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 16,
	color: theme.palette.neutral.dark,
}));

export const StyledCancelButton = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	fontFamily: 'Rubik',
	height: 49,
	width: 128,
	fontWeight: 700,
	fontSize: 18,
	cursor: 'pointer',
	color: theme.palette.primary.main,
	border: '2px solid',
	borderColor: theme.palette.neutral.dark2,
	borderRadius: 8,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
		height: 39,
		width: 108,
	},
}));
