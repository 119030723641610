import Moment from 'moment';
import 'moment/locale/pt-br';

export const CREATE_PROMOTION_INITIAL_VALUES = {
	title: '',
	description: '',
	initial_price: 0,
	promotional_price: 0,
	promotion_initial_date: Moment(),
	promotion_expires_date: null,
	promotion_expires_hour: null,
	tag_id: '',
	tag_name: '',
	promotion_image: '',
	has_coupons: false,
	qtd_coupons: 0,
	coupon_duration: '',
	user_with_multiplus_coupons: false,
	payment_methods: [],
};

export default CREATE_PROMOTION_INITIAL_VALUES;
