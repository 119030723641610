import { Box, Button } from "@mui/material";
import { ReactComponent as CheckIcon } from "../../../assets/images/CheckIcon.svg";
import { ReactComponent as GoldPlanIcon } from "../../../assets/images/GoldPlanIcon.svg";
import { ReactComponent as UpdatePlanIcon } from "../../../assets/images/UpdatePlanIcon.svg";

import {
  AlignNameAndIcon,
  AlignValue,
  BenefitsPreview,
  ButtonViewAllBenefit,
  ButtonViewAllText,
  CurrentFlatCardButtons,
  CurrentPlanBox,
  CurrentPlanCard,
  ElipseIcon,
  FirstBenefit,
  PlanName,
  PlanTypeAndValue,
  PlanValue,
  SecondBenefit,
  SubTitle,
  SymbolAndName,
  SymbolPlan,
  Texts,
  TextValue,
  Title,
  UnderlineStyleButtonText,
  UpdatePlanButton,
  ValidityPlan,
} from "./styles";

const CurrentPlan: React.FC = (): JSX.Element => (
  <>
    <Texts>
      <Title>Plano atual</Title>
      <SubTitle>Esse é o seu plano contratado para esse mês</SubTitle>
    </Texts>
    <CurrentPlanBox>
      <CurrentPlanCard>
        <UpdatePlanButton>
          <UpdatePlanIcon />
        </UpdatePlanButton>
        <PlanTypeAndValue>
          <SymbolAndName>
            <SymbolPlan>
              <GoldPlanIcon />
            </SymbolPlan>

            <Box>
              <PlanName>Gold</PlanName>
              <ValidityPlan>Até 07/08/2022</ValidityPlan>
            </Box>
          </SymbolAndName>

          <AlignValue>
            <PlanValue> R$ 149,90</PlanValue>
            <TextValue>/mes</TextValue>
          </AlignValue>
        </PlanTypeAndValue>

        <BenefitsPreview>
          <AlignNameAndIcon>
            <CheckIcon />
            <FirstBenefit>15 Promoções limitadas até 5 dias</FirstBenefit>
          </AlignNameAndIcon>
          <AlignNameAndIcon>
            <ElipseIcon />
            <SecondBenefit>
              3 promoções pode ser destacadas na categoria do produto
            </SecondBenefit>
          </AlignNameAndIcon>
        </BenefitsPreview>
        <CurrentFlatCardButtons>
          <Button>
            <UnderlineStyleButtonText>Cancelar plano</UnderlineStyleButtonText>
          </Button>
          <ButtonViewAllBenefit>
            <ButtonViewAllText>Ver todos os benefícios</ButtonViewAllText>
          </ButtonViewAllBenefit>
        </CurrentFlatCardButtons>
      </CurrentPlanCard>
    </CurrentPlanBox>
  </>
);

export default CurrentPlan;
