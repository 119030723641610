import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { PasswordProps } from "../../../utils/api/interfaces";

const formHelperStyle = {
  fontSize: 13,
  fontFamily: "Rubik",
};

const customTextFieldStyles = () => ({
  width: "100%",
  fontFamily: "Rubik",
  "& .MuiOutlinedInput-root ": {
    "@media only screen and (max-width: 1400px)": {
      fontSize: 14,
    },
    [`&.Mui-focused fieldset`]: {
      borderColor: "#6029B1",
    },
  },
  [`& fieldset`]: {
    borderRadius: 2,
  },
  [`& label.Mui-focused`]: {
    color: "#6029B1",
  },
});

const PasswordInput: React.FC = (): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    values: { password },
    handleChange,
    touched,
    errors,
  } = useFormikContext<PasswordProps>();

  return (
    <FormControl variant="outlined" fullWidth>
      <TextField
        variant="outlined"
        id="standard-outlined-password"
        label="Senha"
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={handleChange("password")}
        inputProps={{
          autoComplete: "current-password",
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="mostrar senha"
                edge="end"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={touched.password && Boolean(errors.password)}
        helperText={touched.password && errors.password}
        FormHelperTextProps={{
          style: formHelperStyle,
        }}
        sx={customTextFieldStyles}
      />
    </FormControl>
  );
};

export default PasswordInput;
