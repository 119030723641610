import { AxiosResponse } from 'axios';
import { BASE_URL } from '../api';
import { request } from '../base-request/base-request';
import { IMenuItem } from '../interfaces';

interface IItem {
	price?: number;
	priceId?: string;
	itemImage?: string;
	available?: string;
}

export const patchItemById = async (
	itemId: string,
	item: IItem
): Promise<AxiosResponse<IMenuItem>> => {
	const { available, itemImage, price, priceId } = item;
	try {
		return request({
			url: `${BASE_URL}/itens/${itemId}`,
			method: 'patch',
			data: { available, itemImage, price, priceId },
		});
	} catch (error) {
		return Promise.reject(error);
	}
};
