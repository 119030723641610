import CloseIcon from '@mui/icons-material/Close';
import { Box, DialogTitle, MenuItem, useTheme } from '@mui/material';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import React, { memo, useEffect, useRef, useState } from 'react';
import { ReactComponent as ComplementsGroupIcon } from '../../../../../assets/images/complementsGroup.svg';
import { getItemCategory } from '../../../../../utils/api/get/getItemCategory';
import { getMenuItensByCategory } from '../../../../../utils/api/get/getMenuItensByCategory';
import { IMenu, IMenuItem } from '../../../../../utils/api/interfaces';
import {
	StyledButton,
	StyledButtonContainer,
	StyledCategoriesTextField,
	StyledDialog,
	StyledIconButton,
	StyledSelectContainer,
	StyledTypography,
} from './styles';

interface CopyOptionsModalProps {
	openCopyOptionsModal: boolean;
	handleCloseCopyOptionsModal: () => void;
	setItemCopiedId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const CopyOptionsModal: React.FC<CopyOptionsModalProps> = ({
	openCopyOptionsModal,
	handleCloseCopyOptionsModal,
	setItemCopiedId,
}: CopyOptionsModalProps): JSX.Element => {
	const [categories, setCategories] = useState<
		Pick<IMenu, 'name' | 'id' | 'items_count'>[]
	>([]);
	const [copyCategoryId, setCopyCategoryId] = useState<string | undefined>(
		undefined
	);
	const [itemCategories, setItemCategories] = useState<
		Pick<IMenuItem, 'name' | 'id' | 'has_option_group'>[]
	>([]);
	const [copyItemId, setCopyItemId] = useState<string | undefined>(undefined);
	const theme = useTheme();
	const unsubscribe = useRef<boolean>();
	const { enqueueSnackbar } = useSnackbar();

	const onCloseModal = (): void => {
		handleCloseCopyOptionsModal();
	};

	const handleCloseModal = (): void => {
		handleCloseCopyOptionsModal();
		setItemCopiedId(copyItemId);
	};

	const getProfileCategories = (): void => {
		getItemCategory()
			.then((res) => {
				setCategories(res?.data);
			})
			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	const getItemCategories = (id: string): void => {
		getMenuItensByCategory(id).then((res) => {
			setItemCategories(res?.data);
		});
	};

	useEffect(() => {
		unsubscribe.current = true;
		if (unsubscribe) {
			getProfileCategories();
			return () => {
				unsubscribe.current = false;
			};
		}
	}, []);

	useEffect(() => {
		unsubscribe.current = true;
		if (unsubscribe && copyCategoryId) {
			getItemCategories(copyCategoryId);
			return () => {
				unsubscribe.current = false;
			};
		}
	}, [copyCategoryId, setCopyCategoryId]);

	return (
		<StyledDialog
			open={openCopyOptionsModal}
			onClose={onCloseModal}
			fullWidth
			maxWidth="sm"
		>
			<StyledIconButton onClick={onCloseModal}>
				<CloseIcon />
			</StyledIconButton>
			<Box display="flex">
				<ComplementsGroupIcon />
				<Box>
					<DialogTitle
						alignSelf={'start'}
						fontSize={'18px'}
						padding={'0px 16px 0px 16px!important'}
					>
						Copiar grupo de complementos
					</DialogTitle>
					<DialogTitle
						alignSelf={'start'}
						fontSize={'14px'}
						color={theme.palette.neutral.dark1}
						mb={2}
						padding={'0px 16px 24px 16px!important'}
					>
						Selecione um item do seu cardápio para copiar o grupo de
						complementos
					</DialogTitle>
				</Box>
			</Box>
			<StyledSelectContainer>
				<StyledCategoriesTextField
					select
					id="outlined-basic"
					type="text"
					value={copyCategoryId ?? ''}
					defaultValue={''}
					label="Selecione a Categoria"
					variant="outlined"
					onChange={(event) => setCopyCategoryId(event.target.value)}
					SelectProps={{
						MenuProps: {
							sx: { maxHeight: '20%' },
						},
					}}
				>
					{categories.map(
						(categories: Pick<IMenu, 'name' | 'id' | 'items_count'>) => (
							<MenuItem
								key={categories.id}
								value={categories.id}
								disabled={categories.items_count === 0}
							>
								{categories.name}
							</MenuItem>
						)
					)}
				</StyledCategoriesTextField>
				<StyledCategoriesTextField
					select
					id="outlined-basic"
					type="text"
					value={copyItemId ?? ''}
					defaultValue={''}
					label="Selecione o item"
					variant="outlined"
					onChange={(event) => setCopyItemId(event.target.value)}
					disabled={copyCategoryId ? false : true}
					SelectProps={{
						MenuProps: {
							sx: { maxHeight: '30%' },
						},
					}}
				>
					{itemCategories.map(
						(
							itemCategories: Pick<
								IMenuItem,
								'name' | 'id' | 'has_option_group'
							>
						) => (
							<MenuItem
								key={itemCategories.id}
								value={itemCategories.id}
								disabled={!itemCategories.has_option_group}
							>
								{itemCategories.name}
							</MenuItem>
						)
					)}
				</StyledCategoriesTextField>
			</StyledSelectContainer>
			<StyledButtonContainer>
				<StyledButton onClick={handleCloseModal}>
					<StyledTypography>Copiar Grupo</StyledTypography>
				</StyledButton>
			</StyledButtonContainer>
		</StyledDialog>
	);
};

export default memo(CopyOptionsModal);
