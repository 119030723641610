import {
  CepAndUFAndCiyt,
  Container,
  DistrictAndComplement,
  InputCep,
  InputCity,
  InputComplement,
  InputDistrict,
  InputNumber,
  InputStreet,
  InputUF,
  StreetAndNumber,
} from "./styles";

const InputContentAddressIfSelected: React.FC = (): JSX.Element => (
  <Container>
    <CepAndUFAndCiyt>
      <InputCep id="cep" label="Cep" variant="outlined" />
      <InputUF id="uf" label="UF" variant="outlined" />
      <InputCity id="city" label="Cidade" variant="outlined" />
    </CepAndUFAndCiyt>
    <StreetAndNumber>
      <InputStreet id="street" label="Rua" variant="outlined" />
      <InputNumber id="number" label="Numero" variant="outlined" />
    </StreetAndNumber>
    <DistrictAndComplement>
      <InputDistrict id="district" label="Bairro" variant="outlined" />
      <InputComplement id="complement" label="Complemento" variant="outlined" />
    </DistrictAndComplement>
  </Container>
);

export default InputContentAddressIfSelected;
