import { Box, styled, TextField, Typography } from '@mui/material';

export const StyledValueTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		width: 100,
		height: 48,
		fontFamily: 'Rubik',
		'@media only screen and (max-width: 1400px)': {
			width: 115,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'@media only screen and (max-width: 1400px)': {
		marginRight: 20,
		'& .MuiFormLabel-root': {
			fontSize: '13px',
		},
		'& .MuiFormHelperText-root': {
			fontSize: '9px!important',
			whiteSpace: 'nowrap',
		},
	},
}));

export const ImageInput = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '65px',
	height: '65px',
	mr: 2,
	border: `1px solid${theme.palette.neutral.dark2}`,
	borderRadius: 8,
}));

export const WrapperItemName = styled(Box)(() => ({
	marginLeft: 15,
}));

export const ItemName = styled(Typography)(({ theme }) => ({
	fontSize: '18px',
	fontWeight: 500,
	fontFamily: 'Rubik',
	color: theme.palette.common.black,
}));

export const PreviewDetails = styled(Typography)(({ theme }) => ({
	fontSize: '16px',
	fontWeight: 400,
	fontFamily: 'Rubik',
	color: theme.palette.neutral.dark1,
}));

export const ImageContent = styled(Box)(() => ({
	width: '65px',
	height: '65px',
	borderRadius: '8px',
	marginRight: '8px',
}));

export const WrapperTitle = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
}));

export const ComplementsGroupTitle = styled(Typography)(() => ({
	fontSize: 20,
	fontWeight: 500,
	fontFamily: 'Rubik',
	marginLeft: 5,
}));

export const BoxForLoadingGroups = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
	justifyContent: 'center',
	alignItems: 'center',
}));

export const BoxForEmptyGroup = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
	justifyContent: 'center',
	alignItems: 'center',
}));

export const EmptyTitle = styled(Typography)(() => ({
	fontSize: 16,
	fontWeight: 400,
	fontFamily: 'Rubik',
}));
