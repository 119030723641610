import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Store } from 'redux';
import {
	refreshTokenService,
	setAuthToken,
} from '../../../shared/helpers/refreshTokenService';

export const request = async ({
	url,
	data,
	method,
	headers,
	params,
}: AxiosRequestConfig): Promise<AxiosResponse<any>> => {
	const response = await axios({
		method,
		url,
		data,
		headers,
		params,
	});
	return response;
};

interface QueuedRequest {
	resolve: (value: AxiosResponse<any>) => void;
	reject: (reason?: any) => void;
	config: AxiosRequestConfig;
}

let isRefreshing = false;
let requestsQueue: QueuedRequest[] = [];

const processQueue = (token: string | null, error: any = null) => {
	requestsQueue.forEach((pendingReq) => {
		if (error) {
			pendingReq.reject(error);
		} else if (token) {
			pendingReq.config.headers = pendingReq.config.headers || {};
			pendingReq.config.headers['Authorization'] = `Bearer ${token}`;
			axios(pendingReq.config).then(pendingReq.resolve, pendingReq.reject);
		}
	});
	requestsQueue = []; // Clear the queue after processing
};

export const axiosInterceptors = (store: Store): void => {
	axios.interceptors.request.use(
		(request) => {
			const accessToken = store.getState().auth.accessToken;
			if (accessToken) {
				request.headers = request.headers || {};
				request.headers['Authorization'] = `Bearer ${accessToken}`;
			}
			return request;
		},
		(error) => Promise.reject(error)
	);
};

export const axiosResponseInterceptors = (store: Store): void => {
	axios.interceptors.response.use(
		(response) => response,
		async (error) => {
			const originalRequest = error.config;
			const status = error.response ? error.response.status : null;
			const state = store.getState();
			const advertiser_id = state.auth.advertiser_id;

			if (status === 401 && !originalRequest._retry) {
				originalRequest._retry = true; // Mark this request as already retried

				if (!isRefreshing) {
					const refreshTokenValue = localStorage.getItem('refresh_token');
					if (refreshTokenValue) {
						isRefreshing = true;
						try {
							const accessToken = await refreshTokenService(
								store.dispatch,
								refreshTokenValue,
								advertiser_id
							);
							setAuthToken(accessToken);
							processQueue(accessToken);
							isRefreshing = false;
							return axios(originalRequest);
						} catch (refreshError) {
							processQueue(null, refreshError);
							isRefreshing = false;
							return Promise.reject(refreshError);
						}
					} else {
						isRefreshing = false;
						localStorage.clear();
						window.location.href = '/login';
						return Promise.reject('No refresh token available');
					}
				} else {
					return new Promise((resolve, reject) => {
						requestsQueue.push({
							resolve: resolve,
							reject: reject,
							config: originalRequest,
						});
					});
				}
			}

			return Promise.reject(error);
		}
	);
};
