import React from 'react';
import { useNavigate } from 'react-router-dom';

import NavButton from '../../Buttons/NavButton/NavButton';
import { StyledButtonBox } from '../Introduction/styles';
import {
	StyledContainer,
	StyledSecondGridImg,
	StyledSecondGridImgCellphone,
	StyledText,
	StyledTextContainer,
	StyledTitleText,
	SvgContainer,
} from './styles';

const FirstAnnouncement: React.FC = (): JSX.Element => {
	const navigate = useNavigate();
	return (
		<StyledContainer>
			<SvgContainer>
				<StyledSecondGridImg />
				<StyledSecondGridImgCellphone />
			</SvgContainer>
			<StyledTextContainer>
				<StyledTitleText>Com você em todo o processo de venda!</StyledTitleText>
				<StyledText>
					Cadastre sua loja, venda seus produtos, poste promoções com cupons e
					acompanhe dados estatísticos em tempo real!
				</StyledText>
				<StyledButtonBox onClick={() => navigate('/sign-up')}>
					<NavButton isPurple={true}>Cadastrar loja</NavButton>
				</StyledButtonBox>
			</StyledTextContainer>
		</StyledContainer>
	);
};

export default FirstAnnouncement;
