import { AxiosResponse } from "axios";
import { request } from "../base-request/base-request";

export const getCitiesByState = async (
  uf: string
): Promise<AxiosResponse<any> | undefined> => {
  try {
    return request({
      url: `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios?orderBy=nome`,
      method: "get",
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
