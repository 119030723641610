import { Box, TextField, styled } from '@mui/material';

export const StyledDeliveryContainer = styled(Box)(() => ({
	display: 'flex',
	height: 44,
	maxHeight: 44,
	marginTop: 20,
}));

export const StyledDelivery = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
}));

export const StyledNameTextFieldContainer = styled(Box)(() => ({
	display: 'flex',
	marginLeft: 16,
	alignItems: 'center',
	width: '34.3%',
}));

export const StyledNameTextField = styled(TextField)(() => ({
	fontFamily: 'Rubik',
	fontWeight: 400,
	fontSize: 16,
	lineHeight: '20px',

	'& .MuiOutlinedInput-root ': {
		borderRadius: '8px 0px 0px 8px',
		height: 60,
		minHeight: 60,

		'@media only screen and (max-width: 1400px)': {
			fontSize: 12,
			height: 50,
			minHeight: 50,
		},
		'@media only screen and (max-width: 1200px)': {
			height: 40,
			minHeight: 40,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'@media only screen and (max-width: 1400px)': {
		'& .MuiFormLabel-root': {
			fontSize: '13px',
		},
	},
}));

export const StyledValueTextFieldContainer = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	width: '16.8%',
}));

export const StyledValueTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 44,
		fontFamily: 'Rubik',
		fontSize: '13px',
		marginLeft: 8,

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'@media only screen and (max-width: 1400px)': {
		marginRight: 20,
		'& .MuiFormLabel-root': {
			fontSize: '13px',
		},
		'& .MuiFormHelperText-root': {
			fontSize: '9px!important',
			whiteSpace: 'nowrap',
		},
	},
}));

export const StyledTimeTextFieldContainer = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	width: '17.2%',
}));

export const StyledTimeTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 44,
		fontFamily: 'Rubik',
		fontSize: '13px',
		marginLeft: 10,

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'@media only screen and (max-width: 1400px)': {
		marginRight: 20,
		'& .MuiFormLabel-root': {
			fontSize: '13px',
		},
		'& .MuiFormHelperText-root': {
			fontSize: '9px!important',
			whiteSpace: 'nowrap',
		},
	},
}));

export const StyledSwitchContainer = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '25%',
}));
