import { Pagination, Skeleton } from "@mui/material";
import React from "react";
import { ICouponList } from "../../../pages/Coupons/CouponsManager/interface/couponList-props.interface";
import CouponListCard from "./CouponListCard/CouponListCard";

import BigEmptyCard from "../../BigEmptyCard/BigEmptyCard";
import { CouponListProps } from "./interface/couponList-props.interface";
import {
  StyledAllCouponsContainer,
  StyledCouponInformationContainer,
  StyledCouponList,
  StyledCouponListCard,
  StyledEmptyList,
  StyledIconContainer,
  StyledImageContainer,
  StyledInformationContainer,
  StyledInformationContainerText,
  StyledInformationText,
  StyledInformationTitle,
  StyledPagination,
  StyledPromotionImage,
  StyledPromotionTitle,
  StyledSkeletonTitle,
  StyledSkeletonUsername,
  StyledUseCouponButton,
  StyledUseCouponButtonContainer,
  StyledUserImage,
  StyledUsername,
} from "./styles";

const CouponListSkeleton: React.FC = (): JSX.Element => {
  return (
    <StyledCouponListCard>
      <StyledImageContainer>
        <StyledUserImage>
          <Skeleton
            sx={{ height: "100%", width: "100%" }}
            animation="wave"
            variant="rectangular"
          />
        </StyledUserImage>

        <StyledPromotionImage>
          <Skeleton
            sx={{ height: "100%", width: "100%" }}
            animation="wave"
            variant="rectangular"
          />
        </StyledPromotionImage>
      </StyledImageContainer>
      <StyledCouponInformationContainer>
        <StyledPromotionTitle>
          <StyledSkeletonTitle animation="wave" variant="rectangular" />
        </StyledPromotionTitle>
        <StyledUsername>
          <StyledSkeletonUsername animation="wave" variant="rectangular" />
        </StyledUsername>
      </StyledCouponInformationContainer>
      <StyledInformationContainer>
        <StyledIconContainer>
          <Skeleton
            sx={{ height: "100%", width: "100%" }}
            animation="wave"
            variant="rectangular"
          />
        </StyledIconContainer>
        <StyledInformationContainerText>
          <StyledInformationTitle>
            <Skeleton
              sx={{ height: "100%", width: "100%" }}
              animation="wave"
              variant="rectangular"
            />
          </StyledInformationTitle>
          <StyledInformationText>
            <Skeleton
              sx={{ height: "100%", width: "100%" }}
              animation="wave"
              variant="rectangular"
            />
          </StyledInformationText>
        </StyledInformationContainerText>
      </StyledInformationContainer>

      <StyledInformationContainer>
        <StyledIconContainer>
          <Skeleton
            sx={{ height: "100%", width: "100%" }}
            animation="wave"
            variant="rectangular"
          />
        </StyledIconContainer>

        <StyledInformationContainerText>
          <StyledInformationTitle>
            <Skeleton
              sx={{ height: "100%", width: "100%" }}
              animation="wave"
              variant="rectangular"
            />
          </StyledInformationTitle>
          <StyledInformationText>
            <Skeleton
              sx={{ height: "100%", width: "100%" }}
              animation="wave"
              variant="rectangular"
            />
          </StyledInformationText>
        </StyledInformationContainerText>
      </StyledInformationContainer>

      <StyledUseCouponButtonContainer>
        <StyledUseCouponButton>
          <Skeleton
            sx={{ height: "100%", width: "100%", borderRadius: 1 }}
            animation="wave"
            variant="rectangular"
          />
        </StyledUseCouponButton>
      </StyledUseCouponButtonContainer>
    </StyledCouponListCard>
  );
};

const CouponList: React.FC<CouponListProps> = ({
  coupon,
  totalPages,
  currentPage,
  handleCurrentPageChange,
  loader,
  setCoupon,
  params,
  setCouponListLoader,
}: CouponListProps): JSX.Element => (
  <StyledAllCouponsContainer>
    {loader ? (
      <StyledCouponList>
        {Array(5)
          .fill(null)
          .map((_, index) => (
            <CouponListSkeleton key={index} />
          ))}
      </StyledCouponList>
    ) : (
      <>
        {coupon.length > 0 && (
          <StyledCouponList>
            {coupon.map((coupon: ICouponList) => (
              <CouponListCard
                data={coupon}
                key={coupon.id}
                setCoupon={setCoupon}
                params={params}
                setCouponListLoader={setCouponListLoader}
              />
            ))}
          </StyledCouponList>
        )}
        {coupon.length === 0 && (
          <StyledEmptyList>
            <BigEmptyCard
              text={
                "Você ainda não tem nenhum cupom resgatado. Continue engajando seus clientes :)"
              }
              image={"promotion"}
            />
          </StyledEmptyList>
        )}
      </>
    )}
    <StyledPagination>
      {totalPages && !loader ? (
        <Pagination
          shape="rounded"
          count={totalPages}
          page={currentPage}
          onChange={handleCurrentPageChange}
          color="primary"
          sx={{ margin: "auto" }}
          showFirstButton
          showLastButton
        />
      ) : null}
    </StyledPagination>
  </StyledAllCouponsContainer>
);

export default CouponList;
