import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as ExpandMoreIcon } from "../../../assets/images/accordionExpandIcon.svg";
import {
  FifthGridFirstRow,
  FifthGridFirstRowTitle,
  FifthGridSecondRow,
  StyledAccordion,
  StyledAccordionInsideText,
  StyledAccordionText,
  StyledContainer,
  StyledQuestions,
} from "./styles";

const CommomQuestions: React.FC = (): JSX.Element => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) =>
    (event: React.SyntheticEvent, isExpanded: boolean): void => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <StyledContainer>
      <FifthGridFirstRow>
        <FifthGridFirstRowTitle>Perguntas frequentes</FifthGridFirstRowTitle>
      </FifthGridFirstRow>
      <FifthGridSecondRow>
        <StyledQuestions>
          <StyledAccordion>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={{
                borderRadius: "24px!important",
                boxShadow: "none",
                border: "1px solid",
                borderColor: "#C5C5C5",
                paddingX: "32px",
                paddingY: "15px",
                "@media (orientation: portrait)": {
                  paddingX: "5px",
                  paddingY: "15px",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <StyledAccordionText>
                  Preciso pagar para cadastrar minha loja?
                </StyledAccordionText>
              </AccordionSummary>
              <AccordionDetails>
                <StyledAccordionInsideText>
                  Não, criar o perfil da sua loja no d$conto é gratuito e não é
                  necessário assinar nenhum plano. No entanto, futuramente,
                  somente usuários com planos ativos poderão criar promoções na
                  nossa plataforma.
                </StyledAccordionInsideText>
              </AccordionDetails>
            </Accordion>
          </StyledAccordion>
          <StyledAccordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              sx={{
                borderRadius: "24px!important",
                boxShadow: "none",
                border: "1px solid",
                borderColor: "#C5C5C5",
                paddingX: "32px",
                paddingY: "15px",
                "@media (orientation: portrait)": {
                  paddingX: "20px",
                  paddingY: "15px",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{
                  "  @media (orientation: portrait)": {
                    padding: 0,
                  },
                }}
              >
                <StyledAccordionText>
                  Como funciona a assinatura dos planos?
                </StyledAccordionText>
              </AccordionSummary>
              <AccordionDetails>
                <StyledAccordionInsideText>
                  A assinatura dos planos ainda não está disponível no d$conto.
                  Por tempo limitado você pode aproveitar todos os benefícios da
                  plataforma de forma gratuita! Cadastre sua loja agora mesmo 🙂
                </StyledAccordionInsideText>
              </AccordionDetails>
            </Accordion>
          </StyledAccordion>
          <StyledAccordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              sx={{
                borderRadius: "24px!important",
                boxShadow: "none",
                border: "1px solid",
                borderColor: "#C5C5C5",
                paddingX: "32px",
                paddingY: "15px",
                "@media (orientation: portrait)": {
                  paddingX: "5px",
                  paddingY: "15px",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <StyledAccordionText>
                  O dsconto é responsável pela venda dos produtos?
                </StyledAccordionText>
              </AccordionSummary>
              <AccordionDetails>
                <StyledAccordionInsideText>
                  Não, o dsconto não é responsável por vender seus produtos.
                  Nosso aplicativo é uma plataforma que permite aos comerciantes
                  anunciarem seus produtos em promoção e oferecer cupons de
                  desconto para os usuários. Nós não intermediamos as vendas ou
                  transações financeiras entre os anunciantes e seus clientes. O
                  dsconto é apenas um facilitador, permitindo que você alcance
                  uma audiência maior e aumente sua visibilidade, além de
                  oferecer aos usuários uma forma fácil de resgatar seus cupons
                  e aproveitar suas promoções.
                </StyledAccordionInsideText>
              </AccordionDetails>
            </Accordion>
          </StyledAccordion>
        </StyledQuestions>
      </FifthGridSecondRow>
    </StyledContainer>
  );
};

export default CommomQuestions;
