import { ReactComponent as NoOrderIcon } from '../../../assets/images/noOrderIcon.svg';

import {
	WrapperHistoryDay,
	Title,
	MiddleContent,
	QuantityOrders,
	TotalSum,
	AlignIcon,
} from './styles';

const NoOrders: React.FC = (): JSX.Element => {
	return (
		<>
			<WrapperHistoryDay>
				<Title>Hoje</Title>
				<MiddleContent>
					<QuantityOrders>0 pedidos • </QuantityOrders>
					<TotalSum>Total bruto: R$ 0,00</TotalSum>
				</MiddleContent>
			</WrapperHistoryDay>
			<AlignIcon>
				<NoOrderIcon />
			</AlignIcon>
		</>
	);
};

export default NoOrders;
