import { Box, Button, styled, Typography } from "@mui/material";

export const StyledButton = styled(Button)(({ theme }) => ({
  height: 72,
  width: "100%",
  marginBottom: 20,
  borderRadius: 12,
  boxShadow: "11px 24px 40px rgba(96, 41, 177, 0.35)",
  padding: "22px 30px 22px 30px",
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));
export const StyledButtonIcon = styled(Box)(({ theme }) => ({
  height: 22,
  width: 22,
  color: theme.palette.neutral.light,
}));

export const StyledButtonTypography = styled(Typography)(({ theme }) => ({
  textTransform: "none",
  fontSize: 24,
  color: theme.palette.neutral.light,
  fontFamily: "Rubik",
  fontWeight: 500,
  marginLeft: 14,
  "@media only screen and (max-width: 1400px)": {
    fontSize: 16,
  },
  "@media only screen and (max-width: 1200px)": {
    fontSize: 14,
  },
}));
