import { BASE_URL } from '../api';
import { request } from '../base-request/base-request';
import { IMenu } from '../interfaces';

export const getItemByName = async (
	id: string,
	name: string
): Promise<IMenu[]> => {
	try {
		const response = await request({
			url: id
				? `${BASE_URL}/showcaseCategories/menuResponse?name=${name}&categoryId=${id}`
				: `${BASE_URL}/showcaseCategories/menuResponse?name=${name}`,
			method: 'get',
		});
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
