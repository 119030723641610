import {
	styled,
	Box,
	Typography,
	Button,
	Accordion,
	AccordionSummary,
	TableCell,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const StyledHeaderOptionsCell = styled(TableCell)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	borderBottom: 'none',
	marginTop: '2%',
}));

export const WrapperTitle = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
}));

export const ComplementsGroupTitle = styled(Typography)(() => ({
	fontSize: 20,
	fontWeight: 500,
	fontFamily: 'Rubik',
	marginLeft: 5,
}));

export const AccordionStyled = styled(Accordion)(() => ({
	border: '1px solid #F1F1F1',
	borderRadius: '8px!important',
	marginTop: '20px',

	'&::before': {
		backgroundColor: 'transparent',
	},
}));

export const AccordionSummaryStyled = styled(AccordionSummary)(({ theme }) => ({
	backgroundColor: theme.palette.neutral.light1,
	borderTopLeftRadius: '8px',
	borderTopRightRadius: '8px',

	'& .css-i4bv87-MuiSvgIcon-root': {
		fontSize: '2rem',
	},
}));

export const ComplementWrapper = styled(Box)(({ theme }) => ({
	marginTop: '20px',
	border: `2px solid ${theme.palette.neutral.dark2}`,
	borderRadius: '8px',
}));

export const BoxTitle = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
	alignItems: 'center',
	padding: '0px 20px',
	borderTopLeftRadius: '8px',
	borderTopRightRadius: '8px',
	justifyContent: 'space-between',
}));

export const InitialMenuIcon = styled(MenuIcon)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: '20px',
}));

export const InitialBox = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	width: '30%',
}));

export const ComplementName = styled(Typography)(({ theme }) => ({
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.common.black,
}));
export const BoxContent = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	height: '110px',
}));

export const ImageAndText = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	width: '30%',
}));

export const WrapperContent = styled(Box)(() => ({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: '0px 20px',
}));

export const SubName = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.common.black,
}));

export const EndBox = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	width: '8%',
}));

export const OptionButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.neutral.light3,
	textTransform: 'initial',
	padding: '10px 30px',
}));

export const MoreIcon = styled(MoreVertIcon)(({ theme }) => ({
	color: theme.palette.neutral.dark,
	width: '20px',
}));

export const OptionButtonText = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.primary.main,
}));

export const ArrowUpIcon = styled(KeyboardArrowUpIcon)(({ theme }) => ({
	color: theme.palette.primary.main,
}));

export const WrapperIcons = styled(Box)(() => ({
	cursor: 'pointer',
}));

export const ImageItem = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '120px',
	height: '80px',
	borderRadius: '8px',
	border: `1px solid ${theme.palette.neutral.dark2}`,
}));

export const NameAndSubtitle = styled(Box)(() => ({
	width: '100%',
	marginLeft: '2%',
}));

export const Img = styled('img')(() => ({
	width: '100%',
	height: '100%',
	borderRadius: '8px',
}));

export const ContentName = styled(Typography)(({ theme }) => ({
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.common.black,
}));
export const ContentSubName = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
}));

export const WrapperPrice = styled(Box)(() => ({}));

export const PriceText = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
}));
export const Price = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.common.black,
}));

export const WrapperButton = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'flex-end',
	width: '100%',
	paddingTop: '50px',
}));

export const CreateButton = styled(Button)(({ theme }) => ({
	width: 272,
	height: 56,
	border: `1px solid ${theme.palette.neutral.dark2}`,
	borderRadius: 8,
	textTransform: 'initial',
}));

export const ButtonText = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.primary.main,
}));

export const TextButton = styled(Button)(() => ({
	textTransform: 'initial',
}));

export const LinkText = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontWeight: 700,
	fontFamily: 'Rubik',
	color: theme.palette.primary.main,
}));
