import {
	Box,
	Button,
	FormControl,
	RadioGroup,
	TextField,
	Typography,
	styled,
} from '@mui/material';

import ErrorIcon from '@mui/icons-material/Error';
import { ReactComponent as BigEmptyIcon } from '../../../../assets/images/bigEmptyImg.svg';
import { PreviewCardBackground } from '../../../../pages/AddItem/Interfaces/interfaces';

export const TitleAndButton = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: '20px 0px 30px 0px',
}));

export const TipsButton = styled(Button)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: 200,
	height: 52,
	borderRadius: 8,
	backgroundColor: theme.palette.primary.main,
}));

export const ButtonText = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 700,
	marginLeft: '10px',
	color: theme.palette.common.white,
	textTransform: 'initial',
}));

export const MiddleNotice = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	width: '97%',
	height: '45px',
	justifyContent: 'space-between',
	marginTop: '2%',
	borderRadius: '8px',
	padding: '10px 20px 10px 20px',
	border: `1px solid ${theme.palette.info.main}`,
	borderTop: `3px solid${theme.palette.info.main}`,
}));

export const InfoBox = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
}));

export const InfoIcon = styled(ErrorIcon)(({ theme }) => ({
	fontSize: 16,
	color: theme.palette.info.main,
}));

export const TextInfo = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 500,
	marginLeft: '15px',
	color: theme.palette.info.main,
}));

export const ScreeenTitles = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 500,
	marginTop: '2%',
	color: theme.palette.common.black,
}));

export const SubtitlePhoto = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.common.black,
}));

export const PhotoContainer = styled(Box)(() => ({
	display: 'flex',
	maxHeight: 275,
}));

export const Input = styled('input')({
	display: 'none',
});

export const PhotoItem = styled(Box)(({ theme }) => ({
	width: 462,
	height: 275,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: 20,
	backgroundColor: theme.palette.neutral.light2,
}));

export const StyledLabel = styled('label')(() => ({
	width: '60%',
	height: '275px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
}));

export const ChoseButton = styled(Box)(({ theme }) => ({
	width: '100%',
	height: '15%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginTop: '10px',
	padding: '0px 20px 0px 20px',
	borderRadius: '8px',
	textTransform: 'initial',
	border: `2px dashed ${theme.palette.neutral.dark2}`,
	cursor: 'pointer',
}));

export const StyledRemoveImageIcon = styled(Box)(() => ({
	cursor: 'pointer',
	position: 'absolute',
	top: 10,
	right: 10,
}));

export const StyledPreviewImg = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'isEmpty',
})<PreviewCardBackground>(({ theme }) => ({
	width: 462,
	height: 275,
	display: 'flex',
	backgroundColor: theme.palette.neutral.light6,

	borderRadius: 20,
	position: 'relative',
	'& img': {
		height: '100%',
		width: '100%',
		objectFit: 'scale-down',
	},
}));

export const StyledBigEmptyIcon = styled(BigEmptyIcon)(() => ({
	'@media only screen and (max-width: 1200px)': {
		height: 40,
		width: 124,
	},
}));

export const TextButton = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
}));

export const StyledCategoriesTextField = styled(TextField)(() => ({
	width: 274,
	marginLeft: '5%',
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 45,
		fontFamily: 'Rubik',
		fontWeight: 400,
		fontSize: 14,

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'& .MuiFormLabel-root': {
		['& .MuiInputLabel-root']: {
			lineHeight: '0px',
		},
	},
}));

export const DeliveryPrice = styled(TextField)(() => ({
	width: 211,
	marginLeft: '2%',
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 45,
		fontFamily: 'Rubik',
		fontWeight: 400,
		fontSize: '14px',

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},

	'@media only screen and (max-width: 1400px)': {
		'& .MuiFormLabel-root': {
			fontSize: '13px',
		},
	},
}));

export const WrapperInputs = styled(Box)(() => ({
	width: '100%',
	display: 'flex',
	maxHeight: '15%',
}));

export const RightSide = styled(Box)(() => ({
	width: '100%',
	maxHeight: '20%',
	marginLeft: '2%',
}));

export const ItemName = styled(TextField)(() => ({
	width: '60%',
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 45,
		fontFamily: 'Rubik',
		fontWeight: 400,

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'@media only screen and (max-width: 1400px)': {
		'& .MuiFormLabel-root': {
			fontSize: '13px',
		},
	},
}));

export const ItemDescription = styled(TextField)(() => ({
	width: '100%',
	marginTop: '2%',
	'& .MuiOutlinedInput-root ': {
		display: 'flex',
		alignItems: 'flex-start',
		borderRadius: 8,
		height: 200,
		maxHeight: 200,
		fontFamily: 'Rubik',
		fontWeight: 400,

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},

	'@media only screen and (max-width: 1400px)': {
		marginTop: '3%',
		'& .MuiFormLabel-root': {
			fontSize: '13px',
			textAlign: 'center',
			lineBreak: 'auto',
		},
	},
}));

export const StyledFieldCount = styled(Box)(({ theme }) => ({
	marginTop: 8,
	marginRight: 10,
	fontFamily: 'Rubik',
	fontWeight: 500,
	position: 'absolute',
	bottom: 0,
	right: 0,
	fontSize: 12,
	color: theme.palette.neutral.dark2,
	textAlign: 'end',
	pointerEvents: 'none',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 8,
	},
}));

export const FormControlBox = styled(FormControl)(() => ({
	width: '100%',
}));

export const ScreenSubtitle = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 400,
	marginTop: '10px',
	color: theme.palette.common.black,
}));

export const RadioGroupBox = styled(RadioGroup)(() => ({
	display: 'flex',
	width: '75%',
	flexDirection: 'row',
	justifyContent: 'space-between',
}));

export const WrapperRadioGroup = styled(Box)(({ theme }) => ({
	width: 198,
	height: 100,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center ',
	padding: '10px ',
	marginTop: '30px',
	border: `1px solid ${theme.palette.neutral.dark2}`,
	borderRadius: 20,

	'@media only screen and (max-width: 1800px)': {
		width: 130,
		height: 60,
	},

	'& .css-ahj2mt-MuiTypography-root': {
		fontSize: '18px',
		fontFamily: 'Rubik',
		fontWeight: 400,
	},
}));
