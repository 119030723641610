import { Box, styled } from "@mui/material";
import { IInputProps } from "./interfaces";

export const Container = styled(Box)(({ theme }) => ({
  display: "list-item",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.neutral.light2,
}));

export const StyledDashboardTopBar = styled(Box)(() => ({
  display: "flex",
  width: "100%",
}));

export const ContainerContent = styled(Box)<IInputProps>(
  ({ theme, valueAddress, valueCard, editingAddress, editingCard }) => ({
    display: "flex",
    width: "93%",
    height: "72%",
    overflow:
      valueAddress === "addAddress" ||
      valueCard === "addCard" ||
      editingAddress ||
      editingCard
        ? "auto"
        : "",
    marginTop: "80px",
    marginLeft: "44px",
    borderRadius: "20px",
    backgroundColor: theme.palette.neutral.light,
  })
);
