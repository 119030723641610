import { Box, Button, TextField, Typography, styled } from '@mui/material';
import { Ibuttons } from '../interfaces';

export const Container = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
	height: '100%',
}));

export const StyledMenuContainer = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.neutral.light2,
	width: '100%',
	height: '100%',
}));

export const StyledMenuTopBar = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
}));

export const StyledMenuContent = styled(Box)(({ theme }) => ({
	padding: '32px 44px 0px 44px',
	backgroundColor: theme.palette.neutral.light2,

	'@media (max-width: 1200px)': {
		padding: '24px 20px 0px 24px',
	},
}));

export const WrapperContainer = styled(Box)(({ theme }) => ({
	display: 'block',
	boxSizing: 'border-box',
	width: '100%',
	backgroundColor: theme.palette.neutral.light,
	padding: '36px 44px 44px 44px',
	borderRadius: 20,
	minHeight: '749px',

	'@media (max-width: 1400px)': {
		minHeight: '490px',
	},
	'@media (max-width: 1200px)': {
		padding: '19px 20px 19px 20px',
	},
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
	fontSize: 20,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.neutral.dark,
}));

export const CategoryName = styled(TextField)(() => ({
	marginTop: '2%',
	width: '100%',
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 58,
		fontFamily: 'Rubik',
		fontWeight: 400,
		fontSize: 18,

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
}));

export const Buttons = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'flex-end',
	marginTop: '2%',
}));
export const CancelButton = styled(Button)(({ theme }) => ({
	width: '157px',
	height: '56px',
	marginRight: '15px',
	borderRadius: '8px',
	border: `1px solid ${theme.palette.primary.main}`,
	textTransform: 'none',
}));
export const Texts = styled(Box)<Ibuttons>(({ theme, propName }) => ({
	fontSize: '16px',
	fontFamily: 'Rubik',
	fontWeight: 600,
	color:
		propName === 'Cancelar'
			? theme.palette.primary.main
			: theme.palette.neutral.light,
}));
export const CreateButton = styled(Button)(({ theme }) => ({
	width: '157px',
	height: '56px',
	borderRadius: '8px',
	textTransform: 'none',
	backgroundColor: theme.palette.primary.main,
}));
