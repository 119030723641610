import React from "react";
import { IStatisticsCard } from "../interface/card-interface";
import {
  StyledNumber,
  StyledNumberContainer,
  StyledNumberSkeleton,
  StyledPeriod,
  StyledViewText,
} from "./styles";

const LikesCard: React.FC<IStatisticsCard> = ({
  itens,
  index,
  indexButton,
}: IStatisticsCard): JSX.Element => (
  <>
    {itens.loader ? (
      <StyledNumber isSelected={index === indexButton}>
        <StyledNumberSkeleton animation="wave" />
      </StyledNumber>
    ) : itens.likes ? (
      <StyledNumberContainer>
        <StyledNumber isSelected={index === indexButton}>
          {itens.likes}
        </StyledNumber>
        <StyledPeriod isSelected={index === indexButton}>
          Total de curtidas
        </StyledPeriod>
      </StyledNumberContainer>
    ) : (
      <StyledViewText isSelected={index === indexButton}>
        Sem estatísticas disponíveis
      </StyledViewText>
    )}
  </>
);
export default LikesCard;
