import { ReactComponent as AddButtonIcon } from "../../../assets/images/AddButtonIcon.svg";
import { ReactComponent as MasterCardIcon } from "../../../assets/images/MasterCardIcon.svg";
import { ReactComponent as TalkToUsIcon } from "../../../assets/images/TalkToUsIcon.svg";

import {
  AlignTalkToUsButton,
  ButtonAddCreditCard,
  ButtonRemove,
  ButtonRemoveText,
  CardWallet,
  CreditCardContent,
  CreditCardHeader,
  CreditCardText,
  FinalCardNumbers,
  RemoveCreditCard,
  SubTitle,
  Texts,
  Title,
} from "./styles";

const FormOfPayment: React.FC = (): JSX.Element => (
  <>
    <Texts>
      <Title>Forma de pagamento</Title>
      <SubTitle>Gerencie seus métodos de pagamento</SubTitle>
    </Texts>
    <CardWallet>
      <RemoveCreditCard>
        <CreditCardHeader>
          <CreditCardText>Cartão de crédito</CreditCardText>
        </CreditCardHeader>
        <CreditCardContent>
          <MasterCardIcon />
          <FinalCardNumbers>**** **** **** 123</FinalCardNumbers>
          <ButtonRemove>
            <ButtonRemoveText>Remover</ButtonRemoveText>
          </ButtonRemove>
        </CreditCardContent>
      </RemoveCreditCard>
      <ButtonAddCreditCard>
        <AddButtonIcon />
      </ButtonAddCreditCard>
    </CardWallet>
    <AlignTalkToUsButton>
      <TalkToUsIcon />
    </AlignTalkToUsButton>
  </>
);

export default FormOfPayment;
