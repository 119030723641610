import { Box, styled } from '@mui/material';
import { ReactComponent as CouponsChartLine } from '../../../assets/images/couponChartLine.svg';
import { ReactComponent as CouponsChartRedLine } from '../../../assets/images/couponsChartRedLine.svg';

export const StyledCouponsStatusDisplay = styled(Box)(({ theme }) => ({
	display: 'block',
	boxSizing: 'border-box',
	width: '100%',
	height: '100%',
	backgroundColor: theme.palette.neutral.light,
	borderBottom: '8px solid #6029B1',
	borderRadius: 12,
	padding: '23px 27px 15px 27px',
	'@media only screen and (max-width: 1500px)': {
		padding: '23px 15px 13px 15px',
	},
}));

export const StyledCouponsChartLineContainer = styled(Box)(() => ({
	display: 'flex',
	marginBottom: 18,
	justifyContent: 'space-between',
}));

export const StyledCouponsNumber = styled(Box)(({ theme }) => ({
	display: 'flex',
	color: theme.palette.neutral.dark,
	fontSize: 24,
	fontFamily: 'Rubik',
	fontWeight: 500,
	lineHeight: '28px',
	marginBottom: 4,
	minHeight: 36,
	'@media only screen and (max-width: 1500px)': {
		fontSize: 18,
		lineHeight: '24px',
		marginBottom: 0,
	},
}));

export const StyledCouponsText = styled(Box)(({ theme }) => ({
	display: 'flex',
	color: theme.palette.neutral.dark1,
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	lineHeight: '20px',
	'@media only screen and (max-width: 1500px)': {
		fontSize: 14,
		lineHeight: '20px',
		textAlign: 'center',
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 12,
		lineHeight: '18px',
		textAlign: 'center',
	},
}));

export const CouponsChartSVG = styled(CouponsChartLine)(() => ({
	'@media only screen and (max-width: 1500px)': {
		width: 70,
	},
}));

export const CouponsChartRedSVG = styled(CouponsChartRedLine)(() => ({
	'@media only screen and (max-width: 1500px)': {
		width: 70,
	},
}));
