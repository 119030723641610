import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Button, Typography, styled } from '@mui/material';

interface ListItemStyledProp {
	isCancelled: boolean;
}

export const Container = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	maxWidth: '100%',
	maxHeight: '100%',
	paddingBottom: '30px',
	borderRadius: '12px',
	backgroundColor: theme.palette.common.white,
}));

export const FilterContent = styled(Box)(() => ({
	padding: '20px',
}));

export const FilterButton = styled(Button)(({ theme }) => ({
	width: '189px',
	height: '52px',
	borderRadius: '8px',
	textTransform: 'initial',
	backgroundColor: theme.palette.primary.main,
}));

export const ButtonText = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 700,
	color: theme.palette.common.white,
}));

export const TableHeader = styled(Box)(() => ({
	display: 'grid',
	maxWidth: '100%',
	gridTemplateColumns: '100px repeat(7, 1fr)',
	padding: '20px',
}));

export const StyledLoadingContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	width: '100%',
	marginTop: theme.spacing(10),
}));

export const AlignText = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));
export const FirstAlignText = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	marginLeft: 20,
}));

export const LastColumnText = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	width: '10%',
}));

export const TitleText = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.common.black,
}));

export const WrapperHistoryDay = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	maxWidth: '100%',
	backgroundColor: theme.palette.neutral.light1,
	padding: '0px 0px 0px 20px',
}));

export const MiddleContent = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
}));

export const Title = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.common.black,
}));

export const QuantityOrders = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
}));

export const TotalSum = styled(Box)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
}));

export const WrapperStatusOrder = styled(Box)(() => ({
	display: 'grid',
	maxWidth: '100%',
	gridTemplateColumns: '100px repeat(7, 1fr)',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: '20px',
}));
export const OrderText = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
}));

export const NumberText = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'isCancelled',
})<ListItemStyledProp>(({ theme, isCancelled }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
	textDecoration: isCancelled ? 'line-through' : 'none',
}));
export const StatusText = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
}));
export const Status = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
}));
export const ApprovedIcon = styled(CheckCircleOutlineIcon)(({ theme }) => ({
	color: theme.palette.success.main,
	marginRight: '5px',
	fontSize: '20px',
}));
export const BoldStatusText = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'isCancelled',
})<ListItemStyledProp>(({ theme, isCancelled }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.common.black,
	textDecoration: isCancelled ? 'line-through' : 'none',
}));
export const RightArrowIcon = styled(ArrowForwardIosIcon)(({ theme }) => ({
	color: theme.palette.primary.main,
	fontSize: '16px',
	cursor: 'pointer',
}));
