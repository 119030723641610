import {
	Box,
	Button,
	Dialog,
	DialogContent,
	IconButton,
	styled,
	Typography,
} from '@mui/material';

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
	position: 'absolute',
	right: 8,
	top: 8,
	color: theme.palette.neutral.dark1,
}));

export const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 15,
		padding: '24px 0px 29px 0px',

		'@media only screen and (max-width: 1400px)': {
			width: 800,
			height: 450,
		},

		'& .MuiDialogContent-root': {
			display: 'flex',
		},

		'& .MuiDialogActions-root': {
			display: 'flex',
		},
	},
}));

export const StyledModalContainer = styled(Box)(({ theme }) => ({
	display: 'block',
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	backgroundColor: theme.palette.neutral.light,
	borderRadius: 20,
	border: 'none',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '36px 156px 48px 156px',
}));

export const StyledModalTitleContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	boxSizing: 'border-box',
	width: '100%',
	padding: '0px 24px 20px 24px',
	alignItems: 'center',
	marginBottom: 32,
	borderBottom: '1px solid',
	borderColor: theme.palette.neutral.dark2,
}));

export const StyledTitleContainer = styled(Box)(() => ({
	display: 'block',
	boxSizing: 'border-box',
	marginLeft: 16,
}));

export const StyledModalTitle = styled(Box)(({ theme }) => ({
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.neutral.dark,
	lineHeight: '28px',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 18,
	},
}));
export const StyledModalSubtitle = styled(Box)(() => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: '#475467',
	lineHeight: '20px',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
	},
}));

export const StyledDialogContent = styled(DialogContent)(() => ({
	display: 'block!important',
	width: '100%',
	padding: 0,
}));

export const StyledOrderInformation = styled(Box)(({ theme }) => ({
	width: '40%',
	borderRadius: 12,
	border: '1px solid',
	borderColor: theme.palette.neutral.dark2,
	padding: '20px 14px 24px 24px',
	justifyContent: 'space-around',
	marginBottom: 44,
}));

export const StyledOrderInformationTitle = styled(Box)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark,
	lineHeight: '20px',
	marginBottom: 2,
	padding: '0px 24px 24px 24px',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
	},
}));

export const StyledOrderInformationSubtitle = styled(Box)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
	lineHeight: '20px',
	marginBottom: 27,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
	},
}));
export const StyledInformationText = styled(Box)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark,
	lineHeight: '20px',
	marginBottom: 12,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
	},
	'& svg': {
		marginRight: 12,
	},
}));

export const StyledOrderRefundPaymentInformation = styled(Box)(() => ({
	display: 'flex',
	padding: '0px 14px 16px 24px',
	alignItems: 'center',
}));

export const StyledOrderRefundPaymentInformationTitle = styled(Box)(
	({ theme }) => ({
		fontSize: 14,
		fontFamily: 'Rubik',
		fontWeight: 500,
		color: theme.palette.neutral.dark,
		lineHeight: '20px',
		marginLeft: 8,
	})
);

export const StyledOrderRefundPaymentInformationText = styled(Box)(
	({ theme }) => ({
		fontSize: 14,
		fontFamily: 'Rubik',
		fontWeight: 400,
		color: theme.palette.neutral.dark1,
		lineHeight: '20px',
	})
);

export const StyledButton = styled(Button)(({ theme }) => ({
	height: 48,
	width: 300,
	borderRadius: 8,
	backgroundColor: theme.palette.primary.main,
	padding: '12px 32px',
	'&:hover': {
		backgroundColor: theme.palette.primary.main,
	},
	'@media only screen and (max-width: 1400px)': {
		width: 156,
	},
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
	textTransform: 'none',
	width: '100%',
	fontSize: 16,
	color: theme.palette.neutral.light,
	fontFamily: 'Rubik',
	fontWeight: 700,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
	},
}));
