import { AxiosResponse } from 'axios';
import { BASE_URL } from '../api';
import { request } from '../base-request/base-request';
import { UpdatedCurrentPayments } from '../interfaces';

export const patchProfilePayments = async (
	advertiser_id: string,
	newPaymentOptions: UpdatedCurrentPayments
): Promise<AxiosResponse<any>> => {
	try {
		const response = await request({
			url: `${BASE_URL}/clients/payment/${advertiser_id}`,
			data: newPaymentOptions,
			method: 'PATCH',
		});
		return response;
	} catch (error) {
		return Promise.reject(error);
	}
};
