export const customTextFieldStyles = (): any => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 2,
		height: 48,
		fontFamily: 'Rubik',
		'@media only screen and (max-width: 1400px)': {
			height: 38,
			fontSize: 12,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px',
	},
	'& .MuiOutlinedInput-input': {
		'&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
			WebkitAppearance: 'none',
		},
	},
});

export const formHelperStyle = {
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 400,
};
