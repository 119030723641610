import { Box, styled, Typography } from "@mui/material";

export const StyledCommentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  whiteSpace: "nowrap",
  padding: "10px 25px 10px 12px",
  borderTop: "1px solid",
  borderColor: theme.palette.neutral.light2,
  justifyContent: "space-between",
  "@media only screen and (max-width: 1200px)": {
    padding: 0,
    paddingTop: 3,
  },
}));

export const StyledCommentContent = styled(Box)(() => ({
  display: "flex",
  width: "100%",
}));
export const StyledAvatar = styled(Box)(() => ({
  display: "flex",
  width: 40,
  height: 40,
  borderRadius: "50%",
  overflow: "hidden",
  "& img": {
    height: "100%",
    width: "100%",
  },
  "@media (max-width: 1400px)": {
    width: 20,
    height: 20,
    marginTop: 3,
  },
  "@media (max-width: 1200px)": {
    width: 25,
    height: 25,
    marginTop: 0,
    marginLeft: 3,
  },
}));
export const StyledCommentText = styled(Box)(() => ({
  display: "block",
  marginLeft: 12,
}));
export const StyledTitle = styled(Box)(() => ({
  display: "flex",
  whiteSpace: "nowrap",
  "@media (max-width: 1400px)": {
    width: "150px",
  },
}));
export const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.primary.main,
  "@media only screen and (max-width: 1400px)": {
    fontSize: 10,
  },
}));
export const StyledPromotionName = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.neutral.dark,
  "@media (max-width: 1400px)": {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
export const StyledComment = styled(Box)(({ theme }) => ({
  fontSize: 12,
  fontFamily: "Rubik",
  fontWeight: 400,
  width: 250,
  color: theme.palette.neutral.dark,
  overflow: "hidden",
  textOverflow: "ellipsis",
  "@media only screen and (max-width: 1400px)": {
    fontSize: 8,
    width: 170,
  },
}));

export const StyledDot = styled(Box)(() => ({
  position: "relative",
  float: "right",
  top: 10,
  "@media only screen and (max-width: 1400px)": {
    fontSize: 7,
    top: 0,
  },
}));

export const StyledTitleContainer = styled(Box)(() => ({
  display: "flex",
}));

export const StyledChevronIcon = styled(Box)(() => ({
  marginRight: 8,
  marginTop: 3,
  cursor: "pointer",
}));

export const StyledCommentsTitle = styled(Box)(() => ({
  display: "flex",
  padding: "20px 32px 13px 32px",
  justifyContent: "space-between",
}));
export const StyledCommentsTitleTypography = styled(Typography)(
  ({ theme }) => ({
    fontSize: 18,
    fontFamily: "Rubik",
    fontWeight: 500,
    color: theme.palette.primary.dark,
    textAlign: "left",
    "@media only screen and (max-width: 1400px)": {
      fontSize: 14,
    },
  })
);

export const StyledCommentsList = styled(Box)(({ theme }) => ({
  display: "block",
  margin: "0px 32px 0px 32px",
  borderRadius: 8,
  border: "1px solid",
  borderColor: theme.palette.neutral.light2,
  "& > :first-of-type": {
    borderTop: 0,
  },
  "@media only screen and (max-width: 1400px)": {
    margin: "0px 15px 0px 15px",
  },
  "@media only screen and (max-width: 1200px)": {
    margin: "20px 15px 0px 15px",
  },
}));

export const StyledImage = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  paddingTop: 48,
}));

export const StyledEmptyTypography = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontFamily: "Rubik",
  fontWeight: 400,
  display: "baseline",
  color: theme.palette.neutral.dark1,
  textAlign: "center",
  paddingTop: 20,
}));
