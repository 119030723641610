import { Autocomplete, FormControl } from '@mui/material';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { getCitiesByState } from '../../../../utils/api/get/getCitiesByState';
import { getStates } from '../../../../utils/api/get/getStates';
import { getViaCep } from '../../../../utils/api/get/getViaCep';
import { AddressFormProps } from '../../../../utils/api/interfaces';
import { maskCEP, numberMask } from '../../../../utils/mask.util';
import { CitiesProps } from './interface/profileCities-interface';
import { StatesProps } from './interface/profileStates-interface';
import {
	AddressFormContainer,
	StyledAddressContainer,
	StyledCepContainer,
	StyledCityContainer,
	StyledComplementContainer,
	StyledDistrictContainer,
	StyledFirstRowForm,
	StyledNumberContainer,
	StyledSecondRowForm,
	StyledStateContainer,
	StyledTextContainer,
	StyledTextField,
	StyledThirdRowForm,
	TypographyText,
	TypographyTitle,
} from './styles';

const formHelperStyle = {
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 400,
};

const AddressForm: React.FC = (): JSX.Element => {
	const [states, setStates] = useState<StatesProps[]>([]);
	const [cities, setCities] = useState<CitiesProps[]>([]);
	const { enqueueSnackbar } = useSnackbar();
	const {
		values: {
			advertiserAddress: {
				cep,
				state,
				city,
				address,
				number,
				district,
				complement,
			},
		},
		handleChange,
		touched,
		errors,
		setFieldValue,
	} = useFormikContext<AddressFormProps>();
	const unsubscribe = useRef<boolean>();

	const getCep = (cep: string): void => {
		getViaCep(cep)
			.then((res) => {
				if (res.data.erro) {
					return;
				}
				setFieldValue('advertiserAddress.state', res.data.uf);
				setFieldValue('advertiserAddress.city', res.data.localidade);
				setFieldValue('advertiserAddress.address', res.data.logradouro);
				setFieldValue('advertiserAddress.district', res.data.bairro);
				setFieldValue('advertiserAddress.complement', res.data.complemento);
			})
			.catch(() =>
				enqueueSnackbar('Ocorreu um erro tente novamente.', {
					variant: 'error',
					autoHideDuration: 1500,
				})
			);
	};

	const getAllStates = () => {
		getStates()
			.then((res) => {
				setStates(res?.data);
			})
			.catch(() =>
				enqueueSnackbar('Ocorreu um erro tente novamente.', {
					variant: 'error',
					autoHideDuration: 1500,
				})
			);
	};

	const getAllCitiesByState = () => {
		getCitiesByState(state)
			.then((res) => {
				setCities(res?.data);
			})
			.catch(() =>
				enqueueSnackbar('Ocorreu um erro tente novamente.', {
					variant: 'error',
					autoHideDuration: 1500,
				})
			);
	};

	const onBlurCep = (cep: string): void => {
		const cepFormated = cep.replace(/[^0-9]+/g, '');
		if (cepFormated.length === 8) {
			getCep(cepFormated);
		}
	};

	useEffect(() => {
		unsubscribe.current = true;
		if (unsubscribe) {
			getAllStates();
		}
		return () => {
			unsubscribe.current = false;
		};
	}, []);
	useEffect(() => {
		getAllCitiesByState();
	}, [state, setStates]);

	return (
		<AddressFormContainer>
			<StyledTextContainer>
				<TypographyTitle>Endereço da loja</TypographyTitle>
				<TypographyText>
					O endereço cadastrado ficará visível no aplicativo
				</TypographyText>
			</StyledTextContainer>
			<StyledFirstRowForm>
				<StyledCepContainer>
					<FormControl fullWidth>
						<StyledTextField
							value={maskCEP(cep)}
							onPaste={(e: any) => {
								numberMask(e.target.value);
								return e;
							}}
							label="CEP"
							variant="outlined"
							onChange={handleChange('advertiserAddress.cep')}
							onBlur={(e) => onBlurCep(e.target.value)}
							error={
								touched.advertiserAddress?.cep &&
								Boolean(errors.advertiserAddress?.cep)
							}
							helperText={
								touched.advertiserAddress?.cep && errors.advertiserAddress?.cep
							}
							inputProps={{ maxLength: 9 }}
							FormHelperTextProps={{
								style: formHelperStyle,
							}}
						/>
					</FormControl>
				</StyledCepContainer>
				<StyledStateContainer>
					<FormControl fullWidth>
						<Autocomplete
							value={{ sigla: state }}
							options={states}
							filterSelectedOptions
							autoHighlight
							getOptionLabel={(option) => option.sigla}
							onChange={(e, value) => {
								setFieldValue('advertiserAddress.state', value?.sigla || '');
								setFieldValue('advertiserAddress.city', '');
							}}
							includeInputInList
							isOptionEqualToValue={(option, value) =>
								option.sigla === value.sigla
							}
							renderInput={(params) => (
								<StyledTextField
									{...params}
									label="UF"
									variant="outlined"
									value={state}
									error={
										touched.advertiserAddress?.state &&
										Boolean(errors.advertiserAddress?.state)
									}
									helperText={
										touched.advertiserAddress?.state &&
										errors.advertiserAddress?.state
									}
									FormHelperTextProps={{
										style: formHelperStyle,
									}}
								/>
							)}
						/>
					</FormControl>
				</StyledStateContainer>
				<StyledCityContainer>
					<FormControl fullWidth>
						<Autocomplete
							value={{ nome: city }}
							options={cities}
							filterSelectedOptions
							autoHighlight
							getOptionLabel={(option) => option.nome}
							onChange={(e, value) =>
								setFieldValue('advertiserAddress.city', value?.nome || '')
							}
							includeInputInList
							isOptionEqualToValue={(option, value) =>
								option.nome === value.nome
							}
							renderInput={(params) => (
								<StyledTextField
									{...params}
									label="Cidade"
									variant="outlined"
									value={state}
									error={
										touched.advertiserAddress?.city &&
										Boolean(errors.advertiserAddress?.city)
									}
									helperText={
										touched.advertiserAddress?.city &&
										errors.advertiserAddress?.city
									}
									FormHelperTextProps={{
										style: formHelperStyle,
									}}
								/>
							)}
						/>
					</FormControl>
				</StyledCityContainer>
			</StyledFirstRowForm>
			<StyledSecondRowForm>
				<StyledAddressContainer>
					<FormControl fullWidth>
						<StyledTextField
							value={address}
							label="Endereço"
							variant="outlined"
							onChange={handleChange('advertiserAddress.address')}
							error={
								touched.advertiserAddress?.address &&
								Boolean(errors.advertiserAddress?.address)
							}
							helperText={
								touched.advertiserAddress?.address &&
								errors.advertiserAddress?.address
							}
							inputProps={{ maxLength: 50 }}
							FormHelperTextProps={{
								style: formHelperStyle,
							}}
						/>
					</FormControl>
				</StyledAddressContainer>
				<StyledNumberContainer>
					<FormControl fullWidth>
						<StyledTextField
							value={number}
							label="Número"
							variant="outlined"
							onChange={handleChange('advertiserAddress.number')}
							error={
								touched.advertiserAddress?.number &&
								Boolean(errors.advertiserAddress?.number)
							}
							helperText={
								touched.advertiserAddress?.number &&
								errors.advertiserAddress?.number
							}
							inputProps={{ maxLength: 50 }}
							FormHelperTextProps={{
								style: formHelperStyle,
							}}
						/>
					</FormControl>
				</StyledNumberContainer>
			</StyledSecondRowForm>
			<StyledThirdRowForm>
				<StyledDistrictContainer>
					<FormControl fullWidth>
						<StyledTextField
							value={district}
							label="Bairro"
							variant="outlined"
							onChange={handleChange('advertiserAddress.district')}
							error={
								touched.advertiserAddress?.district &&
								Boolean(errors.advertiserAddress?.district)
							}
							helperText={
								touched.advertiserAddress?.district &&
								errors.advertiserAddress?.district
							}
							inputProps={{ maxLength: 50 }}
							FormHelperTextProps={{
								style: formHelperStyle,
							}}
						/>
					</FormControl>
				</StyledDistrictContainer>
				<StyledComplementContainer>
					<FormControl fullWidth>
						<StyledTextField
							value={complement}
							label="Complemento"
							variant="outlined"
							onChange={handleChange('advertiserAddress.complement')}
							error={
								touched.advertiserAddress?.complement &&
								Boolean(errors.advertiserAddress?.complement)
							}
							helperText={
								touched.advertiserAddress?.complement &&
								errors.advertiserAddress?.complement
							}
							inputProps={{ maxLength: 50 }}
							FormHelperTextProps={{
								style: formHelperStyle,
							}}
						/>
					</FormControl>
				</StyledComplementContainer>
			</StyledThirdRowForm>
		</AddressFormContainer>
	);
};

export default AddressForm;
