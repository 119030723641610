import { Box, styled, TextField, Typography } from '@mui/material';
import { ErrorMessage } from 'formik';
import {
	PreviewCardBackground,
	PropsPromotionImage,
} from './interface/edit-promotion.interface';

export const StyledCreatePromotionFormContainer = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
}));

export const StyledPromotionImageContainer = styled(Box)(() => ({
	display: 'block',
	width: 376,
}));

export const StyledUploadImageContainer = styled(Box)(() => ({
	display: 'block',
	width: '33%',
	maxWidth: 412,
	'@media only screen and (max-width: 1400px)': {
		maxWidth: 162,
	},
}));
export const SyledUploadImage = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'error',
})<PropsPromotionImage>(({ theme, error }) => ({
	height: 58,
	maxHeight: 58,
	border: '1px dashed',
	borderColor: error ? theme.palette.neutral.dark2 : theme.palette.error.main,
	borderRadius: 6,
	boxSizing: 'border-box',
	'& img': {
		height: '100%',
		width: '100%',
		objectFit: 'contain',
	},
	marginBottom: 44,
}));

export const Input = styled('input')({
	display: 'none',
});

export const StyledUploadFileContainer = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
	height: '100%',
	justifyContent: 'center',
	alignItems: 'center',
	cursor: 'pointer',
	marginBottom: 2,
	maxWidth: 390,
}));
export const StyledUploadFileContent = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '95%',
}));
export const StyledEmptyImage = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginRight: 12,
}));
export const StyledUploadFileTypography = styled(Typography)(({ theme }) => ({
	textAlign: 'center',
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 10,
	},
}));

export const StyledImageTitle = styled(Typography)(({ theme }) => ({
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 600,
	color: theme.palette.neutral.dark,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
	},
}));
export const StyledImageText = styled(Box)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
	textAlign: 'left',
	marginTop: 12,
	marginBottom: 28,
	lineHeight: '20px',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
	},
}));

export const StyledImageTextPromotion = styled(Box)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.primary.main,
	textAlign: 'left',
	textDecoration: 'underline',
	marginTop: 12,
	marginBottom: 28,
	lineHeight: '20px',
	cursor: 'pointer',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
	},
}));

export const StyledImageError = styled(ErrorMessage)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	fontSize: 12,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.error.main,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 8,
	},
}));

export const StyledImageName = styled(Box)(({ theme }) => ({
	display: 'flex',
	border: '1px solid',
	borderColor: theme.palette.neutral.dark2,
	borderRadius: 6,
	padding: '0px 11px 0px 8px',
	width: '100%',
	maxWidth: 'calc(100% - 21px)',
	alignItems: 'center',
	justifyContent: 'space-between',
	height: 56,
	maxHeight: 56,
	marginBottom: 44,
	'@media only screen and (max-width: 1200px)': {
		padding: '0px 8px 0px 8px',
	},
}));

export const StyledImageNameText = styled(Box)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 10,
	},
	'@media only screen and (max-width: 1200px)': {
		width: 100,
	},
}));

export const StyledRemoveImageIcon = styled(Box)(() => ({
	cursor: 'pointer',
	marginLeft: 17,
	'& svg': {
		marginTop: 4,
	},
}));

export const StyledPromotionPreviewCard = styled(Box)(() => ({
	width: 372,
	height: 300,
	borderRadius: 31,
	boxShadow: '4px 6px 15px rgba(0, 0, 0, 0.1)',
	margin: '0 auto',
	overflow: 'hidden',
	'@media only screen and (max-width: 1400px)': {
		width: 230,
		height: 230,
	},
	'@media (max-width: 1200px)': {
		width: 124,
		height: 120,
	},
}));

export const StyledPreviewImg = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'isEmpty',
})<PreviewCardBackground>(({ theme, isEmpty }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	height: 213,
	width: '100%',
	backgroundColor: isEmpty ? theme.palette.neutral.light1 : 'none',
	marginBottom: 12,
	borderRadius: 20,
	'& img': {
		height: '100%',
		width: '100%',
		objectFit: 'scale-down',
	},
	'@media only screen and (max-width: 1400px)': {
		height: 150,
	},
	'@media only screen and (max-width: 1200px)': {
		height: 80,
		marginBottom: 2,
	},
}));

export const StyledPreviewName = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'isEmpty',
})<PreviewCardBackground>(({ theme, isEmpty }) => ({
	display: 'flex',
	alignItems: 'center',
	width: 232,
	height: 20,
	backgroundColor: isEmpty ? theme.palette.neutral.light1 : 'none',
	marginLeft: 30,
	marginBottom: 15,
	borderRadius: 20,
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 16,
	lineHeight: '20px',
	'@media only screen and (max-width: 1400px)': {
		width: 150,
		fontSize: 12,
		lineHeight: '20px',
		marginBottom: 5,
	},
	'@media only screen and (max-width: 1200px)': {
		width: 80,
		fontSize: 10,
		lineHeight: '10px',
		marginLeft: 15,
		marginBottom: 2,
		height: 15,
	},
}));

export const StyledPreviewPrice = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'isEmpty',
})<PreviewCardBackground>(({ theme, isEmpty }) => ({
	display: 'flex',
	alignItems: 'center',
	width: 101,
	height: isEmpty ? 16 : 24,
	marginLeft: 30,
	backgroundColor: isEmpty ? theme.palette.neutral.light1 : 'none',
	'@media only screen and (max-width: 1400px)': {
		width: 70,
	},
	'@media only screen and (max-width: 1200px)': {
		width: 50,
		marginLeft: 15,
		height: isEmpty ? 10 : 15,
		marginTop: 5,
	},
}));

export const StyledPreviewPriceTypography = styled(Box)(({ theme }) => ({
	height: 24,
	width: 101,
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 16,
	lineHeight: '24px',
	color: theme.palette.success.main,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
		lineHeight: '20px',
	},
}));

export const StyledPromotionFormContainer = styled(Box)(() => ({
	width: '75%',
	height: '100%',
	marginLeft: '4%',
}));

export const StyledPromotonTitle = styled(Box)(() => ({
	maxHeight: 60,
	width: '50%',
}));

export const StyledNameTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		position: 'relative',
		borderRadius: 8,
		height: 60,
		fontFamily: 'Rubik',
		'@media only screen and (max-width: 1400px)': {
			fontSize: 14,
			height: 50,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'@media only screen and (max-width: 1400px)': {
		'& .MuiFormLabel-root': {
			fontSize: '15px',
		},
	},
}));

export const StyledPromotionFormText = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 18,
	color: theme.palette.neutral.dark,
	marginBottom: 12,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
	},
}));

export const StyledFieldCount = styled(Box)(({ theme }) => ({
	marginTop: 8,
	marginRight: 10,
	fontFamily: 'Rubik',
	fontWeight: 500,
	position: 'absolute',
	bottom: 0,
	right: 0,
	fontSize: 12,
	color: theme.palette.neutral.dark2,
	textAlign: 'end',
	pointerEvents: 'none',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 8,
	},
}));

export const StyledPromotionDescription = styled(Box)(() => ({
	marginTop: 20,
	marginBottom: 24,
	maxHeight: 100,
}));

export const StyledDescriptionTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		position: 'relative',
		borderRadius: 8,
		height: 100,
		fontFamily: 'Rubik',
		'@media only screen and (max-width: 1400px)': {
			fontSize: 14,
			height: 70,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
}));

export const StyledPromotionPeriodTitle = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 18,
	color: theme.palette.neutral.dark,
	marginBottom: 16,
}));

export const StyledPromotionPeriodContainer = styled(Box)(() => ({
	display: 'block',
	width: '70%',
	marginTop: 36,
	'@media only screen and (max-width: 1400px)': {
		width: '100%',
	},
	'@media only screen and (max-width: 1200px)': {
		marginTop: 15,
	},
}));
export const StyledPromotionPeriod = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
}));
export const StyledPromotionStart = styled(Box)(() => ({
	maxWidth: '30%',
	height: 79,
	marginRight: 60,
}));
export const StyledPromotionSmallFormText = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 16,
	color: theme.palette.neutral.dark,
	marginBottom: 12,
}));
export const StyledPromotionEnd = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	maxWidth: '70%',
	height: 79,
}));

export const StyledBottomRow = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
}));

export const StyledValuesRow = styled(Box)(() => ({
	display: 'flex',
	maxHeight: 48,
	width: '100%',
	'@media only screen and (max-width: 1400px)': {
		justifyContent: 'space-between',
	},
}));

export const StyledPromotionPriceContainer = styled(Box)(() => ({
	display: 'block',
	width: '37%',
	'@media only screen and (max-width: 1400px)': {
		width: '50%',
	},
	'@media only screen and (max-width: 1200px)': {
		width: '60%',
	},
}));
export const StyledPromotionPrice = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
	justifyContent: 'space-between',
}));
export const StyledInitialPrice = styled(Box)(() => ({}));
export const StyledPromotionalPrice = styled(Box)(() => ({}));

export const StyledPromotionalCategory = styled(Box)(() => ({
	marginLeft: 44,
	'@media only screen and (max-width: 1200px)': {
		marginLeft: 24,
	},
}));

export const StyledDataTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 48,
		width: 230,
		fontFamily: 'Rubik',
		cursor: 'not-allowed',
		'@media only screen and (max-width: 1200px)': {
			fontSize: 14,
			height: 35,
			width: 190,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'& .MuiOutlinedInput-input': {
		cursor: 'not-allowed',
	},
}));

export const StyledDataTextFieldFinal = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 48,
		width: 180,
		fontFamily: 'Rubik',
		cursor: 'not-allowed',
		'@media only screen and (max-width: 1200px)': {
			fontSize: 14,
			height: 35,
			width: 150,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'& .MuiOutlinedInput-input': {
		cursor: 'not-allowed',
	},
}));

export const StyledTagTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 48,
		width: 230,
		'@media only screen and (max-width: 1400px)': {
			fontSize: 14,
			height: 35,
			width: 150,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
}));
export const StyledValueTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 48,
		width: 172,
		fontFamily: 'Rubik',
		'@media only screen and (max-width: 1400px)': {
			width: 115,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'@media only screen and (max-width: 1400px)': {
		'& .MuiFormLabel-root': {
			fontSize: '13px',
		},
	},
}));

export const StyledPromotionCouponContainer = styled(Box)(() => ({
	display: 'flex',
	width: 'calc(60% - 48px)',
	marginTop: 40,
	height: 82,
	'@media only screen and (max-width: 1200px)': {
		marginTop: 15,
	},
}));

export const StyledCouponStatusContainer = styled(Box)(() => ({
	display: 'block',
}));
export const StyledCouponStatus = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	height: 24,
	width: 156,
	color: theme.palette.neutral.dark1,
	borderRadius: 8,
	fontFamily: 'Rubik',
	fontWeight: 400,
	fontSize: 18,
	padding: '11px 16px 11px 12px',
	marginRight: 20,
	border: '1px solid',
	borderColor: theme.palette.neutral.dark2,
}));

export const StyledCouponValueTextField = styled(TextField)(({ theme }) => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 48,
		width: 100,
		fontFamily: 'Rubik',

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
		[`&.Mui-disabled`]: {
			color: theme.palette.neutral.dark1,
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'& .MuiOutlinedInput-input': {
		cursor: 'not-allowed',
	},
}));

export const StyledCouponPrefix = styled(Box)(() => ({
	display: 'block',
	marginTop: 34,
	marginLeft: 24,
}));
export const StyledCouponVolume = styled(Box)(() => ({
	display: 'block',
	marginTop: 34,
}));

export const StyledCouponPrefixTextField = styled(TextField)(({ theme }) => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 48,
		width: 200,
		fontFamily: 'Rubik',

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
		[`&.Mui-disabled`]: {
			color: theme.palette.neutral.dark1,
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
}));

export const StyledButtonsContainer = styled(Box)(() => ({
	display: 'flex',
	marginTop: '54px',
	alignItems: 'center',
	justifyContent: 'center',
}));

export const StyledCreatePromotionButton = styled(Box)(() => ({
	marginLeft: 40,
	width: 230,
}));

export const StyledResetFormButton = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	fontFamily: 'Rubik',
	height: 49,
	width: 128,
	fontWeight: 700,
	fontSize: 18,
	cursor: 'pointer',
	color: theme.palette.primary.main,
	border: '2px solid',
	borderColor: theme.palette.neutral.dark2,
	borderRadius: 5,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
		height: 39,
		width: 108,
	},
}));

export const StyledHourTextField = styled(TextField)(() => ({
	marginTop: 34,
	'@media only screen and (max-width: 1200px)': {
		marginTop: 29,
	},
	'& .MuiOutlinedInput-root ': {
		position: 'relative',
		borderRadius: 8,
		height: 48,
		width: 83,
		fontFamily: 'Rubik',

		'@media only screen and (max-width: 1200px)': {
			fontSize: 14,
			height: 35,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'& .MuiOutlinedInput-input': {
		cursor: 'not-allowed',
	},
	'& input[type="time"]::-webkit-calendar-picker-indicator': {
		background: 'none',
		display: 'none',
	},
}));

export const StyledPromotionEndDateContainer = styled(Box)(() => ({
	display: 'block',
	marginRight: 30,
}));

export const StyledCouponDuration = styled(TextField)(() => ({
	marginLeft: 24,
	'& .MuiOutlinedInput-root ': {
		position: 'relative',
		borderRadius: 8,
		height: 48,
		width: 123,
		fontFamily: 'Rubik',
		'@media only screen and (max-width: 1400px)': {
			height: 38,
			fontSize: 13,
			'& .MuiFormLabel-root': {
				fontSize: '13px',
			},
		},
		'& .MuiOutlinedInput-input': {
			cursor: 'not-allowed',
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'& input[type="time"]::-webkit-calendar-picker-indicator': {
		background: 'none',
		display: 'none',
	},
}));

export const StyledDurationTypography = styled(Typography)(({ theme }) => ({
	fontSize: 15,
	fontFamily: 'Rubik',
	fontWeight: 400,
	lineHeight: 'unset',
	display: 'flex',
	marginTop: 2,
	color: theme.palette.neutral.dark1,
	cursor: 'not-allowed',
}));
