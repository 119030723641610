import { Box, styled, Typography } from "@mui/material";

export const StyledComment = styled(Box)(() => ({
  display: "flex",
  width: "100%",
}));

export const StyledCommentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutral.light2,
  width: "100%",
  height: "fit-content",
}));

export const StyledCommentTopBar = styled(Box)(() => ({
  display: "flex",
  width: "100%",
}));

export const StyledCommentContent = styled(Box)(() => ({
  maxHeight: "calc(100% - 188px)",
  padding: "32px 44px 36px 44px",
  "@media only screen and (max-width: 1200px)": {
    padding: "24px 20px 32px 24px",
  },
}));

export const StyledCommentListContainer = styled(Box)(({ theme }) => ({
  display: "block",
  boxSizing: "border-box",
  width: "100%",
  backgroundColor: theme.palette.neutral.light,
  padding: "36px 44px 44px 44px",
  borderRadius: 20,
  minHeight: "749px",

  "@media (max-height: 850px)": {
    paddingBottom: 45,
  },
  "@media (max-height: 770px)": {
    paddingBottom: 35,
  },
  "@media (max-width: 1400px)": {
    minHeight: "490px",
  },
  "@media (max-width: 1200px)": {
    padding: "19px 20px 19px 20px",
  },
}));

export const StyledCommentsTitleContainer = styled(Box)(() => ({
  display: "flex",
}));

export const StyledCommentsTitleTypography = styled(Typography)(
  ({ theme }) => ({
    fontSize: 20,
    fontFamily: "Rubik",
    fontWeight: 600,
    color: theme.palette.neutral.dark,
    textAlign: "left",
    "@media (max-width: 1200px)": {
      fontSize: 16,
    },
  })
);

export const StyledCommentsList = styled(Box)(({ theme }) => ({
  display: "block",
  width: "100%",
  marginTop: 34,
  borderRadius: 8,
  border: "1px solid",

  borderColor: theme.palette.neutral.light2,
  "& > :first-of-type": {
    borderTop: "none",
  },
  "@media only screen and (max-width: 1200px)": {
    minHeight: 334,
    maxHeight: 334,
    marginTop: 14,
  },
}));

export const StyledPagination = styled(Box)(() => ({
  display: "flex",
  marginTop: 77,
  minHeight: 32,
  "@media (max-height: 850px)": {
    marginTop: 30,
  },
  "@media only screen and (max-width: 1200px)": {
    marginTop: 46,
  },
}));

export const StyledEmptyList = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: 555,
  "@media only screen and (max-width: 1200px)": {
    minHeight: 351,
  },
}));
