import { Box, Dialog, IconButton, Typography, styled } from '@mui/material';

interface PropsPromotionImage {
	error: boolean;
}

export const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 15,
		padding: '20px 0px 48px 0px',
		width: '100%',
		height: '70%',
		maxWidth: '1000px',
		'@media (max-height: 850px)': {
			maxWidth: '750px',
			maxHeight: '500px',
		},

		'& .MuiDialogContent-root': {
			display: 'flex',
		},

		'& .MuiDialogActions-root': {
			display: 'flex',
		},
	},
}));
export const StyledIconButton = styled(IconButton)(({ theme }) => ({
	position: 'absolute',
	right: 8,
	top: 8,
	color: theme.palette.neutral.dark1,
}));

export const StyledItemModal = styled(Box)(() => ({
	display: 'flex',
	width: '95%',
	height: '85%',

	flex: 1,
	maxHeight: 600,
	'@media (max-width: 1400px)': {
		minHeight: 200,
		width: '100%',
	},
}));

export const SyledUploadImage = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'error',
})<PropsPromotionImage>(({ theme, error }) => ({
	border: '1px dashed',
	borderColor: error ? theme.palette.neutral.dark2 : theme.palette.error.main,
	borderRadius: 6,
	boxSizing: 'border-box',
	backgroundColor: theme.palette.primary.contrastText,
	width: '100%',
	flex: 1,
}));

export const SyledImgPreview = styled(Box)(() => ({
	borderRadius: 6,
	boxSizing: 'border-box',
	width: '100%',
	height: '-webkit-fill-available',
	'& img': {
		height: '80%',
		width: '100%',
		objectFit: 'contain',
	},
}));

export const Input = styled('input')({
	display: 'none',
});

export const StyledUploadFileContainer = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
	height: '100%',
	justifyContent: 'center',
	alignItems: 'center',
	cursor: 'pointer',
	marginBottom: 2,
}));
export const StyledUploadFileContent = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '95%',
}));
export const StyledEmptyImage = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginRight: 12,
}));
export const StyledUploadFileTypography = styled(Typography)(({ theme }) => ({
	textAlign: 'center',
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 10,
	},
}));

export const StyledButtonsContainer = styled(Box)(() => ({
	display: 'flex',
	marginTop: '54px',
	alignItems: 'center',
	justifyContent: 'center',
	'@media only screen and (max-width: 1200px)': {
		marginTop: 24,
	},
}));

export const StyledResetFormButton = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	fontFamily: 'Rubik',
	height: 49,
	width: 128,
	fontWeight: 700,
	fontSize: 18,
	cursor: 'pointer',
	color: theme.palette.primary.main,
	border: '2px solid',
	borderColor: theme.palette.neutral.dark2,
	borderRadius: 5,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
		height: 39,
		width: 108,
	},
}));

export const StyledCreateItemImgButton = styled(Box)(() => ({
	marginLeft: 40,
	width: 230,
}));
