import CloseIcon from '@mui/icons-material/Close';
import { FormControl, InputAdornment, MenuItem, Switch } from '@mui/material';
import {
	DatePicker,
	DateTimePicker,
	LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { ReactComponent as BigEmptyIcon } from '../../../../assets/images/bigEmptyImg.svg';
import { ReactComponent as SmallEmptyIcon } from '../../../../assets/images/smallEmptyImg.svg';
import categoriesFormat from '../../../../shared/helpers/categoriesFormat';
import { convertToBase64 } from '../../../../shared/helpers/convertToBase64.helper';
import { ITag } from '../../../../utils/api/interfaces';
import { moneyMask } from '../../../../utils/mask.util';
import ImageModal from '../../../Promotion/ImageModal/ImageModal';

import {
	StyledBottomRow,
	StyledCouponDuration,
	StyledCouponStatus,
	StyledCouponStatusContainer,
	StyledCouponValueTextField,
	StyledCouponVolume,
	StyledCreatePromotionFormContainer,
	StyledDataTextFieldFinal,
	StyledDurationTypography,
	StyledFieldCount,
	StyledHourTextField,
	StyledImageName,
	StyledImageNameText,
	StyledImageText,
	StyledImageTextPromotion,
	StyledImageTitle,
	StyledInitialPrice,
	StyledNameTextField,
	StyledPreviewImg,
	StyledPreviewName,
	StyledPreviewPrice,
	StyledPreviewPriceTypography,
	StyledPromotionCouponContainer,
	StyledPromotionEnd,
	StyledPromotionEndDateContainer,
	StyledPromotionFormContainer,
	StyledPromotionFormText,
	StyledPromotionPeriod,
	StyledPromotionPeriodContainer,
	StyledPromotionPreviewCard,
	StyledPromotionStart,
	StyledPromotionalCategory,
	StyledPromotionalPrice,
	StyledPromotonTitle,
	StyledRemoveImageIcon,
	StyledUploadImageContainer,
	StyledValueTextField,
	StyledValuesRow,
	SyledUploadImage,
} from '../EditPromotionForm/styles';
import {
	EditPromotionFormProps,
	IEditPromotionForm,
	IFile,
} from './interface/edit-promotion.interface';
import {
	Input,
	StyledDataTextField,
	StyledDescriptionTextField,
	StyledEmptyImage,
	StyledImageError,
	StyledPromotionDescription,
	StyledPromotionPrice,
	StyledPromotionPriceContainer,
	StyledTagTextField,
	StyledUploadFileContainer,
	StyledUploadFileContent,
	StyledUploadFileTypography,
} from './styles';

const customTextFieldStyles = () => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 2,
		height: 48,
		fontFamily: 'Rubik',
		'@media only screen and (max-width: 1400px)': {
			height: 35,
			fontSize: 10,
			paddingLeft: 0,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},

	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'& .MuiOutlinedInput-input': {
		'&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
			WebkitAppearance: 'none',
		},
	},
	'@media only screen and (max-width: 1400px)': {
		'& .MuiFormLabel-root': {
			fontSize: '11px',
		},
	},
});

const formHelperStyle = {
	fontFamily: 'Rubik',
	fontWeight: 400,
	marginLeft: 0,
	marginRight: 0,
};

const EditPromotionForm: React.FC<IEditPromotionForm> = ({
	tags,
	promotion,
}: IEditPromotionForm): JSX.Element => {
	const [photoError, setPhotoError] = useState(false);
	const [openPromotionImageModal, setOpenPromotionImageModal] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const handleClosePromotionImageModal = (): void =>
		setOpenPromotionImageModal(false);

	const handleOpenPromotionImageModal = (): void =>
		setOpenPromotionImageModal(true);

	const {
		values: {
			title,
			description,
			initial_price,
			promotional_price,
			promotion_expires_date,
			promotion_expires_hour,
			tag_id,
			promotion_image,
			coupon_duration,
		},
		handleChange,
		touched,
		errors,
		setFieldValue,
		isSubmitting,
		isValid,
		isValidating,
		submitCount,
	} = useFormikContext<EditPromotionFormProps>();

	const [imageState, setImageState] = useState({
		promotion_image: promotion_image,
		promotion_image_name: '',
		path: promotion_image,
	});

	const handleFileChange = async (e: any): Promise<void> => {
		const file = e.target.files[0];

		if (
			(file as IFile).type == 'image/jpeg' ||
			(file as IFile).type == 'image/jpg' ||
			(file as IFile).type == 'image/png' ||
			(file as IFile).type == 'image/webp'
		) {
			const base64 = await convertToBase64(file);

			setImageState({
				promotion_image: base64 as string,
				promotion_image_name: e.target.files[0].name,
				path: URL.createObjectURL(e.target.files[0]),
			});

			setPhotoError(false);
		} else {
			setPhotoError(true);
			enqueueSnackbar('Verifique o formato da imagem', {
				variant: 'error',
				autoHideDuration: 1500,
			});
		}
	};

	const handleImagePreview = (): void => {
		setImageState({
			promotion_image: '',
			promotion_image_name: '',
			path: '',
		});

		if (submitCount > 0) {
			setPhotoError(true);
		}
	};

	useEffect(() => {
		setFieldValue('promotion_image', imageState?.promotion_image);
	}, [imageState?.promotion_image]);

	useEffect(() => {
		if (!isValid && !isValidating && isSubmitting && errors.promotion_image) {
			setPhotoError(true);
		}
	}, [isSubmitting, isValid, isValidating]);

	return (
		<StyledCreatePromotionFormContainer>
			<StyledUploadImageContainer>
				<StyledImageTitle>Imagem do produto</StyledImageTitle>

				<StyledImageTextPromotion
					onClick={() => handleOpenPromotionImageModal()}
				>
					Saiba como escolher a melhor foto
				</StyledImageTextPromotion>
				<ImageModal
					handleClosePromotionImageModal={handleClosePromotionImageModal}
					openPromotionImageModal={openPromotionImageModal}
				/>
				{!imageState.path && (
					<SyledUploadImage error={!photoError}>
						<label>
							<Input
								type="file"
								name="promotion_image"
								onChange={handleFileChange}
								accept="image/*"
								multiple
							/>
							<StyledUploadFileContainer>
								<StyledUploadFileContent>
									<StyledEmptyImage>
										<SmallEmptyIcon />
									</StyledEmptyImage>
									<StyledUploadFileTypography>
										Clique aqui para selecionar a imagem
									</StyledUploadFileTypography>
								</StyledUploadFileContent>
							</StyledUploadFileContainer>
						</label>
						<StyledImageError component="div" name="promotion_image" />
					</SyledUploadImage>
				)}
				{imageState.path && (
					<StyledImageName>
						<StyledImageNameText>
							{imageState.promotion_image_name
								? imageState.promotion_image_name
								: `${promotion?.title}.png`}
						</StyledImageNameText>
						<StyledRemoveImageIcon onClick={handleImagePreview}>
							<CloseIcon sx={{ color: '#747476' }} />
						</StyledRemoveImageIcon>
					</StyledImageName>
				)}
				<StyledImageTitle>Pré-visualização</StyledImageTitle>
				<StyledImageText>
					Preencha as informações para visualizar como sua promoção ficará.
				</StyledImageText>
				<StyledPromotionPreviewCard>
					<StyledPreviewImg isEmpty={!imageState.path}>
						{!imageState.path && <BigEmptyIcon />}
						{imageState.path && <img src={imageState?.path} />}
					</StyledPreviewImg>
					<StyledPreviewName isEmpty={!title}>{title}</StyledPreviewName>
					<StyledPreviewPrice isEmpty={!promotional_price}>
						{promotional_price === 0 ? (
							<StyledPreviewPriceTypography></StyledPreviewPriceTypography>
						) : (
							<StyledPreviewPriceTypography>
								{moneyMask(promotional_price)}
							</StyledPreviewPriceTypography>
						)}
					</StyledPreviewPrice>
				</StyledPromotionPreviewCard>
			</StyledUploadImageContainer>

			<StyledPromotionFormContainer>
				<StyledPromotonTitle>
					<FormControl fullWidth>
						<StyledNameTextField
							id="outlined-basic"
							value={title}
							label="Nome do produto"
							variant="outlined"
							onChange={handleChange('title')}
							error={touched.title && Boolean(errors.title)}
							helperText={touched.title && errors.title}
							inputProps={{ maxLength: 50 }}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<StyledFieldCount>
											{`${Number(50) - title.length} caracteres restantes`}
										</StyledFieldCount>
									</InputAdornment>
								),
							}}
							FormHelperTextProps={{
								style: formHelperStyle,
							}}
						/>
					</FormControl>
				</StyledPromotonTitle>
				<StyledPromotionDescription>
					<FormControl fullWidth>
						<StyledDescriptionTextField
							id="outlined-basic"
							value={description}
							label="Descrição"
							variant="outlined"
							onChange={handleChange('description')}
							error={touched.description && Boolean(errors.description)}
							helperText={touched.description && errors.description}
							multiline={true}
							rows={3}
							inputProps={{
								maxLength: 500,
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<StyledFieldCount>
											{`${
												Number(500) - description.length
											} caracteres restantes`}
										</StyledFieldCount>
									</InputAdornment>
								),
							}}
							FormHelperTextProps={{
								style: formHelperStyle,
							}}
						/>
					</FormControl>
				</StyledPromotionDescription>
				<StyledValuesRow>
					<StyledPromotionPriceContainer>
						<StyledPromotionPrice>
							<StyledInitialPrice>
								<FormControl>
									<StyledValueTextField
										id="outlined-basic"
										value={moneyMask(initial_price)}
										label="Valor original"
										placeholder="ex: R$ 987,00"
										variant="outlined"
										onChange={handleChange('initial_price')}
										error={
											touched.initial_price && Boolean(errors.initial_price)
										}
										helperText={touched.initial_price && errors.initial_price}
										inputProps={{ maxLength: 15 }}
										FormHelperTextProps={{
											style: formHelperStyle,
										}}
										sx={customTextFieldStyles}
									/>
								</FormControl>
							</StyledInitialPrice>
							<StyledPromotionalPrice>
								<FormControl>
									<StyledValueTextField
										id="outlined-basic"
										value={moneyMask(promotional_price)}
										label="Valor com desconto"
										placeholder="ex: R$ 987,00"
										variant="outlined"
										onChange={handleChange('promotional_price')}
										error={
											touched.promotional_price &&
											Boolean(errors.promotional_price)
										}
										helperText={
											touched.promotional_price && errors.promotional_price
										}
										inputProps={{ maxLength: 15 }}
										FormHelperTextProps={{
											style: formHelperStyle,
										}}
										sx={customTextFieldStyles}
									/>
								</FormControl>
							</StyledPromotionalPrice>
						</StyledPromotionPrice>
					</StyledPromotionPriceContainer>
					<StyledPromotionalCategory>
						<FormControl>
							<StyledTagTextField
								select
								id="outlined-basic"
								type="text"
								value={tag_id}
								label="Categoria"
								variant="outlined"
								onChange={handleChange('tag_id')}
								error={touched.tag_id && Boolean(errors.tag_id)}
								helperText={touched.tag_id && errors.tag_id}
								FormHelperTextProps={{
									style: formHelperStyle,
								}}
								SelectProps={{
									MenuProps: {
										sx: { maxHeight: '20%' },
									},
								}}
							>
								{tags.map((tags: ITag) => (
									<MenuItem
										key={tags.id}
										value={tags.id}
										onClick={() => {
											setFieldValue('tag_name', tags.name);
										}}
									>
										{categoriesFormat(tags.name)}
									</MenuItem>
								))}
							</StyledTagTextField>
						</FormControl>
					</StyledPromotionalCategory>
				</StyledValuesRow>
				<StyledPromotionPeriodContainer>
					<StyledPromotionPeriod>
						<StyledPromotionStart>
							<StyledPromotionFormText>Ínicio</StyledPromotionFormText>
							<LocalizationProvider
								dateAdapter={AdapterDateFns}
								adapterLocale={brLocale}
							>
								<DateTimePicker
									disabled={true}
									value={moment()}
									onChange={(val: any) => {
										setFieldValue('promotion_initial_date', val);
									}}
									renderInput={(params: any) => (
										<StyledDataTextField
											{...params}
											error={
												touched.promotion_initial_date &&
												Boolean(errors.promotion_initial_date)
											}
											helperText={
												touched.promotion_initial_date &&
												errors.promotion_initial_date
											}
											InputLabelProps={{ shrink: false }}
											FormHelperTextProps={{
												style: formHelperStyle,
											}}
											disabled={true}
										/>
									)}
								/>
							</LocalizationProvider>
						</StyledPromotionStart>
						<StyledPromotionEnd>
							<StyledPromotionEndDateContainer>
								<StyledPromotionFormText>Fim</StyledPromotionFormText>

								<LocalizationProvider
									dateAdapter={AdapterDateFns}
									adapterLocale={brLocale}
								>
									<DatePicker
										disabled
										value={promotion_expires_date}
										onChange={(val) => {
											setFieldValue('promotion_expires_date', val);
										}}
										minDate={moment().toDate()}
										renderInput={(params: any) => (
											<StyledDataTextFieldFinal
												{...params}
												error={
													touched.promotion_expires_date &&
													Boolean(errors.promotion_expires_date)
												}
												helperText={
													touched.promotion_expires_date &&
													errors.promotion_expires_date
												}
												InputLabelProps={{ shrink: false }}
												FormHelperTextProps={{
													style: formHelperStyle,
												}}
											/>
										)}
									/>
								</LocalizationProvider>
							</StyledPromotionEndDateContainer>
							<StyledHourTextField
								disabled
								type="time"
								variant="outlined"
								label="Horas"
								value={promotion_expires_hour}
								InputLabelProps={{ shrink: true }}
								error={
									touched.promotion_expires_hour &&
									Boolean(errors.promotion_expires_hour)
								}
								helperText={
									touched.promotion_expires_hour &&
									errors.promotion_expires_hour
								}
								onChange={(val) => {
									setFieldValue('promotion_expires_hour', val.target.value);
								}}
								FormHelperTextProps={{
									style: formHelperStyle,
								}}
							/>
						</StyledPromotionEnd>
					</StyledPromotionPeriod>
				</StyledPromotionPeriodContainer>
				<StyledBottomRow>
					<StyledPromotionCouponContainer>
						<StyledCouponStatusContainer>
							<StyledPromotionFormText>Cupons</StyledPromotionFormText>
							<StyledCouponStatus>
								Ativar cupons
								<Switch
									checked={promotion?.has_coupons}
									size="small"
									inputProps={{ 'aria-label': 'controlled' }}
									disabled
								/>
							</StyledCouponStatus>
						</StyledCouponStatusContainer>
						{promotion?.has_coupons && (
							<>
								<StyledCouponVolume>
									<FormControl>
										<StyledCouponValueTextField
											id="outlined-basic"
											type="number"
											value={promotion?.coupon_batch.qtd_coupons ?? ''}
											label="Quantidade"
											placeholder="ex: 10"
											variant="outlined"
											onChange={handleChange('qtd_coupons')}
											error={touched.qtd_coupons && Boolean(errors.qtd_coupons)}
											helperText={touched.qtd_coupons && errors.qtd_coupons}
											inputProps={{ maxLength: 6 }}
											FormHelperTextProps={{
												style: formHelperStyle,
											}}
											disabled
											sx={customTextFieldStyles}
										/>
									</FormControl>
								</StyledCouponVolume>
								<StyledCouponVolume>
									<FormControl>
										<StyledCouponDuration
											disabled
											type="time"
											variant="outlined"
											label="Duração do cupom"
											value={coupon_duration ?? ''}
											InputLabelProps={{
												sx: {
													fontSize: '1.6vh',
													top: '-1.6vh',
													'&.MuiInputLabel-shrink': { top: 0 },
												},
											}}
											error={
												touched.coupon_duration &&
												Boolean(errors.coupon_duration)
											}
											helperText={
												touched.coupon_duration && errors.coupon_duration
											}
											onChange={(val) => {
												setFieldValue('coupon_duration', val.target.value);
											}}
											FormHelperTextProps={{
												style: formHelperStyle,
											}}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<StyledDurationTypography>
															horas
														</StyledDurationTypography>
													</InputAdornment>
												),
												sx: { fontSize: '1.6vh', height: '3.3vh' },
											}}
										/>
									</FormControl>
								</StyledCouponVolume>
							</>
						)}
					</StyledPromotionCouponContainer>
				</StyledBottomRow>
			</StyledPromotionFormContainer>
		</StyledCreatePromotionFormContainer>
	);
};

export default EditPromotionForm;
