import { FormControl, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { EmailProps } from '../../../utils/api/interfaces';

const formHelperStyle = {
	fontSize: 13,
	fontFamily: 'Rubik',
};

const customTextFieldStyles = () => ({
	width: '100%',
	fontFamily: 'Rubik',
	'& .MuiOutlinedInput-root ': {
		'@media only screen and (max-width: 1400px)': {
			fontSize: 14,
		},
		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	[`& fieldset`]: {
		borderRadius: 2,
	},
	[`& label.Mui-focused`]: {
		color: '#6029B1',
	},
});

const EmailInput: React.FC = (): JSX.Element => {
	const {
		values: { email },
		handleChange,
		touched,
		errors,
	} = useFormikContext<EmailProps>();

	return (
		<FormControl variant="outlined" fullWidth>
			<TextField
				variant="outlined"
				id="login-email"
				type="text"
				label="Email"
				value={email}
				onChange={handleChange('email')}
				error={touched.email && Boolean(errors.email)}
				helperText={touched.email && errors.email}
				FormHelperTextProps={{
					style: formHelperStyle,
				}}
				sx={customTextFieldStyles}
			/>
		</FormControl>
	);
};

export default EmailInput;
