import {
	Box,
	Button,
	Dialog,
	DialogContent,
	IconButton,
	styled,
	Typography,
} from '@mui/material';

import { ReactComponent as CalendarIcon } from '../../../assets/images/calendarIcon.svg';
import { ReactComponent as CouponIcon } from '../../../assets/images/couponIconModal.svg';
import { ReactComponent as LightIcon } from '../../../assets/images/lightModalIcon.svg';
import { ReactComponent as SalePriceIcon } from '../../../assets/images/oldPrice.svg';
import { ReactComponent as PurpleDot } from '../../../assets/images/purpleDot.svg';
import { ReactComponent as OldPriceIcon } from '../../../assets/images/salePrice.svg';

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
	position: 'absolute',
	right: 8,
	top: 8,
	color: theme.palette.neutral.dark1,
}));

export const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 15,
		padding: '24px 0px 29px 0px',

		'@media only screen and (max-width: 1400px)': {
			width: 800,
			height: 450,
		},

		'& .MuiDialogContent-root': {
			display: 'flex',
		},

		'& .MuiDialogActions-root': {
			display: 'flex',
		},
	},
}));

export const StyledModalTitleContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	boxSizing: 'border-box',
	width: '100%',
	padding: '0px 24px 20px 24px',
	alignItems: 'center',
	marginBottom: 32,
	borderBottom: '1px solid',
	borderColor: theme.palette.neutral.dark2,
}));

export const StyledTitleContainer = styled(Box)(() => ({
	display: 'block',
	boxSizing: 'border-box',
	marginLeft: 16,
}));

export const StyledModalTitle = styled(Box)(({ theme }) => ({
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.neutral.dark,
	lineHeight: '28px',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 18,
	},
}));
export const StyledModalSubtitle = styled(Box)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
	lineHeight: '20px',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
	},
}));

export const StyledDialogContent = styled(DialogContent)(() => ({
	justifyContent: 'space-around',
	width: '100%',
	padding: 0,
}));

export const SyledPromotionImageContainer = styled(Box)(() => ({
	display: 'block',
	boxSizing: 'border-box',
	minWidth: '40%',
	width: '40%',
	padding: '28px 44px 52px 28px',
}));

export const SyledPromotionImage = styled(Box)(() => ({
	borderRadius: 20,
	maxHeight: 370,
	height: 370,
	minWidth: '39%',
	border: '1px dashed',
	borderColor: 'transparent',
	overflow: 'hidden',
	position: 'relative',
	boxSizing: 'border-box',
	'& img': {
		height: '100%',
		width: '100%',
		objectFit: 'scale-down',
	},
	'@media only screen and (max-width: 1400px)': {
		maxHeight: 200,
		height: 200,
		minWidth: '39%',
	},
}));

export const StyledImgInfo = styled(Box)(() => ({
	display: 'flex',
	marginTop: 44,
}));

export const StyledImgInfoText = styled(Box)(({ theme }) => ({
	display: 'flex',
	fontSize: 16,
	color: theme.palette.neutral.dark1,
	fontFamily: 'Rubik',
	fontWeight: 400,
	lineHeight: '20px',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
		lineHeight: '16px',
	},
}));

export const StyledLightIcon = styled(LightIcon)(() => ({
	minWidth: 40,
	minHeight: 40,
	marginRight: 16,
}));

export const StyledCouponIcon = styled(CouponIcon)(() => ({
	maxWidth: 40,
	maxHeight: 40,
	marginRight: 16,
}));
export const StyledCalendarIcon = styled(CalendarIcon)(() => ({
	maxWidth: 40,
	maxHeight: 40,
	marginRight: 16,
	color: '#C5C5C5',
}));

export const StyledPromotionInfo = styled(Box)(() => ({
	display: 'block',
	minWidth: '60%',
	width: '60',
}));

export const StyledPromotionTitleContainer = styled(Box)(() => ({
	display: 'flex',
}));

export const StyledPromotionTitle = styled(Box)(({ theme }) => ({
	maxWidth: '75%',
	fontSize: 24,
	color: theme.palette.neutral.dark,
	fontFamily: 'Rubik',
	fontWeight: 500,
	lineHeight: '28px',
	wordBreak: 'break-word',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	WebkitLineClamp: '1',
	WebkitBoxOrient: 'vertical',
	display: '-webkit-box',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 16,
		lineHeight: '24px',
		maxWidth: '65%',
	},
}));

export const StyledPromotionTag = styled(Box)(({ theme }) => ({
	display: 'flex',
	fontSize: 18,
	color: theme.palette.primary.main,
	fontFamily: 'Rubik',
	fontWeight: 500,
	lineHeight: '24px',
	alignItems: 'center',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 16,
		lineHeight: '24px',
	},
}));

export const StyledPromotionTagIcon = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	'@media only screen and (max-width: 1400px)': {
		'& svg': {
			width: 20,
			height: 16,
		},
	},
}));

export const StyledPurpleDot = styled(PurpleDot)(() => ({
	alignSelf: 'center',
	marginRight: 8,
	marginLeft: 12,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 16,
		lineHeight: '24px',
	},
}));

export const StyledPromotionDescription = styled(Box)(({ theme }) => ({
	display: 'flex',
	fontSize: 16,
	color: theme.palette.neutral.dark1,
	fontFamily: 'Rubik',
	fontWeight: 400,
	lineHeight: '20px',
	marginTop: 12,
	width: '94%',
	overflowWrap: 'anywhere',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 10,
		lineHeight: '14px',
		marginTop: 5,
	},
}));

export const StyledPriceAndCoupounsContainer = styled(Box)(() => ({
	display: 'flex',
	marginTop: 40,
	'@media only screen and (max-width: 1400px)': {
		marginTop: 10,
	},
}));

export const StyledPromotionPriceContainer = styled(Box)(() => ({
	display: 'block',
	marginRight: 28,
}));

export const StyledPromotionPrice = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 400,
	height: 20,
	color: theme.palette.neutral.dark,
	border: '1px solid',
	borderColor: theme.palette.neutral.dark2,
	borderRadius: 8,
	padding: '15px 25px 15px 15px',
	minWidth: 88,
	maxWidth: 130,
	overflow: 'hidden',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 10,
		height: 10,
		width: 64,
		padding: '8px 5px 8px 5px',
	},
}));

export const StyledOldPriceIcon = styled(OldPriceIcon)(() => ({
	minWidth: 24,
	height: 20,
	marginRight: 10,
}));

export const StyledSalePriceIcon = styled(SalePriceIcon)(() => ({
	minWidth: 24,
	height: 20,
	marginRight: 10,
}));

export const StyledPromotionText = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 500,
	height: 20,
	marginBottom: 8,
	color: theme.palette.neutral.dark,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 13,
		lineHeight: '18px',
	},
}));

export const StyledPromotionCouponContainer = styled(Box)(() => ({
	display: 'block',
	marginRight: 17,
	'@media only screen and (max-width: 1400px)': {
		marginRight: 12,
	},
}));

export const StyledPromotionDateContainer = styled(Box)(() => ({
	display: 'flex',
	marginTop: 40,
	'@media only screen and (max-width: 1400px)': {
		marginTop: 14,
	},
}));

export const StyledDateContainer = styled(Box)(() => ({
	display: 'block',
	marginRight: 10,
}));

export const StyledPromotionDate = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 400,
	height: 20,
	color: theme.palette.neutral.dark,
	border: '1px solid',
	borderColor: theme.palette.neutral.dark2,
	borderRadius: 8,
	padding: '15px 25px 15px 15px',
	minWidth: 88,
	maxWidth: 130,
	overflow: 'hidden',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 10,
		height: 10,
		width: 64,
		padding: '8px 5px 8px 5px',
	},
}));

export const StyledPromotionHour = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 400,
	height: 20,
	color: theme.palette.neutral.dark,
	border: '1px solid',
	borderColor: theme.palette.neutral.dark2,
	borderRadius: 8,
	padding: '15px 25px 15px 15px',
	width: 62,
	marginRight: 20,
	overflow: 'hidden',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 10,
		height: 10,
		width: 64,
		padding: '8px 5px 8px 5px',
	},
}));

export const StyledButtonContainer = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
	height: 52,
	width: 323,
	borderRadius: 8,
	marginTop: 44,
	backgroundColor: theme.palette.primary.main,
	padding: '12px 32px',
	'&:hover': {
		backgroundColor: theme.palette.primary.main,
	},
	'@media only screen and (max-width: 1400px)': {
		width: 200,
		marginTop: 18,
		height: 40,
	},
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
	textTransform: 'none',
	width: '100%',
	fontSize: 16,
	color: theme.palette.neutral.light,
	fontFamily: 'Rubik',
	fontWeight: 700,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
	},
}));
