import { Box, styled, Typography } from '@mui/material';

export const StyledContainer = styled(Box)(() => ({
	width: '100%',
	display: 'grid',
	gridTemplateRows: '0.1fr 3fr 1fr',
}));

export const FifthGridFirstRow = styled(Box)(() => ({
	gridRow: '1',
	display: 'flex',
}));

export const FifthGridFirstRowTitle = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 32,
	color: theme.palette.neutral.light,
	'@media (orientation: portrait)': {
		display: 'flex',
		fontSize: 22,
		justifyContent: 'center',
		width: '100%',
		color: theme.palette.neutral.light,
	},
}));

export const FifthGridSecondRow = styled(Box)(() => ({
	gridRow: '2',
	display: 'flex',
}));

export const StyledQuestions = styled(Box)(() => ({
	width: '100%',
	marginTop: 52,
	maxHeight: 240,
	'@media (orientation: portrait)': {
		marginLeft: 22,
	},
}));

export const StyledAccordionText = styled(Typography)(() => ({
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 24,
	'@media (orientation: portrait)': {
		fontSize: 14,
	},
}));

export const StyledAccordionInsideText = styled(Typography)(() => ({
	fontFamily: 'Rubik',
	fontWeight: 400,
	fontSize: 18,
	'@media (orientation: portrait)': {
		fontSize: 12,
	},
}));

export const StyledAccordion = styled(Box)(() => ({
	marginBottom: 10,
	'@media (orientation: portrait)': {
		width: '95%',
	},
}));
