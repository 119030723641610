import { FormControl, MenuItem } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { ReactComponent as InformationIcon } from '../../../../assets/images/informationIcon.svg';
import {
	DataFormProps,
	ICategories,
} from '../../../../pages/Profile/interface/profileCategories.interface';
import categoriesFormat from '../../../../shared/helpers/categoriesFormat';
import { ProfileFormDataProps } from '../../../../utils/api/interfaces';
import {
	CategoryAndPayment,
	DataFormContainer,
	StyledCategoriesDescription,
	StyledCategoriesTextField,
	StyledDescriptionTextField,
	StyledNameTextField,
	StyledProfileDescription,
	StyledProfileName,
	StyledUserWarning,
} from './styles';

const formHelperStyle = {
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 400,
};

const ProfileDataForm: React.FC<DataFormProps> = ({
	categories,
}: DataFormProps): JSX.Element => {
	const {
		values: {
			advertiserInfos: {
				advertiserName,
				advertiserDescription,
				advertiserCategoryId,
			},
		},
		handleChange,
		touched,
		errors,
	} = useFormikContext<ProfileFormDataProps>();

	return (
		<DataFormContainer>
			<StyledUserWarning>
				<InformationIcon style={{ marginRight: 10 }} />
				Essas informações ficarão visíveis para os usuários no aplicativo.
			</StyledUserWarning>

			<CategoryAndPayment>
				<StyledProfileName>
					<FormControl fullWidth>
						<StyledNameTextField
							id="outlined-basic"
							label="Nome da Loja"
							value={advertiserName ?? ''}
							variant="outlined"
							onChange={handleChange('advertiserInfos.advertiserName')}
							error={
								touched.advertiserInfos?.advertiserName &&
								Boolean(errors.advertiserInfos?.advertiserName)
							}
							helperText={
								touched.advertiserInfos?.advertiserName &&
								errors.advertiserInfos?.advertiserName
							}
							FormHelperTextProps={{
								style: formHelperStyle,
							}}
						/>
					</FormControl>
				</StyledProfileName>
				<StyledCategoriesDescription>
					<FormControl>
						<StyledCategoriesTextField
							select
							id="outlined-basic"
							type="text"
							value={advertiserCategoryId ?? ''}
							defaultValue={''}
							label="Categoria"
							variant="outlined"
							onChange={handleChange('advertiserInfos.advertiserCategoryId')}
							error={
								touched.advertiserInfos?.advertiserCategoryId &&
								Boolean(errors.advertiserInfos?.advertiserCategoryId)
							}
							helperText={
								touched.advertiserInfos?.advertiserCategoryId &&
								errors.advertiserInfos?.advertiserCategoryId
							}
							FormHelperTextProps={{
								style: formHelperStyle,
							}}
							SelectProps={{
								MenuProps: {
									sx: { maxHeight: '20%' },
								},
							}}
						>
							{categories.map((categories: ICategories) => (
								<MenuItem key={categories.id} value={categories.id}>
									{categoriesFormat(categories.name)}
								</MenuItem>
							))}
						</StyledCategoriesTextField>
					</FormControl>
				</StyledCategoriesDescription>
			</CategoryAndPayment>

			<StyledProfileDescription>
				<FormControl fullWidth>
					<StyledDescriptionTextField
						id="outlined-basic"
						label="Descrição da Loja"
						value={advertiserDescription ?? ''}
						variant="outlined"
						multiline
						onChange={handleChange('advertiserInfos.advertiserDescription')}
						error={
							touched.advertiserInfos?.advertiserDescription &&
							Boolean(errors.advertiserInfos?.advertiserDescription)
						}
						helperText={
							touched.advertiserInfos?.advertiserDescription &&
							errors.advertiserInfos?.advertiserDescription
						}
						inputProps={{ maxLength: 200 }}
						FormHelperTextProps={{
							style: formHelperStyle,
						}}
					/>
				</FormControl>
			</StyledProfileDescription>
		</DataFormContainer>
	);
};

export default ProfileDataForm;
