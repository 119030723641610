import { AxiosResponse } from 'axios';
import { BASE_URL } from '../api';
import { request } from '../base-request/base-request';
export const createNewCategory = async (
  name: string
): Promise<AxiosResponse<any>> => {
  try {
    const response = await request({
      url: `${BASE_URL}/showcaseCategories`,
      data: { name },
      method: 'POST',
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
