import { AxiosResponse } from "axios";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";

export const getAdvertiserCategories = async (): Promise<
  AxiosResponse<any> | undefined
> => {
  try {
    return request({ url: `${BASE_URL}/categories`, method: "get" });
  } catch (error) {
    return Promise.reject(error);
  }
};
