import { ADD_AUTH, REMOVE_AUTH } from "../constants/auth-config.constants";
import { AuthAction, IAuth } from "../interface/auth.interface";

export const addAuth = (auth: IAuth): AuthAction => ({
  type: ADD_AUTH,
  payload: auth,
});

export const removeAuth = (auth: IAuth): AuthAction => ({
  type: REMOVE_AUTH,
  payload: auth,
});
