import { Box } from "@mui/material";
import { ReactComponent as GoldIconChosenPlan } from "../../../assets/images/GoldIconChosenPlan.svg";
import {
  AlignIconAndName,
  AlignPlanValue,
  Container,
  InfoContent,
  InfoText,
  InfoTitle,
  InitialContent,
  NameAndSubtitle,
  PlanName,
  PlanValue,
  PlanValueText,
  Renovation,
  RenovationText,
  Subtitle,
} from "./styles";

export const InforConfirmPlan: React.FC = (): JSX.Element => (
  <Container>
    <InitialContent>
      <AlignIconAndName>
        <GoldIconChosenPlan />
        <NameAndSubtitle>
          <PlanName>Gold</PlanName>
          <Subtitle>Assinatura mensal</Subtitle>
        </NameAndSubtitle>
      </AlignIconAndName>
      <AlignPlanValue>
        <PlanValue>R$149,90</PlanValue>
        <PlanValueText>/mês</PlanValueText>
      </AlignPlanValue>
    </InitialContent>
    <InfoContent>
      <Box>
        <InfoTitle>Informações</InfoTitle>
        <InfoText>
          Os créditos adquiridos no plano não são acumulativos.
        </InfoText>
        <InfoText>
          Assinatura recorrente com renovação automática em 20/08/2022. Para não
          ser cobrado, cancele antes dessa data.
        </InfoText>
      </Box>
      <Renovation>
        <RenovationText>Renova em 20/08/2022</RenovationText>
      </Renovation>
    </InfoContent>
  </Container>
);

export default InforConfirmPlan;
