import { AxiosResponse } from "axios";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";
export const createNewPassword = async (
  token: string,
  password: string,
  password_confirm: string
): Promise<AxiosResponse<any>> => {
  try {
    const response = await request({
      url: `${BASE_URL}/password/reset/${token}`,
      data: { password, password_confirm },
      method: "POST",
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
