import { ScriptableContext } from "chart.js";
import React from "react";
import { Line } from "react-chartjs-2";
import { CHART_OPTIONS } from "../Dashboard/DashboardChart/constants/chartOptions-config";

import EmptyCard from "../Dashboard/EmptyCard/EmptyCard";
import { ILineChart } from "./interface/lineChart.interface";
import {
  StyledChartTitle,
  StyledChartTitleTime,
  StyledTitleContainer,
} from "./styles";

const ChartBackgroundColor = (context: ScriptableContext<"line">) => {
  const ctx = context.chart.ctx;
  const gradient = ctx.createLinearGradient(0, 0, 0, 330);
  gradient.addColorStop(0, "rgba(96, 41, 177, 0.3");
  gradient.addColorStop(1, "rgba(96, 41, 177, 0");
  return gradient;
};

const LineChart: React.FC<ILineChart> = ({
  labels,
  data,
  label,
}: ILineChart): JSX.Element => {
  return (
    <>
      {data ? (
        <>
          <StyledTitleContainer>
            <StyledChartTitle>{label}</StyledChartTitle>
            <StyledChartTitleTime>Últimos 7 dias</StyledChartTitleTime>
          </StyledTitleContainer>
          <Line
            data={{
              labels: labels,
              datasets: [
                {
                  label: label,
                  data,
                  fill: true,
                  backgroundColor: ChartBackgroundColor,
                  borderColor: "#412272",
                  tension: 0.4,
                },
              ],
            }}
            options={CHART_OPTIONS}
          />
        </>
      ) : (
        <>
          <StyledChartTitle>{label}</StyledChartTitle>
          <EmptyCard
            text={
              "Ainda não há estatísticas disponíveis, crie uma promoção para começar."
            }
            image={"stats"}
          />
        </>
      )}
    </>
  );
};

export default LineChart;
