import { Box, Button, IconButton, styled } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

interface StyledLougoutButtonProp {
	isHovered?: boolean;
}

export const Drawer = styled(MuiDrawer)(({ theme }) => ({
	position: 'fixed',
	top: 0,
	bottom: 0,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	zIndex: 2,
	boxSizing: 'border-box',
	backgroundColor: theme.palette.primary.dark,
	color: theme.palette.neutral.light,
	border: 'none',
	overflowX: 'hidden',
	transition: 'transform 0.5s cubic-bezier(0.4, 0, 0.2, 1)',
	'& .MuiDrawer-paper': {
		backgroundColor: theme.palette.primary.dark,
		width: 272,
	},
	'&.collapsed': {
		'& .MuiDrawer-paper': {
			backgroundColor: theme.palette.primary.dark,
			width: '80px!important',
		},
	},

	'@media only screen and (max-width: 1700px)': {
		'& .MuiDrawer-paper': {
			width: 222,
		},
		'&.collapsed': {
			'& .MuiDrawer-paper': {
				backgroundColor: theme.palette.primary.dark,
				width: '80px!important',
			},
		},
	},
	'@media only screen and (max-width: 1200px)': {
		'& .MuiDrawer-paper': {
			width: 150,
		},
		'&.collapsed': {
			'& .MuiDrawer-paper': {
				backgroundColor: theme.palette.primary.dark,
				width: '80px!important',
			},
		},
	},
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: theme.spacing(2),
	'@media only screen and (max-height: 700px)': {
		paddingBottom: 0,
	},
}));

export const StyledContainerOpenImage = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
	marginTop: 24,
	marginBottom: 50,
	width: '188px',
	cursor: 'pointer',
	'@media (max-width: 1700px)': {
		marginBottom: 0,
	},
	'@media only screen and (max-width: 1200px)': {
		marginTop: 0,
		marginBottom: 0,
		width: '80px',
		'& svg': {
			height: 50,
			width: 80,
		},
	},
	'@media only screen and (max-height: 700px)': {
		marginTop: 0,
		marginBottom: 0,
		width: '150px',
	},
}));

export const StyledOpenLogo = styled('img')(() => ({
	width: '188px',
	position: 'relative',
}));

export const StyledContainerCloseImage = styled('div')(() => ({
	display: 'flex',
	width: '55px',
	height: '55px',
	justifyContent: 'center',
	marginTop: 49,
	marginBottom: 80,
	marginRight: 14,
}));

export const StyledCloseLogo = styled('img')(() => ({
	position: 'relative',
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
	backgroundColor: theme.palette.neutral.light,
	position: 'absolute',
	width: 30,
	height: 30,
	top: 20,
	left: '220px',
	'&:hover': {
		backgroundColor: theme.palette.neutral.light,
	},
}));

export const StyledLougoutButton = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'isHovered',
})<StyledLougoutButtonProp>(({ isHovered }) => ({
	position: 'fixed',
	width: isHovered ? 272 : 80,
	display: 'flex',
	justifyContent: 'center',
	bottom: 30,
	'@media only screen and (max-width: 1700px)': {
		width: isHovered ? 222 : 80,
		bottom: 20,
	},
	'@media only screen and (max-width: 1200px)': {
		width: isHovered ? 150 : 80,
		bottom: 10,
	},
	'@media only screen and (max-height: 700px)': {
		marginTop: 0,
		marginBottom: 0,
		bottom: 10,
	},
}));

export const StyledButtonContainer = styled(Button)(() => ({
	marginBottom: '6px',
	cursor: 'auto',
	'@media only screen and (max-width: 1200px)': {
		marginBottom: 0,
	},
	'@media only screen and (max-height: 700px)': {
		marginBottom: 0,
		paddingBlock: 0,
	},
}));
