import { AxiosError } from 'axios';
import { Formik, FormikValues } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AuthAction, IAuth } from '../../auth/interface/auth.interface';
import { IReducers } from '../../auth/store/store';
import DashboardTopBar from '../../components/DashboardTopBar/DashboardTopBar';
import AddressForm from '../../components/Forms/Profile/AddressForm/AddressForm';
import ContactForm from '../../components/Forms/Profile/ContactForm/ContactForm';
import { IProfileSocials } from '../../components/Forms/Profile/ContactForm/interface/socials.interface';
import ImageForm from '../../components/Forms/Profile/ImageForm/ImageForm';
import OpeningHoursForm from '../../components/Forms/Profile/OpeningHoursForm/OpeningHoursForm';
import VALIDATION_OPENING_HOURS_SCHEMA from '../../components/Forms/Profile/OpeningHoursForm/constants/openingHours-validationSchema';
import ProfileDataForm from '../../components/Forms/Profile/ProfileDataForm/ProfileDataForm';
import { getAdvertiser } from '../../utils/api/get/getAdvertiser';
import { getAdvertiserAddress } from '../../utils/api/get/getAdvertiserAddress';
import { getAdvertiserCategories } from '../../utils/api/get/getAdvertiserCategories';
import { getAdvertiserSocials } from '../../utils/api/get/getAdvertiserSocials';
import {
	AddressProps,
	ICurrentPayments,
	LocationState,
	OpeningHoursDataProps,
	UpdatedCurrentPayments,
} from '../../utils/api/interfaces';

import { Box, CircularProgress } from '@mui/material';
import { addAuth } from '../../auth/actions/auth.action';
import VALIDATION_PROFILE_SCHEMA from '../../components/Forms/Profile/AddressForm/constants/profile-validationSchema';
import { INITIAL_VALUES_OPENING_HOURS_FORM } from '../../components/Forms/Profile/OpeningHoursForm/constants/initial-values-form.constant';
import { IDeliveryOptionsGet } from '../../components/Forms/Profile/OpeningHoursForm/interface/openingHoursInitialValue.interface';
import PaymentForm from '../../components/Forms/Profile/PaymentForm/PaymentForm';
import DeliveryModal from '../../components/Menu/DeliveryModal/DeliveryModal';
import { getCurrentPayments } from '../../utils/api/get/getCurrentPayments';
import { patchProfileInfo } from '../../utils/api/patch/patchProfileInfo';
import { patchProfileOpeningHours } from '../../utils/api/patch/patchProfileOpeningHours';
import { patchProfilePayments } from '../../utils/api/patch/patchProfilePayments';
import { onlyNumbers } from '../../utils/mask.util';
import { StyledLoadingContainer } from '../Menu/styles';
import { PROFILE_DAYS } from './enum/profileDays.enum';
import { PROFILE_FORM_STEP } from './enum/profileFormStep.enum';
import { PROFILE_SOCIALS } from './enum/profileSocials.enum';
import {
	AdvertiserSocialMedia,
	ProfileAdvertiserProps,
} from './interface/profileAdvertiser.interface';
import { ICategories } from './interface/profileCategories.interface';
import {
	ProfileTab,
	ProfileTabs,
	StyledCreateProfileContainer,
	StyledProfile,
	StyledProfileContainer,
	StyledProfileContent,
	StyledProfileData,
	StyledProfileFormsContainer,
	StyledProfileTopBar,
	StyledTabsContainer,
} from './styles';

const Profile: React.FC = (): JSX.Element => {
	const location = useLocation();
	const tab = (location.state as LocationState)?.tab ?? 0;

	const [selectedTab, setSelectedTab] = useState(tab);
	const [profileAddress, setProfileAddress] = useState<AddressProps>();
	const [advertiser, setAdvertiser] = useState<ProfileAdvertiserProps>();
	const [advertiserSocialMedias, setAdvertiserSocialMedias] = useState<
		AdvertiserSocialMedia[]
	>([]);
	const [advertiserOpeningHours, setAdvertiserOpeningHours] = useState<
		OpeningHoursDataProps[]
	>([]);
	const [advertiserDeliveryOptions, setAdvertiserDeliveryOptions] =
		useState<IDeliveryOptionsGet>();
	const [profileSocials, setProfileSocials] = useState<IProfileSocials[]>([]);
	const [loaderAdvertiser, setLoaderAdvertiser] = useState(true);
	const [categories, setCategories] = useState<ICategories[]>([]);
	const [openHours, setOpenHours] = useState('');
	const [closeHours, setCloseHours] = useState('');
	const [currentPayment, setCurrentPayment] = useState<ICurrentPayments>();
	const [openDeliveryModal, setOpenDeliveryModal] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { advertiser_id }: IAuth = useSelector(
		(state: IReducers) => state.auth
	);
	const unsubscribe = useRef<boolean>();
	const dispatch = useDispatch();
	const setAuth = (auth: IAuth): AuthAction => dispatch(addAuth(auth));

	const auth: IAuth = useSelector((state: IReducers) => state.auth);

	const handleCloseDeliveryModal = (): void => setOpenDeliveryModal(false);

	const handleOpenDeliveryModal = (): void => setOpenDeliveryModal(true);

	const handleTabChange = (
		event: React.SyntheticEvent,
		newValue: number
	): void => setSelectedTab(newValue);

	const handleProfileOpeningHours = (
		values: FormikValues,
		setSubmit: (isSubmitting: boolean) => void
	): void => {
		const { openingHours } = values;
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { id, pickUpTimeStart, pickUpTimeEnd, ...deliveryOptionsWithoutId } =
			values.deliveryOptions;

		const pickUpTime = `${pickUpTimeStart}-${pickUpTimeEnd}`;

		const deliveryOptions = {
			...deliveryOptionsWithoutId,
			pickUpTime,
		};

		patchProfileOpeningHours({
			openingHours,
			advertiser_id,
			deliveryOptions,
		})
			.then(() => {
				setSubmit(false);
				setAdvertiserOpeningHours(openingHours);
				setAdvertiserDeliveryOptions(deliveryOptions);

				enqueueSnackbar('Salvo com sucesso.', {
					variant: 'success',
					autoHideDuration: 1500,
				});
			})

			.catch((error: AxiosError) => {
				if (error) {
					setSubmit(false);
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	const handleProfileInfo = (
		values: FormikValues,
		setSubmit: (isSubmitting: boolean) => void
	): void => {
		const { advertiserInfos, advertiserAddress, advertiserContacts } = values;

		if (advertiserAddress.cep) {
			advertiserAddress.cep = advertiserAddress.cep
				.toString()
				.replace(/[^0-9]+/g, '');
		}

		if (advertiserContacts.cellphone) {
			advertiserContacts.cellphone = onlyNumbers(advertiserContacts.cellphone);
		}
		if (advertiserContacts.phone) {
			advertiserContacts.phone = onlyNumbers(advertiserContacts.phone);
		}

		patchProfileInfo(
			advertiser_id,
			advertiserInfos,
			advertiserAddress,
			advertiserContacts
		)
			.then((res) => {
				setSubmit(false);
				setAdvertiser(res.data);
				enqueueSnackbar('Salvo com sucesso.', {
					variant: 'success',
					autoHideDuration: 1500,
				});
				setAuth({
					...auth,
					advertiser_id,
					advertiserName: advertiserInfos.advertiserName,
				});
				setProfileAddress(advertiserAddress);
				updateAdvertiser();
			})
			.catch((error: AxiosError) => {
				if (error) {
					setSubmit(false);
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	const updateAdvertiserAddress = (): void => {
		getAdvertiserAddress(advertiser_id)
			.then((res) => {
				setProfileAddress(res?.data);
			})
			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	const updateAdvertiserOpeningHours = (): void => {
		const hoursEnum = PROFILE_DAYS;
		if (advertiserOpeningHours) {
			const updateHours = advertiserOpeningHours
				.map((item) => {
					const newDayOfWeek =
						hoursEnum[item.day_of_week as keyof typeof PROFILE_DAYS];
					return {
						...item,
						day_of_week: newDayOfWeek,
						day: item.day_of_week,
					};
				})
				.sort((a, b) => Number(a.day_of_week) - Number(b.day_of_week));
			setAdvertiserOpeningHours(updateHours);
		}
	};

	const handleInitialHours = (): void => {
		if (advertiserOpeningHours) {
			const initialHour = advertiserOpeningHours.find((item) => item.is_active);
			setOpenHours(initialHour ? initialHour.opening_hour : '00:00');
			setCloseHours(initialHour ? initialHour.close_hour : '00:00');
		}
	};

	const updateSocials = (): void => {
		const socialsEnum = PROFILE_SOCIALS;
		if (advertiser?.advertiserSocialMedias) {
			const updatedSocials = advertiser.advertiserSocialMedias.map((item) => {
				const socialMedia =
					socialsEnum[item.social_media as keyof typeof PROFILE_SOCIALS];
				return { ...item, social_media: socialMedia };
			});
			setAdvertiserSocialMedias(updatedSocials);
		}
	};

	const updateAdvertiser = (): void => {
		getAdvertiser(advertiser_id)
			.then((res) => {
				setAdvertiser(res?.data);
				setAdvertiserOpeningHours(res?.data.advertiserOpeningHours);
				setAdvertiserDeliveryOptions(res?.data.deliveryOption);
				setLoaderAdvertiser(false);
			})
			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	const getSocialsOptions = (): void => {
		getAdvertiserSocials()
			.then((res) => {
				setProfileSocials(res?.data);
			})
			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	const getProfileCategories = (): void => {
		getAdvertiserCategories()
			.then((res) => {
				setCategories(res?.data);
			})
			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	const getProfilePayments = (): void => {
		getCurrentPayments(advertiser_id)
			.then((res) => {
				setCurrentPayment(res?.data);
			})
			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	const updatePaymentData = (
		data: ICurrentPayments
	): UpdatedCurrentPayments => {
		const creditCardActive = data.credit.some((card) => card.active);
		const debitCardActive = data.debit.some((card) => card.active);

		const cardBannerIds = [
			...data.credit.filter((card) => card.active).map((card) => card.id),
			...data.debit.filter((card) => card.active).map((card) => card.id),
		];

		return {
			cashDown: {
				cash: data.cashDown.cash,
				pix: data.cashDown.pix,
				credit_card: creditCardActive,
				debit_card: debitCardActive,
			},
			cardBannerIds,
		};
	};

	const handleProfilePayments = (
		values: FormikValues,
		setSubmit: (isSubmitting: boolean) => void
	): void => {
		const newPaymentOptions = updatePaymentData(values as ICurrentPayments);

		patchProfilePayments(advertiser_id, newPaymentOptions)
			.then(() => {
				setSubmit(false);
				setCurrentPayment(values as ICurrentPayments);
				enqueueSnackbar('Salvo com sucesso.', {
					variant: 'success',
					autoHideDuration: 1500,
				});
			})
			.catch((error: AxiosError) => {
				if (error) {
					setSubmit(false);
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};
	useEffect(() => {
		unsubscribe.current = true;
		if (unsubscribe) {
			updateAdvertiserAddress();
			updateAdvertiser();
			getSocialsOptions();
			getProfileCategories();
			getProfilePayments();
			return () => {
				unsubscribe.current = false;
			};
		}
	}, []);

	useEffect(() => {
		updateSocials();
		updateAdvertiserOpeningHours();
	}, [loaderAdvertiser, setLoaderAdvertiser]);

	useEffect(() => {
		handleInitialHours();
	}, [advertiserOpeningHours, setAdvertiserOpeningHours]);

	useEffect(() => {
		setSelectedTab(tab);
	}, [location]);

	return (
		<StyledProfile>
			<StyledProfileContainer>
				<StyledProfileTopBar>
					<DashboardTopBar
						title={'Meu Perfil'}
						text={'Visualize e edite informações sobre sua loja'}
						menu={true}
						handleOpenDeliveryModal={handleOpenDeliveryModal}
					/>
					{openDeliveryModal && (
						<DeliveryModal
							handleCloseDeliveryModal={handleCloseDeliveryModal}
							openDeliveryModal={openDeliveryModal}
						/>
					)}
				</StyledProfileTopBar>
				<StyledProfileFormsContainer>
					<StyledProfileContent>
						<StyledCreateProfileContainer>
							<StyledTabsContainer>
								<ProfileTabs
									value={selectedTab}
									onChange={handleTabChange}
									TabIndicatorProps={{ sx: { display: 'none' } }}
								>
									<ProfileTab label="Dados" disabled={false} />
									<ProfileTab
										label="Funcionamento"
										disabled={loaderAdvertiser}
									/>
									<ProfileTab label="Pagamento" disabled={loaderAdvertiser} />
								</ProfileTabs>
							</StyledTabsContainer>
							<StyledProfileData>
								{selectedTab === PROFILE_FORM_STEP.FORM_PROFILE_DATA && (
									<>
										{loaderAdvertiser ? (
											<StyledLoadingContainer>
												<CircularProgress color="primary" />
											</StyledLoadingContainer>
										) : (
											<Formik
												enableReinitialize
												initialValues={{
													advertiserInfos: {
														advertiserName: advertiser?.profile?.name || '',
														advertiserDescription:
															advertiser?.description || '',
														advertiserCategoryId:
															advertiser?.advertiser_category_id || '',
													},
													advertiserAddress: {
														addressId: profileAddress ? profileAddress.id : '',
														cep: profileAddress ? profileAddress.cep : '',
														state: profileAddress ? profileAddress.state : '',
														city: profileAddress ? profileAddress.city : '',
														address: profileAddress
															? profileAddress.address
															: '',
														number: profileAddress ? profileAddress.number : '',
														district: profileAddress
															? profileAddress.district
															: '',
														complement: profileAddress
															? profileAddress.complement
															: '',
													},
													advertiserContacts: {
														phone: advertiser?.phone || '',
														cellphone: advertiser?.profile?.cellphone || '',
														socialMedias: !advertiser?.advertiserSocialMedias
															?.length
															? [
																	{
																		social_media: '',
																		link: '',
																		is_active: true,
																	},
															  ]
															: advertiserSocialMedias,
													},
												}}
												validationSchema={VALIDATION_PROFILE_SCHEMA}
												validateOnChange={false}
												onSubmit={(values, { setSubmitting }) => {
													handleProfileInfo(values, setSubmitting);
												}}
											>
												{({ handleSubmit }) => (
													<form
														onSubmit={(e) => {
															e.preventDefault();
															handleSubmit();
														}}
													>
														<Box style={{ display: 'flex', width: '100%' }}>
															<Box className={'form'} style={{ width: '35%' }}>
																<ImageForm advertiser={advertiser} />
															</Box>
															<Box className={'form'} style={{ width: '65%' }}>
																<ProfileDataForm categories={categories} />
															</Box>
														</Box>
														<Box className={'form'}>
															<AddressForm />
														</Box>
														<Box className={'form'}>
															<ContactForm profileSocials={profileSocials} />
														</Box>
													</form>
												)}
											</Formik>
										)}
									</>
								)}
								{selectedTab === PROFILE_FORM_STEP.FORM_OPEN_HOURS_DATA && (
									<>
										<Formik
											enableReinitialize
											initialValues={
												advertiserOpeningHours.length
													? {
															openingHours: advertiserOpeningHours,
															deliveryOptions: {
																deliveryAvailable:
																	advertiserDeliveryOptions?.deliveryAvailable,
																pickUpAvailable:
																	advertiserDeliveryOptions?.pickUpAvailable,
																pickUpTimeStart:
																	advertiserDeliveryOptions?.pickUpTime
																		? parseInt(
																				advertiserDeliveryOptions.pickUpTime.split(
																					'-'
																				)[0],
																				10
																		  )
																		: undefined,
																pickUpTimeEnd:
																	advertiserDeliveryOptions?.pickUpTime
																		? parseInt(
																				advertiserDeliveryOptions.pickUpTime.split(
																					'-'
																				)[1],
																				10
																		  )
																		: undefined,
															},
													  }
													: INITIAL_VALUES_OPENING_HOURS_FORM
											}
											validationSchema={VALIDATION_OPENING_HOURS_SCHEMA}
											onSubmit={(values, { setSubmitting }) => {
												handleProfileOpeningHours(values, setSubmitting);
											}}
										>
											{({ handleSubmit }) => (
												<form
													onSubmit={(e) => {
														e.preventDefault();
														handleSubmit();
													}}
												>
													<OpeningHoursForm
														openHours={openHours}
														closeHours={closeHours}
													/>
												</form>
											)}
										</Formik>
									</>
								)}
								{selectedTab === PROFILE_FORM_STEP.FORM_PAYMENT_DATA && (
									<>
										<Formik
											initialValues={{
												cashDown: currentPayment?.cashDown,
												debit: currentPayment?.debit,
												credit: currentPayment?.credit,
											}}
											enableReinitialize
											onSubmit={(values, { setSubmitting }) => {
												handleProfilePayments(values, setSubmitting);
											}}
										>
											{({ handleSubmit }) => (
												<form
													onSubmit={(e) => {
														e.preventDefault();
														handleSubmit();
													}}
												>
													<PaymentForm />
												</form>
											)}
										</Formik>
									</>
								)}
							</StyledProfileData>
						</StyledCreateProfileContainer>
					</StyledProfileContent>
				</StyledProfileFormsContainer>
			</StyledProfileContainer>
		</StyledProfile>
	);
};

export default Profile;
