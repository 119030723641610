import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as XIcon } from '../../../../assets/images/XIcon.svg';
import { ReactComponent as EditPen } from '../../../../assets/images/editPen.svg';
import { ReactComponent as EmptyIcon } from '../../../../assets/images/emptyImage.svg';
import { ProfileAdvertiserProps } from '../../../../pages/Profile/interface/profileAdvertiser.interface';
import { convertToBase64 } from '../../../../shared/helpers/convertToBase64.helper';
import { patchProfileAvatar } from '../../../../utils/api/patch/patchProfileAvatar';
import { patchProfileHeader } from '../../../../utils/api/patch/patchProfileHeader';
import {
	Input,
	StyledEditHeaderContainer,
	StyledEditProfile,
	StyledEmptyImage,
	StyledProfileDescription,
	StyledProfileFields,
	StyledProfileFieldsContainer,
	StyledProfileName,
	StyledPromotionAvatar,
	StyledPromotionHeader,
	StyledPromotionProfileExample,
	StyledUploadAvatarEditButton,
	StyledUploadAvatarRemoveButton,
	StyledUploadFileContent,
	StyledUploadFileTypography,
	StyledUploadHeaderEditButton,
} from './styles';

interface ImageFormProps {
	advertiser: ProfileAdvertiserProps | undefined;
}

const ImageForm: React.FC<ImageFormProps> = ({ advertiser }): JSX.Element => {
	const [editMode, setEditMode] = useState<boolean>(false);
	const [headerState, setHeaderState] = useState({
		path: '',
	});
	const [avatarState, setAvatarState] = useState({
		path: '',
	});
	const { enqueueSnackbar } = useSnackbar();
	const inputRef = useRef<HTMLInputElement>(null);

	const handleEditMode = (): void => {
		setEditMode(!editMode);
	};

	const triggerFileInputClick = (): void => {
		if (inputRef.current) {
			inputRef.current.click();
		}
	};

	const handleHeaderChange = async (e: any): Promise<void> => {
		try {
			const file = e.target.files[0];

			const base64 = await convertToBase64(file);
			patchProfileHeader(base64 as string);
			setHeaderState({
				...headerState,
				path: URL.createObjectURL(e.target.files[0]),
			});
		} catch {
			enqueueSnackbar('Ocorreu um erro tente novamente.', {
				variant: 'error',
				autoHideDuration: 1500,
			});
		}
	};

	const handleHeaderRemove = (): void => {
		setHeaderState({
			path: '',
		});
	};

	const handleAvatarRemove = (): void => {
		setAvatarState({
			path: '',
		});
	};

	const handleAvatarChange = async (e: any): Promise<void> => {
		try {
			const file = e.target.files[0];
			const base64 = await convertToBase64(file);
			patchProfileAvatar(base64 as string);
			setAvatarState({
				...avatarState,
				path: URL.createObjectURL(e.target.files[0]),
			});
		} catch {
			enqueueSnackbar('Ocorreu um erro tente novamente.', {
				variant: 'error',
				autoHideDuration: 1500,
			});
		}
	};

	useEffect(() => {
		if (advertiser) {
			setHeaderState({
				...headerState,
				path: advertiser.image_header as string,
			});

			setAvatarState({
				...avatarState,
				path: advertiser.profile.avatar as string,
			});
		}
	}, [advertiser]);
	return (
		<>
			<StyledPromotionHeader>
				{headerState.path === '' && (
					<label>
						<Input
							type="file"
							name="photo"
							onChange={handleHeaderChange}
							accept="image/*"
							multiple
						/>
						<StyledUploadFileContent>
							<StyledEmptyImage>
								<EmptyIcon />
							</StyledEmptyImage>
							<StyledUploadFileTypography>
								Clique aqui para selecionar a imagem
							</StyledUploadFileTypography>
						</StyledUploadFileContent>
					</label>
				)}
				{headerState.path === null && (
					<>
						<label>
							<Input
								type="file"
								name="photo"
								onChange={handleHeaderChange}
								accept="image/*"
								multiple
							/>
							<StyledUploadHeaderEditButton>
								EDITAR
							</StyledUploadHeaderEditButton>
						</label>
					</>
				)}

				{headerState.path && editMode && (
					<>
						<StyledEditHeaderContainer>
							<div
								style={{
									position: 'absolute',
									justifyContent: 'end',
									display: 'flex',
									height: '100%',
									width: '100%',
								}}
							>
								<Input
									type="file"
									name="photo"
									onChange={handleHeaderChange}
									accept="image/*"
									multiple
									ref={inputRef}
								/>
								<StyledUploadHeaderEditButton onClick={triggerFileInputClick}>
									<EditPen color="white" />
								</StyledUploadHeaderEditButton>
								<StyledUploadHeaderEditButton onClick={handleHeaderRemove}>
									<XIcon color="white" width={20} height={20} />
								</StyledUploadHeaderEditButton>
							</div>
						</StyledEditHeaderContainer>
						<img src={headerState?.path} />
					</>
				)}
				{!editMode && <img src={headerState?.path} />}
			</StyledPromotionHeader>

			<StyledPromotionProfileExample>
				<StyledProfileFieldsContainer>
					<StyledProfileFields>
						<Box sx={{ display: 'flex' }}>
							<StyledPromotionAvatar>
								{avatarState.path === '' && (
									<label
										style={{
											position: 'absolute',
											justifyContent: 'center',
											display: 'flex',
											width: '100%',
											height: '100%',
										}}
									>
										<Input
											type="file"
											name="photo"
											onChange={handleAvatarChange}
											accept="image/*"
											multiple
										/>
										<StyledUploadAvatarEditButton>
											<EmptyIcon />
										</StyledUploadAvatarEditButton>
									</label>
								)}

								{avatarState.path && editMode && (
									<>
										<StyledEditHeaderContainer>
											<label
												style={{
													position: 'absolute',
													justifyContent: 'center',
													display: 'flex',
													width: '100%',
													height: '100%',
												}}
											>
												<StyledUploadAvatarRemoveButton
													onClick={handleAvatarRemove}
												>
													<XIcon color="white" width={20} height={20} />
												</StyledUploadAvatarRemoveButton>
											</label>
										</StyledEditHeaderContainer>
										<div
											style={{
												justifyContent: 'center',
												display: 'flex',
												width: '100%',
												height: '100%',
											}}
										>
											<img
												src={avatarState?.path}
												style={{ cursor: 'pointer' }}
											/>
										</div>
									</>
								)}
								{!editMode && (
									<>
										<div
											style={{
												justifyContent: 'center',
												display: 'flex',
												width: '100%',
												height: '100%',
											}}
										>
											<img
												src={avatarState?.path}
												style={{ cursor: 'pointer' }}
											/>
										</div>
									</>
								)}
							</StyledPromotionAvatar>
							<Box sx={{ paddingY: '14px', width: '100%' }}>
								<Box
									sx={{
										display: 'flex',
										width: '100%',
										justifyContent: 'space-between',
									}}
								>
									<StyledProfileName>
										{advertiser?.profile?.name}
									</StyledProfileName>
									<StyledEditProfile onClick={handleEditMode}>
										Editar fotos
									</StyledEditProfile>
								</Box>
								<StyledProfileDescription>
									{advertiser?.description
										? advertiser?.description
										: 'Você ainda não possui uma descrição'}
								</StyledProfileDescription>
							</Box>
						</Box>
					</StyledProfileFields>
				</StyledProfileFieldsContainer>
			</StyledPromotionProfileExample>
		</>
	);
};

export default ImageForm;
