import { IconButton } from "@mui/material";
import { AxiosError } from "axios";
import _ from "lodash";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MagnifyingGlassIcon } from "../../../assets/images/magnifyingGlass.svg";
import CouponList from "../../../components/Coupons/CouponList/CouponList";
import { getCouponsListByAdvertiser } from "../../../utils/api/get/getCouponsListByAdvertiser";
import { CouponParams } from "./interface/coupon-params.interface";
import { ICouponList } from "./interface/couponList-props.interface";
import {
  CouponStatusTab,
  CouponStatusTabs,
  StyleChevronIcon,
  StyledCouponContent,
  StyledCouponDetails,
  StyledCouponDetailsContainer,
  StyledCouponDetailsTopBar,
  StyledCouponStatusTabsContainer,
  StyledCouponStatusTopContainer,
  StyledListCouponContainer,
  StyledSearchCoupon,
  StyledSearchTextField,
  StyledTextContainer,
  StyledTopBarContainer,
  StyledTopBarTextContainer,
  StyledTopBarTextIcon,
  TypographyText,
  TypographyTitle,
} from "./styles";
const CouponsManager: React.FC = (): JSX.Element => {
  const [selectedCouponStatusTab, setSelectedCouponStatusTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [coupon, setCoupon] = useState<ICouponList[]>([]);
  const [couponListLoader, setCouponListLoader] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [params, setParams] = useState<CouponParams>({
    status: "RANSOMED",
    page: 1,
    perPage: 5,
  } as CouponParams);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleCouponStatusList = (currentPage = 1, newValue: number): void => {
    setCurrentPage(currentPage);
    setSelectedCouponStatusTab(newValue);
    const params: CouponParams = {
      status: newValue === 0 ? "RANSOMED" : "USED",
      page: currentPage,
      perPage: 5,
    };
    setParams(params);
  };

  const handleCurrentPageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ): void => handleCouponStatusList(value, selectedCouponStatusTab);

  const handleCouponStatusTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ): void => handleCouponStatusList(1, newValue);

  const getCouponList = (params: CouponParams): void => {
    setCouponListLoader(true);
    getCouponsListByAdvertiser(params)
      .then((res) => {
        setCoupon(res.data);
        setTotalPages(res?.last_page);
        setCouponListLoader(false);
      })
      .catch((err: AxiosError) => {
        if (err) {
          enqueueSnackbar("Ocorreu um erro tente novamente.", {
            variant: "error",
            autoHideDuration: 1500,
          });
        }
      });
  };

  const handleSearch = (): void => {
    setCouponListLoader(true);
    getCouponsListByAdvertiser({
      ...params,
      page: 1,
      search: searchTerm,
    })
      .then(() => {
        setCouponListLoader(false);
      })
      .catch((err: AxiosError) => {
        if (err) {
          enqueueSnackbar("Ocorreu um erro tente novamente.", {
            variant: "error",
            autoHideDuration: 1500,
          });
        }
      });
  };

  const handleSearchChange = (value: string): void => {
    setSearchTerm(value);
    debounce(value);
  };

  const debounce = useCallback(
    _.debounce((searchTerm: string): void => {
      setCurrentPage(1);
      if (searchTerm) {
        setCouponListLoader(true);
        getCouponsListByAdvertiser({
          ...params,
          page: 1,
          search: searchTerm,
        }).then((res) => {
          setCoupon(res?.data);
          setCouponListLoader(false);
          setTotalPages(res?.last_page);
        });
      } else {
        getCouponList({ ...params, page: 1 });
      }
    }, 500),
    [params, setParams]
  );

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (searchTerm) {
        getCouponList({ ...params, page: currentPage, search: searchTerm });
      } else {
        getCouponList(params);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [params, setParams, selectedCouponStatusTab, setSelectedCouponStatusTab]);

  return (
    <StyledCouponDetails>
      <StyledCouponDetailsContainer>
        <StyledCouponDetailsTopBar>
          <StyledTopBarContainer>
            <StyledTopBarTextContainer>
              <StyledTopBarTextIcon onClick={() => navigate("/coupons")}>
                <StyleChevronIcon />
              </StyledTopBarTextIcon>
              <StyledTextContainer>
                <TypographyTitle>Uso dos Cupons</TypographyTitle>
                <TypographyText>
                  Gerencie os cupons das suas promoções
                </TypographyText>
              </StyledTextContainer>
            </StyledTopBarTextContainer>
          </StyledTopBarContainer>
        </StyledCouponDetailsTopBar>
        <StyledCouponContent>
          <StyledListCouponContainer>
            <StyledCouponStatusTopContainer>
              <StyledCouponStatusTabsContainer>
                <CouponStatusTabs
                  value={selectedCouponStatusTab}
                  onChange={handleCouponStatusTabChange}
                  TabIndicatorProps={{
                    style: {
                      display: "none",
                    },
                  }}
                >
                  <CouponStatusTab label="Resgatados" />
                  <CouponStatusTab label="Utilizados" />
                </CouponStatusTabs>
              </StyledCouponStatusTabsContainer>
              <StyledSearchCoupon>
                <StyledSearchTextField
                  InputLabelProps={{ shrink: false }}
                  placeholder="Pesquisar por código, produto ou usuário"
                  value={searchTerm}
                  onChange={(e) => handleSearchChange(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleSearch}>
                        <MagnifyingGlassIcon />
                      </IconButton>
                    ),
                  }}
                />
              </StyledSearchCoupon>
            </StyledCouponStatusTopContainer>
            {selectedCouponStatusTab === 0 && (
              <CouponList
                coupon={coupon}
                totalPages={totalPages}
                currentPage={currentPage}
                handleCurrentPageChange={handleCurrentPageChange}
                loader={couponListLoader}
                setCoupon={setCoupon}
                params={params}
                setCouponListLoader={setCouponListLoader}
              />
            )}
            {selectedCouponStatusTab === 1 && (
              <CouponList
                coupon={coupon}
                totalPages={totalPages}
                currentPage={currentPage}
                handleCurrentPageChange={handleCurrentPageChange}
                loader={couponListLoader}
                setCoupon={setCoupon}
                params={params}
                setCouponListLoader={setCouponListLoader}
              />
            )}
          </StyledListCouponContainer>
        </StyledCouponContent>
      </StyledCouponDetailsContainer>
    </StyledCouponDetails>
  );
};

export default CouponsManager;
