import { Box, Button, styled, Typography } from "@mui/material";

export const Texts = styled(Box)(() => ({
  width: "90%",
  marginTop: "35px",
  marginLeft: "54px",
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.common.black,
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 400,
  color: theme.palette.neutral.dark1,
}));

export const CurrentPlanBox = styled(Box)(() => ({
  width: "100%",
}));

export const CurrentPlanCard = styled(Box)(({ theme }) => ({
  width: "500px",
  height: "250px",
  marginLeft: "54px",
  padding: "30px",
  marginTop: "5%",
  borderRadius: "24px",
  border: `1px solid ${theme.palette.primary.contrastText}`,
}));

export const UpdatePlanButton = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "62px",
  height: "62px",
  marginLeft: "480px",
  marginTop: "-50px",
  borderRadius: "50%",
  position: "absolute",
  backgroundColor: theme.palette.primary.main,
}));

export const PlanTypeAndValue = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  height: "80px",
  alignItems: "center",
}));

export const SymbolAndName = styled(Box)(() => ({
  display: "flex",
  width: "50%",
}));

export const SymbolPlan = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "56px",
  height: "56px",
  marginRight: "12px",
  alignItems: "center",
  borderRadius: "50%",
  justifyContent: "center",
  backgroundColor: theme.palette.neutral.light2,
}));

export const PlanName = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.common.black,
}));

export const ValidityPlan = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontFamily: "Rubik",
  fontWeight: 400,
  color: theme.palette.neutral.dark1,
}));

export const AlignValue = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  width: "50%",
}));

export const PlanValue = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.common.black,
}));

export const TextValue = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.neutral.dark1,
}));

export const BenefitsPreview = styled(Box)(() => ({
  width: "80%",
  paddingTop: "20px",
  height: "70px",
}));

export const AlignNameAndIcon = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const FirstBenefit = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  marginLeft: "13px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.common.black,
}));

export const ElipseIcon = styled(Box)(({ theme }) => ({
  width: "4px",
  height: "4px",
  marginLeft: "5px",
  borderRadius: "50%",
  backgroundColor: theme.palette.neutral.dark1,
}));

export const SecondBenefit = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  marginTop: "8px",
  marginLeft: "12px",
  fontWeight: 400,
  color: theme.palette.neutral.dark1,
}));

export const CurrentFlatCardButtons = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  paddingTop: "40px",
  justifyContent: "space-between",
  width: "100%",
  height: "50px",
}));

export const UnderlineStyleButtonText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  textDecorationLine: "underline",
  textTransform: "initial",
  fontWeight: 400,
  color: theme.palette.primary.main,
}));

export const ButtonViewAllBenefit = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "206px",
  height: "40px",
  borderRadius: "8px",
  border: `1px solid ${theme.palette.neutral.dark2}`,
}));

export const ButtonViewAllText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  textTransform: "initial",
  fontWeight: 700,
  color: theme.palette.primary.main,
}));
