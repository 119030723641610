import { Box, Skeleton, styled, Typography } from "@mui/material";

interface StyledStatisticsCardProps {
  isSelected: boolean;
}
interface StyledTextProps {
  isSelected: boolean;
}

interface StyledFrom {
  from: boolean;
}

export const StyledStatisticsCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<StyledStatisticsCardProps>(({ theme, isSelected }) => ({
  height: "100%",
  width: "100%",
  marginTop: "-19px",
  display: "block",
  backgroundColor: isSelected
    ? theme.palette.primary.main
    : theme.palette.neutral.light,
  padding: "16px 20px 16px 20px",
  borderRadius: 20,
  cursor: "pointer",
  position: "relative",
  boxShadow: isSelected ? "11px 24px 40px rgba(96, 41, 177, 0.35)" : "none",
}));

export const StyledCardTitle = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
}));
export const StyledIcon = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "from",
})<StyledFrom>(({ theme, from }) => ({
  height: 36,
  width: 36,
  borderRadius: 36,
  marginRight: 12,
  display: from ? "none" : "flex",
  backgroundColor: theme.palette.primary.contrastText,
  justifyContent: "center",
  alignItems: "center",
  "@media only screen and (max-width: 1400px)": {
    height: 20,
    width: 20,
  },
}));

export const StyledTextContainer = styled(Box)(() => ({
  display: "block",
}));
export const StyledText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<StyledTextProps>(({ theme, isSelected }) => ({
  fontSize: 18,
  fontFamily: "Rubik",
  fontWeight: 500,
  display: "baseline",
  color: isSelected ? theme.palette.neutral.light : theme.palette.primary.dark,
  textAlign: "left",
  textTransform: "none",
  lineHeight: "24px",
  "@media only screen and (max-width: 1400px)": {
    fontSize: 14,
    lineHeight: "18px",
  },
}));

export const StyledNumber = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<StyledTextProps>(({ theme, isSelected }) => ({
  fontSize: 20,
  fontFamily: "Rubik",
  fontWeight: 500,
  display: "baseline",
  color: isSelected ? theme.palette.neutral.light : theme.palette.primary.dark,
  textAlign: "left",
}));

export const StyledViewText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<StyledTextProps>(({ theme, isSelected }) => ({
  fontSize: 12,
  fontFamily: "Rubik",
  fontWeight: 400,
  display: "baseline",
  color: isSelected ? theme.palette.neutral.light : theme.palette.primary.dark,
  textAlign: "left",
  textTransform: "none",
  marginTop: 5,
}));

export const StyledTextSkeleton = styled(Skeleton)(() => ({
  height: 20,
  width: 150,
  "@media only screen and (max-width: 1200px)": {
    width: 100,
  },
}));
