import { AxiosResponse } from "axios";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";
export const editAnswerComment = async (
  commentAnswer: string,
  id: string
): Promise<AxiosResponse<any>> => {
  try {
    const response = await request({
      url: `${BASE_URL}/commentAnswer/${id}`,
      data: { newCommentAnswer: commentAnswer },
      method: "PUT",
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
