import { Box, Typography, styled } from '@mui/material';

interface PaymentsProp {
	isSelected: boolean;
}

export const PaymentFormContainer = styled(Box)(() => ({
	display: 'block',
	'@media only screen and (max-width: 1200px)': {
		minHeight: 362,
		marginTop: -40,
	},
}));

export const StyledTextContainer = styled(Box)(() => ({
	display: 'block',
	marginBottom: '30px',
}));

export const TypographyTitle = styled(Typography)(({ theme }) => ({
	fontSize: 20,
	lineHeight: '24px',
	fontFamily: 'Rubik',
	fontWeight: 500,
	display: 'baseline',
	color: theme.palette.neutral.dark,
	marginBottom: 5,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 16,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 14,
	},
}));

export const TypographyText = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	lineHeight: '20px',
	fontFamily: 'Rubik',
	fontWeight: 400,
	display: 'baseline',
	color: theme.palette.neutral.dark1,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 12,
	},
}));

export const StyledUserWarning = styled(Box)(({ theme }) => ({
	display: 'flex',
	fontFamily: 'Rubik',
	maxHeight: 48,
	fontSize: 16,
	fontWeight: 500,
	color: theme.palette.primary.dark,
	alignItems: 'center',
	justifyContent: 'start',
	padding: '12px 12px 12px 0px',
	marginBottom: 28,
	borderRadius: 8,
	backgroundColor: theme.palette.neutral.light1,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
		padding: '16px 24px 16px 24px',
	},
}));

export const StyledPaymentContainer = styled(Box)(() => ({
	display: 'flex',
}));

export const StyledPayment = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isChecked',
})<PaymentsProp>(({ theme, isSelected }) => ({
	display: 'flex',
	justifyContent: 'center',
	height: 56,
	width: 150,
	backgroundColor: isSelected
		? theme.palette.primary.main
		: theme.palette.neutral.light,
	borderRadius: 12,
	marginTop: 16,
	marginRight: 12,
	border: '1px solid',
	borderColor: theme.palette.neutral.dark2,
	cursor: 'pointer',
	'@media only screen and (max-width: 1200px)': {
		height: 36,
		width: 130,
		marginRight: 12,
	},
}));

export const StyledPaymentText = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'isSelected',
})<PaymentsProp>(({ theme, isSelected }) => ({
	display: 'flex',
	fontSize: '14px',
	lineHeight: '20px',
	justifyContent: 'center',
	alignItems: 'center',
	color: isSelected ? theme.palette.neutral.dark : theme.palette.neutral.light,
	fontFamily: 'Rubik',
	fontWeight: 500,
	userSelect: 'none',
	'@media only screen and (max-width: 1200px)': {
		fontSize: '14px',
		padding: 0,
	},
}));

export const PaymentTitle = styled(Typography)(({ theme }) => ({
	fontSize: 18,
	lineHeight: '24px',
	fontFamily: 'Rubik',
	fontWeight: 500,
	display: 'baseline',
	color: theme.palette.neutral.dark,
	marginBottom: 5,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 16,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 14,
	},
}));

export const StyledButton = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginLeft: 36,
}));

export const StyledButtonsContainer = styled(Box)(() => ({
	display: 'flex',
	marginTop: '50px',
	alignItems: 'center',
	justifyContent: 'center',
	paddingBottom: '50px',
	'@media only screen and (max-width: 1200px)': {
		marginTop: 24,
	},
}));

export const StyledResetFormButton = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	fontFamily: 'Rubik',
	height: 54,
	width: 300,
	fontWeight: 700,
	fontSize: 18,
	cursor: 'pointer',
	color: theme.palette.primary.main,
	border: '1px solid',
	borderColor: theme.palette.neutral.dark2,
	borderRadius: 5,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
		height: 39,
		width: 108,
	},
}));

export const GridContainer = styled(Box)(() => ({
	overflow: 'hidden',
	maxHeight: 230,
	display: 'flex',
	flexFlow: 'column wrap',
}));
