import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Skeleton, Tooltip } from '@mui/material';
import React from 'react';
import { CouponsStatusCardProps } from './interface/couponsStatus.interface';
import {
	CouponsChartRedSVG,
	CouponsChartSVG,
	StyledCouponsChartLineContainer,
	StyledCouponsNumber,
	StyledCouponsStatusDisplay,
	StyledCouponsText,
} from './styles';

const CouponsStatusCard: React.FC<CouponsStatusCardProps> = ({
	value,
	text,
	tooltip,
}: CouponsStatusCardProps): JSX.Element => (
	<StyledCouponsStatusDisplay>
		<StyledCouponsChartLineContainer>
			{text === 'Cupons expirados' ? (
				<CouponsChartRedSVG />
			) : (
				<CouponsChartSVG />
			)}
			<Tooltip title={tooltip} arrow placement="top-end">
				<HelpOutlineIcon
					color="disabled"
					fontSize="inherit"
					style={{ height: 27, width: 27 }}
				/>
			</Tooltip>
		</StyledCouponsChartLineContainer>
		{value === undefined && (
			<StyledCouponsNumber>
				<Skeleton
					sx={{
						height: 36,
						width: 80,
						borderRadius: 2,
					}}
					animation="wave"
					variant="rectangular"
				/>
			</StyledCouponsNumber>
		)}

		{value >= 0 && <StyledCouponsNumber>{value}</StyledCouponsNumber>}
		<StyledCouponsText>{text}</StyledCouponsText>
	</StyledCouponsStatusDisplay>
);

export default CouponsStatusCard;
