import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
	FormControl,
	IconButton,
	InputAdornment,
	TextField,
} from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { ReactComponent as BackArrow } from '../../../../assets/images/backArrow.svg';
import { PasswordProps } from '../../../../utils/api/interfaces';
import ContinueButton from '../../../Buttons/ContinueLoginButton/ContinueLoginButton';
import {
	StyledFormCreateAccountLink,
	StyledFormCreateAccountText,
} from '../LoginForm/styles';
import {
	BodyGrid,
	FormBody,
	FormBox,
	FormHeader,
	ItemGrid,
	StyledBackArrow,
	StyledFormSubText,
	StyledFormText,
} from '../styles';
import { FORM_HELPER_STYLE } from './constants/changePassword-form.constants';
import { ChangePasswordFormProps } from './interface/changePassword-form.interface';
import { FormFooter, StyledSendButtonContainer } from './styles';

const customTextFieldStyles = () => ({
	width: '100%',
	fontFamily: 'Rubik',

	'& .MuiOutlinedInput-root ': {
		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	[`& fieldset`]: {
		borderRadius: 2,
	},
	[`& label.Mui-focused`]: {
		color: '#6029B1',
	},
});

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = (
	props: ChangePasswordFormProps
): JSX.Element => {
	const [showPassword, setShowPassword] = useState(false);
	const {
		values: { password, password_confirm },
		handleChange,
		touched,
		errors,
	} = useFormikContext<PasswordProps>();

	return (
		<FormBox>
			<FormHeader>
				<StyledBackArrow onClick={props.handleFormPage}>
					<BackArrow />
				</StyledBackArrow>
			</FormHeader>
			<FormBody>
				<BodyGrid>
					<ItemGrid xs={12}>
						<StyledFormText>Alterar senha</StyledFormText>
						<StyledFormSubText>
							Digite e confirme sua senha nova
						</StyledFormSubText>
					</ItemGrid>
					<ItemGrid xs={12}>
						<FormControl fullWidth>
							<TextField
								id="outlined"
								type={showPassword ? 'text' : 'password'}
								value={password}
								label="Nova Senha"
								variant="outlined"
								onChange={handleChange('password')}
								error={touched.password && Boolean(errors.password)}
								helperText={touched.password && errors.password}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="mostrar senha"
												edge="end"
												onClick={() => setShowPassword(!showPassword)}
											>
												{showPassword ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
									),
								}}
								FormHelperTextProps={{
									style: FORM_HELPER_STYLE,
								}}
								sx={customTextFieldStyles}
							/>
						</FormControl>
					</ItemGrid>
					<ItemGrid xs={12}>
						<FormControl fullWidth>
							<TextField
								id="outlined"
								label="Confirmar Senha"
								value={password_confirm}
								type={showPassword ? 'text' : 'password'}
								variant="outlined"
								onChange={handleChange('password_confirm')}
								error={
									touched.password_confirm && Boolean(errors.password_confirm)
								}
								helperText={touched.password_confirm && errors.password_confirm}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="mostrar senha"
												edge="end"
												onClick={() => setShowPassword(!showPassword)}
											>
												{showPassword ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
									),
								}}
								FormHelperTextProps={{
									style: FORM_HELPER_STYLE,
								}}
								sx={customTextFieldStyles}
							/>
						</FormControl>
					</ItemGrid>
				</BodyGrid>
			</FormBody>
			<FormFooter>
				<StyledSendButtonContainer>
					<ContinueButton>REDEFINIR SENHA</ContinueButton>
				</StyledSendButtonContainer>
				<StyledFormCreateAccountText>
					Precisa de ajuda?&nbsp;
					<StyledFormCreateAccountLink>
						Clique aqui para falar com o suporte
					</StyledFormCreateAccountLink>
				</StyledFormCreateAccountText>
			</FormFooter>
		</FormBox>
	);
};

export default ChangePasswordForm;
