import { useFormikContext } from 'formik';
import { Radio, Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import CreateComplement from '../CreateComplement/CreateComplement';
import {
	IComplementsGroupProps,
	IComplementsProps,
} from '../../../../pages/AddItem/Interfaces/interfaces';
import { getOptionsGroups } from '../../../../utils/api/get/getOptionsGroups';
import {
	ButtonText,
	CopyButton,
	FormControlLabelStyled,
	FormStyled,
	OptionsComplements,
	RadioGroupStyled,
	ScreeenTitles,
	ScreenSubTitle,
	TitleAndButton,
} from './styles';
import { IItemFromDB } from './interfaces';
import CopyOptionsModal from './CopyOptionsModal/CopyOptionsModal';

const Complement: React.FC<IComplementsProps> = ({
	isEditting,
	optionGroups,
	hasOptionGroup,
	handleRenderCreateGroup,
}: IComplementsProps): JSX.Element => {
	const { setFieldValue } = useFormikContext();
	const [fallbackGroup, setFallbackGroup] = useState<
		undefined | IComplementsGroupProps[]
	>(undefined);
	const [openCopyOptionsModal, setopenCopyOptionsModal] = useState(false);
	const [itemCopiedId, setItemCopiedId] = useState<string>();
	const unsubscribe = useRef<boolean>();

	const handleCloseCopyOptionsModal = (): void =>
		setopenCopyOptionsModal(false);

	const handleOpenCopyOptionsModal = (): void => setopenCopyOptionsModal(true);

	const defaultOptionGroups = [
		{
			name: '',
			description: '',
			minimum: 0,
			maximum: 1,
			optional: true,
			available: false,
			options: [
				{
					name: '',
					description: '',
					available: false,
					optionImage: '',
					optionPrice: 0,
				},
			],
		},
	];

	const fetchOptionsGroup = (id: string): void => {
		getOptionsGroups(id).then((res) => {
			const transformedOptionsgroup = transformToFirstFormatArray(res?.data);

			const oldOptionsGroup = optionGroups?.filter(
				(group) => group.name && group.name.trim() !== ''
			);
			const newOptionsgroup = oldOptionsGroup?.concat(transformedOptionsgroup);
			setFieldValue('optionGroups', newOptionsgroup);
		});
	};

	const transformToFirstFormatArray = (
		dataArray: IItemFromDB[]
	): IComplementsGroupProps[] =>
		dataArray.map((data) => ({
			name: data.name,
			description: data.description,
			minimum: data.minimum,
			maximum: data.maximum,
			optional: data.minimum === 0,
			available: data.available,
			options: data.options.map((option) => ({
				name: option.name,
				description: option.description,
				available: option.available,
				optionImage: option.option_image_url,
				optionPrice: option.price ? option.price.value.value : 0,
			})),
		}));

	const handleOptionGroupChange = async () => {
		await setFieldValue('hasOptionGroup', !hasOptionGroup);
	};

	useEffect(() => {
		if (hasOptionGroup) {
			setFallbackGroup(optionGroups);
		}
	}, []);

	useEffect(() => {
		if (isEditting && !hasOptionGroup) {
			setFieldValue('optionGroups', null);
			return;
		} else if (isEditting && hasOptionGroup && fallbackGroup?.length) {
			setFieldValue('optionGroups', fallbackGroup);
			return;
		} else if (
			isEditting &&
			hasOptionGroup &&
			!optionGroups?.length &&
			!fallbackGroup?.length
		) {
			setFieldValue('optionGroups', defaultOptionGroups);
		}
	}, [hasOptionGroup]);

	useEffect(() => {
		unsubscribe.current = true;
		if (unsubscribe && itemCopiedId) {
			fetchOptionsGroup(itemCopiedId);
			return () => {
				unsubscribe.current = false;
			};
		}

		return () => {
			unsubscribe.current = false;
		};
	}, [itemCopiedId, itemCopiedId]);

	useEffect(() => {
		unsubscribe.current = true;
		if (unsubscribe && optionGroups && optionGroups?.length > 1) {
			const notEmptyOptionGroups = optionGroups.filter(
				(group) => group.name && group.name.trim() !== ''
			);
			setFieldValue('optionGroups', notEmptyOptionGroups);
			return () => {
				unsubscribe.current = false;
			};
		}

		return () => {
			unsubscribe.current = false;
		};
	}, []);

	return (
		<>
			<TitleAndButton>
				<Box>
					<ScreeenTitles>Complementos</ScreeenTitles>
					<ScreenSubTitle>
						Adicione complementos ao seu item, se houver
					</ScreenSubTitle>
				</Box>
				{hasOptionGroup ? (
					<CopyButton onClick={handleOpenCopyOptionsModal}>
						<ButtonText>Copiar de outro item</ButtonText>
					</CopyButton>
				) : null}
			</TitleAndButton>
			{openCopyOptionsModal && (
				<CopyOptionsModal
					handleCloseCopyOptionsModal={handleCloseCopyOptionsModal}
					openCopyOptionsModal={openCopyOptionsModal}
					setItemCopiedId={setItemCopiedId}
				/>
			)}
			<OptionsComplements>
				<FormStyled>
					<RadioGroupStyled
						aria-labelledby="demo-radio-buttons-group-label"
						value={hasOptionGroup}
						name="hasOptionGroup"
						onChange={handleOptionGroupChange}
					>
						<FormControlLabelStyled
							value={false}
							control={<Radio />}
							label="Não, este item não tem complementos"
						/>
						<FormControlLabelStyled
							value={true}
							control={<Radio />}
							label="Sim, este item tem complementos"
						/>
					</RadioGroupStyled>
				</FormStyled>
			</OptionsComplements>
			{hasOptionGroup ? (
				<CreateComplement
					optionGroups={optionGroups}
					handleRenderCreateGroup={handleRenderCreateGroup}
					handleOptionGroupChange={handleOptionGroupChange}
				/>
			) : null}
		</>
	);
};

export default Complement;
