import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import DashboardTopBar from '../../components/DashboardTopBar/DashboardTopBar';
import OrderValueChart from '../../components/Orders/OrderChart/OrderValueChart';
import TotalOrderChart from '../../components/Orders/OrderChart/TotalOrderChart';
import TransferValue from '../../components/Orders/OrderChart/TransferValue';
import OrderList from '../../components/Orders/OrderList/OrderList';
import { DataStructure } from '../../components/Orders/interfaces/interfaces';
import { getInfoCards } from '../../utils/api/get/getInfoCards';
import {
	AlignHeader,
	Container,
	ContentStyled,
	CouponsChartRedSVG,
	CouponsChartSVG,
	StyledDashboardTopBar,
} from './styles';

import { Box, Drawer } from '@mui/material';
import React from 'react';
import OrderDetails from '../../components/Orders/OrderDetails/OrderDetails';
import OrderRefundModal from '../../components/Orders/OrderRefundModal/OrderRefundModal';
import { getInfoCardsById } from '../../utils/api/get/getInfoCardsById';
import { IOrderDetailsProps } from './interfaces';

const Order: React.FC = (): JSX.Element => {
	const [detailsData, setDetailsData] = useState<DataStructure>();
	const [loading, setLoading] = useState<boolean>(true);
	const [openModal, setOpenModal] = useState(false);
	const [orderDetails, setOrderDetails] = useState<IOrderDetailsProps>();
	const [loadDetails, setLoadDetails] = useState<boolean>(false);
	const [openOrderRefundModal, setOpenOrderRefundModal] = useState(false);

	const { enqueueSnackbar } = useSnackbar();
	const { stats, data } = detailsData || {};

	const handleCloseOrderRefundModal = (): void =>
		setOpenOrderRefundModal(false);

	const handleOpenOrderRefundModal = (): void => setOpenOrderRefundModal(true);

	const handleFoooterIcon = (percentage: number | undefined) => {
		if (percentage === null) {
			return;
		}
		if (percentage && percentage < 0) {
			return <CouponsChartRedSVG />;
		} else {
			return <CouponsChartSVG />;
		}
	};

	const handleFooterText = (valuePercentage: number | undefined) => {
		if (valuePercentage === null) {
			return 'Não foi posssivel carregar os dados.';
		}
		if (valuePercentage && valuePercentage < 0) {
			return `${detailsData?.stats.transferValuePercentage}% no último mês`;
		} else {
			return `+${detailsData?.stats.transferValuePercentage}% no último mês`;
		}
	};

	const handleInfoCard = async (): Promise<void> => {
		setLoading(true);
		await getInfoCards()
			.then((res) => {
				setDetailsData(res.data as DataStructure);
				setLoading(false);
			})
			.catch((err: AxiosError) => {
				if (err) {
					setLoading(true);
					enqueueSnackbar('Ocorreu um erro tentar carregar os pedidos.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	const handleOrderDetailsById = async (itemID: string): Promise<void> => {
		setLoadDetails(true);
		await getInfoCardsById(itemID)
			.then((res) => {
				setOrderDetails(res.data as IOrderDetailsProps);
				setLoadDetails(false);
			})
			.catch((err: AxiosError) => {
				setLoadDetails(true);
				if (err) {
					enqueueSnackbar(
						'Ocorreu um erro tentar carregar os detalhes dos pedidos.',
						{
							variant: 'error',
							autoHideDuration: 1500,
						}
					);
				}
			});
	};

	const handleDetailsDrawer = (
		event: React.KeyboardEvent | React.MouseEvent
	) => {
		if (
			event.type === 'keydown' &&
			((event as React.KeyboardEvent).key === 'Tab' ||
				(event as React.KeyboardEvent).key === 'Shift')
		) {
			return;
		}

		setOpenModal((prevState) => !prevState);
	};

	useEffect(() => {
		handleInfoCard();
	}, []);

	return (
		<Container openModal={openModal}>
			<Box>
				<Drawer open={openModal} onClose={handleDetailsDrawer} anchor="right">
					{orderDetails && (
						<OrderDetails
							orderDetails={orderDetails}
							handleDetailsDrawer={handleDetailsDrawer}
							loadDetails={loadDetails}
							handleOpenOrderRefundModal={handleOpenOrderRefundModal}
						/>
					)}
				</Drawer>
			</Box>
			<StyledDashboardTopBar>
				<DashboardTopBar
					title={'Pedidos'}
					text={'Gerencie os pedidos da sua loja e o repasse da comissão'}
				/>
			</StyledDashboardTopBar>
			<ContentStyled>
				<AlignHeader>
					<>
						<OrderValueChart
							loading={loading}
							stats={stats}
							handleFooterText={handleFooterText}
							detailsData={detailsData}
							handleFoooterIcon={handleFoooterIcon}
						/>
						<TotalOrderChart
							loading={loading}
							stats={stats}
							handleFooterText={handleFooterText}
							detailsData={detailsData}
							handleFoooterIcon={handleFoooterIcon}
						/>
						<TransferValue
							loading={loading}
							stats={stats}
							handleFooterText={handleFooterText}
							detailsData={detailsData}
							handleFoooterIcon={handleFoooterIcon}
						/>
					</>
				</AlignHeader>

				<OrderList
					data={data}
					loading={loading}
					handleOrderDetailsById={handleOrderDetailsById}
					handleDetailsDrawer={handleDetailsDrawer}
				/>
				{orderDetails && openOrderRefundModal && (
					<OrderRefundModal
						openOrderRefundModal={openOrderRefundModal}
						handleCloseOrderRefundModal={handleCloseOrderRefundModal}
						orderDetails={orderDetails}
					/>
				)}
			</ContentStyled>
		</Container>
	);
};

export default Order;
