import { Box, styled, TextField, Typography } from '@mui/material';

interface NewSocialProps {
	cursor: boolean;
}

export const ContactFormContainer = styled(Box)(() => ({
	display: 'block',
	position: 'relative',
	height: '100%',
	'@media only screen and (max-width: 1200px)': {
		minHeight: 342,
		marginTop: -20,
	},
}));

export const StyledPhoneContainer = styled(Box)(() => ({
	display: 'flex',
	maxHeight: 60,
	marginBottom: 24,
	'@media only screen and (max-width: 1400px)': {
		marginBottom: 24,
	},
	'@media only screen and (max-width: 1200px)': {
		maxHeight: 40,
		marginBottom: 20,
	},
}));

export const StyledTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		position: 'relative',
		borderRadius: 8,
		height: 60,
		width: 248,
		fontFamily: 'Rubik',
		'@media only screen and (max-width: 1400px)': {
			fontSize: 12,
			height: 50,
			width: 200,
		},
		'@media only screen and (max-width: 1200px)': {
			height: 40,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'@media only screen and (max-width: 1400px)': {
		'& .MuiFormLabel-root': {
			fontSize: '14px',
		},
	},
}));

export const StyledFormText = styled(Typography)(() => ({
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 20,
	lineHeight: '24px',
	marginBottom: 12,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 16,
		lineHeight: '20px',
	},
}));

export const StyledSocialMediaContainer = styled(Box)(() => ({
	display: 'flex',
	minWidth: 558.891,
	height: 60,
	maxHeight: 60,
	marginTop: 20,
	'@media only screen and (max-width: 1400px)': {
		minWidth: 400,
		marginTop: 10,
	},
	'@media only screen and (max-width: 1200px)': {
		height: 40,
		maxHeight: 40,
	},
}));

export const StyledSocialsTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: '8px 0px 0px 8px',
		height: 60,
		minHeight: 60,
		width: 230,
		'@media only screen and (max-width: 1400px)': {
			fontSize: 12,
			height: 50,
			minHeight: 50,
			width: 180,
		},
		'@media only screen and (max-width: 1200px)': {
			height: 40,
			minHeight: 40,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'@media only screen and (max-width: 1400px)': {
		'& .MuiFormLabel-root': {
			fontSize: '13px',
		},
	},
}));

export const StyledSocialsLinkTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		position: 'relative',
		borderRadius: '0px 8px 8px 0px',
		height: 60,
		width: 280,
		fontFamily: 'Rubik',
		'@media only screen and (max-width: 1400px)': {
			fontSize: 12,
			height: 50,
			minHeight: 50,
			width: 240,
		},
		'@media only screen and (max-width: 1200px)': {
			height: 40,
			minHeight: 40,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'@media only screen and (max-width: 1400px)': {
		'& .MuiFormLabel-root': {
			fontSize: '15px',
		},
	},
}));

export const StyledAddNewSocial = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'cursor',
})<NewSocialProps>(({ theme, cursor }) => ({
	display: 'flex',
	width: 279,
	height: 60,
	justifyContent: 'center',
	alignItems: 'center',
	border: '1.5px dashed',
	borderColor: theme.palette.neutral.dark2,
	borderRadius: 8,
	color: theme.palette.neutral.dark1,
	fontFamily: 'Rubik',
	fontWeight: 400,
	fontSize: 24,
	marginTop: 24,
	cursor: cursor ? 'pointer' : 'not-allowed',
	userSelect: 'none',
	marginBottom: 100,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
		marginTop: 14,
		height: 50,
		minHeight: 50,
		width: 200,
	},
	'@media only screen and (max-width: 1200px)': {
		height: 40,
		minHeight: 40,
	},
}));

export const StyledAddNewSocialText = styled(Typography)(({ theme }) => ({
	color: theme.palette.neutral.dark1,
	fontFamily: 'Rubik',
	fontWeight: 400,
	fontSize: 18,
	lineHeight: '24px',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
		lineHeight: '20px',
	},
}));

export const StyledSocialMedia = styled(Box)(() => ({
	display: 'block',
}));

export const StyledErrorMessage = styled(Box)(({ theme }) => ({
	fontSize: 12,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.error.main,
	marginTop: 1,
	marginLeft: 14,
}));

export const StyledButton = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginLeft: 36,
}));

export const StyledButtonsContainer = styled(Box)(() => ({
	display: 'flex',
	paddingBottom: '50px',
	alignItems: 'center',
	justifyContent: 'center',
	'@media only screen and (max-width: 1200px)': {
		marginTop: 24,
	},
}));

export const StyledResetFormButton = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	fontFamily: 'Rubik',
	height: 54,
	width: 300,
	fontWeight: 700,
	fontSize: 18,
	cursor: 'pointer',
	color: theme.palette.primary.main,
	border: '1px solid',
	borderColor: theme.palette.neutral.dark2,
	borderRadius: 5,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
		height: 39,
		width: 108,
	},
}));
