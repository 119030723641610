import { Box, Checkbox, Divider, Slider } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { createStyles, makeStyles } from '@mui/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';
import { FieldArray, Form, getIn, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as HouseIcon } from '../../../assets/images/houseIcon.svg';
import { ReactComponent as LogoDsconto } from '../../../assets/images/logoDsconto.svg';
import SaveButton from '../../Buttons/SaveButton/SaveButton';
import {
	ButtonClose,
	CloseBox,
	Container,
	DateBox,
	FilterText,
	HeaderBox,
	HeaderDetail,
	IconClose,
	OrderStatusBox,
	OrderTypeBox,
	PaymentsMethodsBox,
	StyledBoxContainer,
	StyledButtonContainer,
	StyledDataTextFieldStart,
	StyledIconBox,
	StyledOrderStatusText,
	StyledOrderType,
	StyledOrderTypeText,
	StyledOrderTypeTitle,
	StyledPaymentMethods,
	StyledPaymentMethodsText,
	SubText,
	SubTitleBox,
	TitleBox,
	TitleText,
} from './styles';

const drawerWidth = '30%';

const useStyles = makeStyles(() =>
	createStyles({
		drawer: {
			width: drawerWidth,
		},
		drawerPaper: {
			width: drawerWidth,
		},
		drawerContainer: {
			overflow: 'auto',
		},
	})
);

interface FilterDrawerProps {
	open: boolean;
	onClose: () => void;
	initialValues: FilterDrawerFormProps;
}
interface PaymentsMethodsProps {
	paymentsMethod: string;
	is_active: boolean;
}

interface OrderTypeProps {
	type: string;
	text: string;
	is_active: boolean;
}

interface OrderStatusProps {
	text: string;
	is_active: boolean;
}

export interface FilterDrawerFormProps {
	startDate: moment.Moment;
	endDate: moment.Moment;
	paymentsMethods: PaymentsMethodsProps[];
	orderType: OrderTypeProps[];
	range: number[];
	orderStatus: OrderStatusProps[];
}

interface PaymentMethodsFormProps {
	index: number;
	is_active: string;
	paymentsMethods: PaymentsMethodsProps;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
}

interface OrderTypeFormProps {
	index: number;
	is_active: string;
	orderType: OrderTypeProps;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
}

interface OrderStatusFormProps {
	index: number;
	is_active: string;
	orderStatus: OrderStatusProps;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
}

const formHelperStyle = {
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 400,
};

const PaymentMethodsForm: React.FC<PaymentMethodsFormProps> = ({
	is_active,
	index,
	setFieldValue,
	paymentsMethods,
}: PaymentMethodsFormProps): JSX.Element => {
	const [checked, setChecked] = useState(paymentsMethods.is_active);
	const [textColor, setTextColor] = useState(!checked);

	const handleSwitchChange = () => {
		setChecked(!checked);
		setTextColor(!textColor);
	};

	useEffect(() => {
		setFieldValue(is_active, checked);
	}, [checked, setChecked]);

	useEffect(() => {
		setFieldValue(is_active, paymentsMethods.is_active);
		setChecked(paymentsMethods.is_active);
		setTextColor(!paymentsMethods.is_active);
	}, [paymentsMethods.is_active]);
	return (
		<StyledBoxContainer key={index}>
			<StyledPaymentMethods onClick={handleSwitchChange} isSelected={checked}>
				<StyledPaymentMethodsText isSelected={textColor}>
					{paymentsMethods.paymentsMethod}
				</StyledPaymentMethodsText>
			</StyledPaymentMethods>
		</StyledBoxContainer>
	);
};

const OrderTypeForm: React.FC<OrderTypeFormProps> = ({
	is_active,
	index,
	setFieldValue,
	orderType,
}: OrderTypeFormProps): JSX.Element => {
	const [checked, setChecked] = useState(orderType.is_active);
	const [textColor, setTextColor] = useState(!checked);

	const handleSwitchChange = () => {
		setChecked(!checked);
		setTextColor(!textColor);
	};

	useEffect(() => {
		setFieldValue(is_active, checked);
	}, [checked, setChecked]);

	useEffect(() => {
		setFieldValue(is_active, orderType.is_active);
		setChecked(orderType.is_active);
		setTextColor(!orderType.is_active);
	}, [orderType.is_active]);
	return (
		<StyledBoxContainer key={index}>
			<StyledOrderType onClick={handleSwitchChange} isSelected={checked}>
				<StyledIconBox isSelected={textColor}>
					{orderType.type === 'Online' ? <LogoDsconto /> : <HouseIcon />}
				</StyledIconBox>
				<StyledOrderTypeTitle isSelected={textColor}>
					{orderType.type}
				</StyledOrderTypeTitle>
				<StyledOrderTypeText isSelected={textColor}>
					{orderType.text}
				</StyledOrderTypeText>
			</StyledOrderType>
		</StyledBoxContainer>
	);
};

const OrderStatusForm: React.FC<OrderStatusFormProps> = ({
	is_active,
	index,
	setFieldValue,
	orderStatus,
}: OrderStatusFormProps): JSX.Element => {
	const [checked, setChecked] = useState(orderStatus.is_active);

	const handleSwitchChange = () => {
		setChecked(!checked);
	};

	useEffect(() => {
		setFieldValue(is_active, checked);
	}, [checked, setChecked]);

	useEffect(() => {
		setFieldValue(is_active, orderStatus.is_active);
		setChecked(orderStatus.is_active);
	}, [orderStatus.is_active]);
	return (
		<StyledBoxContainer key={index}>
			<Checkbox onChange={handleSwitchChange} checked={checked} />

			<StyledOrderStatusText onClick={handleSwitchChange}>
				{orderStatus.text}
			</StyledOrderStatusText>
		</StyledBoxContainer>
	);
};

const FilterDrawer: React.FC<FilterDrawerProps> = ({
	open,
	onClose,
	initialValues,
}: FilterDrawerProps) => {
	const classes = useStyles();

	const {
		values: {
			startDate,
			endDate,
			paymentsMethods,
			orderType,
			range,
			orderStatus,
		},
		setFieldValue,
		touched,
		errors,
	} = useFormikContext<FilterDrawerFormProps>();

	const handleChange = (event: Event, newValue: number | number[]) => {
		setFieldValue('range', newValue as [number, number]);
	};

	const resetFieldHandler = useCallback((field: string) => {
		setFieldValue(field, getIn(initialValues, field), true);
	}, []);

	return (
		<Drawer
			anchor="right"
			open={open}
			onClose={onClose}
			className={classes.drawer}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<Box>
				<Container>
					<Form>
						<HeaderDetail>
							<CloseBox>
								<ButtonClose onClick={onClose}>
									<IconClose />
								</ButtonClose>
							</CloseBox>
							<TitleBox>
								<TitleText>Filtrar pedidos</TitleText>
							</TitleBox>
							<SubTitleBox>
								<SubText>Refine sua busca aplicando filtros</SubText>
							</SubTitleBox>
						</HeaderDetail>
						<Divider />
						<HeaderBox>
							<Box>
								<TitleBox>
									<TitleText>Período</TitleText>
								</TitleBox>
								<SubTitleBox>
									<SubText>Selecione um período</SubText>
								</SubTitleBox>
							</Box>
							<FilterText
								onClick={() => {
									resetFieldHandler('startDate'), resetFieldHandler('endDate');
								}}
							>
								<SubText>Limpar filtro</SubText>
							</FilterText>
						</HeaderBox>
						<DateBox>
							<LocalizationProvider
								dateAdapter={AdapterDateFns}
								adapterLocale={brLocale}
							>
								<DatePicker
									disablePast
									value={startDate}
									onChange={(val) => {
										setFieldValue('startDate', val);
									}}
									renderInput={(params: any) => (
										<StyledDataTextFieldStart
											{...params}
											error={touched.startDate && Boolean(errors.startDate)}
											helperText={touched.startDate && errors.startDate}
											InputLabelProps={{ shrink: false }}
											FormHelperTextProps={{
												style: formHelperStyle,
											}}
										/>
									)}
								/>
							</LocalizationProvider>
							<Divider sx={{ width: 20, height: 20 }} />
							<LocalizationProvider
								dateAdapter={AdapterDateFns}
								adapterLocale={brLocale}
							>
								<DatePicker
									disablePast
									value={endDate}
									onChange={(val) => {
										setFieldValue('endDate', val);
									}}
									renderInput={(params: any) => (
										<StyledDataTextFieldStart
											{...params}
											error={touched.endDate && Boolean(errors.endDate)}
											helperText={touched.endDate && errors.endDate}
											InputLabelProps={{ shrink: false }}
											FormHelperTextProps={{
												style: formHelperStyle,
											}}
										/>
									)}
								/>
							</LocalizationProvider>
						</DateBox>
						<HeaderBox>
							<Box>
								<TitleBox>
									<TitleText>Método de pagamento</TitleText>
								</TitleBox>
								<SubTitleBox>
									<SubText>Você pode selecionar várias opções</SubText>
								</SubTitleBox>
							</Box>
							<FilterText onClick={() => resetFieldHandler('paymentsMethods')}>
								<SubText>Limpar filtro</SubText>
							</FilterText>
						</HeaderBox>
						<PaymentsMethodsBox>
							<FieldArray name="paymentsMethods">
								{() => (
									<React.Fragment>
										{paymentsMethods.map((paymentsMethods, index) => {
											const is_active = `paymentsMethods[${index}].is_active`;

											return (
												<PaymentMethodsForm
													key={index}
													index={index}
													is_active={is_active}
													setFieldValue={setFieldValue}
													paymentsMethods={paymentsMethods}
												/>
											);
										})}
									</React.Fragment>
								)}
							</FieldArray>
						</PaymentsMethodsBox>
						<HeaderBox>
							<Box>
								<TitleBox>
									<TitleText>Tipo de pagamento</TitleText>
								</TitleBox>
								<SubTitleBox>
									<SubText>Filtre por onde o pedido foi pago</SubText>
								</SubTitleBox>
							</Box>
							<FilterText onClick={() => resetFieldHandler('orderType')}>
								<SubText>Limpar filtro</SubText>
							</FilterText>
						</HeaderBox>
						<OrderTypeBox>
							<FieldArray name="orderType">
								{() => (
									<React.Fragment>
										{orderType.map((orderType, index) => {
											const is_active = `orderType[${index}].is_active`;

											return (
												<OrderTypeForm
													key={index}
													index={index}
													is_active={is_active}
													setFieldValue={setFieldValue}
													orderType={orderType}
												/>
											);
										})}
									</React.Fragment>
								)}
							</FieldArray>
						</OrderTypeBox>
						<HeaderBox>
							<Box>
								<TitleBox>
									<TitleText>Valor do pedido</TitleText>
								</TitleBox>
								<SubTitleBox>
									<SubText>Filtre por faixa de valor (R$)</SubText>
								</SubTitleBox>
							</Box>
							<FilterText onClick={() => resetFieldHandler('range')}>
								<SubText>Limpar filtro</SubText>
							</FilterText>
						</HeaderBox>
						<HeaderBox>
							<Slider
								value={range}
								onChange={handleChange}
								valueLabelDisplay="on"
								step={1}
								max={1000}
							/>
						</HeaderBox>
						<HeaderBox>
							<Box>
								<TitleBox>
									<TitleText>Status do pedido</TitleText>
								</TitleBox>
								<SubTitleBox>
									<SubText>Você pode selecionar várias opções</SubText>
								</SubTitleBox>
							</Box>
							<FilterText onClick={() => resetFieldHandler('orderStatus')}>
								<SubText>Limpar filtro</SubText>
							</FilterText>
						</HeaderBox>
						<OrderStatusBox>
							<FieldArray name="orderStatus">
								{() => (
									<React.Fragment>
										{orderStatus.map((orderStatus, index) => {
											const is_active = `orderStatus[${index}].is_active`;

											return (
												<OrderStatusForm
													key={index}
													index={index}
													is_active={is_active}
													setFieldValue={setFieldValue}
													orderStatus={orderStatus}
												/>
											);
										})}
									</React.Fragment>
								)}
							</FieldArray>
						</OrderStatusBox>
						<StyledButtonContainer>
							<SaveButton>Aplicar Filtro</SaveButton>
						</StyledButtonContainer>
					</Form>
				</Container>
			</Box>
		</Drawer>
	);
};

export default FilterDrawer;
