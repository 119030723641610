import Moment from 'moment';
import 'moment/locale/pt-br';

const cnpjMask = (value: string): string => {
	return value
		.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
		.replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1/$2')
		.replace(/(\d{4})(\d{1,2})/, '$1-$2');
};

const celularMask = (value: string): string => {
	return value
		.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
		.replace(/(\d{0})(\d)/, '$1($2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
		.replace(/(\d{2})(\d)/, '$1) $2')
		.replace(/(\d{5})(\d{1,2})/, '$1-$2');
};

const telefoneMask = (value: string): string => {
	return value
		.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
		.replace(/(\d{0})(\d)/, '$1($2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
		.replace(/(\d{2})(\d)/, '$1) $2')
		.replace(/(\d{4})(\d{1,2})/, '$1-$2');
};

const onlyNumbers = (value: string): string => {
	return value.replace(/[^\d]/g, '');
};

const moneyMask = (value: number): string => {
	return (Number(value.toString().replace(/\D/g, '')) / 100).toLocaleString(
		'pt-BR',
		{
			style: 'currency',
			currency: 'BRL',
		}
	);
};

const unMaskReais = (value: string | undefined): number => {
	return typeof value === 'number'
		? value
		: Number(value?.replace(/\D/g, '')) / 100;
};

const replaceDot = (value: string | undefined): string | undefined => {
	return value?.replace('.', ',');
};

const dateFormat = (date: string | undefined): string => {
	return Moment(new Date(date as string)).format('DD/MM/YYYY');
};

const dateFullFormat = (date: string | undefined): string => {
	return Moment(new Date(date as string)).format('DD/MM/YYYY HH:mm');
};

const hourFormat = (date: string | undefined): string => {
	return Moment(new Date(date as string))
		.utcOffset(-3)
		.format('HH:mm');
};

const dateDaysFormat = (date: string | undefined): string => {
	return Moment(date).calendar();
};

const maskCEP = (value: number): string => {
	return value
		.toString()
		.replace(/\D/g, '')
		.replace(/^(\d{5})(\d{3})+?$/, '$1-$2');
};

const numberMask = (value: string): number => {
	return Number(value.replace(/[^\d.-]+/g, ''));
};

const dateFullFormatWithDot = (date: string | undefined): string => {
	return Moment(new Date(date as string)).format('DD/MM • HH:mm');
};

const addLeadingZero = (value?: string): string => {
	if (value === undefined) {
		return '';
	}

	const [integerPart, decimalPart] = value.split(',');

	const formattedIntegerPart =
		integerPart.length === 1 ? '0' + integerPart : integerPart;

	return `${formattedIntegerPart},${decimalPart}`;
};

export {
	addLeadingZero,
	celularMask,
	cnpjMask,
	dateDaysFormat,
	dateFormat,
	dateFullFormat,
	dateFullFormatWithDot,
	hourFormat,
	maskCEP,
	moneyMask,
	numberMask,
	onlyNumbers,
	replaceDot,
	telefoneMask,
	unMaskReais,
};
