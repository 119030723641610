import { Box, styled } from '@mui/material';

export const StyledBillingStatusDisplay = styled(Box)(({ theme }) => ({
	display: 'grid',
	boxSizing: 'border-box',
	width: '19%',
	height: '100',
	backgroundColor: theme.palette.neutral.light,
	border: '1px solid #0000001A',
	borderRadius: 8,
	padding: '16px 20px 16px 20px',
}));

export const StyledBillingChartLineContainer = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginBottom: 16,
}));

export const StyledIconBox = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	border: '1px solid #0000001A',
	borderRadius: 7,
	padding: 7,
}));

export const StyledBillingNumber = styled(Box)(({ theme }) => ({
	display: 'flex',
	color: theme.palette.neutral.dark,
	fontSize: 20,
	fontFamily: 'Rubik',
	fontWeight: 500,
	lineHeight: '24px',
}));

export const StyledBillingText = styled(Box)(() => ({
	display: 'flex',
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 500,
	lineHeight: '20px',
	marginLeft: 8,
}));
