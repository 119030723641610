import { FieldArray, useFormikContext } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { IEditOptionGroup } from '../../../../auth/interface/editOptionGroup.interface';
import { IReducers } from '../../../../auth/store/store';
import {
	IAddItemForm,
	IComplementsGroupProps,
} from '../../../../pages/AddItem/Interfaces/interfaces';
import DetailsComponent from './DetailsComponent/DetailsComponent';

export interface IPositionProps {
	position: number;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
	handleChange: {
		(e: React.ChangeEvent<any>): void;
		<T = string | React.ChangeEvent<any>>(
			field: T
		): T extends React.ChangeEvent<any>
			? void
			: (e: string | React.ChangeEvent<any>) => void;
	};
	optionGroups: IComplementsGroupProps[];
}

export const DetailsGroup: React.FC<IPositionProps> = ({
	position,
	optionGroups,
	handleChange,
	setFieldValue,
}: IPositionProps): JSX.Element => {
	const { optionGroupEditId }: IEditOptionGroup = useSelector(
		(state: IReducers) => state.editOptionGroup
	);
	const {
		values: { hasOptionGroup },
	} = useFormikContext<IAddItemForm>();

	return (
		<FieldArray name="optionGroups">
			{(optionGroupsArrayHelpers) => {
				if (optionGroups.length === 0 && hasOptionGroup) {
					optionGroupsArrayHelpers.push({
						name: '',
						description: '',
						minimum: 0,
						maximum: 0,
						available: true,
						optional: true,
						options: [
							{
								name: '',
								description: '',
								available: false,
								optionImage: '',
								optionPrice: 0,
							},
						],
					});
				}

				return (
					<React.Fragment>
						{optionGroups?.map((optionsGroup, index) => {
							const name = `optionGroups[${index}].name`;
							const maximum = `optionGroups[${index}].maximum`;
							const minimum = `optionGroups[${index}].minimum`;
							const optional = `optionGroups[${index}].optional`;

							if (
								optionGroupEditId !== undefined &&
								index === optionGroupEditId
							) {
								return (
									<DetailsComponent
										key={index}
										name={name}
										maximum={maximum}
										minimum={minimum}
										handleChange={handleChange}
										index={index}
										optionsGroup={optionsGroup}
										optionGroups={optionGroups}
										options={optionsGroup.options}
										setFieldValue={setFieldValue}
										optional={optional}
										position={position}
										optionGroupsArrayHelpers={optionGroupsArrayHelpers}
										optionGroupEditId={optionGroupEditId}
									/>
								);
							}

							if (
								index === optionGroups.length - 1 &&
								optionGroupEditId === undefined
							) {
								return (
									<DetailsComponent
										key={index}
										name={name}
										index={index}
										maximum={maximum}
										minimum={minimum}
										optional={optional}
										position={position}
										handleChange={handleChange}
										optionsGroup={optionsGroup}
										optionGroups={optionGroups}
										setFieldValue={setFieldValue}
										options={optionsGroup.options}
										optionGroupEditId={optionGroupEditId}
										optionGroupsArrayHelpers={optionGroupsArrayHelpers}
									/>
								);
							} else {
								return null;
							}
						})}
					</React.Fragment>
				);
			}}
		</FieldArray>
	);
};
