import { Box, styled, Typography } from "@mui/material";
import ReactCodeInput from "react-code-input";

export const StyledFormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: 24,
  width: "76.8%",
  maxHeight: 587,
  maxWidth: 620,
  paddingTop: 24,
  backgroundColor: theme.palette.neutral.light,
  borderRadius: 40,
  padding: "19px 64px 63px 64px ",
  [theme.breakpoints.down('md')]: {
    maxWidth: "75%",
    padding: "20px 40px 20px 40px ",
    margin: 'auto'
  }
}));

export const StyledFormControl = styled(Box)(() => ({}));

export const StyledBackArrow = styled(Box)(() => ({
  cursor: "pointer",
  width: 32,
  height: 32,
}));

export const StyledFormText = styled(Box)(({ theme }) => ({
  fontSize: 24,
  lineHeight: "28px",
  color: theme.palette.primary.main,
  fontFamily: "Rubik",
  fontWeight: 500,
}));

export const StyledFormSubText = styled(Box)(({ theme }) => ({
  fontSize: 18,
  lineHeight: "24px",
  color: theme.palette.neutral.dark1,
  fontFamily: "Rubik",
  fontWeight: 500,
}));

export const StyledFieldsTitle = styled(Typography)(() => ({
  fontSize: 18,
  fontFamily: "Rubik",
  fontWeight: 500,
}));

export const StyledSendButtonContainer = styled(Box)(() => ({
  width: "70%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
}));
export const StyledReactCodeInput = styled(ReactCodeInput)(() => ({
  width: 300,
  height: 65,
  display: "flex",
}));
export const StyledLinkContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledTypographyLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  cursor: "pointer",
  transition: "all 0.3s ease-out",
  fontFamily: "Rubik",
  fontWeight: 400,
  fontSize: 14,
  textDecoration: "underline",
}));
export const StyledTypographyLinkBold = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  cursor: "pointer",
  transition: "all 0.3s ease-out",
  fontFamily: "Rubik",
  fontWeight: 600,
  fontSize: 14,
  textDecoration: "underline",
}));