import { AxiosError } from 'axios';
import { Formik, FormikValues } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useNavigate } from 'react-router';
import { ReactComponent as ArrowUpIcon } from '../../assets/images/arrowUpIcon.svg';
import { ReactComponent as BottomEllipse } from '../../assets/images/bottomEllipse.svg';
import { ReactComponent as PigIcon } from '../../assets/images/pigIcon.svg';
import { ReactComponent as RocketImg } from '../../assets/images/rocketImg.svg';
import { ReactComponent as TopEllipse } from '../../assets/images/topEllipse.svg';

import SignUpForm from '../../components/Forms/SignUpForm/SignUpForm';
import IconBack from '../../components/IconBack/IconBack';
import { createAdvertiser } from '../../utils/api/post/createAdvertiser';
import { onlyNumbers } from '../../utils/mask.util';
import SIGN_UP_INITIAL_VALUES from './constants/signUp-initialValues';
import SIGN_UP_FIELDS_VALIDATION_SCHEMA from './constants/signUp-validationSchema';
import {
	CenterBox,
	Container,
	FirstGrid,
	FirstGridFirstColumn,
	FirstGridSecondColumn,
	StyeledCardsContainer,
	StyledBottomElipse,
	StyledCard,
	StyledCardImg,
	StyledCardText,
	StyledCardTitle,
	StyledIconBack,
	StyledRocketImg,
	StyledSecondColumnText,
	StyledSecondColumnTextBox,
	StyledSecondColumnTitle,
	StyledTopElipse,
} from './styles';

const SignUp: React.FC = (): JSX.Element => {
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const backPage = (): void => {
		navigate(-1);
	};

	const handleCreateAccount = (
		values: FormikValues,
		setSubmit: (isSubmitting: boolean) => void,
		resetForm: any
	) => {
		const { name, email, password } = values;
		const cellphoneRaw = values.cellphone;
		const cnpjRaw = values.cnpj;

		const cellphone = onlyNumbers(cellphoneRaw);
		const cnpj = onlyNumbers(cnpjRaw);

		createAdvertiser({
			advertiser: {
				name,
				cnpj,
				cellphone,
				email,
				password,
			},
		})
			.then(() => {
				setSubmit(false);
				resetForm();
				enqueueSnackbar('Conta criada com sucesso.', {
					variant: 'success',
					autoHideDuration: 1500,
				});
				navigate('/login');
			})
			.catch((error: AxiosError) => {
				if (error) {
					setSubmit(false);
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	return (
		<Container>
			<FirstGrid>
				<StyledIconBack>
					<IconBack onClick={backPage} />
				</StyledIconBack>
				<FirstGridFirstColumn>
					<Formik
						initialValues={SIGN_UP_INITIAL_VALUES}
						validationSchema={SIGN_UP_FIELDS_VALIDATION_SCHEMA}
						onSubmit={(values, { setSubmitting, resetForm }) => {
							handleCreateAccount(values, setSubmitting, resetForm);
						}}
					>
						{({ handleSubmit }) => (
							<form
								style={{ width: '100%' }}
								onSubmit={(e) => {
									e.preventDefault();
									handleSubmit();
								}}
							>
								<SignUpForm />
							</form>
						)}
					</Formik>
				</FirstGridFirstColumn>
				<FirstGridSecondColumn>
					<StyledTopElipse>
						<TopEllipse />
					</StyledTopElipse>
					<StyledBottomElipse>
						<BottomEllipse />
					</StyledBottomElipse>
					<StyledRocketImg>
						<RocketImg />
					</StyledRocketImg>
					<CenterBox>
						<StyledSecondColumnTitle>
							Desfrute da menor taxa e <br></br> mensalidade do mercado!
						</StyledSecondColumnTitle>
						<StyledSecondColumnTextBox>
							<StyledSecondColumnText>
								Aproveite uma das menores taxas do mercado,<br></br> com apenas
								10% de comissão por pedido!
							</StyledSecondColumnText>
						</StyledSecondColumnTextBox>
						<StyeledCardsContainer>
							<StyledCard>
								<StyledCardImg>
									<PigIcon />
								</StyledCardImg>
								<StyledCardTitle>VENDA MAIS, PAGUE MENOS</StyledCardTitle>
								<StyledCardText>
									Venda mais de R$ 1.800,00/mês e fique{' '}
									<b>isento da mensalidade</b> de R$ 79,90!
								</StyledCardText>
							</StyledCard>
							<StyledCard>
								<StyledCardImg>
									<ArrowUpIcon />
								</StyledCardImg>
								<StyledCardTitle>POTENCIALIZE SEU NEGÓCIO</StyledCardTitle>
								<StyledCardText>
									Cadastre seus produtos, faça vendas, poste promoções e
									visualize dados estatísticos!
								</StyledCardText>
							</StyledCard>
						</StyeledCardsContainer>
					</CenterBox>
				</FirstGridSecondColumn>
			</FirstGrid>
		</Container>
	);
};

export default SignUp;
