import React from "react";
import {
  CouponIconSkeleton,
  EmptyCouponTextSkeleton,
  EmptyCouponTitleSkeleton,
  StyledCouponIcon,
  StyledEmptyCouponText,
  StyledEmptyCouponTitleSkeleton,
} from "./styles";

const EmptyCouponCardSkeleton: React.FC = (): JSX.Element => (
  <>
    <StyledCouponIcon>
      <CouponIconSkeleton animation="wave" variant="rectangular" />
    </StyledCouponIcon>
    <StyledEmptyCouponTitleSkeleton>
      <EmptyCouponTitleSkeleton animation="wave" variant="rectangular" />
    </StyledEmptyCouponTitleSkeleton>
    <StyledEmptyCouponText>
      <EmptyCouponTextSkeleton animation="wave" variant="rectangular" />
    </StyledEmptyCouponText>
  </>
);

export default EmptyCouponCardSkeleton;
