import { createTheme, ThemeOptions } from '@mui/material';

const themes: ThemeOptions = createTheme({
	typography: {
		fontFamily: 'Rubik',
	},
	palette: {
		common: {
			white: '#FFF',
			black: '#202020',
		},
		primary: {
			main: '#6029B1!important',
			light: '#B888FF',
			dark: '#412272',
			contrastText: '#EDE8F4',
		},
		secondary: {
			main: '#FF5616',
			light: '#FF8F4D',
			dark: '#E05C03',
		},
		tertiary: {
			main: '#F4AD21',
			light: '#FFCF48',
			dark: '#D08E00',
		},
		neutral: {
			light: '#FEFEFE!important',
			light1: '#F9F9F9',
			light2: '#F1F1F1',
			light3: 'rgba(96, 41, 177,0.1)',
			light4: '#9248FF',
			light5: '#5F2EAD',
			light6: 'rgba(217, 217, 217, 0.2)',
			dark: '#1f1f1f',
			dark1: '#747476',
			dark2: '#C5C5C5',
			dark3: 'transparent',
			dark4: '#959595',
			dark5: 'rgba(255, 86, 22, 0.1)',
			main2: '#481C8A',
		},
		success: {
			main: '#15C589',
			light: '#5FF7B7',
			dark: 'rgba(21, 197, 137, 0.05)',
		},
		error: {
			main: '#EB3333!important',
			light: '#FF8876',
			dark: 'rgba(235, 51, 51, 0.05)',
		},
		info: {
			main: '#4D92FA',
			light: '#8CC5FF',
		},
		warning: {
			main: '#FFC940',
			light: '#FFEC63',
		},
	},
});

export default themes;
