import { Button, Skeleton, TextField } from '@mui/material';
import { AxiosError } from 'axios';
import { Formik, FormikValues } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { addNav } from '../../../auth/actions/sideNav.action';
import { ISideNav } from '../../../auth/interface/sideNav.interface';
import CouponsChart from '../../../components/Dashboard/DashboardChart/CouponsChart';
import LikesChart from '../../../components/Dashboard/DashboardChart/LikesChart';
import ViewsChart from '../../../components/Dashboard/DashboardChart/ViewsChart';
import { DataStatsProps } from '../../../components/Dashboard/DashboardChart/interface/chart-data.interface';
import StatisticsCard from '../../../components/Dashboard/StatisticsCard/StatisticsCard';
import { CARDICONS } from '../../../components/Dashboard/StatisticsCard/constants/statisticsCard-value.constants';
import { ICard } from '../../../components/Dashboard/StatisticsCard/interface/card-interface';
import EditPromotionForm from '../../../components/Forms/Promotion/EditPromotionForm/EditPromotionForm';
import ModalSucess from '../../../components/Promotion/ModalSucess/ModalSucess';
import PromotionConfirmModal from '../../../components/Promotion/PromotionConfirmModal/PromotionConfirmModal';
import CommentsCard from '../../../components/PromotionDetails/CommentsCard/CommentsCard';
import CommentsModal from '../../../components/PromotionDetails/CommentsModal/CommentsModal';
import CouponCard from '../../../components/PromotionDetails/CouponCard/CouponCard';
import EmptyCouponCard from '../../../components/PromotionDetails/CouponCard/EmptyCouponCard';
import EmptyCouponCardSkeleton from '../../../components/PromotionDetails/CouponCard/EmptyCouponCardSkeleton';
import DisablePromotionModal from '../../../components/PromotionDetails/DisablePromotionModal/DisablePromotionModal';
import PromotionFields from '../../../components/PromotionDetails/PromotionFields/PromotionFields';
import { getCouponsPercent } from '../../../shared/helpers/couponsPercent';
import { getCommentsByPromotion } from '../../../utils/api/get/getCommentsByPromotion';
import { getCouponsStatusByPromotion } from '../../../utils/api/get/getCouponsStatusByPromotion';
import { getPromotionById } from '../../../utils/api/get/getPromotionById';
import { getPromotionLikes } from '../../../utils/api/get/getPromotionLikes';
import { getPromotionRamsomedCoupons } from '../../../utils/api/get/getPromotionRamsomedCoupons';
import { getPromotionViews } from '../../../utils/api/get/getPromotionViews';
import { getTags } from '../../../utils/api/get/getTags';
import { ConfirmModalProps, ITag } from '../../../utils/api/interfaces';
import { patchDisablePromotionById } from '../../../utils/api/patch/patchDisablePromotionById';
import { dateFullFormat, hourFormat } from '../../../utils/mask.util';
import { PromotionsProps } from '../../Dashboard/interface';
import { ILastComments } from '../../Dashboard/interface/lastComments.interface';
import EDIT_PROMOTION_FIELDS_VALIDATION_SCHEMA from '../constants/editPromotion-validationSchema';
import {
	PromotionTab,
	PromotionTabs,
	StyledCreatePromotion,
	StyledPromotionContent,
	StyledTabsContainer,
} from '../styles';
import { CouponPercent, CouponStatus } from './interface/coupon.interface';
import {
	StyleChevronIcon,
	StyledBottomRow,
	StyledCancelButton,
	StyledEditButton,
	StyledPromotionChart,
	StyledPromotionComments,
	StyledPromotionCoupon,
	StyledPromotionDate,
	StyledPromotionDateContainer,
	StyledPromotionDateCouponContainer,
	StyledPromotionDateFieldContainer,
	StyledPromotionDetails,
	StyledPromotionDetailsContainer,
	StyledPromotionDetailsContent,
	StyledPromotionDetailsFields,
	StyledPromotionDetailsTopBar,
	StyledPromotionStats,
	StyledPromotionText,
	StyledRemoveButton,
	StyledSaveButton,
	StyledTopBarButtonsContainer,
	StyledTopBarContainer,
	StyledTopBarTextContainer,
	StyledTopBarTextIcon,
	StyledTopBarTypographyTitle,
} from './styles';

const customTextFieldStyles = () => ({
	'& .MuiInputBase-root': {
		borderRadius: 2,
		height: 40,
		fontFamily: 'Rubik',
		'@media only screen and (max-width: 1400px)': {
			height: 30,
			fontSize: 12,
			width: 140,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
});

const PromotionDetails: React.FC = (): JSX.Element => {
	const [promotion, setPromotion] = useState<PromotionsProps>();
	const [coupon, setCoupon] = useState<CouponStatus>();
	const [couponPercent, setCouponPercent] = useState<CouponPercent>();
	const [usedCoupons, setUsedCoupons] = useState<DataStatsProps>();
	const [totalComments, setTotalComments] = useState(0);
	const [comments, setComments] = useState<ILastComments[]>([]);
	const [openCommentsModal, setOpenCommentsModal] = useState(false);
	const [openDisablePromotionModal, setOpenDisablePromotionModal] =
		useState(false);
	const [openPromotionConfirmModal, setOpenPromotionConfirmModal] =
		useState(false);
	const [confirmModalProps, setConfirmModalProps] =
		useState<ConfirmModalProps>();
	const [openPromotionSucessModal, setOpenPromotionSucessModal] =
		useState(false);
	const [edit, setEdit] = useState<boolean>(false);
	const [tags, setTags] = useState<ITag[]>([]);
	const [view, setView] = useState<DataStatsProps>();
	const [like, setLikes] = useState<DataStatsProps>();
	const [indexButton, setindexButton] = useState(0);
	const [loaderComments, setLoaderComments] = useState(true);
	const [loaderChart, setLoaderChart] = useState(true);
	const [loaderPromotion, setLoaderPromotion] = useState(true);
	const [loaderCoupon, setLoaderCoupon] = useState(true);
	const { enqueueSnackbar } = useSnackbar();
	const { id } = useParams();
	const navigate = useNavigate();

	const handleClosePromotionModal = (): void => {
		setOpenPromotionSucessModal(false);
		handleEdit();
	};

	const handleOpenPromotionModal = (): void =>
		setOpenPromotionSucessModal(true);

	const handleCloseCommentsModal = (): void => setOpenCommentsModal(false);

	const handleOpenCommentsModal = (): void => setOpenCommentsModal(true);

	const handleCloseDisablePromotionModal = (): void =>
		setOpenDisablePromotionModal(false);

	const handleOpenDisablePromotionModal = (): void => {
		if (promotion?.is_expired) {
			return;
		}
		setOpenDisablePromotionModal(true);
	};

	const handleClosePromotionConfirmModal = (): void =>
		setOpenPromotionConfirmModal(false);

	const handleOpenPromotionConfirmModal = (): void => {
		setOpenPromotionConfirmModal(true);
	};

	const handleCouponPercent = (): void => {
		if (coupon && promotion?.coupon_batch?.qtd_coupons) {
			const total = promotion.coupon_batch.qtd_coupons;
			const NewCouponValue = getCouponsPercent(total, coupon);
			setCouponPercent(NewCouponValue);
			setLoaderCoupon(false);
		} else {
			setLoaderCoupon(false);
			return;
		}
	};

	const handleDisablePromotion = (): void => {
		if (promotion?.is_expired) {
			return;
		}
		patchDisablePromotionById(id)
			.then((res) => {
				if (res?.status === 204) {
					enqueueSnackbar('A promoção foi desativada!', {
						variant: 'success',
						autoHideDuration: 2000,
					});
				}
				getPromotion();
			})
			.finally(() => handleCloseDisablePromotionModal())
			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	const handleEditPromotion = (
		values: FormikValues,
		setSubmit: (isSubmitting: boolean) => void
	): void => {
		setConfirmModalProps({ values, setSubmit });
		handleOpenPromotionConfirmModal();
		setSubmit(false);
	};

	const handleSelection = (index: number): void => {
		if (index === 0) {
			setindexButton(0);
		} else if (index === 1) {
			setindexButton(1);
		} else if (index === 2) {
			setindexButton(2);
		}
	};

	const handleEdit = (): void => {
		if (promotion?.is_expired) {
			return;
		}
		setEdit(!edit);
	};

	const getPromotion = (): void => {
		if (id) {
			getPromotionById(id)
				.then((res) => {
					setPromotion(res?.data);
					setLoaderPromotion(false);
				})
				.catch((err: AxiosError) => {
					if (err) {
						enqueueSnackbar('Ocorreu um erro tente novamente.', {
							variant: 'error',
							autoHideDuration: 1500,
						});
					}
				});
		}
	};

	const getPromotionComments = (): void => {
		getCommentsByPromotion(id)
			.then((res) => {
				setComments(res.data);
				setTotalComments(res.total);
				setLoaderComments(false);
			})

			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	const getViewsByPromotion = (): void => {
		getPromotionViews(id)
			.then((res) => {
				setView(res?.data);
				setLoaderChart(false);
			})
			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	const getLikesByPromotion = (): void => {
		getPromotionLikes(id)
			.then((res) => {
				setLikes(res?.data);
			})
			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	const getRamsomedCouponsByPromotion = (): void => {
		getPromotionRamsomedCoupons(id).then((res) => setUsedCoupons(res?.data));
	};

	const getCouponsByPromotion = (): void => {
		if (promotion?.has_coupons) {
			getCouponsStatusByPromotion(id).then((res) => {
				setCoupon(res?.data);
			});
		}
	};

	const getPromotionTags = (): void => {
		getTags().then((res) => {
			setTags(res?.data);
		});
	};
	const dispatch = useDispatch();

	const setIndexOfSideNavButton = (sideNav: ISideNav): void => {
		dispatch(addNav(sideNav));
	};

	useEffect(() => {
		getPromotionComments();
		getViewsByPromotion();
		getLikesByPromotion();
		getRamsomedCouponsByPromotion();
	}, [id]);

	useEffect(() => {
		if (promotion) {
			getCouponsByPromotion();
		}
	}, [promotion, setPromotion]);

	useEffect(() => {
		let isMounted = true;

		if (isMounted) {
			getPromotionTags();
			setIndexOfSideNavButton({
				showSide: true,
				buttonIndex: 1,
			});
		}
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		getPromotion();
	}, [edit]);

	useEffect(() => {
		if (coupon) {
			handleCouponPercent();
		}
	}, [coupon, setCoupon]);

	return (
		<StyledPromotionDetails>
			<StyledPromotionDetailsContainer>
				<StyledPromotionDetailsTopBar>
					<StyledTopBarContainer>
						<StyledTopBarTextContainer>
							<StyledTopBarTextIcon onClick={() => navigate('/promotion')}>
								<StyleChevronIcon />
							</StyledTopBarTextIcon>
							<StyledTopBarTypographyTitle>
								{edit ? 'Editar Promoção' : 'Detalhes da promoção'}
							</StyledTopBarTypographyTitle>
						</StyledTopBarTextContainer>
						<StyledTopBarButtonsContainer>
							{!edit && (
								<>
									<StyledRemoveButton
										onClick={handleOpenDisablePromotionModal}
										disable={promotion?.is_expired}
									>
										Desativar
									</StyledRemoveButton>
									<StyledEditButton
										onClick={handleEdit}
										disable={promotion?.is_expired}
									>
										Editar
									</StyledEditButton>
								</>
							)}
							{edit && (
								<>
									<StyledCancelButton onClick={handleEdit}>
										Cancelar
									</StyledCancelButton>
									<StyledSaveButton form="editForm" type="submit">
										Salvar
									</StyledSaveButton>
								</>
							)}
						</StyledTopBarButtonsContainer>
						{openDisablePromotionModal && (
							<DisablePromotionModal
								handleCloseDisablePromotionModal={
									handleCloseDisablePromotionModal
								}
								openDisablePromotionModal={openDisablePromotionModal}
								handleDisablePromotion={handleDisablePromotion}
								modalButton="Desativar"
								modalText="Ao prosseguir seu anúncio será desativado e não ficará visível para os usuários. Essa ação não poderá ser desfeita."
								modalTitle="Desativar anúncio?"
							/>
						)}
					</StyledTopBarContainer>
				</StyledPromotionDetailsTopBar>
				{!edit && (
					<StyledPromotionDetailsContent>
						<>
							<StyledPromotionDetailsFields>
								<PromotionFields
									promotion={promotion}
									loader={loaderPromotion}
								/>
							</StyledPromotionDetailsFields>

							<StyledPromotionDateCouponContainer>
								<StyledPromotionDate>
									<StyledPromotionDateContainer>
										<StyledPromotionText>
											Duração da promoção
										</StyledPromotionText>
										<StyledPromotionDateFieldContainer>
											{loaderPromotion ? (
												<>
													<Skeleton
														sx={{
															height: 40,
															width: 208,
															borderRadius: 2,
															'@media only screen and (max-width: 1400px)': {
																height: 22,
																width: 148,
															},
														}}
														animation="wave"
														variant="rectangular"
													/>
												</>
											) : (
												<>
													<TextField
														value={dateFullFormat(
															promotion?.promotion_initial_date
														)}
														InputProps={{
															readOnly: true,
														}}
														label="Ínicio da promoção"
														sx={customTextFieldStyles}
													/>
												</>
											)}
										</StyledPromotionDateFieldContainer>
									</StyledPromotionDateContainer>
									<StyledPromotionDateContainer>
										<StyledPromotionText></StyledPromotionText>
										<StyledPromotionDateFieldContainer>
											{loaderPromotion ? (
												<>
													<Skeleton
														sx={{
															height: 40,
															width: 208,
															borderRadius: 2,
															'@media only screen and (max-width: 1400px)': {
																height: 22,
																width: 148,
															},
														}}
														animation="wave"
														variant="rectangular"
													/>
												</>
											) : (
												<TextField
													value={dateFullFormat(
														promotion?.promotion_expires_date
													)}
													label="Fim da promoção"
													InputProps={{
														readOnly: true,
													}}
													sx={customTextFieldStyles}
												/>
											)}
										</StyledPromotionDateFieldContainer>
									</StyledPromotionDateContainer>
								</StyledPromotionDate>

								<StyledPromotionCoupon>
									{loaderPromotion ? (
										<EmptyCouponCardSkeleton />
									) : promotion?.has_coupons ? (
										<CouponCard
											coupon={coupon}
											couponPercent={couponPercent}
											promotion={promotion}
											loaderCoupon={loaderCoupon}
											loaderPromotion={loaderPromotion}
										/>
									) : (
										<EmptyCouponCard />
									)}
								</StyledPromotionCoupon>
							</StyledPromotionDateCouponContainer>
						</>

						<StyledBottomRow>
							<StyledPromotionStats>
								{CARDICONS.map((itens: ICard, index) => (
									<Button
										key={index}
										onClick={() => handleSelection(index)}
										disableTouchRipple
										disableFocusRipple
										disableRipple
										sx={{
											position: 'relative',
											height: '96px',
											width: '100%',
											paddingTop: '25px',
											marginBottom: '4%',
											paddingRight: '0px',
											paddingLeft: '0px',
											'&:last-child': {
												marginBottom: '0px',
											},
											'@media (max-width: 1441px)': {
												height: '80px',
												marginBottom: '15.5%',
											},
										}}
									>
										<StatisticsCard
											itens={{
												...itens,
												...{
													views: promotion?.views,
													likes: promotion?.qtdLikes,
													coupons: coupon?.used,
													loader: loaderPromotion,
												},
											}}
											index={index}
											indexButton={indexButton}
											from={'details'}
										/>
									</Button>
								))}
							</StyledPromotionStats>
							<StyledPromotionChart>
								{indexButton === 0 && (
									<ViewsChart data={view} loader={loaderChart} />
								)}
								{indexButton === 1 && (
									<LikesChart data={like} loader={loaderChart} />
								)}
								{indexButton === 2 && (
									<CouponsChart data={usedCoupons} loader={loaderChart} />
								)}
							</StyledPromotionChart>
							<StyledPromotionComments>
								<CommentsCard
									comments={comments}
									loader={loaderComments}
									totalComments={totalComments}
									handleOpenCommentsModal={handleOpenCommentsModal}
								/>
							</StyledPromotionComments>
						</StyledBottomRow>
						{openCommentsModal && (
							<CommentsModal
								openCommentsModal={openCommentsModal}
								handleCloseCommentsModal={handleCloseCommentsModal}
								id={id}
								loader={loaderComments}
								totalComments={totalComments}
							/>
						)}
					</StyledPromotionDetailsContent>
				)}

				{edit && (
					<StyledPromotionContent>
						<StyledTabsContainer>
							<PromotionTabs
								value={0}
								TabIndicatorProps={{
									style: {
										display: 'none',
										height: 0,
									},
								}}
							>
								<PromotionTab label={'Editar'} />
							</PromotionTabs>
						</StyledTabsContainer>
						<StyledCreatePromotion>
							<Formik
								enableReinitialize
								initialValues={{
									title: promotion?.title,
									description: promotion?.description,
									initial_price: promotion?.initial_price,
									promotional_price: promotion?.promotional_price,
									promotion_initial_date: promotion?.promotion_initial_date,
									promotion_expires_date: promotion?.promotion_expires_date,
									promotion_expires_hour: hourFormat(
										promotion?.promotion_expires_date
									),
									tag_id: promotion?.tag.id,
									tag_name: promotion?.tag.name,
									promotion_image: promotion?.image.url,
									paymentMethods: promotion?.paymentMethods,
									has_coupons: promotion?.has_coupons ?? false,
									qtd_coupons: promotion?.coupon_batch
										? promotion?.coupon_batch.qtd_coupons
										: 0,
									coupon_duration: promotion?.coupon_batch
										? promotion?.coupon_batch.coupon_duration
										: '',
								}}
								validationSchema={EDIT_PROMOTION_FIELDS_VALIDATION_SCHEMA}
								onSubmit={(values, { setSubmitting }) => {
									handleEditPromotion(values, setSubmitting);
								}}
							>
								{({ handleSubmit }) => (
									<form
										id="editForm"
										onSubmit={(e) => {
											e.preventDefault();
											handleSubmit();
										}}
										style={{ width: '100%' }}
									>
										<EditPromotionForm tags={tags} promotion={promotion} />
									</form>
								)}
							</Formik>
							<ModalSucess
								modalType={'sucess'}
								modalButtonText={'Ver minhas promoções'}
								modalTitle={'Alterações feitas com sucesso!'}
								openPromotionSucessModal={openPromotionSucessModal}
								handleClosePromotionModal={handleClosePromotionModal}
								modalText={
									'Você pode acessar e editar os dados dessa promoção em Promoções > Minhas Promoções.'
								}
							/>
						</StyledCreatePromotion>
					</StyledPromotionContent>
				)}
				{confirmModalProps && (
					<PromotionConfirmModal
						id={id}
						fromDetails={true}
						promotion={promotion}
						getPromotion={getPromotion}
						values={confirmModalProps.values}
						resetForm={confirmModalProps.resetForm}
						setSubmit={confirmModalProps.setSubmit}
						handleOpenPromotionModal={handleOpenPromotionModal}
						openPromotionConfirmModal={openPromotionConfirmModal}
						handleClosePromotionConfirmModal={handleClosePromotionConfirmModal}
					/>
				)}
			</StyledPromotionDetailsContainer>
		</StyledPromotionDetails>
	);
};

export default PromotionDetails;
