import { AxiosResponse } from "axios";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";

export const getPromotionById = async (
  promotionId: string
): Promise<AxiosResponse<any> | undefined> => {
  try {
    return request({
      url: `${BASE_URL}/promotions/${promotionId}`,
      method: "get",
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
