import { ChartOptions } from "../interface/chart-options.interface";

export const CHART_OPTIONS: ChartOptions = {
  maintainAspectRatio: false,
  scaleBeginAtZero: true,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,

      grid: {
        display: false,
      },
    },
  },
  elements: {
    point: {
      radius: 5,
      hitRadius: 80,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};
