import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CouponsPromotionModal from "../Coupons/CouponsPromotionModal/CouponsPromotionModal";
import { PromotionCardProps } from "./interface/promotion-data.interface";
import { ConditionalLinkProps } from "./interface/promotion-link.interface";
import {
  StyledPromotion,
  StyledPromotionContainer,
  StyledPromotionExpired,
  StyledPromotionImg,
  StyledPromotionTitle,
  StyledPromotionValue,
} from "./styles";

const PromotionCard: React.FC<PromotionCardProps> = ({
  data: { id, title, image, is_expired, promotional_price },
  data,
  fromCoupons,
}: PromotionCardProps): JSX.Element => {
  const [openPromotionModal, setOpenPromotionModal] = useState(false);
  const navigate = useNavigate();

  const handleClosePromotionModal = (): void => setOpenPromotionModal(false);

  const handleOpenPromotionModal = (): void => setOpenPromotionModal(true);
  const handlePromotionClick = () => {
    fromCoupons
      ? handleOpenPromotionModal()
      : navigate({ pathname: `/promotion/details/${id}` });
  };

  const ConditionalLink = ({
    children,
    to,
    condition,
  }: ConditionalLinkProps): JSX.Element =>
    !condition && to ? (
      <Link to={to} style={{ textDecoration: "none" }}>
        {children}
      </Link>
    ) : (
      <>{children}</>
    );

  return (
    <StyledPromotionContainer>
      <ConditionalLink
        to={{
          pathname: `/promotion/details/${id}`,
        }}
        condition={fromCoupons}
      >
        <StyledPromotionExpired isExpired={is_expired}>
          Desativada
        </StyledPromotionExpired>
        <StyledPromotion isExpired={is_expired} onClick={handlePromotionClick}>
          <StyledPromotionImg>
            <img src={image.url} alt="Imagem da promoção" />
          </StyledPromotionImg>
          <StyledPromotionTitle>{title}</StyledPromotionTitle>
          <StyledPromotionValue>
            {new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(Number(promotional_price))}
          </StyledPromotionValue>
        </StyledPromotion>
        {openPromotionModal && (
          <CouponsPromotionModal
            handleClosePromotionModal={handleClosePromotionModal}
            openPromotionModal={openPromotionModal}
            promotion={data}
          />
        )}
      </ConditionalLink>
    </StyledPromotionContainer>
  );
};

export default PromotionCard;
