import { Box, styled } from '@mui/material';

export const StyledContainer = styled(Box)(() => ({
	display: 'block',
	justifyContent: 'center',
	height: '500px',
	alignItems: 'center',
	position: 'relative',
	width: 'inherit',
}));

export const StyledTextContainer = styled(Box)(() => ({
	display: 'block',
	maxWidth: '30%',
}));
export const StyledContainerTitle = styled(Box)(({ theme }) => ({
	display: 'flex',
	boxSizing: 'border-box',
	width: '100%',
	height: '25%',
	justifyContent: 'center',
	alignItems: 'center',
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 24,
	lineHeight: '28px',
	color: theme.palette.neutral.dark,
	paddingTop: 30,
	'@media (orientation: portrait)': {
		fontWeight: 500,
		fontSize: 20,
		lineHeight: '20px',
		width: '60%',
		margin: '0px auto',
		textAlign: 'center',
		height: '15%',
	},
}));

export const StyeledCardsContainer = styled(Box)(() => ({
	display: 'flex',
	boxSizing: 'border-box',
	alignItems: 'center',
	width: '100%',
	height: '75%',
	justifyContent: 'space-around',
	padding: '0px 105px 0px 105px',
	'@media (orientation: portrait)': {
		display: 'block',
	},
}));
export const StyledCard = styled(Box)(() => ({
	display: 'block',
	width: 246,
	margin: '0 auto',
	paddingBottom: '50px',
	'@media only screen and (max-width: 1400px)': {
		width: '150px ',
		paddingBottom: '60px',
	},
}));
export const StyledCardImg = styled(Box)(() => ({
	display: 'flex',
	marginBottom: 20,
	justifyContent: 'center',
	'@media (orientation: portrait)': {
		justifyContent: 'center',
	},
}));
export const StyledCardTitle = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 18,
	lineHeight: '24px',
	color: theme.palette.neutral.dark,
	textAlign: 'center',
	marginBottom: 10,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
		lineHeight: '20px',
	},
	'@media (orientation: portrait)': {
		display: 'flex',
		justifyContent: 'center',
	},
}));
export const StyledCardText = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 400,
	fontSize: 14,
	lineHeight: '20px',
	textAlign: 'center',
	color: theme.palette.neutral.dark1,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 10,
		lineHeight: '16px',
	},
	'@media (orientation: portrait)': {
		textAlign: 'center',
	},
}));
