import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";
import { IItemOptionsGroup } from "../interfaces";

export const getOptionsGroupsByItem = async (
  itemId: string
): Promise<IItemOptionsGroup[]> => {
  try {
    const response = await request({
      url: `${BASE_URL}/optionGroups/items/${itemId}`,
      method: "get",
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
