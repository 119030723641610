import { Box, styled, Typography } from "@mui/material";

interface StyledDotProps {
  promotionDetails?: boolean | undefined;
}
export const StyledLastComment = styled(Box)(({ theme }) => ({
  display: "flex",
  whiteSpace: "nowrap",
  padding: "12px 25px 12px 12px",
  borderTop: "1px solid",
  borderColor: theme.palette.neutral.light2,
  justifyContent: "space-between",
  "@media only screen and (max-width: 1400px)": {
    padding: "8px 16px 8px 8px",
  },
  "@media only screen and (max-width: 1200px)": {
    padding: "7px 16px 7px 8px",
  },
}));

export const StyledLastCommentSkeleton = styled(Box)(({ theme }) => ({
  display: "flex",
  whiteSpace: "nowrap",
  padding: "12px 25px 12px 12px",
  borderTop: "1px solid",
  borderColor: theme.palette.neutral.light2,
  justifyContent: "space-between",
  "@media only screen and (max-width: 1400px)": {
    padding: "8px 16px 8px 8px",
  },
  "@media only screen and (max-width: 1200px)": {
    padding: "6px 16px 6px 8px",
  },
}));

export const StyledLastCommentContent = styled(Box)(() => ({
  display: "flex",
  width: "100%",
}));
export const StyledAvatar = styled(Box)(() => ({
  display: "flex",
  minWidth: 40,
  maxWidth: 40,
  height: 40,
  borderRadius: "50%",
  overflow: "hidden",
  pointerEvents: "none",
  "& img": {
    height: "100%",
    width: "100%",
  },
  "@media only screen and (max-width: 1400px)": {
    height: 27,
    minWidth: 27,
  },
}));
export const StyledLastCommentText = styled(Box)(() => ({
  display: "block",
  marginLeft: 12,
  width: "92%",
}));
export const StyledTitle = styled(Box)(() => ({
  display: "flex",
  whiteSpace: "nowrap",
  width: 540,
  overflow: "hidden",
  textOverflow: "ellipsis",
  "@media only screen and (max-width: 1400px)": {
    width: 292,
  },
}));
export const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.neutral.dark1,
}));
export const StyledPromotionName = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.neutral.dark,
  overflow: "hidden",
  textOverflow: "ellipsis",
  "@media only screen and (max-width: 1400px)": {
    fontSize: 10,
  },
}));

export const StyledPromotionTitle = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.neutral.dark1,
  overflow: "hidden",
  textOverflow: "ellipsis",
  "@media only screen and (max-width: 1400px)": {
    fontSize: 10,
  },
}));

export const StyledPromotionDetailsName = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.primary.main,
  overflow: "hidden",
  textOverflow: "ellipsis",
  "@media only screen and (max-width: 1400px)": {
    fontSize: 10,
  },
}));
export const StyledComment = styled(Box, {
  shouldForwardProp: (prop) => prop !== "promotionDetails",
})<StyledDotProps>(({ promotionDetails, theme }) => ({
  whiteSpace: promotionDetails ? "initial" : "nowrap",
  fontSize: 12,
  fontFamily: "Rubik",
  fontWeight: 400,
  color: theme.palette.neutral.dark,
  overflow: "hidden",
  textOverflow: "ellipsis",
  "@media only screen and (max-width: 1400px)": {
    fontSize: 10,
  },
}));

export const StyledDot = styled(Box, {
  shouldForwardProp: (prop) => prop !== "promotionDetails",
})<StyledDotProps>(({ promotionDetails }) => ({
  display: promotionDetails ? "block" : "none",
  position: "relative",
  float: "right",
  top: 10,
  "@media only screen and (max-width: 1400px)": {
    fontSize: 14,
    top: 0,
  },
}));
