import { Box, styled, TextField } from '@mui/material';

export const DataFormContainer = styled(Box)(() => ({
	display: 'block',
	position: 'relative',
	height: '100%',
	padding: '0px 40px',
}));

export const StyledUserWarning = styled(Box)(({ theme }) => ({
	display: 'flex',
	fontFamily: 'Rubik',
	maxHeight: 48,
	fontSize: 16,
	fontWeight: 500,
	color: theme.palette.primary.dark,
	alignItems: 'center',
	justifyContent: 'start',
	padding: '12px 12px 12px 0px',
	marginBottom: 28,
	borderRadius: 8,
	backgroundColor: theme.palette.neutral.light1,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
		padding: '16px 24px 16px 24px',
	},
}));

export const StyledProfileName = styled(Box)(() => ({
	maxHeight: 48,
	marginRight: 24,
	marginBottom: 24,
	// '@media only screen and (max-width: 1500px)': {
	// 	marginBottom: 29,
	// },
}));

export const CategoryAndPayment = styled(Box)(() => ({
	display: 'flex',
}));

export const StyledNameTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 48,
		width: 400,
		fontFamily: 'Rubik',
		'@media only screen and (max-width: 1500px)': {
			fontSize: 14,
			height: 40,
			width: 300,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'@media only screen and (max-width: 1500px)': {
		'& .MuiFormLabel-root': {
			fontSize: '15px',
		},
	},
}));

export const StyledProfileDescription = styled(Box)(() => ({
	maxHeight: 150,
	marginBottom: 52,
	'@media only screen and (max-width: 1500px)': {
		marginBottom: 29,
	},
}));

export const StyledDescriptionTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 150,
		width: '100%',
		fontFamily: 'Rubik',
		'@media only screen and (max-width: 1500px)': {
			fontSize: 14,
			height: 108,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'@media only screen and (max-width: 1500px)': {
		'& .MuiFormLabel-root': {
			fontSize: '15px',
		},
	},
}));

export const StyledCategoriesDescription = styled(Box)(() => ({
	maxHeight: 48,
}));

export const StyledCategoriesTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 48,
		width: 230,
		'@media only screen and (max-width: 1500px)': {
			fontSize: 14,
			height: 40,
			width: 180,
		},
		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'@media only screen and (max-width: 1500px)': {
		'& .MuiFormLabel-root': {
			fontSize: '13px',
		},
	},
}));
