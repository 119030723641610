import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage/LandingPage';
import Login from './pages/Login/Login';
import SignUp from './pages/SignUp/SignUp';

const AuthRoutes: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<LandingPage />} />
			<Route path="/login" element={<Login />} />
			<Route path="/sign-up" element={<SignUp />} />
			<Route path="*" element={<Navigate to="/" replace />} />
		</Routes>
	);
};

export default AuthRoutes;
