import { Box, CircularProgress } from '@mui/material';
import { Formik, FormikErrors, FormikValues } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { addOptionGroupId } from '../../../auth/actions/editOptionGroup.action';
import {
	IEditOptionGroup,
	IEditOptionGroupAction,
} from '../../../auth/interface/editOptionGroup.interface';
import DashboardTopBar from '../../../components/DashboardTopBar/DashboardTopBar';
import Complement from '../../../components/Forms/AddItem/Complement/Complement';
import Details from '../../../components/Forms/AddItem/Details/Details';
import { DetailsGroup } from '../../../components/Forms/AddItem/DetailsGroup/DetailsGroup';
import { BASE_URL } from '../../../utils/api/api';
import { request } from '../../../utils/api/base-request/base-request';
import { LocationState } from '../../../utils/api/interfaces';
import { createOrEditCategoryItem } from '../../../utils/api/post/createOrEditCategoryItem';
import { unMaskReais } from '../../../utils/mask.util';
import { CREATE_ITEM_VALIDATION_SCHEMA } from '../../Promotion/constants/createItem-validationSchema';
import {
	FetchItemResponseProps,
	IComplementsGroupProps,
	OptionGroup,
} from '../Interfaces/interfaces';
import {
	ActionButtons,
	ActionsButtonsText,
	AddProfileTab,
	ButtonContinue,
	ContainerForLoading,
	GoBackArrow,
	GoBackButton,
	GoBackInformation,
	ProfileTab,
	ProfileTabs,
	StepInformation,
	StyledCreateProfileContainer,
	StyledForm,
	StyledProfile,
	StyledProfileContainer,
	StyledProfileContent,
	StyledProfileTopBar,
} from './styles';
import {
	initialDefaultValues,
	initialEdittingValues,
} from './utils/initialFormValues';

const PreparedItem: React.FC = (): JSX.Element => {
	const step = 0;
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);
	const [selectedStep, setSelectedStep] = useState(step);
	const [item, setItem] = useState<FetchItemResponseProps>(); // If this exists, it means that the user is editting a item.
	const itemId = (location.state as LocationState)?.itemId ?? ''; // If this exists, it means that the user is editting a item.
	const optionGroupId = (location.state as LocationState)?.optionGroupId ?? ''; // If this exists, it means that the user is editting a optionGroup an it came from Menu screen.
	const newCategoryId = (location.state as LocationState)?.categoryId ?? '';
	const [fetchingItemInfo, setFetchingItemInfo] = useState(
		itemId || optionGroupId ? true : false
	);
	const [oldOptionGroup, setOldOptionGroup] = useState<OptionGroup[]>();

	const currentValidationSchema = CREATE_ITEM_VALIDATION_SCHEMA[selectedStep];
	const fetchItem = async () => {
		if (itemId) {
			try {
				const response = await request({
					url: `${BASE_URL}/itens/${itemId}`,
					method: 'get',
				});
				setItem(response?.data);
				const initialOptionGroup = response?.data?.optionGroup?.map(
					(optionGroup: OptionGroup) => ({
						id: optionGroup.id ?? undefined,
						name: optionGroup?.name ?? '',
						description: optionGroup?.description ?? '',
						minimum: optionGroup?.minimum ?? 0,
						maximum: optionGroup?.maximum ?? 1,
						optional: optionGroup?.minimum > 0 ? false : true ?? false,
						available: optionGroup?.available ?? false,
						options: optionGroup?.options?.map((option) => ({
							id: option.id ?? undefined,
							name: option?.name ?? '',
							description: option?.description ?? '',
							available: option?.available ?? false,
							optionImage: '',
							optionPrice: option?.price ? option?.price.value.value : 0,
						})),
					})
				);
				setOldOptionGroup(initialOptionGroup);
			} catch (error) {
				return Promise.reject(error);
			}
		}
	};

	const initialValues = !itemId
		? initialDefaultValues(newCategoryId)
		: initialEdittingValues(item);

	const infoStepName = [
		`  >   ${!itemId ? 'Adicionar Item' : `${item?.name}`}`,
		`  >   ${!itemId ? 'Adicionar Item' : `${item?.name}`}   >  Complementos `,
		`  >   ${
			!itemId ? 'Adicionar Item' : `${item?.name}`
		}   >   Complementos   >   Novo grupo de complementos `,
		`  >   ${
			!itemId ? 'Adicionar Item' : `${item?.name}`
		}   >   Complementos   >   Novo grupo de complementos   >   Itens complementares`,
	];

	const getButtonText = (hasOptionGroup: boolean) => {
		if (selectedStep === 1) {
			if (!hasOptionGroup && !itemId) {
				return 'Criar item';
			} else if (!hasOptionGroup && itemId) {
				return 'Salvar';
			} else {
				return 'Salvar';
			}
		} else if (selectedStep === 3) {
			if (!itemId) {
				return 'Criar item';
			} else {
				return 'Continuar';
			}
		} else {
			return 'Continuar';
		}
	};

	const setOptionGroupEdit = (
		optionGroupId: IEditOptionGroup
	): IEditOptionGroupAction => dispatch(addOptionGroupId(optionGroupId));

	const handleGoBackToMenu = () => navigate('/menu');

	const handleBackBehavior = (
		values: FormikValues,
		setFieldValue: (
			field: string,
			value: any,
			shouldValidate?: boolean | undefined
		) => Promise<void | FormikErrors<any>>
	) => {
		if (selectedStep === 0) {
			handleGoBackToMenu();
		}

		if (selectedStep <= 2) {
			if (
				oldOptionGroup?.length === values.optionGroups?.length &&
				oldOptionGroup !== values.optionGroups
			) {
				setFieldValue('optionGroups', oldOptionGroup);
			}
			setSelectedStep(selectedStep - 1);
		}

		if (!values.hasOptionGroup && selectedStep === 1) {
			setSelectedStep(selectedStep - 1);
		}

		if (selectedStep === 3) {
			setSelectedStep(2);
		}
	};

	const handleTabChange = (
		event: React.SyntheticEvent,
		newValue: number
	): void => {
		event.stopPropagation();
		setSelectedStep(newValue);
	};

	const submitForm = (values: FormikValues) => {
		setLoading(true);
		const {
			name,
			description,
			hasOptionGroup,
			available,
			categoryId,
			tag_id,
			itemImage,
			portionSize,
			price: priceRaw,
		} = values;

		const price = unMaskReais(priceRaw);

		const optionGroups = (values.optionGroups as IComplementsGroupProps[])?.map(
			({ options, ...rest }) => {
				const modifiedOptions = options.map((option) => {
					const optionPrice = unMaskReais(String(option.optionPrice));
					return { ...option, optionPrice };
				});

				return { options: modifiedOptions, ...rest };
			}
		);

		const valuesToSend: {
			name: string;
			description: string;
			hasOptionGroup: boolean;
			available: boolean;
			categoryId?: string;
			price: number;
			tag_id?: string;
			itemImage?: string;
			portionSize: number;
			optionGroups?: IComplementsGroupProps[];
		} = {
			name,
			description,
			hasOptionGroup,
			available,
			categoryId,
			price,
			tag_id,
			itemImage,
			portionSize,
			optionGroups,
		};

		if (itemImage === item?.item_image_url) {
			delete valuesToSend.itemImage;
		}

		if (!hasOptionGroup) {
			delete valuesToSend.optionGroups;
		}

		if (optionGroups[0].name) {
			valuesToSend.optionGroups?.map((item) => delete item.optional);
		}

		if (itemId) {
			delete valuesToSend.categoryId;
			delete valuesToSend.tag_id;
		}

		const snackBarMessage = !itemId
			? 'Item criado com sucesso!'
			: 'Alterações salvas!';

		createOrEditCategoryItem({
			values: valuesToSend,
			isEditting: !!itemId,
			itemId,
		})
			.then(() => {
				handleGoBackToMenu();
				enqueueSnackbar(snackBarMessage, {
					variant: 'success',
					autoHideDuration: 2000,
				});
			})
			.catch(() => {
				enqueueSnackbar('Ocorreu um erro, tente novamente.', {
					variant: 'error',
					autoHideDuration: 2000,
				});
			})
			.finally(() => setLoading(false));
	};

	const handleSubmit = (values: FormikValues) => {
		if (selectedStep === 0) {
			setSelectedStep(selectedStep + 1);
			return;
		}

		if (selectedStep === 1 && !values.hasOptionGroup) {
			if (values.optionGroups === null || values.optionGroups === undefined) {
				values.optionGroups = [];
			}
			values.optionGroups.push({
				name: '',
				description: '',
				minimum: 0,
				maximum: 0,
				available: true,
				optional: true,
				options: [
					{
						name: '',
						description: '',
						available: false,
						optionImage: '',
						optionPrice: 0,
					},
				],
			});
			submitForm(values);
			return;
		}

		if (
			selectedStep === 1 &&
			values.hasOptionGroup &&
			values.optionGroups.length > 0
		) {
			submitForm(values);
			return;
		}

		if (selectedStep === 2 && values.hasOptionGroup) {
			setSelectedStep(selectedStep + 1);
			return;
		}
		if (selectedStep === 3) {
			setOldOptionGroup(values.optionGroups);
			setSelectedStep(1);
			return;
		}
	};

	const handleRenderCreateGroup = () => {
		setSelectedStep(2);
	};

	const renderStepContent = (
		tab: number,
		values: FormikValues,
		handleChange: (e: React.ChangeEvent<any>) => void,
		setFieldValue: (
			field: string,
			value: any,
			shouldValidate?: boolean | undefined
		) => Promise<void | FormikErrors<any>>
	) => {
		if (values.hasOptionGroup) {
			switch (tab) {
				case 0:
					return <Details isEditting={!!itemId} />;

				case 1:
					return (
						<Complement
							isEditting={!!itemId}
							handleRenderCreateGroup={handleRenderCreateGroup}
							hasOptionGroup={values.hasOptionGroup}
							optionGroups={values.optionGroups}
						/>
					);

				case 2:
					if (
						oldOptionGroup?.length !== values.optionGroups?.length ||
						oldOptionGroup === values.optionGroups
					) {
						setOldOptionGroup(values.optionGroups);
					}
					return (
						<DetailsGroup
							position={0}
							handleChange={handleChange}
							setFieldValue={setFieldValue}
							optionGroups={values.optionGroups}
						/>
					);

				case 3:
					return (
						<DetailsGroup
							position={1}
							handleChange={handleChange}
							setFieldValue={setFieldValue}
							optionGroups={values.optionGroups}
						/>
					);

				default:
					return <></>;
			}
		} else {
			switch (tab) {
				case 0:
					return <Details isEditting={!!itemId} />;

				case 1:
					return (
						<Complement
							isEditting={!!itemId}
							handleRenderCreateGroup={handleRenderCreateGroup}
							hasOptionGroup={values.hasOptionGroup}
							optionGroups={values.optionGroups}
						/>
					);

				default:
					return <></>;
			}
		}
	};
	useEffect(() => {
		fetchItem();

		return () => {
			setItem(undefined);
		};
	}, []);

	useEffect(() => {
		if (!optionGroupId && item?.id) {
			setFetchingItemInfo(false);
		}

		return () => {
			setFetchingItemInfo(false);
		};
	}, [item, optionGroupId]);

	useEffect(() => {
		if (itemId && item && optionGroupId) {
			const indexOfGroup = item.optionGroup?.findIndex(
				(item) => item.id === optionGroupId
			);

			setOptionGroupEdit({ optionGroupEditId: indexOfGroup });

			handleRenderCreateGroup();

			// Using this setState here ensures that the loading will disappear only when the correct component is created in the DOM.
			setTimeout(() => {
				setFetchingItemInfo(false);
			}, 500);
		}

		return () => {
			setFetchingItemInfo(false);
			setSelectedStep(0);
			setOptionGroupEdit({ optionGroupEditId: 0 });
		};
	}, [itemId, item, optionGroupId]);

	return (
		<StyledProfile>
			<StyledProfileTopBar>
				<DashboardTopBar
					title={itemId ? 'Editar Item' : 'Cardápio'}
					text={
						itemId
							? 'Edite a categoria e outros dados do item'
							: 'Visualize e adicione itens da sua loja no seu cardápio'
					}
				/>
			</StyledProfileTopBar>

			{fetchingItemInfo ? (
				<ContainerForLoading>
					<CircularProgress color="primary" />
				</ContainerForLoading>
			) : (
				<StyledProfileContainer>
					<StyledProfileContent>
						<StyledCreateProfileContainer>
							<Formik
								initialValues={initialValues}
								enableReinitialize
								validationSchema={currentValidationSchema}
								validateOnChange={false}
								onSubmit={(values: any) => {
									handleSubmit(values);
								}}
							>
								{({ values, handleChange, setFieldValue }) => (
									<Box>
										<ProfileTabs
											value={selectedStep}
											onChange={handleTabChange}
											TabIndicatorProps={{ sx: { display: 'none' } }}
										>
											<ProfileTab label="Adicionar item" disabled={true} />
											<ProfileTab label="Complementos" disabled={true} />
											{values.hasOptionGroup && (
												<AddProfileTab
													label="Grupo de complementos"
													disabled={true}
												/>
											)}

											{values.hasOptionGroup && (
												<AddProfileTab
													label="Detalhes dos complementos"
													disabled={true}
												/>
											)}

											<ProfileTab label="Disponibilidade" disabled={true} />
										</ProfileTabs>
										<StyledForm selectedtab={selectedStep}>
											<GoBackInformation>
												<GoBackButton
													onClick={() =>
														handleBackBehavior(values, setFieldValue)
													}
												>
													<GoBackArrow />
												</GoBackButton>
												<StepInformation>
													Itens do cardápio{infoStepName[selectedStep]}
												</StepInformation>
											</GoBackInformation>

											{renderStepContent(
												selectedStep,
												values,
												handleChange,
												setFieldValue
											)}

											<ActionButtons>
												<ButtonContinue type="submit">
													{loading ? (
														<CircularProgress color="secondary" />
													) : (
														<ActionsButtonsText namebutton={'Continuar'}>
															{getButtonText(values.hasOptionGroup)}
														</ActionsButtonsText>
													)}
												</ButtonContinue>
											</ActionButtons>
										</StyledForm>
									</Box>
								)}
							</Formik>
						</StyledCreateProfileContainer>
					</StyledProfileContent>
				</StyledProfileContainer>
			)}
		</StyledProfile>
	);
};

export default PreparedItem;
