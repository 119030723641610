import { Box, Divider, Skeleton } from '@mui/material';
import {
	Container,
	Top,
	Cards,
	Middle,
	List,
	Footer,
	AlignSkeleton,
} from './styles';

const OrderSkeleton: React.FC = (): JSX.Element => {
	return (
		<Container>
			<Top>
				<Skeleton
					sx={{
						height: 22,
						width: 150,
						borderRadius: 8,
					}}
				/>
				<Skeleton
					sx={{
						height: 22,
						width: 250,
						borderRadius: 8,
					}}
				/>
			</Top>

			<Divider />
			<Cards>
				<Skeleton
					sx={{
						height: 130,
						width: 130,
						borderRadius: 8,
					}}
				/>
				<Skeleton
					sx={{
						height: 130,
						width: 130,
						borderRadius: 8,
					}}
				/>
				<Skeleton
					sx={{
						height: 130,
						width: 130,
						borderRadius: 8,
					}}
				/>
			</Cards>
			<Middle>
				<Skeleton
					sx={{
						height: 22,
						width: 150,
						borderRadius: 8,
						marginLeft: 2,
					}}
				/>
				<List>
					<Box>
						<Skeleton
							sx={{
								height: 22,
								width: 480,
								borderRadius: 8,
								marginTop: 1.5,
							}}
						/>
						<Skeleton
							sx={{
								height: 22,
								width: 450,
								marginLeft: 2.5,
								borderRadius: 8,
								marginTop: 1.5,
							}}
						/>
						<Skeleton
							sx={{
								height: 22,
								width: 480,
								borderRadius: 8,
								marginTop: 1.5,
							}}
						/>
						<Skeleton
							sx={{
								height: 22,
								width: 450,
								borderRadius: 8,
								marginTop: 1.5,
								marginLeft: 2.5,
							}}
						/>
						<Skeleton
							sx={{
								height: 22,
								width: 480,
								borderRadius: 8,
								marginTop: 1.5,
							}}
						/>
						<Skeleton
							sx={{
								height: 22,
								width: 450,
								marginLeft: 2.5,
								borderRadius: 8,
								marginTop: 1.5,
							}}
						/>
					</Box>
				</List>
			</Middle>
			<Footer>
				<AlignSkeleton>
					<Skeleton
						sx={{
							height: 22,
							width: 100,
							borderRadius: 8,
							marginTop: 1.5,
						}}
					/>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<Skeleton
							sx={{
								height: 22,
								width: 180,
								borderRadius: 8,
								marginTop: 1.5,
							}}
						/>
						<Skeleton
							sx={{
								height: 22,
								width: 180,
								borderRadius: 8,
								marginTop: 1.5,
							}}
						/>
					</Box>
				</AlignSkeleton>
			</Footer>
			<Footer>
				<AlignSkeleton>
					<Skeleton
						sx={{
							height: 22,
							width: 100,
							borderRadius: 8,
							marginTop: 1.5,
						}}
					/>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<Skeleton
							sx={{
								height: 22,
								width: 180,
								borderRadius: 8,
								marginTop: 1.5,
							}}
						/>
						<Skeleton
							sx={{
								height: 22,
								width: 180,
								borderRadius: 8,
								marginTop: 1.5,
							}}
						/>
					</Box>
				</AlignSkeleton>
			</Footer>
		</Container>
	);
};

export default OrderSkeleton;
