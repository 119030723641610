import * as yup from "yup";

const validationForgotPasswordSchema = yup.object({
  email: yup
    .string()
    .email("Entre um e-mail válido")
    .required("Por favor, preencha o campo acima"),
});

export default validationForgotPasswordSchema;
