import {
	ADD_OPTIONGROUPID,
	REMOVE_OPTIONGROUPID,
} from '../constants/editOptionGroup-config.constant';
import {
	IEditOptionGroup,
	IEditOptionGroupAction,
} from '../interface/editOptionGroup.interface';

export const addOptionGroupId = (
	editOptionGroup: IEditOptionGroup
): IEditOptionGroupAction => ({
	type: ADD_OPTIONGROUPID,
	payload: editOptionGroup,
});

export const removeOptionGroupId = (
	editOptionGroup: IEditOptionGroup
): IEditOptionGroupAction => ({
	type: REMOVE_OPTIONGROUPID,
	payload: editOptionGroup,
});
