import { AxiosError } from "axios";
import { Form, Formik, FormikValues } from "formik";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router";
import DashboardTopBar from "../../../components/DashboardTopBar/DashboardTopBar";
import { LocationState } from "../../../utils/api/interfaces";
import { patchMenuById } from "../../../utils/api/patch/patchMenuById";
import {
  Buttons,
  CancelButton,
  CategoryName,
  Container,
  CreateButton,
  StyledMenuContainer,
  StyledMenuContent,
  StyledMenuTopBar,
  SubTitle,
  Texts,
  WrapperContainer,
} from "../NewCategoryFirstStep/styles";
import VALIDATION_CREATE_CATEGORY_SCHEMA from "../constants/create-categorySchema";

const EditCategory: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const id = (location.state as LocationState)?.categoryId ?? "";
  const name = (location.state as LocationState)?.categoryName ?? "";
  const navigate = useNavigate();

  const hadleNavigateToMenu = (): void => {
    navigate("/menu");
  };

  const handleEditCategory = (
    values: FormikValues,
    setSubmitting: (isSubmitting: boolean) => void
  ): void => {
    const { name } = values;
    patchMenuById(id, undefined, name)
      .then(() => {
        setSubmitting(false);
        enqueueSnackbar("Categoria salva com sucesso", {
          variant: "success",
          autoHideDuration: 1500,
        });
        hadleNavigateToMenu?.();
      })
      .catch((err: AxiosError) => {
        if (err) {
          setSubmitting(false);
          enqueueSnackbar("Nome da categoria invalido!", {
            variant: "error",
            autoHideDuration: 1500,
          });
        }
      });
  };
  return (
    <Container>
      <StyledMenuContainer>
        <StyledMenuTopBar>
          <DashboardTopBar
            title={"Editar categoria"}
            text={"Edite as informação da categoria"}
          />
        </StyledMenuTopBar>
        <StyledMenuContent>
          <WrapperContainer>
            <SubTitle>Edite o nome da categoria</SubTitle>
            <Formik
              initialValues={{ name: name }}
              validationSchema={VALIDATION_CREATE_CATEGORY_SCHEMA}
              onSubmit={(values, { setSubmitting }) => {
                handleEditCategory(values, setSubmitting);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values: { name },
                touched,
                errors,
              }) => (
                <Form>
                  <CategoryName
                    label="Nome"
                    value={name}
                    inputProps={{ maxLength: 40 }}
                    helperText={touched.name && errors.name}
                    onChange={handleChange("name")}
                    error={touched.name && Boolean(errors.name)}
                  />
                  <Buttons>
                    <CancelButton onClick={hadleNavigateToMenu}>
                      <Texts propName={"Cancelar"}>Cancelar</Texts>
                    </CancelButton>
                    <CreateButton onClick={() => handleSubmit()}>
                      <Texts propName={"Criar"}>Editar categoria</Texts>
                    </CreateButton>
                  </Buttons>
                </Form>
              )}
            </Formik>
          </WrapperContainer>
        </StyledMenuContent>
      </StyledMenuContainer>
    </Container>
  );
};

export default EditCategory;
