import { Box, Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ContinueButton from '../../../Buttons/ContinueLoginButton/ContinueLoginButton';
import EmailInput from '../../../Inputs/EmailInput/EmailInput';
import PasswordInput from '../../../Inputs/PasswordInput/PasswordInput';
import {
	BodyGrid,
	FormBody,
	FormBodyHeader,
	FormBox,
	ItemGrid,
	StyledFormSubText,
	StyledFormText,
} from '../styles';
import { LoginFormProps } from './interface/login-page.interface';
import {
	FormFooter,
	StyledFieldInput,
	StyledFormCreateAccountLink,
	StyledFormCreateAccountText,
	StyledLoginButtonContainer,
	StyledTypographyLInk,
} from './styles';

const LoginForm: React.FC<LoginFormProps> = ({
	handleFormPage,
	handleRememberMeChange,
	rememberMe,
}: LoginFormProps): JSX.Element => {
	const navigate = useNavigate();
	return (
		<FormBox>
			<FormBodyHeader rowGap={1} display="flex" flexDirection="column">
				<StyledFormText>
					Bem-vindo!<br></br> É bom ter você aqui
				</StyledFormText>
				<StyledFormSubText>Faça o login na sua conta:</StyledFormSubText>
			</FormBodyHeader>
			<FormBody justifyContent="center">
				<BodyGrid>
					<ItemGrid xs={12}>
						<StyledFieldInput>
							<EmailInput />
						</StyledFieldInput>
					</ItemGrid>
					<ItemGrid xs={12}>
						<StyledFieldInput>
							<PasswordInput />
						</StyledFieldInput>
					</ItemGrid>
					<ItemGrid
						xs={12}
						sx={{ display: 'flex', justifyContent: 'space-between' }}
					>
						<FormControlLabel
							control={
								<Checkbox
									checked={rememberMe}
									onChange={handleRememberMeChange}
								/>
							}
							label="Lembrar de mim"
						/>
						<Box>
							<StyledTypographyLInk onClick={handleFormPage}>
								Esqueceu sua senha?
							</StyledTypographyLInk>
						</Box>
					</ItemGrid>
				</BodyGrid>
			</FormBody>
			<FormFooter>
				<StyledLoginButtonContainer>
					<ContinueButton>ENTRAR</ContinueButton>
				</StyledLoginButtonContainer>
				<StyledFormCreateAccountText>
					Não tem uma conta?&nbsp;
					<StyledFormCreateAccountLink onClick={() => navigate('/sign-up')}>
						Criar conta
					</StyledFormCreateAccountLink>
				</StyledFormCreateAccountText>
			</FormFooter>
		</FormBox>
	);
};

export default LoginForm;
