import { Box, Skeleton, styled, Typography } from "@mui/material";

interface StyledTextProps {
  isSelected: boolean;
}

export const StyledNumber = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<StyledTextProps>(({ theme, isSelected }) => ({
  fontSize: 20,
  fontFamily: "Rubik",
  fontWeight: 500,
  display: "flex",
  minWidth: 36,
  justifyContent: "center",
  color: isSelected ? theme.palette.neutral.light : theme.palette.primary.dark,
  "@media only screen and (max-width: 1400px)": {
    fontSize: 14,
  },
  "@media only screen and (max-width: 1200px)": {
    minWidth: 20,
  },
}));

export const StyledViewText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<StyledTextProps>(({ theme, isSelected }) => ({
  fontSize: 14,
  fontFamily: "Rubik",
  fontWeight: 400,
  display: "baseline",
  color: isSelected ? theme.palette.neutral.light : theme.palette.primary.dark,
  textAlign: "left",
  textTransform: "none",
  marginTop: 5,
  "@media only screen and (max-width: 1400px)": {
    fontSize: 10,
  },
}));

export const StyledNumberContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const StyledPeriod = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<StyledTextProps>(({ theme, isSelected }) => ({
  display: "flex",
  height: 14,
  fontSize: 14,
  fontFamily: "Rubik",
  fontWeight: 400,
  lineHeight: "14px",
  color: isSelected ? theme.palette.neutral.light : theme.palette.neutral.dark1,
  textTransform: "none",
  "@media only screen and (max-width: 1400px)": {
    fontSize: 10,
  },
}));

export const StyledNumberSkeleton = styled(Skeleton)(() => ({
  height: 20,
  width: "100%",
  "@media only screen and (max-width: 1200px)": {
    width: "90%",
  },
}));
