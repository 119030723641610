import { Skeleton } from "@mui/material";
import React, { useState } from "react";
import { CouponCardProps } from "./interface/couponCard.interface";
import {
  CouponTab,
  CouponTabs,
  ExpiredCouponTab,
  StyledCouponPercent,
  StyledCouponTitle,
  StyledCouponValue,
  StyledCouponValueContainer,
  StyledPromotionCouponBarContainer,
  StyledTabsContainer,
} from "./styles";

const CouponCardPromotionExpired: React.FC<CouponCardProps> = ({
  coupon,
  couponPercent,
}: CouponCardProps): JSX.Element => {
  const [couponselectedTab, setCouponSelectedTab] = useState(1);

  const handleCouponTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setCouponSelectedTab(newValue);
  };
  return (
    <>
      <StyledCouponValueContainer>
        {couponselectedTab === 0 && (
          <>
            <StyledCouponValue>
              {coupon && coupon.used <= 1
                ? `${coupon?.expired} cupom expirado`
                : `${coupon?.expired} cupons expirados`}
            </StyledCouponValue>
            <StyledCouponPercent>
              {couponPercent?.UsedPercentValue}
            </StyledCouponPercent>
          </>
        )}
        {couponselectedTab === 1 && (
          <>
            {coupon ? (
              <StyledCouponValue>
                {coupon && coupon.used <= 1
                  ? `${coupon?.used} cupom utilizado`
                  : `${coupon?.used} cupons utilizados`}
              </StyledCouponValue>
            ) : (
              <StyledCouponValue>
                <Skeleton
                  sx={{
                    width: 200,
                    height: 28,
                  }}
                  animation="wave"
                  variant="rectangular"
                />
              </StyledCouponValue>
            )}
            <StyledCouponPercent>
              {couponPercent?.UsedPercentValue}
            </StyledCouponPercent>
          </>
        )}
      </StyledCouponValueContainer>
      <StyledTabsContainer>
        <CouponTabs
          value={couponselectedTab}
          onChange={handleCouponTabChange}
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
        >
          <ExpiredCouponTab label="Expirados" />
          <ExpiredCouponTab label="Utilizados" />
        </CouponTabs>
      </StyledTabsContainer>
    </>
  );
};

const CouponCard: React.FC<CouponCardProps> = ({
  coupon,
  couponPercent,
  loaderCoupon,
  loaderPromotion,
  promotion,
}: CouponCardProps): JSX.Element => {
  const [couponselectedTab, setCouponSelectedTab] = useState(0);

  const handleCouponTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ): void => {
    setCouponSelectedTab(newValue);
  };
  return (
    <>
      {loaderPromotion ? (
        <StyledCouponTitle>
          <Skeleton
            sx={{ height: 20, width: 30 }}
            animation="wave"
            variant="rectangular"
          />
          &nbsp;cupons
        </StyledCouponTitle>
      ) : (
        <StyledCouponTitle>
          {promotion?.coupon_batch ? promotion?.coupon_batch.qtd_coupons : 0}{" "}
          cupons totais
        </StyledCouponTitle>
      )}
      <StyledPromotionCouponBarContainer>
        {promotion?.is_expired ? (
          <CouponCardPromotionExpired
            promotion={promotion}
            coupon={coupon}
            couponPercent={couponPercent}
            loaderPromotion={loaderPromotion}
            loaderCoupon={loaderCoupon}
          />
        ) : (
          <>
            <StyledCouponValueContainer>
              {couponselectedTab === 0 && (
                <>
                  {coupon ? (
                    <StyledCouponValue>
                      {coupon && coupon.available <= 1
                        ? `${coupon.available} cupom disponível`
                        : `${coupon?.available} cupons disponíveis`}
                    </StyledCouponValue>
                  ) : (
                    <StyledCouponValue>
                      <Skeleton
                        sx={{
                          width: 200,
                          height: 28,
                        }}
                        animation="wave"
                        variant="rectangular"
                      />
                    </StyledCouponValue>
                  )}
                  <StyledCouponPercent>
                    {couponPercent?.AvaiblePercentValue}
                  </StyledCouponPercent>
                </>
              )}
              {couponselectedTab === 1 && (
                <>
                  <StyledCouponValue>
                    {coupon && coupon.ransomed <= 1
                      ? `${coupon?.ransomed} cupom resgatado`
                      : `${coupon?.ransomed} cupons resgatados`}
                  </StyledCouponValue>
                  <StyledCouponPercent>
                    {couponPercent?.RedeemedPercentValue}
                  </StyledCouponPercent>
                </>
              )}
              {couponselectedTab === 2 && (
                <>
                  <StyledCouponValue>
                    {coupon && coupon.used <= 1
                      ? `${coupon?.used} cupom utilizado`
                      : `${coupon?.used} cupons utilizados`}
                  </StyledCouponValue>
                  <StyledCouponPercent>
                    {couponPercent?.UsedPercentValue}
                  </StyledCouponPercent>
                </>
              )}
            </StyledCouponValueContainer>
            <StyledTabsContainer>
              <CouponTabs
                value={couponselectedTab}
                onChange={handleCouponTabChange}
                TabIndicatorProps={{
                  sx: {
                    display: "none",
                  },
                }}
              >
                <CouponTab label="Disponíveis" />
                <CouponTab label="Resgatados" />
                <CouponTab label="Utilizados" />
              </CouponTabs>
            </StyledTabsContainer>
          </>
        )}
      </StyledPromotionCouponBarContainer>
    </>
  );
};

export default CouponCard;
