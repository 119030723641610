import { Box, Button, styled } from '@mui/material';

export const FormContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	backgroundColor: theme.palette.neutral.light,
	width: '70%',
	minHeight: '50vh',
	maxHeight: '50vh',
	flexGrow: 1,
	padding: '69px 60px 55px 65px',
	flexDirection: 'column',
	justifyContent: 'space-between',
	borderRadius: 40,
	[theme.breakpoints.down('md')]: {
		margin: 'auto',
		maxWidth: '90%',
		maxHeight: '90vh',
		padding: '40px 40px 40px 40px',
	},
}));

export const FormTitle = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 22,
	lineHeight: '28px',
	color: theme.palette.primary.main,
	marginBottom: 36,
	textAlign: 'center',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 18,
		lineHeight: '24px',
	},
}));

export const FormTextInline = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	width: '100%',
	height: 55,
	marginTop: 32,
	marginBottom: 32,
	'@media only screen and (max-width: 1400px)': {
		height: 45,
	},
	'@media (orientation: portrait)': {
		fontSize: 10,
		display: 'block',
		height: 'unset',
		marginTop: 30,
		marginBottom: 16,
	},
}));

export const FormText = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 18,
	color: theme.palette.neutral.dark,
	marginBottom: 8,
	width: '80%',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
	},
}));

export const FormTextStyledBig = styled(Box)(() => ({
	width: '100%',
	height: 55,
	'@media only screen and (max-width: 1400px)': {
		height: 45,
	},
}));
export const FormTextStyled = styled(Box)(() => ({
	height: 5,
	width: '48%',
	'@media (orientation: portrait)': {
		height: '55px',
		width: '100%',
		marginBottom: 20,
	},
}));

export const FormTermsStyled = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	width: '100%',
	maxHeight: 18,
	marginTop: 40,
}));

export const FormTermsTextStyled = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 400,
	fontSize: 14,
	color: theme.palette.neutral.dark,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
	},
}));

export const FormTermsLink = styled(Box)(({ theme }) => ({
	cursor: 'pointer',
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 14,
	color: theme.palette.primary.main,
	textDecoration: 'underline',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
	},
}));

export const StyledFormButton = styled(Button)(({ theme }) => ({
	margin: '0 auto',
	marginTop: 86,
	width: '80% !important',
	height: 48,
	borderRadius: '4px !important',
	backgroundColor: theme.palette.primary.main,
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 18,
	color: theme.palette.neutral.light,
	'&:hover': {
		backgroundColor: theme.palette.primary.main,
	},
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
		marginLeft: '80px !important',
	},
	'@media (orientation: portrait)': {
		fontSize: 12,
		marginLeft: '40px !important',
		width: '74% !important',
	},
}));

export const StyledErrorMessage = styled(Box)(({ theme }) => ({
	fontSize: 12,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.error.main,
	marginTop: 3,
	marginLeft: 14,
	lineHeight: 1.66,
	letterSpacing: '0.03333em',
	'@media (orientation: portrait)': {
		fontSize: 10,
	},
}));

export const StyledTermsBox = styled(Box)(() => ({
	height: 18,
	maxHeight: 18,
}));

export const FormBodyHeader = styled(Box)(() => ({
	maxHeight: 100,
	display: 'flex',
	flexGrow: 1,
}));
export const StyledFormText = styled(Box)(({ theme }) => ({
	fontSize: 28,
	lineHeight: '36px',
	color: theme.palette.neutral.dark,
	fontFamily: 'Rubik',
	fontWeight: 700,
}));

export const StyledFormSubText = styled(Box)(({ theme }) => ({
	fontSize: 16,
	lineHeight: '20px',
	color: theme.palette.neutral.dark1,
	fontFamily: 'Rubik',
	fontWeight: 400,
}));
