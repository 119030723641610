import { Box, styled as MUIStyled } from '@mui/material';
import { ReactComponent as SecondGridImg } from '../../../assets/images/secondGridImg.svg';
import { ReactComponent as SecondGridImgCellphone } from '../../../assets/images/secondGridImgCellphone.svg';

export const StyledContainer = MUIStyled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
	height: 'inherit',
	alignItems: 'center',
	'@media (orientation: portrait)': {
		display: 'block',
		zIndex: 1,
	},
}));

export const StyledTextContainer = MUIStyled(Box)(() => ({
	display: 'block',
	maxWidth: '35%',
	'@media only screen and (max-width: 1400px)': {
		maxWidth: '35%',
	},
	'@media (orientation: portrait)': {
		maxWidth: '92%',
		margin: '0 auto',
	},
}));
export const StyledTitleText = MUIStyled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	width: '80%',
	fontWeight: 800,
	fontSize: 40,
	lineHeight: '48px',
	color: theme.palette.neutral.dark,
	marginBottom: 20,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 36,
		lineHeight: '44px',
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 26,
		lineHeight: '36px',
	},
	'@media (orientation: portrait)': {
		textAlign: 'center',
		fontSize: 24,
		lineHeight: '28px',
		margin: '0 auto 20px auto',
	},
}));
export const StyledText = MUIStyled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 600,
	fontSize: 20,
	lineHeight: '24px',
	color: theme.palette.neutral.dark1,
	width: '70%',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 16,
		lineHeight: '20px',
	},
	'@media (orientation: portrait)': {
		textAlign: 'center',
		width: '82%',
		margin: '0 auto',
		fontSize: 14,
		lineHeight: '16px',
	},
}));

export const StyledSecondGridImg = MUIStyled(SecondGridImg)(() => ({
	'@media only screen and (max-width: 1400px)': {
		width: 600,
		marginRight: 50,
	},
	'@media (orientation: portrait)': {
		display: 'none',
	},
}));

export const StyledSecondGridImgCellphone = MUIStyled(SecondGridImgCellphone)(
	() => ({
		display: 'none',

		'@media (orientation: portrait)': {
			display: 'flex',
		},
	})
);

export const SvgContainer = MUIStyled(Box)(() => ({
	display: 'flex',
	marginRight: 100,
	'& img': {
		display: 'none',
	},
	'@media only screen and (max-width: 1400px)': {
		width: 600,
	},
	'@media only screen and (max-width: 1200px)': {
		width: 500,
	},
	'@media (orientation: portrait)': {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		marginBottom: 50,
		'& img': {
			display: 'flex',
			width: 302,
			height: 214,
			objectFit: 'fill',
		},
	},
}));
