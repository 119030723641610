import { Box, Dialog, IconButton, styled } from "@mui/material";

export const StyledPromotionListContainer = styled(Box)(() => ({
  display: "flex",
  height: 30,
  backgroundColor: "yellow",
  width: "100%",
  zIndex: 0,
}));

export const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    display: "block",
    borderRadius: 20,
    width: "1200px",
    maxHeight: "100%",
    zIndex: 0,
    "@media only screen and (max-width: 1400px)": {
      width: "800px",
      height: "500px",
    },
    "& .MuiDialogContent-root": {
      display: "flex",
    },

    "& .MuiDialogActions-root": {
      display: "flex",
    },
  },
}));

export const StyledModalTitle = styled(Box)(() => ({
  display: "flex",
  fontFamily: "Rubik",
  fontWeight: 500,
  fontSize: 20,
  lineHeight: "24px",
  alignSelf: "start",
  marginBottom: 32,
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 8,
  top: 8,
  color: theme.palette.neutral.light,
  width: 32,
  height: 32,
  backgroundColor: theme.palette.primary.main,
}));

export const StyledPromotionRedeemedCoupons = styled(Box)(({ theme }) => ({
  display: "flex",
  width: 380,
  height: 48,
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.success.main,
  position: "absolute",
  top: 0,
  left: 0,
  color: theme.palette.neutral.light,
  fontFamily: "Rubik",
  fontWeight: 500,
  fontSize: 18,
  lineHeight: "24px",
  borderRadius: "20px 0px",
  boxShadow: "10px 5px 5px rgba(0, 0, 0, 0.1)",
}));

export const StyledPromotionImg = styled(Box)(() => ({
  display: "flex",
  height: 225,
  width: "100%",
  zIndex: 0,
  "& img": {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    zIndex: -1,
  },
  "@media only screen and (max-width: 1400px)": {
    height: 150,
  },
}));

export const StyledPromotionContainer = styled(Box)(({ theme }) => ({
  display: "block",
  boxSizing: "border-box",
  backgroundColor: theme.palette.neutral.light,
  borderRadius: "30px 30px 0px 0px",
  height: "calc(100% - 160px)",
  marginTop: -65,
  zIndex: 2,
  padding: "30px 30px 34px 30px",
  "@media only screen and (max-width: 1400px)": {
    marginTop: -65,
  },
}));

export const StyledPromotionFirstRowContainer = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
}));
export const StyledPromotionSecondRowContainer = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  marginTop: 36,
  "@media only screen and (max-width: 1400px)": {
    marginTop: 26,
  },
}));

export const StyledPromotionTitleContainer = styled(Box)(() => ({
  display: "block",
}));

export const StyledPromotionTitle = styled(Box)(({ theme }) => ({
  color: theme.palette.neutral.dark,
  fontFamily: "Rubik",
  fontWeight: 500,
  fontSize: 24,
  lineHeight: "28px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "560px",
  "@media only screen and (max-width: 1400px)": {
    fontSize: 20,
    lineHeight: "24px",
  },
}));

export const StyledPromotionDates = styled(Box)(({ theme }) => ({
  color: theme.palette.neutral.dark1,
  fontFamily: "Rubik",
  fontWeight: 400,
  fontSize: 16,
  lineHeight: "20px",
  "@media only screen and (max-width: 1400px)": {
    fontSize: 9,
    lineHeight: "20px",
  },
}));

export const StyledPromotionCouponsContainer = styled(Box)(() => ({
  display: "flex",
  gap: 18,
  marginTop: -16,
}));

export const StyledRamsomedCouponContainer = styled(Box)(() => ({
  display: "flex",
  height: 316,
  width: 516,
  filter: "drop-shadow(4px 6px 15px rgba(0, 0, 0, 0.1))",
  "@media only screen and (max-width: 1400px)": {
    height: 250,
    width: 350,
  },
}));

export const StyledRamsomedCouponChartContainer = styled(Box)(() => ({
  display: "flex",
  height: 316,
  width: 516,
  filter: "drop-shadow(4px 6px 15px rgba(0, 0, 0, 0.1))",
  "& canvas": {
    height: "220px!important",
    width: "100%!important",
  },
  "@media only screen and (max-width: 1400px)": {
    height: 250,
    width: 350,
    "& canvas": {
      height: "160px!important",
      width: "100%!important",
    },
  },
}));
