import { Box, styled } from '@mui/material';
import { ReactComponent as AppleStore } from '../../../assets/images/appleStore2.svg';
import { ReactComponent as GooglePlay } from '../../../assets/images/googlePlay.svg';
import { ReactComponent as PhoneElipse } from '../../../assets/images/phoneElipse.svg';
import { ReactComponent as Cellphone3 } from '../../../assets/images/phoneImg2.svg';
import { ReactComponent as CellphoneMobile } from '../../../assets/images/phoneImgMobile.svg';

export const StyledContainer = styled(Box)(() => ({
	display: 'grid',
	gridTemplateColumns: '2fr 2.5fr',
	'@media (orientation: portrait)': {
		display: 'inline-flex',
		flexDirection: 'column',
		gridTemplateColumns: '1fr 2fr',
		width: '100%',
	},
}));
export const FourthGridFirstColumn = styled(Box)(() => ({
	display: 'flex',
	gridColumn: '1 / 1',
	maxHeight: 714,
	'@media (orientation: portrait)': {
		maxHeight: 'unset',
		justifyContent: 'center',
		marginBottom: '50px',
	},
}));

export const FourthGridTitle = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 700,
	fontSize: 36,
	lineHeight: '48px',
	color: theme.palette.neutral.dark,
	width: '45%',
	marginTop: 100,
	marginBottom: 20,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 32,
	},
	'@media (orientation: portrait)': {
		fontSize: 22,
		lineHeight: '28px',
		textAlign: 'center',
		width: '75%',
		margin: '10px auto',
		maxWidth: '262px',
	},
}));

export const FourthGridText = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 400,
	fontSize: 18,
	color: theme.palette.neutral.dark1,
	width: '42%',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 22,
	},
	'@media (orientation: portrait)': {
		fontSize: 14,
		lineHeight: '20px',
		textAlign: 'center',
		width: '60%',
		margin: '20px auto',
		maxWidth: '288px',
	},
}));

export const FourthGridStores = styled(Box)(() => ({
	marginTop: 36,
	display: 'flex',
	'@media (orientation: portrait)': {
		justifyContent: 'center',
		marginTop: 63,
	},
}));

export const StyledAppleImg = styled(Box)(() => ({
	cursor: 'pointer',
	marginRight: 32,
	'@media (orientation: portrait)': {
		marginRight: 22,
	},
}));

export const StyledGoogleImg = styled(Box)(() => ({
	cursor: 'pointer',
}));

export const FourthGridSecondColumn = styled(Box)(() => ({
	gridColumn: '2 / 2',
	justifyContent: 'center',
	display: 'block',
	alignItems: 'center',
	padding: '145px 83px 162px 156px',
	maxHeight: 714,
	'@media only screen and (max-width: 1400px)': {
		padding: '145px 50px 162px 50px',
	},
	'@media (orientation: portrait)': {
		padding: 0,
		marginBottom: 80,
	},
}));

export const StyledCellphoneImg = styled(Box)(() => ({
	marginTop: 138,
	right: 0,
	zIndex: 2,
	marginLeft: 250,
	'@media only screen and (max-width: 1200px)': {
		marginLeft: 100,
	},
	'@media (orientation: portrait)': {
		marginLeft: 0,
	},
}));

export const CellphoneSVG = styled(Cellphone3)(() => ({
	zIndex: 1,
	'@media only screen and (max-width: 1400px)': {
		width: 440,
		marginTop: -116,
	},
	'@media only screen and (max-width: 1200px)': {
		width: 400,
		marginTop: -100,
	},
	'@media (orientation: portrait)': {
		display: 'none',
	},
}));
export const StyledCellphoneMobile = styled(CellphoneMobile)(() => ({
	zIndex: 1,
	display: 'none',
	'@media (orientation: portrait)': {
		display: 'flex',
		width: 350,
		margin: '0px auto',
	},
}));

export const StyledLogoApple = styled(AppleStore)(() => ({
	'@media (orientation: portrait)': {
		width: 150,
	},
}));
export const StyledLogoGoogle = styled(GooglePlay)(() => ({
	'@media (orientation: portrait)': {
		width: 150,
	},
}));

export const StyledPhoneElipseContainer = styled(Box)(() => ({
	position: 'absolute',
	right: '0',
}));

export const StyledPhoneElipse = styled(PhoneElipse)(() => ({
	position: 'absolute',
	right: '0',
	bottom: -750,
	'@media (orientation: portrait)': {
		display: 'none',
	},
}));
