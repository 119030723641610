import { Form, Formik } from 'formik';
import DashboardTopBar from '../../../components/DashboardTopBar/DashboardTopBar';
import VALIDATION_CREATE_CATEGORY_SCHEMA from '../constants/create-categorySchema';
import { ICategoryProps } from '../interfaces';

import {
  Container,
  WrapperContainer,
  SubTitle,
  CategoryName,
  Buttons,
  CancelButton,
  Texts,
  CreateButton,
  StyledMenuTopBar,
  StyledMenuContent,
  StyledMenuContainer,
} from './styles';

const NewCategoryFirstStep: React.FC<ICategoryProps> = ({
  hadleNavigateToMenu,
  handleCreateNewCategory,
}) => {
  return (
    <Container>
      <StyledMenuContainer>
        <StyledMenuTopBar>
          <DashboardTopBar
            title={'Nova categoria'}
            text={'Crie uma nova categoria'}
          />
        </StyledMenuTopBar>
        <StyledMenuContent>
          <WrapperContainer>
            <SubTitle>Preencha o nome da nova categoria</SubTitle>
            <Formik
              initialValues={{ name: '' }}
              validationSchema={VALIDATION_CREATE_CATEGORY_SCHEMA}
              onSubmit={(values, { setSubmitting }) => {
                handleCreateNewCategory?.(values, setSubmitting);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values: { name },
                touched,
                errors,
              }) => (
                <Form>
                  <CategoryName
                    label="Nome"
                    value={name}
                    inputProps={{ maxLength: 40 }}
                    helperText={touched.name && errors.name}
                    onChange={handleChange('name')}
                    error={touched.name && Boolean(errors.name)}
                  />
                  <Buttons>
                    <CancelButton onClick={hadleNavigateToMenu}>
                      <Texts propName={'Cancelar'}>Cancelar</Texts>
                    </CancelButton>
                    <CreateButton onClick={() => handleSubmit()}>
                      <Texts propName={'Criar'}>Criar categoria</Texts>
                    </CreateButton>
                  </Buttons>
                </Form>
              )}
            </Formik>
          </WrapperContainer>
        </StyledMenuContent>
      </StyledMenuContainer>
    </Container>
  );
};

export default NewCategoryFirstStep;
