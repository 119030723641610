import { Box } from '@mui/material';
import Modal from '@mui/material/Modal';
import { ReactComponent as CheckCircle } from '../../../assets/images/CheckCircle.svg';
import { ReactComponent as XIcon } from '../../../assets/images/XIcon.svg';
import { IConcludeModal } from '../interfaces';
import {
	AlignButton,
	AlignCheckIcon,
	AlignConcludeButton,
	AlignText,
	ButtonClose,
	ConcludeButton,
	ConcludeText,
	ContainerModal,
	SubtitleComplete,
	TextComplete,
} from './styles';

const ConcludeModal: React.FC<IConcludeModal> = ({
	open,
	handleClose,
	navigateToPlans,
}: IConcludeModal): JSX.Element => (
	<Modal open={open} onClose={handleClose}>
		<ContainerModal>
			<AlignButton>
				<ButtonClose onClick={handleClose}>
					<XIcon color="#747476" />
				</ButtonClose>
			</AlignButton>
			<AlignCheckIcon>
				<CheckCircle />
			</AlignCheckIcon>
			<AlignText>
				<Box>
					<TextComplete>Assinatura concluída com sucesso</TextComplete>
					<SubtitleComplete>
						Sua loja agora é Gold! Você já pode começar a criar promoções
					</SubtitleComplete>
				</Box>
			</AlignText>
			<AlignConcludeButton>
				<ConcludeButton onClick={navigateToPlans}>
					<ConcludeText>Concluir</ConcludeText>
				</ConcludeButton>
			</AlignConcludeButton>
		</ContainerModal>
	</Modal>
);

export default ConcludeModal;
