import { Box, styled, TextField, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

interface StyledTabProps {
	label: string;
}

export const StyledPromotion = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
	height: '100%',
}));

export const StyledPromotionContainer = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.neutral.light2,
	width: '100%',
	height: '100%',
}));

export const StyledPromotionTopBar = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
}));

export const StyledPromotionContent = styled(Box)(({ theme }) => ({
	height: 'calc(100% - 188px)',
	padding: '29px 44px 36px 44px',
	backgroundColor: theme.palette.neutral.light2,
	'@media only screen and (max-width: 1600px)': {
		height: 'unset',
	},
	'@media (max-width: 1200px)': {
		padding: '24px 20px 32px 24px',
	},
}));

export const StyledTabsContainer = styled(Box)(() => ({
	display: 'flex',
	height: 52,
	'@media (max-width: 1200px)': {
		height: 42,
	},
}));

export const PromotionTabs = styled(Tabs)({
	display: 'flex',
});

export const PromotionTab = styled((props: StyledTabProps) => (
	<Tab disableRipple {...props} />
))(({ theme }) => ({
	textTransform: 'none',
	fontSize: 20,
	fontWeight: 500,
	marginRight: 32,
	height: 52,
	color: theme.palette.neutral.dark1,
	padding: '12px 48px',
	borderRadius: '20px 20px 0 0',
	fontFamily: 'Rubik',
	'&.Mui-selected': {
		backgroundColor: theme.palette.neutral.light,
	},
	'@media (max-width: 1200px)': {
		height: 42,
		fontSize: 14,
		padding: '12px 24px',
		marginRight: 22,
	},
}));

export const StyledCreatePromotion = styled(Box)(({ theme }) => ({
	display: 'flex',
	backgroundColor: theme.palette.neutral.light,
	padding: '36px 44px 25px 44px',
	borderRadius: '0px 20px 20px 20px',
	'@media (max-width: 1200px)': {
		padding: '26px 20px 26px 20px',
	},
}));

export const StyledUploadImageContainer = styled(Box)(() => ({
	display: 'block',
	width: '20%',
}));
export const SyledUploadImage = styled(Box)(({ theme }) => ({
	height: 396,
	border: '1px dashed',
	borderColor: theme.palette.neutral.dark2,
	borderRadius: 8,
	boxSizing: 'border-box',
}));
export const StyledImageTitle = styled(Typography)(({ theme }) => ({
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.neutral.dark,
	marginTop: 15,
}));
export const StyledImageText = styled(Box)(({ theme }) => ({
	fontSize: 12,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
	textAlign: 'justify',
	marginTop: 15,
}));

export const StyledPromotionFormContainer = styled(Box)(() => ({
	height: '100%',
	marginLeft: 56,
}));

export const StyledPromotionFormText = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 18,
	color: theme.palette.neutral.dark,
	marginBottom: 4,
}));

export const StyledPromotionStatusTopContainer = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
}));

export const StyledPromotionStatusTabsContainer = styled(Box)(() => ({
	display: 'flex',
	height: 52,
	'@media (max-width: 1200px)': {
		height: 48,
	},
}));

export const PromotionStatusTabs = styled(Tabs)({
	display: 'flex',
});

export const PromotionStatusTab = styled((props: StyledTabProps) => (
	<Tab disableRipple {...props} />
))(({ theme }) => ({
	textTransform: 'none',
	fontSize: 18,
	fontWeight: 500,
	marginRight: 12,
	color: theme.palette.primary.main,
	borderRadius: 8,
	fontFamily: 'Rubik',
	border: '1px solid',
	borderColor: theme.palette.neutral.dark2,
	'&.Mui-selected': {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.neutral.light,
	},
	'& .MuiTabs-indicator': {
		display: 'none',
	},
	'@media (max-width: 1200px)': {
		fontSize: 14,
		padding: '8px 14px',
	},
}));

export const StyledSearchPromotion = styled(Box)(() => ({}));

export const StyledSearchTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 48,
		fontFamily: 'Rubik',
		fontWeight: 500,
		fontSize: 18,

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},

	'@media (max-width: 1200px)': {
		'& .MuiOutlinedInput-root ': {
			fontSize: 14,
		},
	},
}));

export const StyledListPromotionContainer = styled(Box)(({ theme }) => ({
	display: 'block',
	backgroundColor: theme.palette.neutral.light,
	padding: '36px 47px 36px 47px',
	borderRadius: '0px 20px 20px 20px',
	'@media (max-width: 1200px)': {
		padding: '26px 20px 26px 20px',
	},
}));

export const StyledAllPromotionsContainer = styled(Box)(() => ({}));

export const StylePromotionListText = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontSize: 24,
	fontWeight: 500,
	color: theme.palette.neutral.dark,
	marginTop: 30,
	marginBottom: 30,
}));

export const StyledPromotionList = styled(Box)(() => ({
	display: 'inline-flex',
	flexWrap: 'wrap',
	minHeight: 422,
	gap: 16,
	'@media only screen and (max-width: 1200px)': {
		minHeight: 330,
	},
}));

export const StyledEmptyList = styled(Box)(() => ({
	minHeight: 422,
	'@media only screen and (max-width: 1200px)': {
		minHeight: 330,
	},
}));

export const StyledPagination = styled(Box)(() => ({
	display: 'flex',
	marginTop: 42,
}));
