import { Skeleton } from "@mui/material";
import React from "react";

import {
  StyledCouponsContainer,
  StyledCouponsNumber,
  StyledCouponsPercent,
  StyledCouponsStatusDisplay,
  StyledCouponsText,
} from "./styles";

interface CouponsStatusCardProps {
  value: number | undefined;
  text: string;
  color: string;
  percent: string | undefined;
}

const CouponsPromotionModalStatusCard: React.FC<CouponsStatusCardProps> = ({
  value,
  text,
  color,
  percent,
}: CouponsStatusCardProps): JSX.Element => (
  <StyledCouponsStatusDisplay bottomColor={color}>
    <StyledCouponsContainer>
      {value !== undefined ? (
        <>
          <StyledCouponsNumber>{value}</StyledCouponsNumber>
          <StyledCouponsPercent>{percent}</StyledCouponsPercent>
        </>
      ) : (
        <>
          <StyledCouponsNumber>
            <Skeleton
              sx={{
                height: 18,
                width: 50,
                borderRadius: 5,
                marginTop: 0.5,
              }}
              animation="wave"
              variant="rectangular"
            />
          </StyledCouponsNumber>
          <StyledCouponsPercent>
            <Skeleton
              sx={{
                height: "100%",
                width: "100%",
                borderRadius: 12,
              }}
              animation="wave"
              variant="rectangular"
            />
          </StyledCouponsPercent>
        </>
      )}
    </StyledCouponsContainer>
    <StyledCouponsText>{text}</StyledCouponsText>
  </StyledCouponsStatusDisplay>
);

export default CouponsPromotionModalStatusCard;
