interface IAdvertiserInfo {
	advertiserInfos: AdvertiserInfos;
	advertiserAddress: AdvertiserAddress;
	advertiserContacts: AdvertiserContacts;
}

interface AdvertiserInfos {
	advertiserName: string;
	advertiserCategoryId: string;
	advertiserDescription: string;
}

interface AdvertiserAddress {
	addressId: string;
	cep: string;
	state: string;
	city: string;
	address: string;
	number: string;
	district: string;
	complement: string;
}

interface AdvertiserContacts {
	phone: string;
	cellphone: string;
	socialMedias: SocialMedia[];
}

interface SocialMedia {
	social_media: string;
	link: string;
	is_active: boolean;
}

export const INITIAL_VALUES_PROFILE_INFO_FORM: IAdvertiserInfo = {
	advertiserInfos: {
		advertiserName: '',
		advertiserDescription: '',
		advertiserCategoryId: '',
	},
	advertiserAddress: {
		addressId: '',
		cep: '',
		state: '',
		city: '',
		address: '',
		number: '',
		district: '',
		complement: '',
	},
	advertiserContacts: {
		phone: '',
		cellphone: '',
		socialMedias: [
			{
				social_media: '',
				link: '',
				is_active: true,
			},
		],
	},
};
