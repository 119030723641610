import { Button, styled, Typography } from "@mui/material";

export const StyledButton = styled(Button)(({ theme }) => ({
  height: 50,
  width: "100%",
  minWidth: "189.062px",
  borderRadius: 8,
  backgroundColor: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
  "@media only screen and (max-width: 1400px)": {
    height: 40,
    width: "100%",
    minWidth: "189.062px",
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  textTransform: "none",
  fontSize: 18,
  color: theme.palette.neutral.light,
  fontFamily: "Rubik",
  fontWeight: 700,
  lineHeight: "20px",
  "@media only screen and (max-width: 1400px)": {
    fontSize: 14,
  },
}));
