import { AxiosResponse } from "axios";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";

export const patchOptionGroupById = async (
  optionGroupId: string,
  available: boolean
): Promise<AxiosResponse<any> | undefined> => {
  try {
    return request({
      url: `${BASE_URL}/optionGroups/${optionGroupId}`,
      method: "patch",
      data: { available },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
