import { Box, Skeleton, styled, Typography } from '@mui/material';

interface StylePromotionList {
	fromCoupons: boolean | undefined;
}

export const StyledAllPromotionsContainer = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'fromCoupons',
})<StylePromotionList>(({ fromCoupons }) => ({
	maxHeight: fromCoupons ? 340 : 'none',
}));

export const StylePromotionListText = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'fromCoupons',
})<StylePromotionList>(({ theme, fromCoupons }) => ({
	fontFamily: 'Rubik',
	fontSize: 24,
	fontWeight: 600,
	color: theme.palette.neutral.dark,
	marginTop: 30,
	marginBottom: 30,
	display: fromCoupons ? 'none' : 'flex',
	minHeight: 28,
	'@media only screen and (max-width: 1200px)': {
		fontSize: 18,
		minHeight: 24,
	},
}));

export const StyledPromotionList = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'fromCoupons',
})<StylePromotionList>(({ fromCoupons }) => ({
	display: 'inline-flex',
	flexWrap: 'wrap',
	minHeight: fromCoupons ? 'none' : 422,
	gap: 16,
	'@media only screen and (max-width: 1200px)': {
		gap: 10,
	},
	'@media (max-width: 1200px)': {
		minHeight: fromCoupons ? 'none' : 330,
	},
}));

export const StyledEmptyList = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'fromCoupons',
})<StylePromotionList>(({ fromCoupons }) => ({
	boxSizing: 'border-box',
	minHeight: fromCoupons ? 0 : 422,
	paddingTop: fromCoupons ? 40 : 0,
	marginBottom: fromCoupons ? -50 : 0,
	'@media only screen and (max-width: 1200px)': {
		minHeight: fromCoupons ? 0 : 333,
	},
}));

export const StyledPagination = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'fromCoupons',
})<StylePromotionList>(({ fromCoupons }) => ({
	display: 'flex',
	marginTop: fromCoupons ? 105 : 42,
	minHeight: 42,
	'@media only screen and (max-width: 1500px)': {
		marginTop: fromCoupons ? 65 : 42,
	},
	'@media (max-width: 1200px)': {
		minHeight: 32,
	},
}));

export const StyledPromotion = styled(Box)(() => ({
	width: '220px',
	height: '202px',
	borderRadius: 20,
	overflow: 'hidden',
	boxShadow: '4px 6px 15px rgba(0, 0, 0, 0.1)',
	cursor: 'pointer',
	'@media only screen and (max-width: 1500px)': {
		width: '180px',
	},
	'@media only screen and (max-width: 1200px)': {
		width: '120px',
		height: '120px',
	},
}));
export const StyledPromotionImg = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
	height: 148,
	'& img': {
		height: '100%',
		width: '100%',
		objectFit: 'scale-down',
	},
}));
export const StyledPromotionTitle = styled(Typography)(({ theme }) => ({
	marginTop: 8,
	fontSize: 14,
	width: 'calc(100% - 12px)',
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.neutral.dark,
	marginLeft: 12,
	display: 'inline-block',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	'@media (max-width: 1500px)': {
		width: '195px',
	},
}));
export const StyledPromotionValue = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.success.main,
	marginLeft: 12,
}));

export const StyledBestPromotionsTitle = styled(Box)(({ theme }) => ({
	display: 'flex',
	height: 36,
	width: 'calc(100% - 64px)',
	borderBottom: '1px solid',
	borderColor: theme.palette.neutral.light2,
	padding: '0px 32px 0px 32px',
	justifyContent: 'space-between',
}));

export const StyledPromotionCardSkeleton = styled(Skeleton)(() => ({
	height: '100%',
	width: '100%',
	'@media only screen and (max-width: 1200px)': {
		height: 120,
		width: 120,
	},
}));
