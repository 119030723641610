import { IconButton } from '@mui/material';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as MagnifyingGlassIcon } from '../../assets/images/magnifyingGlass.svg';
import { IAuth } from '../../auth/interface/auth.interface';
import { IReducers } from '../../auth/store/store';
import CouponsStatusCard from '../../components/Coupons/CouponsStatusCard/CouponsStatusCard';
import DashboardTopBar from '../../components/DashboardTopBar/DashboardTopBar';
import PromotionList from '../../components/Promotion/PromotionList/PromotionList';
import { handleParams } from '../../shared/helpers/handleParams';
import { getAdvertiserPromotionByName } from '../../utils/api/get/getAdvertiserPromotionByName';
import { getCouponsByAdvertiser } from '../../utils/api/get/getCouponsByAdvertiser';
import { getPromotion } from '../../utils/api/get/getPromotion';
import { NewPromotionProps } from '../Dashboard/interface';
import { PromotionParams } from '../Promotion/interface/promotion-params.interface';
import {
	StyledSearchPromotion,
	StyledSearchTextField,
} from '../Promotion/styles';
import { ICouponsValue } from './interface/coupons-value.interface';
import {
	CouponIconSVG,
	CouponsPromotionStatusTab,
	CouponsPromotionStatusTabs,
	StyledCouponFirstStatusDisplay,
	StyledCouponFourthStatusDisplay,
	StyledCouponIconContainer,
	StyledCouponManager,
	StyledCouponManagerButton,
	StyledCouponManagerSubTitle,
	StyledCouponManagerTextContainer,
	StyledCouponManagerTitle,
	StyledCouponManagerTitleContainer,
	StyledCouponPromotionList,
	StyledCouponSecondStatusDisplay,
	StyledCouponThirdStatusDisplay,
	StyledCoupons,
	StyledCouponsContainer,
	StyledCouponsContent,
	StyledCouponsPromotionStatusTabsContainer,
	StyledCouponsTopBar,
} from './styles';

const Coupons: React.FC = (): JSX.Element => {
	const [couponsValue, setCouponsValue] = useState<ICouponsValue>(
		{} as ICouponsValue
	);
	const [selectedPromotionStatusTab, setSelectedPromotionStatusTab] =
		useState(0);
	const [promotion, setPromotion] = useState<NewPromotionProps[]>([]);
	const [promotionName, setPromotionName] = useState('');
	const [loaderTotal, setLoaderTotal] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [total, setTotal] = useState(0);
	const [promotionListLoader, setPromotionListLoader] = useState(true);
	const [params, setParams] = useState<PromotionParams>({
		page: currentPage,
		perPage: 6,
		has_coupons: true,
	} as PromotionParams);

	const { advertiser_id }: IAuth = useSelector(
		(state: IReducers) => state.auth
	);

	const navigate = useNavigate();

	const handlePromotionStatusTabChange = (
		event: React.SyntheticEvent,
		newValue: number
	): void => {
		handlePromotionStatusList(1, newValue);
	};

	const handleCurrentPageChange = (
		event: React.ChangeEvent<unknown>,
		value: number
	): void => handlePromotionStatusList(value, selectedPromotionStatusTab);

	const handleSearch = (): void => {
		setPromotionListLoader(true);
		getAdvertiserPromotionByName(advertiser_id, promotionName, params).then(
			(res) => {
				setPromotion(res?.data);
				setPromotionListLoader(false);
			}
		);
	};

	const handleSearchChange = (value: string): void => {
		setPromotionName(value);
		debounce(value);
	};

	const debounce = useCallback(
		_.debounce((promotionName: string): void => {
			setCurrentPage(1);
			if (promotionName) {
				setPromotionListLoader(true);
				getAdvertiserPromotionByName(advertiser_id, promotionName, {
					...params,
					page: 1,
				}).then((res) => {
					setPromotion(res?.data);
					setTotal(res?.total);
					setTotalPages(res?.last_page);
					setPromotionListLoader(false);
				});
			} else {
				getPromotionList({ ...params, page: 1 });
			}
		}, 500),
		[params, setParams]
	);

	const handlePromotionStatusList = (
		currentPage = 1,
		newValue: number
	): void => {
		setCurrentPage(currentPage);
		setSelectedPromotionStatusTab(newValue);

		const params: PromotionParams = {
			page: currentPage,
			perPage: 6,
			has_coupons: true,
		};
		if (promotionName) {
			params.term = promotionName;
		}

		setParams(handleParams(newValue, params));
	};

	const getPromotionList = (params: PromotionParams): void => {
		setPromotionListLoader(true);
		getPromotion(advertiser_id, params).then((res) => {
			setPromotion(res?.data);
			setTotal(res?.total);
			setTotalPages(res?.last_page);
			setPromotionListLoader(false);
			setLoaderTotal(false);
		});
	};

	useEffect(() => {
		let isMounted = true;

		getCouponsByAdvertiser().then((res) => {
			if (isMounted) {
				setCouponsValue(res.data);
			}
		});
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		let isMounted = true;

		if (isMounted) {
			getPromotionList(params);
		}
		return () => {
			isMounted = false;
		};
	}, [params, setParams]);

	return (
		<StyledCoupons>
			<StyledCouponsContainer>
				<StyledCouponsTopBar>
					<DashboardTopBar
						title={'Meus cupons'}
						text={'Gerencie os cupons das suas promoções'}
					/>
				</StyledCouponsTopBar>
				<StyledCouponsContent>
					<StyledCouponFirstStatusDisplay>
						<CouponsStatusCard
							text="Cupons disponíveis"
							value={couponsValue.available}
							tooltip="Esses são os cupons que você
              cadastrou e ainda estão disponíveis
              para os usuários resgatarem."
						/>
					</StyledCouponFirstStatusDisplay>
					<StyledCouponSecondStatusDisplay>
						<CouponsStatusCard
							text="Cupons resgatados"
							value={couponsValue.ransomed}
							tooltip="Esses são os cupons que já foram
              resgatados pelos usuários e estão
              aguardando para serem utilizados."
						/>
					</StyledCouponSecondStatusDisplay>
					<StyledCouponThirdStatusDisplay>
						<CouponsStatusCard
							text="Cupons utilizados"
							value={couponsValue.used}
							tooltip="Os cupons resgatados ficam no
              estado de utilizados após você fazer
              o registro no campo de inserir código."
						/>
					</StyledCouponThirdStatusDisplay>
					<StyledCouponFourthStatusDisplay>
						<CouponsStatusCard
							text="Cupons expirados"
							value={couponsValue.expired}
							tooltip="Esses são os cupons que expiraram por
              falta de uso ou por não terem sido
              resgatados."
						/>
					</StyledCouponFourthStatusDisplay>
					<StyledCouponManager>
						<StyledCouponManagerTitleContainer>
							<StyledCouponIconContainer>
								<CouponIconSVG />
							</StyledCouponIconContainer>
							<StyledCouponManagerTextContainer>
								<StyledCouponManagerTitle>
									Uso dos cupons
								</StyledCouponManagerTitle>
								<StyledCouponManagerSubTitle>
									Cupons resgatados e utilizados
								</StyledCouponManagerSubTitle>
							</StyledCouponManagerTextContainer>
						</StyledCouponManagerTitleContainer>
						<StyledCouponManagerButton
							onClick={() => navigate('/coupons/management')}
						>
							Gerenciar cupons
						</StyledCouponManagerButton>
					</StyledCouponManager>
					<StyledCouponPromotionList>
						<StyledCouponsPromotionStatusTabsContainer>
							<CouponsPromotionStatusTabs
								value={selectedPromotionStatusTab}
								onChange={handlePromotionStatusTabChange}
								TabIndicatorProps={{
									style: {
										display: 'none',
									},
								}}
							>
								<CouponsPromotionStatusTab label="Todas" />
								<CouponsPromotionStatusTab label="Ativas" />
								<CouponsPromotionStatusTab label="Desativadas" />
							</CouponsPromotionStatusTabs>
							<StyledSearchPromotion>
								<StyledSearchTextField
									InputLabelProps={{ shrink: false }}
									placeholder="Pesquisar promoção..."
									value={promotionName}
									onChange={(e) => handleSearchChange(e.target.value)}
									InputProps={{
										endAdornment: (
											<IconButton onClick={handleSearch}>
												<MagnifyingGlassIcon />
											</IconButton>
										),
									}}
								/>
							</StyledSearchPromotion>
						</StyledCouponsPromotionStatusTabsContainer>
						{selectedPromotionStatusTab === 0 && (
							<PromotionList
								total={total}
								promotion={promotion}
								totalPages={totalPages}
								currentPage={currentPage}
								handleCurrentPageChange={handleCurrentPageChange}
								loader={promotionListLoader}
								loaderTotal={promotionListLoader}
								fromCoupons={true}
							/>
						)}
						{selectedPromotionStatusTab === 1 && (
							<PromotionList
								total={total}
								promotion={promotion}
								totalPages={totalPages}
								currentPage={currentPage}
								handleCurrentPageChange={handleCurrentPageChange}
								loader={promotionListLoader}
								loaderTotal={loaderTotal}
								fromCoupons={true}
							/>
						)}
						{selectedPromotionStatusTab === 2 && (
							<PromotionList
								total={total}
								promotion={promotion}
								totalPages={totalPages}
								currentPage={currentPage}
								handleCurrentPageChange={handleCurrentPageChange}
								loader={promotionListLoader}
								loaderTotal={loaderTotal}
								fromCoupons={true}
							/>
						)}
					</StyledCouponPromotionList>
				</StyledCouponsContent>
			</StyledCouponsContainer>
		</StyledCoupons>
	);
};

export default Coupons;
