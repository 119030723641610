import { Box, Button, Dialog, IconButton, styled } from "@mui/material";

export const StyledPromotionListContainer = styled(Box)(() => ({
  display: "flex",
  height: 30,
  backgroundColor: "yellow",
  width: "100%",
}));

export const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
    padding: "48px",
    "@media only screen and (max-width: 1400px)": {
      width: 320,
      height: 500,
      padding: "0px 50px 0px 50px",
    },

    "& .MuiDialogContent-root": {
      display: "flex",
    },

    "& .MuiDialogActions-root": {
      display: "flex",
    },
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 8,
  top: 8,
  color: theme.palette.neutral.dark1,
}));

export const StyledSvgContainer = styled(Box)(() => ({
  display: "flex",
  marginBottom: 28,
}));

export const StyledModalTitle = styled(Box)(() => ({
  display: "flex",
  height: 48,
  fontFamily: "Rubik",
  fontWeight: 500,
  fontSize: 24,
  lineHeight: "28px",
  alignSelf: "center",
  alignItems: "center",
  "@media only screen and (max-width: 1400px)": {
    height: 28,
    fontSize: 18,
    lineHeight: "22px",
  },
}));

export const StyledModalText = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "60%",
  height: 48,
  fontFamily: "Rubik",
  fontWeight: 500,
  fontSize: 18,
  lineHeight: "24px",
  alignSelf: "center",
  alignItems: "center",
  color: theme.palette.neutral.dark1,
  textAlign: "center",
  "@media only screen and (max-width: 1400px)": {
    height: 28,
    fontSize: 14,
    lineHeight: "18px",
    width: "100%",
    marginTop: 5,
  },
}));

export const StyledButtonsContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginTop: 32,
}));

export const StyledDisableButton = styled(Button)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 700,
  borderRadius: 8,
  fontFamily: "Rubik",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.neutral.light,
  marginLeft: 20,
  marginRight: 32,
  lineHeight: "24px",
  padding: "16px 53px",
  cursor: "pointer",
  userSelect: "none",
  textTransform: "none",
}));
