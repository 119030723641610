import { AxiosResponse } from "axios";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";

export const patchCommentsById = async (
  commentIds: string[]
): Promise<AxiosResponse<any> | undefined> => {
  try {
    return request({
      url: `${BASE_URL}/comments/`,
      method: "patch",
      data: { commentIds },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
