import React from 'react';

import {
	CellphoneSVG,
	FourthGridFirstColumn,
	FourthGridSecondColumn,
	FourthGridStores,
	FourthGridText,
	FourthGridTitle,
	StyledAppleImg,
	StyledCellphoneImg,
	StyledCellphoneMobile,
	StyledContainer,
	StyledGoogleImg,
	StyledLogoApple,
	StyledLogoGoogle,
} from './styles';

const DownloadApp: React.FC = (): JSX.Element => (
	<StyledContainer>
		<FourthGridFirstColumn>
			<StyledCellphoneImg>
				<CellphoneSVG />
				<StyledCellphoneMobile />
			</StyledCellphoneImg>
		</FourthGridFirstColumn>
		<FourthGridSecondColumn>
			<FourthGridTitle>
				Baixe nosso aplicativo e economize nos seus pedidos!
			</FourthGridTitle>
			<FourthGridText>
				Encontre as melhores lojas e promoções próximas a você
			</FourthGridText>
			<FourthGridStores>
				<StyledAppleImg
					onClick={() =>
						window.open(
							'https://apps.apple.com/br/app/dsconto-desconto-e-cupons/id1643693437'
						)
					}
				>
					<StyledLogoApple />
				</StyledAppleImg>
				<StyledGoogleImg
					onClick={() =>
						window.open(
							'https://play.google.com/store/apps/details?id=com.dsconto'
						)
					}
				>
					<StyledLogoGoogle />
				</StyledGoogleImg>
			</FourthGridStores>
		</FourthGridSecondColumn>
	</StyledContainer>
);

export default DownloadApp;
