import { Box, Button, TextField, Typography, styled } from '@mui/material';

export const ContentBox = styled(Box)(() => ({
	display: 'flex',
}));

export const MiddleBox = styled(Box)(() => ({
	width: '100%',
}));

export const AlignInputs = styled(Box)(() => ({
	alignItems: 'center',
	display: 'flex',
	marginTop: '1.5%',
	width: '100%',
}));

export const ComplementName = styled(TextField)(() => ({
	width: '60%',
	'& .MuiOutlinedInput-root ': {
		borderTopLeftRadius: 8,
		borderBottomLeftRadius: 8,
		height: 60,
		fontFamily: 'Rubik',
		fontWeight: 400,

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'@media only screen and (max-width: 1400px)': {
		'& .MuiFormLabel-root': {
			fontSize: '13px',
		},
		'.css-2v8jo8-MuiInputBase-root-MuiOutlinedInput-root': {
			borderRadius: '0px',
		},
	},
}));

export const SwitchButton = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: ' center',
	justifyContent: 'space-between',
	width: 164,
	height: 58,
	borderTopRightRadius: 8,
	borderBottomRightRadius: 8,
	border: `1px solid ${theme.palette.neutral.dark2}`,
	padding: '0px 20px',
}));

export const SwitchText = styled(Typography)(({ theme }) => ({
	fontSize: '16px',
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
}));

export const DeleteButton = styled(Button)(() => ({
	marginTop: '20px',
}));

export const InuputDescription = styled(TextField)(() => ({
	marginTop: '2%',
	width: '100%',

	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 60,
		fontFamily: 'Rubik',
		fontWeight: 400,
		alignItems: 'flex-start',
		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'@media only screen and (max-width: 1400px)': {
		'& .MuiFormLabel-root': {
			fontSize: '13px',
		},
	},
}));

export const SinglePrice = styled(TextField)(() => ({
	width: '10%',
	marginLeft: '1.5%',
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 58,
		fontFamily: 'Rubik',
		fontWeight: 400,
		fontSize: '14px',

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},

	'@media only screen and (max-width: 1400px)': {
		'& .MuiFormLabel-root': {
			fontSize: '13px',
		},
	},
}));

export const WrapperButton = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'flex-end',
	width: '97.5%',
	padding: '0px 20px',
	marginTop: '2%',
}));

export const CreateButton = styled(Button)(({ theme }) => ({
	border: `1px solid ${theme.palette.primary.main}`,
	textTransform: 'initial',
}));

export const ButtonText = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.primary.main,
}));
