import { Collapse, Table, TableBody, TableCell } from '@mui/material';
import React from 'react';
import {
	StyledFirstCell,
	StyledSecondCell,
	StyledThirdCell,
	TableRowStyled,
} from '../styles';
import Item from './Item/Item';
import { IMenuListItems } from '../interfaces';

const ItemsList: React.FC<IMenuListItems> = ({
	items,
	open,
	idMenu,
	indexMenu,
	handleItemPatch,
	handleItemDelete,
	handleEditItemOption,
	itemImageModalIsVisible,
	handleOpenItemImageModal,
	deleteItemModalIsVisible,
	handleOpenDeleteItemModal,
	handleCloseItemImageModal,
	handleCloseDeleteItemModal,
}: IMenuListItems): JSX.Element => {
	return (
		<TableCell style={{ padding: 0, width: '100%' }} colSpan={6}>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<Table
					aria-label="item table"
					key={indexMenu}
					style={{ paddingLeft: 25 }}
				>
					{items?.length ? (
						<TableBody>
							<TableRowStyled>
								<StyledFirstCell>Item</StyledFirstCell>
								<StyledSecondCell>Preço</StyledSecondCell>
								<StyledThirdCell>Status de venda</StyledThirdCell>
								<StyledThirdCell></StyledThirdCell>
							</TableRowStyled>

							{items.map((item) => (
								<Item
									item={item}
									idMenu={idMenu}
									key={item.id}
									handleItemPatch={handleItemPatch}
									handleItemDelete={handleItemDelete}
									handleEditItemOption={handleEditItemOption}
									itemImageModalIsVisible={itemImageModalIsVisible}
									handleOpenItemImageModal={handleOpenItemImageModal}
									deleteItemModalIsVisible={deleteItemModalIsVisible}
									handleCloseItemImageModal={handleCloseItemImageModal}
									handleOpenDeleteItemModal={handleOpenDeleteItemModal}
									handleCloseDeleteItemModal={handleCloseDeleteItemModal}
								/>
							))}
						</TableBody>
					) : null}
				</Table>
			</Collapse>
		</TableCell>
	);
};

export default ItemsList;
