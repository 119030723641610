import { AxiosResponse } from "axios";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";
export const getRansomedCouponsByAdvertiser = async (
  advetiser_id: string
): Promise<AxiosResponse<any> | undefined> => {
  try {
    const response = await request({
      url: `${BASE_URL}/clients/stats/coupons/week/${advetiser_id}`,
      method: "get",
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
