import { Box, styled, Typography } from "@mui/material";

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: "block",
  backgroundColor: theme.palette.neutral.light,
  width: "100%",
  borderRadius: 20,
  padding: "28px 34px 28px 34px",
  "@media only screen and (max-width: 1400px)": {
    padding: "28px 10px 28px 10px",
  },
}));

export const StyledChartTitle = styled(Typography)(({ theme }) => ({
  marginBottom: 28,
  fontSize: 18,
  fontFamily: "Rubik",
  fontWeight: 500,
  display: "baseline",
  color: theme.palette.primary.dark,
}));
