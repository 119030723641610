import { AxiosResponse } from "axios";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";

export const patchMenuSequence = async (
  newMenuSequence: Record<
    string,
    {
      id: string;
      sequence: number;
    }[]
  >
): Promise<AxiosResponse<any>> => {
  try {
    return request({
      url: `${BASE_URL}/showcaseCategories/update/sequence`,
      method: "patch",
      data: newMenuSequence,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
