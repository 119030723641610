import React from "react";
import { IStatisticsCard } from "../interface/card-interface";
import {
  StyledNumber,
  StyledNumberContainer,
  StyledNumberSkeleton,
  StyledPeriod,
  StyledViewText,
} from "./styles";

const ViewCard: React.FC<IStatisticsCard> = ({
  itens,
  index,
  indexButton,
}: IStatisticsCard): JSX.Element => (
  <>
    {itens.loader ? (
      <StyledNumber isSelected={index === indexButton}>
        <StyledNumberSkeleton animation="wave" />
      </StyledNumber>
    ) : itens.views ? (
      <StyledNumberContainer>
        <StyledNumber isSelected={index === indexButton}>
          {itens.views}
        </StyledNumber>
        <StyledPeriod isSelected={index === indexButton}>
          Total de visualizações
        </StyledPeriod>
      </StyledNumberContainer>
    ) : (
      <StyledViewText isSelected={index === indexButton}>
        Sem estatísticas disponíveis
      </StyledViewText>
    )}
  </>
);

export default ViewCard;
