import React from 'react';
import { ReactComponent as BarsImg } from '../../../assets/images/barsImg.svg';
import { ReactComponent as HouseImg } from '../../../assets/images/houseImg.svg';
import { ReactComponent as MoneyImg } from '../../../assets/images/moneyImg.svg';
import { ReactComponent as SpeakerImg } from '../../../assets/images/speakerImg.svg';

import {
	StyeledCardsContainer,
	StyledCard,
	StyledCardImg,
	StyledCardText,
	StyledCardTitle,
	StyledContainer,
	StyledContainerTitle,
} from './styles';

const Benefits: React.FC = (): JSX.Element => (
	<StyledContainer>
		<StyledContainerTitle>
			Por que você não pode ficar de fora?
		</StyledContainerTitle>
		<StyeledCardsContainer>
			<StyledCard>
				<StyledCardImg>
					<SpeakerImg />
				</StyledCardImg>
				<StyledCardTitle>Promoções e cupons</StyledCardTitle>
				<StyledCardText>
					Crie e promova anúncios no aplicativo de forma simples
				</StyledCardText>
			</StyledCard>
			<StyledCard>
				<StyledCardImg>
					<BarsImg />
				</StyledCardImg>
				<StyledCardTitle>Dados estatísticos</StyledCardTitle>
				<StyledCardText>
					Tenha acesso à todos os dados de engajamento dos seus anúncios
				</StyledCardText>
			</StyledCard>
			<StyledCard>
				<StyledCardImg>
					<HouseImg />
				</StyledCardImg>
				<StyledCardTitle>Destaque sua loja</StyledCardTitle>
				<StyledCardText>
					Promova sua loja e ganhe mais visibilidade
				</StyledCardText>
			</StyledCard>
			<StyledCard>
				<StyledCardImg>
					<MoneyImg />
				</StyledCardImg>
				<StyledCardTitle>Venda mais</StyledCardTitle>
				<StyledCardText>
					Recebemos os pedidos e pagamentos e repassamos tudo para você
				</StyledCardText>
			</StyledCard>
		</StyeledCardsContainer>
	</StyledContainer>
);

export default Benefits;
