import { AxiosResponse } from "axios";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";

export const forgotPassword = async (
  email: string
): Promise<AxiosResponse<any>> => {
  try {
    const response = await request({
      url: `${BASE_URL}/password/forgot/email`,
      data: { email },
      method: "POST",
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
