import * as yup from "yup";

const SIGN_UP_FIELDS_VALIDATION_SCHEMA = yup.object({
  name: yup.string().required("Campo obrigatório."),
  cnpj: yup.string().required("Campo obrigatório."),
  cellphone: yup.string().required("Campo obrigatório."),
  email: yup
    .string()
    .email("Entre um e-mail válido")
    .required("Campo obrigatório."),
  password: yup
    .string()
    .min(7, "Senhas devem ter no minimo de 7 caracteres")
    .required("Campo obrigatório."),
  confirmPassword: yup
    .string()
    .min(7, "Senhas devem ter no minimo de 7 caracteres")
    .oneOf([yup.ref("password"), null], "As senhas devem ser iguais")
    .required("Campo obrigatório."),
  tandc: yup
    .boolean()
    .oneOf([true], "Aceite os termos de uso para se cadastrar."),
});

export default SIGN_UP_FIELDS_VALIDATION_SCHEMA;
