import { AxiosResponse } from "axios";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";

export const getCouponsByAdvertiser = async (): Promise<AxiosResponse<any>> => {
  try {
    const response = await request({
      url: `${BASE_URL}/coupons/status/client/`,
      method: "get",
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
