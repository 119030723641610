export const amountOfPeople = [
	{
		value: 0,
		label: 'Não se aplica',
	},
	{
		value: 1,
		label: '1 pessoa',
	},
	{
		value: 2,
		label: '2 pessoas',
	},
	{
		value: 3,
		label: '3 pessoas',
	},
	{
		value: 4,
		label: '4+ pessoas',
	},
];
