import CloseIcon from '@mui/icons-material/Close';
import { Box, TextField, Typography, styled } from '@mui/material';

interface DayOfWeekProp {
	isSelected: boolean;
	isChecked?: boolean;
}

export const Container = styled(Box)(({ theme }) => ({
	width: '100%',
	height: '100%',
	paddingBottom: '5%',
	overflow: 'hidden',
	backgroundColor: theme.palette.common.white,
}));

export const HeaderDetail = styled(Box)(() => ({
	padding: '10px 30px 20px 30px',
}));

export const BoxContent = styled(Box)(() => ({
	width: '100%',
}));

export const TitleBox = styled(Box)(() => ({
	display: ' flex',
}));

export const SubTitleBox = styled(Box)(() => ({
	display: ' flex',
	alignItems: ' center',
}));

export const SubText = styled(Box)(({ theme }) => ({
	fontSize: '16px',
	fontFamily: 'Rubik',
	fontWeight: 400,
	lineHeight: '20px',
	color: theme.palette.neutral.dark1,
}));

export const TitleText = styled(Typography)(({ theme }) => ({
	fontSize: 20,
	fontFamily: 'Rubik',
	fontWeight: 500,
	lineHeight: '24px',
	color: theme.palette.common.black,
}));

export const CloseBox = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'flex-end',
}));

export const ButtonClose = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: 30,
	height: 30,
	cursor: 'pointer',
}));

export const IconClose = styled(CloseIcon)(({ theme }) => ({
	color: theme.palette.neutral.dark1,
}));

export const HeaderBox = styled(Box)(() => ({
	display: 'flex',
	padding: '10px 30px 20px 30px',
	justifyContent: 'space-between',
}));

export const FilterText = styled(Box)(({ theme }) => ({
	fontSize: '16px',
	fontFamily: 'Rubik',
	fontWeight: 400,
	lineHeight: '20px',
	paddingTop: 4,
	color: theme.palette.neutral.dark1,
	textDecoration: 'underline',
	cursor: 'pointer',
}));

export const DateBox = styled(Box)(() => ({
	display: 'flex',
	padding: '10px 30px 20px 30px',
	justifyContent: 'space-evenly',
}));

export const StyledDataTextFieldStart = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 48,
		width: 180,
		fontFamily: 'Rubik',
		'@media only screen and (max-width: 1400px)': {
			height: 38,
			width: 150,
			fontSize: 13,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'@media only screen and (max-width: 1400px)': {
		'& .MuiFormLabel-root': {
			fontSize: '13px',
		},
		'& .MuiFormHelperText-root': {
			fontSize: '9px!important',
			whiteSpace: 'nowrap',
		},
	},
}));

export const PaymentsMethodsBox = styled(Box)(() => ({
	display: 'flex',
	padding: '10px 30px 20px 30px',
	justifyContent: 'space-evenly',
}));

export const OrderTypeBox = styled(Box)(() => ({
	display: 'flex',
	padding: '10px 30px 20px 30px',
}));

export const StyledBoxContainer = styled(Box)(() => ({
	display: 'flex',
}));

export const StyledPaymentMethods = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isChecked',
})<DayOfWeekProp>(({ theme, isSelected }) => ({
	display: 'flex',
	backgroundColor: isSelected
		? theme.palette.primary.main
		: theme.palette.neutral.light,
	borderRadius: 8,
	marginRight: 20,
	border: '1px solid',
	borderColor: theme.palette.primary.main,
	cursor: 'pointer',
}));

export const StyledPaymentMethodsText = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'isSelected',
})<DayOfWeekProp>(({ theme, isSelected }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '12px 32px 12px 32px',

	color: isSelected ? theme.palette.primary.main : theme.palette.neutral.light,
	fontFamily: 'Rubik',
	fontWeight: 500,
	userSelect: 'none',
	'@media only screen and (max-width: 1200px)': {
		fontSize: '14px',
		padding: 0,
	},
}));

export const StyledOrderType = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isChecked',
})<DayOfWeekProp>(({ theme, isSelected }) => ({
	display: 'block',
	backgroundColor: isSelected
		? theme.palette.primary.main
		: theme.palette.neutral.light,
	borderRadius: 8,
	marginRight: 20,
	padding: '15px 0px',
	cursor: 'pointer',
	width: 165,
}));

export const StyledOrderTypeTitle = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'isSelected',
})<DayOfWeekProp>(({ theme, isSelected }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	color: isSelected ? theme.palette.neutral.dark : theme.palette.neutral.light,
	fontFamily: 'Rubik',
	fontWeight: 500,
	userSelect: 'none',
	fontSize: '14px',
	marginTop: 10,
	marginBottom: 5,
}));

export const StyledOrderTypeText = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'isSelected',
})<DayOfWeekProp>(({ theme, isSelected }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontSize: '14px',
	color: isSelected
		? theme.palette.neutral.dark1
		: theme.palette.neutral.light2,
	fontFamily: 'Rubik',
	fontWeight: 400,
	userSelect: 'none',
	textAlign: 'center',
}));

export const StyledIconBox = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'isSelected',
})<DayOfWeekProp>(({ theme, isSelected }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	color: isSelected ? theme.palette.primary.main : theme.palette.neutral.light,
}));

export const StyledOrderStatusText = styled(Typography)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontSize: '14px',
	color: theme.palette.neutral.dark,

	fontFamily: 'Rubik',
	fontWeight: 400,
	userSelect: 'none',
	textAlign: 'center',
}));

export const OrderStatusBox = styled(Box)(() => ({
	display: 'block',
	padding: '10px 30px 20px 30px',
}));

export const StyledButtonContainer = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
}));
