import React from "react";
import {
  PlansSvg,
  StyledComingSoonIcons,
  StyledDescriptionPlan,
  StyledPlanSoon,
  StyledTitlePlan,
  StyledTopInfo,
} from "./styles";

const PlanCard: React.FC = (): JSX.Element => {
  return (
    <>
      <StyledTopInfo>
        <StyledPlanSoon>Em breve!</StyledPlanSoon>
      </StyledTopInfo>
      <StyledComingSoonIcons>
        <PlansSvg />
      </StyledComingSoonIcons>
      <StyledTitlePlan>Planos para sua empresa</StyledTitlePlan>
      <StyledDescriptionPlan>
        Novos planos para gerenciar o perfil da {"\n"}
        sua empresa e destacar sua loja na cidade.
      </StyledDescriptionPlan>
    </>
  );
};

export default PlanCard;
