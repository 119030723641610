import { Skeleton } from "@mui/material";
import { Chart as ChartJS, registerables } from "chart.js";
import React from "react";
import LineChart from "../../LineChart/LineChart";
import { StatsChart } from "./interface/chart-stats.interface";
import { StyledChartTitle, StyledContainer } from "./styles";
ChartJS.register(...registerables);

const CouponsChart: React.FC<StatsChart> = ({
  data,
  text = "Cupons Utilizados",
  loader,
}: StatsChart): JSX.Element => {
  const labels: string[] = [];

  return (
    <StyledContainer>
      {loader ? (
        <>
          <StyledChartTitle>
            <Skeleton
              sx={{
                height: 22,
                width: 230,
                borderRadius: 8,
                marginLeft: -1,
              }}
              animation="wave"
              variant="rectangular"
            />
          </StyledChartTitle>
          <Skeleton
            sx={{ height: "75%", borderRadius: 8 }}
            animation="wave"
            variant="rectangular"
          />
        </>
      ) : (
        <LineChart data={data} labels={labels} label={text} />
      )}
    </StyledContainer>
  );
};

export default CouponsChart;
