import React from "react";
import { ReactComponent as BigEmptyComments } from "../../assets/images/bigEmptyComments.svg";
import { ReactComponent as BigEmptyPromotion } from "../../assets/images/bigEmptyPromotion.svg";
import { ReactComponent as BigEmptyHistory } from "../../assets/images/emptyHistory.svg";
import { BigEmptyCardProps } from "./interface/bigEmptyCard.interface";
import { StyledCard, StyledEmptyTypography, StyledImage } from "./styles";

const BigEmptyCard: React.FC<BigEmptyCardProps> = ({
  text,
  image,
}: BigEmptyCardProps): JSX.Element => {
  const IMAGES_OPTIONS = {
    promotion: <BigEmptyPromotion />,
    comments: <BigEmptyComments />,
    history: <BigEmptyHistory />,
  };

  return (
    <StyledCard>
      <StyledImage>
        {IMAGES_OPTIONS[image as keyof typeof IMAGES_OPTIONS]}
      </StyledImage>
      <StyledEmptyTypography>{text}</StyledEmptyTypography>
    </StyledCard>
  );
};

export default BigEmptyCard;
