import { PromotionParams } from "../../../pages/Promotion/interface/promotion-params.interface";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";
import { IPromotionResponse } from "../interfaces";

export const getPromotion = async (
  advertiser_id: string,
  params: PromotionParams
): Promise<IPromotionResponse> => {
  try {
    const response = await request({
      url: `${BASE_URL}/promotions/client/${advertiser_id}`,
      method: "get",
      params: params,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
