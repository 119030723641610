import { IInitialValues } from '../interface/openingHoursInitialValue.interface';

export const INITIAL_VALUES_OPENING_HOURS_FORM: IInitialValues = {
	openingHours: [
		{
			day: 'Segunda',
			day_of_week: '0',
			opening_hour: '00:00',
			close_hour: '00:00',
			is_active: false,
			same_hours: true,
		},
		{
			day: 'Terça',
			day_of_week: '1',
			opening_hour: '00:00',
			close_hour: '00:00',
			is_active: false,
			same_hours: true,
		},
		{
			day: 'Quarta',
			day_of_week: '2',
			opening_hour: '00:00',
			close_hour: '00:00',
			is_active: false,
			same_hours: true,
		},
		{
			day: 'Quinta',
			day_of_week: '3',
			opening_hour: '00:00',
			close_hour: '00:00',
			is_active: false,
			same_hours: true,
		},
		{
			day: 'Sexta',
			day_of_week: '4',
			opening_hour: '00:00',
			close_hour: '00:00',
			is_active: false,
			same_hours: true,
		},

		{
			day: 'Sábado',
			day_of_week: '5',
			opening_hour: '00:00',
			close_hour: '00:00',
			is_active: false,
			same_hours: true,
		},

		{
			day: 'Domingo',
			day_of_week: '6',
			opening_hour: '00:00',
			close_hour: '00:00',
			is_active: false,
			same_hours: true,
		},
	],
	deliveryOptions: {
		deliveryAvailable: false,
		pickUpAvailable: false,
		pickUpTimeStart: 0,
		pickUpTimeEnd: 0,
	},
};
