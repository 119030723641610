import { CircularProgress } from '@mui/material';
import { useFormikContext } from 'formik';
import { SIZE_BUTTON } from '../ContinueLoginButton/contants/button-config.constants';
import { ButtonsInterface } from '../ContinueLoginButton/interface/button.interface';
import { StyledButton, StyledTypography } from '../SaveButton/styles';

const SaveButton: React.FC<ButtonsInterface> = ({
	children,
}: ButtonsInterface): JSX.Element => {
	const { isSubmitting } = useFormikContext();

	return (
		<StyledButton type="submit" variant="contained" disabled={isSubmitting}>
			{isSubmitting ? (
				<CircularProgress color="secondary" size={SIZE_BUTTON} />
			) : (
				<StyledTypography variant="body1">{children}</StyledTypography>
			)}
		</StyledButton>
	);
};

export default SaveButton;
