import { AxiosResponse } from 'axios';
import { BASE_URL } from '../api';
import { request } from '../base-request/base-request';

export const getMonthlyBilling = async (
	month: number,
	year: number
): Promise<AxiosResponse<any> | undefined> => {
	try {
		return request({
			url: `${BASE_URL}/advertisersWallet/monthlyBilling?year=${year}&month=${month}`,
			method: 'get',
		});
	} catch (error) {
		return Promise.reject(error);
	}
};
