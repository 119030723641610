import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ReactComponent as ModalPaper } from '../../../assets/images/modalPaper.svg';
import { ReactComponent as ModalPerson } from '../../../assets/images/modalPerson.svg';
import { addAuth } from '../../../auth/actions/auth.action';
import { addNav } from '../../../auth/actions/sideNav.action';
import { AuthAction, IAuth } from '../../../auth/interface/auth.interface';
import { ISideNav } from '../../../auth/interface/sideNav.interface';
import { IReducers } from '../../../auth/store/store';
import { ProfileAdvertiserResponseProps } from '../../../pages/Profile/interface/profileAdvertiser.interface';
import { getAdvertiser } from '../../../utils/api/get/getAdvertiser';
import { DashboardModalProps } from './interface/modalDisablePromotion.interface';
import {
	StyledButtonsContainer,
	StyledDialog,
	StyledDisableButton,
	StyledIconButton,
	StyledModalText,
	StyledModalTitle,
	StyledSvgContainer,
} from './styles';

const DashboardModal: React.FC<DashboardModalProps> = ({
	handleCloseDashboardModal,
	openDashboardModal,
}: DashboardModalProps): JSX.Element => {
	const [advertiser, setAdvertiser] =
		useState<ProfileAdvertiserResponseProps>();
	const dispatch = useDispatch();
	const { advertiser_id }: IAuth = useSelector(
		(state: IReducers) => state.auth
	);

	const auth: IAuth = useSelector((state: IReducers) => state.auth);

	const setAuth = (auth: IAuth): AuthAction => dispatch(addAuth(auth));

	const setIndexOfSideNavButton = (sideNav: ISideNav): void => {
		dispatch(addNav(sideNav));
	};
	const navigate = useNavigate();

	const handleNavigate = (): void => {
		if (!advertiser?.advertiserOpeningHours.length) {
			navigate('/profile', { state: { tab: 1 } });
		}
		if (!advertiser?.address) {
			navigate('/profile', { state: { tab: 0 } });
		}
		if (!advertiser?.description) {
			navigate('/profile', { state: { tab: 0 } });
		}
		if (!advertiser?.paymentMethods.length) {
			navigate('/profile', { state: { tab: 2 } });
		}

		setIndexOfSideNavButton({
			showSide: true,
			buttonIndex: 3,
		});
		handleCloseDashboardModal();
	};

	const getCurrentAdvertiser = (): void => {
		getAdvertiser(advertiser_id).then((res) => {
			setAdvertiser(res?.data);
			if (res) {
				const { description, address, advertiserOpeningHours, paymentMethods } =
					res.data;
				const validateAdvertiser =
					!description ||
					!address ||
					!advertiserOpeningHours.length ||
					!paymentMethods.length;

				setAuth({
					...auth,
					advertiserComplete: !validateAdvertiser,
				});
			}
		});
	};

	useEffect(() => {
		getCurrentAdvertiser();
	}, [openDashboardModal]);

	return (
		<StyledDialog
			open={openDashboardModal}
			onClose={handleCloseDashboardModal}
			fullWidth
			maxWidth="sm"
		>
			<StyledIconButton onClick={handleCloseDashboardModal}>
				<CloseIcon />
			</StyledIconButton>
			<StyledSvgContainer>
				<ModalPaper />
				<ModalPerson />
			</StyledSvgContainer>
			<StyledModalTitle>Conclua seu cadastro</StyledModalTitle>
			<StyledModalText>
				Antes de postar promoções ou produtos no cardápio você precisa finalizar
				o seu cadastro.
			</StyledModalText>
			<StyledButtonsContainer>
				<StyledDisableButton onClick={handleNavigate}>
					Continuar
				</StyledDisableButton>
			</StyledButtonsContainer>
		</StyledDialog>
	);
};

export default DashboardModal;
