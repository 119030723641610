import { Box, styled, Typography } from '@mui/material';

export const StyledFormContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	rowGap: 24,
	maxWidth: '70%',
	paddingTop: 24,
	backgroundColor: theme.palette.neutral.light,
	borderRadius: 40,
	padding: '69px 64px 63px 64px ',
	'@media only screen and (max-width: 1200px)': {
		padding: '49px 45px 43px 44px',
	},
	[theme.breakpoints.down('md')]: {
		maxWidth: '80%',
	},
}));
export const StyledFormText = styled(Box)(({ theme }) => ({
	fontSize: 32,
	color: theme.palette.primary.main,
	fontFamily: 'Rubik',
	fontWeight: 500,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 28,
	},
}));
export const StyledFormSubText = styled(Box)(({ theme }) => ({
	fontSize: 18,
	color: theme.palette.neutral.dark1,
	fontFamily: 'Rubik',
	fontWeight: 500,
}));

export const StyledFieldsTitle = styled(Typography)(() => ({
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 500,
}));

export const StyledFieldInput = styled(Box)(() => ({
	maxHeight: 56,
	width: '100%',
}));

export const StyledPasswordOptionsContainer = styled(Box)(() => ({
	display: 'grid',
	gridTemplateColumns: '1fr 1fr',
	width: '100%',
	alignItems: 'center',
}));

export const StyledFirstColumn = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
}));

export const StyledSecondColumn = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
}));

export const StyledTypography = styled(Typography)(() => ({
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 12,
}));

export const StyledTypographyLInk = styled(Typography)(({ theme }) => ({
	color: theme.palette.primary.main,
	borderBottom: `1px solid #412272`,
	cursor: 'pointer',
	transition: 'all 0.3s ease-out',
	fontFamily: 'Rubik',
	fontWeight: 400,
	fontSize: 14,
}));

export const StyledLoginButtonContainer = styled(Box)(() => ({
	width: '68%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	margin: 'auto',
	'@media only screen and (max-width: 1400px)': {
		width: '50%',
	},
}));

export const FormFooter = styled(Box)(() => ({
	display: 'block',
	justifyContent: 'center',
	alignItems: 'center',
	flexGrow: 1,
	maxHeight: 50,
}));

export const StyledFormCreateAccountText = styled(Box)(({ theme }) => ({
	display: 'flex',
	marginTop: '15%',
	fontSize: 16,
	lineHeight: '20px',
	color: theme.palette.neutral.dark,
	fontFamily: 'Rubik',
	fontWeight: 400,
	alignContent: 'center',
	justifyContent: 'center',
}));

export const StyledFormCreateAccountLink = styled(Typography)(({ theme }) => ({
	color: theme.palette.primary.main,
	borderBottom: `1px solid #412272`,
	cursor: 'pointer',
	transition: 'all 0.3s ease-out',
	fontFamily: 'Rubik',
	fontWeight: 400,
	fontSize: 16,
	lineHeight: '20px',
}));
