import { Box, Button, Table, TableCell, TableRow, styled } from '@mui/material';

export const StyledRow = styled(TableRow)(({ theme }) => ({
	border: 'none !important',
	backgroundColor: theme.palette.neutral.light1,
}));

export const StyledFirstCell = styled(TableCell)(() => ({
	width: '60%',
	borderBottom: 'none!important',
	fontWeight: 500,
}));

export const AlingFirstCell = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
	alignItems: 'center',
	paddingLeft: 25,
}));

export const StyledTitleCategory = styled(Box)(({ theme }) => ({
	display: 'flex',
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
}));

export const TitleAndCategory = styled(Box)(() => ({
	marginLeft: 10,
}));

export const StyledSecondCell = styled(TableCell)(() => ({
	width: '12%',
	borderBottom: 'none!important',
	textAlign: 'center',
	fontWeight: 500,
}));
export const StyledThirdCell = styled(TableCell)(() => ({
	minWidth: '18%',
	borderBottom: 'none!important',
	fontWeight: 500,
}));

export const StyledFourthCell = styled(TableCell)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	whiteSpace: 'nowrap',
	borderBottom: 'none',
	paddingRight: '24px',
	fontWeight: 500,
}));

export const StyledDraggabelCell = styled(TableCell)(() => ({
	marginLeft: -16,
	padding: 0,
	maxWidth: 16,
}));

export const StyledHeaderOptionsCell = styled(TableCell)(() => ({
	borderBottom: 'none',
}));

export const StyledOptionsTable = styled(Table)(() => ({
	'& .MuiTableRow-root': {
		borderBottom: 'none',
		'& .MuiTableCell-root': {
			borderBottom: 'none',
		},
	},
}));

export const AddItemButton = styled(Button)(() => ({
	width: 184,
	height: 52,
	borderRadius: 8,
}));

export const SwitchButton = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: ' center',
	justifyContent: 'space-between',
	width: 164,
	height: 52,
	borderRadius: 8,
	border: `1px solid ${theme.palette.neutral.light2}`,
	padding: '0px 20px',
}));

export const BorderlessButton = styled(Box)(() => ({
	display: 'flex',
	alignItems: ' center',
	justifyContent: 'space-between',
	width: 164,
	height: 52,
	borderRadius: 8,
	padding: '0px 20px',
}));

export const TableRowStyled = styled(TableRow)(() => ({
	borderBottom: '1px solid #EDE8F4',
}));
