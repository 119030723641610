import CloseIcon from "@mui/icons-material/Close";
import { DialogContent } from "@mui/material";
import React from "react";
import { ModalSucessProps } from "./interface/promotion-modal.interface";
import {
  StyleCouponCheckIcon,
  StyledButton,
  StyledDialog,
  StyledDialogActions,
  StyledErrorCheckIcon,
  StyledIconButton,
  StyledModalText,
  StyledModalTitle,
  StyledSucessCheckIcon,
  StyledTypography,
  StyledWarningCheckIcon,
} from "./styles";

const ModalTypeSVG = {
  sucess: <StyledSucessCheckIcon />,
  error: <StyledErrorCheckIcon />,
  warning: <StyledWarningCheckIcon />,
  coupon: <StyleCouponCheckIcon />,
};

const ModalSucess: React.FC<ModalSucessProps> = ({
  openPromotionSucessModal,
  handleClosePromotionModal,
  setSelectedTab,
  modalButtonText,
  modalText,
  modalTitle,
  modalType,
}: ModalSucessProps): JSX.Element => {
  const navigateToMyPromotion = (): void => {
    setSelectedTab ? setSelectedTab(0) : "";
    handleClosePromotionModal();
  };
  return (
    <StyledDialog
      open={openPromotionSucessModal}
      onClose={handleClosePromotionModal}
      maxWidth={"sm"}
    >
      <StyledIconButton onClick={handleClosePromotionModal}>
        <CloseIcon />
      </StyledIconButton>
      {ModalTypeSVG[modalType as keyof typeof ModalTypeSVG]}

      <StyledModalTitle>{modalTitle}</StyledModalTitle>
      <DialogContent>
        <StyledModalText>{modalText}</StyledModalText>
      </DialogContent>
      <StyledDialogActions>
        <StyledButton onClick={() => navigateToMyPromotion()}>
          <StyledTypography>{modalButtonText}</StyledTypography>
        </StyledButton>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default ModalSucess;
