import OrderStatusCard from '../OrderStatusCard/OrdersStatusCard';
import SkeletonCard from '../SekeletonCard/SkeletonCard';
import { IChartProps } from '../interfaces/interfaces';

const OrderValueChart: React.FC<IChartProps> = ({
	loading,
	stats,
	detailsData,
	handleFooterText,
	handleFoooterIcon,
}: IChartProps): JSX.Element => {
	return (
		<>
			{loading ? (
				<SkeletonCard />
			) : (
				stats && (
					<OrderStatusCard
						text="Pedidos totais"
						value={stats.totOrders}
						handleFoooterIcon={handleFoooterIcon(
							detailsData?.stats.totOrdersPercentage
						)}
						footerText={handleFooterText(
							detailsData?.stats.totOrdersPercentage
						)}
						descending={
							stats.totOrdersPercentage < 0 || stats.totOrdersPercentage === 0
						}
						money={false}
						tooltip="Essa é a quantidade total de pedidos nos últimos 30 dias."
					/>
				)
			)}
		</>
	);
};

export default OrderValueChart;
