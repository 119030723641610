import { Box, styled, TextField, Typography } from '@mui/material';

interface DayOfWeekProp {
	isSelected: boolean;
	isChecked?: boolean;
}

export const OpeningHoursFormContainer = styled(Box)(() => ({
	display: 'block',
	'@media only screen and (max-width: 1200px)': {
		minHeight: 362,
	},
}));

export const StyledFormText = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 20,
	color: theme.palette.neutral.dark,
	marginBottom: 4,
	'@media only screen and (max-width: 1200px)': {
		fontSize: 16,
	},
}));

export const StyledDayStatusContainer = styled(Box)(() => ({
	display: 'flex',
	maxHeight: 80,
	'@media only screen and (max-width: 1200px)': {
		maxHeight: 60,
	},
}));

export const StyledDayStatus = styled(Box)(({ theme }) => ({
	display: 'flex',
	height: 56,
	width: 222,
	color: theme.palette.neutral.dark1,
	borderRadius: 8,
	fontFamily: 'Rubik',
	fontWeight: 400,
	fontSize: 18,
	marginTop: 15,
	marginRight: 20,
	border: '1px solid',
	borderColor: theme.palette.neutral.dark2,
	justifyContent: 'center',
	alignItems: 'center',
	userSelect: 'none',
	'@media only screen and (max-width: 1200px)': {
		height: 40,
		width: 182,
		fontSize: 14,
		marginTop: 5,
	},
}));

export const StyledDaysContainer = styled(Box)(() => ({
	display: 'flex',
	marginBottom: 50,
}));

export const StyledDayListContainer = styled(Box)(() => ({
	display: 'flex',
}));

export const StyledHoursGridContainer = styled(Box)(() => ({
	display: 'grid',
	gridTemplateColumns: '1fr 1fr 1fr',
	gridTemplateRows: 'repeat(3, 80px)',
	gridAutoFlow: 'row dense',
	marginBottom: 50,
	'@media only screen and (max-width: 1200px)': {
		gridTemplateRows: 'repeat(4, 60px)',
	},
}));

export const StyledFieldsContainer = styled(Box)(() => ({
	display: 'flex',
	width: 300,
	'@media only screen and (max-width: 1200px)': {
		width: 227,
	},
}));

export const StyledDayOfWeek = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isChecked',
})<DayOfWeekProp>(({ theme, isSelected, isChecked }) => ({
	display: 'flex',
	justifyContent: 'center',
	height: 56,
	width: 150,
	backgroundColor: isSelected
		? theme.palette.primary.main
		: theme.palette.neutral.light,
	borderRadius: 12,
	marginTop: 16,
	marginRight: isChecked ? 8 : 20,
	border: '1px solid',
	borderColor: theme.palette.primary.main,
	cursor: 'pointer',
	'@media only screen and (max-width: 1200px)': {
		height: 36,
		width: 130,
		marginRight: isChecked ? 8 : 10,
	},
}));

export const StyledDayOfWeekText = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'isSelected',
})<DayOfWeekProp>(({ theme, isSelected }) => ({
	display: 'flex',
	fontSize: '18px',
	justifyContent: 'center',
	alignItems: 'center',
	color: isSelected ? theme.palette.primary.main : theme.palette.neutral.light,
	fontFamily: 'Rubik',
	fontWeight: 500,
	userSelect: 'none',
	'@media only screen and (max-width: 1200px)': {
		fontSize: '14px',
		padding: 0,
	},
}));

export const StyledHoursContainer = styled(Box)(() => ({
	display: 'flex',
	width: 223,
	justifyContent: 'space-between',
	'@media only screen and (max-width: 1200px)': {
		height: 40,
		width: 182,
		fontSize: 14,
	},
}));

export const StyledOutsideHoursContainer = styled(Box)(() => ({
	display: 'flex',
	width: 223,
	justifyContent: 'space-between',
	'@media only screen and (max-width: 1200px)': {
		height: 40,
		width: 182,
		fontSize: 14,
		marginTop: -10,
	},
}));

export const StyledTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		position: 'relative',
		borderRadius: 8,
		height: 56,
		width: 83,
		fontFamily: 'Rubik',

		'@media only screen and (max-width: 1200px)': {
			height: 40,
			width: 73,
			fontSize: 14,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'& input[type="time"]::-webkit-calendar-picker-indicator': {
		background: 'none',
		display: 'none',
	},
}));

export const StyledErrorMessage = styled(Box)(({ theme }) => ({
	fontSize: 12,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.error.main,
	marginTop: 1,
	marginLeft: 14,
}));

export const StyledLineDivisor = styled(Box)(() => ({
	display: 'flex',
	marginTop: 16,
	alignItems: 'center',
	justifyContent: 'center',
	'@media only screen and (max-width: 1200px)': {
		marginTop: 32,
	},
}));

export const StyledTextContainer = styled(Box)(() => ({
	display: 'block',
	marginBottom: '30px',
}));

export const TypographyTitle = styled(Typography)(({ theme }) => ({
	fontSize: 20,
	lineHeight: '24px',
	fontFamily: 'Rubik',
	fontWeight: 500,
	display: 'baseline',
	color: theme.palette.neutral.dark,
	marginBottom: 5,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 16,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 14,
	},
}));

export const TypographyText = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	lineHeight: '20px',
	fontFamily: 'Rubik',
	fontWeight: 400,
	display: 'baseline',
	color: theme.palette.neutral.dark1,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 12,
	},
}));

export const StyledUserWarning = styled(Box)(({ theme }) => ({
	display: 'flex',
	fontFamily: 'Rubik',
	maxHeight: 48,
	fontSize: 16,
	fontWeight: 500,
	color: theme.palette.primary.dark,
	alignItems: 'center',
	justifyContent: 'start',
	padding: '12px 12px 12px 0px',
	marginBottom: 28,
	borderRadius: 8,
	backgroundColor: theme.palette.neutral.light1,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
		padding: '16px 24px 16px 24px',
	},
}));

export const StyledDeliveryContainer = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	maxHeight: 60,
	'@media only screen and (max-width: 1200px)': {
		maxHeight: 60,
	},
}));

export const StyledDelivery = styled(Box)(({ theme }) => ({
	display: 'flex',
	height: 60,

	width: 222,
	color: theme.palette.neutral.dark,
	borderRadius: 8,
	fontFamily: 'Rubik',
	fontWeight: 400,
	fontSize: 18,
	marginRight: 20,
	border: '1px solid',
	borderColor: theme.palette.neutral.dark2,
	justifyContent: 'space-between',
	alignItems: 'center',
	userSelect: 'none',
	paddingInline: '16px',
	'@media only screen and (max-width: 1200px)': {
		height: 40,
		width: 182,
		fontSize: 14,
		marginTop: 5,
	},
}));

export const StyledButtonsContainer = styled(Box)(() => ({
	display: 'flex',
	marginTop: '50px',
	paddingBottom: '50px',
	alignItems: 'center',
	justifyContent: 'center',
	'@media only screen and (max-width: 1200px)': {
		marginTop: 24,
	},
}));

export const StyledResetFormButton = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	fontFamily: 'Rubik',
	height: 54,
	width: 300,
	fontWeight: 700,
	fontSize: 18,
	cursor: 'pointer',
	color: theme.palette.primary.main,
	border: '1px solid',
	borderColor: theme.palette.neutral.dark2,
	borderRadius: 5,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
		height: 39,
		width: 108,
	},
}));

export const StyledButton = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginLeft: 36,
}));

export const StyledLeftContainer = styled(Box)(() => ({
	display: 'flex',
}));

export const StyledStartPreparingDuration = styled(TextField)(({ theme }) => ({
	'& .MuiOutlinedInput-root ': {
		position: 'relative',
		borderRadius: 8,
		maxHeight: 60,
		height: 60,
		width: 123,
		fontFamily: 'Rubik',
		color: theme.palette.neutral.dark1,
		'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
			display: 'none',
		},
		'& input[type=number]': {
			MozAppearance: 'textfield',
		},
		'@media only screen and (max-width: 1400px)': {
			height: 40,
			fontSize: 13,
			'& .MuiFormLabel-root': {
				fontSize: '13px',
			},
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'& input[type="time"]::-webkit-calendar-picker-indicator': {
		background: 'none',
		display: 'none',
	},
	'@media only screen and (max-width: 1400px)': {
		'& .MuiFormLabel-root': {
			fontSize: '13px',
		},
		'& .MuiFormHelperText-root': {
			fontSize: '9px!important',
			whiteSpace: 'nowrap',
		},
	},
}));

export const StyledDurationTypography = styled(Typography)(({ theme }) => ({
	fontSize: 15,
	fontFamily: 'Rubik',
	fontWeight: 400,
	lineHeight: 'unset',
	display: 'flex',
	marginTop: 2,
	color: theme.palette.neutral.dark1,
}));

export const TypographyTextPreparation = styled(Typography)(({ theme }) => ({
	fontSize: 18,
	lineHeight: '24px',
	fontFamily: 'Rubik',
	fontWeight: 400,
	display: 'baseline',
	color: theme.palette.neutral.dark1,
	marginInline: 16,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 12,
	},
}));
