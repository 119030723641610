import { Button } from '@mui/material';
import React from 'react';
import { ReactComponent as CarIcon } from '../../assets/images/carIcon.svg';
import { IDashboardTopBar } from './interface/topbar-text.interface';
import {
	StyledContainer,
	StyledTextContainer,
	TypographyText,
	TypographyTitle,
} from './styles';

const DashboardTopBar: React.FC<IDashboardTopBar> = ({
	title,
	text,
	menu,
	handleOpenDeliveryModal,
}: IDashboardTopBar): JSX.Element => {
	return (
		<StyledContainer>
			<StyledTextContainer>
				<TypographyTitle>{title}</TypographyTitle>
				<TypographyText>{text}</TypographyText>
			</StyledTextContainer>
			{menu && (
				<Button
					variant="contained"
					color="primary"
					style={{ textTransform: 'none', fontSize: 16, borderRadius: 8 }}
					onClick={handleOpenDeliveryModal}
				>
					<CarIcon color="white" /> &nbsp; Gestão de entrega
				</Button>
			)}
		</StyledContainer>
	);
};

export default DashboardTopBar;
