import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
	Autocomplete,
	FormControl,
	InputAdornment,
	Switch,
	Tooltip,
} from '@mui/material';
import {
	DatePicker,
	DateTimePicker,
	LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';
import { useFormikContext } from 'formik';
import { default as moment } from 'moment';
import 'moment/locale/pt-br';
import React, { useEffect, useState } from 'react';
import { ReactComponent as SmallEmptyIcon } from '../../../../assets/images/smallEmptyImg.svg';
import { convertToBase64 } from '../../../../shared/helpers/convertToBase64.helper';
import { ITag, PromotionProps } from '../../../../utils/api/interfaces';
import { moneyMask } from '../../../../utils/mask.util';
import ContinueButton from '../../../Buttons/ContinueLoginButton/ContinueLoginButton';
import ImageModal from '../../../Promotion/ImageModal/ImageModal';
import ModalSucess from '../../../Promotion/ModalSucess/ModalSucess';
import { CreatePromotionFormProps } from './interface/promotionTags-interface';
import {
	Input,
	StyledBigEmptyIcon,
	StyledBottomRow,
	StyledButtonsContainer,
	StyledCouponDuration,
	StyledCouponHours,
	StyledCouponLimitContainer,
	StyledCouponStatus,
	StyledCouponStatusContainer,
	StyledCouponValueTextField,
	StyledCouponVolume,
	StyledCreatePromotionButton,
	StyledCreatePromotionFormContainer,
	StyledDataTextField,
	StyledDataTextFieldFinal,
	StyledDescriptionTextField,
	StyledDurationTypography,
	StyledEmptyImage,
	StyledFieldCount,
	StyledHourTextField,
	StyledImageError,
	StyledImageName,
	StyledImageNameText,
	StyledImageText,
	StyledImageTextPromotion,
	StyledImageTitle,
	StyledInitialPrice,
	StyledLimitCouponStatus,
	StyledNameTextField,
	StyledPreviewImg,
	StyledPreviewName,
	StyledPreviewPrice,
	StyledPreviewPriceTypography,
	StyledPromotionCouponContainer,
	StyledPromotionDescription,
	StyledPromotionEnd,
	StyledPromotionEndDateContainer,
	StyledPromotionFormContainer,
	StyledPromotionFormText,
	StyledPromotionPeriod,
	StyledPromotionPeriodContainer,
	StyledPromotionPreviewCard,
	StyledPromotionPrice,
	StyledPromotionPriceContainer,
	StyledPromotionStart,
	StyledPromotionalCategory,
	StyledPromotionalPrice,
	StyledPromotonTitle,
	StyledRemoveImageIcon,
	StyledResetFormButton,
	StyledTagTextField,
	StyledUploadFileContainer,
	StyledUploadFileContent,
	StyledUploadFileTypography,
	StyledUploadImageContainer,
	StyledValueTextField,
	StyledValuesRow,
	SyledUploadImage,
} from './styles';

const customTextFieldStyles = () => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 2,
		height: 48,
		fontFamily: 'Rubik',
		'@media only screen and (max-width: 1400px)': {
			height: 38,
			fontSize: 12,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px',
	},
	'& .MuiOutlinedInput-input': {
		'&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
			WebkitAppearance: 'none',
		},
	},
});

const formHelperStyle = {
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 400,
};

const CreatePromotionForm: React.FC<CreatePromotionFormProps> = ({
	tags,
}: CreatePromotionFormProps): JSX.Element => {
	const {
		values: {
			tag_name,
			title,
			description,
			initial_price,
			promotional_price,
			promotion_expires_date,
			promotion_expires_hour,
			qtd_coupons,
			promotion_image,
			has_coupons,
			coupon_duration,
			user_with_multiplus_coupons,
		},
		handleChange,
		touched,
		errors,
		setFieldValue,
		resetForm,
		isSubmitting,
		isValid,
		isValidating,
		submitCount,
		initialValues,
	} = useFormikContext<PromotionProps>();
	const [photoError, setPhotoError] = useState(false);
	const [checked, setChecked] = useState(false);
	const [openPromotionImageModal, setOpenPromotionImageModal] = useState(false);
	const [openCouponDurationModal, setOpenCouponDurationModal] = useState(false);
	const [openCouponLimitModal, setOpenLimitModal] = useState(false);

	const [imageState, setImageState] = useState({
		promotion_image: '',
		promotion_image_name: '',
		path: '',
	});
	const [selectedTag, setSelectedTag] = useState<ITag>({
		name: tag_name || '',
	});

	const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFieldValue('user_with_multiplus_coupons', event.target.checked);
	};

	const handleClosePromotionImageModal = (): void =>
		setOpenPromotionImageModal(false);

	const handleOpenPromotionImageModal = (): void =>
		setOpenPromotionImageModal(true);

	const handleCloseLimitModal = (): void => setOpenLimitModal(false);

	const handleOpenLimitModal = (): void => setOpenLimitModal(true);

	const handleCloseCouponDurationModal = (): void =>
		setOpenCouponDurationModal(false);

	const handleOpenCouponDurationModal = (): void =>
		setOpenCouponDurationModal(true);

	const handleFileChange = async (e: any) => {
		const file = e.target.files[0];
		const base64 = await convertToBase64(file);
		setImageState({
			promotion_image: base64 as string,
			promotion_image_name: e.target.files[0].name,
			path: URL.createObjectURL(e.target.files[0]),
		});
		setPhotoError(false);
	};

	const handleImagePreview = () => {
		setImageState({
			promotion_image: '',
			promotion_image_name: '',
			path: '',
		});
		if (submitCount > 0) {
			setPhotoError(true);
		}
	};

	const handleResetForm = () => {
		resetForm({ values: initialValues });
		handleImagePreview();
		setChecked(false);
		setPhotoError(false);
		setSelectedTag({ name: '' });
		setFieldValue('promotion_expires_hour', '');
	};

	useEffect(() => {
		setFieldValue('promotion_image', imageState?.promotion_image);
	}, [imageState?.promotion_image]);

	useEffect(() => {
		setFieldValue('has_coupons', checked);
	}, [checked]);

	useEffect(() => {
		if (!isValid && !isValidating && isSubmitting && errors.promotion_image) {
			setPhotoError(true);
		}
	}, [isSubmitting, isValid, isValidating]);

	useEffect(() => {
		setFieldValue('promotion_initial_date', moment());
	}, []);

	useEffect(() => {
		if (!promotion_image) {
			setImageState({
				promotion_image: '',
				promotion_image_name: '',
				path: '',
			});
		}
	}, [promotion_image]);

	useEffect(() => {
		if (!has_coupons) {
			setChecked(false);
		}
	}, [has_coupons]);

	useEffect(() => {
		setSelectedTag({ name: tag_name || '' });
	}, [tag_name]);

	return (
		<StyledCreatePromotionFormContainer>
			<StyledUploadImageContainer>
				<StyledImageTitle>Imagem do produto</StyledImageTitle>

				<StyledImageTextPromotion
					onClick={() => handleOpenPromotionImageModal()}
				>
					Saiba como escolher a melhor foto
				</StyledImageTextPromotion>
				<ImageModal
					handleClosePromotionImageModal={handleClosePromotionImageModal}
					openPromotionImageModal={openPromotionImageModal}
				/>
				{!imageState.path && (
					<SyledUploadImage error={!photoError}>
						<label>
							<Input
								type="file"
								name="promotion_image"
								onChange={handleFileChange}
								accept="image/*"
								multiple
							/>
							<StyledUploadFileContainer>
								<StyledUploadFileContent>
									<StyledEmptyImage>
										<SmallEmptyIcon />
									</StyledEmptyImage>
									<StyledUploadFileTypography>
										Clique aqui para selecionar a imagem
									</StyledUploadFileTypography>
								</StyledUploadFileContent>
							</StyledUploadFileContainer>
						</label>
						<StyledImageError component="div" name="promotion_image" />
					</SyledUploadImage>
				)}
				{imageState.path && (
					<StyledImageName>
						<StyledImageNameText>
							{imageState.promotion_image_name}
						</StyledImageNameText>
						<StyledRemoveImageIcon onClick={handleImagePreview}>
							<CloseIcon sx={{ color: '#747476' }} />
						</StyledRemoveImageIcon>
					</StyledImageName>
				)}
				<StyledImageTitle>Pré-visualização</StyledImageTitle>
				<StyledImageText>
					Preencha as informações para visualizar como sua promoção ficará.
				</StyledImageText>
				<StyledPromotionPreviewCard>
					<StyledPreviewImg isEmpty={!imageState.path}>
						{!imageState.path && <StyledBigEmptyIcon />}
						{imageState.path && <img src={imageState?.path} />}
					</StyledPreviewImg>
					<StyledPreviewName isEmpty={!title}>{title}</StyledPreviewName>
					<StyledPreviewPrice isEmpty={!promotional_price}>
						{promotional_price === 0 ? (
							<StyledPreviewPriceTypography></StyledPreviewPriceTypography>
						) : (
							<StyledPreviewPriceTypography>
								{moneyMask(promotional_price)}
							</StyledPreviewPriceTypography>
						)}
					</StyledPreviewPrice>
				</StyledPromotionPreviewCard>
			</StyledUploadImageContainer>

			<StyledPromotionFormContainer>
				<StyledPromotonTitle>
					<FormControl fullWidth>
						<StyledNameTextField
							id="outlined-basic"
							value={title}
							label="Nome do produto"
							variant="outlined"
							onChange={handleChange('title')}
							error={touched.title && Boolean(errors.title)}
							helperText={touched.title && errors.title}
							inputProps={{ maxLength: 50 }}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<StyledFieldCount>
											{`${Number(50) - title.length} caracteres restantes`}
										</StyledFieldCount>
									</InputAdornment>
								),
							}}
							FormHelperTextProps={{
								style: formHelperStyle,
							}}
						/>
					</FormControl>
				</StyledPromotonTitle>
				<StyledPromotionDescription>
					<FormControl fullWidth>
						<StyledDescriptionTextField
							id="outlined-basic"
							value={description}
							label="Descrição"
							variant="outlined"
							onChange={handleChange('description')}
							error={touched.description && Boolean(errors.description)}
							helperText={touched.description && errors.description}
							multiline={true}
							rows={3}
							inputProps={{
								maxLength: 500,
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<StyledFieldCount>
											{`${
												Number(500) - description.length
											} caracteres restantes`}
										</StyledFieldCount>
									</InputAdornment>
								),
							}}
							FormHelperTextProps={{
								style: formHelperStyle,
							}}
						/>
					</FormControl>
				</StyledPromotionDescription>
				<StyledValuesRow>
					<StyledPromotionPriceContainer>
						<StyledPromotionPrice>
							<StyledInitialPrice>
								<FormControl>
									<StyledValueTextField
										id="outlined-basic"
										value={moneyMask(initial_price)}
										label="Valor original"
										placeholder="ex: R$ 987,00"
										variant="outlined"
										onChange={handleChange('initial_price')}
										error={
											touched.initial_price && Boolean(errors.initial_price)
										}
										helperText={touched.initial_price && errors.initial_price}
										inputProps={{ maxLength: 15 }}
										FormHelperTextProps={{
											style: formHelperStyle,
										}}
										sx={customTextFieldStyles}
									/>
								</FormControl>
							</StyledInitialPrice>
							<StyledPromotionalPrice>
								<FormControl>
									<StyledValueTextField
										id="outlined-basic"
										value={moneyMask(promotional_price)}
										label="Valor com desconto"
										placeholder="ex: R$ 987,00"
										variant="outlined"
										onChange={handleChange('promotional_price')}
										error={
											touched.promotional_price &&
											Boolean(errors.promotional_price)
										}
										helperText={
											touched.promotional_price && errors.promotional_price
										}
										inputProps={{ maxLength: 15 }}
										FormHelperTextProps={{
											style: formHelperStyle,
										}}
										sx={customTextFieldStyles}
									/>
								</FormControl>
							</StyledPromotionalPrice>
						</StyledPromotionPrice>
					</StyledPromotionPriceContainer>
					<StyledPromotionalCategory>
						<FormControl>
							<Autocomplete
								sx={{
									width: 252,
									'@media only screen and (max-width: 1400px)': {
										width: 150,
									},
								}}
								value={selectedTag}
								options={tags}
								autoHighlight
								getOptionLabel={(option) => option?.name}
								onChange={(e, value) => {
									setFieldValue('tag_id', value?.id || '');
									setFieldValue('tag_name', value?.name || '');
									setSelectedTag({ name: tag_name || '' });
								}}
								includeInputInList
								isOptionEqualToValue={(option, value) =>
									value.name === '' || option.name === value.name
								}
								renderInput={(params) => (
									<StyledTagTextField
										{...params}
										id="outlined-basic"
										type="text"
										label="Categoria"
										variant="outlined"
										error={touched.tag_id && Boolean(errors.tag_id)}
										helperText={touched.tag_id && errors.tag_id}
										FormHelperTextProps={{
											style: formHelperStyle,
										}}
									/>
								)}
							/>
						</FormControl>
					</StyledPromotionalCategory>
				</StyledValuesRow>
				<StyledPromotionPeriodContainer>
					<StyledPromotionPeriod>
						<StyledPromotionStart>
							<StyledPromotionFormText>Ínicio</StyledPromotionFormText>
							<LocalizationProvider
								dateAdapter={AdapterDateFns}
								adapterLocale={brLocale}
							>
								<DateTimePicker
									disabled={true}
									value={moment()}
									onChange={(val: any) => {
										setFieldValue('promotion_initial_date', val);
									}}
									renderInput={(params: any) => (
										<StyledDataTextField
											{...params}
											error={
												touched.promotion_initial_date &&
												Boolean(errors.promotion_initial_date)
											}
											helperText={
												touched.promotion_initial_date &&
												errors.promotion_initial_date
											}
											InputLabelProps={{ shrink: false }}
											FormHelperTextProps={{
												style: formHelperStyle,
											}}
											disabled={true}
										/>
									)}
								/>
							</LocalizationProvider>
						</StyledPromotionStart>
						<StyledPromotionEnd>
							<StyledPromotionEndDateContainer>
								<StyledPromotionFormText>Fim</StyledPromotionFormText>

								<LocalizationProvider
									dateAdapter={AdapterDateFns}
									adapterLocale={brLocale}
								>
									<DatePicker
										disablePast
										value={promotion_expires_date}
										onChange={(val) => {
											setFieldValue('promotion_expires_date', val);
										}}
										minDate={moment().toDate()}
										renderInput={(params: any) => (
											<StyledDataTextFieldFinal
												{...params}
												error={
													touched.promotion_expires_date &&
													Boolean(errors.promotion_expires_date)
												}
												helperText={
													touched.promotion_expires_date &&
													errors.promotion_expires_date
												}
												InputLabelProps={{ shrink: false }}
												FormHelperTextProps={{
													style: formHelperStyle,
												}}
											/>
										)}
									/>
								</LocalizationProvider>
							</StyledPromotionEndDateContainer>
							<StyledHourTextField
								type="time"
								variant="outlined"
								label="Horas"
								value={promotion_expires_hour ?? ''}
								InputLabelProps={{ shrink: true }}
								error={
									touched.promotion_expires_hour &&
									Boolean(errors.promotion_expires_hour)
								}
								helperText={
									touched.promotion_expires_hour &&
									errors.promotion_expires_hour
								}
								onChange={(val) => {
									setFieldValue('promotion_expires_hour', val.target.value);
								}}
								FormHelperTextProps={{
									style: formHelperStyle,
								}}
							/>
						</StyledPromotionEnd>
					</StyledPromotionPeriod>
				</StyledPromotionPeriodContainer>
				<StyledBottomRow>
					<StyledPromotionCouponContainer>
						<StyledCouponStatusContainer>
							<StyledPromotionFormText>Cupons</StyledPromotionFormText>
							<StyledCouponStatus>
								Ativar cupons
								<Switch
									checked={checked}
									onChange={handleSwitchChange}
									size="small"
									inputProps={{ 'aria-label': 'controlled' }}
								/>
							</StyledCouponStatus>
						</StyledCouponStatusContainer>
						{checked && (
							<>
								<StyledCouponVolume>
									<FormControl>
										<StyledCouponValueTextField
											id="outlined-basic"
											type="number"
											value={qtd_coupons}
											label="Quantidade"
											placeholder="ex: 10"
											variant="outlined"
											onChange={handleChange('qtd_coupons')}
											error={touched.qtd_coupons && Boolean(errors.qtd_coupons)}
											helperText={touched.qtd_coupons && errors.qtd_coupons}
											inputProps={{ maxLength: 6 }}
											FormHelperTextProps={{
												style: formHelperStyle,
											}}
											sx={customTextFieldStyles}
										/>
									</FormControl>
								</StyledCouponVolume>
								<StyledCouponLimitContainer>
									<StyledPromotionFormText>
										Limitar cupons
										<Tooltip
											title={'Clique para saber mais'}
											arrow
											placement="top-end"
											onClick={handleOpenLimitModal}
										>
											<HelpOutlineIcon
												color="disabled"
												fontSize="inherit"
												style={{
													height: 22,
													width: 22,
													marginLeft: 5,
													cursor: 'pointer',
												}}
											/>
										</Tooltip>
									</StyledPromotionFormText>
									<StyledLimitCouponStatus>
										Limitar
										<Switch
											checked={user_with_multiplus_coupons}
											onChange={handleLimitChange}
											size="small"
											inputProps={{ 'aria-label': 'controlled' }}
										/>
									</StyledLimitCouponStatus>
								</StyledCouponLimitContainer>
								<StyledCouponHours>
									<StyledPromotionFormText>
										Duração
										<Tooltip
											title={'Clique para saber mais'}
											arrow
											placement="top-end"
											onClick={handleOpenCouponDurationModal}
										>
											<HelpOutlineIcon
												color="disabled"
												fontSize="inherit"
												style={{
													height: 22,
													width: 22,
													marginLeft: 5,
													cursor: 'pointer',
												}}
											/>
										</Tooltip>
									</StyledPromotionFormText>
									<FormControl>
										<StyledCouponDuration
											type="time"
											variant="outlined"
											value={coupon_duration ?? ''}
											InputLabelProps={{ shrink: true }}
											error={
												touched.coupon_duration &&
												Boolean(errors.coupon_duration)
											}
											helperText={
												touched.coupon_duration && errors.coupon_duration
											}
											onChange={(val) => {
												setFieldValue('coupon_duration', val.target.value);
											}}
											FormHelperTextProps={{
												style: formHelperStyle,
											}}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<StyledDurationTypography>
															horas
														</StyledDurationTypography>
													</InputAdornment>
												),
											}}
										/>
									</FormControl>
								</StyledCouponHours>
							</>
						)}
					</StyledPromotionCouponContainer>
				</StyledBottomRow>

				<StyledButtonsContainer>
					<StyledResetFormButton onClick={() => handleResetForm()}>
						Limpar
					</StyledResetFormButton>
					<StyledCreatePromotionButton>
						<ContinueButton>Postar promoção</ContinueButton>
					</StyledCreatePromotionButton>
				</StyledButtonsContainer>
			</StyledPromotionFormContainer>
			{openCouponDurationModal && (
				<ModalSucess
					handleClosePromotionModal={handleCloseCouponDurationModal}
					openPromotionSucessModal={openCouponDurationModal}
					modalType={'coupon'}
					modalTitle={'Duração do cupom'}
					modalText={
						'A duração do cupom define quanto tempo o usuário terá para utilizar o cupom após resgatar. Caso o usuário não utilize o cupom, ele será expirado e voltará para a oferta.'
					}
					modalButtonText={'Ok, entendi!'}
				/>
			)}
			{openCouponLimitModal && (
				<ModalSucess
					handleClosePromotionModal={handleCloseLimitModal}
					openPromotionSucessModal={openCouponLimitModal}
					modalType={'coupon'}
					modalTitle={'Limitar cupom por usuário'}
					modalText={
						'Decida se os usuários poderão resgatar mais de um cupom dessa promoção. Os usuários só poderão resgatar outro cupom após a confirmação do anterior.'
					}
					modalButtonText={'Ok, entendi!'}
				/>
			)}
		</StyledCreatePromotionFormContainer>
	);
};

export default CreatePromotionForm;
