import React, { useEffect, useState } from 'react';
import { ReactComponent as BackArrow } from '../../../../assets/images/iconReturn.svg';
import ContinueButton from '../../../Buttons/ContinueLoginButton/ContinueLoginButton';
import {
	BodyGrid,
	FormBody,
	FormBox,
	FormFooter,
	FormHeader,
	ItemGrid,
	StyledBackArrow,
	StyledFormSubText,
	StyledFormText,
} from '../styles';
import { CodeInput } from './CodeInput';
import { InsertTokenFormProps } from './interface/insertToker.interface';
import {
	StyledLinkContainer,
	StyledSendButtonContainer,
	StyledTypographyLink,
	StyledTypographyLinkBold,
} from './styles';

const InsertTokenForm: React.FC<InsertTokenFormProps> = ({
	forgotPassword,
	handleFormPage,
	handleTokenChange,
	isTokenCodeValid,
	userEmail,
}): JSX.Element => {
	const [counter, setCounter] = useState(30);
	const [multiple, setMultiple] = useState(2);

	const handleForgotPassword = (): void => {
		forgotPassword(userEmail),
			setCounter(multiple * 30),
			setMultiple((prevMultiple) => prevMultiple + 1);
	};

	useEffect(() => {
		counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
	}, [counter]);
	return (
		<FormBox>
			<FormHeader>
				<StyledBackArrow onClick={handleFormPage}>
					<BackArrow />
				</StyledBackArrow>
			</FormHeader>
			<FormBody>
				<BodyGrid>
					<ItemGrid xs={12}>
						<StyledFormText>Insira o código</StyledFormText>
						<StyledFormSubText>
							Enviamos um código de verificação para o seu email.
						</StyledFormSubText>
					</ItemGrid>
					<ItemGrid xs={12}>
						<CodeInput
							onComplete={handleTokenChange}
							isValid={isTokenCodeValid}
						/>
					</ItemGrid>
					<ItemGrid xs={12}>
						{counter ? (
							<StyledLinkContainer>
								<StyledTypographyLink>
									Aguarde {counter} segundos para reenviar.
								</StyledTypographyLink>
							</StyledLinkContainer>
						) : (
							<StyledLinkContainer onClick={handleForgotPassword}>
								<StyledTypographyLink>
									Não recebeu o código? &nbsp;
								</StyledTypographyLink>
								<StyledTypographyLinkBold>Reenviar.</StyledTypographyLinkBold>
							</StyledLinkContainer>
						)}
					</ItemGrid>
				</BodyGrid>
			</FormBody>
			<FormFooter>
				<StyledSendButtonContainer>
					<ContinueButton>CONFIRMAR</ContinueButton>
				</StyledSendButtonContainer>
			</FormFooter>
		</FormBox>
	);
};

export default InsertTokenForm;
