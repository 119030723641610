import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardTopBar from "../../components/DashboardTopBar/DashboardTopBar";
import CurrentPlan from "./CurrentPlan/CurrentPlan";
import FormOfPayment from "./FormOfPayment/FormOfPayment";
import PlanHistory from "./PlanHistory/PlanHistory";
import PlanUse from "./PlanUse/PlanUse";
import ScreenAllPlans from "./ScreenAllPlans/ScreenAllPlans";
import ScreenControllerButtons from "./ScreenControllerButtons/ScreenControllerButtons";
import {
  Container,
  ContainerContent,
  CurrenPlanAndHistory,
  PlanUseAndFormOfPayment,
  StyledDashboardTopBar,
} from "./styles";

const Plans: React.FC = (): JSX.Element => {
  const [buttonselected, setButtonselected] = useState(0);
  const [indexPlan, setIndexPlan] = useState<number | undefined>(1);
  const navigate = useNavigate();

  const handleChossePlanButtonText = (
    index: number
  ): "Fazer upgrade" | "Escolher plano" | "Plano atual" | undefined => {
    if (indexPlan != undefined) {
      if (index > indexPlan) {
        return "Fazer upgrade";
      } else if (index < indexPlan) {
        return "Escolher plano";
      } else if (index === indexPlan) {
        return "Plano atual";
      }
    } else {
      return "Escolher plano";
    }
  };

  const navigateToSignPlan = (): void => {
    navigate("/sign-plan");
  };

  return (
    <Container>
      <StyledDashboardTopBar>
        <DashboardTopBar
          title={"Planos"}
          text={"Visualize e gerencie o seu plano"}
        />
      </StyledDashboardTopBar>

      <ScreenControllerButtons
        buttonselected={buttonselected}
        setButtonselected={setButtonselected}
      />

      <ContainerContent buttonselected={buttonselected}>
        {!buttonselected ? (
          <>
            <CurrenPlanAndHistory>
              <CurrentPlan />
              <PlanHistory />
            </CurrenPlanAndHistory>
            <PlanUseAndFormOfPayment>
              <PlanUse />
              <FormOfPayment />
            </PlanUseAndFormOfPayment>
          </>
        ) : (
          <ScreenAllPlans
            handleChossePlanButtonText={handleChossePlanButtonText}
            setIndexPlan={setIndexPlan}
            indexPlan={indexPlan}
            navigateToSignPlan={navigateToSignPlan}
          />
        )}
      </ContainerContent>
    </Container>
  );
};

export default Plans;
