import axios from 'axios';
import { Dispatch } from 'react';
import { addAuth } from '../../auth/actions/auth.action';
import { newRefreshToken } from '../../utils/api/post/newRefreshToken';

export async function refreshTokenService(
	dispatch: Dispatch<any>,
	oldRefreshToken: string,
	advertiser_id: string
): Promise<string> {
	try {
		const response = await newRefreshToken(oldRefreshToken);
		const { access_token, refresh_token } = response.data;

		dispatch(
			addAuth({
				advertiser_id,
				accessToken: access_token,
				refresh_token,
			})
		);

		localStorage.setItem('refresh_token', refresh_token);
		return access_token;
	} catch (error) {
		console.error('Failed to refresh token:', error);
		throw error;
	}
}

export function setAuthToken(token: string): void {
	axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
