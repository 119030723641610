import CloseIcon from '@mui/icons-material/Close';
import { Box, CircularProgress, DialogTitle, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { memo, useEffect, useState } from 'react';
import { getMenuCategories } from '../../../utils/api/get/getMenuCategories';
import { getMenuItensByCategory } from '../../../utils/api/get/getMenuItensByCategory';
import { getOptions } from '../../../utils/api/get/getOptions';
import { getOptionsGroups } from '../../../utils/api/get/getOptionsGroups';
import { patchMenuSequence } from '../../../utils/api/patch/patchMenuSequence';
import DraggableTable from './DraggableTable/DraggableTable';
import {
	StyledButton,
	StyledButtonContainer,
	StyledDialog,
	StyledIconButton,
	StyledTypography,
} from './styles';

interface MenuOrderModalProps {
	openMenuOrderModal: boolean;
	handleCloseMenuOrderModal: () => void;
}

export interface ArrayItem {
	id: string;
	name: string;
	sequence: number;
}

export interface IOrderArray {
	[key: string]: ArrayItem[];
}

const MenuOrderModal: React.FC<MenuOrderModalProps> = ({
	openMenuOrderModal,
	handleCloseMenuOrderModal,
}: MenuOrderModalProps): JSX.Element => {
	const [menuCategories, setMenuCategories] = useState<ArrayItem[]>([]);
	const [itemCategories, setItemCategories] = useState<ArrayItem[]>([]);
	const [optionsGroup, setoptionsGroup] = useState<ArrayItem[]>([]);
	const [options, setoptions] = useState<ArrayItem[]>([]);
	const [getting, setGetting] = useState<boolean>(false);
	const [loaderTable, setLoaderTable] = useState<boolean>(true);
	const [submitingSequence, setSubmitingSequence] = useState<boolean>(false);
	const [orderArray, setOrderArray] = useState<IOrderArray>({
		category: [],
		item: [],
		optionGroup: [],
		option: [],
	});

	const { enqueueSnackbar } = useSnackbar();

	const theme = useTheme();

	function extractIdAndSequenceFromObject(
		obj: IOrderArray
	): Record<string, Array<{ id: string; sequence: number }>> {
		const result: Record<string, Array<{ id: string; sequence: number }>> = {};

		for (const key in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, key)) {
				result[key] = obj[key].map(({ id, sequence }) => ({ id, sequence }));
			}
		}
		return result;
	}

	const compareAndExtractObjects = (
		array1: ArrayItem[],
		array2: ArrayItem[]
	): ArrayItem[] => {
		const idMap = new Map<string, ArrayItem>();
		for (const obj of array2) {
			idMap.set(obj.id, obj);
		}
		const resultArray: ArrayItem[] = [];
		for (const obj of array1) {
			if (idMap.has(obj.id)) {
				resultArray.push(idMap.get(obj.id)!);
			}
		}
		resultArray.sort((a, b) => a.sequence - b.sequence);
		return resultArray;
	};

	const onCloseModal = (): void => {
		handleCloseMenuOrderModal();
	};

	const getCategories = () => {
		setLoaderTable(true);
		getMenuCategories()
			.then((res) => {
				setMenuCategories(res?.data);
				setLoaderTable(false);
			})
			.catch(() => {
				setLoaderTable(false);
			});
	};
	const getItemCategories = async (id: string): Promise<void> => {
		setGetting(true);

		await getMenuItensByCategory(id).then((res) => {
			const newOrder = compareAndExtractObjects(res?.data, [
				...orderArray.item,
			]);
			newOrder.length
				? setItemCategories(newOrder)
				: setItemCategories(res?.data);
			setoptionsGroup([]);
			setoptions([]);
		});
		setGetting(false);
	};

	const getOptionsGroup = (id: string): void => {
		getOptionsGroups(id).then((res) => {
			const newOrder = compareAndExtractObjects(
				orderArray.optionGroup,
				res?.data
			);
			newOrder.length ? setoptionsGroup(newOrder) : setoptionsGroup(res?.data);
			setoptions([]);
		});
	};

	const getOptionsByOptionsGroup = (id: string): void => {
		getOptions(id).then((res) => {
			const newOrder = compareAndExtractObjects(orderArray.option, res?.data);
			newOrder.length ? setoptions(newOrder) : setoptions(res?.data);
		});
	};

	const handleNewSequence = (): void => {
		setSubmitingSequence(true);
		const newSequence = extractIdAndSequenceFromObject(orderArray);
		patchMenuSequence(newSequence)
			.then(() => {
				setSubmitingSequence(false);
				onCloseModal();
				enqueueSnackbar('Nova ordem salva com sucesso.', {
					variant: 'success',
					autoHideDuration: 1500,
				});
			})
			.catch(() => {
				setSubmitingSequence(false);
				enqueueSnackbar('Erro ao salvar nova ordem', {
					variant: 'success',
					autoHideDuration: 1500,
				});
			});
	};

	useEffect(() => {
		getCategories();
	}, []);

	return (
		<StyledDialog
			open={openMenuOrderModal}
			onClose={onCloseModal}
			fullWidth
			maxWidth="sm"
		>
			<StyledIconButton onClick={onCloseModal}>
				<CloseIcon />
			</StyledIconButton>
			<DialogTitle
				alignSelf={'start'}
				fontSize={'1.8rem'}
				padding={'0px 16px 0px 16px!important'}
			>
				Reordenar o cardápio
			</DialogTitle>
			<DialogTitle
				alignSelf={'start'}
				fontSize={'1.0rem'}
				color={theme.palette.neutral.dark1}
				mb={2}
				padding={'0px 16px 24px 16px!important'}
			>
				Para alterar a ordem arraste o item para a posição desejada
			</DialogTitle>
			<Box style={{ display: 'flex', minHeight: '60%' }}>
				{loaderTable ? (
					<Box
						justifyContent={'center'}
						width={'100%'}
						display={'flex'}
						mt={10}
					>
						<CircularProgress color="primary" />
					</Box>
				) : (
					<>
						<DraggableTable
							items={menuCategories}
							getItemCategories={getItemCategories}
							label="Categoria"
							orderArray={orderArray}
							setOrderArray={setOrderArray}
							getting={getting}
						/>

						<DraggableTable
							items={itemCategories}
							getItemCategories={getOptionsGroup}
							label="Itens"
							orderArray={orderArray}
							setOrderArray={setOrderArray}
							getting={getting}
						/>

						<DraggableTable
							items={optionsGroup}
							getItemCategories={getOptionsByOptionsGroup}
							label="Grupo de Complementos"
							orderArray={orderArray}
							setOrderArray={setOrderArray}
							getting={getting}
						/>

						<DraggableTable
							items={options}
							label="Complementos"
							orderArray={orderArray}
							setOrderArray={setOrderArray}
							getting={getting}
						/>
					</>
				)}
			</Box>
			<StyledButtonContainer>
				<StyledButton onClick={handleNewSequence}>
					{submitingSequence ? (
						<CircularProgress color="secondary" />
					) : (
						<StyledTypography variant="body1">Salvar</StyledTypography>
					)}
				</StyledButton>
			</StyledButtonContainer>
		</StyledDialog>
	);
};

export default memo(MenuOrderModal);
