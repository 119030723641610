import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { useSelector } from 'react-redux';
import './App.scss';
import { IAuth } from './auth/interface/auth.interface';
import { IReducers } from './auth/store/store';
import Auth from './pages/Auth/Auth';
import Layout from './pages/Layout/Layout';
import {
	APP_MAX_SNACK,
	APP_POSITION,
} from './shared/constants/appConfing.constants';
import themes from './styles/themes/themes';

const App: React.FC = () => {
	const { accessToken }: IAuth = useSelector((state: IReducers) => state.auth);

	let check = true;

	const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
	if (isMobile) {
		check = true;
	}

	return (
		<SnackbarProvider
			maxSnack={APP_MAX_SNACK}
			preventDuplicate
			anchorOrigin={APP_POSITION}
		>
			<ThemeProvider theme={themes}>
				{accessToken && check ? <Layout /> : <Auth />}
			</ThemeProvider>
		</SnackbarProvider>
	);
};

export default App;
