import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as PlatinumChoose } from "../../assets/images/PlatinumChoose.svg";
import { ReactComponent as SilverChoose } from "../../assets/images/SilverChoose.svg";
import DashboardTopBar from "../../components/DashboardTopBar/DashboardTopBar";
import ChangePlanModal from "./ChangePlanModal/ChangePlanModal";
import ChosenPlan from "./ChosenPlan/ChosenPlan";
import PaymentInformation from "./PaymentInformation/PaymentInformation";

import { Container, ContainerContent, StyledDashboardTopBar } from "./styles";

const SignPlan: React.FC = (): JSX.Element => {
  const [valueAddress, setValueAddress] = useState("adress");
  const [valueCard, setValueCard] = useState("card");
  const [editingAddress, setEditingAddress] = useState<boolean>(false);
  const [editingCard, setEditingCard] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const handleChangeAdress = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setValueAddress((event.target as HTMLInputElement).value);
  };

  const handleChangeCard = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setValueCard((event.target as HTMLInputElement).value);
  };

  const navigate = useNavigate();

  const navigateToPlans = (): void => {
    navigate("/plans");
  };

  const navigateToConfirmPlan = (): void => {
    navigate("/confirm-plan");
  };

  const handleClose = (): void => setOpen(false);

  const handleOpen = (): void => setOpen(true);

  const handleIconPlansToChoose = (planType: string) => {
    if (planType === "Silver") {
      return <SilverChoose />;
    } else if (planType === "Platinum") {
      return <PlatinumChoose />;
    }
  };

  return (
    <Container>
      <ChangePlanModal
        open={open}
        handleClose={handleClose}
        handleIconPlansToChoose={handleIconPlansToChoose}
      />

      <StyledDashboardTopBar>
        <DashboardTopBar
          title={"Planos"}
          text={"Visualize e gerencie o seu plano"}
        />
      </StyledDashboardTopBar>
      <ContainerContent
        valueAddress={valueAddress}
        valueCard={valueCard}
        editingAddress={editingAddress}
        editingCard={editingCard}
      >
        <PaymentInformation
          valueAddress={valueAddress}
          valueCard={valueCard}
          handleChangeAdress={handleChangeAdress}
          handleChangeCard={handleChangeCard}
          editingAddress={editingAddress}
          setEditingAddress={setEditingAddress}
          setValueAddress={setValueAddress}
          setValueCard={setValueCard}
          editingCard={editingCard}
          setEditingCard={setEditingCard}
        />
        <ChosenPlan
          navigateToPlans={navigateToPlans}
          navigateToConfirmPlan={navigateToConfirmPlan}
          handleOpen={handleOpen}
        />
      </ContainerContent>
    </Container>
  );
};

export default SignPlan;
