import CloseIcon from '@mui/icons-material/Close';
import { DialogActions } from '@mui/material';
import React from 'react';
import { ReactComponent as CheckIcon } from '../../../assets/images/checkIconWithBorder.svg';
import { ReactComponent as CreditCardIcon } from '../../../assets/images/creditCardIcon.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/images/errorIcon.svg';
import { ReactComponent as MoneyIcon } from '../../../assets/images/money.svg';
import { ReactComponent as PixIcon } from '../../../assets/images/pixIcon.svg';
import { ReactComponent as WarningIcon } from '../../../assets/images/warningIconWithBorder.svg';
import { ReactComponent as WatchIcon } from '../../../assets/images/watchIcon.svg';
import { dateFormat, replaceDot } from '../../../utils/mask.util';
import {
	ModalTitleSectionProps,
	OrderRefundModalProps,
	PaymentInformationProps,
} from './interface/orderRefund-modal.interface';
import {
	StyledButton,
	StyledDialog,
	StyledDialogContent,
	StyledIconButton,
	StyledModalSubtitle,
	StyledModalTitle,
	StyledModalTitleContainer,
	StyledOrderInformationTitle,
	StyledOrderRefundPaymentInformation,
	StyledOrderRefundPaymentInformationText,
	StyledOrderRefundPaymentInformationTitle,
	StyledTitleContainer,
	StyledTypography,
} from './styles';

const PaymentInformation: React.FC<PaymentInformationProps> = ({
	Icon,
	title,
	text,
}) => (
	<StyledOrderRefundPaymentInformation>
		<Icon />
		<StyledOrderRefundPaymentInformationTitle>
			{title}
		</StyledOrderRefundPaymentInformationTitle>
		<StyledOrderRefundPaymentInformationText>
			&nbsp;{text}
		</StyledOrderRefundPaymentInformationText>
	</StyledOrderRefundPaymentInformation>
);

const ModalTitleSection: React.FC<ModalTitleSectionProps> = ({
	Icon,
	title,
	subtitle,
}) => (
	<StyledModalTitleContainer>
		<Icon />
		<StyledTitleContainer>
			<StyledModalTitle>{title}</StyledModalTitle>
			<StyledModalSubtitle>{subtitle}</StyledModalSubtitle>
		</StyledTitleContainer>
	</StyledModalTitleContainer>
);

const OrderRefundModal: React.FC<OrderRefundModalProps> = ({
	openOrderRefundModal,
	handleCloseOrderRefundModal,
	orderDetails,
}) => {
	return (
		<StyledDialog
			open={openOrderRefundModal}
			onClose={handleCloseOrderRefundModal}
			fullWidth
			maxWidth="sm"
		>
			<StyledIconButton onClick={handleCloseOrderRefundModal}>
				<CloseIcon />
			</StyledIconButton>

			{orderDetails?.order.status === 'Reembolso Pendente' && (
				<>
					<ModalTitleSection
						Icon={WarningIcon}
						title="Reembolso em andamento"
						subtitle="Confira os detalhes do reembolso"
					/>
					<StyledDialogContent>
						<StyledOrderInformationTitle>
							O cliente já foi notificado sobre o reembolso e o valor será
							enviado em breve. Não se preocupe, a taxa não será cobrada por
							esse pedido! Confira abaixo os prazos estimados para a conclusão
							do processo de reembolso:
						</StyledOrderInformationTitle>
						<PaymentInformation
							Icon={CreditCardIcon}
							title="Cartão de crédito:"
							text="Até 5 dias úteis, ou na próxima fatura, caso a fatura mensal atual já esteja fechada."
						/>
						<PaymentInformation
							Icon={CreditCardIcon}
							title="Cartão de débito:"
							text="Até 3 dias úteis."
						/>
						<PaymentInformation
							Icon={PixIcon}
							title="PIX:"
							text="Até 1 dia útil."
						/>
						<PaymentInformation
							Icon={MoneyIcon}
							title="Valor do reembolso:"
							text={`R$ ${replaceDot(orderDetails.totItemPrice.toFixed(2))}`}
						/>
					</StyledDialogContent>
				</>
			)}

			{orderDetails?.order.status === 'Reembolsado' && (
				<>
					<ModalTitleSection
						Icon={CheckIcon}
						title="Reembolso concluído"
						subtitle="Confira os detalhes do reembolso"
					/>
					<StyledDialogContent>
						<StyledOrderInformationTitle>
							O reembolso do pedido foi concluído e o valor já foi enviado ao
							cliente. Não se preocupe, a taxa não será cobrada por esse pedido!
						</StyledOrderInformationTitle>
						<PaymentInformation
							Icon={WatchIcon}
							title="Data do reembolso:"
							text={dateFormat(orderDetails.order.updated_at)}
						/>
						<PaymentInformation
							Icon={MoneyIcon}
							title="Valor do reembolso:"
							text={`R$ ${replaceDot(orderDetails.totItemPrice.toFixed(2))}`}
						/>
						<PaymentInformation
							Icon={PixIcon}
							title="Meio de reembolso:"
							text={orderDetails.order.paymentMethod.name}
						/>
					</StyledDialogContent>
				</>
			)}

			{orderDetails?.order.status === 'Reembolso Cancelado' && (
				<>
					<ModalTitleSection
						Icon={ErrorIcon}
						title="Reembolso não concluído"
						subtitle="Confira os detalhes do reembolso"
					/>
					<StyledDialogContent>
						<StyledOrderInformationTitle>
							Infelizmente, por um erro inesperado, não foi possível concluir o
							reembolso do valor do pedido para o cliente.
						</StyledOrderInformationTitle>
						<StyledOrderInformationTitle>
							Orientamos que o cliente entre em contato com o suporte para obter
							ajuda com o reembolso.
						</StyledOrderInformationTitle>
						<StyledOrderInformationTitle>
							Não se preocupe, a sua loja não pagará taxa por esse pedido!
						</StyledOrderInformationTitle>
					</StyledDialogContent>
				</>
			)}

			<DialogActions>
				<StyledButton onClick={handleCloseOrderRefundModal}>
					<StyledTypography>Falar com o suporte</StyledTypography>
				</StyledButton>
			</DialogActions>
		</StyledDialog>
	);
};

export default OrderRefundModal;
