import { Box } from "@mui/material";
import { ReactComponent as CardFlag } from "../../../assets/images/CardFlag.svg";
import {
  AlignContentAndButton,
  CardNameAndFlag,
  ChangeButton,
  ChangeButtonText,
  InfoPaymentContent,
  PaymentText,
  PaymentTitle,
  TypeCard,
} from "./styles";

const InfoPaymentConfirmPlan: React.FC = (): JSX.Element => (
  <InfoPaymentContent>
    <AlignContentAndButton>
      <Box>
        <PaymentTitle>Método de pagamento</PaymentTitle>
        <CardNameAndFlag>
          <CardFlag />
          <TypeCard>VISA Crédito</TypeCard>
          <PaymentText>**** **** **** 9100 20/2032</PaymentText>
        </CardNameAndFlag>
      </Box>
      <ChangeButton>
        <ChangeButtonText>Alterar</ChangeButtonText>
      </ChangeButton>
    </AlignContentAndButton>
  </InfoPaymentContent>
);

export default InfoPaymentConfirmPlan;
