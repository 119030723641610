import React from 'react';
import AuthRoutes from '../../AuthRoutes';

const Auth: React.FC = (): JSX.Element => {
  return (
    <main>
      <AuthRoutes />
    </main>
  );
};

export default Auth;
