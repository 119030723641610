import { Box, styled, Typography } from "@mui/material";

import { ReactComponent as EmptyPromotion } from "../../../assets/images/emptyTemplate.svg";

interface TypographyProp {
  item: boolean | undefined;
}

export const StyledCard = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 230,
  width: "100%",
  "@media only screen and (max-width: 1400px)": {
    height: 180,
  },
  "@media only screen and (max-width: 1200px)": {
    height: 180,
    width: "100%",
  },
}));
export const StyledCardContainer = styled(Box)(() => ({}));

export const StyledImage = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media only screen and (max-width: 1200px)": {
    "& svg": {
      paddingTop: 10,
      width: 80,
    },
  },
}));

export const StyledEmptyTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "item",
})<TypographyProp>(({ theme, item }) => ({
  fontSize: 14,
  fontFamily: "Rubik",
  fontWeight: 400,
  display: "baseline",
  color: theme.palette.neutral.dark1,
  textAlign: "center",
  paddingTop: 20,
  width: item ? 500 : 300,
  "@media only screen and (max-width: 1400px)": {
    fontSize: item ? 10 : 14,
    width: item ? 300 : 300,
  },
  "@media only screen and (max-width: 1200px)": {
    fontSize: item ? 10 : 12,
  },
}));

export const EmptyPromotions = styled(EmptyPromotion)(() => ({
  "@media only screen and (max-width: 1400px)": {
    width: 60,
    height: 100,
  },
}));
