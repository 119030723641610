import React from "react";
import { ReactComponent as LogoImage } from "../../assets/images/sideNavLogo.svg";
import TopBarLogoInterface from "./interfaces/top-bar-logo.interface";
import { LogoContainer } from "./styles";

const TopBarLogo: React.FC<TopBarLogoInterface> = ({
  onClick,
}): JSX.Element => {
  const topClicked = (event: any): void => {
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <LogoContainer>
      <LogoImage onClick={topClicked} />
    </LogoContainer>
  );
};

export default TopBarLogo;
