import * as yup from 'yup';
import { unMaskReais } from '../../../../utils/mask.util';

yup.addMethod<yup.StringSchema>(
	yup.string,
	'moreThan0',
	function (errorMessage) {
		return this.test(`test-value`, errorMessage, function (value) {
			const { path, createError } = this;
			return (
				unMaskReais(value) !== 0 || createError({ path, message: errorMessage })
			);
		});
	}
);

const VALIDATION_DELIVERY_SCHEMA = yup.object({
	data: yup.array().of(
		yup.object().shape({
			districtId: yup.string().required('Campo Obrigatório'),
			name: yup.string().required('Campo Obrigatório'),
			price: yup.string().required('Campo Obrigatório'),
			deliveryTime: yup
				.string()
				.required('Campo Obrigatório')
				.moreThan0('O tempo deve ser maior que 0'),
			deliveryPermission: yup.boolean().required('Campo Obrigatório'),
		})
	),
});

export default VALIDATION_DELIVERY_SCHEMA;
