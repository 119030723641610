import CloseIcon from "@mui/icons-material/Close";
import { DialogActions } from "@mui/material";
import React from "react";
import { ReactComponent as ErrorIcon } from "../../../assets/images/imgModalError.svg";
import { ReactComponent as ImgIcon } from "../../../assets/images/imgModalIcon.svg";
import { ReactComponent as SucessIcon } from "../../../assets/images/imgModalSucess.svg";
import { ImageModalProps } from "./interface/image-modal.interface";
import {
  StyledButton,
  StyledDialog,
  StyledDialogContent,
  StyledIconButton,
  StyledImageInformation,
  StyledImageInformationSubtitle,
  StyledImageInformationTitle,
  StyledInformationText,
  StyledModalSubtitle,
  StyledModalTitle,
  StyledModalTitleContainer,
  StyledTitleContainer,
  StyledTypography,
} from "./styles";

const ImageModal: React.FC<ImageModalProps> = ({
  openPromotionImageModal,
  handleClosePromotionImageModal,
}: ImageModalProps): JSX.Element => {
  return (
    <StyledDialog
      open={openPromotionImageModal}
      onClose={handleClosePromotionImageModal}
      fullWidth
      maxWidth="md"
    >
      <StyledIconButton onClick={handleClosePromotionImageModal}>
        <CloseIcon />
      </StyledIconButton>
      <StyledModalTitleContainer>
        <ImgIcon />
        <StyledTitleContainer>
          <StyledModalTitle>Imagem do produto</StyledModalTitle>
          <StyledModalSubtitle>
            Use nossas dicas para escolher a melhor foto
          </StyledModalSubtitle>
        </StyledTitleContainer>
      </StyledModalTitleContainer>
      <StyledDialogContent>
        <StyledImageInformation>
          <StyledImageInformationTitle>Faça</StyledImageInformationTitle>
          <StyledImageInformationSubtitle>
            Como escolher a melhor foto
          </StyledImageInformationSubtitle>
          <StyledInformationText>
            <SucessIcon />
            Use imagens com boa qualidade (até 1 mb)
          </StyledInformationText>
          <StyledInformationText>
            <SucessIcon />
            Use imagens com boa iluminação
          </StyledInformationText>
          <StyledInformationText>
            <SucessIcon />
            Use imagens reais dos seus produtos
          </StyledInformationText>
          <StyledInformationText>
            <SucessIcon />
            Use fotos com fundos brancos/transparentes
          </StyledInformationText>
        </StyledImageInformation>
        <StyledImageInformation>
          <StyledImageInformationTitle>Não Faça</StyledImageInformationTitle>
          <StyledImageInformationSubtitle>
            Quais imagens evitar do seu produto
          </StyledImageInformationSubtitle>
          <StyledInformationText>
            <ErrorIcon />
            Usar imagens com textos
          </StyledInformationText>
          <StyledInformationText>
            <ErrorIcon />
            Usar imagens com a logo
          </StyledInformationText>
          <StyledInformationText>
            <ErrorIcon />
            Montagem de diversos produtos
          </StyledInformationText>
          <StyledInformationText>
            <ErrorIcon />
            Usar foto da fachada da sua loja
          </StyledInformationText>
        </StyledImageInformation>
      </StyledDialogContent>
      <DialogActions>
        <StyledButton onClick={handleClosePromotionImageModal}>
          <StyledTypography>Ok, entendi</StyledTypography>
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default ImageModal;
