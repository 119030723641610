import { IconButton } from '@mui/material';
import { AxiosError } from 'axios';
import { Formik, FormikValues } from 'formik';
import _ from 'lodash';
import 'moment/locale/pt-br';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ReactComponent as MagnifyingGlassIcon } from '../../assets/images/magnifyingGlass.svg';
import { IAuth } from '../../auth/interface/auth.interface';
import { IReducers } from '../../auth/store/store';
import DashboardTopBar from '../../components/DashboardTopBar/DashboardTopBar';
import CreatePromotionForm from '../../components/Forms/Promotion/CreatePromotionForm/CreatePromotionForm';
import ModalSucess from '../../components/Promotion/ModalSucess/ModalSucess';
import PromotionConfirmModal from '../../components/Promotion/PromotionConfirmModal/PromotionConfirmModal';
import PromotionList from '../../components/Promotion/PromotionList/PromotionList';
import { handleParams } from '../../shared/helpers/handleParams';
import { getAdvertiserPromotionByName } from '../../utils/api/get/getAdvertiserPromotionByName';
import { getPromotion } from '../../utils/api/get/getPromotion';
import { getTags } from '../../utils/api/get/getTags';
import {
	ConfirmModalProps,
	ITag,
	LocationState,
} from '../../utils/api/interfaces';
import { NewPromotionProps } from '../Dashboard/interface';
import CREATE_PROMOTION_INITIAL_VALUES from './constants/createPromotion-initialValues';
import CREATE_PROMOTION_FIELDS_VALIDATION_SCHEMA from './constants/createPromotion-validationSchema';
import { PromotionParams } from './interface/promotion-params.interface';
import {
	PromotionStatusTab,
	PromotionStatusTabs,
	PromotionTab,
	PromotionTabs,
	StyledCreatePromotion,
	StyledListPromotionContainer,
	StyledPromotion,
	StyledPromotionContainer,
	StyledPromotionContent,
	StyledPromotionStatusTabsContainer,
	StyledPromotionStatusTopContainer,
	StyledPromotionTopBar,
	StyledSearchPromotion,
	StyledSearchTextField,
	StyledTabsContainer,
} from './styles';

const Promotion: React.FC = (): JSX.Element => {
	const location = useLocation();
	const tab = (location.state as LocationState)?.tab ?? 0;
	const [selectedTab, setSelectedTab] = useState(tab);
	const [params, setParams] = useState<PromotionParams>({
		page: 1,
		perPage: 12,
	} as PromotionParams);
	const [selectedPromotionStatusTab, setSelectedPromotionStatusTab] =
		useState(0);
	const [tags, setTags] = useState<ITag[]>([]);
	const [promotion, setPromotion] = useState<NewPromotionProps[]>([]);
	const [confirmModalProps, setConfirmModalProps] =
		useState<ConfirmModalProps>();
	const [total, setTotal] = useState(0);
	const [loaderTotal, setLoaderTotal] = useState(true);
	const [promotionName, setPromotionName] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [promotionListLoader, setPromotionListLoader] = useState(true);
	const [openPromotionSucessModal, setOpenPromotionSucessModal] =
		useState(false);
	const [openPromotionConfirmModal, setOpenPromotionConfirmModal] =
		useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { advertiser_id }: IAuth = useSelector(
		(state: IReducers) => state.auth
	);

	const handlePromotionStatusList = (
		currentPage = 1,
		newValue: number
	): void => {
		setCurrentPage(currentPage);
		setSelectedPromotionStatusTab(newValue);
		const params: PromotionParams = { page: currentPage, perPage: 12 };
		setParams(handleParams(newValue, params));
	};

	const handleClosePromotionModal = (): void =>
		setOpenPromotionSucessModal(false);

	const handleOpenPromotionModal = (): void =>
		setOpenPromotionSucessModal(true);

	const handleClosePromotionConfirmModal = (): void =>
		setOpenPromotionConfirmModal(false);

	const handleOpenPromotionConfirmModal = (): void => {
		setOpenPromotionConfirmModal(true);
	};
	const handleTabChange = (
		event: React.SyntheticEvent,
		newValue: number
	): void => setSelectedTab(newValue);

	const handleCurrentPageChange = (
		event: React.ChangeEvent<unknown>,
		value: number
	): void => handlePromotionStatusList(value, selectedPromotionStatusTab);

	const handlePromotionStatusTabChange = (
		event: React.SyntheticEvent,
		newValue: number
	): void => handlePromotionStatusList(1, newValue);

	const handleSearch = (): void => {
		setLoaderTotal(true);
		setPromotionListLoader(true);
		getAdvertiserPromotionByName(advertiser_id, promotionName, params)
			.then((res) => {
				setPromotion(res?.data);
				setLoaderTotal(false);
				setPromotionListLoader(false);
			})
			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	const handleSearchChange = (value: string): void => {
		setPromotionName(value);
		debounce(value);
	};

	const debounce = useCallback(
		_.debounce(async (promotionName: string): Promise<void> => {
			setCurrentPage(1);
			if (promotionName) {
				setLoaderTotal(true);
				setPromotionListLoader(true);
				getAdvertiserPromotionByName(advertiser_id, promotionName, {
					...params,
					page: 1,
				}).then((res) => {
					setPromotion(res?.data);
					setTotal(res?.total);
					setTotalPages(res?.last_page);
					setLoaderTotal(false);
					setPromotionListLoader(false);
				});
			} else {
				getPromotionList({ ...params, page: 1 });
			}
		}, 500),
		[params, setParams, currentPage, setCurrentPage]
	);

	const getPromotionByName = (params: PromotionParams) => {
		setLoaderTotal(true);
		setPromotionListLoader(true);
		getAdvertiserPromotionByName(advertiser_id, promotionName as string, params)
			.then((res) => {
				setPromotion(res?.data);
				setTotal(res?.total);
				setTotalPages(res?.last_page);
				setLoaderTotal(false);
				setPromotionListLoader(false);
			})
			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	const getPromotionList = (params: PromotionParams): void => {
		setLoaderTotal(true);
		setPromotionListLoader(true);
		getPromotion(advertiser_id, params)
			.then((res) => {
				setPromotion(res?.data);
				setTotal(res?.total);
				setTotalPages(res?.last_page);
				setPromotionListLoader(false);
				setLoaderTotal(false);
			})
			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	const handleCreatePromotion = (
		values: FormikValues,
		setSubmit: (isSubmitting: boolean) => void,
		resetForm: any
	): void => {
		setConfirmModalProps({ resetForm, values, setSubmit });
		handleOpenPromotionConfirmModal();
		setSubmit(false);
	};

	const getPromotionTags = (): void => {
		getTags()
			.then((res) => {
				setTags(res?.data);
			})
			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			getPromotionTags();
		}
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			if (promotionName) {
				getPromotionByName({ ...params, page: currentPage });
			} else {
				getPromotionList(params);
			}
			return () => {
				isMounted = false;
			};
		}
	}, [params, setParams, selectedTab, setSelectedTab]);

	return (
		<StyledPromotion>
			<StyledPromotionContainer>
				<StyledPromotionTopBar>
					<DashboardTopBar
						title={'Promoções'}
						text={'Crie e gerencie suas ofertas'}
					/>
				</StyledPromotionTopBar>
				<StyledPromotionContent>
					<StyledTabsContainer>
						<PromotionTabs
							value={selectedTab}
							onChange={handleTabChange}
							TabIndicatorProps={{
								style: {
									display: 'none',
									height: 0,
								},
							}}
						>
							<PromotionTab label="Minhas promoções" />
							<PromotionTab label="Criar promoção" />
						</PromotionTabs>
					</StyledTabsContainer>
					{selectedTab === 0 && (
						<StyledListPromotionContainer>
							<StyledPromotionStatusTopContainer>
								<StyledPromotionStatusTabsContainer>
									<PromotionStatusTabs
										value={selectedPromotionStatusTab}
										onChange={handlePromotionStatusTabChange}
										TabIndicatorProps={{
											style: {
												display: 'none',
											},
										}}
									>
										<PromotionStatusTab label="Todas" />
										<PromotionStatusTab label="Ativas" />
										<PromotionStatusTab label="Desativadas" />
									</PromotionStatusTabs>
								</StyledPromotionStatusTabsContainer>
								<StyledSearchPromotion>
									<StyledSearchTextField
										InputLabelProps={{ shrink: false }}
										placeholder="Pesquisar promoção..."
										value={promotionName}
										onChange={(e) => handleSearchChange(e.target.value)}
										InputProps={{
											endAdornment: (
												<IconButton onClick={handleSearch}>
													<MagnifyingGlassIcon />
												</IconButton>
											),
										}}
									/>
								</StyledSearchPromotion>
							</StyledPromotionStatusTopContainer>
							{selectedPromotionStatusTab === 0 && (
								<PromotionList
									total={total}
									promotion={promotion}
									totalPages={totalPages}
									currentPage={currentPage}
									handleCurrentPageChange={handleCurrentPageChange}
									loader={promotionListLoader}
									loaderTotal={loaderTotal}
								/>
							)}
							{selectedPromotionStatusTab === 1 && (
								<PromotionList
									total={total}
									promotion={promotion}
									totalPages={totalPages}
									currentPage={currentPage}
									handleCurrentPageChange={handleCurrentPageChange}
									loader={promotionListLoader}
									loaderTotal={loaderTotal}
								/>
							)}
							{selectedPromotionStatusTab === 2 && (
								<PromotionList
									total={total}
									promotion={promotion}
									totalPages={totalPages}
									currentPage={currentPage}
									handleCurrentPageChange={handleCurrentPageChange}
									loader={promotionListLoader}
									loaderTotal={loaderTotal}
								/>
							)}
						</StyledListPromotionContainer>
					)}
					{selectedTab === 1 && (
						<StyledCreatePromotion>
							<Formik
								enableReinitialize
								initialValues={CREATE_PROMOTION_INITIAL_VALUES}
								validationSchema={CREATE_PROMOTION_FIELDS_VALIDATION_SCHEMA}
								onSubmit={(values, { setSubmitting, resetForm }) => {
									handleCreatePromotion(values, setSubmitting, resetForm);
								}}
							>
								{({ handleSubmit }) => (
									<form
										onSubmit={(e) => {
											e.preventDefault();
											handleSubmit();
										}}
										style={{ width: '100%' }}
									>
										<CreatePromotionForm tags={tags} />
									</form>
								)}
							</Formik>
							{openPromotionSucessModal && (
								<ModalSucess
									handleClosePromotionModal={handleClosePromotionModal}
									openPromotionSucessModal={openPromotionSucessModal}
									setSelectedTab={setSelectedTab}
									modalType={'sucess'}
									modalTitle={'Promoção criada com sucesso!'}
									modalText={
										'Você pode acessar e editar os dados dessa promoção em Promoções > Minhas Promoções.'
									}
									modalButtonText={'Ver minhas promoções'}
								/>
							)}

							{confirmModalProps && (
								<PromotionConfirmModal
									handleClosePromotionConfirmModal={
										handleClosePromotionConfirmModal
									}
									openPromotionConfirmModal={openPromotionConfirmModal}
									resetForm={confirmModalProps.resetForm}
									values={confirmModalProps.values}
									setSubmit={confirmModalProps.setSubmit}
									handleOpenPromotionModal={handleOpenPromotionModal}
								/>
							)}
						</StyledCreatePromotion>
					)}
				</StyledPromotionContent>
			</StyledPromotionContainer>
		</StyledPromotion>
	);
};

export default Promotion;
