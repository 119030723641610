import { AxiosError } from "axios";
import { Formik, FormikValues } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import CREATE_ANSWER_VALIDATION_SCHEMA from "../../pages/Comment/constants/createAnswer-validationSchema";
import { deleteAnswerComment } from "../../utils/api/delete/deleteAnswerComment";
import { answerComment } from "../../utils/api/post/answerComment";
import { editAnswerComment } from "../../utils/api/put/editAnswerComment";
import { dateDaysFormat } from "../../utils/mask.util";
import { ILastCommentsWithAnswersProps } from "../Dashboard/LastCommentsCard/interface/lastComments-data.interface";
import AnswerInput from "../Forms/Comments/AnswerInput";
import DeleteAnswerModal from "./DeleteAnswerModal/DeleteAnswerModal";
import {
  AnswerContainer,
  StyledAdvertiserAnswerContainer,
  StyledAnswerContainer,
  StyledAvatar,
  StyledComment,
  StyledContainer,
  StyledDot,
  StyledGreenDot,
  StyledGreenText,
  StyledIconsContainer,
  StyledImageContainer,
  StyledLastComment,
  StyledLastCommentContent,
  StyledLastCommentText,
  StyledPenIcon,
  StyledPromotionDetailsName,
  StyledPromotionImage,
  StyledPromotionName,
  StyledPromotionTitle,
  StyledTrashIcon,
  StyledUserImage,
} from "./styles";

const LastCommentsWithAnswers: React.FC<ILastCommentsWithAnswersProps> = ({
  data: {
    comment,
    created_at,
    promotion,
    commentAnswer,
    id,
    profile,
    was_read,
  },
  promotionDetails,
  getLastCommentsByAdvertiser,
}: ILastCommentsWithAnswersProps): JSX.Element => {
  const [editCommentAnswer, setEditCommentAnswer] = useState(false);
  const [openDeleteAnswerModal, setOpenDeleteAnswerModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseDeleteAnswerModal = (): void =>
    setOpenDeleteAnswerModal(false);

  const handleOpenDeleteAnswerModal = (): void =>
    setOpenDeleteAnswerModal(true);

  const handleCloseEditComment = (): void => setEditCommentAnswer(false);

  const handleEditComment = (): void => setEditCommentAnswer(true);

  const handleAnswerComment = (
    values: FormikValues,
    setSubmit: (isSubmitting: boolean) => void
  ): void => {
    setSubmit(true);
    const { newCommentAnswer } = values;

    if (!editCommentAnswer) {
      answerComment(newCommentAnswer, id)
        .then(() => {
          setSubmit(false);
          getLastCommentsByAdvertiser ? getLastCommentsByAdvertiser() : "";
          enqueueSnackbar(" Comentário realizado com sucesso!.", {
            variant: "success",
            autoHideDuration: 1500,
          });
        })
        .catch((err: AxiosError) => {
          setSubmit(false);
          if (err) {
            enqueueSnackbar("Ocorreu um erro tente novamente.", {
              variant: "error",
              autoHideDuration: 1500,
            });
          }
        });
      return;
    }

    editAnswerComment(newCommentAnswer, commentAnswer.id)
      .then(async () => {
        setSubmit(false);
        getLastCommentsByAdvertiser ? await getLastCommentsByAdvertiser() : "";
        enqueueSnackbar(" Comentário editado com sucesso!.", {
          variant: "success",
          autoHideDuration: 1500,
        });
      })
      .catch((err: AxiosError) => {
        setSubmit(false);
        if (err) {
          enqueueSnackbar("Ocorreu um erro tente novamente.", {
            variant: "error",
            autoHideDuration: 1500,
          });
        }
      })
      .finally(() => {
        handleCloseEditComment();
      });
    return;
  };

  const handleDeleteAnswerComment = (): void => {
    deleteAnswerComment(commentAnswer.id)
      .then(async () => {
        getLastCommentsByAdvertiser ? await getLastCommentsByAdvertiser() : "";
        handleCloseDeleteAnswerModal();
        enqueueSnackbar(" Comentário deletado com sucesso!.", {
          variant: "success",
          autoHideDuration: 1500,
        });
      })
      .catch((err: AxiosError) => {
        if (err) {
          enqueueSnackbar("Ocorreu um erro tente novamente.", {
            variant: "error",
            autoHideDuration: 1500,
          });
        }
      });
  };
  return (
    <StyledLastComment>
      <StyledLastCommentContent>
        <StyledImageContainer>
          <StyledUserImage>
            <img src={profile.avatar} />
          </StyledUserImage>

          <StyledPromotionImage>
            <img src={promotion.image.url} />
          </StyledPromotionImage>
        </StyledImageContainer>
        <StyledLastCommentText>
          <StyledContainer>
            <StyledPromotionDetailsName>
              {profile.name}
            </StyledPromotionDetailsName>
            <StyledPromotionTitle>
              Comentou em &nbsp;
              <StyledPromotionName> {promotion.title}</StyledPromotionName>
              &nbsp;• {dateDaysFormat(String(created_at))}
            </StyledPromotionTitle>
          </StyledContainer>
          <StyledComment promotionDetails={promotionDetails}>
            {comment}
          </StyledComment>
          <StyledAdvertiserAnswerContainer>
            {commentAnswer && (
              <AnswerContainer edit={editCommentAnswer}>
                <StyledAvatar>
                  <img
                    src={commentAnswer.profile.avatar}
                    alt="Avatar do usuario"
                  />
                </StyledAvatar>
                <StyledAnswerContainer>
                  <StyledPromotionDetailsName>
                    {commentAnswer.profile.name}
                    <StyledPromotionTitle>
                      &nbsp;• {dateDaysFormat(String(commentAnswer.updated_at))}
                    </StyledPromotionTitle>
                  </StyledPromotionDetailsName>
                  <StyledComment promotionDetails={promotionDetails}>
                    {commentAnswer.answer}
                  </StyledComment>
                </StyledAnswerContainer>
                <StyledIconsContainer>
                  <StyledPenIcon onClick={handleEditComment} />
                  <StyledTrashIcon onClick={handleOpenDeleteAnswerModal} />
                </StyledIconsContainer>
              </AnswerContainer>
            )}

            {(!commentAnswer || editCommentAnswer) && (
              <>
                <Formik
                  initialValues={{
                    newCommentAnswer: commentAnswer?.answer ?? "",
                  }}
                  validationSchema={CREATE_ANSWER_VALIDATION_SCHEMA}
                  onSubmit={(values, { setSubmitting }) => {
                    handleAnswerComment(values, setSubmitting);
                  }}
                >
                  {({ handleSubmit }) => (
                    <form
                      id="answerForm"
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      style={{ width: "calc(100% - 150px)" }}
                    >
                      <AnswerInput edit={editCommentAnswer} />
                    </form>
                  )}
                </Formik>
              </>
            )}
          </StyledAdvertiserAnswerContainer>
        </StyledLastCommentText>
        {!was_read && (
          <StyledDot>
            <StyledGreenDot /> <StyledGreenText>Novo</StyledGreenText>
          </StyledDot>
        )}
      </StyledLastCommentContent>
      {openDeleteAnswerModal && (
        <DeleteAnswerModal
          handleCloseDeleteAnswerModal={handleCloseDeleteAnswerModal}
          handleDeleteAnswerComment={handleDeleteAnswerComment}
          openDeleteAnswerModal={openDeleteAnswerModal}
        />
      )}
    </StyledLastComment>
  );
};

export default LastCommentsWithAnswers;
