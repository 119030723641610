import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { getCouponsListByAdvertiser } from "../../../../utils/api/get/getCouponsListByAdvertiser";
import { getUseCoupon } from "../../../../utils/api/get/getUseCoupon";
import { dateFullFormat } from "../../../../utils/mask.util";
import CouponConfirmModal from "../../CouponConfirmModal/CouponConfirmModal";
import { CouponCardProps } from "./interface/promotion-data.interface";
import {
  StyledCalendarIcon,
  StyledCheckIcon,
  StyledCouponInformationContainer,
  StyledCouponListCard,
  StyledIconContainer,
  StyledImageContainer,
  StyledInformationContainer,
  StyledInformationContainerText,
  StyledInformationText,
  StyledInformationTitle,
  StyledPromotionImage,
  StyledPromotionTitle,
  StyledPromotionTitleTypography,
  StyledTagIcon,
  StyledUseCouponButton,
  StyledUseCouponButtonContainer,
  StyledUserImage,
  StyledUsername,
} from "./styles";

const CouponListCard: React.FC<CouponCardProps> = ({
  data,
  setCoupon,
  params,
  setCouponListLoader,
}): JSX.Element => {
  const [openConfirmCouponModal, setOpenConfirmCouponModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleCloseConfirmCouponModal = (): void =>
    setOpenConfirmCouponModal(false);

  const handleOpenConfirmCouponModal = (): void =>
    setOpenConfirmCouponModal(true);

  const getCouponList = (): void => {
    setCouponListLoader(true);
    getCouponsListByAdvertiser(params)
      .then((res) => {
        setCoupon(res.data);
        setCouponListLoader(false);
      })
      .catch((err: AxiosError) => {
        if (err) {
          enqueueSnackbar("Ocorreu um erro tente novamente.", {
            variant: "error",
            autoHideDuration: 1500,
          });
        }
      });
  };

  const handleRedeemCoupon = (): void => {
    getUseCoupon(data.coupon_name)
      .then(() => {
        enqueueSnackbar(" Cupom utilizado com sucesso!.", {
          variant: "success",
          autoHideDuration: 1500,
        });
        handleCloseConfirmCouponModal();
        getCouponList();
      })
      .catch(() =>
        enqueueSnackbar("Cupom já utilizado.", {
          variant: "error",
          autoHideDuration: 1500,
        })
      );
  };
  return (
    <StyledCouponListCard>
      <StyledImageContainer>
        <StyledUserImage>
          <img src={data?.user.profile.avatar} />
        </StyledUserImage>

        <StyledPromotionImage>
          <img src={data?.coupon_batch.promotion.image.url} />
        </StyledPromotionImage>
      </StyledImageContainer>
      <StyledCouponInformationContainer>
        <StyledPromotionTitle>
          <StyledPromotionTitleTypography>
            {data.coupon_batch.promotion.title}
          </StyledPromotionTitleTypography>
        </StyledPromotionTitle>
        <StyledUsername>
          Resgatado por <b>{data.user.profile.name}</b>
        </StyledUsername>
      </StyledCouponInformationContainer>
      <StyledInformationContainer>
        <StyledIconContainer>
          <StyledCalendarIcon />
        </StyledIconContainer>
        <StyledInformationContainerText>
          <StyledInformationTitle>Resgatado em:</StyledInformationTitle>
          <StyledInformationText>
            {dateFullFormat(data.created_at)}
          </StyledInformationText>
        </StyledInformationContainerText>
      </StyledInformationContainer>
      {data.is_used && (
        <StyledInformationContainer>
          <StyledIconContainer>
            <StyledCheckIcon />
          </StyledIconContainer>

          <StyledInformationContainerText>
            <StyledInformationTitle>Utilizado em:</StyledInformationTitle>
            <StyledInformationText>
              {dateFullFormat(data.updated_at)}
            </StyledInformationText>
          </StyledInformationContainerText>
        </StyledInformationContainer>
      )}
      <StyledInformationContainer>
        <StyledIconContainer>
          <StyledTagIcon />
        </StyledIconContainer>
        <StyledInformationContainerText>
          <StyledInformationTitle>Código do cupom</StyledInformationTitle>
          <StyledInformationText>{data.coupon_name}</StyledInformationText>
        </StyledInformationContainerText>
      </StyledInformationContainer>
      {!data.is_used && (
        <StyledUseCouponButtonContainer>
          <StyledUseCouponButton onClick={handleOpenConfirmCouponModal}>
            Confirmar uso
          </StyledUseCouponButton>
        </StyledUseCouponButtonContainer>
      )}
      {openConfirmCouponModal && (
        <CouponConfirmModal
          handleCloseConfirmCouponModal={handleCloseConfirmCouponModal}
          handleConfirmCoupon={handleRedeemCoupon}
          openConfirmCouponModal={openConfirmCouponModal}
          promotionTitle={data.coupon_batch.promotion.title}
        />
      )}
    </StyledCouponListCard>
  );
};
export default CouponListCard;
