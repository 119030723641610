import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { DeleteAnswerModalProps } from "./interface/modalDeleteAnswer.interface";
import {
  StyledButtonsContainer,
  StyledCancelButton,
  StyledDialog,
  StyledDisableButton,
  StyledErrorCheckIcon,
  StyledIconButton,
  StyledModalText,
  StyledModalTitle,
} from "./styles";

const DeleteAnswerModal: React.FC<DeleteAnswerModalProps> = ({
  handleCloseDeleteAnswerModal,
  openDeleteAnswerModal,
  handleDeleteAnswerComment,
}: DeleteAnswerModalProps): JSX.Element => (
  <StyledDialog
    open={openDeleteAnswerModal}
    onClose={handleCloseDeleteAnswerModal}
    fullWidth
    maxWidth="sm"
  >
    <StyledIconButton onClick={handleCloseDeleteAnswerModal}>
      <CloseIcon />
    </StyledIconButton>
    <StyledErrorCheckIcon />
    <StyledModalTitle>Excluir comentário?</StyledModalTitle>
    <StyledModalText>
      Ao confirmar, sua resposta ao comentário será deletada. Essa ação não
      poderá ser desfeita.
    </StyledModalText>
    <StyledButtonsContainer>
      <StyledCancelButton onClick={handleCloseDeleteAnswerModal}>
        Cancelar
      </StyledCancelButton>
      <StyledDisableButton onClick={handleDeleteAnswerComment}>
        Confirmar
      </StyledDisableButton>
    </StyledButtonsContainer>
  </StyledDialog>
);

export default DeleteAnswerModal;
