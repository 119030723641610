import { DataStatsProps } from "../../../components/Dashboard/DashboardChart/interface/chart-data.interface";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";

export const getRamsomedCouponsByWeek = async (
  promotionid: string
): Promise<DataStatsProps> => {
  try {
    const response = await request({
      url: `${BASE_URL}/clients/promotion/${promotionid}/stats/coupons`,
      method: "get",
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
