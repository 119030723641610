import { Box, styled, Tab, Tabs } from '@mui/material';
import { ReactComponent as CouponIcon } from '../../assets/images/couponIconFilled.svg';

interface StyledTabProps {
	label: string;
}

export const StyledCoupons = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
	zIndex: 0,
}));

export const StyledCouponsContainer = styled(Box)(({ theme }) => ({
	display: 'block',
	backgroundColor: theme.palette.neutral.light2,
	width: '100%',
}));

export const StyledCouponsTopBar = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
}));

export const StyledCouponsContent = styled(Box)(() => ({
	display: 'grid',
	gridTemplateColumns: 'repeat(4, 320px) 400px',
	gridTemplateRows: '0.4fr 2fr',
	gap: '40px 25px',
	height: 'calc(100% - 200px)',
	padding: '40px 44px 40px 44px',
	'@media only screen and (max-width: 1600px)': {
		gridTemplateColumns: 'repeat(4, 260px) 320px ',
	},
	'@media only screen and (max-width: 1440px)': {
		gridTemplateColumns: 'repeat(4, 225px) 300px ',
	},
	'@media (max-width: 1200px)': {
		gridTemplateColumns: 'repeat(4, 140px) 190px ',
		gridTemplateRows: '150px auto',
		padding: '24px 20px 32px 24px',
		gap: 15,
	},
}));

export const StyledCouponFirstStatusDisplay = styled(Box)(() => ({
	gridRow: 1,
	gridColumn: 1,
}));

export const StyledCouponSecondStatusDisplay = styled(Box)(() => ({
	gridRow: 1,
	gridColumn: 2,
}));
export const StyledCouponThirdStatusDisplay = styled(Box)(() => ({
	gridRow: 1,
	gridColumn: 3,
}));
export const StyledCouponFourthStatusDisplay = styled(Box)(() => ({
	gridRow: 1,
	gridColumn: 4,
}));
export const StyledCouponManager = styled(Box)(({ theme }) => ({
	display: 'block',
	boxSizing: 'border-box',
	gridRow: 1,
	gridColumn: 5,
	backgroundColor: theme.palette.primary.main,
	height: '100%',
	width: '100%',
	borderRadius: 12,
	padding: '24px 21px 20px 21px',
}));

export const StyledCouponManagerTitleContainer = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	marginBottom: 24,
}));

export const StyledCouponIconContainer = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: 36,
	height: 36,
}));

export const CouponIconSVG = styled(CouponIcon)(({ theme }) => ({
	color: theme.palette.neutral.light,
	width: 24,
	height: 24,
	marginRight: 8,
}));

export const StyledCouponManagerButton = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	height: 44,
	backgroundColor: theme.palette.neutral.light,
	color: theme.palette.primary.main,
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 700,
	lineHeight: '20px',
	borderRadius: 8,
	cursor: 'pointer',
	'@media only screen and (max-width: 1500px)': {
		fontSize: 12,
		lineHeight: '16px',
	},
}));

export const StyledCouponManagerTextContainer = styled(Box)(() => ({
	display: 'block',
}));

export const StyledCouponManagerTitle = styled(Box)(({ theme }) => ({
	display: 'flex',
	color: theme.palette.neutral.light,
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 500,
	lineHeight: '24px',
	'@media only screen and (max-width: 1500px)': {
		fontSize: 14,
		lineHeight: '20px',
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 12,
		lineHeight: '18px',
	},
}));

export const StyledCouponManagerSubTitle = styled(Box)(({ theme }) => ({
	display: 'flex',
	color: theme.palette.neutral.dark2,
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	lineHeight: '20px',
	'@media only screen and (max-width: 1500px)': {
		fontSize: 12,
		lineHeight: '14px',
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 10,
		lineHeight: '12px',
	},
}));

export const StyledCouponPromotionList = styled(Box)(({ theme }) => ({
	display: 'block',
	boxSizing: 'border-box',
	gridRow: 2,
	gridColumn: '1 / 6',
	backgroundColor: theme.palette.neutral.light,
	borderRadius: 20,
	boxShadow: '4px 6px 15px rgba(0, 0, 0, 0.1);',
	padding: '36px 40px 28px 40px',
	'@media only screen and (max-width: 1500px)': {
		height: 650,
	},
	'@media only screen and (max-width: 1200px)': {
		height: 'unset',
		padding: '26px 20px 26px 20px',
	},
}));

export const StyledCouponsPromotionStatusTabsContainer = styled(Box)(() => ({
	display: 'flex',
	height: 52,
	justifyContent: 'space-between',
	marginBottom: 40,
	'@media (max-width: 1200px)': {
		height: 48,
	},
}));

export const CouponsPromotionStatusTabs = styled(Tabs)({
	display: 'flex',
});

export const CouponsPromotionStatusTab = styled((props: StyledTabProps) => (
	<Tab disableRipple {...props} />
))(({ theme }) => ({
	textTransform: 'none',
	fontSize: 18,
	fontWeight: 500,
	marginRight: 12,
	color: theme.palette.primary.main,
	borderRadius: 8,
	fontFamily: 'Rubik',
	border: '1px solid',
	borderColor: theme.palette.neutral.dark2,
	'&.Mui-selected': {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.neutral.light,
	},
	'@media (max-width: 1200px)': {
		fontSize: 14,
		padding: '8px 14px',
	},
}));
