import React from 'react';

import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowUpIcon } from '../../../assets/images/arrowUpIcon.svg';
import { ReactComponent as PigIcon } from '../../../assets/images/pigIcon.svg';
import NavButton from '../../Buttons/NavButton/NavButton';
import { StyledButtonBox } from '../Introduction/styles';
import {
	NotebookSVG,
	StyeledCardsContainer,
	StyledCard,
	StyledCardImg,
	StyledCardText,
	StyledCardTitle,
	StyledContainer,
	StyledSvgBox,
	StyledText,
	StyledTextContainer,
	StyledTextMobile,
	StyledTitleGreenText,
	StyledTitleText,
} from './styles';

const OurPlans: React.FC = (): JSX.Element => {
	const navigate = useNavigate();
	return (
		<StyledContainer>
			<StyledTextContainer>
				<StyledTitleGreenText>
					A 1° MENSALIDADE É POR NOSSA CONTA!
				</StyledTitleGreenText>
				<StyledTitleText>Nosso plano leva sua loja mais longe!</StyledTitleText>

				<StyledText>Desfrute das melhores condições do mercado</StyledText>
				<StyledTextMobile>
					Desfrute das melhores condições do mercado
				</StyledTextMobile>
				<StyeledCardsContainer>
					<StyledCard>
						<StyledCardImg>
							<PigIcon />
						</StyledCardImg>
						<StyledCardTitle>VENDA MAIS, PAGUE MENOS</StyledCardTitle>
						<StyledCardText>
							Venda mais de R$ 1.800,00/mês e fique <b>isento da mensalidade</b>{' '}
							de R$ 79,90!
						</StyledCardText>
					</StyledCard>
					<StyledCard>
						<StyledCardImg>
							<ArrowUpIcon />
						</StyledCardImg>
						<StyledCardTitle>A MENOR TAXA DO MERCADO</StyledCardTitle>
						<StyledCardText>
							Apenas 10% por pedido de comissão, simples assim!
						</StyledCardText>
					</StyledCard>
				</StyeledCardsContainer>
				<StyledButtonBox onClick={() => navigate('/sign-up')}>
					<NavButton>Cadastrar loja</NavButton>
				</StyledButtonBox>
			</StyledTextContainer>
			<StyledSvgBox>
				<NotebookSVG />
			</StyledSvgBox>
		</StyledContainer>
	);
};

export default OurPlans;
