import React from 'react';
import Benefits from '../../components/LandingPage/Benefits/Benefits';
import BottomMenu from '../../components/LandingPage/BottomMenu/BottomMenu';
import CommomQuestions from '../../components/LandingPage/CommomQuestions/CommomQuestions';
import DownloadApp from '../../components/LandingPage/DownloadApp/DownloadApp';
import FirstAnnouncement from '../../components/LandingPage/FirstAnnouncement/FirstAnnouncement';
import Footer from '../../components/LandingPage/Footer/Footer';
import Introduction from '../../components/LandingPage/Introduction/Introduction';
import OurPlans from '../../components/LandingPage/OurPlans/OurPlans';
import SecondAnnouncement from '../../components/LandingPage/SecondAnnouncement/SecondAnnouncement';
import ThirdAnnouncement from '../../components/LandingPage/ThirdAnnouncement/ThirdAnnouncement';
import TopBar from '../../components/LandingPage/TopBar/TopBar';
import {
	EighthGrid,
	FifthGrid,
	FirstGrid,
	FirstGridFirstColumn,
	FirstGridSecondColumn,
	FourthGrid,
	IntroductionImgSVG,
	LandingPageContainer,
	NavBarGrid,
	NinthGrid,
	SecondGrid,
	SeventhGrid,
	StyledIntroductionImgSVG,
	TenthGrid,
	ThirdGrid,
} from './styles';

const LandingPage: React.FC = (): JSX.Element => {
	return (
		<LandingPageContainer id="form">
			<NavBarGrid>
				<TopBar />
			</NavBarGrid>
			<FirstGrid>
				<FirstGridFirstColumn>
					<Introduction />
				</FirstGridFirstColumn>
				<FirstGridSecondColumn>
					<IntroductionImgSVG />
					<StyledIntroductionImgSVG />
				</FirstGridSecondColumn>
			</FirstGrid>
			<SecondGrid>
				<FirstAnnouncement />
			</SecondGrid>
			<ThirdGrid>
				<SecondAnnouncement />
			</ThirdGrid>
			<FourthGrid>
				<ThirdAnnouncement />
				<Benefits />
			</FourthGrid>
			<FifthGrid id="Plans">
				<OurPlans />
			</FifthGrid>
			<SeventhGrid id="downloadApp">
				<DownloadApp />
			</SeventhGrid>
			<EighthGrid id="commomQuestions">
				<CommomQuestions />
			</EighthGrid>
			<NinthGrid>
				<BottomMenu />
			</NinthGrid>
			<TenthGrid>
				<Footer />
			</TenthGrid>
		</LandingPageContainer>
	);
};

export default LandingPage;
