import { Box, Typography, styled } from '@mui/material';

export const StyledPromotionHeader = styled(Box)(() => ({
	display: 'flex',
	position: 'relative',
	maxHeight: 186,
	height: 186,
	width: '100%',
	borderRadius: 10,
	justifyContent: 'center',
	overflow: 'hidden',
	'& img': {
		objectFit: 'inherit',
	},
	'@media only screen and (max-width: 1500px)': {
		height: 180,
	},
	'@media only screen and (max-width: 1200px)': {
		height: 150,
	},
}));

export const StyledUploadHeaderEditButton = styled(Box)(() => ({
	display: 'flex',
	height: 40,
	justifyContent: 'end',
	alignItems: 'center',
	cursor: 'pointer',
	marginRight: 20,
	marginTop: 12,
	'@media only screen and (max-width: 1200px)': {
		width: 64,
		height: 30,
	},
}));

export const StyledEditHeaderContainer = styled(Box)(() => ({
	width: '100%',
	height: '100%',
	position: 'absolute',
	background: '#00000099',
}));

export const Input = styled('input')({
	display: 'none',
});

export const StyledPromotionAvatar = styled(Box)(() => ({
	width: 128,
	height: 90,
	borderRadius: '50%',
	position: 'relative',
	marginRight: 16,
	overflow: 'hidden',
	'& img': {
		objectFit: 'contain',
	},
	'@media only screen and (max-width: 1200px)': {
		width: 70,
		height: 70,
	},
}));

export const StyledUploadAvatarRemoveButton = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	cursor: 'pointer',
	'@media only screen and (max-width: 1200px)': {
		width: 64,
		height: 30,
	},
}));

export const StyledUploadAvatarEditButton = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	cursor: 'pointer',
	border: '3px solid',
	color: '#F1F1F1',
	width: '96%',
	height: '96%',
	borderRadius: '50%',
	'@media only screen and (max-width: 1200px)': {
		width: 64,
		height: 30,
	},
}));
export const StyledPromotionProfileExample = styled(Box)(({ theme }) => ({
	display: 'block',
	backgroundColor: theme.palette.neutral.light,
	position: 'relative',
	width: '100%',
	zIndex: 1,
}));

export const StyledProfileFieldsContainer = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
	padding: '20px 0px 0px 0px',
	'@media only screen and (max-width: 1200px)': {
		padding: '50px 50px 18px 50px',
	},
}));

export const StyledProfileFields = styled(Box)(() => ({
	display: 'block',
	width: '100%',
}));

export const StyledProfileName = styled(Box)(() => ({
	fontSize: 20,
	lineHeight: '24px',
	fontFamily: 'Rubik',
	fontWeight: 300,
	wordBreak: 'break-word',
	textAlign: 'start',
	height: 24,
	maxWidth: 300,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	WebkitLineClamp: '1',
	WebkitBoxOrient: 'vertical',
	display: '-webkit-box',
	'@media only screen and (max-width: 1500px)': {
		fontSize: 16,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 14,
	},
}));

export const StyledProfileDescription = styled(Box)(({ theme }) => ({
	fontSize: 16,
	lineHeight: '20px',
	fontFamily: 'Rubik',
	fontWeight: 300,
	wordBreak: 'break-word',
	color: theme.palette.neutral.dark1,
	textAlign: 'start',
	height: 64,
	maxHeight: 64,
	maxWidth: 438,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	WebkitLineClamp: '3',
	WebkitBoxOrient: 'vertical',
	display: '-webkit-box',
	'@media only screen and (max-width: 1500px)': {
		fontSize: 12,
	},
	'@media only screen and (max-width: 1200px)': {
		height: 44,
		maxHeight: 44,
	},
}));

export const StyledEditProfile = styled(Box)(({ theme }) => ({
	display: 'flex',
	lineHeight: '20px',
	justifyContent: 'end',
	width: 'max-content',
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 500,
	textDecoration: 'underline',
	paddingRight: 10,
	color: theme.palette.primary.main,
	cursor: 'pointer',
	'@media only screen and (max-width: 1500px)': {
		fontSize: 14,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 12,
	},
}));

export const StyledUploadFileContent = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	height: '100%',
	cursor: 'pointer',
}));
export const StyledEmptyImage = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginRight: 12,
}));
export const StyledUploadFileTypography = styled(Typography)(({ theme }) => ({
	textAlign: 'center',
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
	// border: '1px dashed',
	// borderColor: theme.palette.neutral.dark2,
	padding: 16,
	backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='grey' stroke-width='1' stroke-dasharray='10%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
	borderRadius: 8,

	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 10,
	},
}));
