import { AxiosResponse } from "axios";
import { request } from "../base-request/base-request";
import { CepProps } from "../interfaces";

export const getViaCep = async (
  cep: string
): Promise<AxiosResponse<CepProps>> => {
  try {
    return request({
      url: `https://viacep.com.br/ws/${cep}/json`,
      method: "get",
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
