import { AxiosResponse } from "axios";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";

export const getCouponHistoryByPromotion = async (
  promotionId: string
): Promise<AxiosResponse> => {
  try {
    const response = await request({
      url: `${BASE_URL}/coupons/historic/promotion/${promotionId}`,
      method: "get",
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
