import { Box, IconButton, styled, Typography } from "@mui/material";

interface EmptyCardSize {
  promotionDetails: boolean | undefined;
}
export const StyledLastComment = styled(Box)(({ theme }) => ({
  display: "flex",
  whiteSpace: "nowrap",
  padding: "12px 25px 12px 12px",
  borderTop: "1px solid",
  borderColor: theme.palette.neutral.light2,
  justifyContent: "space-between",
  "@media only screen and (max-width: 1200px)": {
    padding: 0,
    paddingTop: 3,
  },
}));

export const StyledLastCommentContent = styled(Box)(() => ({
  display: "flex",
}));
export const StyledAvatar = styled(Box)(() => ({
  display: "flex",
  width: 40,
  height: 40,
  borderRadius: "50%",
  overflow: "hidden",
  "& img": {
    height: "100%",
    width: "100%",
  },

  "@media only screen and (max-width: 1400px)": {
    height: 27,
    minWidth: 27,
    borderRadius: 0,
    paddingLeft: 5,
    paddingTop: 5,
  },
}));
export const StyledLastCommentText = styled(Box)(() => ({
  display: "block",
  marginLeft: 12,
}));
export const StyledTitle = styled(Box)(() => ({
  display: "flex",
  whiteSpace: "nowrap",
  width: 540,
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

export const StyledTitleSkeleton = styled(Box)(() => ({
  display: "flex",
  whiteSpace: "nowrap",
  width: 540,
  overflow: "hidden",
  textOverflow: "ellipsis",
  "@media only screen and (max-width: 1400px)": {
    width: 292,
  },
}));
export const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.neutral.dark1,
}));
export const StyledPromotionName = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.neutral.dark,
  overflow: "hidden",
  textOverflow: "ellipsis",
}));
export const StyledComment = styled(Box)(({ theme }) => ({
  width: 540,
  fontSize: 12,
  fontFamily: "Rubik",
  fontWeight: 400,
  color: theme.palette.neutral.dark,
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

export const StyledCommentSkeleton = styled(Box)(({ theme }) => ({
  width: 540,
  fontSize: 12,
  fontFamily: "Rubik",
  fontWeight: 400,
  color: theme.palette.neutral.dark,
  overflow: "hidden",
  textOverflow: "ellipsis",
  "@media only screen and (max-width: 1400px)": {
    width: 292,
  },
}));

export const StyledDot = styled(Box)(() => ({
  position: "relative",
  float: "right",
  top: 10,
}));

export const StyledTitleContainer = styled(Box)(() => ({
  display: "flex",
}));
export const StyledCommentsTitleTypography = styled(Typography)(
  ({ theme }) => ({
    fontSize: 18,
    fontFamily: "Rubik",
    fontWeight: 600,
    color: theme.palette.primary.dark,
    textAlign: "left",
    "@media only screen and (max-width: 1400px)": {
      fontSize: 14,
    },
  })
);

export const StyledChevronIcon = styled(Box)(() => ({
  marginRight: 8,
  marginTop: 3,
  cursor: "pointer",
}));

export const StyledLastCommentsTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 36,
  width: "calc(100% - 64px)",
  borderBottom: "1px solid",
  borderColor: theme.palette.neutral.light2,
  padding: "0px 32px 0px 32px",
  justifyContent: "space-between",
}));
export const StyledLastCommentsTitleTypography = styled(Typography)(
  ({ theme }) => ({
    fontSize: 18,
    fontFamily: "Rubik",
    fontWeight: 500,
    color: theme.palette.primary.dark,
    textAlign: "left",
  })
);

export const StyledLastCommentsList = styled(Box)(({ theme }) => ({
  display: "block",
  margin: "28px 32px 0px 32px",
  borderRadius: 8,
  border: "1px solid",
  borderColor: theme.palette.neutral.light2,
  "& > :first-of-type": {
    borderTop: 0,
  },
  "@media only screen and (max-width: 1400px)": {
    padding: "8px 16px 8px 8px",
    overflowY: "auto",
    overflowX: "hidden",
    height: 300,
  },
}));

export const StyledImage = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  paddingTop: 48,
}));

export const StyledEmptyTypography = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontFamily: "Rubik",
  fontWeight: 400,
  display: "baseline",
  color: theme.palette.neutral.dark1,
  textAlign: "center",
  paddingTop: 20,
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 8,
  top: 8,
  color: theme.palette.neutral.dark1,
}));

export const StyledEmptyCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== "promotionDetails",
})<EmptyCardSize>(({ promotionDetails }) => ({
  display: "flex",
  alignItems: "center",
  height: promotionDetails ? 562 : "100%",
  "@media only screen and (max-width: 1400px)": {
    height: "unset",
  },
}));
