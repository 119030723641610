import { Box, Typography, styled } from '@mui/material';

interface ListItemStyledProp {
	isUnderlined: boolean;
}

export const Container = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	width: '165px',
	height: '129px',
	marginLeft: '2%',
	borderRadius: '8px',
	border: `1px solid ${theme.palette.neutral.light2}`,
}));

export const Title = styled(Typography)(({ theme }) => ({
	fontSize: '14px',
	fontWeight: 500,
	fontFamily: 'Rubik',
	color: theme.palette.common.black,
}));
export const Subtitle = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'isUnderlined',
})<ListItemStyledProp>(({ theme, isUnderlined }) => ({
	fontSize: '14px',
	fontWeight: 400,
	fontFamily: 'Rubik',
	color: theme.palette.neutral.dark1,
	textDecoration: isUnderlined ? 'underline' : 'none',
	cursor: isUnderlined ? 'pointer' : 'pointer-events',
}));

export const Time = styled(Typography)(({ theme }) => ({
	fontSize: '14px',
	fontWeight: 400,
	fontFamily: 'Rubik',
	color: theme.palette.neutral.dark1,
}));
