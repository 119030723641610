import { Box, TextField, Typography, styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

export const StyledStoreFinance = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
}));

export const StyledStoreFinanceContainer = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.neutral.light2,
	width: '100%',
	height: 'fit-content',
}));

export const StyledStoreFinanceTopBar = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
}));

export const StyledStoreFinanceContentContainer = styled(Box)(() => ({
	maxHeight: 'calc(100% - 188px)',
	padding: '32px 44px 36px 60px',
	'@media only screen and (max-width: 1200px)': {
		padding: '24px 20px 32px 24px',
	},
}));

export const StyledStoreFinanceContent = styled(Box)(({ theme }) => ({
	display: 'block',
	backgroundColor: theme.palette.neutral.light,
}));

export const StyledContainer = styled(Box)(() => ({
	display: 'block',
	padding: '36px',
}));

export const StyledTitleContainer = styled(Box)(() => ({
	display: 'block',
	width: '100%',
	marginBottom: 36,
}));

export const StyledTitle = styled(Typography)(() => ({
	fontSize: 20,
	fontWeight: 500,
	lineHeight: '24px',
	marginBottom: 8,
}));

export const StyledSubtitle = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontWeight: 400,
	lineHeight: '20px',
	color: theme.palette.neutral.dark1,
}));

export const StyledMonthTextField = styled(TextField)(({ theme }) => ({
	width: 185,
	marginLeft: 15,

	'& .MuiInputBase-input': {
		paddingLeft: '12px!important',
		color: theme.palette.primary.main,
		fontWeight: 500,
		fontSize: 16,
		textTransform: 'capitalize',
		cursor: 'pointer',
		marginRight: -20,
	},

	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'& .MuiFormLabel-root': {
		['& .MuiInputLabel-root']: {
			lineHeight: '0px',
		},
	},
}));

export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
	'& .MuiInputBase-input': {
		paddingLeft: '12px!important',
		color: theme.palette.primary.main,
		fontWeight: 500,
		fontSize: 16,
		textTransform: 'capitalize',
	},

	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'& .MuiCalendarPicker-root': {
		display: 'none',
	},
	'& .MuiTypography-root': {
		['& .MuiTypography-root']: {
			backgorundColor: 'transparent!important',
		},
	},
	'& .MuiFormLabel-root': {
		['& .MuiInputLabel-root']: {
			lineHeight: '0px',
		},
	},
}));

export const StyledDetails = styled(Box)(({ theme }) => ({
	marginLeft: 15,
	fontSize: 16,
	fontWeight: 500,
	lineHeight: '24px',
	color: theme.palette.primary.main,
	textDecoration: 'underline',
	wordWrap: 'break-word',
	whiteSpace: 'nowrap',
	fontFamily: 'Rubik',
	paddingTop: 4,
}));
