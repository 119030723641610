import { Box, Button, styled, Typography } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  display: "list-item",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.neutral.light2,
}));

export const StyledDashboardTopBar = styled(Box)(() => ({
  display: "flex",
  width: "100%",
}));

export const ContainerContent = styled(Box)(({ theme }) => ({
  width: "93%",
  height: "72%",
  marginTop: "80px",
  marginLeft: "44px",
  borderRadius: "20px",
  backgroundColor: theme.palette.neutral.light,
}));

export const AlignTitleAndButton = styled(Box)(() => ({
  display: "flex",
  height: "80px",
  justifyContent: "space-between",
  marginLeft: "55px",
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontFamily: "Rubik",
  fontWeight: 500,
  marginTop: "24px",
  color: theme.palette.common.black,
}));

export const ButtonClose = styled(Button)(() => ({
  width: "5px",
  height: "5px",
  marginTop: "18px",
}));
