import {
	Box,
	Button,
	Dialog,
	IconButton,
	Typography,
	styled,
} from '@mui/material';

export const StyledDialog = styled(Dialog)(() => ({
	'& .MuiPaper-root': {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 15,
		padding: '20px 40px 48px 40px',
		height: '60%',
		width: 'fit-content',
		maxWidth: '80%',
		minWidth: '45%',

		'& .MuiDialogContent-root': {
			display: 'flex',
		},

		'& .MuiDialogActions-root': {
			display: 'flex',
		},
	},
}));
export const StyledIconButton = styled(IconButton)(({ theme }) => ({
	position: 'absolute',
	right: 8,
	top: 8,
	color: theme.palette.neutral.dark1,
}));

export const StyledButtonContainer = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
	height: 52,
	width: 323,
	borderRadius: 8,
	marginTop: 44,
	backgroundColor: theme.palette.primary.main,
	padding: '12px 32px',
	'&:hover': {
		backgroundColor: theme.palette.primary.main,
	},
	'@media only screen and (max-width: 1400px)': {
		width: 200,
		marginTop: 18,
		height: 40,
	},
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
	textTransform: 'none',
	width: '100%',
	fontSize: 16,
	color: theme.palette.neutral.light,
	fontFamily: 'Rubik',
	fontWeight: 700,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
	},
}));
