import { AxiosResponse } from 'axios';
import { BASE_URL } from '../api';
import { request } from '../base-request/base-request';

export const newRefreshToken = async (
	refresh_token: string
): Promise<AxiosResponse<any>> => {
	try {
		const response = await request({
			url: `${BASE_URL}/login/refresh-token`,
			data: { refresh_token },
			method: 'POST',
		});
		return response;
	} catch (error) {
		return Promise.reject(error);
	}
};
