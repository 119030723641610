import { AxiosResponse } from 'axios';
import { BASE_URL } from '../api';
import { request } from '../base-request/base-request';

export const getFilteredOrders = async (
	queryString: string
): Promise<AxiosResponse<any>> => {
	try {
		const response = await request({
			url: `${BASE_URL}/orders/filters?${queryString}`,
			method: 'get',
		});
		return response;
	} catch (error) {
		return Promise.reject(error);
	}
};
