import { ADD_AUTH, REMOVE_AUTH } from '../constants/auth-config.constants';
import { AuthAction, IAuth } from '../interface/auth.interface';

const initialState: IAuth = {
	advertiser_id: '',
};

const authReducer = (
	state: IAuth = initialState,
	action: AuthAction
): IAuth => {
	switch (action.type) {
		case ADD_AUTH: {
			return {
				...state,
				...action.payload,
			};
		}

		case REMOVE_AUTH: {
			return initialState;
		}

		default:
			return state;
	}
};

export default authReducer;
