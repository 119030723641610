import * as yup from 'yup';
import { unMaskReais } from '../../../utils/mask.util';

yup.addMethod<yup.StringSchema>(
	yup.string,
	'moreThan0',
	function (errorMessage) {
		return this.test(`test-value`, errorMessage, function (value) {
			const { path, createError } = this;
			return (
				unMaskReais(value) !== 0 || createError({ path, message: errorMessage })
			);
		});
	}
);

export const CREATE_ITEM_VALIDATION_SCHEMA = [
	yup.object().shape({
		categoryId: yup.string().required('Campo obrigatório'),
		name: yup.string().required('Campo obrigatório.'),
		description: yup.string().required('Campo obrigatório.'),
		price: yup
			.string()
			.moreThan0('Deve ser maior que 0')
			.required('Campo obrigatório.'),
	}),

	yup.object().shape({
		hasOptionGroup: yup.boolean().required(),
	}),

	yup.object().shape({
		optionGroups: yup.array().when('hasOptionGroup', {
			is: true,
			then: yup.array().of(
				yup.object().shape({
					name: yup.string().required('Campo obrigatório.'),
					minimum: yup.number(),
					maximum: yup.number(),
					optional: yup.boolean(),
				})
			),
		}),
	}),

	yup.object().shape({
		optionGroups: yup.array().when('hasOptionGroup', {
			is: true,
			then: yup.array().of(
				yup.object().shape({
					options: yup.array().of(
						yup.object().shape({
							name: yup.string().required('Campo obrigatório.'),
						})
					),
				})
			),
		}),
	}),
];
