import { CircularProgress } from '@mui/material';
import ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import App from './App';
import { persistedStore, storeConfig } from './auth/store/store';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import {
	axiosInterceptors,
	axiosResponseInterceptors,
} from './utils/api/base-request/base-request';

axiosInterceptors(storeConfig);
axiosResponseInterceptors(storeConfig);

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container as HTMLElement);
root.render(
	<Provider store={storeConfig}>
		<PersistGate loading={<CircularProgress />} persistor={persistedStore}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</PersistGate>
	</Provider>
);

reportWebVitals();
