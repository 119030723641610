import { AxiosResponse } from 'axios';
import { BASE_URL } from '../api';
import { request } from '../base-request/base-request';
import { ICurrentPayments } from '../interfaces';

export const getCurrentPayments = async (
	id: string | undefined
): Promise<AxiosResponse<ICurrentPayments>> => {
	try {
		const response = await request({
			url: `${BASE_URL}/clients/cardBannerAndPayments/list/${id}`,
			method: 'get',
		});
		return response;
	} catch (error) {
		return Promise.reject(error);
	}
};
