import OrderStatusCard from '../OrderStatusCard/OrdersStatusCard';
import SkeletonCard from '../SekeletonCard/SkeletonCard';
import { IChartProps } from '../interfaces/interfaces';

const TotalOrderChart: React.FC<IChartProps> = ({
	loading,
	stats,
	detailsData,
	handleFooterText,
	handleFoooterIcon,
}: IChartProps): JSX.Element => {
	return (
		<>
			{loading ? (
				<SkeletonCard />
			) : (
				stats && (
					<OrderStatusCard
						text="Valor dos pedidos"
						value={stats.totOrdersValue}
						handleFoooterIcon={handleFoooterIcon(
							detailsData?.stats.totOrdersValuePercentage
						)}
						money={true}
						descending={
							stats.totOrdersValuePercentage < 0 ||
							stats.totOrdersValuePercentage === 0
						}
						footerText={handleFooterText(
							detailsData?.stats.totOrdersValuePercentage
						)}
						tooltip="Esse é o valor total de todos os pedidos nos últimos 30 dias."
					/>
				)
			)}
		</>
	);
};

export default TotalOrderChart;
