import { IDistricts } from '../../../components/Menu/DeliveryModal/interface/modalDisablePromotion.interface';
import { BASE_URL } from '../api';
import { request } from '../base-request/base-request';

export const getCityDistricts = async (): Promise<IDistricts[]> => {
	try {
		const response = await request({
			url: `${BASE_URL}/districts`,
			method: 'get',
		});
		return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
