import { Box, styled, Typography } from "@mui/material";

export const Texts = styled(Box)(() => ({
  width: "90%",
  marginTop: "35px",
  marginLeft: "54px",
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.common.black,
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 400,
  color: theme.palette.neutral.dark1,
}));

export const PromotionAndHighlights = styled(Box)(() => ({
  display: "flex",
  width: "90%",
  height: "165px",
  marginLeft: "54px",
  marginTop: "31px",
  justifyContent: "space-between",
}));

export const CardPromotion = styled(Box)(({ theme }) => ({
  width: "392px",
  height: "154px",
  borderRadius: "16px",
  border: `1px solid ${theme.palette.primary.contrastText}`,
}));

export const CardTitle = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "88%",
  marginLeft: "24px",
  marginTop: "20px",
  justifyContent: "space-between",
}));

export const TextTitle = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.primary.dark,
}));

export const StatusCard = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "104px",
  height: "36px",
  borderRadius: "30px",
  backgroundColor: theme.palette.neutral.dark2,
}));

export const StatusText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.neutral.dark1,
}));

export const CardContent = styled(Box)(() => ({
  display: "flex",
  width: "88%",
  alignItems: "center",
  marginLeft: "24px",
  marginTop: "20px",
}));

export const TotalAndQuantityUsed = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.common.black,
}));

export const CardFooter = styled(Box)(() => ({
  display: "flex",
  width: "88%",
  alignItems: "center",
  marginLeft: "24px",
}));

export const CardFooterText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.common.black,
}));

export const HighlightCard = styled(Box)(({ theme }) => ({
  width: "392px",
  height: "154px",
  borderRadius: "16px",
  border: `1px solid ${theme.palette.primary.contrastText}`,
}));

export const PromotionAndStoreOnHomeScreen = styled(Box)(() => ({
  display: "flex",
  width: "90%",
  marginLeft: "54px",
  marginTop: "15px",
  justifyContent: "space-between",
}));

export const PromotionOnHomeScreen = styled(Box)(({ theme }) => ({
  width: "392px",
  height: "130px",
  borderRadius: "8px",
  border: `1px solid ${theme.palette.primary.contrastText}`,
}));

export const IconsAndNameOnCard = styled(Box)(() => ({
  display: "flex",
  marginTop: "25px",
  marginLeft: "24px",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const AlignIconAndName = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const TitleCard = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 500,
  marginLeft: "8px",
  color: theme.palette.common.black,
}));

export const ContentCard = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginTop: "12px",
  marginLeft: "24px",
}));

export const TextContentCard = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 400,
  color: theme.palette.common.black,
}));

export const UnderlineStyleButtonText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  textDecorationLine: "underline",
  textTransform: "initial",
  fontWeight: 400,
  color: theme.palette.primary.main,
}));

export const StoreOnHomeScreen = styled(Box)(({ theme }) => ({
  width: "392px",
  height: "130px",
  borderRadius: "8px",
  border: `1px solid ${theme.palette.primary.contrastText}`,
}));
