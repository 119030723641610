import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import InputContentAddressIfSelected from "../InputContentAddressIfSelected/InputContentAddressIfSelected";
import InputContentPaymentIfSelected from "../InputContentPaymentIfSelected/InputContentPaymentIfSelected";
import { IPaymentInformation } from "../interfaces";

import {
  AlignLabelAndRadio,
  ButtonText,
  Container,
  ContentAndButtonAlignment,
  CreditCardIcon,
  EditButton,
  FirstInputAddress,
  FirstInputCard,
  InitialContent,
  InputContent,
  LabelInput,
  SaveButton,
  SecondInputAddress,
  SecondInputCard,
  SubTitle,
  Text,
  Title,
} from "./styles";

const PaymentInformation: React.FC<IPaymentInformation> = ({
  valueAddress,
  valueCard,
  setValueCard,
  setValueAddress,
  handleChangeAdress,
  handleChangeCard,
  editingAddress,
  setEditingAddress,
  editingCard,
  setEditingCard,
}: IPaymentInformation): JSX.Element => (
  <Container>
    <Title>
      <Text>Assinar plano</Text>
    </Title>

    <SubTitle>Endereço de cobrança</SubTitle>
    <FormControl>
      <RadioGroup
        defaultValue="adress"
        value={valueAddress}
        onChange={handleChangeAdress}
      >
        <FirstInputAddress editingAddress={editingAddress}>
          <ContentAndButtonAlignment>
            <InitialContent>
              <FormControlLabel label="" value="adress" control={<Radio />} />
              <LabelInput>Endereço da loja</LabelInput>
              <InputContent>
                Rua Prefeito João Costa, 000, Centro, Unaí, Minas Gerais,
                38610-000
              </InputContent>
            </InitialContent>
            {editingAddress ? (
              <SaveButton onClick={() => setEditingAddress(false)}>
                <ButtonText>Salvar</ButtonText>
              </SaveButton>
            ) : (
              <EditButton onClick={() => setEditingAddress(true)}>
                <ButtonText>Editar</ButtonText>
              </EditButton>
            )}
          </ContentAndButtonAlignment>
          {editingAddress ? <InputContentAddressIfSelected /> : ""}
        </FirstInputAddress>

        <SecondInputAddress valueAddress={valueAddress}>
          <ContentAndButtonAlignment>
            <AlignLabelAndRadio>
              <FormControlLabel
                label=""
                value="addAddress"
                control={<Radio />}
              />
              <LabelInput>Adicionar endereço</LabelInput>
            </AlignLabelAndRadio>
            {valueAddress === "addAddress" ? (
              <SaveButton onClick={() => setValueAddress("address")}>
                <ButtonText>Salvar</ButtonText>
              </SaveButton>
            ) : (
              ""
            )}
          </ContentAndButtonAlignment>
          {valueAddress === "addAddress" ? (
            <InputContentAddressIfSelected />
          ) : (
            ""
          )}
        </SecondInputAddress>
      </RadioGroup>
    </FormControl>

    <SubTitle>Método de pagamento</SubTitle>

    <FormControl>
      <RadioGroup
        defaultValue="card"
        value={valueCard}
        onChange={handleChangeCard}
      >
        <FirstInputCard editingCard={editingCard}>
          <ContentAndButtonAlignment>
            <AlignLabelAndRadio>
              <FormControlLabel label="" value="card" control={<Radio />} />
              <CreditCardIcon />
              <LabelInput>Mastercard 1234</LabelInput>
              <InputContent>**** **** **** 1234 20/2028</InputContent>
            </AlignLabelAndRadio>
            {editingCard ? (
              <SaveButton onClick={() => setEditingCard(false)}>
                <ButtonText>Salvar</ButtonText>
              </SaveButton>
            ) : (
              <EditButton onClick={() => setEditingCard(true)}>
                <ButtonText>Editar</ButtonText>
              </EditButton>
            )}
          </ContentAndButtonAlignment>
          {editingCard ? <InputContentPaymentIfSelected /> : ""}
        </FirstInputCard>

        <SecondInputCard valueCard={valueCard}>
          <ContentAndButtonAlignment>
            <AlignLabelAndRadio>
              <FormControlLabel value="addCard" control={<Radio />} label="" />
              <LabelInput>Adicionar cartão</LabelInput>
            </AlignLabelAndRadio>
            {valueCard === "addCard" ? (
              <SaveButton onClick={() => setValueCard("card")}>
                <ButtonText>Salvar</ButtonText>
              </SaveButton>
            ) : (
              ""
            )}
          </ContentAndButtonAlignment>
          {valueCard === "addCard" ? <InputContentPaymentIfSelected /> : ""}
        </SecondInputCard>
      </RadioGroup>
    </FormControl>
  </Container>
);

export default PaymentInformation;
