import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { ModalConfirmLogoutProps } from "./interfaces/modal-confirm-logout-props.interface";
import {
  StyledButtonsContainer,
  StyledCancelButton,
  StyledDialog,
  StyledDisableButton,
  StyledIconButton,
  StyledLogoutIcon,
  StyledModalText,
  StyledModalTitle,
} from "./styles";

const ConfirmLogoutPage: React.FC<ModalConfirmLogoutProps> = ({
  close,
  confirm,
}: ModalConfirmLogoutProps): JSX.Element => {
  return (
    <>
      <StyledDialog open={true} onClose={close} maxWidth="sm">
        <StyledIconButton onClick={close}>
          <CloseIcon />
        </StyledIconButton>
        <StyledLogoutIcon />
        <StyledModalTitle>Encerrar sessão?</StyledModalTitle>
        <StyledModalText>
          Ao prosseguir com a ação, sua sessão será encerrada e você será
          redirecionado para o login.
        </StyledModalText>
        <StyledButtonsContainer>
          <StyledCancelButton onClick={close}>Cancelar</StyledCancelButton>
          <StyledDisableButton onClick={confirm}>Encerrar</StyledDisableButton>
        </StyledButtonsContainer>
      </StyledDialog>
    </>
  );
};

export default ConfirmLogoutPage;
