import { Box, styled } from '@mui/material';

export const NavBarContainer = styled(Box)(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: '2.9fr 1.5fr',
	width: '100%',
	height: 136,
	backgroundColor: theme.palette.neutral.main2,
	'@media (orientation: portrait)': {
		display: 'flex',
	},
	'@media only screen and (max-width: 1200px)': {
		gridTemplateColumns: '700px 300px',
	},
}));

export const NavBarMenu = styled(Box)(() => ({
	padding: '52px 70px 32px 50px',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	'@media (orientation: portrait)': {
		width: '100%',
		padding: 0,
	},
	'@media only screen and (max-width: 1200px)': {
		padding: '52px 10px 32px 20px',
	},
}));

export const StyledLogo = styled(Box)(() => ({
	marginRight: '5%',
	'@media (orientation: portrait)': {
		display: 'flex',
		justifyContent: 'center',
		margin: '0 auto',
	},
}));
export const StyledMenuItems = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 400,
	fontSize: 18,
	textDecoration: 'none',
	cursor: 'pointer',
	color: theme.palette.neutral.light,
	'@media (orientation: portrait)': {
		display: 'none',
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 16,
	},
}));

export const StyledFirstButton = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 18,
	color: theme.palette.neutral.light,
	cursor: 'pointer',
	textDecoration: 'underline',
	letterSpacing: '0.01em',
	'@media only screen and (max-width: 1200px)': {
		fontSize: 16,
	},
}));
export const StyledSecondButton = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 18,
	cursor: 'pointer',
	color: theme.palette.neutral.light,
	border: '2px solid',
	borderColor: theme.palette.neutral.light,
	borderRadius: 8,
	padding: '16px 32px',
	'@media only screen and (max-width: 1200px)': {
		fontSize: 16,
		padding: '14px 32px',
	},
}));

export const NavBarButtons = styled(Box)(() => ({
	padding: '52px 15% 32px 35%',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	'@media (orientation: portrait)': {
		display: 'none',
	},
	'@media only screen and (max-width: 1200px)': {
		padding: '52px 5% 32px 15%',
	},
}));
