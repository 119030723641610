import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PromotionIcon } from "../../../assets/images/promotionIcon.svg";
import { addNav } from "../../../auth/actions/sideNav.action";
import { IAuth } from "../../../auth/interface/auth.interface";
import { ISideNav } from "../../../auth/interface/sideNav.interface";
import { IReducers } from "../../../auth/store/store";
import { PromotionButtonProps } from "./interface/button-interface";
import {
  StyledButton,
  StyledButtonIcon,
  StyledButtonTypography,
} from "./styles";

const PromotionButton: React.FC<PromotionButtonProps> = ({
  handleOpenDashboardModal,
}: PromotionButtonProps): JSX.Element => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const setIndexOfSideNavButton = (sideNav: ISideNav): void => {
    dispatch(addNav(sideNav));
  };
  const { advertiserComplete }: IAuth = useSelector(
    (state: IReducers) => state.auth
  );

  const toCreatePromotion = (): void => {
    if (!advertiserComplete) {
      handleOpenDashboardModal();
    } else {
      navigate("/promotion", { state: { tab: 1 } });
      setIndexOfSideNavButton({
        showSide: true,
        buttonIndex: 1,
      });
    }
  };

  return (
    <>
      <StyledButton onClick={toCreatePromotion}>
        <StyledButtonIcon>
          <PromotionIcon />
        </StyledButtonIcon>
        <StyledButtonTypography>Criar promoção</StyledButtonTypography>
      </StyledButton>
    </>
  );
};

export default PromotionButton;
