import { IPageButtons } from "../interfaces";
import {
  CancelButton,
  CancelButtonText,
  Container,
  ForwardButton,
  ForwardButtonText,
} from "./styles";

const PageButtons: React.FC<IPageButtons> = ({
  navigateToPlans,
  handleOpen,
}: IPageButtons): JSX.Element => (
  <Container>
    <CancelButton onClick={navigateToPlans}>
      <CancelButtonText>Cancelar</CancelButtonText>
    </CancelButton>
    <ForwardButton onClick={handleOpen}>
      <ForwardButtonText>Assinar plano</ForwardButtonText>
    </ForwardButton>
  </Container>
);

export default PageButtons;
