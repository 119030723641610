import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CommentsParams,
  ILastComments,
} from "../../../pages/Dashboard/interface/lastComments.interface";
import { getCommentsByPromotion } from "../../../utils/api/get/getCommentsByPromotion";
import LastCommentsCard from "../../Dashboard/LastCommentsCard/LastCommentsCard";
import { CommentsModalProps } from "./interface/comments-modal.interface";
import { StyledCommentsModal, StyledDialog, StyledPagination } from "./styles";

const CommentsModal: React.FC<CommentsModalProps> = ({
  handleCloseCommentsModal,
  openCommentsModal,
  id,
  totalComments,
}): JSX.Element => {
  const [comments, setComments] = useState<ILastComments[]>([]);
  const [loaderComments, setLoaderComments] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const params: CommentsParams = { page: currentPage, perPage: 8 };

  const handleCurrentPageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ): void => {
    setCurrentPage(value);
  };

  const getPromotionComments = (): void => {
    getCommentsByPromotion(id, params).then((res) => {
      setComments(res.data);
      setLoaderComments(false);
      setTotalPages(res?.last_page);
    });
  };

  const onCloseModal = (): void => {
    handleCloseCommentsModal();
    setCurrentPage(1);
  };

  useEffect(() => {
    getPromotionComments();
  }, [currentPage]);

  return (
    <StyledDialog
      open={openCommentsModal}
      onClose={onCloseModal}
      fullWidth
      maxWidth="sm"
    >
      <StyledCommentsModal>
        <LastCommentsCard
          comments={comments}
          totalComments={totalComments}
          loader={loaderComments}
          promotionDetails={true}
          handleCloseCommentsModal={onCloseModal}
          getLastCommentsByAdvertiser={getPromotionComments}
        />
      </StyledCommentsModal>
      <StyledPagination>
        <Pagination
          shape="rounded"
          count={totalPages}
          page={currentPage}
          onChange={handleCurrentPageChange}
          color="primary"
          sx={{ margin: "auto" }}
          showFirstButton
          showLastButton
        />
      </StyledPagination>
    </StyledDialog>
  );
};

export default CommentsModal;
