import { Box, styled } from '@mui/material';
import { ReactComponent as NotebookImage } from '../../../assets/images/plansImg.svg';

export const StyledContainer = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
	height: 'inherit',
	alignItems: 'center',
	position: 'relative',
	width: '85%',
	'@media (orientation: portrait)': {
		flexDirection: 'column-reverse',
		width: '100%',
	},
}));

export const StyledTextContainer = styled(Box)(() => ({
	display: 'block',
	maxWidth: '43%',
	'@media (orientation: portrait)': {
		maxWidth: '100%',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

export const StyledTitleGreenText = styled(Box)(({ theme }) => ({
	width: '60%',
	fontFamily: 'Rubik',
	fontWeight: 800,
	fontSize: 12,
	lineHeight: '28px',
	borderRadius: 50,
	color: theme.palette.success.main,
	backgroundColor: theme.palette.success.dark,
	textAlign: 'center',
	marginBottom: 20,
	padding: '4px 6px 4px, 6px',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
		lineHeight: '28px',
	},

	'@media (orientation: portrait)': {
		textAlign: 'center',
		fontSize: 10,
		lineHeight: '28px',
	},
}));

export const StyledTitleText = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 800,
	fontSize: 40,
	lineHeight: '48px',
	color: theme.palette.neutral.light,
	textAlign: 'start',
	marginBottom: 20,
	width: '70%',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 36,
		lineHeight: '44px',
	},

	'@media (orientation: portrait)': {
		textAlign: 'center',
		fontSize: 24,
		lineHeight: '28px',
	},
}));
export const StyledText = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	display: 'flex',
	fontWeight: 400,
	fontSize: 16,
	lineHeight: '24px',
	color: theme.palette.neutral.light,
	width: '100%',
	justifyContent: 'start',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 16,
		lineHeight: '20px',
	},
	'@media only screen and (max-width: 1200px)': {
		whiteSpace: 'nowrap',
	},

	'@media (orientation: portrait)': {
		display: 'none',
		fontSize: 12,
	},
}));

export const StyledTextMobile = styled(Box)(({ theme }) => ({
	display: 'none',
	'@media (orientation: portrait)': {
		display: 'block',
		fontFamily: 'Rubik',
		fontWeight: 800,
		fontSize: 14,
		lineHeight: '20px',
		color: theme.palette.neutral.light,
		textAlign: 'center',
		justifyContent: 'center',
	},
}));

export const NotebookSVG = styled(NotebookImage)(() => ({
	zIndex: 1,
	marginLeft: '50%',

	'@media (orientation: portrait)': {
		width: 300,
		height: 350,
		marginRight: 0,
		marginLeft: 0,
		marginBottom: -25,
	},
}));

export const StyledCard = styled(Box)(() => ({
	display: 'block',
	width: 257,
	paddingTop: '50px',
	paddingBottom: '50px',
	'@media only screen and (max-width: 1400px)': {
		width: '70% ',
		paddingBottom: '60px',
	},
}));
export const StyledCardImg = styled(Box)(() => ({
	display: 'flex',
	marginBottom: 20,
	justifyContent: 'start',
	'@media (orientation: portrait)': {
		justifyContent: 'start',
	},
}));
export const StyledCardTitle = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 18,
	lineHeight: '24px',
	color: theme.palette.neutral.light,
	textAlign: 'start',
	marginBottom: 10,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
		lineHeight: '20px',
	},
	'@media (orientation: portrait)': {
		display: 'flex',
		textAlign: 'start',
	},
}));
export const StyledCardText = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 400,
	fontSize: 14,
	lineHeight: '20px',
	textAlign: 'start',
	color: theme.palette.neutral.light,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
		lineHeight: '26px',
	},
	'@media (orientation: portrait)': {
		textAlign: 'start',
	},
}));

export const StyeledCardsContainer = styled(Box)(() => ({
	display: 'flex',
	boxSizing: 'border-box',
	alignItems: 'center',
	width: '100%',
	height: '75%',
	justifyContent: 'space-between',
	'@media (orientation: portrait)': {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

export const StyledSvgBox = styled(Box)(() => ({
	'@media (orientation: portrait)': {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		marginBottom: '20%',
		marginTop: 110,
	},
}));
