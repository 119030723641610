import { Box, Button, styled as MUIStyled, Typography } from "@mui/material";
import styled from "styled-components";

import { ReactComponent as BiggerGoldIcon } from "../../../assets/images/BiggerGoldIcon.svg";
import { ReactComponent as BiggerPlatinumIcon } from "../../../assets/images/BiggerPlatinumIcon.svg";
import { ReactComponent as BiggerSilverIcon } from "../../../assets/images/BiggerSilverIcon.svg";

interface IButtonChoosePlan {
  indexPlan: number | undefined;
  index: number | undefined;
}

interface ListItemStyledProp {
  isSelected: boolean;
}

export const Container = MUIStyled(Box)(() => ({
  display: "flex",
  paddingTop: "62px",
  paddingLeft: "110px",
}));

export const PlanContent = MUIStyled(Box)(() => ({
  marginLeft: "60px",
}));

export const AlignIconPlan = MUIStyled(Box)(() => ({
  position: "absolute",
  marginLeft: "120px",
  marginTop: "-65px",
}));

export const IconPlanSVG = styled.svg<ListItemStyledProp>`
  path {
    fill: ${({ isSelected }) => (isSelected ? "#FEFEFE" : "#6029B1")};
  }
  rect {
    fill: ${({ isSelected }) => (isSelected ? "#6029B1" : "#F1F1F1")};
  }
`;

export const PlanTypeAndValue = MUIStyled(Box)(() => ({
  position: "absolute",
  width: "300px",
  marginTop: "70px",
  marginLeft: "28px",
}));

export const PlansCardSelectedBackground = styled.svg<IButtonChoosePlan>`
  path {
    stroke: ${({ index, indexPlan }) =>
      index === indexPlan ? "#6029B1" : "#EDE8F4"};
    stroke-width: 1px;
  }
`;

export const PlanCardContent = MUIStyled(Box)(() => ({
  width: "300px",
  paddingTop: "20px",
  height: "370px",
}));

export const ContentTitle = MUIStyled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 500,
  marginTop: "25px",
  color: theme.palette.common.black,
}));

export const AlignNameAndIcon = MUIStyled(Box)(() => ({
  display: "flex",
  alignItems: "baseline",
  width: "300px",
}));

export const ChoosePlanButton = MUIStyled(Button)<IButtonChoosePlan>(
  ({ theme, indexPlan, index }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "169px",
    height: "40px",
    borderRadius: "8px",
    marginLeft: "62px",
    backgroundColor:
      indexPlan === index
        ? theme.palette.neutral.dark2
        : theme.palette.primary.main,
  })
);

export const ChoosePlanButtonText = MUIStyled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 700,
  textTransform: "none",
  color: theme.palette.common.white,
}));

export const FirstBenefit = MUIStyled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  marginTop: "8px",
  marginLeft: "8px",
  fontWeight: 400,
  color: theme.palette.neutral.dark1,
}));

export const TitleAndOldValue = MUIStyled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));
export const Title = MUIStyled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.common.black,
}));
export const OldValue = MUIStyled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 500,
  textDecoration: "line-through",
  color: theme.palette.neutral.dark1,
}));
export const TypeAndNewValue = MUIStyled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));
export const Type = MUIStyled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.common.black,
}));
export const NewValue = MUIStyled(Box)(() => ({
  display: "flex",
}));
export const Value = MUIStyled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.success.main,
}));
export const TextValue = MUIStyled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.neutral.dark1,
}));

export const GoldPlan = MUIStyled(Box)(() => ({
  marginLeft: "60px",
}));

export const PlatinumPlan = MUIStyled(Box)(() => ({
  marginLeft: "70px",
}));

export const AlignSilverIcon = MUIStyled(BiggerSilverIcon)(() => ({
  position: "absolute",
  marginLeft: "150px",
  color: "red",
}));

export const AlignGoldIcon = MUIStyled(BiggerGoldIcon)(() => ({
  position: "absolute",
  marginLeft: "150px",
}));

export const AlignPlatinumIcon = MUIStyled(BiggerPlatinumIcon)(() => ({
  position: "absolute",
  marginLeft: "150px",
}));

export const AlignTalkToUsButton = MUIStyled(Button)(() => ({
  position: "absolute",
  marginLeft: "422px",
  marginTop: "523px",
}));
