import Moment from 'moment';
import 'moment/locale/pt-br';

const initialStartDate = Moment().subtract(1, 'months');

const initialEndDate = Moment();

export const FILTER_ORDERS_INITIAL_VALUES = {
	startDate: initialStartDate,
	endDate: initialEndDate,
	paymentsMethods: [
		{
			paymentsMethod: 'Pix',
			is_active: false,
		},
		{
			paymentsMethod: 'Cartão de crédito',
			is_active: false,
		},
		{
			paymentsMethod: 'Dinheiro',
			is_active: false,
		},
	],
	orderType: [
		{
			type: 'Online',
			text: 'Pago pelo aplicativo dsconto',
			is_active: false,
		},
		{
			type: 'Presencial',
			text: 'Pago na entrega para a loja',
			is_active: false,
		},
	],
	range: [0, 100],
	orderStatus: [
		{
			text: 'Pedido concluído',
			is_active: false,
		},
		{
			text: 'Pedido cancelado',
			is_active: false,
		},
		{
			text: 'Pedido em andamento',
			is_active: false,
		},
	],
};

export default FILTER_ORDERS_INITIAL_VALUES;
