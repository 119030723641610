import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { ErrorMessage, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { AdvertiserProps } from '../../../utils/api/interfaces';
import { celularMask, cnpjMask } from '../../../utils/mask.util';
import {
  FormBodyHeader,
  FormContainer,
  FormTermsLink,
  FormTermsStyled,
  FormTermsTextStyled,
  FormTextInline,
  FormTextStyled,
  StyledErrorMessage,
  StyledFormButton,
  StyledFormSubText,
  StyledFormText,
  StyledTermsBox,
} from './styles';

const customTextFieldStyles = () => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 2,
		height: 52,
		fontFamily: 'Rubik',
		'@media only screen and (max-width: 1400px)': {
			fontSize: 14,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'@media (orientation: portrait)': {
		'& .MuiFormHelperText-root': {
			fontSize: '10px!important',
		},
	},
});

const formHelperStyle = {
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 400,
};

const SignUpForm: React.FC = (): JSX.Element => {
	const [showPassword, setShowPassword] = useState(false);

	const {
		values: { name, cnpj, cellphone, email, password, confirmPassword, tandc },
		handleChange,
		touched,
		errors,
		setFieldValue,
	} = useFormikContext<AdvertiserProps>();

	return (
		<FormContainer>
			<FormBodyHeader rowGap={1} display="flex" flexDirection="column">
				<StyledFormText>
					Cadastre sua loja e<br></br> ganhe 30 dias grátis!
				</StyledFormText>
				<StyledFormSubText>
					A primeira mensalidade é por nossa conta
				</StyledFormSubText>
			</FormBodyHeader>
			<FormTextInline>
				<FormTextStyled>
					<FormControl fullWidth>
						<TextField
							value={name}
							label="Nome da sua Loja"
							variant="outlined"
							onChange={handleChange('name')}
							error={touched.name && !!errors.name}
							helperText={touched.name && errors.name}
							FormHelperTextProps={{
								style: formHelperStyle,
							}}
							sx={customTextFieldStyles}
						/>
					</FormControl>
				</FormTextStyled>
				<FormTextStyled>
					<FormControl fullWidth>
						<TextField
							label="CNPJ"
							value={cnpjMask(cnpj)}
							variant="outlined"
							onChange={handleChange('cnpj')}
							error={touched.cnpj && !!errors.cnpj}
							helperText={touched.cnpj && errors.cnpj}
							inputProps={{
								maxLength: 18,
							}}
							FormHelperTextProps={{
								style: formHelperStyle,
							}}
							sx={customTextFieldStyles}
						/>
					</FormControl>
				</FormTextStyled>
			</FormTextInline>
			<FormTextInline>
				<FormTextStyled>
					<FormControl fullWidth>
						<TextField
							label="Celular"
							value={celularMask(cellphone)}
							variant="outlined"
							onChange={handleChange('cellphone')}
							error={touched.cellphone && !!errors.cellphone}
							helperText={touched.cellphone && errors.cellphone}
							inputProps={{
								maxLength: 15,
								height: 23,
							}}
							FormHelperTextProps={{
								style: formHelperStyle,
							}}
							sx={customTextFieldStyles}
						/>
					</FormControl>
				</FormTextStyled>
				<FormTextStyled>
					<FormControl fullWidth>
						<TextField
							label="Email"
							value={email}
							variant="outlined"
							onChange={handleChange('email')}
							error={touched.email && !!errors.email}
							helperText={touched.email && errors.email}
							FormHelperTextProps={{
								style: formHelperStyle,
							}}
							sx={customTextFieldStyles}
						/>
					</FormControl>
				</FormTextStyled>
			</FormTextInline>

			<FormTextInline>
				<FormTextStyled>
					<FormControl fullWidth>
						<TextField
							type={showPassword ? 'text' : 'password'}
							value={password}
							label="Senha"
							variant="outlined"
							onChange={handleChange('password')}
							error={touched.password && !!errors.password}
							helperText={touched.password && errors.password}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="mostrar senha"
											edge="end"
											onClick={() => setShowPassword(!showPassword)}
										>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								),
							}}
							FormHelperTextProps={{
								style: formHelperStyle,
							}}
							sx={customTextFieldStyles}
						/>
					</FormControl>
				</FormTextStyled>
				<FormTextStyled>
					<FormControl fullWidth>
						<TextField
							label="Confirme sua senha"
							value={confirmPassword}
							type={showPassword ? 'text' : 'password'}
							variant="outlined"
							onChange={handleChange('confirmPassword')}
							error={touched.confirmPassword && !!errors.confirmPassword}
							helperText={touched.confirmPassword && errors.confirmPassword}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="mostrar senha"
											edge="end"
											onClick={() => setShowPassword(!showPassword)}
										>
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								),
							}}
							FormHelperTextProps={{
								style: formHelperStyle,
							}}
							sx={customTextFieldStyles}
						/>
					</FormControl>
				</FormTextStyled>
			</FormTextInline>
			<StyledTermsBox>
				<FormTermsStyled>
					<Checkbox
						value={tandc}
						color="primary"
						onChange={() => setFieldValue('tandc', !tandc)}
					/>
					<FormTermsTextStyled>Eu concordo com os &nbsp;</FormTermsTextStyled>
					<FormTermsLink> Termos de Uso e Privacidade.</FormTermsLink>
				</FormTermsStyled>
				<StyledErrorMessage>
					<ErrorMessage name="tandc" />
				</StyledErrorMessage>
			</StyledTermsBox>
			<StyledFormButton type="submit" variant="contained">
				Cadastrar Loja
			</StyledFormButton>
		</FormContainer>
	);
};
export default SignUpForm;
