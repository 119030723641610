import { Box, styled } from '@mui/material';
import { ReactComponent as Logo } from '../../../assets/images/logo-d$scontoWhite.svg';
import { ReactComponent as NotebookImage } from '../../../assets/images/pcThirdRow.svg';

export const StyledContainer = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
	height: 'inherit',
	alignItems: 'center',
	position: 'relative',
	'@media (orientation: portrait)': {
		display: 'flex-column',
		flexDirection: 'column-reverse',
	},
}));

export const StyledTextContainer = styled(Box)(() => ({
	display: 'block',
	maxWidth: '24%',
	marginLeft: '5%',
	'@media (orientation: portrait)': {
		maxWidth: '100%',
		margin: '0 auto',
	},
}));

export const StyledTitleText = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 800,
	fontSize: 40,
	lineHeight: '48px',
	color: theme.palette.neutral.light,
	textAlign: 'start',
	marginBottom: 20,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 36,
		lineHeight: '44px',
	},

	'@media (orientation: portrait)': {
		textAlign: 'center',
		fontSize: 28,
		lineHeight: '28px',
	},
}));
export const StyledText = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	display: 'flex',
	fontWeight: 400,
	fontSize: 20,
	lineHeight: '24px',
	color: theme.palette.neutral.light,
	width: '100%',
	alignItems: 'center',
	justifyContent: 'end',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 16,
		lineHeight: '20px',
	},
	'@media only screen and (max-width: 1200px)': {
		whiteSpace: 'nowrap',
	},

	'@media (orientation: portrait)': {
		display: 'none',
	},
}));

export const StyledTextMobile = styled(Box)(({ theme }) => ({
	display: 'none',
	'@media (orientation: portrait)': {
		display: 'block',
		fontFamily: 'Rubik',
		fontWeight: 800,
		fontSize: 14,
		lineHeight: '20px',
		color: theme.palette.neutral.light,
		textAlign: 'center',
		justifyContent: 'center',
	},
}));

export const NotebookSVG = styled(NotebookImage)(() => ({
	zIndex: 1,
	'@media only screen and (max-width: 1400px)': {
		width: 600,
	},
	'@media only screen and (max-width: 1200px)': {
		width: 500,
	},
	'@media (orientation: portrait)': {
		width: 350,
		height: 400,
		marginRight: 0,
		marginLeft: -36,
		marginTop: -100,
		marginBottom: -25,
	},
}));

export const StyledLogo = styled(Logo)(() => ({
	height: 30,
	width: 120,
	'@media (orientation: portrait)': {
		marginBottom: -9,
	},
	'@media only screen and (max-width: 1200px)': {
		minWidth: 120,
	},
}));

export const StyledBoxSvg = styled(Box)(() => ({
	marginLeft: '10%',
	'@media (orientation: portrait)': {
		marginLeft: 0,
	},
}));
