import { AxiosResponse } from "axios";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";

export const getAdvertiser = async (
  advertiser_id: string
): Promise<AxiosResponse<any> | undefined> => {
  try {
    return request({
      url: `${BASE_URL}/clients/${advertiser_id}`,
      method: "get",
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
