import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { DisablePromotionModalProps } from "./interface/modalDisablePromotion.interface";
import {
  StyledButtonsContainer,
  StyledCancelButton,
  StyledDialog,
  StyledDisableButton,
  StyledErrorCheckIcon,
  StyledIconButton,
  StyledModalText,
  StyledModalTitle,
} from "./styles";

const DisablePromotionModal: React.FC<DisablePromotionModalProps> = ({
  handleCloseDisablePromotionModal,
  openDisablePromotionModal,
  handleDisablePromotion,
  modalButton,
  modalText,
  modalTitle,
}: DisablePromotionModalProps): JSX.Element => (
  <StyledDialog
    open={openDisablePromotionModal}
    onClose={handleCloseDisablePromotionModal}
    fullWidth
    maxWidth="sm"
  >
    <StyledIconButton onClick={handleCloseDisablePromotionModal}>
      <CloseIcon />
    </StyledIconButton>
    <StyledErrorCheckIcon />
    <StyledModalTitle>{modalTitle}</StyledModalTitle>
    <StyledModalText>{modalText}</StyledModalText>
    <StyledButtonsContainer>
      <StyledCancelButton onClick={handleCloseDisablePromotionModal}>
        Cancelar
      </StyledCancelButton>
      <StyledDisableButton onClick={handleDisablePromotion}>
        {modalButton}
      </StyledDisableButton>
    </StyledButtonsContainer>
  </StyledDialog>
);

export default DisablePromotionModal;
