import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
	Box,
	IconButton,
	Switch,
	TableBody,
	TableHead,
	TableRow,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { ReactComponent as CategoryMarking } from '../../../assets/images/categoryMarkingIcon.svg';
import { toggleSwitch } from '../../../auth/actions/toggle.action';
import {
	IToggle,
	IToggleAction,
} from '../../../auth/interface/toggle.interface';
import { StyledTitleMenu } from '../../../pages/Menu/styles';
import AutoSave from '../../../shared/helpers/autoSaveFormik';
import { SwitchText } from '../../Forms/AddItem/ComplementaryItems/ComplementaryComponent/styles';
import ItemsList from '../ItemsList/ItemsList';
import MenuPopperButton from '../MenuPopperButton';
import { IItemListHeader } from '../interfaces';
import {
	AddItemButton,
	AlingFirstCell,
	StyledFirstCell,
	StyledFourthCell,
	StyledRow,
	StyledSecondCell,
	StyledThirdCell,
	StyledTitleCategory,
	SwitchButton,
	TitleAndCategory,
} from '../styles';

const ItemListHeader: React.FC<IItemListHeader> = ({
	id,
	name,
	items,
	indexMenu,
	available,
	handleItemPatch,
	handleItemDelete,
	handleEditItemOption,
	handleCreateItemOption,
	itemImageModalIsVisible,
	handleOpenItemImageModal,
	deleteItemModalIsVisible,
	handleOpenDeleteItemModal,
	handleCloseItemImageModal,
	handleCloseDeleteItemModal,
	handleCategoryAvailability,
	handleOpenCategoryDeleteModal,
}: IItemListHeader): JSX.Element => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const anchorRef = useRef<HTMLButtonElement>(null);
	const [open, setOpen] = useState(false);
	const [openPopper, setOpenPopper] = useState(false);
	const [categoryActiveToggle, setCategoryActiveToggle] = useState(available);

	const handleToggle = () => {
		setOpenPopper((prevOpen) => !prevOpen);
	};

	const handleClose = (event: MouseEvent | TouchEvent) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
			return;
		}
		setOpenPopper(false);
	};

	const handleListKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpenPopper(false);
		}
	};

	const handleCategoryClick = (action: string) => {
		const actionFunctions: { [key: string]: () => void } = {
			edit: () =>
				navigate('editCategory', {
					state: { categoryId: id, categoryName: name },
				}),
			delete: handleOpenCategoryDeleteModal,
		};

		if (actionFunctions[action]) {
			actionFunctions[action]();
		}

		setOpenPopper(false);
	};

	const menuItems = [
		{ action: 'edit', label: 'Editar categoria' },
		{ action: 'delete', label: 'Excluir categoria' },
	];

	const setToggle = (toggle: IToggle): IToggleAction =>
		dispatch(toggleSwitch(toggle));

	return (
		<>
			<TableHead>
				<StyledRow>
					<StyledFirstCell>
						<AlingFirstCell>
							<CategoryMarking />
							<TitleAndCategory>
								<StyledTitleMenu>{name}</StyledTitleMenu>
								<StyledTitleCategory>Categoria</StyledTitleCategory>
							</TitleAndCategory>
						</AlingFirstCell>
					</StyledFirstCell>
					<StyledSecondCell></StyledSecondCell>
					<StyledThirdCell>
						<AddItemButton
							variant="contained"
							color="primary"
							style={{ textTransform: 'none' }}
							onClick={() => handleCreateItemOption(id)}
						>
							+ Adicionar item
						</AddItemButton>
					</StyledThirdCell>
					<StyledFourthCell>
						<Formik
							initialValues={{
								menuId: id,
								available: categoryActiveToggle,
							}}
							onSubmit={(values, { setSubmitting }) => {
								handleCategoryAvailability(
									values,
									categoryActiveToggle,
									setSubmitting,
									setCategoryActiveToggle
								);
							}}
							enableReinitialize
						>
							{({
								submitForm,
								isSubmitting,
								setFieldValue,
								values: { available },
							}) => (
								<Form>
									<SwitchButton>
										<SwitchText>
											{categoryActiveToggle ? 'Ativado' : 'Desativado'}
										</SwitchText>
										<Switch
											checked={available}
											onChange={() => {
												setFieldValue('available', !available);

												setToggle({ menu: !available, menuId: id });
											}}
											disabled={isSubmitting}
											inputProps={{ 'aria-label': 'status switch' }}
										/>
									</SwitchButton>
									<AutoSave onSubmit={submitForm} />
								</Form>
							)}
						</Formik>
						<Box
							display={'flex'}
							minWidth={98}
							justifyContent={'flex-end'}
							ml={1}
						>
							{items && items.length > 0 && (
								<IconButton
									aria-label="expand row"
									size="small"
									onClick={() => setOpen(!open)}
								>
									{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
								</IconButton>
							)}
							<MenuPopperButton
								open={openPopper}
								anchorRef={anchorRef}
								menuItems={menuItems}
								handleClose={handleClose}
								handleToggle={handleToggle}
								handleItemClick={handleCategoryClick}
								handleListKeyDown={handleListKeyDown}
							/>
						</Box>
					</StyledFourthCell>
				</StyledRow>
			</TableHead>
			<TableBody>
				<TableRow
					sx={{
						'& .css-11utkwe-MuiTableCell-root': {
							padding: '0px 44px',
							paddingTop: '40px',
						},
					}}
				>
					<ItemsList
						idMenu={id}
						items={items}
						open={open}
						indexMenu={indexMenu}
						handleItemPatch={handleItemPatch}
						handleItemDelete={handleItemDelete}
						handleEditItemOption={handleEditItemOption}
						itemImageModalIsVisible={itemImageModalIsVisible}
						deleteItemModalIsVisible={deleteItemModalIsVisible}
						handleOpenItemImageModal={handleOpenItemImageModal}
						handleCloseItemImageModal={handleCloseItemImageModal}
						handleOpenDeleteItemModal={handleOpenDeleteItemModal}
						handleCloseDeleteItemModal={handleCloseDeleteItemModal}
					/>
				</TableRow>
			</TableBody>
		</>
	);
};

export default ItemListHeader;
