import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import { ReactScrollLinkProps } from 'react-scroll/modules/components/Link';
import TopBarLogo from '../../TopBarLogo/TopBarLogo';
import { TOPBAR_SCROLL_CONFIG } from './constants/topbar-scroll.constant';
import {
	NavBarButtons,
	NavBarContainer,
	NavBarMenu,
	StyledFirstButton,
	StyledLogo,
	StyledMenuItems,
	StyledSecondButton,
} from './styles';

const TopBar: React.FC = (): JSX.Element => {
	const navigate = useNavigate();
	const { spy, smooth, offset, duration }: ReactScrollLinkProps =
		TOPBAR_SCROLL_CONFIG;

	return (
		<NavBarContainer>
			<NavBarMenu>
				<StyledLogo>
					<TopBarLogo />
				</StyledLogo>
				<StyledMenuItems>
					<Link
						to="howItWorks"
						spy={spy}
						smooth={smooth}
						offset={offset}
						duration={duration}
					>
						Como Funciona
					</Link>
				</StyledMenuItems>
				<StyledMenuItems>
					<Link
						to="Plans"
						spy={spy}
						smooth={smooth}
						offset={offset}
						duration={duration}
					>
						Plano
					</Link>
				</StyledMenuItems>
				<StyledMenuItems>
					<Link
						to="commomQuestions"
						spy={spy}
						smooth={smooth}
						offset={offset}
						duration={duration}
					>
						Perguntas Frequentes
					</Link>
				</StyledMenuItems>
			</NavBarMenu>
			<NavBarButtons>
				<StyledFirstButton>
					<Link
						to="downloadApp"
						spy={spy}
						smooth={smooth}
						offset={offset}
						duration={duration}
					>
						Baixe o app
					</Link>
				</StyledFirstButton>
				<StyledSecondButton onClick={() => navigate('/login')}>
					Login
				</StyledSecondButton>
			</NavBarButtons>
		</NavBarContainer>
	);
};

export default TopBar;
