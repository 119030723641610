import React from 'react';

import {
	NotebookSVG,
	StyledBoxSvg,
	StyledContainer,
	StyledText,
	StyledTextContainer,
	StyledTextMobile,
	StyledTitleText,
} from './styles';

const SecondAnnouncement: React.FC = (): JSX.Element => (
	<StyledContainer>
		<StyledTextContainer>
			<StyledTitleText>
				Poste promoções com cupons e tenha mais visibilidade!{' '}
			</StyledTitleText>

			<StyledText>
				Aqui você visualiza todas as estatísticas das suas promoções em tempo
				real!
			</StyledText>
			<StyledTextMobile>
				Aqui você visualiza todas as estatísticas das suas promoções em tempo
				real!
			</StyledTextMobile>
		</StyledTextContainer>
		<StyledBoxSvg>
			<NotebookSVG />
		</StyledBoxSvg>
	</StyledContainer>
);

export default SecondAnnouncement;
