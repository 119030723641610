import { Skeleton } from "@mui/material";
import React from "react";
import { StyledLastCommentSkeleton } from "../styles";

const CommentsSkeletonWithAnswers: React.FC = (): JSX.Element => {
  return (
    <StyledLastCommentSkeleton>
      <Skeleton
        animation="wave"
        width="90%"
        sx={{
          height: 140,
          marginLeft: 5,
          "@media only screen and (max-width: 1400px)": {
            height: 93,
          },
        }}
      />
    </StyledLastCommentSkeleton>
  );
};

export default CommentsSkeletonWithAnswers;
