import { PromotionParams } from "../../pages/Promotion/interface/promotion-params.interface";

export const handleParams = (
  newValue: number,
  params: PromotionParams
): PromotionParams => {
  switch (newValue) {
    case 0: {
      params = { ...params };
      break;
    }
    case 1: {
      params = {
        ...params,
        expired: false,
      };
      break;
    }
    case 2: {
      params = {
        ...params,
        expired: true,
      };
      break;
    }
    default:
      break;
  }
  return params;
};
