import { Box, styled } from "@mui/material";

interface StyledCouponsStatusDisplayProp {
  bottomColor: string;
}

export const StyledCouponsStatusDisplay = styled(Box, {
  shouldForwardProp: (prop) => prop !== "bottomColor",
})<StyledCouponsStatusDisplayProp>(({ theme, bottomColor }) => ({
  display: "block",
  boxSizing: "border-box",
  width: 160,
  height: 84,
  backgroundColor: theme.palette.neutral.light,
  borderBottom: "6px solid",
  borderColor: bottomColor,
  borderRadius: 12,
  padding: "16px 20px 20px 12px",
  boxShadow: "4px 6px 15px rgba(0, 0, 0, 0.1)",
}));

export const StyledCouponsContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const StyledCouponsNumber = styled(Box)(({ theme }) => ({
  display: "flex",
  color: theme.palette.primary.dark,
  fontSize: 18,
  fontFamily: "Rubik",
  fontWeight: 500,
  lineHeight: "24px",
  marginBottom: 4,
  minHeight: 24,
}));

export const StyledCouponsPercent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 40,
  height: 20,
  borderRadius: 12,
  background: "rgba(96, 41, 177, 0.1)",
  fontSize: 12,
  fontFamily: "Rubik",
  fontWeight: 400,
  lineHeight: "16px",
  color: theme.palette.primary.dark,
}));

export const StyledCouponsText = styled(Box)(({ theme }) => ({
  display: "flex",
  color: theme.palette.neutral.dark1,
  fontSize: 14,
  fontFamily: "Rubik",
  fontWeight: 400,
  lineHeight: "20px",
}));
