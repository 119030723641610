import { Box, styled, TextField, Typography } from '@mui/material';

export const AddressFormContainer = styled(Box)(() => ({
	display: 'block',
}));

export const StyledFirstRowForm = styled(Box)(() => ({
	display: 'flex',
	marginBottom: 24,
}));

export const StyledCepContainer = styled(Box)(() => ({
	width: '25%',
	minWidth: 153,
	marginRight: 40,
	maxHeight: 60,
	'@media only screen and (max-width: 1400px)': {
		minWidth: 130,
		height: 60,
	},
	'@media only screen and (max-width: 1200px)': {
		minWidth: 100,
		height: 40,
		marginRight: 30,
	},
}));

export const StyledFormText = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 20,
	color: theme.palette.neutral.dark,
	marginBottom: 12,
}));

export const StyledTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		position: 'relative',
		borderRadius: 8,
		height: 60,
		maxHeight: 60,
		fontFamily: 'Rubik',
		'@media only screen and (max-width: 1400px)': {
			fontSize: 12,
			height: 46,
		},
		'@media only screen and (max-width: 1200px)': {
			fontSize: 12,
			height: 40,
		},

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'@media only screen and (max-width: 1400px)': {
		'& .MuiFormLabel-root': {
			fontSize: '12px',
		},
	},
}));

export const StyledStateContainer = styled(Box)(() => ({
	display: 'block',
	width: '20%',
	minWidth: 155,
	marginRight: 40,
	maxHeight: 60,
	'@media only screen and (max-width: 1400px)': {
		height: 60,
		minWidth: 90,
	},
	'@media only screen and (max-width: 1200px)': {
		height: 40,
		marginRight: 30,
	},
}));

export const StyledCityContainer = styled(Box)(() => ({
	display: 'block',
	width: '35%',
	minWidth: 252,
	'@media only screen and (max-width: 1400px)': {
		height: 60,
		minWidth: 210,
	},
	'@media only screen and (max-width: 1200px)': {
		minWidth: 180,
		height: 40,
	},
}));

export const StyledSecondRowForm = styled(Box)(() => ({
	display: 'flex',
	marginBottom: 24,
}));

export const StyledAddressContainer = styled(Box)(() => ({
	display: 'block',
	height: 60,
	maxHeight: 60,
	width: '72%',
	minWidth: 437,
	marginRight: 40,
	'@media only screen and (max-width: 1400px)': {
		height: 60,
		minWidth: 330,
	},
	'@media only screen and (max-width: 1200px)': {
		minWidth: 280,
		height: 40,
		marginRight: 30,
	},
}));

export const StyledNumberContainer = styled(Box)(() => ({
	display: 'block',
	height: 60,
	maxHeight: 60,
	width: '17%',
	minWidth: 140,
	'@media only screen and (max-width: 1400px)': {
		height: 60,
	},
	'@media only screen and (max-width: 1200px)': {
		height: 40,
	},
}));

export const StyledThirdRowForm = styled(Box)(() => ({
	display: 'flex',
	marginBottom: 24,
}));

export const StyledDistrictContainer = styled(Box)(() => ({
	display: 'block',
	height: 60,
	maxHeight: 60,
	width: '38%',
	minWidth: 316,
	marginRight: 40,
	'@media only screen and (max-width: 1400px)': {
		height: 60,
		minWidth: 240,
	},
}));

export const StyledComplementContainer = styled(Box)(() => ({
	display: 'block',
	height: 60,
	maxHeight: 60,
	minWidth: 316,
	width: '51%',
	'@media only screen and (max-width: 1400px)': {
		height: 60,
		minWidth: 230,
	},
	'@media only screen and (max-width: 1200px)': {
		minWidth: 150,
		height: 40,
	},
}));

export const StyledTextContainer = styled(Box)(() => ({
	display: 'block',
	marginBottom: '30px',
}));

export const TypographyTitle = styled(Typography)(({ theme }) => ({
	fontSize: 20,
	lineHeight: '24px',
	fontFamily: 'Rubik',
	fontWeight: 500,
	display: 'baseline',
	color: theme.palette.neutral.dark,
	marginBottom: 5,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 16,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 14,
	},
}));

export const TypographyText = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	lineHeight: '20px',
	fontFamily: 'Rubik',
	fontWeight: 400,
	display: 'baseline',
	color: theme.palette.neutral.dark1,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 12,
	},
}));
