import * as yup from "yup";
import { unMaskReais } from "../../../utils/mask.util";

yup.addMethod<yup.StringSchema>(
  yup.string,
  "moreThan0",
  function (errorMessage) {
    return this.test(`test-value`, errorMessage, function (value) {
      const { path, createError } = this;
      return (
        unMaskReais(value) !== 0 || createError({ path, message: errorMessage })
      );
    });
  }
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  "lessThanInitial",
  function (errorMessage) {
    return this.test(`test-value`, errorMessage, function (value) {
      const { initial_price } = this.parent;
      const { path, createError } = this;
      return (
        unMaskReais(value) < unMaskReais(initial_price) ||
        createError({ path, message: errorMessage })
      );
    });
  }
);

const yesterday = new Date(Date.now() - 86400000);

const EDIT_PROMOTION_FIELDS_VALIDATION_SCHEMA = yup.object({
  title: yup.string().required("Campo obrigatório."),
  description: yup.string().required("Campo obrigatório."),
  initial_price: yup
    .string()
    .moreThan0("Deve ser maior que 0")
    .required("Campo obrigatório."),
  promotional_price: yup
    .string()
    .moreThan0("Deve ser maior que 0")
    .required("Campo obrigatório.")
    .lessThanInitial("Entre um valor válido"),
  promotion_initial_date: yup
    .date()
    .required("Campo obrigatório.")
    .typeError("Digite uma data válida"),
  promotion_expires_date: yup
    .date()
    .min(yesterday, "Digite uma data válida")
    .required("Campo obrigatório.")
    .typeError("Digite uma data válida"),
  promotion_expires_hour: yup
    .string()
    .required("Campo obrigatório.")
    .typeError("Digite uma hora válida"),
  tag_id: yup.string().required("Campo obrigatório."),
  promotion_image: yup.string().required("Faça upload de uma imagem"),
  has_coupons: yup.boolean(),
  qtd_coupons: yup.number().when("has_coupons", {
    is: true,
    then: yup
      .number()
      .positive("Maior que 0")
      .min(1, "Maior que 0")
      .required("Maior que 0"),
  }),
  coupon_duration: yup.string().when("has_coupons", {
    is: true,
    then: yup.string().required("Campo obrigatório."),
  }),
  paymentMethods: yup.array().required().min(1, "Campo obrigatório"),
});

export default EDIT_PROMOTION_FIELDS_VALIDATION_SCHEMA;
