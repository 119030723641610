import { FormikValues, useFormikContext } from 'formik';
import { isEqual, omit } from 'lodash';
import { FC, useCallback, useEffect } from 'react';

interface AutoSaveProps {
	delay?: number;
	onSubmit?: (v: FormikValues) => void;
}

const AutoSave: FC<AutoSaveProps> = ({ delay = 1000, onSubmit }) => {
	const { values, errors, initialValues } = useFormikContext<FormikValues>();

	const isSameValueAsInitialValue = async (v: FormikValues) =>
		isEqual(v, initialValues);

	const onFormSubmit = useCallback(async () => {
		const v: FormikValues = omit(values, Object.keys(errors));
		if (onSubmit && !(await isSameValueAsInitialValue(v))) onSubmit(v);
	}, [values, initialValues, errors]);

	useEffect(() => {
		const timer = setTimeout(() => onFormSubmit(), delay);
		return () => clearTimeout(timer);
	}, [values, errors]);

	return null;
};

export default AutoSave;
