import { CouponParams } from "../../../pages/Coupons/CouponsManager/interface/coupon-params.interface";
import { ICouponResponse } from "../../../pages/Coupons/CouponsManager/interface/couponList-props.interface";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";

export const getCouponsListByAdvertiser = async (
  params: CouponParams
): Promise<ICouponResponse> => {
  try {
    const response = await request({
      url: `${BASE_URL}/coupons/client/couponsList`,
      method: "get",
      params: params,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
