import { Pagination, Skeleton } from "@mui/material";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IAuth } from "../../auth/interface/auth.interface";
import { IReducers } from "../../auth/store/store";
import BigEmptyCard from "../../components/BigEmptyCard/BigEmptyCard";
import DashboardTopBar from "../../components/DashboardTopBar/DashboardTopBar";
import CommentsSkeletonWithAnswers from "../../components/LastCommentsWithAnswers/CommentsSkeleton/CommentsSkeleton";
import LastCommentsWithAnswers from "../../components/LastCommentsWithAnswers/LastCommentsWithAnswers";
import { getLastComments } from "../../utils/api/get/getLastComments";
import { patchCommentsById } from "../../utils/api/patch/patchCommentsById";
import {
  CommentsParams,
  ILastComments,
} from "../Dashboard/interface/lastComments.interface";
import {
  StyledComment,
  StyledCommentContainer,
  StyledCommentContent,
  StyledCommentListContainer,
  StyledCommentTopBar,
  StyledCommentsList,
  StyledCommentsTitleContainer,
  StyledCommentsTitleTypography,
  StyledEmptyList,
  StyledPagination,
} from "./styles";

const Comment: React.FC = (): JSX.Element => {
  const [lastComments, setLastComments] = useState<ILastComments[]>([]);
  const [commentsIds, setCommentsIds] = useState<string[]>([]);
  const [totalComments, setTotalComments] = useState(0);
  const [loaderTotalComments, setLoaderTotalComments] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loaderComments, setLoaderComments] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { advertiser_id }: IAuth = useSelector(
    (state: IReducers) => state.auth
  );
  const unsubscribe = useRef<boolean>();

  const params: CommentsParams = { page: currentPage, perPage: 3 };

  const handleCurrentPageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ): void => {
    setCurrentPage(value);
  };

  const getcommentsIds = (comments: ILastComments[]) => {
    const commentsId = comments.flatMap(({ was_read, id }) => {
      return was_read ? [] : id;
    });
    if (commentsId.length > 0) {
      setCommentsIds(commentsId);
    }
    return;
  };

  const getLastCommentsByAdvertiser = async (): Promise<void> => {
    setLoaderComments(true);
    await getLastComments(advertiser_id, params)
      .then(async (res) => {
        await getcommentsIds(res?.data);
        setLastComments(res?.data);
        setTotalComments(res.total);
        setTotalPages(res?.last_page);
        setLoaderComments(false);
        setLoaderTotalComments(false);
      })
      .catch((err: AxiosError) => {
        setLoaderComments(false);
        setLoaderTotalComments(false);
        setLastComments([]);
        if (err) {
          enqueueSnackbar("Ocorreu um erro tente novamente.", {
            variant: "error",
            autoHideDuration: 1500,
          });
        }
      });
  };

  useEffect(() => {
    unsubscribe.current = true;
    if (unsubscribe) {
      setLoaderComments(true);
      getLastCommentsByAdvertiser();
      return () => {
        unsubscribe.current = false;
      };
    }
  }, [currentPage]);

  useEffect(() => {
    if (commentsIds.length > 0) {
      patchCommentsById(commentsIds);
    }
  }, [commentsIds]);

  return (
    <StyledComment>
      <StyledCommentContainer>
        <StyledCommentTopBar>
          <DashboardTopBar
            title={"Comentários"}
            text={"Visualize comentários sobre suas ofertas"}
          />
        </StyledCommentTopBar>
        <StyledCommentContent>
          <StyledCommentListContainer>
            {loaderTotalComments ? (
              <StyledCommentsTitleContainer>
                <Skeleton
                  animation="wave"
                  variant="text"
                  sx={{
                    height: 30,
                    width: 200,
                    "@media only screen and (max-width: 1200px)": {
                      height: 24,
                    },
                  }}
                />
              </StyledCommentsTitleContainer>
            ) : lastComments.length === 0 ? (
              <StyledCommentsTitleContainer>
                <StyledCommentsTitleTypography>{`Nenhuma comentário encontrado`}</StyledCommentsTitleTypography>
              </StyledCommentsTitleContainer>
            ) : (
              <StyledCommentsTitleContainer>
                <StyledCommentsTitleTypography>
                  {lastComments.length === 1
                    ? `${totalComments} comentário`
                    : `${totalComments} comentários`}
                </StyledCommentsTitleTypography>
              </StyledCommentsTitleContainer>
            )}
            {loaderComments ? (
              <StyledCommentsList>
                {Array(3)
                  .fill(null)
                  .map((_, index) => (
                    <CommentsSkeletonWithAnswers key={index} />
                  ))}
              </StyledCommentsList>
            ) : (
              <>
                {lastComments.length > 0 && (
                  <StyledCommentsList>
                    {lastComments.map((data: ILastComments, index) => (
                      <LastCommentsWithAnswers
                        getLastCommentsByAdvertiser={
                          getLastCommentsByAdvertiser
                        }
                        data={data}
                        key={index}
                        promotionDetails={true}
                      />
                    ))}
                  </StyledCommentsList>
                )}
                {lastComments.length === 0 && (
                  <StyledEmptyList>
                    <BigEmptyCard
                      text={
                        "Você ainda não recebeu nenhum comentário. Continue criando promoções!"
                      }
                      image={"comments"}
                    />
                  </StyledEmptyList>
                )}
              </>
            )}
            <StyledPagination>
              {lastComments.length > 0 && (
                <Pagination
                  shape="rounded"
                  count={totalPages}
                  page={currentPage}
                  onChange={handleCurrentPageChange}
                  color="primary"
                  sx={{ margin: "auto" }}
                  showFirstButton
                  showLastButton
                />
              )}
            </StyledPagination>
          </StyledCommentListContainer>
        </StyledCommentContent>
      </StyledCommentContainer>
    </StyledComment>
  );
};

export default Comment;
