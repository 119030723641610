import OrderStatusCard from '../OrderStatusCard/OrdersStatusCard';
import SkeletonCard from '../SekeletonCard/SkeletonCard';
import { IChartProps } from '../interfaces/interfaces';

const TransferValue: React.FC<IChartProps> = ({
	loading,
	stats,
	detailsData,
	handleFooterText,
	handleFoooterIcon,
}: IChartProps): JSX.Element => {
	return (
		<>
			{loading ? (
				<SkeletonCard />
			) : (
				stats && (
					<OrderStatusCard
						text="Valor do repasse"
						value={stats.transferValue}
						handleFoooterIcon={handleFoooterIcon(
							detailsData?.stats.transferValuePercentage
						)}
						money={true}
						descending={
							stats.transferValuePercentage < 0 ||
							stats.transferValuePercentage === 0
						}
						footerText={handleFooterText(
							detailsData?.stats.transferValuePercentage
						)}
						tooltip="Esse é o valor do repasse já descontado as taxas do dsconto dos últimos 30 dias."
					/>
				)
			)}
		</>
	);
};

export default TransferValue;
