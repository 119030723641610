import { Box, Skeleton } from '@mui/material';
import { SkeletonContent } from './styles';

const SkeletonCard: React.FC = (): JSX.Element => {
	return (
		<SkeletonContent>
			<Box
				sx={{
					height: 45,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<Skeleton
					sx={{
						height: 22,
						width: 150,
						borderRadius: 8,
					}}
				/>
				<Skeleton variant="circular" width={30} height={30} />
			</Box>
			<Box
				sx={{
					height: 60,
				}}
			>
				<Skeleton
					sx={{
						height: 22,
						width: 310,
						borderRadius: 8,
						marginTop: 1,
					}}
				/>
				<Skeleton
					sx={{
						height: 22,
						width: 310,
						borderRadius: 8,
						marginTop: 1,
					}}
				/>
			</Box>
		</SkeletonContent>
	);
};

export default SkeletonCard;
