import { Box, TextField } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)(() => ({
  width: "100%",
  height: "100%",
}));

export const CepAndUFAndCiyt = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  marginTop: "33px",
}));

export const InputCep = styled(TextField)(() => ({
  width: "200px",

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
}));

export const InputUF = styled(TextField)(() => ({
  width: "100px",
  marginLeft: "28px",

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
}));

export const InputCity = styled(TextField)(() => ({
  width: "180px",
  marginLeft: "28px",

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
}));

export const StreetAndNumber = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  marginTop: "33px",
}));

export const InputStreet = styled(TextField)(() => ({
  width: "606px",

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
}));

export const InputNumber = styled(TextField)(() => ({
  width: "104px",
  marginLeft: "28px",

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
}));

export const DistrictAndComplement = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  marginTop: "33px",
}));

export const InputDistrict = styled(TextField)(() => ({
  width: "404px",

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
}));

export const InputComplement = styled(TextField)(() => ({
  width: "306px",
  marginLeft: "28px",

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
}));
