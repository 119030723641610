import { ReactComponent as CouponIcon } from "../../../../assets/images/couponFilledIcon.svg";
import { ReactComponent as EyeIcon } from "../../../../assets/images/eyeIcon.svg";
import { ReactComponent as HeartIcon } from "../../../../assets/images/heartIcon.svg";

export const CARDICONS = [
  {
    text: "Visualizações",
    icon: <EyeIcon />,
  },
  {
    text: "Curtidas",
    icon: <HeartIcon />,
  },
  {
    text: "Cupons utilizados",
    icon: <CouponIcon />,
  },
];
