import { Skeleton } from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as FireIcon } from '../../../assets/images/fireDashboardIcon.svg';
import { IAuth } from '../../../auth/interface/auth.interface';
import { IReducers } from '../../../auth/store/store';
import { NewPromotionProps } from '../../../pages/Dashboard/interface';
import { getPromotion } from '../../../utils/api/get/getPromotion';
import PromotionCard from '../../PromotionCard/PromotionCard';
import EmptyCard from '../EmptyCard/EmptyCard';
import {
	EmptyCardContainer,
	StyledBestPromotionsTitle,
	StyledBestPromotionsTitleTypography,
	StyledFireIcon,
	StyledPromotion,
	StyledPromotionCardSkeleton,
	StyledPromotionCards,
	StyledTitleContainer,
} from './styles';

const PromotionListSkeleton: React.FC = (): JSX.Element => (
	<StyledPromotion>
		<StyledPromotionCardSkeleton animation="wave" variant="rectangular" />
	</StyledPromotion>
);

const MostViewPromotionCard: React.FC = (): JSX.Element => {
	const [promotion, setPromotion] = useState<NewPromotionProps[]>([]);
	const [promotionListLoader, setPromotionListLoader] = useState(true);

	const { advertiser_id }: IAuth = useSelector(
		(state: IReducers) => state.auth
	);
	const unsubscribe = useRef<boolean>();

	const getPromotionList = (): void => {
		const params = { page: 1, perPage: 3, order_by: 'views', expired: false };
		getPromotion(advertiser_id, params).then((res) => {
			setPromotion(res?.data);
			setPromotionListLoader(false);
		});
	};

	useEffect(() => {
		unsubscribe.current = true;
		if (unsubscribe) {
			getPromotionList();
		}
		return () => {
			unsubscribe.current = false;
		};
	}, []);

	return (
		<>
			<StyledBestPromotionsTitle>
				<StyledTitleContainer>
					{promotionListLoader ? (
						<StyledBestPromotionsTitleTypography>
							<Skeleton
								sx={{
									height: 20,
									width: 200,
									borderRadius: 2,
									marginLeft: -1,
								}}
								animation="wave"
								variant="rectangular"
							/>
						</StyledBestPromotionsTitleTypography>
					) : (
						<>
							<StyledBestPromotionsTitleTypography>
								Bombando no app
							</StyledBestPromotionsTitleTypography>
							<StyledFireIcon>
								<FireIcon />
							</StyledFireIcon>
						</>
					)}
				</StyledTitleContainer>
			</StyledBestPromotionsTitle>
			{promotionListLoader ? (
				<StyledPromotionCards>
					{Array(3)
						.fill(null)
						.map((_, index) => (
							<PromotionListSkeleton key={index} />
						))}
				</StyledPromotionCards>
			) : (
				<>
					{promotion.length > 0 && (
						<StyledPromotionCards>
							{promotion.map((promotion: NewPromotionProps, index) => (
								<PromotionCard data={promotion} key={index} />
							))}
						</StyledPromotionCards>
					)}
					{promotion.length === 0 && (
						<EmptyCardContainer>
							<EmptyCard
								text={
									'Ainda não há estatísticas disponíveis, crie uma promoção para começar.'
								}
								image={'promotion'}
							/>
						</EmptyCardContainer>
					)}
				</>
			)}
		</>
	);
};
export default memo(MostViewPromotionCard);
