import { Grid, TextField, styled } from '@mui/material';
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';

const Input = styled(TextField)(({ theme }) => ({
	borderRadius: '3px',
	backgroundColor: '#F9F9F9',
	outlineWidth: 2,
	MozAppearance: 'textfield',
	borderColor: '#F9F9F9',
	width: '65px',
	[theme.breakpoints.down('lg')]: {
		width: '50px',
	},
	[theme.breakpoints.down('md')]: {
		width: '70px',
	},
}));
Input.defaultProps = {
	variant: 'outlined',
	inputProps: {
		maxLength: 1,
		style: {
			fontFamily: 'Rubik',
			fontWeight: 700,
			textAlign: 'center',
			fontSize: 25,
			color: '#C5C5C5',
		},
	},
};

type Props = {
	onComplete: (token: string) => void;
	isValid: boolean;
};

export const CodeInput: React.FC<Props> = ({
	onComplete,
	isValid,
}): JSX.Element => {
	const [inputs, setInputs] = useState<string[]>(
		Array.from({ length: 6 }, () => '')
	);
	const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
	const onChange = (value: string, index: number) => {
		const newInputs = [...inputs];
		newInputs[index] = value;
		setInputs(newInputs);
		if (value !== '') {
			handleFocusNextInput(index);
		}
	};
	useEffect(() => {
		const isAllInputsFilled = inputs.every((item) => item.trim() !== '');
		if (isAllInputsFilled) {
			const token = inputs.join('');
			onComplete(token);
			return;
		}
	}, [inputs]);
	useEffect(() => {
		if (inputRefs.current[0]) {
			inputRefs.current[0]?.focus();
		}
	}, []);
	const handleFocusNextInput = (index: number) => {
		if (inputRefs.current[index + 1]) {
			inputRefs.current[index + 1]?.focus();
			inputRefs.current[index + 1]?.select();
			return;
		}
	};
	const handleFocusLastInput = (index: number) => {
		if (inputRefs.current[index - 1]) {
			inputRefs.current[index - 1]?.focus();
			inputRefs.current[index - 1]?.select();
		}
	};
	const handleKeyPress = (e: KeyboardEvent, index: number) => {
		switch (e.key) {
			case 'ArrowRight':
			case 'Space':
			case 'Enter': {
				handleFocusNextInput(index);
				break;
			}
			case 'Backspace': {
				const newInputs = [...inputs];
				newInputs[index] = '';
				setInputs(newInputs);
				if (inputs[index] == '') {
					handleFocusLastInput(index);
				}
				break;
			}
			case 'ArrowLeft': {
				handleFocusLastInput(index);
				break;
			}
		}
	};
	return (
		<Grid container spacing={2}>
			{inputs.map((item, idx: number) => (
				<Grid key={idx} item xs={4} md={2} sm={2}>
					<Input
						value={item}
						error={!isValid}
						onFocus={() => inputRefs.current[idx]?.select()}
						inputRef={(ref) => (inputRefs.current[idx] = ref)}
						onChange={(e) => onChange(e.target.value, idx)}
						onKeyDown={(e) => handleKeyPress(e, idx)}
					/>
				</Grid>
			))}
		</Grid>
	);
};
