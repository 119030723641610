import { Box, styled } from '@mui/material';

export const IntroductionContainer = styled(Box)(() => ({}));

export const CenterBox = styled(Box)(() => ({
	height: 550,
	width: 900,
	boxSizing: 'border-box',
	display: 'block',
	position: 'relative',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '110px 80px 20px 200px',
	'@media only screen and (max-width: 1400px)': {
		width: 740,
	},
	'@media only screen and (max-width: 1200px)': {
		width: 540,
		padding: '90px 20px 20px 100px',
	},
	'@media (orientation: portrait)': {
		width: '100%',
		padding: 0,
		height: '100%',
	},
}));

export const CenterBoxTitle = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 56,
	color: theme.palette.neutral.light,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 46,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 36,
	},
	'@media (orientation: portrait)': {
		textAlign: 'center',
		fontSize: 36,
	},
}));

export const CenterBoxText = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 24,
	color: theme.palette.neutral.light,
	marginTop: 32,
	width: '90%',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 20,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 18,
	},
	'@media (orientation: portrait)': {
		width: '80%',
		textAlign: 'center',
		margin: '0 auto',
		marginTop: 32,
		fontSize: 18,
	},
}));

export const CenterBoxTextLast = styled(Box)(({ theme }) => ({
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 24,
	color: theme.palette.neutral.light,
}));

export const StyledButtonBox = styled(Box)(() => ({
	width: '80%',
	marginTop: '10%',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 20,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 18,
	},
	'@media (orientation: portrait)': {
		width: '80%',
		margin: '0px auto',
		marginTop: 50,
	},
}));
