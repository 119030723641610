import {
	ADD_OPTIONGROUPID,
	REMOVE_OPTIONGROUPID,
} from '../constants/editOptionGroup-config.constant';
import {
	IEditOptionGroup,
	IEditOptionGroupAction,
} from '../interface/editOptionGroup.interface';

const initialState: IEditOptionGroup = {
	optionGroupEditId: undefined,
};

const editOptionGroupReducer = (
	state: IEditOptionGroup = initialState,
	action: IEditOptionGroupAction
): IEditOptionGroup => {
	switch (action.type) {
		case ADD_OPTIONGROUPID: {
			return {
				...state,
				...action.payload,
			};
		}

		case REMOVE_OPTIONGROUPID: {
			return initialState;
		}

		default:
			return state;
	}
};

export default editOptionGroupReducer;
