import { Box } from "@mui/material";
import { ReactComponent as SilverPlanIcon } from "../../../assets/images/SilverPlanIcon.svg";
import {
  DayPayText,
  FirstHistoryCard,
  FormOfPayment,
  FormOfPaymentBox,
  FormOfPaymentText,
  NameAndLogo,
  PlanNameHistory,
  PlansHistory,
  SecondHistoryCard,
  SubTitle,
  Texts,
  Title,
} from "./styles";

const PlanHistory: React.FC = (): JSX.Element => (
  <>
    <Texts>
      <Title>Histórico</Title>
      <SubTitle>Confira seus planos ultilizados nos últimos meses</SubTitle>
    </Texts>
    <PlansHistory>
      <FirstHistoryCard>
        <NameAndLogo>
          <SilverPlanIcon />
          <PlanNameHistory> Silver</PlanNameHistory>
        </NameAndLogo>
        <FormOfPaymentBox>
          <FormOfPaymentText>Faturado em: </FormOfPaymentText>
          <FormOfPayment>Visa Crédito 1234</FormOfPayment>
        </FormOfPaymentBox>
        <Box>
          <DayPayText>07/06/2022</DayPayText>
        </Box>
      </FirstHistoryCard>
      <SecondHistoryCard>
        <NameAndLogo>
          <SilverPlanIcon />
          <PlanNameHistory> Silver</PlanNameHistory>
        </NameAndLogo>
        <FormOfPaymentBox>
          <FormOfPaymentText>Faturado em: </FormOfPaymentText>
          <FormOfPayment>Visa Crédito 1234</FormOfPayment>
        </FormOfPaymentBox>
        <Box>
          <DayPayText>07/05/2022</DayPayText>
        </Box>
      </SecondHistoryCard>
    </PlansHistory>
  </>
);

export default PlanHistory;
