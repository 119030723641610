import {
	Box,
	Button,
	FormControl,
	RadioGroup,
	TextField,
	Typography,
	styled,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IIncrementButtonsProps } from '../../interfaces';

export const ComplementsGroupName = styled(TextField)(() => ({
	width: '60%',
	marginTop: '2%',
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 60,
		fontFamily: 'Rubik',
		fontWeight: 400,

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'@media only screen and (max-width: 1400px)': {
		'& .MuiFormLabel-root': {
			fontSize: '13px',
		},
	},
}));

export const OptionsComplements = styled(Box)(() => ({
	width: '100%',
	marginTop: '1%',
	'& .PrivateSwitchBase-root': {
		padding: '0px',
		marginLeft: '8px',
	},
	'& .MuiFormControlLabel-label': {
		marginLeft: '5px',
	},
	'& .MuiFormHelperText-root': {
		marginLeft: '-1px',
	},
}));

export const FormControlStyled = styled(FormControl)(() => ({
	width: '100%',
}));

export const RadioGroupStyled = styled(RadioGroup)(() => ({
	width: '100%',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
}));

export const WrapperRadioGroup = styled(Box)(({ theme }) => ({
	width: '45%',
	height: 80,
	display: 'flex',
	alignItems: 'center',
	border: `1px solid ${theme.palette.neutral.dark2}`,
	borderRadius: 8,
	paddingLeft: 20,
}));

export const WrapperMidleContent = styled(Box)(() => ({
	width: '99%',
	height: '150px',
	marginTop: '2%',
}));

export const AlignCounters = styled(Box)(() => ({
	display: 'flex',
	width: '30%',
	marginTop: '1%',
	justifyContent: 'space-between',
	'@media only screen and (max-width: 1400px)': {
		width: '45%',
	},
}));

export const InputCountName = styled(Typography)(({ theme }) => ({
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.neutral.dark,
	marginTop: '5.5%',
}));

export const InputCount = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: '0px 15px',
	marginTop: '5%',
	width: 180,
	height: 66,
	border: `1px solid ${theme.palette.neutral.dark2}`,
	borderRadius: '8px',
}));

export const RemoveMin = styled(RemoveIcon, {
	shouldForwardProp: (prop) => prop !== 'someMin',
})<IIncrementButtonsProps>(({ theme, someMin }) => ({
	width: '20px',
	height: '20px',
	color: `${
		!someMin ? theme.palette.neutral.dark2 : theme.palette.primary.main
	}`,
}));

export const RemoveMax = styled(RemoveIcon)(({ theme }) => ({
	width: '20px',
	height: '20px',
	color: theme.palette.primary.main,
}));

export const AddMin = styled(AddIcon, {
	shouldForwardProp: (prop) => prop !== 'someMin',
})<IIncrementButtonsProps>(({ someMin, theme }) => ({
	width: '20px',
	height: '20px',
	color: `${
		!someMin ? theme.palette.neutral.dark2 : theme.palette.primary.main
	}`,
}));

export const AddMax = styled(AddIcon)(({ theme }) => ({
	width: '20px',
	height: '20px',
	color: theme.palette.primary.main,
}));

export const IconButton = styled(Button)(() => ({
	cursor: 'pointer',
	width: '10px',
	minWidth: '0px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

export const NumberMin = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'someMin',
})<IIncrementButtonsProps>(({ theme, someMin }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: `${
		!someMin ? theme.palette.neutral.dark2 : theme.palette.common.black
	}`,
}));

export const NumberMax = styled(Box)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.common.black,
}));

export const Title = styled(Typography)(({ theme }) => ({
	fontSize: 20,
	fontFamily: 'Rubik',
	fontWeight: 500,
	marginTop: '2%',
	color: theme.palette.neutral.dark,
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	marginTop: '5px',
	color: theme.palette.neutral.dark1,
}));

export const WrapperButton = styled(Box)(() => ({
	display: 'flex',
	marginTop: '2%',
}));
export const CreateButton = styled(Button)(({ theme }) => ({
	width: 340,
	height: 60,
	border: `1px dashed ${theme.palette.neutral.dark2}`,
	borderRadius: 8,
	textTransform: 'initial',
}));

export const ButtonText = styled(Typography)(({ theme }) => ({
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
}));
