import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, Switch } from '@mui/material';
import { getIn } from 'formik';
import { useEffect, useState } from 'react';
import { moneyMask } from '../../../../../utils/mask.util';
import { IComplementaryProps } from '../../interfaces';
import {
	AlignInputs,
	ComplementName,
	ContentBox,
	DeleteButton,
	InuputDescription,
	MiddleBox,
	SinglePrice,
	SwitchButton,
	SwitchText,
} from './styles';

export const ComplementaryComponent: React.FC<IComplementaryProps> = ({
	name,
	available,
	description,
	optionImage,
	option,
	optionPrice,
	setFieldValue,
	handleChange,
	optionArrayHelpers,
	index,
}: IComplementaryProps): JSX.Element => {
	const [isSelected, setIsSelected] = useState(option.available ?? false);
	const [imageState, setImageState] = useState({
		itemImage: option.optionImage,
		path: option.optionImage,
	});

	const touchedName = getIn(optionArrayHelpers.form.touched, name);
	const errorName = getIn(optionArrayHelpers.form.errors, name);

	const touchedDescription = getIn(
		optionArrayHelpers.form.touched,
		description
	);
	const errorDescription = getIn(optionArrayHelpers.form.errors, description);

	const touchedPrice = getIn(optionArrayHelpers.form.touched, optionPrice);
	const errorPrice = getIn(optionArrayHelpers.form.errors, optionPrice);

	useEffect(() => {
		setFieldValue(optionImage, imageState?.itemImage);
	}, [imageState?.itemImage]);

	useEffect(() => {
		if (!optionImage) {
			setImageState({
				itemImage: '',
				path: '',
			});
		}
	}, [optionImage]);

	useEffect(() => {
		optionArrayHelpers.form.setTouched({}, false);
	}, []);

	return (
		<ContentBox>
			<MiddleBox>
				<AlignInputs>
					<ComplementName
						id="outlined-basic"
						type="text"
						value={option.name}
						name={name}
						label="Nome do complemento"
						onChange={handleChange}
						inputProps={{ maxLength: 80 }}
						error={touchedName && Boolean(errorName)}
						helperText={touchedName && errorName}
					/>
					<Box>
						<SwitchButton>
							<SwitchText>{isSelected ? 'Ativado' : 'Desativado'}</SwitchText>
							<Switch
								checked={isSelected}
								onChange={() => {
									setFieldValue(available, !isSelected);
									setIsSelected(!isSelected);
								}}
								inputProps={{ 'aria-label': 'status switch' }}
							/>
						</SwitchButton>
					</Box>
					<SinglePrice
						id="outlined-basic"
						name={optionPrice}
						value={moneyMask(option.optionPrice)}
						placeholder="R$ 0,00"
						onChange={handleChange}
						variant="outlined"
						inputProps={{ maxLength: 15 }}
						error={touchedPrice && Boolean(errorPrice)}
						helperText={touchedPrice && errorPrice}
					/>
					<DeleteButton onClick={() => optionArrayHelpers.remove(index)}>
						<DeleteOutlineOutlinedIcon />
					</DeleteButton>
				</AlignInputs>

				<InuputDescription
					value={option.description}
					name={description}
					label="Descrição"
					onChange={handleChange}
					inputProps={{ maxLength: 80 }}
					error={touchedDescription && Boolean(errorDescription)}
					helperText={touchedDescription && errorDescription}
				/>
			</MiddleBox>
		</ContentBox>
	);
};
