import { Box, Grid, styled } from '@mui/material';

export const FormBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	backgroundColor: theme.palette.neutral.light,
	width: '70%',
	minHeight: '50vh',
	maxHeight: '50vh',
	flexGrow: 1,
	padding: '69px 60px 55px 65px',
	flexDirection: 'column',
	justifyContent: 'space-between',
	borderRadius: 40,
	[theme.breakpoints.down('md')]: {
		margin: 'auto',
		maxWidth: '90%',
		maxHeight: '90vh',
		padding: '40px 40px 40px 40px',
	},
}));

export const FormHeader = styled(Box)(() => ({
	display: 'flex',
	marginLeft: 48,
	cursor: 'pointer',
	zIndex: 1,
	position: 'absolute',
	top: 30,
	left: -20,
}));

export const FormBody = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	flexGrow: 1,
	padding: '25px 0px 40px 0px',
	marginTop: 50,
}));

export const FormBodyHeader = styled(Box)(() => ({
	maxHeight: 100,
	display: 'flex',
	flexGrow: 1,
}));
export const StyledFormText = styled(Box)(({ theme }) => ({
	fontSize: 28,
	lineHeight: '36px',
	color: theme.palette.neutral.dark,
	fontFamily: 'Rubik',
	fontWeight: 700,
}));

export const StyledFormSubText = styled(Box)(({ theme }) => ({
	fontSize: 16,
	lineHeight: '20px',
	color: theme.palette.neutral.dark1,
	fontFamily: 'Rubik',
	fontWeight: 400,
}));
export const BodyGrid = styled(Grid)(() => ({}));
BodyGrid.defaultProps = {
	container: true,
	spacing: 3,
};
export const ItemGrid = styled(Grid)(() => ({}));
ItemGrid.defaultProps = {
	item: true,
};
export const StyledBackArrow = styled(Box)(() => ({
	cursor: 'pointer',
	width: 32,
	height: 32,
}));
export const FormFooter = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexGrow: 1,
	maxHeight: 50,
}));
