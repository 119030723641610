import CloseIcon from "@mui/icons-material/Close";
import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CouponPercent,
  CouponStatus,
} from "../../../pages/Promotion/PromotionDetails/interface/coupon.interface";
import { getCouponsPercent } from "../../../shared/helpers/couponsPercent";
import { getCouponHistoryByPromotion } from "../../../utils/api/get/getCouponHistoryByPromotion";
import { getCouponsStatusByPromotion } from "../../../utils/api/get/getCouponsStatusByPromotion";
import { getRamsomedCouponsByWeek } from "../../../utils/api/get/getRamsomedCouponsByWeek";
import { dateFormat } from "../../../utils/mask.util";
import CouponsChart from "../../Dashboard/DashboardChart/CouponsChart";
import { DataStatsProps } from "../../Dashboard/DashboardChart/interface/chart-data.interface";
import CouponsPromotionModalStatusCard from "./CouponsPromotionModalStatusCard/CouponsPromotionModalStatusCard";
import { ILastCoupons } from "./CouponsPromotionModalStatusCard/interface/lastCoupons.interface";
import CouponsPromotionModalUsedCard from "./CouponsPromotionModalUsedCard/CouponsPromotionModalUsedCard";

import { CouponsPromotionModalProps } from "./interface/modalPromotion.interface";

import {
  StyledDialog,
  StyledIconButton,
  StyledPromotionContainer,
  StyledPromotionCouponsContainer,
  StyledPromotionDates,
  StyledPromotionFirstRowContainer,
  StyledPromotionImg,
  StyledPromotionRedeemedCoupons,
  StyledPromotionSecondRowContainer,
  StyledPromotionTitle,
  StyledPromotionTitleContainer,
  StyledRamsomedCouponChartContainer,
  StyledRamsomedCouponContainer,
} from "./styles";

const CouponsPromotionModal: React.FC<CouponsPromotionModalProps> = ({
  handleClosePromotionModal,
  openPromotionModal,
  promotion,
}: CouponsPromotionModalProps): JSX.Element => {
  const [coupon, setCoupon] = useState<CouponStatus>();
  const [couponPercent, setCouponPercent] = useState<CouponPercent>();
  const [ramsomedCoupons, setRamsomedCoupons] = useState<DataStatsProps>(
    {} as DataStatsProps
  );
  const [lastUsedCoupons, setLastUsedCoupons] = useState<ILastCoupons[]>();
  const [loaderChart, setLoaderChart] = useState(true);
  const [loaderUsedCard, setLoaderUsedCard] = useState(true);

  const getCouponsByPromotion = (): void => {
    if (promotion?.has_coupons) {
      getCouponsStatusByPromotion(promotion.id).then((res) => {
        setCoupon(res?.data);
      });
    }
  };

  const getRamsomedCouponsByPromotion = (): void => {
    getRamsomedCouponsByWeek(promotion.id).then((res) => {
      setRamsomedCoupons(res);
      setLoaderChart(false);
    });
  };

  const getLastUsedCouponsByPromotion = (): void => {
    getCouponHistoryByPromotion(promotion.id).then((res) => {
      setLastUsedCoupons(res?.data);
      setLoaderUsedCard(false);
    });
  };

  const handleCouponPercent = (): void => {
    if (coupon && promotion?.coupon_batch?.qtd_coupons) {
      const total = promotion.coupon_batch.qtd_coupons;
      const NewCouponValue = getCouponsPercent(total, coupon);
      setCouponPercent(NewCouponValue);
    }
  };

  useEffect(() => {
    getCouponsByPromotion();
    getRamsomedCouponsByPromotion();
    getLastUsedCouponsByPromotion();
  }, []);

  useEffect(() => {
    handleCouponPercent();
  }, [coupon, setCoupon, promotion?.coupon_batch?.qtd_coupons]);

  return (
    <StyledDialog
      open={openPromotionModal}
      onClose={handleClosePromotionModal}
      fullWidth
      maxWidth="lg"
    >
      <StyledPromotionImg>
        {coupon && coupon.used > 0 && (
          <StyledPromotionRedeemedCoupons>
            {couponPercent?.UsedPercentValue} dos cupons já foram utilizados!
          </StyledPromotionRedeemedCoupons>
        )}
        {couponPercent ? (
          <img src={promotion?.image.url} />
        ) : (
          <Skeleton
            sx={{
              height: "100%",
              width: "100%",
              zIndex: -1,
            }}
            animation="wave"
            variant="rectangular"
          />
        )}
      </StyledPromotionImg>
      <StyledIconButton onClick={handleClosePromotionModal}>
        <CloseIcon color="inherit" fontSize="small" />
      </StyledIconButton>
      <StyledPromotionContainer>
        <StyledPromotionFirstRowContainer>
          <StyledPromotionTitleContainer>
            <StyledPromotionTitle>{promotion.title}</StyledPromotionTitle>
            <StyledPromotionDates>
              Criado em {dateFormat(promotion.promotion_initial_date)} • Expira
              em {dateFormat(promotion.promotion_expires_date)}
            </StyledPromotionDates>
          </StyledPromotionTitleContainer>
          <StyledPromotionCouponsContainer>
            <CouponsPromotionModalStatusCard
              value={coupon?.available}
              text={"Cupons disponíveis"}
              color={"#15C589"}
              percent={couponPercent?.AvaiblePercentValue}
            />
            <CouponsPromotionModalStatusCard
              value={coupon?.ransomed}
              text={"Cupons resgatados"}
              color={"#FFC940"}
              percent={couponPercent?.RedeemedPercentValue}
            />
            <CouponsPromotionModalStatusCard
              value={coupon?.used}
              text={"Cupons utilizados"}
              color={"#4D92FA"}
              percent={couponPercent?.UsedPercentValue}
            />
          </StyledPromotionCouponsContainer>
        </StyledPromotionFirstRowContainer>
        <StyledPromotionSecondRowContainer>
          <StyledRamsomedCouponContainer>
            <CouponsPromotionModalUsedCard
              lastUsedCoupons={lastUsedCoupons}
              loaderUsedCard={loaderUsedCard}
            />
          </StyledRamsomedCouponContainer>
          <StyledRamsomedCouponChartContainer>
            <CouponsChart
              data={ramsomedCoupons}
              loader={loaderChart}
              text={"Atividade de utilização"}
            />
          </StyledRamsomedCouponChartContainer>
        </StyledPromotionSecondRowContainer>
      </StyledPromotionContainer>
    </StyledDialog>
  );
};

export default CouponsPromotionModal;
