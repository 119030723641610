import { Box, styled } from '@mui/material';
import { Form } from 'formik';
import { ReactComponent as IntroductionImg } from '../../assets/images/introductionImg.svg';
import { ReactComponent as IntroductionImgCellphone } from '../../assets/images/introductionImgCellphone.svg';

export const LandingPageContainer = styled(Box)(({ theme }) => ({
	display: 'grid',
	gridTemplateRows: '0.5fr 4.5fr 4.5fr 4.5fr 4.5fr 4.5fr 4.5fr 4.5fr 4.5fr',
	height: 6014,
	backgroundColor: theme.palette.neutral.light1,
}));

export const NavBarGrid = styled(Box)(({ theme }) => ({
	gridColumn: '1 / 4',
	backgroundColor: theme.palette.neutral.light,
	height: 136,
	display: 'flex',
	zIndex: 2,
	position: 'sticky',
	top: 0,
	'@media (orientation: portrait)': {
		position: 'unset',
	},
}));

export const FirstGrid = styled(Box)(() => ({
	gridColumn: '1 / 4',
	gridTemplateColumns: '2.3fr 1.6fr',
	background: 'linear-gradient(180deg, #481C8A 20.31%, #6029B1 100%);',
	display: 'grid',
	position: 'relative',
	'@media only screen and (max-width: 1400px)': {
		gridTemplateColumns: '1fr 2fr',
	},
	'@media (orientation: portrait)': {
		display: 'block',
		padding: 0,
		paddingBlock: 110,
		height: 'unset',
	},
}));

export const FirstGridFirstColumn = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'relative',
	padding: '0% 6% 16% 5%',
}));

export const FirstGridSecondColumn = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'end',
	justifyContent: 'end',
	zIndex: 1,
	'@media only screen and (max-width: 1400px)': {
		paddingRight: '50px',
	},
	'@media (orientation: portrait)': {
		paddingRight: '20px',
		paddingLeft: '20px',
	},
}));

export const StyledForm = styled(Form)(() => ({
	'@media (orientation: portrait)': {
		width: '100%',
	},
}));

export const StyledTopElipse = styled(Box)(() => ({
	position: 'absolute',
	zIndex: -3,
	top: -150,
	right: 0,
	'@media (orientation: portrait)': {
		display: 'none',
	},
}));

export const StyledLeftElipse = styled(Box)(() => ({
	display: 'none',
	'@media (orientation: portrait)': {
		display: 'block',
		position: 'absolute',
		zIndex: -1,
		top: 300,
		left: 0,
	},
}));

export const SecondGrid = styled(Box)(({ theme }) => ({
	gridColumn: '1 / 4',
	backgroundColor: theme.palette.neutral.light,
	height: 805,
	gridTemplateRows: '1fr 3fr',
	display: 'grid',
	'@media (orientation: portrait)': {
		display: 'block',
		padding: 0,
		paddingBlock: 110,
		height: 'unset',
	},
}));

export const ThirdGrid = styled(Box)(() => ({
	gridColumn: '1 / 4',
	width: '100%',
	display: 'grid',
	background: 'linear-gradient(180deg, #481C8A 20.31%, #6029B1 100%);',
	height: 805,
	'@media (orientation: portrait)': {
		display: 'block',
		padding: 0,
		paddingBlock: 110,
		height: 'unset',
	},
}));

export const FourthGrid = styled(Box)(({ theme }) => ({
	gridColumn: '1 / 4',
	width: '100%',
	height: 1400,
	display: 'block',
	backgroundColor: theme.palette.neutral.light,
	zIndex: 1,
	'@media (orientation: portrait)': {
		display: 'block',
		padding: 0,
		paddingBlock: 110,
		height: 1700,
	},
}));

export const FifthGrid = styled(Box)(() => ({
	gridColumn: '1 / 4',
	display: 'flex',
	padding: '84px 156px 70px 156px',
	background: 'linear-gradient(180deg, #481C8A 20.31%, #6029B1 100%);',
	zIndex: 0,
	height: 805,
	'@media only screen and (max-width: 1400px)': {
		padding: '44px 10px 40px 10px',
	},
	'@media (orientation: portrait)': {
		display: 'block',
		padding: 0,
		paddingBlock: 110,
		height: 'unset',
	},
}));

export const SixthGrid = styled(Box)(({ theme }) => ({
	gridColumn: '1 / 4',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	height: 805,
	backgroundColor: theme.palette.neutral.light,
	'@media (orientation: portrait)': {
		height: 1800,
	},
}));

export const SeventhGrid = styled(Box)(({ theme }) => ({
	gridColumn: '1 / 4',
	display: 'flex',
	padding: '20px 0px 100px 84px',
	background: theme.palette.neutral.light,
	zIndex: 0,
	height: 805,
	'@media only screen and (max-width: 1400px)': {
		padding: '0px',
		height: '700px',
	},
	'@media (orientation: portrait)': {
		display: 'block',
		padding: 0,
		paddingBlock: 110,
		height: 'unset',
	},
}));

export const EighthGrid = styled(Box)(() => ({
	gridColumn: '1 / 4',
	boxSizing: 'border-box',
	display: 'flex',
	alignItems: 'center',
	padding: '118px 156px 118px 156px',
	justifyContent: 'center',
	width: '100%',
	background: 'linear-gradient(180deg, #481C8A 20.31%, #6029B1 100%);',
	height: 1000,
	'@media (orientation: portrait)': {
		display: 'block',
		padding: 0,
		paddingBlock: 110,
		height: 'unset',
	},
}));

export const NinthGrid = styled(Box)(({ theme }) => ({
	gridColumn: '1 / 4',
	display: 'flex',
	padding: '62px 180px 90px 166px',
	backgroundColor: theme.palette.neutral.main2,
	'@media (orientation: portrait)': {
		display: 'block',
		padding: 0,
		paddingBlock: 110,
		height: 'unset',
	},
}));
export const TenthGrid = styled(Box)(({ theme }) => ({
	gridColumn: '1 / 4',
	display: 'flex',
	padding: '20px 180px 20px 166px',
	backgroundColor: theme.palette.neutral.main2,
	'@media (orientation: portrait)': {
		display: 'block',
		padding: 0,
		height: 'unset',
	},
}));

export const IntroductionImgSVG = styled(IntroductionImg)(() => ({
	zIndex: 1,
	'@media only screen and (max-width: 1400px)': {
		width: 440,
		marginTop: -116,
	},
	'@media only screen and (max-width: 1200px)': {
		width: 400,
		marginTop: -100,
	},
	'@media (orientation: portrait)': {
		display: 'none',
	},
}));
export const StyledIntroductionImgSVG = styled(IntroductionImgCellphone)(
	() => ({
		zIndex: 1,
		display: 'none',
		'@media (orientation: portrait)': {
			display: 'flex',
			height: 375,
			width: 350,
			margin: '0px auto',
		},
	})
);
