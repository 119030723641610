import { Box, Typography, styled } from '@mui/material';

export const SwitchButton = styled(Box)(() => ({
	display: 'flex',
	alignItems: ' center',
	justifyContent: 'space-between',
	width: 130,
	height: 52,
}));

export const WrapperOptionName = styled(Box)(() => ({
	marginLeft: 15,
}));

export const OptionName = styled(Typography)(({ theme }) => ({
	fontSize: '18px',
	fontWeight: 500,
	fontFamily: 'Rubik',
	color: theme.palette.common.black,
}));

export const PreviewDetails = styled(Typography)(({ theme }) => ({
	fontSize: '16px',
	fontWeight: 400,
	fontFamily: 'Rubik',
	color: theme.palette.neutral.dark1,
}));
