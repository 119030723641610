import CloseIcon from "@mui/icons-material/Close";
import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ChevronIcon } from "../../../assets/images/chevronIcon.svg";
import { ILastComments } from "../../../pages/Dashboard/interface/lastComments.interface";
import LastComments from "../../LastComments/LastComments";
import LastCommentsWithAnswers from "../../LastCommentsWithAnswers/LastCommentsWithAnswers";
import EmptyCard from "../EmptyCard/EmptyCard";
import { LastCommentsProps } from "./interface/lastComments-data.interface";
import {
  StyledAvatar,
  StyledChevronIcon,
  StyledCommentSkeleton,
  StyledCommentsTitleTypography,
  StyledDot,
  StyledEmptyCard,
  StyledIconButton,
  StyledLastComment,
  StyledLastCommentContent,
  StyledLastCommentText,
  StyledLastCommentsList,
  StyledLastCommentsTitle,
  StyledLastCommentsTitleTypography,
  StyledTitleContainer,
  StyledTitleSkeleton,
  StyledTitleTypography,
} from "./styles";

const LastCommentSkeleton: React.FC = (): JSX.Element => (
  <StyledLastComment>
    <StyledLastCommentContent>
      <StyledAvatar>
        <Skeleton
          animation="wave"
          variant="circular"
          sx={{
            width: 40,
            height: 40,
            "@media only screen and (max-width: 1400px)": {
              width: 27,
              height: 27,
            },
          }}
        />
      </StyledAvatar>
      <StyledLastCommentText>
        <StyledTitleSkeleton>
          <StyledTitleTypography>
            <Skeleton
              animation="wave"
              style={{ marginBottom: 6, marginTop: 3 }}
              sx={{
                height: 15,
                width: 350,
                "@media only screen and (max-width: 1400px)": {
                  height: 15,
                  width: 200,
                },
              }}
            />
          </StyledTitleTypography>
        </StyledTitleSkeleton>
        <StyledCommentSkeleton>
          <Skeleton
            animation="wave"
            sx={{
              height: 15,
              width: 350,
              "@media only screen and (max-width: 1400px)": {
                height: 15,
                width: 240,
              },
            }}
          />
        </StyledCommentSkeleton>
      </StyledLastCommentText>
    </StyledLastCommentContent>
    <StyledDot></StyledDot>
  </StyledLastComment>
);

const LastCommentsCard: React.FC<LastCommentsProps> = ({
  comments,
  loader,
  promotionDetails,
  handleCloseCommentsModal,
  totalComments,
  getLastCommentsByAdvertiser,
}: LastCommentsProps): JSX.Element => {
  const [titleText, setTitleText] = useState("");
  const navigate = useNavigate();

  const commentsArray = comments.slice(0, 3);

  const titleComments = (): void => {
    let titleText = "Últimos comentários";
    if (totalComments === 0 && totalComments <= 1) {
      titleText = `${totalComments} comentário`;
    } else if (totalComments && totalComments > 1) {
      titleText = `${totalComments} comentários`;
    }
    setTitleText(titleText);
  };

  useEffect(() => {
    titleComments();
  }, []);

  return (
    <>
      <StyledLastCommentsTitle>
        <StyledTitleContainer>
          {loader ? (
            <StyledLastCommentsTitleTypography>
              <Skeleton
                sx={{
                  height: 22,
                  width: 200,
                  borderRadius: 8,
                  marginLeft: -1,
                }}
                animation="wave"
                variant="rectangular"
              />
            </StyledLastCommentsTitleTypography>
          ) : (
            <StyledTitleContainer>
              <StyledCommentsTitleTypography>
                {titleText}
              </StyledCommentsTitleTypography>
            </StyledTitleContainer>
          )}
        </StyledTitleContainer>
        {promotionDetails ? (
          <StyledIconButton onClick={handleCloseCommentsModal}>
            <CloseIcon />
          </StyledIconButton>
        ) : (
          <StyledChevronIcon onClick={() => navigate("/comment")}>
            <ChevronIcon />
          </StyledChevronIcon>
        )}
      </StyledLastCommentsTitle>
      {loader ? (
        <StyledLastCommentsList>
          {Array(promotionDetails ? 8 : 3)
            .fill(null)
            .map((_, index) => (
              <LastCommentSkeleton key={index} />
            ))}
        </StyledLastCommentsList>
      ) : commentsArray.length > 0 ? (
        <StyledLastCommentsList>
          {promotionDetails &&
            commentsArray.map((data: ILastComments, index) => (
              <LastCommentsWithAnswers
                data={data}
                key={index}
                promotionDetails={promotionDetails}
                getLastCommentsByAdvertiser={getLastCommentsByAdvertiser}
              />
            ))}
          {!promotionDetails &&
            commentsArray.map((data: ILastComments, index) => (
              <LastComments
                data={data}
                key={index}
                promotionDetails={promotionDetails}
              />
            ))}
        </StyledLastCommentsList>
      ) : (
        <StyledEmptyCard promotionDetails={promotionDetails}>
          <EmptyCard
            text={"Você ainda não recebeu nenhum comentário."}
            image={"comments"}
          />
        </StyledEmptyCard>
      )}
    </>
  );
};

export default LastCommentsCard;
