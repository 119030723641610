import Modal from '@mui/material/Modal';
import { ReactComponent as XIcon } from '../../../assets/images/XIcon.svg';
import PlansToChoose from '../PlansToChoose/PlansToChoose';
import { IChangePlanModal } from '../interfaces';
import {
	CloseModal,
	ContainerModal,
	ModalSubtitle,
	ModalTitle,
	TitleAndIcon,
} from './styles';

const ChangePlanModal: React.FC<IChangePlanModal> = ({
	open,
	handleClose,
	handleIconPlansToChoose,
}: IChangePlanModal): JSX.Element => (
	<Modal open={open} onClose={handleClose}>
		<ContainerModal>
			<TitleAndIcon>
				<ModalTitle>Trocar Plano</ModalTitle>
				<CloseModal onClick={handleClose}>
					<XIcon color="#747476" />
				</CloseModal>
			</TitleAndIcon>
			<ModalSubtitle>O plano escolhido anteriormente foi Gold</ModalSubtitle>
			<PlansToChoose
				planType="Silver"
				oldPrice="R$ 139,90"
				newPrice="R$ 69,90"
				firstBenefit="5 promoções limitadas até 3 dias"
				secondeBenefit="1 promoção em destaque na aba da categoria do produto."
				handleIconPlansToChoose={handleIconPlansToChoose}
				handleClose={handleClose}
			/>

			<PlansToChoose
				planType="Platinum"
				oldPrice="R$ 379,90"
				newPrice="R$ 299,90"
				firstBenefit="30 promoções limitadas até 7 dias;"
				secondeBenefit="5 promoções em destaque na aba da categoria do produto."
				handleIconPlansToChoose={handleIconPlansToChoose}
				handleClose={handleClose}
			/>
		</ContainerModal>
	</Modal>
);

export default ChangePlanModal;
