import { Box, Button, styled, Typography } from "@mui/material";

export const AlignContentAndButton = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const CardNameAndFlag = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginTop: "28px",
}));

export const ChangeButton = styled(Button)(() => ({
  height: "50px",
  marginTop: "5px",
}));

export const ChangeButtonText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 400,
  color: theme.palette.primary.main,
  textTransform: "none",
  textDecoration: "underline",
}));

export const InfoPaymentContent = styled(Box)(({ theme }) => ({
  width: "1367px",
  height: "110px",
  marginLeft: "55px",
  marginTop: "30px",
  padding: "0px 28px 0px 28px",
  borderRadius: "8px",
  border: `1px solid ${theme.palette.neutral.light2}`,
}));

export const PaymentText = styled(Box)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 400,
  marginLeft: "28px",
  color: theme.palette.neutral.dark1,
}));

export const PaymentTitle = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 500,
  marginTop: "18px",
  color: theme.palette.common.black,
}));

export const TypeCard = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  marginLeft: "16px",
  fontWeight: 400,
  color: theme.palette.common.black,
}));
