import { Box, Button, Dialog, IconButton, styled } from "@mui/material";

import { ReactComponent as WarningCheck } from "../../../assets/images/warningCheck.svg";

export const StyledPromotionListContainer = styled(Box)(() => ({
  display: "flex",
  height: 30,
  backgroundColor: "yellow",
  width: "100%",
}));

export const StyledDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    borderRadius: 15,
    width: 495,
    "@media only screen and (max-width: 1200px)": {
      width: 395,
    },

    "& .MuiDialogContent-root": {
      display: "flex",
    },

    "& .MuiDialogActions-root": {
      display: "flex",
    },
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 8,
  top: 8,
  color: theme.palette.neutral.dark1,
}));

export const StyledExclamationIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  borderRadius: "50%",
  color: theme.palette.neutral.light,
  backgroundColor: theme.palette.warning.main,
  padding: 10,
  fontSize: 50,
  marginBottom: 52,
}));

export const StyledModalTitle = styled(Box)(({ theme }) => ({
  fontSize: 20,
  fontFamily: "Rubik",
  fontWeight: 600,
  color: theme.palette.neutral.dark,
  marginTop: 24,
  lineHeight: "24px",
  marginLeft: 36,
  marginBottom: 12,
  "@media only screen and (max-width: 1400px)": {
    fontSize: 18,
  },
  "@media only screen and (max-width: 1200px)": {
    fontSize: 16,
    marginTop: 0,
    lineHeight: "20px",
  },
}));

export const StyledModalText = styled(Box)(({ theme }) => ({
  width: "85%",
  fontSize: 16,
  fontFamily: "Rubik",
  fontWeight: 400,
  color: theme.palette.neutral.dark1,
  lineHeight: "20px",
  marginBottom: 12,
  textAlign: "start",
  marginLeft: 36,
  "@media only screen and (max-width: 1400px)": {
    fontSize: 14,
  },
  "@media only screen and (max-width: 1200px)": {
    fontSize: 12,
    lineHeight: "16px",
  },
}));

export const StyledButtonsContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  width: "100%",
  marginTop: 36,
  marginBottom: 32,
  "@media only screen and (max-width: 1200px)": {
    marginTop: 16,
  },
}));

export const StyledCancelButton = styled(Box)(({ theme }) => ({
  boxSizing: "border-box",
  width: 209,
  fontSize: 18,
  fontWeight: 700,
  borderRadius: 8,
  fontFamily: "Rubik",
  border: "1px solid",
  borderColor: theme.palette.neutral.dark2,
  color: theme.palette.primary.main,
  padding: "16px 32px",
  cursor: "pointer",
  userSelect: "none",
  textAlign: "center",
  "@media only screen and (max-width: 1200px)": {
    fontSize: 16,
    width: 150,
    padding: "12px 32px",
  },
}));

export const StyledConfirmButton = styled(Button)(({ theme }) => ({
  width: 209,
  fontSize: 18,
  fontWeight: 700,
  borderRadius: 8,
  fontFamily: "Rubik",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.neutral.light,
  lineHeight: "normal",
  padding: "16px 32px",
  cursor: "pointer",
  userSelect: "none",
  textTransform: "none",
  "@media only screen and (max-width: 1200px)": {
    fontSize: 16,
    width: 150,
    padding: "12px 32px",
  },
}));

export const StyledErrorCheckIcon = styled(WarningCheck)(() => ({
  marginTop: 34,
  marginLeft: 36,
  "@media only screen and (max-width: 1200px)": {
    width: 50,
    marginTop: 24,
  },
}));
