import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { FieldArray, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { ReactComponent as InformationIcon } from '../../../../assets/images/informationIcon.svg';
import { ICredit, ICurrentPayments } from '../../../../utils/api/interfaces';
import SaveButton from '../../../Buttons/SaveButton/SaveButton';
import ConfirmResetFormModal from '../ConfirmResetFormModal/ConfirmResetFormModal';
import {
	GridContainer,
	PaymentFormContainer,
	PaymentTitle,
	StyledButton,
	StyledButtonsContainer,
	StyledPaymentContainer,
	StyledResetFormButton,
	StyledTextContainer,
	StyledUserWarning,
	TypographyText,
	TypographyTitle,
} from './styles';

export interface CreditDebitFormProps {
	active: string;
	credit: ICredit;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
}

const CreditDebitForm: React.FC<CreditDebitFormProps> = ({
	active,
	credit,
	setFieldValue,
}: CreditDebitFormProps): JSX.Element => {
	const [checked, setChecked] = useState(credit.active);

	const handleSwitchChange = () => {
		setChecked(!checked);
		setFieldValue(active, !checked);
	};

	useEffect(() => {
		if (credit.active !== checked) {
			setChecked(credit.active);
		}
	}, [credit.active]);

	return (
		<StyledPaymentContainer key={credit.id}>
			<FormControlLabel
				control={<Checkbox checked={checked} onChange={handleSwitchChange} />}
				label={credit.banner_name}
			/>
		</StyledPaymentContainer>
	);
};

const PaymentForm: React.FC = () => {
	const [showModalConfirmResetForm, setShowModalConfirmResetForm] =
		useState(false);
	const {
		values: { cashDown, credit, debit },
		values,
		setFieldValue,
		resetForm,
	} = useFormikContext<ICurrentPayments>();

	const openModalConfirmResetForm = (): void => {
		setShowModalConfirmResetForm(true);
	};

	const closeModalConfirmResetForm = (): void => {
		setShowModalConfirmResetForm(false);
	};

	const setBooleanFieldsToFalse = (obj: ICurrentPayments): ICurrentPayments => {
		if (obj.cashDown) {
			if (typeof obj.cashDown.cash === 'boolean') obj.cashDown.cash = false;
			if (typeof obj.cashDown.pix === 'boolean') obj.cashDown.pix = false;
			if (typeof obj.cashDown.credit_card === 'boolean')
				obj.cashDown.credit_card = false;
			if (typeof obj.cashDown.debit_card === 'boolean')
				obj.cashDown.debit_card = false;
		}

		const setArrayItemsActiveFalse = (arr: any[]) => {
			arr.forEach((item) => {
				if (typeof item.active === 'boolean') {
					item.active = false;
				}
			});
		};

		if (Array.isArray(obj.debit)) {
			setArrayItemsActiveFalse(obj.debit);
		}
		if (Array.isArray(obj.credit)) {
			setArrayItemsActiveFalse(obj.credit);
		}
		return obj;
	};

	const handleResetForm = (): void => {
		const initialValues = setBooleanFieldsToFalse(values);
		resetForm({ values: initialValues });
		closeModalConfirmResetForm();
	};
	const handleSwitchChange = (fieldName: keyof typeof cashDown): void => {
		setFieldValue(`cashDown.${fieldName}`, !cashDown[fieldName]);
	};

	return (
		<PaymentFormContainer>
			<Box display={'flex'} justifyContent={'space-between'}>
				<StyledTextContainer>
					<TypographyTitle>Formas de pagamento</TypographyTitle>
					<TypographyText>
						Selecione as formas de pagamento aceitas pela sua loja
					</TypographyText>
				</StyledTextContainer>
				<StyledUserWarning>
					<InformationIcon style={{ marginRight: 10 }} />
					Essas informações ficarão visíveis para os usuários no aplicativo.
				</StyledUserWarning>
			</Box>

			<Box mb={5}>
				<StyledTextContainer>
					<PaymentTitle>À vista</PaymentTitle>
					<TypographyText>Dinheiro e pix</TypographyText>
				</StyledTextContainer>
				<StyledPaymentContainer>
					<FormControlLabel
						control={
							<Checkbox
								checked={cashDown.cash}
								onChange={() => handleSwitchChange('cash')}
							/>
						}
						label="Dinheiro"
					/>
				</StyledPaymentContainer>
				<StyledPaymentContainer>
					<FormControlLabel
						control={
							<Checkbox
								checked={cashDown.pix}
								onChange={() => handleSwitchChange('pix')}
							/>
						}
						label="Pix"
					/>
				</StyledPaymentContainer>
			</Box>
			<Box display={'flex'}>
				<Box mr={10}>
					<StyledTextContainer>
						<PaymentTitle>Débito</PaymentTitle>
						<TypographyText>Bandeiras de cartão</TypographyText>
					</StyledTextContainer>

					<FieldArray name="debit">
						{() => (
							<React.Fragment>
								{debit.map((debit, index) => {
									const active = `debit[${index}].active`;
									return (
										<CreditDebitForm
											key={index}
											active={active}
											credit={debit}
											setFieldValue={setFieldValue}
										/>
									);
								})}
							</React.Fragment>
						)}
					</FieldArray>
				</Box>
				<Box mr={10}>
					<StyledTextContainer>
						<PaymentTitle>Crédito</PaymentTitle>
						<TypographyText>Bandeiras de cartão</TypographyText>
					</StyledTextContainer>

					<FieldArray name="credit">
						{() => (
							<GridContainer>
								{credit.map((credit, index) => {
									const active = `credit[${index}].active`;
									return (
										<CreditDebitForm
											key={index}
											active={active}
											credit={credit}
											setFieldValue={setFieldValue}
										/>
									);
								})}
							</GridContainer>
						)}
					</FieldArray>
				</Box>
			</Box>

			<StyledButtonsContainer>
				<StyledResetFormButton onClick={openModalConfirmResetForm}>
					Limpar
				</StyledResetFormButton>
				<StyledButton>
					<SaveButton>Salvar alterações</SaveButton>
				</StyledButton>
			</StyledButtonsContainer>
			{showModalConfirmResetForm ? (
				<ConfirmResetFormModal
					close={closeModalConfirmResetForm}
					confirm={handleResetForm}
				/>
			) : null}
		</PaymentFormContainer>
	);
};

export default PaymentForm;
