import React from "react";
import SideNav from "../../components/SideNav/SideNav";
import Routes from "../../Routes";
import { StyledDashboard } from "./styles";

const Layout: React.FC = (): JSX.Element => {
  return (
    <StyledDashboard>
      <SideNav />
      <Routes />
    </StyledDashboard>
  );
};

export default Layout;
