import {
  CommentsParams,
  LastCommentResponse,
} from "../../../pages/Dashboard/interface/lastComments.interface";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";

export const getCommentsByPromotion = async (
  promotionId: string,
  params?: CommentsParams
): Promise<LastCommentResponse> => {
  try {
    const response = await request({
      url: `${BASE_URL}/comments/promotion/${promotionId}`,
      method: "get",
      params: params,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
