import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import { ReactComponent as PlansIcon } from '../../../assets/images/PlansIcon.svg';
import { ReactComponent as CommentIcon } from '../../../assets/images/commentIcon.svg';
import { ReactComponent as CouponIcon } from '../../../assets/images/couponIcon.svg';
import { ReactComponent as DashboardIcon } from '../../../assets/images/dashboardIcon.svg';
import { ReactComponent as FinancialIcon } from '../../../assets/images/financialIcon.svg';
import { ReactComponent as MyProfileIcon } from '../../../assets/images/myProfileIcon.svg';
import { ReactComponent as OrderIcon } from '../../../assets/images/orderIcon.svg';
import { ReactComponent as PromotionIcon } from '../../../assets/images/promotionIcon.svg';

export const BUTTON_ICONS = [
	{
		label: 'Dashboard',
		icon: <DashboardIcon />,
		url: '/dashboard',
	},
	{
		label: 'Promoções',
		icon: <PromotionIcon />,
		url: '/promotion',
	},
	{
		label: 'Pedidos',
		icon: <OrderIcon />,
		url: '/orders',
	},
	{
		label: 'Cardápio',
		icon: <RestaurantMenuIcon />,
		url: '/menu',
	},
	{
		label: 'Meus cupons',
		icon: <CouponIcon />,
		url: '/coupons',
	},
	{
		label: 'Meu perfil',
		icon: <MyProfileIcon />,
		url: '/profile',
	},
	{
		label: 'Planos',
		icon: <PlansIcon />,
		url: '/plans',
	},
	{
		label: 'Financeiro',
		icon: <FinancialIcon />,
		url: '/finance',
	},
	{
		label: 'Comentários',
		icon: <CommentIcon />,
		url: '/comment',
	},
];
