import { Box, TextField } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)(() => ({
  width: "100%",
  height: "100%",
}));

export const NumberAndDateAndCode = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  marginTop: "33px",
}));

export const InputNumber = styled(TextField)(() => ({
  width: "441px",

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
}));

export const InputDate = styled(TextField)(() => ({
  width: "182px",
  marginLeft: "28px",

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
}));

export const InputCode = styled(TextField)(() => ({
  width: "107px",
  marginLeft: "28px",

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
}));

export const NameAndCpf = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  marginTop: "33px",
}));

export const InputName = styled(TextField)(() => ({
  width: "441px",

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
}));

export const InputCPF = styled(TextField)(() => ({
  width: "317px",
  marginLeft: "28px",

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
  },
}));
