import {
	Box,
	Collapse,
	Table,
	TableCell,
	TableHead,
	Typography,
	styled,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { IColapseProp } from '../interfaces';

export const TableContent = styled(TableCell)(() => ({
	padding: 'none',
	borderBottom: 'none',
}));

export const TableHeader = styled(TableHead)(({ theme }) => ({
	backgroundColor: theme.palette.neutral.light1,
	borderTopLeftRadius: '8px',
	borderTopRightRadius: '8px',
}));

export const StyledFourthCell = styled(TableCell)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	whiteSpace: 'nowrap',
	borderBottom: 'none',
	fontWeight: 500,
}));

export const Container = styled(Table)(() => ({
	'& .MuiTableRow-root:last-child': {
		borderBottom: 'none',
	},
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.common.black,
}));

export const StyledSubTitle = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
}));

export const HeaderOptions = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
}));

export const OptionsIcon = styled(MenuIcon)(({ theme }) => ({
	fontSize: 32,
	color: theme.palette.primary.main,
	marginRight: 10,
}));

export const SwitchButton = styled(Box)(() => ({
	display: 'flex',
	alignItems: ' center',
	justifyContent: 'space-between',
	width: 130,
	height: 52,
	marginLeft: '4%',
}));

export const CollapseStyled = styled(Collapse)<IColapseProp>(
	({ openOptionsList }) => ({
		border: '1px solid #EDE8F4',
		borderRadius: '8px',
		paddingBottom: openOptionsList ? '40px' : 'none',
	})
);
