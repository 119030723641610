import { IScreenControllerButtonsProps } from "../interfaces";
import {
  AllPlans,
  ButtonsContainer,
  MyPlan,
  TextAllPlans,
  TextMyPlan,
} from "./styles";

const ScreenControllerButtons: React.FC<IScreenControllerButtonsProps> = ({
  buttonselected,
  setButtonselected,
}: IScreenControllerButtonsProps): JSX.Element => (
  <ButtonsContainer>
    <MyPlan
      buttonselected={buttonselected}
      onClick={() => setButtonselected(0)}
    >
      <TextMyPlan buttonselected={buttonselected}>Meu plano</TextMyPlan>
    </MyPlan>
    <AllPlans
      buttonselected={buttonselected}
      onClick={() => setButtonselected(1)}
    >
      <TextAllPlans buttonselected={buttonselected}>
        Todos os planos
      </TextAllPlans>
    </AllPlans>
  </ButtonsContainer>
);

export default ScreenControllerButtons;
