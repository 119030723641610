import { SideLogo, StyleSideImageContainer } from "./styles";

const SideImage: React.FC = (): JSX.Element => {
  return (
    <StyleSideImageContainer>
      <SideLogo />
    </StyleSideImageContainer>
  );
};

export default SideImage;
