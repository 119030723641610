import {
	applyMiddleware,
	combineReducers,
	legacy_createStore as createStore,
} from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import authReducer from '../reducer/auth.reducer';
import editOptionGroupReducer from '../reducer/editOptionGroup.reducer';
import sideNavReducer from '../reducer/sideNavReducer';
import toggleReducer from '../reducer/toggleSwitch.reducer';

export interface IReducers {
	sideNav: any;
	auth: any;
	toggle: any;
	editOptionGroup: any;
}

const persistedConfig = {
	key: 'root',
	storage,
	whitelist: ['auth', 'sideNav', 'toggle', 'editOptionGroup'],
};

const reducers = combineReducers({
	auth: authReducer,
	sideNav: sideNavReducer,
	toggle: toggleReducer,
	editOptionGroup: editOptionGroupReducer,
});

const persistedReducer = persistReducer(persistedConfig, reducers);

const storeConfig: any = createStore(persistedReducer, applyMiddleware(thunk));

const persistedStore = persistStore(storeConfig);

export { persistedStore, storeConfig };
