import { Box, styled, Tab, Tabs, TextField, Typography } from "@mui/material";
import { ReactComponent as ChevronIcon } from "../../../assets/images/whiteChevronIcon.svg";

interface StyledTabProps {
  label: string;
}

export const StyledCouponDetails = styled(Box)(() => ({
  display: "flex",
  width: "100%",
}));

export const StyledCouponDetailsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutral.light2,
  width: "100%",
}));

export const StyledCouponDetailsTopBar = styled(Box)(() => ({
  display: "flex",
  width: "100%",
}));

export const StyledTopBarContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: 56,
  alignItems: "center",
  backgroundColor: theme.palette.neutral.light,
  boxShadow: "4px 6px 15px rgba(0, 0, 0, 0.1)",
  padding: "32px 47px 32px 47px",
  justifyContent: "space-between",
  "@media only screen and (max-width: 1700px)": {
    padding: "22px 47px 22px 47px",
  },
  "@media only screen and (max-width: 1200px)": {
    padding: "11px 47px 11px 47px",
  },
}));

export const StyledTopBarTextContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const StyledTopBarTypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontFamily: "Rubik",
  fontWeight: 500,
  display: "baseline",
  color: theme.palette.neutral.dark,
  "@media only screen and (max-width: 1400px)": {
    fontSize: 20,
  },
  "@media only screen and (max-width: 1200px)": {
    fontSize: 16,
  },
}));

export const StyledTopBarTextIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 32,
  height: 32,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 16,
  marginRight: 24,
  cursor: "pointer",
  "@media only screen and (max-width: 1200px)": {
    width: 26,
    height: 26,
  },
}));

export const StyleChevronIcon = styled(ChevronIcon)(() => ({
  "@media only screen and (max-width: 1200px)": {
    width: 26,
    height: 12,
  },
}));

export const StyledTextContainer = styled(Box)(() => ({
  display: "block",
}));

export const TypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize: 24,
  fontFamily: "Rubik",
  fontWeight: 500,
  display: "baseline",
  color: theme.palette.neutral.dark,
  "@media only screen and (max-width: 1700px)": {
    fontSize: 20,
  },
  "@media only screen and (max-width: 1200px)": {
    fontSize: 16,
  },
}));

export const TypographyText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontFamily: "Rubik",
  fontWeight: 400,
  display: "baseline",
  color: theme.palette.neutral.dark1,
  "@media only screen and (max-width: 1200px)": {
    fontSize: 14,
  },
}));

export const StyledCouponContent = styled(Box)(({ theme }) => ({
  height: "calc(100% - 188px)",
  padding: "32px 44px 36px 44px",
  backgroundColor: theme.palette.neutral.light2,
  "@media (max-width: 1200px)": {
    padding: "24px 20px 32px 24px",
  },
}));

export const StyledListCouponContainer = styled(Box)(({ theme }) => ({
  display: "block",
  backgroundColor: theme.palette.neutral.light,
  padding: "36px 47px 36px 47px",
  borderRadius: 20,
  boxShadow: "4px 6px 15px rgba(0, 0, 0, 0.1)",
  "@media only screen and (max-width: 1700px)": {
    padding: "26px 30px 26px 30px",
  },
  "@media only screen and (max-width: 1200px)": {
    padding: "26px 20px 26px 20px",
  },
}));

export const StyledCouponStatusTopContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const StyledCouponStatusTabsContainer = styled(Box)(() => ({
  display: "flex",
  height: 60,
}));

export const CouponStatusTabs = styled(Tabs)({
  display: "flex",
});

export const CouponStatusTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  boxSizing: "border-box",
  fontSize: 18,
  fontWeight: 500,
  marginRight: 12,
  lineHeight: "24px",
  color: theme.palette.primary.main,
  borderRadius: 8,
  fontFamily: "Rubik",
  border: "1px solid",
  borderColor: theme.palette.neutral.dark2,
  padding: "16px 32px",
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.neutral.light,
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "@media only screen and (max-width: 1700px)": {
    padding: "12px 32px",
    fontSize: 16,
    lineHeight: "20px",
  },
  "@media (max-width: 1200px)": {
    fontSize: 14,
    padding: "8px 14px",
  },
}));

export const StyledSearchCoupon = styled(Box)(() => ({
  width: 439,
  "@media only screen and (max-width: 1700px)": {
    width: 350,
  },
}));

export const StyledSearchTextField = styled(TextField)(() => ({
  width: "100%",
  "& .MuiOutlinedInput-root ": {
    borderRadius: 8,
    height: 48,
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: 18,

    [`&.Mui-focused fieldset`]: {
      borderColor: "#6029B1",
    },
  },

  "& .MuiFormHelperText-root": {
    fontSize: "12px!important",
  },
  "@media only screen and (max-width: 1700px)": {
    fontSize: 12,
    "& input::placeholder": {
      fontSize: "13px!important",
    },
  },
}));
