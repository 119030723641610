import { CircularProgress, FormControl, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import { CommentAnswerProps } from "../../../utils/api/interfaces";
import { SIZE_BUTTON } from "../../Buttons/ContinueLoginButton/contants/button-config.constants";
import {
  StyledButton,
  StyledTypography,
} from "../../LastCommentsWithAnswers/styles";

const formHelperStyle = {
  fontSize: 13,
  fontFamily: "Rubik",
};

const customTextFieldStyles = () => ({
  width: "100%",
  fontFamily: "Rubik",

  "& .MuiOutlinedInput-root ": {
    "@media only screen and (max-width: 1400px)": {
      fontSize: 12,
      height: 34,
    },

    [`& input`]: {
      paddingTop: "10.5px",
      paddingBottom: "10.5px",
      "@media only screen and (max-width: 1400px)": {
        paddingTop: "5.5px",
        paddingBottom: "5.5px",
      },
    },

    [`&.Mui-focused fieldset`]: {
      borderColor: "#6029B1",
    },
  },

  "& .MuiFormLabel-root ": {
    top: "-6px",
    "@media only screen and (max-width: 1400px)": {
      fontSize: 12,
    },
  },
  [`& label[data-shrink='true']`]: {
    top: 0,
  },
  [`& fieldset`]: {
    borderRadius: 2,
  },
  [`& label.Mui-focused`]: {
    color: "#6029B1",
  },
});

type AnswerInputProps = {
  edit: boolean;
};

const AnswerInput: React.FC<AnswerInputProps> = ({ edit }): JSX.Element => {
  const {
    values: { newCommentAnswer },
    handleChange,
    touched,
    errors,
    isSubmitting,
  } = useFormikContext<CommentAnswerProps>();

  return (
    <>
      <FormControl variant="outlined" fullWidth>
        <TextField
          id="answer"
          variant="outlined"
          type="text"
          label="Resposta"
          value={newCommentAnswer}
          onChange={handleChange("newCommentAnswer")}
          inputProps={{
            maxLength: 250,
          }}
          error={touched.newCommentAnswer && Boolean(errors.newCommentAnswer)}
          helperText={touched.newCommentAnswer && errors.newCommentAnswer}
          FormHelperTextProps={{
            style: formHelperStyle,
          }}
          sx={customTextFieldStyles}
        />
      </FormControl>
      <StyledButton type="submit" variant="contained" disabled={isSubmitting}>
        {isSubmitting ? (
          <CircularProgress color="secondary" size={SIZE_BUTTON} />
        ) : (
          <StyledTypography variant="body1">
            {edit ? "Salvar" : "Postar"}
          </StyledTypography>
        )}
      </StyledButton>
    </>
  );
};

export default AnswerInput;
