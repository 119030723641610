import { Button } from "@mui/material";
import { ReactComponent as BlockIcon } from "../../../assets/images/BlockIcon.svg";
import { ReactComponent as StarIcon } from "../../../assets/images/StarIcon.svg";
import { ReactComponent as StoreIcon } from "../../../assets/images/StoreIcon.svg";

import {
  AlignIconAndName,
  CardContent,
  CardFooter,
  CardFooterText,
  CardPromotion,
  CardTitle,
  ContentCard,
  HighlightCard,
  IconsAndNameOnCard,
  PromotionAndHighlights,
  PromotionAndStoreOnHomeScreen,
  PromotionOnHomeScreen,
  StatusCard,
  StatusText,
  StoreOnHomeScreen,
  SubTitle,
  TextContentCard,
  Texts,
  TextTitle,
  Title,
  TitleCard,
  TotalAndQuantityUsed,
  UnderlineStyleButtonText,
} from "./styles";

export const PlanUse: React.FC = (): JSX.Element => (
  <>
    <Texts>
      <Title>Uso do plano</Title>
      <SubTitle>Confira os dados de uso do seu plano</SubTitle>
    </Texts>
    <PromotionAndHighlights>
      <CardPromotion>
        <CardTitle>
          <TextTitle>Promoções</TextTitle>
          <StatusCard>
            <StatusText>Esgotado</StatusText>
          </StatusCard>
        </CardTitle>
        <CardContent>
          <TotalAndQuantityUsed> 15/</TotalAndQuantityUsed>
          <TotalAndQuantityUsed>15</TotalAndQuantityUsed>
        </CardContent>
        <CardFooter>
          <CardFooterText>Promoções criadas</CardFooterText>
        </CardFooter>
      </CardPromotion>
      <HighlightCard>
        <CardTitle>
          <TextTitle>Destaques</TextTitle>
          <StatusCard>
            <StatusText>Esgotado</StatusText>
          </StatusCard>
        </CardTitle>
        <CardContent>
          <TotalAndQuantityUsed> 3/</TotalAndQuantityUsed>
          <TotalAndQuantityUsed>3</TotalAndQuantityUsed>
        </CardContent>
        <CardFooter>
          <CardFooterText>créditos usados</CardFooterText>
        </CardFooter>
      </HighlightCard>
    </PromotionAndHighlights>
    <PromotionAndStoreOnHomeScreen>
      <PromotionOnHomeScreen>
        <IconsAndNameOnCard>
          <AlignIconAndName>
            <StarIcon />
            <TitleCard>Promoção na tela inicial</TitleCard>
          </AlignIconAndName>
          <Button>
            <BlockIcon />
          </Button>
        </IconsAndNameOnCard>
        <ContentCard>
          <TextContentCard>Disponivel para o plano Platinum.</TextContentCard>
          <Button>
            <UnderlineStyleButtonText>Fazer upgrade</UnderlineStyleButtonText>
          </Button>
        </ContentCard>
      </PromotionOnHomeScreen>
      <StoreOnHomeScreen>
        <IconsAndNameOnCard>
          <AlignIconAndName>
            <StoreIcon />
            <TitleCard>Sua loja na tela inicial</TitleCard>
          </AlignIconAndName>
          <Button>
            <BlockIcon />
          </Button>
        </IconsAndNameOnCard>
        <ContentCard>
          <TextContentCard>Disponivel para o plano Platinum.</TextContentCard>
          <Button>
            <UnderlineStyleButtonText>Fazer upgrade</UnderlineStyleButtonText>
          </Button>
        </ContentCard>
      </StoreOnHomeScreen>
    </PromotionAndStoreOnHomeScreen>
  </>
);

export default PlanUse;
