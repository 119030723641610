import * as yup from "yup";

const VALIDATION_LOGIN_SCHEMA = yup.object({
  email: yup
    .string()
    .email("Entre um e-mail válido")
    .required("Por favor, preencha o campo acima"),
  password: yup
    .string()
    .min(7, "Senhas devem ter no minimo de 7 caracteres")
    .required("Por favor, preencha o campo acima"),
});

export default VALIDATION_LOGIN_SCHEMA;
