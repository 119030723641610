import { AxiosResponse } from "axios";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";

export const getMenuItensByCategory = async (
  id: string
): Promise<AxiosResponse<any> | undefined> => {
  try {
    return request({ url: `${BASE_URL}/itens/category/${id}`, method: "get" });
  } catch (error) {
    return Promise.reject(error);
  }
};
