interface Screens {
  [key: string]: string;
}

const categoriesFormat = (screenName: string): string => {
  const screens: Screens = {
    eletronicos: "Eletrônicos",
    hamburguer: "Hambúrguer",
    acai: "Açai",
    conveniencia: "Conveniência",
    farmacia: "Farmácia",
    familia: "Família",
    acougue: "Açougue",
    lojas: "Lojas",
  };

  return (
    screens[screenName] ?? screenName[0].toUpperCase() + screenName.slice(1)
  );
};

export default categoriesFormat;
