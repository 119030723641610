import {
  Container,
  InputCode,
  InputCPF,
  InputDate,
  InputName,
  InputNumber,
  NameAndCpf,
  NumberAndDateAndCode,
} from "./styles";

const InputContentPaymentIfSelected: React.FC = (): JSX.Element => (
  <Container>
    <NumberAndDateAndCode>
      <InputNumber id="number" label="Number" variant="outlined" />
      <InputDate id="date" label="Data" variant="outlined" />
      <InputCode id="code" label="CVC" variant="outlined" />
    </NumberAndDateAndCode>

    <NameAndCpf>
      <InputName id="street" label="Rua" variant="outlined" />
      <InputCPF id="number" label="Numero" variant="outlined" />
    </NameAndCpf>
  </Container>
);

export default InputContentPaymentIfSelected;
