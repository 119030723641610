import { Box, styled } from '@mui/material';

export const SkeletonContent = styled(Box)(({ theme }) => ({
	boxSizing: 'border-box',
	width: '32%',
	backgroundColor: theme.palette.neutral.light,
	borderRadius: 12,
	padding: '15px 15px 5px 15px',
	'@media only screen and (max-width: 1500px)': {
		padding: '23px 15px 13px 15px',
	},
}));
