import { AxiosResponse } from "axios";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";

export const getOptions = async (
  id: string
): Promise<AxiosResponse<any> | undefined> => {
  try {
    return request({
      url: `${BASE_URL}/options/optionGroup/${id}`,
      method: "get",
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
