import { ListItem, ListItemText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

interface ListItemStyledProp {
	isHovered?: boolean;
}

export const TypographyTitle = styled(Box)(() => ({
	fontSize: 18,
	lineHeight: '24px',
	fontFamily: 'Rubik',
	fontWeight: 500,
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	width: 100,
	display: 'block',
	overflow: 'hidden',
	'@media only screen and (max-width: 1700px)': {
		fontSize: 15,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 12,
		lineHeight: '16px',
		width: 50,
	},
}));

export const TypographyText = styled(Typography)(() => ({
	fontSize: 16,
	lineHeight: '24px',
	fontFamily: 'Rubik',
	fontWeight: 400,
	display: 'baseline',
	cursor: 'pointer',
	'@media only screen and (max-width: 1700px)': {
		fontSize: 14,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 10,
		lineHeight: '16px',
	},
}));

export const ListItemStyledOpen = styled(ListItem, {
	shouldForwardProp: (prop) => prop !== 'isHovered',
})<ListItemStyledProp>(({ theme, isHovered }) => ({
	width: isHovered ? 188 : 60,
	height: 46,
	padding: '11px 0px 11px 0px',
	borderRadius: 8,
	color: theme.palette.neutral.light,
	'@media only screen and (max-width: 1400px)': {
		width: isHovered ? 170 : 64,
	},
	'@media only screen and (max-width: 1200px)': {
		width: isHovered ? 115 : 64,
	},
}));

export const ListItemStyled = styled(ListItem)(({ theme }) => ({
	marginLeft: '10px',
	width: '200px',
	height: 55,
	marginBottom: 6,
	borderRadius: '8px',
	color: theme.palette.neutral.light,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	'@media only screen and (max-width: 1700px)': {
		width: '170px',
	},
	'@media only screen and (max-width: 1200px)': {
		width: '120px',
	},
}));

export const BoxStyled = styled(Box)(({ theme }) => ({
	height: 40,
	width: 30,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fill: theme.palette.primary.main,
	cursor: 'pointer',
	'@media only screen and (max-width: 1200px)': {
		height: 20,
		width: 15,
	},
}));

export const ListItemTextOpen = styled(ListItemText)(() => ({
	textTransform: 'initial',
	marginLeft: 10,
	display: 'block',
	'@media only screen and (max-width: 1400px)': {
		marginLeft: 8,
	},
	'@media only screen and (max-width: 1200px)': {
		marginLeft: 10,
	},
}));

export const ListItemTextClosed = styled(ListItemText)(() => ({
	display: 'block',
}));

export const StyledAdvertiserAvatar = styled(Box)(() => ({
	width: 50,
	height: 50,
	borderRadius: '50%',
	position: 'relative',
	margin: 'auto',
	zIndex: 2,
	marginTop: -50,
	marginBottom: -50,
	overflow: 'hidden',
	'& img': {
		objectFit: 'contain',
	},
	'@media only screen and (max-width: 1200px)': {
		width: 35,
		height: 35,
		marginTop: -35,
		marginBottom: -35,
	},
}));
