import {
  CouponPercent,
  CouponStatus,
} from "../../pages/Promotion/PromotionDetails/interface/coupon.interface";

export const getCouponsPercent = (
  total: number,
  coupon: CouponStatus
): CouponPercent => {
  const AvaiblePercentValue =
    ((coupon.available / total) * 100).toFixed() + "%";
  const RedeemedPercentValue =
    ((coupon.ransomed / total) * 100).toFixed() + "%";
  const UsedPercentValue = ((coupon.used / total) * 100).toFixed() + "%";
  const ExpiredPercentValue = ((coupon.expired / total) * 100).toFixed() + "%";
  const NewCouponValue = {
    AvaiblePercentValue,
    RedeemedPercentValue,
    UsedPercentValue,
    ExpiredPercentValue,
    total,
  };
  return NewCouponValue;
};
