import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { IAuth } from './auth/interface/auth.interface';
import { IReducers } from './auth/store/store';
import PreparedItem from './pages/AddItem/PreparedItem/PreparedItem';
import Comment from './pages/Comment/Comment';
import ConfirmPlan from './pages/ConfirmPlan/ConfirmPlan';
import Coupons from './pages/Coupons/Coupons';
import CouponsManager from './pages/Coupons/CouponsManager/CouponsManager';
import Dashboard from './pages/Dashboard/Dashboard';
import Menu from './pages/Menu/Menu';
import EditCategory from './pages/NewCategory/EditCategory/EditCategory';
import NewCategory from './pages/NewCategory/NewCategory';
import Order from './pages/Order/Order';
import Plans from './pages/Plans/Plans';
import Profile from './pages/Profile/Profile';
import Promotion from './pages/Promotion/Promotion';
import PromotionDetails from './pages/Promotion/PromotionDetails/PromotionDetails';
import SignPlan from './pages/SignPlan/SignPlan';
import StoreFinance from './pages/StoreFinance/StoreFinance';

const Rotas: React.FC = () => {
	const { advertiserComplete }: IAuth = useSelector(
		(state: IReducers) => state.auth
	);

	return (
		<Routes>
			<Route path="/dashboard" element={<Dashboard />} />
			<Route
				path="/promotion"
				element={
					advertiserComplete ? (
						<Promotion />
					) : (
						<Navigate to="/dashboard" replace />
					)
				}
			/>
			<Route path="/promotion/details/:id/" element={<PromotionDetails />} />
			<Route path="/menu" element={<Menu />} />
			<Route path="/coupons" element={<Coupons />} />
			<Route path="/orders" element={<Order />} />
			<Route path="/coupons/management" element={<CouponsManager />} />
			<Route path="/profile" element={<Profile />} />
			<Route path="/plans" element={<Plans />} />
			<Route path="/sign-plan" element={<SignPlan />} />
			<Route path="/confirm-plan" element={<ConfirmPlan />} />
			<Route path="/menu/newCategory" element={<NewCategory />} />
			<Route path="/menu/editCategory" element={<EditCategory />} />
			<Route path="/menu/preparedItem" element={<PreparedItem />} />
			<Route path="/finance" element={<StoreFinance />} />
			<Route path="/comment" element={<Comment />} />
			<Route path="*" element={<Navigate to="/dashboard" replace />} />
		</Routes>
	);
};

export default Rotas;
