import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, IconButton, Switch, TableBody, TableRow } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import AutoSave from '../../../shared/helpers/autoSaveFormik';
import { deleteMenuOptionGroup } from '../../../utils/api/delete/deleteMenuOptionGroup';
import { IItemOptionsGroup } from '../../../utils/api/interfaces';
import { patchOptionGroupById } from '../../../utils/api/patch/patchOptionGroupById';
import { SwitchText } from '../../Forms/AddItem/ComplementaryItems/ComplementaryComponent/styles';
import ItemDeleteModal from '../ItemsList/Item/ItemDeleteModal/ItemDeleteModal';
import MenuPopperButton from '../MenuPopperButton';
import OptionsList from '../OptionsList/OptionsList';
import { IItemListOptionsHeader } from '../interfaces';
import { StyledFirstCell, StyledSecondCell, StyledThirdCell } from '../styles';
import {
	CollapseStyled,
	Container,
	HeaderOptions,
	OptionsIcon,
	StyledFourthCell,
	StyledSubTitle,
	StyledTitle,
	SwitchButton,
	TableContent,
	TableHeader,
} from './styles';

const OptionsGroupHeader: React.FC<IItemListOptionsHeader> = ({
	openOptions,
	optionsGroup: { id, name, options, available, optionsCount },
	indexOptionGroup,
	handleRefreshData,
	itemId,
}: IItemListOptionsHeader): JSX.Element => {
	const [openOptionsList, setOpenopenOptionsList] = useState(true);
	const [newOptionGroup, setNewOptionGroup] = useState<IItemOptionsGroup>();
	const [openPopper, setOpenPopper] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);

	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const anchorRef = useRef<HTMLButtonElement>(null);
	const optionNumber = optionsCount ?? 0;

	const handleCloseDeleteModal = (): void => setOpenDeleteModal(false);

	const handleNavigate = () =>
		navigate('/menu/preparedItem', { state: { itemId, optionGroupId: id } });

	const handleOpenDeleteModal = (): void => setOpenDeleteModal(true);

	const handleOptionGroupPatch = (
		values: FormikValues,
		setSubmit: (isSubmitting: boolean) => void
	): void => {
		const { optionGroupId, available } = values;
		patchOptionGroupById(optionGroupId, available).then((res) => {
			setNewOptionGroup(res?.data);
			enqueueSnackbar('Grupo de complemento atualizado com sucesso!', {
				variant: 'success',
				autoHideDuration: 1500,
			});
			setSubmit(false);
		});
	};

	const handleToggle = () => {
		setOpenPopper((prevOpen) => !prevOpen);
	};

	const handleClose = (event: MouseEvent | TouchEvent) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
			return;
		}
		setOpenPopper(false);
	};

	const handleOptionGroupDelete = (): void => {
		deleteMenuOptionGroup(id)
			.then(() => {
				setOpenDeleteModal(false);
				handleRefreshData();
				enqueueSnackbar('Item excluido com sucesso!', {
					variant: 'success',
					autoHideDuration: 1500,
				});
			})
			.catch((err) => {
				setOpenDeleteModal(false);
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			});
	};

	const handleListKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpenPopper(false);
		}
	};

	const handleItemClick = (action: string) => {
		const actionFunctions: { [key: string]: () => void } = {
			edit: handleNavigate,
			delete: handleOpenDeleteModal,
		};

		if (actionFunctions[action]) {
			actionFunctions[action]();
		}

		setOpenPopper(false);
	};

	const menuItems = [
		{ action: 'edit', label: 'Editar grupo de complementos' },
		{ action: 'delete', label: 'Excluir grupo de complementos' },
	];

	return (
		<TableContent colSpan={6}>
			<CollapseStyled
				in={openOptions}
				timeout="auto"
				unmountOnExit
				openOptionsList={openOptionsList}
			>
				<Container>
					<TableHeader>
						<TableRow>
							<StyledFirstCell>
								<HeaderOptions>
									<OptionsIcon />
									<Box>
										<StyledTitle>{name}</StyledTitle>
										<StyledSubTitle>
											{optionsCount}
											{optionNumber > 1 ? ' opções' : ' opção'}
										</StyledSubTitle>
									</Box>
								</HeaderOptions>
							</StyledFirstCell>
							<StyledSecondCell></StyledSecondCell>
							<StyledThirdCell></StyledThirdCell>

							<StyledFourthCell>
								<Box style={{ textAlign: 'center' }}>
									<Formik
										enableReinitialize
										initialValues={{
											optionGroupId: id,
											available: newOptionGroup
												? newOptionGroup.available
												: available,
										}}
										onSubmit={(values, { setSubmitting }) => {
											handleOptionGroupPatch(values, setSubmitting);
										}}
									>
										{({
											handleSubmit,
											setFieldValue,
											submitForm,
											isSubmitting,
											values: { available },
										}) => {
											return (
												<Form
													onSubmit={(e) => {
														e.preventDefault();
														handleSubmit();
													}}
												>
													<SwitchButton>
														<SwitchText>
															{available ? 'Ativado' : 'Desativado'}
														</SwitchText>
														<Switch
															checked={available}
															onChange={() => {
																setFieldValue('available', !available);
															}}
															disabled={isSubmitting}
															inputProps={{ 'aria-label': 'status switch' }}
														/>
													</SwitchButton>
													<AutoSave onSubmit={submitForm} />
												</Form>
											);
										}}
									</Formik>
								</Box>
								<Box minWidth={'34px'} sx={{ marginLeft: 5 }}>
									<IconButton
										aria-label="expand row"
										size="small"
										onClick={() => setOpenopenOptionsList(!openOptionsList)}
										style={{ display: optionsCount ? 'inline' : 'none' }}
									>
										{openOptionsList ? (
											<KeyboardArrowUpIcon />
										) : (
											<KeyboardArrowDownIcon />
										)}
									</IconButton>

									<MenuPopperButton
										open={openPopper}
										anchorRef={anchorRef}
										menuItems={menuItems}
										handleItemClick={handleItemClick}
										handleClose={handleClose}
										handleListKeyDown={handleListKeyDown}
										handleToggle={handleToggle}
									/>
									{openDeleteModal && (
										<ItemDeleteModal
											confirm={handleOptionGroupDelete}
											close={handleCloseDeleteModal}
											itemName={name}
											type={'grupo de complementos'}
										/>
									)}
								</Box>
							</StyledFourthCell>
						</TableRow>
					</TableHeader>
					<TableBody>
						<TableRow>
							<OptionsList
								handleRefreshData={handleRefreshData}
								indexOptionGroup={indexOptionGroup}
								option={options}
								openOptionsList={openOptionsList}
							/>
						</TableRow>
					</TableBody>
				</Container>
			</CollapseStyled>
		</TableContent>
	);
};

export default OptionsGroupHeader;
