import { Box, styled } from '@mui/system';
import { ReactComponent as SideImageLogo } from '../../../assets/images/pcThirdRow.svg';

export const StyleSideImageContainer = styled(Box)(() => ({
	width: '100%',
	height: 330,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginTop: '4%',
	'& svg': {
		width: 447,
		height: 330,
	},
	'@media only screen and (max-width: 1200px)': {
		width: 488,
		height: 400,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& svg': {
			width: 488,
			height: 400,
		},
	},
}));

export const SideLogo = styled(SideImageLogo)(() => ({
	'& svg': {
		width: 447,
		height: 330,
	},
	'@media only screen and (max-width: 1200px)': {
		'& svg': {
			width: 488,
			height: 400,
		},
	},
}));
