import { Collapse, TableBody, TableCell } from '@mui/material';
import { StyledOptionsTable } from '../styles';
import OptionsItem from './OptionsItem/OptionsItem';
import { IOptionsListProps } from '../interfaces';

const OptionsList: React.FC<IOptionsListProps> = ({
	indexOptionGroup,
	openOptionsList,
	option,
	handleRefreshData,
}: IOptionsListProps): JSX.Element => {
	return (
		<TableCell style={{ padding: 0 }} colSpan={6}>
			<Collapse in={openOptionsList} timeout="auto" unmountOnExit>
				<StyledOptionsTable
					aria-label=" option item table"
					key={indexOptionGroup}
				>
					<TableBody>
						{option?.map((optionItem, indexGroupList) => (
							<OptionsItem
								handleRefreshData={handleRefreshData}
								optionItem={optionItem}
								key={indexGroupList}
							/>
						))}
					</TableBody>
				</StyledOptionsTable>
			</Collapse>
		</TableCell>
	);
};

export default OptionsList;
