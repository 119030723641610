import { Pagination, Skeleton } from '@mui/material';
import React from 'react';
import { NewPromotionProps } from '../../../pages/Dashboard/interface';
import BigEmptyCard from '../../BigEmptyCard/BigEmptyCard';
import EmptyCard from '../../Dashboard/EmptyCard/EmptyCard';
import PromotionCard from '../../PromotionCard/PromotionCard';
import { PromotionListProps } from './interface/promotionList-props.interface';
import {
	StylePromotionListText,
	StyledAllPromotionsContainer,
	StyledEmptyList,
	StyledPagination,
	StyledPromotion,
	StyledPromotionCardSkeleton,
	StyledPromotionList,
} from './styles';

const PromotionListSkeleton: React.FC = (): JSX.Element => {
	return (
		<>
			<StyledPromotion>
				<StyledPromotionCardSkeleton animation="wave" variant="rectangular" />
			</StyledPromotion>
		</>
	);
};

const PromotionList: React.FC<PromotionListProps> = ({
	total,
	promotion,
	totalPages,
	currentPage,
	handleCurrentPageChange,
	loader,
	loaderTotal,
	fromCoupons,
}: PromotionListProps): JSX.Element => (
	<StyledAllPromotionsContainer fromCoupons={fromCoupons}>
		{loaderTotal ? (
			<StylePromotionListText fromCoupons={fromCoupons}>
				<Skeleton animation="wave" variant="text" width={300} height={'100%'} />
			</StylePromotionListText>
		) : promotion.length === 0 ? (
			<StylePromotionListText fromCoupons={fromCoupons}>
				Nenhuma promoção encontrada
			</StylePromotionListText>
		) : (
			<StylePromotionListText fromCoupons={fromCoupons}>
				{promotion.length === 1
					? `${total} promoção encontrada`
					: `${total} promoções encontradas`}
			</StylePromotionListText>
		)}
		{loader ? (
			<StyledPromotionList fromCoupons={fromCoupons}>
				{fromCoupons
					? Array(6)
							.fill(null)
							.map((_, index) => <PromotionListSkeleton key={index} />)
					: Array(12)
							.fill(null)
							.map((_, index) => <PromotionListSkeleton key={index} />)}
			</StyledPromotionList>
		) : (
			<>
				{promotion.length > 0 && (
					<StyledPromotionList fromCoupons={fromCoupons}>
						{promotion.map((promotion: NewPromotionProps) => (
							<PromotionCard
								data={promotion}
								key={promotion.id}
								fromCoupons={fromCoupons}
							/>
						))}
					</StyledPromotionList>
				)}
				{promotion.length === 0 && (
					<StyledEmptyList fromCoupons={fromCoupons}>
						{fromCoupons ? (
							<EmptyCard
								text={
									'Você não tem nenhuma promoção ativa :( Crie uma promoção para começar.'
								}
								image={'promotion'}
							/>
						) : (
							<BigEmptyCard
								text={`Você não tem nenhuma promoção ativa :(    Crie uma promoção para começar.`}
								image={'promotion'}
							/>
						)}
					</StyledEmptyList>
				)}
			</>
		)}
		<StyledPagination fromCoupons={fromCoupons}>
			{totalPages && !loader ? (
				<Pagination
					shape="rounded"
					count={totalPages}
					page={currentPage}
					onChange={handleCurrentPageChange}
					color="primary"
					sx={{ margin: 'auto' }}
					showFirstButton
					showLastButton
				/>
			) : null}
		</StyledPagination>
	</StyledAllPromotionsContainer>
);

export default PromotionList;
