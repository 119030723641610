import { Box, styled } from "@mui/material";

export const StyledUsedCouponsContainer = styled(Box)(({ theme }) => ({
  display: "block",
  backgroundColor: theme.palette.neutral.light,
  width: "100%",
  borderRadius: 20,
  padding: "28px 32px 0px 31px",
  "@media only screen and (max-width: 1400px)": {
    padding: "18px 22px 0px 21px",
  },
}));

export const StyledUsedCouponsContainerTitle = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontFamily: "Rubik",
  fontWeight: 500,
  fontSize: 18,
  lineHeight: "20px",
  marginBottom: 16,
  "@media only screen and (max-width: 1400px)": {
    fontSize: 14,
    lineHeight: "16px",
  },
}));

export const StyledUsedCouponsContainerList = styled(Box)(({ theme }) => ({
  display: "block",
  width: "100%",
  border: "1px solid",
  borderColor: theme.palette.neutral.light2,
  "& > :first-of-type": {
    borderTop: 0,
  },
}));
export const StyledListContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  boxSizing: "border-box",
  justifyContent: "space-between",
  width: "100%",
  borderTop: "1px solid",
  borderColor: theme.palette.neutral.light2,

  "@media only screen and (max-width: 1400px)": {
    padding: "12px 8px",
  },
}));

export const StyledUsedCoupon = styled(Box)(() => ({
  display: "flex",
}));

export const StyledUsedCouponCode = styled(Box)(({ theme }) => ({
  display: "flex",
  color: theme.palette.neutral.dark,
  fontFamily: "Rubik",
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "20px",
  padding: "16px 12px",
  "@media only screen and (max-width: 1400px)": {
    padding: 0,
    fontSize: 10,
    lineHeight: "16px",
  },
}));

export const StyledUsedCouponDate = styled(Box)(({ theme }) => ({
  display: "flex",
  color: theme.palette.neutral.dark1,
  fontFamily: "Rubik",
  fontWeight: 400,
  fontSize: 12,
  lineHeight: "16px",
  padding: "16px 12px",
  "@media only screen and (max-width: 1400px)": {
    padding: 0,
    fontSize: 10,
    lineHeight: "14px",
  },
}));
