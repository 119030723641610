import { Box, Skeleton, styled, Typography } from '@mui/material';
import { ReactComponent as PurpleDot } from '../../../assets/images/purpleDot.svg';
import { DescriptionProp } from './interface/promotionFields-props.interface';

export const StyledPromotionDetailsFields = styled(Box)(({ theme }) => ({
	gridRow: 1,
	gridColumn: 1,
	display: 'flex',
	backgroundColor: theme.palette.neutral.light,
	padding: '20px 24px 20px 20px',
	borderRadius: 20,
	gap: 24,
	boxShadow: '4px 6px 15px rgba(0, 0, 0, 0.1)',
}));

export const SyledPromotionImage = styled(Box)(() => ({
	borderRadius: 20,
	maxHeight: 322.625,
	height: 322.625,
	minWidth: '39%',
	border: '1px dashed',
	borderColor: 'transparent',
	overflow: 'hidden',
	position: 'relative',
	boxSizing: 'border-box',
	'& img': {
		height: '100%',
		width: '100%',
		objectFit: 'scale-down',
	},
	'@media only screen and (max-width: 1400px)': {
		maxHeight: 200,
		height: 200,
		minWidth: '39%',
	},
}));

export const StyledExpiredPromotion = styled(Box)(({ theme }) => ({
	display: 'flex',
	width: 164,
	height: 40,
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: theme.palette.neutral.dark1,
	position: 'absolute',
	top: 0,
	left: 0,
	color: theme.palette.neutral.light,
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 18,
	borderRadius: '20px 0px',
	'@media only screen and (max-width: 1400px)': {
		width: 124,
		height: 25,
	},
}));
export const StyledActivePromotion = styled(Box)(({ theme }) => ({
	display: 'flex',
	width: 164,
	height: 40,
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: theme.palette.success.main,
	position: 'absolute',
	top: 0,
	left: 0,
	color: theme.palette.neutral.light,
	fontFamily: 'Rubik',
	fontWeight: 500,
	fontSize: 18,
	borderRadius: '20px 0px',
	'@media only screen and (max-width: 1400px)': {
		width: 124,
		height: 25,
	},
}));
export const StyledPromotionValuesContainer = styled(Box)(() => ({
	display: 'block',
	width: '100%',
	maxWidth: 'calc(100% - 39%)',
	'@media only screen and (max-width: 1400px)': {
		maxHeight: '252px',
		overflow: 'hidden',
	},
}));

export const StyledPromotionTitleContainer = styled(Box)(() => ({
	display: 'flex',
}));

export const StyledPromotionTitle = styled(Box)(({ theme }) => ({
	fontSize: 24,
	fontFamily: 'Rubik',
	fontWeight: 500,
	height: 28,
	lineHeight: '28px',
	color: theme.palette.neutral.dark,
	textAlign: 'justify',
	marginTop: 20,
	maxWidth: '100%',
	wordBreak: 'break-word',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	WebkitLineClamp: '1',
	WebkitBoxOrient: 'vertical',
	display: '-webkit-box',
	marginBottom: 8,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 16,
		height: 20,
		lineHeight: '20px',
	},
}));

export const StyledPromotionTag = styled(Box)(({ theme }) => ({
	display: 'flex',
	fontSize: 18,
	color: theme.palette.primary.main,
	fontFamily: 'Rubik',
	fontWeight: 500,
	lineHeight: '24px',
	alignItems: 'center',
	marginTop: 9,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 16,
		lineHeight: '24px',
	},
}));

export const StyledPromotionTagIcon = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	'@media only screen and (max-width: 1400px)': {
		'& svg': {
			width: 20,
			height: 16,
		},
	},
}));

export const StyledPurpleDot = styled(PurpleDot)(() => ({
	alignSelf: 'center',
	width: 6,
	marginRight: 8,
	marginLeft: 12,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 16,
		lineHeight: '24px',
	},
}));

export const StyledPromotionDate = styled(Typography)(({ theme }) => ({
	fontSize: 18,
	lineHeight: '24px',
	fontFamily: 'Rubik',
	fontWeight: 400,
	marginBottom: 20,
	color: theme.palette.neutral.dark1,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
		lineHeight: '20px',
		marginBottom: 5,
	},
}));

export const StyledPromotionText = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 500,
	height: 20,
	marginBottom: 8,
	color: theme.palette.neutral.dark,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
		lineHeight: '20px',
	},
}));

export const StyledPromotionDescriptionTitle = styled(Typography)(
	({ theme }) => ({
		fontSize: 18,
		lineHeight: '24px',
		fontFamily: 'Rubik',
		fontWeight: 500,
		height: 20,
		marginBottom: 8,
		color: theme.palette.neutral.dark,
		marginTop: 20,
		'@media only screen and (max-width: 1400px)': {
			fontSize: 14,
			lineHeight: '20px',
		},
	})
);

export const StyledPromotionDescription = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'isBig',
})<DescriptionProp>(({ theme, isBig }) => ({
	fontSize: isBig ? 10 : 12,
	lineHeight: '20px',
	fontFamily: 'Rubik',
	fontWeight: 500,
	height: 80,
	color: theme.palette.neutral.dark1,
	overflowWrap: 'anywhere',
	'@media only screen and (max-width: 1400px)': {
		fontSize: isBig ? 7 : 9,
		lineHeight: '10px',
		textAlign: 'justify',
	},
}));

export const StyledPromotionBottomContainer = styled(Box)(() => ({
	display: 'flex',
	gap: 20,
	marginTop: '5%',
	'@media only screen and (max-width: 1400px)': {
		marginTop: '2%',
		gap: 10,
	},
}));

export const StyledPromotionPriceContainer = styled(Box)(() => ({
	display: 'block',
}));

export const StyledPromotionPrice = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 400,
	height: 20,
	color: theme.palette.neutral.dark1,
	border: '1px solid',
	borderColor: theme.palette.neutral.dark1,
	borderRadius: 8,
	padding: '10px 25px 10px 10px',
	width: 88,
	overflow: 'hidden',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 10,
		height: 10,
		width: 64,
		padding: '8px 5px 8px 5px',
	},
}));

export const StyledPromotionTagContainer = styled(Box)(() => ({
	display: 'block',
	marginLeft: 30,
	'@media only screen and (max-width: 1400px)': {
		marginLeft: 7,
	},
}));

export const BigSkeleton = styled(Skeleton)(() => ({
	height: '100%',
	width: '100%',
}));

export const MediumSkeleton = styled(Skeleton)(() => ({
	height: '100%',
	width: '20%',
	'@media only screen and (max-width: 1400px)': {
		width: '40%',
	},
}));
export const PromotionTextSkeleton = styled(Skeleton)(() => ({
	height: '100%',
	width: '40%',
}));
export const PriceSkeleton = styled(Skeleton)(() => ({
	height: 40,
	width: 125,
	borderRadius: 8,
	'@media only screen and (max-width: 1400px)': {
		width: 45,
	},
}));
export const TagSkeleton = styled(Skeleton)(() => ({
	height: 40,
	width: 183,
	borderRadius: 2,
	'@media only screen and (max-width: 1400px)': {
		width: 120,
	},
}));

export const OldValueContainer = styled(Box)(() => ({
	display: 'flex',
}));

export const OldValue = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 500,
	textDecoration: 'line-through',
	color: theme.palette.neutral.dark1,
	whiteSpace: 'nowrap',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 10,
	},
}));

export const DiscountPercent = styled(Typography)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: 4,
	marginLeft: 8,
	width: 42,
	height: 20,
	fontSize: 10,
	fontFamily: 'Rubik',
	fontWeight: 500,
	backgroundColor: theme.palette.success.main,
	color: theme.palette.neutral.light,
	lineHeight: 0,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 8,
		width: 32,
		height: 15,
	},
}));

export const NewValue = styled(Box)(() => ({
	display: 'flex',
}));
export const Value = styled(Typography)(({ theme }) => ({
	whiteSpace: 'nowrap',
	fontSize: 32,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.neutral.dark,
	lineHeight: '36px',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
	},
}));

export const StyledPaymentsTitle = styled(Box)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.neutral.dark,
	lineHeight: '20px',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
	},
}));
