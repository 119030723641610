import { ReactComponent as Açai } from "../../../src/assets/images/acai.svg";
import { ReactComponent as Butchery } from "../../../src/assets/images/acougue.svg";
import { ReactComponent as Drinks } from "../../../src/assets/images/bebidas.svg";
import { ReactComponent as Beauty } from "../../../src/assets/images/beleza.svg";
import { ReactComponent as Home } from "../../../src/assets/images/casa.svg";
import { ReactComponent as Convenience } from "../../../src/assets/images/conveniencia.svg";
import { ReactComponent as Eletronics } from "../../../src/assets/images/eletronicos.svg";
import { ReactComponent as Sports } from "../../../src/assets/images/esportes.svg";
import { ReactComponent as Family } from "../../../src/assets/images/familia.svg";
import { ReactComponent as Pharmacy } from "../../../src/assets/images/farmacia.svg";
import { ReactComponent as Fitness } from "../../../src/assets/images/fitness.svg";
import { ReactComponent as Hamburguer } from "../../../src/assets/images/hamburguer.svg";
import { ReactComponent as Health } from "../../../src/assets/images/health.svg";
import { ReactComponent as Snacks } from "../../../src/assets/images/lanches.svg";
import { ReactComponent as Market } from "../../../src/assets/images/mercado.svg";
import { ReactComponent as Fashion } from "../../../src/assets/images/moda.svg";
import { ReactComponent as Bakery } from "../../../src/assets/images/padaria.svg";
import { ReactComponent as StationaryStore } from "../../../src/assets/images/papelaria.svg";
import { ReactComponent as Pet } from "../../../src/assets/images/pet.svg";
import { ReactComponent as Pizza } from "../../../src/assets/images/pizza.svg";
import { ReactComponent as Restaurante } from "../../../src/assets/images/restaurante.svg";
import { ReactComponent as Services } from "../../../src/assets/images/services.svg";
import { ReactComponent as Stores } from "../../../src/assets/images/stores.svg";
import { ReactComponent as Sushi } from "../../../src/assets/images/sushi.svg";
import { CategoryComponentsProps } from "./interfaces/category-svg.interface";

export const getCategoriesIcons = (
  categoryName: string,
  width: number,
  height: number
): JSX.Element | undefined => {
  const categoryComponents: CategoryComponentsProps = {
    Açaí: (
      <Açai
        width={width}
        height={height}
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Açougue: (
      <Butchery
        width={width}
        height={height}
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Bebidas: (
      <Drinks
        width={width}
        height={height}
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Beleza: (
      <Beauty
        width={width}
        height={height}
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Casa: (
      <Home
        width={width}
        height={height}
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Conveniência: (
      <Convenience
        width={width}
        height={height}
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Eletrônicos: (
      <Eletronics
        width={width}
        height={height}
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Esporte: (
      <Sports
        width={width}
        height={height}
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Família: (
      <Family
        width={width}
        height={height}
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Farmácia: (
      <Pharmacy
        width={width}
        height={height}
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Hambúrguer: (
      <Hamburguer
        width={width}
        height={height}
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Lanches: (
      <Snacks
        width={width}
        height={height}
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Mercado: (
      <Market
        width={width}
        height={height}
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Moda: (
      <Fashion
        width={width}
        height={height}
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Padaria: (
      <Bakery
        width={width}
        height={height}
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Papelaria: (
      <StationaryStore
        width={width}
        height={height}
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Pizza: (
      <Pizza
        width={width}
        height={height}
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Restaurante: (
      <Restaurante
        width={width}
        height={height}
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Lojas: (
      <Stores
        width={width}
        height={height}
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Sushi: (
      <Sushi
        width={width}
        height={height}
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Saúde: (
      <Health fill="#6029B1" style={{ marginRight: 8, alignSelf: "center" }} />
    ),
    Serviços: (
      <Services
        fill="#6029B1"
        style={{ marginRight: 8, alignSelf: "center" }}
      />
    ),
    Pet: <Pet fill="#6029B1" style={{ marginRight: 8, alignSelf: "center" }} />,
    Fitness: (
      <Fitness fill="#6029B1" style={{ marginRight: 8, alignSelf: "center" }} />
    ),
  };

  return categoryComponents[categoryName];
};
