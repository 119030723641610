import { Box, styled } from "@mui/material";
import { IButtonsProps } from "./interfaces";

export const Container = styled(Box)(({ theme }) => ({
  display: "list-item",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.neutral.light2,
}));

export const StyledDashboardTopBar = styled(Box)(() => ({
  display: "flex",
  width: "100%",
}));

export const ContainerContent = styled(Box)<IButtonsProps>(
  ({ theme, buttonselected }) => ({
    display: "flex",
    width: "93%",
    height: "72%",
    marginLeft: "44px",
    marginTop: "-2px",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: buttonselected ? "20px" : "",
    backgroundColor: theme.palette.neutral.light,
  })
);

export const CurrenPlanAndHistory = styled(Box)(() => ({
  width: "40%",
  height: "100%",
}));

export const PlanUseAndFormOfPayment = styled(Box)(() => ({
  width: "60%",
  height: "100%",
}));
