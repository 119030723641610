import { AxiosResponse } from 'axios';
import { DataProps } from '../../../components/Menu/DeliveryModal/DistrictList/DistrictList';
import { BASE_URL } from '../api';
import { request } from '../base-request/base-request';
export const createDeliveryPrices = async (
	data: DataProps[]
): Promise<AxiosResponse<any>> => {
	try {
		const response = await request({
			url: `${BASE_URL}/deliveryPrices`,
			data: { data },
			method: 'POST',
		});
		return response;
	} catch (error) {
		return Promise.reject(error);
	}
};
