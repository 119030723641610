import { Box, Typography, styled } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { ITextProps } from '../interfaces/interfaces';

export const Container = styled(Box)(({ theme }) => ({
	width: '583px',
	height: '100%',
	paddingBottom: '5%',
	overflow: 'auto',
	backgroundColor: theme.palette.common.white,
}));

export const HeaderDetail = styled(Box)(() => ({
	padding: '10px 30px 20px 30px',
}));

export const BoxContent = styled(Box)(() => ({
	width: '100%',
}));

export const OrderAndNumber = styled(Box)(() => ({
	display: ' flex',
}));

export const AlignDateAndStatus = styled(Box)(() => ({
	display: ' flex',
	alignItems: ' center',
}));

export const OderText = styled(Typography)(({ theme }) => ({
	fontSize: '20px',
	fontFamily: 'Rubik',
	fontWeight: 600,
	color: theme.palette.common.black,
}));

export const SubText = styled(Box)(({ theme }) => ({
	fontSize: '16px',
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
}));

export const Status = styled(Box)(() => ({
	display: ' flex',
	alignItems: ' center',
	marginLeft: '10px',
}));

export const ApprovedIcon = styled(CheckCircleOutlineIcon)(({ theme }) => ({
	color: theme.palette.success.main,
	marginRight: '5px',
	fontSize: '20px',
}));

export const StatusText = styled(Box)<ITextProps>(({ theme, status }) => ({
	fontSize: '16px',
	fontFamily: 'Rubik',
	fontWeight: 400,
	marginLeft: '5px',
	color:
		status === 'Cancelado'
			? theme.palette.error.main
			: theme.palette.success.main,
}));

export const CloseBox = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'flex-end',
}));

export const ButtonClose = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: 30,
	height: 30,
	cursor: 'pointer',
}));

export const IconClose = styled(CloseIcon)(({ theme }) => ({
	color: theme.palette.neutral.dark1,
}));

export const AlignCards = styled(Box)(() => ({
	display: 'flex',
	padding: '40px 30px 20px 20px',
}));

export const TitleText = styled(Typography)(({ theme }) => ({
	marginTop: '8%',
	marginLeft: ' 30px',
	fontSize: '18px',
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.common.black,
}));

export const WrapperTotalItems = styled(Typography)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	maxWidth: '100%',
	marginTop: '5%',
	backgroundColor: theme.palette.neutral.light1,
	padding: '15px 30px 15px 30px',
}));

export const TotalItems = styled(Typography)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	maxWidth: '100%',
	marginTop: '2%',
	backgroundColor: theme.palette.neutral.light1,
	padding: '15px 30px 15px 30px',
}));

export const WrapperText = styled(Typography)(({ theme }) => ({
	fontSize: '18px',
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.common.black,
}));

export const TotalText = styled(Typography)(({ theme }) => ({
	fontSize: '16px',
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.common.black,
}));

export const TotalSubText = styled(Typography)(({ theme }) => ({
	fontSize: '14px',
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
}));

export const TotalPrice = styled(Typography)(({ theme }) => ({
	fontSize: '18px',
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.common.black,
}));

export const TitleAndPrice = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: '15px 30px 5px 30px',
}));

export const SubTotalPrice = styled(Typography)(({ theme }) => ({
	fontSize: '16px',
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
}));

export const SubTextBold = styled(Typography)(({ theme }) => ({
	fontSize: '16px',
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.common.black,
}));

export const SubTotalPriceBold = styled(Typography)(({ theme }) => ({
	fontSize: '16px',
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.common.black,
}));

export const WrapperButton = styled(Box)(() => ({
	maxWidth: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '15px 30px 5px 30px',
}));

export const ErrorButton = styled(Box)(({ theme }) => ({
	width: '55%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	border: `1px solid ${theme.palette.neutral.dark2}`,
	borderRadius: '8px',
	padding: '20px',
	cursor: 'pointer',
}));

export const WarningText = styled(Typography)(({ theme }) => ({
	marginLeft: '10px',
	fontSize: '18px',
	fontFamily: 'Rubik',
	fontWeight: 700,
	color: theme.palette.neutral.dark1,
}));

export const ContentAboutPayment = styled(Box)(() => ({
	maxWidth: '100%',
	padding: '30px 30px 40px 30px',
}));

export const CanceledValue = styled(Box)(({ theme }) => ({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '8px',
	padding: '15px 0px 15px 0px',
	backgroundColor: theme.palette.error.dark,
}));

export const CanceledText = styled(Typography)(({ theme }) => ({
	fontSize: '16px',
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.error.main,
}));

export const PositiveValue = styled(Box)(({ theme }) => ({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '8px',
	padding: '15px 0px 15px 0px',
	backgroundColor: theme.palette.success.dark,
}));

export const PositiveText = styled(Typography)(({ theme }) => ({
	fontSize: '16px',
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.success.main,
}));

export const NegativeValue = styled(Box)(() => ({
	maxWidth: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '8px',
	padding: '15px 25px 15px 25px',
	backgroundColor: 'rgba(237, 232, 244, 0.25)',
}));

export const NegativeTitleText = styled(Typography)(({ theme }) => ({
	fontSize: '14px',
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.neutral.dark1,
}));

export const NegativeSubText = styled(Typography)(({ theme }) => ({
	fontSize: '14px',
	fontFamily: 'Rubik',
	textAlign: 'center',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
}));
