import ReorderIcon from '@mui/icons-material/Reorder';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import {
	Box,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	useTheme,
} from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import {
	DragDropContext,
	Draggable,
	DraggableProvided,
	DraggableStateSnapshot,
	DropResult,
	Droppable,
	DroppableProvided,
} from 'react-beautiful-dnd';
import { StyledDraggabelCell } from '../../styles';
import { ArrayItem, IOrderArray } from '../MenuOrderModal';

interface ResizableTableCellProps {
	children: React.ReactNode;
	width?: string;
}

const ResizableTableCell: React.FC<ResizableTableCellProps> = ({
	children,
}) => {
	const cellRef = useRef<HTMLTableCellElement>(null);
	const [cellWidth, setCellWidth] = useState<number | null>(null);

	useEffect(() => {
		const observer = new ResizeObserver((entries) => {
			const newWidth = entries[0].contentRect.width;
			setCellWidth(newWidth);
		});

		if (cellRef.current) {
			observer.observe(cellRef.current);
		}

		return () => {
			if (cellRef.current) {
				observer.unobserve(cellRef.current);
			}
		};
	}, []);

	return (
		<TableCell
			ref={cellRef}
			style={{
				width: cellWidth !== null ? cellWidth : 'auto',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			}}
		>
			{children}
		</TableCell>
	);
};

interface IDraggableTable {
	items: ArrayItem[];
	getItemCategories?: (id: string) => void;
	label: string;
	orderArray: IOrderArray;
	setOrderArray: React.Dispatch<React.SetStateAction<IOrderArray>>;
	getting: boolean;
}

const DraggableTable: React.FC<IDraggableTable> = ({
	items,
	getItemCategories,
	label,
	orderArray,
	setOrderArray,
	getting,
}: IDraggableTable) => {
	const [localItems, setLocalItems] = useState<ArrayItem[]>([]);

	const theme = useTheme();

	const handleRowClick = (itemId: string) => {
		getItemCategories ? getItemCategories(itemId) : null;
	};

	const handleDragEnd = (result: DropResult) => {
		if (!result.destination) {
			return;
		}

		setLocalItems((prev: ArrayItem[]) => {
			const oldArray = [...prev];

			const movedItem = oldArray.splice(result.source.index, 1)[0];
			oldArray.splice(result.destination!.index, 0, movedItem);
			const updatedItems = oldArray.map((item, index) => ({
				...item,
				sequence: index,
			}));

			return updatedItems;
		});
	};
	const handleAlphabeticalSort = () => {
		const sortedItems = [...localItems].sort((a, b) =>
			a.name.localeCompare(b.name)
		);
		const updatedItems = sortedItems.map((item, index) => ({
			...item,
			sequence: index,
		}));
		setLocalItems(updatedItems);
	};

	const mergeArrays = (arr1: ArrayItem[], arr2: ArrayItem[]): ArrayItem[] => {
		const merged: ArrayItem[] = [
			...arr1
				.concat(arr2)
				.reduce(
					(m, o) => m.set(o.id, { ...m.get(o.id), ...o }), // Preserve fields from the second array
					new Map<string, ArrayItem>()
				)
				.values(),
		];

		return merged;
	};

	useEffect(() => {
		setLocalItems([...items]);
	}, [items]);

	useEffect(() => {
		if (label === 'Categoria') {
			const newCategory = mergeArrays(orderArray.category, [...localItems]);

			setOrderArray({ ...orderArray, category: newCategory });
		}
		if (label === 'Itens') {
			const newItemGroup = mergeArrays(orderArray.item, [...localItems]);
			setOrderArray({ ...orderArray, item: newItemGroup });
		}
		if (label === 'Grupo de Complementos') {
			const newOptionGroup = mergeArrays(orderArray.optionGroup, [
				...localItems,
			]);
			setOrderArray({ ...orderArray, optionGroup: newOptionGroup });
		}
		if (label === 'Complementos') {
			const newOption = mergeArrays(orderArray.option, [...localItems]);

			setOrderArray({ ...orderArray, option: newOption });
		}
	}, [localItems, setLocalItems]);

	return (
		<TableContainer
			style={{
				width: 'fit-content',
				minWidth: '220px',
				border: '1px solid #ccc',
				minHeight: '60%',
				maxHeight: '380px',
				overflow: 'auto !important',
			}}
		>
			<Table>
				<TableHead
					style={{
						width: '100%',
						backgroundColor: theme.palette.primary.contrastText,
					}}
				>
					<TableRow>
						<StyledDraggabelCell align="left">&nbsp;</StyledDraggabelCell>
						<TableCell sx={{ textWrap: 'nowrap' }}>
							<Box
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
								}}
							>
								{label}

								<Button variant="text" onClick={handleAlphabeticalSort}>
									<SortByAlphaIcon fontSize="small" />
								</Button>
							</Box>
						</TableCell>
					</TableRow>
				</TableHead>
				<DragDropContext onDragEnd={handleDragEnd}>
					<Droppable droppableId="droppable" direction="vertical">
						{(droppableProvided: DroppableProvided) => (
							<TableBody
								ref={droppableProvided.innerRef}
								{...droppableProvided.droppableProps}
							>
								{localItems.map((item: ArrayItem, index: number) => (
									<Draggable key={item.id} draggableId={item.id} index={index}>
										{(
											draggableProvided: DraggableProvided,
											snapshot: DraggableStateSnapshot
										) => {
											return (
												<TableRow
													ref={draggableProvided.innerRef}
													{...draggableProvided.draggableProps}
													style={{
														...draggableProvided.draggableProps.style,
														background: snapshot.isDragging
															? 'rgba(245,245,245, 0.75)'
															: 'none',
													}}
													onClick={() =>
														getting ? undefined : handleRowClick(item.id)
													}
												>
													<ResizableTableCell>
														<div {...draggableProvided.dragHandleProps}>
															<ReorderIcon />
														</div>
													</ResizableTableCell>
													<ResizableTableCell>{item.name}</ResizableTableCell>
												</TableRow>
											);
										}}
									</Draggable>
								))}
								{droppableProvided.placeholder}
							</TableBody>
						)}
					</Droppable>
				</DragDropContext>
			</Table>
		</TableContainer>
	);
};

export default memo(DraggableTable);
