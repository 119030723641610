import { Box, styled } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

interface StyledTabProps {
	label: string;
	disabled: boolean;
}

export const StyledProfile = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
	zIndex: 0,
}));

export const StyledProfileContainer = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.neutral.light2,
	width: '100%',
	height: 'unset',
	'@media only screen and (max-width: 1400px)': {
		height: 'unset',
		minHeight: '100vh',
	},
	'@media only screen and (max-width: 1200px)': {
		height: 'unset',
		minHeight: '100vh',
	},
}));

export const StyledProfileTopBar = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
}));

export const StyledProfileFormsContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	backgroundColor: theme.palette.neutral.light2,
}));

export const StyledProfileContent = styled(Box)(() => ({
	display: 'flex',
	padding: '32px 44px 32px 44px;',
	width: '100%',
	'@media only screen and (max-width: 1400px)': {
		maxWidth: '100%',
		padding: '24px 0px 20px 24px',
	},
	'@media only screen and (max-width: 1200px)': {
		maxWidth: '100%',
		padding: '24px 0px 0px 24px',
	},
}));

export const StyledCreateProfileContainer = styled(Box)(() => ({
	display: 'block',
	borderRadius: '20px 0px 0px 20px',
	width: '100%',
}));

export const StyledTabsContainer = styled(Box)(() => ({
	display: 'flex',
	height: 52,
	'@media only screen and (max-width: 1400px)': {
		height: 40,
	},
}));

export const ProfileTabs = styled(Tabs)({
	display: 'flex',
});

export const ProfileTab = styled((props: StyledTabProps) => (
	<Tab disableRipple {...props} />
))(({ theme }) => ({
	textTransform: 'none',
	fontSize: 20,
	fontWeight: 500,
	marginRight: 32,
	color: theme.palette.neutral.dark1,
	borderRadius: '20px 20px 0 0',
	padding: '16px 32px 16px 32px',
	fontFamily: 'Rubik',
	'&.Mui-selected': {
		backgroundColor: theme.palette.neutral.light,
		color: theme.palette.primary.main,
	},
	'@media only screen and (max-width: 1400px)': {
		fontSize: 16,
		padding: '10px 18px 10px 18px',
		marginRight: 22,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 12,
		padding: '5px 10px 5px 10px',
		marginRight: 15,
	},
}));

export const StyledProfileData = styled(Box)(({ theme }) => ({
	display: 'block',
	backgroundColor: theme.palette.neutral.light,
	padding: '36px 44px 0px 44px',
	borderRadius: '0px 0px 0px 20px',
	'@media only screen and (max-width: 1400px)': {
		padding: '16px 0px 0px 14px',
	},

	'@media only screen and (max-width: 1200px)': {
		padding: '16px 0px 0px 14px',
		height: 'unset',
	},
}));

export const StyledUserWarning = styled(Box)(({ theme }) => ({
	display: 'flex',
	fontFamily: 'Rubik',
	maxHeight: 48,
	fontSize: 18,
	fontWeight: 500,
	color: theme.palette.primary.dark,
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: 10,
	width: '600px',
	borderRadius: 8,
	backgroundColor: theme.palette.neutral.light1,
	marginBottom: 48,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
		width: '470px',
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 12,
		width: '400px',
	},
}));
