import { Box, Button, styled, Typography } from '@mui/material';

import { ReactComponent as EditPen } from '../../../src/assets/images/editPen.svg';
import { ReactComponent as GreenDot } from '../../../src/assets/images/greenDot.svg';
import { ReactComponent as TrashIcon } from '../../../src/assets/images/trashIcon.svg';

interface StyledDotProps {
	promotionDetails?: boolean | undefined;
}

interface StyledEditProps {
	edit?: boolean | undefined;
}
export const StyledLastComment = styled(Box)(({ theme }) => ({
	display: 'flex',
	whiteSpace: 'nowrap',
	padding: '12px 25px 12px 12px',
	borderTop: '1px solid',
	borderColor: theme.palette.neutral.light2,
	justifyContent: 'space-between',
	'@media only screen and (max-width: 1400px)': {
		padding: '8px 16px 8px 8px',
	},
	'@media only screen and (max-width: 1200px)': {
		padding: '7px 16px 7px 8px',
	},
}));

export const StyledLastCommentSkeleton = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyItems: 'center',
	alignItems: 'center',
	whiteSpace: 'nowrap',
	padding: '12px 25px 12px 12px',
	borderTop: '1px solid',
	borderColor: theme.palette.neutral.light2,
	'@media only screen and (max-width: 1400px)': {
		padding: '8px 16px 8px 8px',
	},
	'@media only screen and (max-width: 1200px)': {
		padding: '6px 16px 6px 8px',
	},
}));

export const StyledLastCommentContent = styled(Box)(() => ({
	display: 'flex',
	position: 'relative',
	width: '100%',
}));
export const StyledImageContainer = styled(Box)(() => ({
	display: 'flex',
	minWidth: 38,
	justifyContent: 'space-around',
}));

export const StyledUserImage = styled(Box)(() => ({
	display: 'flex',
	height: 44,
	width: 44,
	borderRadius: 50,
	overflow: 'hidden',
	zIndex: 1,
	boxShadow: '4px 6px 15px rgba(0, 0, 0, 0.1)',
	pointerEvents: 'none',
	'@media only screen and (max-width: 1700px)': {
		height: 34,
		width: 34,
	},
	'@media only screen and (max-width: 1200px)': {
		height: 24,
		width: 24,
	},
}));

export const StyledContainer = styled(Box)(() => ({
	display: 'block',
	marginBottom: 12,
	'@media only screen and (max-width: 1400px)': {
		marginBottom: 5,
	},
}));

export const StyledPromotionImage = styled(Box)(({ theme }) => ({
	display: 'flex',
	height: 44,
	width: 44,
	borderRadius: 50,
	overflow: 'hidden',
	marginLeft: -23,
	boxShadow: '4px 6px 15px rgba(0, 0, 0, 0.1)',
	pointerEvents: 'none',
	'&:before': {
		content: "'‎'",
		position: 'absolute',
		backgroundColor: theme.palette.neutral.light,
		display: 'block',
		height: 44,
		width: 26,
		borderRadius: '0 50px 50px 0',
	},
	'@media only screen and (max-width: 1700px)': {
		height: 34,
		width: 34,

		'&:before': {
			height: 34,
			width: 25,
		},
	},
	'@media only screen and (max-width: 1200px)': {
		height: 24,
		minWidth: 24,
		maxWidth: 24,

		'&:before': {
			height: 24,
			minWidth: 18,
			maxWidth: 18,
		},
	},
}));
export const StyledLastCommentText = styled(Box)(() => ({
	display: 'block',
	marginLeft: 12,
	width: '92%',
}));
export const StyledTitle = styled(Box)(() => ({
	display: 'flex',
	whiteSpace: 'nowrap',
	width: 540,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	'@media only screen and (max-width: 1400px)': {
		width: 292,
	},
}));
export const StyledTitleTypography = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.neutral.dark1,
}));
export const StyledPromotionName = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.primary.main,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	lineHeight: '20px',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 10,
		lineHeight: '14px',
	},
}));

export const StyledPromotionTitle = styled(Box)(({ theme }) => ({
	display: 'flex',
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.neutral.dark4,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	lineHeight: '20px',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 10,
		lineHeight: '14px',
	},
}));

export const StyledPromotionDetailsName = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.neutral.dark,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	lineHeight: '24px',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 10,
		lineHeight: '16px',
	},
}));
export const StyledComment = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'promotionDetails',
})<StyledDotProps>(({ promotionDetails, theme }) => ({
	whiteSpace: promotionDetails ? 'initial' : 'nowrap',
	width: '85%',
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	lineHeight: '20px',
	marginBottom: 20,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 10,
		lineHeight: '14px',
		marginBottom: 10,
	},
}));

export const StyledRightContainer = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'end',
}));

export const StyledDot = styled(Box)(() => ({
	display: 'flex',
	position: 'absolute',
	right: 0,
	top: 10,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
		top: 0,
	},
}));

export const StyledGreenDot = styled(GreenDot)(({ theme }) => ({
	alignSelf: 'center',
	width: 10,
	height: 10,
	marginRight: 8,
	marginLeft: 12,
	color: theme.palette.success.main,
}));

export const StyledGreenText = styled(Box)(({ theme }) => ({
	fontSize: 14,
	color: theme.palette.success.main,
	fontFamily: 'Rubik',
	fontWeight: 500,
	lineHeight: '20px',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 12,
		lineHeight: '18px',
	},
}));

export const StyledButton = styled(Button)(({ theme }) => ({
	position: 'absolute',
	right: -12,
	height: 44,
	width: '140px',
	borderRadius: 8,
	backgroundColor: theme.palette.primary.main,
	'&:hover': {
		backgroundColor: theme.palette.primary.main,
	},
	'@media only screen and (max-width: 1400px)': {
		height: 34,
		width: '140px',
	},
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
	textTransform: 'none',
	fontSize: 18,
	color: theme.palette.neutral.light,
	fontFamily: 'Rubik',
	fontWeight: 700,
	lineHeight: '20px',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
	},
}));

export const StyledAdvertiserAnswerContainer = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
	position: 'relative',
}));

export const StyledAvatar = styled(Box)(() => ({
	display: 'flex',
	minWidth: 44,
	maxWidth: 44,
	height: 44,
	borderRadius: '50%',
	overflow: 'hidden',
	pointerEvents: 'none',
	'& img': {
		height: '100%',
		width: '100%',
	},
	'@media only screen and (max-width: 1400px)': {
		height: 27,
		minWidth: 27,
	},
}));

export const StyledAnswerContainer = styled(Box)(() => ({
	display: 'block',
	marginLeft: 12,
}));

export const StyledIconsContainer = styled(Box)(() => ({
	position: 'absolute',
	right: -12,
	height: 44,
	width: '80px',
	'@media only screen and (max-width: 1400px)': {
		width: '50px',
		top: 16,
	},
}));

export const StyledPenIcon = styled(EditPen)(() => ({
	marginRight: 16,
	cursor: 'pointer',
	color: '#747476',
	'@media only screen and (max-width: 1400px)': {
		height: 14,
		width: 14,
		marginRight: 5,
	},
}));

export const StyledTrashIcon = styled(TrashIcon)(({ theme }) => ({
	color: theme.palette.error.main,
	width: 22,
	height: 26,
	cursor: 'pointer',
	'@media only screen and (max-width: 1400px)': {
		height: 14,
		width: 14,
	},
}));

export const AnswerContainer = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'edit',
})<StyledEditProps>(({ edit }) => ({
	display: edit ? 'none' : 'flex',
}));
