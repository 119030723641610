import { ReactComponent as BruteValueIcon } from '../../../assets/images/bruteValueIcon.svg';
import { ReactComponent as HouseIcon } from '../../../assets/images/houseIcon.svg';
import { ReactComponent as NetMoneyIcon } from '../../../assets/images/netMoneyIcon.svg';
import { ReactComponent as SmallLogoIcon } from '../../../assets/images/smallLogo.svg';
import { ReactComponent as TaxesIcon } from '../../../assets/images/taxesIcon.svg';

export const CARD_INFO = [
	{
		title: 'Valor Bruto',
		icon: <BruteValueIcon />,
		value: 0,
		percent: 0,
	},
	{
		title: 'Recebido pela loja',
		icon: <HouseIcon color="#412272" />,
		value: 0,
		percent: 0,
	},
	{
		title: 'Recebido pelo dsconto',
		icon: <SmallLogoIcon />,
		value: 0,
		percent: 0,
	},
	{
		title: 'Taxas e outros',
		icon: <TaxesIcon />,
		value: 0,
	},
	{
		title: 'Valor líquido',
		icon: <NetMoneyIcon />,
		value: 0,
		percent: 0,
	},
];

export const mockInfo = {
	grossValue: 873,
	receiverValueByAdvertiser: 97,
	receiverValueByDsconto: 776,
	taxesValue: 87.3,
	netValue: 785.7,
	grossValuePercent: 10,
	receiverValueByAdvertiserPercent: 20,
	receiverValueByDscontoPercent: 30,
	netValuePercent: 40,
};
