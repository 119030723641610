import React from "react";
import { dateDaysFormat } from "../../utils/mask.util";
import { ILastCommentsProps } from "../Dashboard/LastCommentsCard/interface/lastComments-data.interface";
import {
  StyledAvatar,
  StyledComment,
  StyledDot,
  StyledLastComment,
  StyledLastCommentContent,
  StyledLastCommentText,
  StyledPromotionDetailsName,
  StyledPromotionName,
  StyledPromotionTitle,
  StyledTitle,
} from "./styles";

const LastComments: React.FC<ILastCommentsProps> = ({
  data,
  promotionDetails,
}: ILastCommentsProps): JSX.Element => {
  return (
    <StyledLastComment>
      <StyledLastCommentContent>
        <StyledAvatar>
          <img src={data.profile.avatar} alt="Avatar do usuario" />
        </StyledAvatar>
        <StyledLastCommentText>
          <StyledTitle>
            {data.promotion ? (
              <StyledPromotionTitle>
                {data.profile.name.split(" ")[0]} comentou em &nbsp;
              </StyledPromotionTitle>
            ) : (
              <StyledPromotionDetailsName>
                {data.profile.name}
              </StyledPromotionDetailsName>
            )}

            {data.promotion ? (
              <StyledPromotionName>{data.promotion.title}</StyledPromotionName>
            ) : (
              <></>
            )}
          </StyledTitle>
          <StyledComment promotionDetails={promotionDetails}>
            {data.comment}
          </StyledComment>
        </StyledLastCommentText>
      </StyledLastCommentContent>
      <StyledDot promotionDetails={promotionDetails}>
        {dateDaysFormat(String(data.created_at))}
      </StyledDot>
    </StyledLastComment>
  );
};

export default LastComments;
