import { ReactScrollLinkProps } from "react-scroll/modules/components/Link";

const TOPBAR_SCROLL_CONFIG: ReactScrollLinkProps = {
  to: "",
  spy: true,
  smooth: true,
  offset: -136,
  duration: 1000,
};

export { TOPBAR_SCROLL_CONFIG };
