import { Box, FormControl, InputAdornment, Switch } from '@mui/material';
import { FieldArray, getIn, useFormikContext } from 'formik';
import 'moment/locale/pt-br';
import React, { useEffect, useState } from 'react';
import { ReactComponent as InformationIcon } from '../../../../assets/images/informationIcon.svg';
import { ReactComponent as GreyLine } from '../../../../assets/images/lineDivider.svg';
import { OpeningHoursProps } from '../../../../utils/api/interfaces';
import SaveButton from '../../../Buttons/SaveButton/SaveButton';
import ConfirmResetFormModal from '../ConfirmResetFormModal/ConfirmResetFormModal';
import {
	DifferentHoursFormProps,
	OpeningHoursFormProps,
	SameHoursFormProps,
} from './interface/openingHours.interface';
import { IDeliveryOptions } from './interface/openingHoursInitialValue.interface';
import {
	OpeningHoursFormContainer,
	StyledButton,
	StyledButtonsContainer,
	StyledDayListContainer,
	StyledDayOfWeek,
	StyledDayOfWeekText,
	StyledDayStatus,
	StyledDayStatusContainer,
	StyledDaysContainer,
	StyledDelivery,
	StyledDeliveryContainer,
	StyledDurationTypography,
	StyledFieldsContainer,
	StyledHoursContainer,
	StyledHoursGridContainer,
	StyledLineDivisor,
	StyledOutsideHoursContainer,
	StyledResetFormButton,
	StyledStartPreparingDuration,
	StyledTextContainer,
	StyledTextField,
	StyledUserWarning,
	TypographyText,
	TypographyTextPreparation,
	TypographyTitle,
} from './styles';

const SameHoursForm: React.FC<SameHoursFormProps> = ({
	is_active,
	index,
	openingHours,
	setFieldValue,
	sameHours,
	opening_hour,
	close_hour,
}: SameHoursFormProps): JSX.Element => {
	const [checked, setChecked] = useState(openingHours.is_active);
	const [textColor, setTextColor] = useState(!checked);

	const handleSwitchChange = () => {
		setChecked(!checked);
		setTextColor(!textColor);
		setFieldValue(is_active, !checked);
	};

	useEffect(() => {
		if (checked === false) {
			setFieldValue(opening_hour, '00:00');
			setFieldValue(close_hour, '00:00');
		}
	}, [checked, setChecked]);

	useEffect(() => {
		if (openingHours.is_active !== checked) {
			setChecked(openingHours.is_active);
			setTextColor(!openingHours.is_active);
		}
	}, [openingHours.is_active]);
	return (
		<StyledDayListContainer key={index}>
			<StyledDayOfWeek
				onClick={handleSwitchChange}
				isSelected={checked}
				isChecked={sameHours}
			>
				<StyledDayOfWeekText isSelected={textColor}>
					{openingHours.day}
				</StyledDayOfWeekText>
			</StyledDayOfWeek>
		</StyledDayListContainer>
	);
};

const DifferentHoursForm: React.FC<DifferentHoursFormProps> = ({
	is_active,
	index,
	openingHours,
	setFieldValue,
	opening_hour,
	close_hour,
	socialArrayHelpers,
	handleChange,
}: DifferentHoursFormProps): JSX.Element => {
	const [checked, setChecked] = useState(openingHours.is_active);
	const [textColor, setTextColor] = useState(!checked);

	const handleSwitchChange = () => {
		setChecked(!checked);
		setTextColor(!textColor);
		setFieldValue(is_active, !checked);
	};

	const touchedCloseHour = getIn(socialArrayHelpers.form.touched, close_hour);
	const errorCloseHour = getIn(socialArrayHelpers.form.errors, close_hour);

	const touchedOpeningHour = getIn(
		socialArrayHelpers.form.touched,
		opening_hour
	);
	const errorOpeningHour = getIn(socialArrayHelpers.form.errors, opening_hour);

	useEffect(() => {
		if (checked === false) {
			setFieldValue(opening_hour, '00:00');
			setFieldValue(close_hour, '00:00');
		}
	}, [checked, setChecked]);

	useEffect(() => {
		if (openingHours.is_active !== checked) {
			setChecked(openingHours.is_active);
			setTextColor(!openingHours.is_active);
		}
	}, [openingHours.is_active]);

	return (
		<StyledFieldsContainer key={index}>
			<StyledDayOfWeek onClick={handleSwitchChange} isSelected={checked}>
				<StyledDayOfWeekText isSelected={textColor}>
					{openingHours.day}
				</StyledDayOfWeekText>
			</StyledDayOfWeek>
			<StyledHoursContainer>
				<StyledTextField
					type="time"
					margin="normal"
					variant="outlined"
					label="Inicio"
					disabled={!checked}
					name={opening_hour}
					value={openingHours.opening_hour}
					InputLabelProps={{ shrink: true }}
					helperText={
						touchedOpeningHour && errorOpeningHour ? errorOpeningHour : ''
					}
					error={Boolean(touchedOpeningHour && errorOpeningHour)}
					onChange={handleChange}
					FormHelperTextProps={{
						style: {
							whiteSpace: 'nowrap',
							fontSize: 18,
							fontFamily: 'Rubik',
							fontWeight: 400,
							margin: 0,
							marginLeft: -10,
							maxWidth: 83,
							maxHeight: 12,
						},
					}}
				/>
				<StyledLineDivisor>
					<GreyLine />
				</StyledLineDivisor>

				<StyledTextField
					type="time"
					margin="normal"
					variant="outlined"
					label="Fim"
					name={close_hour}
					value={openingHours.close_hour}
					InputLabelProps={{ shrink: true }}
					disabled={!checked}
					helperText={touchedCloseHour && errorCloseHour ? errorCloseHour : ''}
					error={Boolean(touchedCloseHour && errorCloseHour)}
					onChange={handleChange}
					FormHelperTextProps={{
						style: {
							whiteSpace: 'nowrap',
							fontSize: 18,
							fontFamily: 'Rubik',
							fontWeight: 400,
							margin: 0,
							marginLeft: -10,
							maxWidth: 83,
							maxHeight: 12,
						},
					}}
				/>
			</StyledHoursContainer>
		</StyledFieldsContainer>
	);
};

const OpeningHoursForm: React.FC<OpeningHoursFormProps> = ({
	openHours,
	closeHours,
}: OpeningHoursFormProps): JSX.Element => {
	const {
		values: { openingHours, deliveryOptions },
		handleChange,
		handleBlur,
		setFieldValue,
		resetForm,
		values,
		touched,
		errors,
	} = useFormikContext<OpeningHoursProps>();

	const [sameHours, setSameHours] = useState(openingHours[0].same_hours);
	const [isDeliveryAvailable, setIsDeliveryAvailable] = useState(
		deliveryOptions.deliveryAvailable
	);
	const [isPickUpAvailable, setIsPickUpAvailable] = useState(
		deliveryOptions.pickUpAvailable
	);
	const [open, setOpen] = useState(openHours);
	const [close, setClose] = useState(closeHours);
	const [openError, setOpenError] = useState(false);
	const [closeError, setCloseError] = useState(false);
	const [showModalConfirmResetForm, setShowModalConfirmResetForm] =
		useState(false);

	const openModalConfirmResetForm = (): void => {
		setShowModalConfirmResetForm(true);
	};

	const closeModalConfirmResetForm = (): void => {
		setShowModalConfirmResetForm(false);
	};

	const toggleActivityStatus = (
		businessHours: OpeningHoursProps
	): OpeningHoursProps => {
		const toggledOpeningHours = businessHours.openingHours.map((hour) => ({
			...hour,
			is_active: false,
		}));

		const toggledDeliveryOptions: IDeliveryOptions = {
			deliveryAvailable: false,
			pickUpAvailable: false,
			pickUpTimeStart: 0,
			pickUpTimeEnd: 0,
		};

		return {
			...businessHours,
			openingHours: toggledOpeningHours,
			deliveryOptions: toggledDeliveryOptions,
		};
	};

	const handleResetForm = (): void => {
		const initialValues = toggleActivityStatus(values);
		setIsDeliveryAvailable(false);
		setIsPickUpAvailable(false);
		setOpen('00:00');
		setClose('00:00');
		resetForm({ values: initialValues });
		closeModalConfirmResetForm();
	};
	const handleSwitchChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setSameHours(event.target.checked);
		setFieldValue('same_hours', event.target.checked);
	};

	const handleDeliveryChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setIsDeliveryAvailable(event.target.checked);
		setFieldValue('deliveryOptions.deliveryAvailable', event.target.checked);
	};

	const handlePickupChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		setIsPickUpAvailable(event.target.checked);
		setFieldValue('deliveryOptions.pickUpAvailable', event.target.checked);
	};

	const handleSameHours = () => {
		const updateSameHours = openingHours.map((item) => {
			return { ...item, same_hours: sameHours };
		});

		setFieldValue('openingHours', updateSameHours);
	};

	const handleSingleOpenHours = (
		e: React.ChangeEvent<HTMLInputElement>
	): void => {
		setOpen(e.target.value);
		setOpenError(false);
	};

	const handleSingleCloseHours = (
		e: React.ChangeEvent<HTMLInputElement>
	): void => {
		setClose(e.target.value);
		setCloseError(false);
	};

	const handleSingleHourSubmit = (): void => {
		if (sameHours === true && open && close) {
			const sameHoursArray = openingHours.filter(
				(item) => item.is_active === true
			);
			sameHoursArray.map((item) => {
				(item.opening_hour = open), (item.close_hour = close);
			});
			handleSameHours();
		} else {
			handleSameHours();
			setOpenError(true);
			setCloseError(true);
		}
	};

	return (
		<OpeningHoursFormContainer>
			<Box display={'flex'} justifyContent={'space-between'}>
				<StyledTextContainer>
					<TypographyTitle>Horário de funcionamento</TypographyTitle>
					<TypographyText>
						O endereço cadastrado ficará visível no aplicativo
					</TypographyText>
				</StyledTextContainer>
				<StyledUserWarning>
					<InformationIcon style={{ marginRight: 10 }} />
					Essas informações ficarão visíveis para os usuários no aplicativo.
				</StyledUserWarning>
			</Box>
			<StyledDayStatusContainer>
				<StyledDayStatus>
					Sempre o mesmo
					<Switch
						checked={sameHours}
						onChange={handleSwitchChange}
						size="small"
						inputProps={{ 'aria-label': 'controlled' }}
					/>
				</StyledDayStatus>
				{sameHours && (
					<StyledOutsideHoursContainer>
						<StyledTextField
							type="time"
							margin="normal"
							variant="outlined"
							label="Inicio"
							name={open}
							value={open}
							InputLabelProps={{ shrink: true }}
							error={openError}
							helperText={openError ? 'Campo obrigatório' : ''}
							onChange={handleSingleOpenHours}
							onBlur={handleBlur}
							FormHelperTextProps={{
								style: {
									whiteSpace: 'nowrap',
									fontSize: 18,
									fontFamily: 'Rubik',
									fontWeight: 400,
									margin: 0,
									marginLeft: -10,
									maxWidth: 83,
									maxHeight: 12,
								},
							}}
						/>
						<StyledLineDivisor>
							<GreyLine />
						</StyledLineDivisor>

						<StyledTextField
							type="time"
							margin="normal"
							variant="outlined"
							label="Fim"
							name={close}
							value={close}
							InputLabelProps={{ shrink: true }}
							error={closeError}
							helperText={closeError ? 'Campo obrigatório' : ''}
							onChange={handleSingleCloseHours}
							onBlur={handleBlur}
							FormHelperTextProps={{
								style: {
									whiteSpace: 'nowrap',
									fontSize: 18,
									fontFamily: 'Rubik',
									fontWeight: 400,
									margin: 0,
									marginLeft: -10,
									maxWidth: 83,
									maxHeight: 12,
								},
							}}
						/>
					</StyledOutsideHoursContainer>
				)}
			</StyledDayStatusContainer>
			{sameHours && (
				<StyledDaysContainer>
					<FieldArray name="openingHours">
						{() => (
							<React.Fragment>
								{openingHours.map((openingHours, index) => {
									const is_active = `openingHours[${index}].is_active`;
									const opening_hour = `openingHours[${index}].opening_hour`;

									const close_hour = `openingHours[${index}].close_hour`;
									return (
										<SameHoursForm
											key={index}
											index={index}
											is_active={is_active}
											openingHours={openingHours}
											setFieldValue={setFieldValue}
											sameHours={sameHours}
											close_hour={close_hour}
											opening_hour={opening_hour}
										/>
									);
								})}
							</React.Fragment>
						)}
					</FieldArray>
				</StyledDaysContainer>
			)}

			{!sameHours && (
				<StyledHoursGridContainer>
					<FieldArray name="openingHours">
						{(socialArrayHelpers) => (
							<React.Fragment>
								{openingHours.map((openingHours, index) => {
									const opening_hour = `openingHours[${index}].opening_hour`;

									const close_hour = `openingHours[${index}].close_hour`;

									const is_active = `openingHours[${index}].is_active`;

									return (
										<DifferentHoursForm
											key={index}
											index={index}
											is_active={is_active}
											openingHours={openingHours}
											setFieldValue={setFieldValue}
											opening_hour={opening_hour}
											close_hour={close_hour}
											socialArrayHelpers={socialArrayHelpers}
											handleChange={handleChange}
										/>
									);
								})}
							</React.Fragment>
						)}
					</FieldArray>
				</StyledHoursGridContainer>
			)}
			<Box display={'flex'}>
				<Box>
					<StyledTextContainer>
						<TypographyTitle>Opções de entrega</TypographyTitle>
						<TypographyText>
							Defina como o cliente poderá receber o pedido
						</TypographyText>
					</StyledTextContainer>
					<StyledDeliveryContainer>
						<StyledDelivery>
							Entrega
							<Switch
								checked={isDeliveryAvailable}
								onChange={handleDeliveryChange}
								size="small"
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						</StyledDelivery>
						<StyledDelivery>
							Retirada na loja
							<Switch
								checked={isPickUpAvailable}
								onChange={handlePickupChange}
								size="small"
								inputProps={{ 'aria-label': 'controlled' }}
							/>
						</StyledDelivery>
					</StyledDeliveryContainer>
				</Box>
				<Box ml={5}>
					<StyledTextContainer>
						<TypographyTitle>Tempo de preparo</TypographyTitle>
						<TypographyText>
							Defina o intervalo de tempo estimado
						</TypographyText>
					</StyledTextContainer>
					<StyledDeliveryContainer>
						<FormControl style={{ maxHeight: 60 }}>
							<StyledStartPreparingDuration
								type="number"
								variant="outlined"
								value={deliveryOptions.pickUpTimeStart}
								InputLabelProps={{ shrink: true }}
								onChange={(val) => {
									setFieldValue(
										'deliveryOptions.pickUpTimeStart',
										val.target.value
									);
								}}
								error={
									touched.deliveryOptions?.pickUpTimeStart &&
									Boolean(errors.deliveryOptions?.pickUpTimeStart)
								}
								helperText={
									touched.deliveryOptions?.pickUpTimeStart &&
									errors.deliveryOptions?.pickUpTimeStart
								}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<StyledDurationTypography>
												minutos
											</StyledDurationTypography>
										</InputAdornment>
									),
								}}
							/>
						</FormControl>
						<TypographyTextPreparation>a</TypographyTextPreparation>
						<FormControl style={{ maxHeight: 60 }}>
							<StyledStartPreparingDuration
								type="number"
								variant="outlined"
								value={deliveryOptions.pickUpTimeEnd}
								InputLabelProps={{ shrink: true }}
								onChange={(val) => {
									setFieldValue(
										'deliveryOptions.pickUpTimeEnd',
										val.target.value
									);
								}}
								error={
									touched.deliveryOptions?.pickUpTimeEnd &&
									Boolean(errors.deliveryOptions?.pickUpTimeEnd)
								}
								helperText={
									touched.deliveryOptions?.pickUpTimeEnd &&
									errors.deliveryOptions?.pickUpTimeEnd
								}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<StyledDurationTypography>
												minutos
											</StyledDurationTypography>
										</InputAdornment>
									),
								}}
							/>
						</FormControl>
					</StyledDeliveryContainer>
				</Box>
			</Box>
			<StyledButtonsContainer>
				<StyledResetFormButton onClick={openModalConfirmResetForm}>
					Limpar
				</StyledResetFormButton>
				<StyledButton onClick={handleSingleHourSubmit}>
					<SaveButton>Salvar alterações</SaveButton>
				</StyledButton>
			</StyledButtonsContainer>
			{showModalConfirmResetForm ? (
				<ConfirmResetFormModal
					close={closeModalConfirmResetForm}
					confirm={handleResetForm}
				/>
			) : null}
		</OpeningHoursFormContainer>
	);
};

export default OpeningHoursForm;
