import { Box, Skeleton, styled, Typography } from "@mui/material";

export const StyledPromotion = styled(Box)(() => ({
  width: "228px",
  height: "100%",
  borderRadius: 20,
  overflow: "hidden",
  boxShadow: "4px 6px 15px rgba(0, 0, 0, 0.1)",
  cursor: "pointer",
  "@media only screen and (max-width: 1400px)": {
    width: "150px",
  },
  "@media only screen and (max-width: 1200px)": {
    width: "124px",
  },
}));
export const StyledPromotionImg = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  "& img": {
    height: "100%",
    width: "100%",
  },
}));
export const StyledPromotionTitle = styled(Typography)(({ theme }) => ({
  marginTop: 8,
  fontSize: 14,
  width: "97%",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.neutral.dark,
  marginLeft: 12,
  display: "inline-block",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  "@media only screen and (max-width: 1400px)": {
    width: "150px",
    fontSize: 10,
  },
}));
export const StyledPromotionValue = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.success.main,
  marginLeft: 12,
}));

export const StyledBestPromotionsTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 36,
  width: "calc(100% - 64px)",
  borderBottom: "1px solid",
  borderColor: theme.palette.neutral.light2,
  padding: "0px 32px 0px 32px",
  justifyContent: "space-between",
}));

export const StyledTitleContainer = styled(Box)(() => ({
  display: "flex",
}));
export const StyledBestPromotionsTitleTypography = styled(Typography)(
  ({ theme }) => ({
    marginBottom: 28,
    fontSize: 18,
    fontFamily: "Rubik",
    fontWeight: 600,
    color: theme.palette.primary.dark,
    textAlign: "left",
    "@media only screen and (max-width: 1400px)": {
      fontSize: 14,
    },
  })
);
export const StyledFireIcon = styled(Box)(() => ({
  marginLeft: 12,
  marginTop: 3,
}));
export const StyledChevronIcon = styled(Box)(() => ({
  marginRight: 8,
  marginTop: 3,
}));
export const StyledPromotionCards = styled(Box)(() => ({
  display: "flex",
  height: 212,
  marginTop: 24,
  gap: 12,
  padding: "0px 32px 0px 32px",
  "& > :first-of-type": {
    marginLeft: 0,
  },
  "& > :only-child": {
    display: "flex",
  },
  "@media (max-width: 1880px)": {
    "& > :last-of-type": {
      display: "none",
    },
    "& > :only-child": {
      display: "flex",
    },
  },
  "@media (max-width: 1269px)": {
    "& > :only-child": {
      display: "flex",
    },
    height: "unset",
  },
}));

export const EmptyCardContainer = styled(Box)(() => ({
  marginTop: 5,
}));

export const StyledPromotionCardSkeleton = styled(Skeleton)(() => ({
  height: "100%",
  width: "100%",
  "@media only screen and (max-width: 1200px)": {
    height: 120,
    width: 124,
    borderRadius: 20,
  },
}));
