import { Box, styled } from '@mui/material';
import { OrderStylesProps } from '../interfaces/interfaces';

export const StyledCouponsStatusDisplay = styled(Box)(({ theme }) => ({
	display: 'grid',
	boxSizing: 'border-box',
	width: '32%',
	backgroundColor: theme.palette.neutral.light,
	borderBottom: '8px solid #6029B1',
	borderRadius: 12,
	padding: '15px 15px 5px 15px',
	'@media only screen and (max-width: 1500px)': {
		padding: '23px 15px 13px 15px',
	},
}));

export const StyledCouponsChartLineContainer = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
}));

export const StyledCouponsNumber = styled(Box)(({ theme }) => ({
	display: 'flex',
	color: theme.palette.neutral.dark,
	fontSize: 24,
	fontFamily: 'Rubik',
	fontWeight: 500,

	'@media only screen and (max-width: 1500px)': {
		fontSize: 18,
		lineHeight: '24px',
		marginBottom: 0,
	},
}));

export const StyledCouponsText = styled(Box)(({ theme }) => ({
	display: 'flex',
	color: theme.palette.neutral.dark1,
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	lineHeight: '20px',
	'@media only screen and (max-width: 1500px)': {
		fontSize: 14,
		lineHeight: '20px',
		textAlign: 'center',
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 12,
		lineHeight: '18px',
		textAlign: 'center',
	},
}));

export const StyledOrderFooterText = styled(Box)<OrderStylesProps>(
	({ theme, descending }) => ({
		display: 'flex',
		color: descending ? theme.palette.error.main : theme.palette.success.main,
		fontSize: 14,
		fontFamily: 'Rubik',
		fontWeight: 500,
		lineHeight: '20px',
		'@media only screen and (max-width: 1500px)': {
			fontSize: 14,
			lineHeight: '20px',
			textAlign: 'center',
		},
		'@media only screen and (max-width: 1200px)': {
			fontSize: 12,
			lineHeight: '18px',
			textAlign: 'center',
		},
	})
);
