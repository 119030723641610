import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as XIcon } from '../../assets/images/XIcon.svg';
import DashboardTopBar from '../../components/DashboardTopBar/DashboardTopBar';
import ConcludeModal from './ConcludeModal/ConcludeModal';
import InfoAddressConfirmPlan from './InfoAddressConfirmPlan/InfoAddressConfirmPlan';
import InfoConfirmPlan from './InfoConfirmPlan/InfoConfirmPlan';
import InfoPaymentConfirmPlan from './InfoPaymentConfirmPlan/InfoPaymentConfirmPlan';
import PageButtons from './PageButtons/PageButton';
import {
	AlignTitleAndButton,
	ButtonClose,
	Container,
	ContainerContent,
	StyledDashboardTopBar,
	Title,
} from './styles';

const ConfirmPlan: React.FC = (): JSX.Element => {
	const [open, setOpen] = useState(false);

	const navigate = useNavigate();

	const navigateToPlans = (): void => {
		navigate('/plans');
	};

	const handleOpen = (): void => setOpen(true);

	const handleClose = (): void => setOpen(false);

	return (
		<Container>
			<ConcludeModal
				open={open}
				handleClose={handleClose}
				navigateToPlans={navigateToPlans}
			/>

			<StyledDashboardTopBar>
				<DashboardTopBar
					title={'Planos'}
					text={'Visualize e gerencie o seu plano'}
				/>
			</StyledDashboardTopBar>

			<ContainerContent>
				<AlignTitleAndButton>
					<Title>Confirmar assinatura</Title>
					<ButtonClose onClick={navigateToPlans}>
						<XIcon color="#747476" />
					</ButtonClose>
				</AlignTitleAndButton>

				<InfoConfirmPlan />

				<InfoAddressConfirmPlan />

				<InfoPaymentConfirmPlan />

				<PageButtons
					handleOpen={handleOpen}
					navigateToPlans={navigateToPlans}
				/>
			</ContainerContent>
		</Container>
	);
};

export default ConfirmPlan;
