import { Box, styled, Typography } from '@mui/material';

export const StyledContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	width: '100%',
	height: 56,
	backgroundColor: theme.palette.neutral.light,
	boxShadow: '4px 6px 15px rgba(0, 0, 0, 0.1)',
	padding: '32px 47px',
	justifyContent: 'space-between',
	'@media only screen and (max-width: 1400px)': {
		padding: '22px 47px',
	},
	'@media only screen and (max-width: 1200px)': {
		padding: '11px 47px',
	},
}));

export const StyledTextContainer = styled(Box)(() => ({}));

export const TypographyTitle = styled(Typography)(({ theme }) => ({
	fontSize: 24,
	fontFamily: 'Rubik',
	fontWeight: 500,
	display: 'baseline',
	color: theme.palette.neutral.dark,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 20,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 16,
	},
}));

export const TypographyText = styled(Typography)(({ theme }) => ({
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 400,
	display: 'baseline',
	color: theme.palette.neutral.dark1,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 16,
	},
	'@media only screen and (max-width: 1200px)': {
		fontSize: 14,
	},
}));

export const StyledIcon = styled(Box)(() => ({
	paddingTop: 13,
}));
