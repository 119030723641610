import { Box, Skeleton, styled, Tab, Tabs } from "@mui/material";

interface StyledTabProps {
  label: string;
}

export const StyledCouponTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 24,
  fontSize: 24,
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.primary.dark,
  marginBottom: 24,
  "@media only screen and (max-width: 1400px)": {
    height: 16,
    fontSize: 16,
  },
}));

export const StyledPromotionCouponBarContainer = styled(Box)(() => ({
  display: "block",
  alignItems: "center",
  justifyContent: "center",
}));

export const CouponTabs = styled(Tabs)({
  display: "flex",
  backgroundColor: "#F9F9F9",
  borderRadius: 30,
});

export const StyledTabsContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
}));

export const CouponTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontSize: 14,
  fontWeight: 500,
  color: theme.palette.neutral.dark1,
  backgroundColor: theme.palette.neutral.light1,
  fontFamily: "Rubik",
  padding: "14px 32px",
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.neutral.light,
    borderRadius: 30,
    "@media only screen and (max-width: 1400px)": {
      width: 70,
      fontSize: 10,
    },
  },
  "@media only screen and (max-width: 1400px)": {
    width: 70,
    fontSize: 10,
  },
}));

export const ExpiredCouponTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  minWidth: 167,
  fontSize: 14,
  fontWeight: 500,
  color: theme.palette.neutral.dark1,
  backgroundColor: theme.palette.neutral.light1,
  fontFamily: "Rubik",
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.neutral.light,
    borderRadius: 30,
  },
}));

export const StyledCouponValueContainer = styled(Box)(() => ({
  display: "flex",
  minHeight: 28,
  marginBottom: 22,
  justifyContent: "space-between",
  "@media only screen and (max-width: 1400px)": {
    marginBottom: 10,
  },
}));

export const StyledCouponValue = styled(Box)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 500,
  color: theme.palette.neutral.dark,
  fontFamily: "Rubik",
  "@media only screen and (max-width: 1400px)": {
    height: 14,
    fontSize: 14,
  },
}));

export const StyledCouponPercent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 72,
  height: 28,
  fontSize: 18,
  fontWeight: 500,
  color: theme.palette.success.main,
  fontFamily: "Rubik",
  borderRadius: 16,
  background: "rgba(95, 247, 183, 0.2)",
  "@media only screen and (max-width: 1400px)": {
    height: 20,
    fontSize: 14,
  },
}));

export const StyledCouponIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.neutral.light2,
  height: 60,
  width: 60,
  borderRadius: 40,
  marginBottom: 20,
  overflow: "hidden",
  "@media only screen and (max-width: 1400px)": {
    height: 20,
    width: 20,
  },
}));

export const StyledEmptyCouponTitle = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 28,
  fontSize: 24,
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.primary.dark,
  marginBottom: 8,
  "@media only screen and (max-width: 1400px)": {
    height: 28,
    fontSize: 18,
  },
}));

export const StyledEmptyCouponTitleSkeleton = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 28,
  fontSize: 24,
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.primary.dark,
  marginBottom: 10,
  "@media only screen and (max-width: 1400px)": {
    height: 28,
    fontSize: 18,
  },
}));

export const StyledEmptyCouponText = styled(Box)(({ theme }) => ({
  display: "flex",
  height: 28,
  fontSize: 18,
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.neutral.dark1,
  "@media only screen and (max-width: 1400px)": {
    height: 28,
    fontSize: 14,
  },
}));

export const CouponIconSkeleton = styled(Skeleton)(() => ({
  height: "100%",
  width: "100%",
}));

export const EmptyCouponTitleSkeleton = styled(Skeleton)(() => ({
  height: "100%",
  width: "50%",
}));

export const EmptyCouponTextSkeleton = styled(Skeleton)(() => ({
  height: "95%",
  width: "80%",
}));
