import * as yup from 'yup';

const VALIDATION_PROFILE_SCHEMA = yup.object({
	advertiserInfos: yup.object({
		advertiserName: yup
			.string()
			.typeError('Campo obrigatório')
			.required('Campo obrigatório'),
		advertiserDescription: yup
			.string()
			.typeError('Campo obrigatório')
			.required('Campo obrigatório'),
		advertiserCategoryId: yup.string().required('Campo obrigatório').nullable(),
	}),
	advertiserAddress: yup.object({
		cep: yup.string().required('Campo obrigatório').min(8, 'Cep inválido'),
		state: yup.string().required('Campo obrigatório'),
		city: yup.string().required('Campo obrigatório'),
		address: yup.string().required('Campo obrigatório'),
		number: yup.number().required('Campo obrigatório'),
		district: yup.string().required('Campo obrigatório'),
	}),
	advertiserContacts: yup.object({
		phone: yup.string(),
		cellphone: yup.string().required('Campo obrigatório'),
		socialMedias: yup.array().of(
			yup.object().shape({
				social_media: yup.string().required('Campo Obrigatório'),
				link: yup.string().required('Campo Obrigatório'),
			})
		),
	}),
});

export default VALIDATION_PROFILE_SCHEMA;
