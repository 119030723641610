import { Box, Typography, styled } from '@mui/material';

export const WrapperHistoryDay = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	maxWidth: '100%',
	backgroundColor: theme.palette.neutral.light1,
	padding: '0px 0px 0px 20px',
}));

export const MiddleContent = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
}));

export const Title = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.common.black,
}));

export const QuantityOrders = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
}));

export const TotalSum = styled(Box)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
}));

export const AlignIcon = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '100px 0px',
}));
