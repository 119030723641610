import { Box, styled, Typography } from "@mui/material";

export const Texts = styled(Box)(() => ({
  width: "90%",
  marginTop: "35px",
  marginLeft: "54px",
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.common.black,
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 400,
  color: theme.palette.neutral.dark1,
}));

export const PlansHistory = styled(Box)(() => ({
  width: "100%",
}));

export const FirstHistoryCard = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "520px",
  height: "70px",
  marginTop: "5%",
  marginLeft: "54px",
  padding: "0px 20px 0px 20px",
  borderTopLeftRadius: "16px",
  borderTopRightRadius: "16px",
  border: `1px solid ${theme.palette.primary.contrastText}`,
}));

export const NameAndLogo = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const PlanNameHistory = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.common.black,
  marginLeft: "12px",
}));

export const FormOfPaymentBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const FormOfPaymentText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 400,
  color: theme.palette.neutral.dark1,
}));

export const FormOfPayment = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  marginLeft: "5px",
  fontWeight: 400,
  color: theme.palette.neutral.dark1,
}));

export const DayPayText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 400,
  color: theme.palette.neutral.dark1,
}));

export const SecondHistoryCard = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "520px",
  height: "70px",
  marginLeft: "54px",
  padding: "0px 20px 0px 20px",
  borderBottomLeftRadius: "16px",
  borderBottomRightRadius: "16px",
  border: `1px solid ${theme.palette.primary.contrastText}`,
  borderTop: "none",
}));
