import { Box, Button, styled, Typography } from "@mui/material";

export const Container = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "35px",
}));

export const CancelButton = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "209px",
  height: "56px",
  marginRight: "24px",
  borderRadius: "8px",
  border: `1px solid ${theme.palette.neutral.dark2}`,
}));

export const CancelButtonText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 700,
  color: theme.palette.primary.main,
  textTransform: "none",
}));

export const ForwardButton = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "500px",
  height: "56px",
  marginRight: "52px",
  borderRadius: "8px",
  backgroundColor: theme.palette.primary.main,
}));

export const ForwardButtonText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 700,
  color: theme.palette.common.white,
  textTransform: "none",
}));
