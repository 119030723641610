import { Box, Button, styled, Typography } from "@mui/material";

export const Texts = styled(Box)(() => ({
  width: "90%",
  marginTop: "35px",
  marginLeft: "54px",
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.common.black,
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 400,
  color: theme.palette.neutral.dark1,
}));

export const CardWallet = styled(Box)(() => ({
  display: "flex",
  width: "90%",
  marginTop: "30px",
  marginLeft: "54px",
}));

export const RemoveCreditCard = styled(Box)(({ theme }) => ({
  width: "356px",
  height: "140px",
  borderRadius: "16px",
  border: `1px solid ${theme.palette.primary.contrastText}`,
}));

export const CreditCardHeader = styled(Box)(() => ({
  marginTop: "25px",
  marginLeft: "24px",
}));

export const CreditCardText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 400,
  color: theme.palette.neutral.dark1,
}));

export const CreditCardContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginTop: "40px",
  marginLeft: "24px",
}));

export const FinalCardNumbers = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 400,
  marginLeft: "14px",
  color: theme.palette.common.black,
}));

export const ButtonRemove = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "94px",
  height: "40px",
  marginLeft: "40px",
  borderRadius: "4px",
  border: `1px solid ${theme.palette.neutral.dark2}`,
}));

export const ButtonRemoveText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 700,
  textTransform: "none",
  color: theme.palette.primary.main,
}));

export const ButtonAddCreditCard = styled(Button)(() => ({
  marginLeft: "28px",
}));

export const AlignTalkToUsButton = styled(Button)(() => ({
  position: "absolute",
  marginLeft: "815px",
  marginTop: "-90px",
}));
