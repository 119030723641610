import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
	Button,
	ClickAwayListener,
	Grow,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Popper,
} from '@mui/material';
import React from 'react';
import { CreatePopperMenuProps } from './interfaces';

const modifiers = [
	{
		name: 'flip',
		enabled: false,
	},
];

const CreatePopperButton: React.FC<CreatePopperMenuProps> = ({
	open,
	anchorRef,
	menuItems,
	handleItemClick,
	handleClose,
	handleListKeyDown,
	handleToggle,
	index,
}: CreatePopperMenuProps) => {
	return (
		<React.Fragment>
			<Button
				aria-controls={open ? 'menu-list-grow' : undefined}
				aria-haspopup="true"
				onClick={() => handleToggle(index)}
				size="small"
				ref={() => (anchorRef.current ? anchorRef.current[index] : null)}
			>
				<MoreVertIcon />
			</Button>

			<Popper
				open={open}
				anchorEl={anchorRef.current ? anchorRef.current[index] : null}
				transition
				disablePortal
				style={{ zIndex: 3 }}
				placement="bottom-start"
				modifiers={modifiers}
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
						<Paper>
							<ClickAwayListener onClickAway={() => handleClose(index)}>
								<MenuList
									autoFocusItem={open}
									id="menu-list-grow"
									onKeyDown={handleListKeyDown}
								>
									{menuItems.map((item) => (
										<MenuItem
											key={item.action}
											onClick={() => handleItemClick(item.action, index)}
										>
											<ListItemText primary={item.label} />
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</React.Fragment>
	);
};

export default CreatePopperButton;
