import { Box, styled, Typography } from "@mui/material";

export const StyledFormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: 24,
  width: "70%",
  paddingTop: 24,
  backgroundColor: theme.palette.neutral.light,
  borderRadius: 40,
  padding: "32px 64px 63px 64px ",
  [theme.breakpoints.down('md')]: {
    maxWidth: "75%",
    padding: "20px 55px 20px 55px ",
    margin: 'auto',
  }
}));

export const StyledBackArrow = styled(Box)(() => ({
  cursor: "pointer",
  width: 32,
  height: 32,
}));

export const StyledFormControl = styled(Box)(() => ({

}));
export const StyledFormText = styled(Box)(({ theme }) => ({
  fontSize: 24,
  lineHeight: "28px",
  color: theme.palette.primary.main,
  fontFamily: "Rubik",
  fontWeight: 500,
}));
export const StyledFormSubText = styled(Box)(({ theme }) => ({
  fontSize: 18,
  lineHeight: "24px",
  color: theme.palette.neutral.dark1,
  fontFamily: "Rubik",
  fontWeight: 500,
  marginBottom: 16,
}));

export const StyledFieldsTitle = styled(Typography)(() => ({
  fontSize: 18,
  fontFamily: "Rubik",
  fontWeight: 500,
}));

export const StyledSendButtonContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
  width: "68%",
}));
