import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
	Box,
	CircularProgress,
	Collapse,
	IconButton,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableRow,
} from '@mui/material';
import { AxiosError } from 'axios';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as ComplementsGroupIcon } from '../../../../assets/images/complementsGroup.svg';
import { ReactComponent as NoImageIcon } from '../../../../assets/images/noImageIcon.svg';
import { toggleSwitch } from '../../../../auth/actions/toggle.action';
import {
	IToggle,
	IToggleAction,
} from '../../../../auth/interface/toggle.interface';
import AutoSave from '../../../../shared/helpers/autoSaveFormik';
import { getOptionsGroupsByItem } from '../../../../utils/api/get/getOptionsGroupsByItems';
import { IItemOptionsGroup, IMenuItem } from '../../../../utils/api/interfaces';
import { moneyMask } from '../../../../utils/mask.util';
import { SwitchText } from '../../../Forms/AddItem/ComplementaryItems/ComplementaryComponent/styles';
import MenuPopperButton from '../../MenuPopperButton';
import OptionsGroupHeader from '../../OptionsGroupHeader/OptionsGroupHeader';
import { BorderlessButton, StyledHeaderOptionsCell } from '../../styles';
import ItemImgModal from './ItemImgModal/ItemImgModal';
import { IMenuListItem } from './interfaces';
import {
	BoxForEmptyGroup,
	BoxForLoadingGroups,
	ComplementsGroupTitle,
	EmptyTitle,
	ImageContent,
	ImageInput,
	ItemName,
	PreviewDetails,
	StyledValueTextField,
	WrapperItemName,
	WrapperTitle,
} from './styles';
import { customTextFieldStyles, formHelperStyle } from './utils';

const Item: React.FC<IMenuListItem> = ({
	item,
	idMenu,
	handleItemPatch,
	handleEditItemOption,
	itemImageModalIsVisible,
	handleOpenItemImageModal,
	handleOpenDeleteItemModal,
	handleCloseItemImageModal,
}: IMenuListItem): JSX.Element => {
	const [open, setOpen] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const anchorRef = useRef<HTMLButtonElement>(null);
	const [newItem, setNewItem] = useState<IMenuItem>();
	const [openOptions, setOpenOptions] = useState(false);
	const [optionsGroup, setOptionsGroup] = useState<IItemOptionsGroup[]>();
	const [loaderOptionsGroup, setLoaderOptionsGroup] = useState<boolean>(false);
	const dispatch = useDispatch();

	const {
		id,
		item_image_url,
		name,
		description,
		price,
		available,
		has_option_group,
	} = item;
	const menuItems = [
		{ action: 'edit', label: 'Editar item' },
		{ action: 'delete', label: 'Excluir item' },
	];

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: MouseEvent | TouchEvent) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
			return;
		}
		setOpen(false);
	};

	const handleListKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	};

	const handleItemClick = (action: string) => {
		const actionFunctions: { [key: string]: () => void } = {
			edit: () => handleEditItemOption(id),
			delete: () => handleOpenDeleteItemModal(item),
		};

		if (actionFunctions[action]) {
			actionFunctions[action]();
		}

		setOpen(false);
	};

	const handleGetOptionsGroup = (): void => {
		if (openOptions) {
			setOpenOptions(false);
			return;
		}
		setLoaderOptionsGroup(true);
		setOpenOptions(!openOptions);
		getOptionsGroupsByItem(id)
			.then((res) => {
				setOptionsGroup(res);
			})
			.catch((err: AxiosError) => {
				if (err) {
					enqueueSnackbar('Ocorreu um erro tente novamente.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				}
			})
			.finally(() => {
				setLoaderOptionsGroup(false);
			});
	};

	const setToggle = (toggle: IToggle): IToggleAction =>
		dispatch(toggleSwitch(toggle));

	return (
		<>
			<TableRow>
				<TableCell component="th" scope="row">
					<Box display={'flex'} alignItems={'center'}>
						{item_image_url || newItem?.item_image_url ? (
							<ImageContent
								width={102}
								height={62}
								mr={2}
								sx={{
									'& img': {
										height: '100%',
										width: '100%',
										objectFit: 'scale-down',
									},
								}}
							>
								<img
									src={item_image_url || newItem?.item_image_url}
									alt={name}
									style={{
										width: '65px',
										height: '65px',
										borderRadius: '8px',
										marginRight: '8px',
									}}
								/>
							</ImageContent>
						) : (
							<Formik
								enableReinitialize
								initialValues={{
									item_img: newItem?.item_image_url ?? '',
									itemId: id,
								}}
								onSubmit={(values, { setSubmitting }) => {
									handleItemPatch(values, setSubmitting, setNewItem);
								}}
							>
								{
									<>
										<ImageInput onClick={handleOpenItemImageModal}>
											<NoImageIcon />
										</ImageInput>

										{itemImageModalIsVisible && (
											<ItemImgModal
												handleCloseImgModal={handleCloseItemImageModal}
												openImgModal={itemImageModalIsVisible}
											/>
										)}
									</>
								}
							</Formik>
						)}
						<WrapperItemName>
							<ItemName>{name}</ItemName>
							<PreviewDetails>{description}</PreviewDetails>
						</WrapperItemName>
					</Box>
				</TableCell>
				<TableCell
					style={{
						whiteSpace: 'nowrap',
					}}
				>
					<Box width={'fit-content'} margin={'auto'}>
						<Formik
							initialValues={{
								itemId: id,
								price: price.value.value,
							}}
							onSubmit={(values, { setSubmitting }) => {
								handleItemPatch(values, setSubmitting, setNewItem);
							}}
						>
							{({
								handleChange,
								submitForm,
								isSubmitting,
								values: { price },
							}) => (
								<Form>
									<StyledValueTextField
										id="outlined-basic"
										value={moneyMask(price)}
										placeholder="ex: R$ 987,00"
										variant="outlined"
										onChange={handleChange('price')}
										disabled={isSubmitting}
										size="small"
										inputProps={{ maxLength: 15 }}
										FormHelperTextProps={{
											style: formHelperStyle,
										}}
										sx={customTextFieldStyles}
									/>
									<AutoSave onSubmit={submitForm} />
								</Form>
							)}
						</Formik>
					</Box>
				</TableCell>

				<TableCell
					style={{
						whiteSpace: 'nowrap',
					}}
				>
					<Box width={'fit-content'} margin={'auto'}>
						<Formik
							enableReinitialize
							initialValues={{
								itemId: id,
								available: newItem?.available ?? available,
							}}
							onSubmit={(values, { setSubmitting }) => {
								handleItemPatch(values, setSubmitting, setNewItem);
							}}
						>
							{({
								handleSubmit,
								setFieldValue,
								isSubmitting,
								submitForm,
								values: { available },
							}) => {
								return (
									<Form
										onSubmit={(e) => {
											e.preventDefault();
											handleSubmit();
										}}
									>
										<BorderlessButton>
											<SwitchText>
												{available ? 'Ativado' : 'Desativado'}
											</SwitchText>
											<Switch
												checked={available}
												onChange={() => {
													setFieldValue('available', !available);

													setToggle({ menu: !available, menuId: idMenu });
												}}
												disabled={isSubmitting}
												inputProps={{ 'aria-label': 'status switch' }}
											/>
										</BorderlessButton>
										<AutoSave onSubmit={submitForm} />
									</Form>
								);
							}}
						</Formik>
					</Box>
				</TableCell>
				<TableCell>
					<Box sx={{ marginLeft: has_option_group ? '0px' : '34px' }}>
						<IconButton
							aria-label="expand row"
							size="small"
							onClick={() => handleGetOptionsGroup()}
							style={{ display: has_option_group ? 'inline' : 'none' }}
						>
							{openOptions ? (
								<KeyboardArrowUpIcon />
							) : (
								<KeyboardArrowDownIcon />
							)}
						</IconButton>

						<MenuPopperButton
							open={open}
							anchorRef={anchorRef}
							menuItems={menuItems}
							handleClose={handleClose}
							handleToggle={handleToggle}
							handleItemClick={handleItemClick}
							handleListKeyDown={handleListKeyDown}
						/>
					</Box>
				</TableCell>
			</TableRow>

			{loaderOptionsGroup && (
				<BoxForLoadingGroups>
					<CircularProgress color="primary" size={20} />
				</BoxForLoadingGroups>
			)}

			{!loaderOptionsGroup && (
				<TableRow
					style={{
						borderBottomWidth: 1,
						borderBottomColor: 'red',
						paddingBottom: 20,
					}}
				>
					<TableCell style={{ paddingBlock: 0 }} colSpan={6}>
						<Collapse in={openOptions} timeout="auto" unmountOnExit>
							<Table>
								<TableBody>
									<TableRow>
										<StyledHeaderOptionsCell>
											<WrapperTitle>
												<ComplementsGroupIcon />
												<ComplementsGroupTitle>
													Grupo(s) de complemento do item
												</ComplementsGroupTitle>
											</WrapperTitle>
										</StyledHeaderOptionsCell>
									</TableRow>
									{optionsGroup && optionsGroup?.length > 0 ? (
										optionsGroup?.map((optionsGroup, indexOptionGroup) => (
											<TableRow key={optionsGroup.id}>
												<OptionsGroupHeader
													itemId={item.id}
													optionsGroup={optionsGroup}
													openOptions={openOptions}
													handleRefreshData={handleGetOptionsGroup}
													indexOptionGroup={indexOptionGroup}
												/>
											</TableRow>
										))
									) : (
										<BoxForEmptyGroup>
											<EmptyTitle>
												{` Nenhum grupo de complementos registrado em "${item.name}". Vá em "Editar item" para cadastrar novos grupos.`}
											</EmptyTitle>
										</BoxForEmptyGroup>
									)}
								</TableBody>
							</Table>
						</Collapse>
					</TableCell>
				</TableRow>
			)}
		</>
	);
};

export default Item;
