import { Box, styled, Typography } from "@mui/material";
import { ReactComponent as ComingSoonIcons } from "../../../assets/images/comingSoonIcon.svg";

export const StyledTopInfo = styled(Box)(() => ({
  width: 112,
  height: 25,
  backgroundColor: "#FF5616",
  borderTopLeftRadius: 20,
  borderBottomRightRadius: 20,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "12px 24px",
  justifyContent: "center",
  "@media only screen and (max-width: 1400px)": {
    width: 50,
    height: 5,
  },
  "@media only screen and (max-width: 1200px)": {
    width: 43,
    height: 5,
    padding: "10px 20px",
  },
}));

export const StyledPlanSoon = styled(Typography)(() => ({
  fontFamily: "rubik",
  fontSize: 18,
  fontWeight: 400,
  color: "#ffffff",
  "@media only screen and (max-width: 1400px)": {
    fontSize: 10,
  },
  "@media only screen and (max-width: 1200px)": {
    fontSize: 9,
  },
}));

export const StyledComingSoonIcons = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginTop: 20,
  "@media only screen and (max-width: 1400px)": {
    marginTop: -20,
  },
}));

export const StyledTitlePlan = styled(Typography)(() => ({
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 20,
  color: "#412272",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  "@media only screen and (max-width: 1400px)": {
    fontSize: 10,
    marginTop: -20,
    paddingBottom: 11,
  },
}));

export const StyledDescriptionPlan = styled(Typography)(() => ({
  fontFamily: "Rubik",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: 14,
  color: "#747476",
  display: "flex",
  textAlign: "center",
  padding: "0px 67px",
  whiteSpace: "pre-line",
  paddingTop: 11,
  justifyContent: "center",
  "@media only screen and (max-width: 1400px)": {
    fontSize: 10,
    paddingTop: 11,
    padding: 0,
  },
  "@media only screen and (max-width: 1200px)": {
    fontSize: 9,
    paddingTop: 11,
    padding: 0,
  },
}));

export const PlansSvg = styled(ComingSoonIcons)(() => ({
  "@media only screen and (max-width: 1400px)": {
    width: 100,
  },
}));
