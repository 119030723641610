import { ReactComponent as CheckIcon } from "../../../assets/images/CheckIcon.svg";
import { IPlansToChoose } from "../interfaces";

import {
  AlignIconAndText,
  Benefits,
  BenefitsAndButton,
  ButtonChoosePlan,
  ButtonText,
  Container,
  FirstBenefitText,
  HeaderPlan,
  IconAndPlan,
  NewPrice,
  NewPriceAndText,
  NewPriceText,
  OldAndNewPrice,
  OldPrice,
  PlanText,
  PlanType,
  SecondBefitText,
  TypeContent,
} from "./styles";

const PlansToChoose: React.FC<IPlansToChoose> = ({
  planType,
  oldPrice,
  newPrice,
  firstBenefit,
  secondeBenefit,
  handleIconPlansToChoose,
  handleClose,
}: IPlansToChoose): JSX.Element => (
  <Container>
    <HeaderPlan>
      <IconAndPlan>
        {handleIconPlansToChoose(planType)}
        <TypeContent>
          <PlanText>Plano</PlanText>
          <PlanType>{planType}</PlanType>
        </TypeContent>
      </IconAndPlan>
      <OldAndNewPrice>
        <OldPrice>{oldPrice}</OldPrice>
        <NewPriceAndText>
          <NewPrice>{newPrice}</NewPrice>
          <NewPriceText>/mês</NewPriceText>
        </NewPriceAndText>
      </OldAndNewPrice>
    </HeaderPlan>

    <BenefitsAndButton>
      <Benefits>
        <AlignIconAndText>
          <CheckIcon />
          <FirstBenefitText>{firstBenefit}</FirstBenefitText>
        </AlignIconAndText>
        <AlignIconAndText>
          <CheckIcon />
          <SecondBefitText>{secondeBenefit}</SecondBefitText>
        </AlignIconAndText>
      </Benefits>
      <ButtonChoosePlan onClick={handleClose}>
        <ButtonText>Escolher plano</ButtonText>
      </ButtonChoosePlan>
    </BenefitsAndButton>
  </Container>
);

export default PlansToChoose;
