import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle } from '@mui/material';
import { Form, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { ReactComponent as SmallEmptyIcon } from '../../../../../assets/images/smallEmptyImg.svg';
import { convertToBase64 } from '../../../../../shared/helpers/convertToBase64.helper';
import ContinueButton from '../../../../Buttons/ContinueLoginButton/ContinueLoginButton';
import {
	Input,
	StyledButtonsContainer,
	StyledCreateItemImgButton,
	StyledDialog,
	StyledEmptyImage,
	StyledIconButton,
	StyledItemModal,
	StyledResetFormButton,
	StyledUploadFileContainer,
	StyledUploadFileContent,
	StyledUploadFileTypography,
	SyledImgPreview,
	SyledUploadImage,
} from './styles';

interface ItemImgModalProps {
	openImgModal: boolean;
	handleCloseImgModal: () => void;
}

interface ItemImgModal {
	itemId: string;
	itemImage: string;
}

const ItemImgModal: React.FC<ItemImgModalProps> = ({
	openImgModal,
	handleCloseImgModal,
}: ItemImgModalProps): JSX.Element => {
	const { setFieldValue, submitCount } = useFormikContext<ItemImgModal>();
	const [photoError, setPhotoError] = useState(false);
	const [imageState, setImageState] = useState({
		itemImage: '',
		item_image_name: '',
		path: '',
	});
	const onCloseModal = (): void => {
		handleCloseImgModal();
	};

	const handleFileChange = async (e: any) => {
		const file = e.target.files[0];
		const base64 = await convertToBase64(file);
		setImageState({
			itemImage: base64 as string,
			item_image_name: e.target.files[0].name,
			path: URL.createObjectURL(e.target.files[0]),
		});
		setPhotoError(false);
	};

	const handleDrag = (e: React.DragEvent<HTMLLabelElement>) => {
		e.preventDefault();
	};

	const handleDrop = async (e: React.DragEvent<HTMLLabelElement>) => {
		e.preventDefault();
		const file = e.dataTransfer.files[0];
		const base64 = await convertToBase64(file);
		setImageState({
			itemImage: base64 as string,
			item_image_name: file.name,
			path: URL.createObjectURL(file),
		});
	};

	const handleResetForm = () => {
		setImageState({
			itemImage: '',
			item_image_name: '',
			path: '',
		});
		if (submitCount > 0) {
			setPhotoError(true);
		}
	};

	useEffect(() => {
		setFieldValue('itemImage', imageState?.itemImage ?? '');
	}, [imageState?.itemImage]);

	return (
		<StyledDialog
			open={openImgModal}
			onClose={onCloseModal}
			fullWidth
			maxWidth="sm"
		>
			<StyledIconButton onClick={onCloseModal}>
				<CloseIcon />
			</StyledIconButton>
			<DialogTitle alignSelf={'start'} fontSize={'1.8rem'}>
				Cadastrar foto do item
			</DialogTitle>
			<StyledItemModal>
				<Form
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						flex: 1,
					}}
				>
					{imageState.path ? (
						<SyledImgPreview>
							<img src={imageState?.path} />
							<StyledButtonsContainer>
								<StyledResetFormButton onClick={() => handleResetForm()}>
									Remover
								</StyledResetFormButton>
								<StyledCreateItemImgButton>
									<ContinueButton>Salvar imagem</ContinueButton>
								</StyledCreateItemImgButton>
							</StyledButtonsContainer>
						</SyledImgPreview>
					) : (
						<SyledUploadImage error={!photoError}>
							<label
								onDrop={(e) => handleDrop(e)}
								onDragOver={(e) => handleDrag(e)}
								onDragEnter={(e) => handleDrag(e)}
								onDragLeave={(e) => handleDrag(e)}
							>
								<Input
									type="file"
									name="item"
									onChange={handleFileChange}
									accept="image/*"
									onDragOver={(e) => {
										e.preventDefault(), e.stopPropagation();
									}}
									onDrag={handleFileChange}
									multiple
								/>
								<StyledUploadFileContainer>
									<StyledUploadFileContent>
										<StyledEmptyImage>
											<SmallEmptyIcon />
										</StyledEmptyImage>
										<StyledUploadFileTypography>
											Clique aqui para selecionar a foto ou arraste a foto até a
											tela
										</StyledUploadFileTypography>
									</StyledUploadFileContent>
								</StyledUploadFileContainer>
							</label>
						</SyledUploadImage>
					)}

					{/* <StyledImageError component="div" name="item_image" /> */}
				</Form>
			</StyledItemModal>
		</StyledDialog>
	);
};

export default ItemImgModal;
