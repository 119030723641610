import { Button, styled, Typography } from '@mui/material';

export const StyledButton = styled(Button)(({ theme }) => ({
	height: 56,
	width: 300,
	borderRadius: 8,
	backgroundColor: theme.palette.primary.main,
	padding: '16px 32px',
	'&:hover': {
		backgroundColor: theme.palette.primary.main,
	},
	'@media only screen and (max-width: 1400px)': {
		height: 39,
		width: 200,
	},
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
	textTransform: 'none',
	width: '100%',
	fontSize: 18,
	color: theme.palette.neutral.light,
	fontFamily: 'Rubik',
	fontWeight: 700,
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
	},
}));
