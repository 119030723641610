import { AxiosResponse } from "axios";
import { CouponStatus } from "../../../pages/Promotion/PromotionDetails/interface/coupon.interface";
import { BASE_URL } from "../api";
import { request } from "../base-request/base-request";

export const getCouponsStatusByPromotion = async (
  promotionId: string
): Promise<AxiosResponse<CouponStatus>> => {
  try {
    const response = await request({
      url: `${BASE_URL}/coupons/status/promotion/${promotionId}`,
      method: "get",
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
