import { Box, styled } from "@mui/material";
import { ReactComponent as DubItLogo } from "../../../assets/images/dubItLogo.svg";

export const StyledContainer = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  gap: 40,
  "@media (orientation: portrait)": {
    display: "inline-block",
  },
}));

export const StyledText = styled(Box)(({ theme }) => ({
  fontFamily: "Rubik",
  fontWeight: 400,
  fontSize: 12,
  color: theme.palette.neutral.light,
  marginBottom: 24,
  marginTop: 24,
  width: 214,
  lineHeight: "16px",
  "@media (orientation: portrait)": {
    margin: "24px  auto",
    textAlign: "center",
  },
}));

export const StyledLogo = styled(DubItLogo)(() => ({
  "@media (orientation: portrait)": {
    display: "flex",
    margin: "50px  auto",
  },
}));
