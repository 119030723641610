import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';
import { AxiosError } from 'axios';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { ReactComponent as ConfirmIcon } from '../../../assets/images/confirmModalIcon.svg';
import { createPromotion } from '../../../utils/api/post/createPromotion';
import { editPromotion } from '../../../utils/api/put/editPromotion';
import { getCategoriesIcons } from '../../../utils/getCategoriesIcons/getCategoriesIcons';
import {
	dateFormat,
	hourFormat,
	moneyMask,
	unMaskReais,
} from '../../../utils/mask.util';
import { SIZE_BUTTON } from '../../Buttons/ContinueLoginButton/contants/button-config.constants';
import { PromotionConfirmModalProps } from './interface/promotionConfirm-modal.interface';
import {
	StyledButton,
	StyledButtonContainer,
	StyledCalendarIcon,
	StyledCouponIcon,
	StyledDateContainer,
	StyledDialog,
	StyledDialogContent,
	StyledIconButton,
	StyledImgInfo,
	StyledImgInfoText,
	StyledLightIcon,
	StyledModalSubtitle,
	StyledModalTitle,
	StyledModalTitleContainer,
	StyledOldPriceIcon,
	StyledPriceAndCoupounsContainer,
	StyledPromotionCouponContainer,
	StyledPromotionDate,
	StyledPromotionDateContainer,
	StyledPromotionDescription,
	StyledPromotionHour,
	StyledPromotionInfo,
	StyledPromotionPrice,
	StyledPromotionPriceContainer,
	StyledPromotionTag,
	StyledPromotionTagIcon,
	StyledPromotionText,
	StyledPromotionTitle,
	StyledPromotionTitleContainer,
	StyledPurpleDot,
	StyledSalePriceIcon,
	StyledTitleContainer,
	StyledTypography,
	SyledPromotionImage,
	SyledPromotionImageContainer,
} from './styles';

const PromotionConfirmModal: React.FC<PromotionConfirmModalProps> = ({
	openPromotionConfirmModal,
	handleClosePromotionConfirmModal,
	values,
	resetForm,
	handleOpenPromotionModal,
	fromDetails,
	getPromotion,
	id,
	promotion,
}: PromotionConfirmModalProps): JSX.Element => {
	const { enqueueSnackbar } = useSnackbar();
	const [isSubmiting, setIsSubmiting] = useState(false);

	const {
		title,
		description,
		promotion_expires_date,
		promotion_expires_hour,
		tag_id,
		promotion_image,
		has_coupons,
		qtd_coupons,
		tag_name,
		initial_price,
		promotional_price,
		coupon_duration,
		user_with_multiplus_coupons,
	} = values;

	const promotion_initial_date = new Date().toLocaleDateString('pt-Br', {
		dateStyle: 'short',
	});
	const promotion_initial_hour = moment().format();

	const ButtonText = fromDetails ? 'Confirmar alterações' : 'Postar promoção';

	const handlePromotion = (): void => {
		fromDetails ? handleEditPromotion() : handleCreatePromotion();
	};

	const handleCreatePromotion = (): void => {
		setIsSubmiting(true);

		const initialPriceRaw = values.initial_price;
		const promotionalPriceRaw = values.promotional_price;

		const initial_price = unMaskReais(initialPriceRaw);
		const promotional_price = unMaskReais(promotionalPriceRaw);

		const initialDateRaw =
			values.promotion_expires_date.toLocaleDateString('en-CA');

		const dateTime = moment(
			`'${initialDateRaw}' ${promotion_expires_hour}`,
			'YYYY-MM-DD HH:mm:ss'
		).format();

		const promotion_expires_date = new Date(dateTime);

		const promotion_initial_date = new Date();

		const qtd_coupons = values.qtd_coupons === '' ? 0 : values.qtd_coupons;

		createPromotion({
			promotion: {
				title,
				description,
				initial_price,
				promotional_price,
				promotion_initial_date,
				promotion_expires_date,
				tag_id,
				promotion_image,
				has_coupons,
				qtd_coupons,
				coupon_duration,
				user_with_multiplus_coupons: !user_with_multiplus_coupons,
			},
		})
			.then(() => {
				setIsSubmiting(false);
				handleClosePromotionConfirmModal();
				handleOpenPromotionModal();
				resetForm();
			})
			.catch((error: AxiosError) => {
				if (
					error.response?.data.message ===
					'You has not permition  to create promotion'
				) {
					setIsSubmiting(false);
					enqueueSnackbar('Você já atingiu seu limite de postagens mensal.', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				} else if (error.response?.data.message === 'Initial date invalid') {
					setIsSubmiting(false);
					enqueueSnackbar('Verifique a hora de expiração da promoção', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				} else if (
					error.response?.data.message === 'number of days exceeds the limit'
				) {
					setIsSubmiting(false);
					enqueueSnackbar('A duração máxima da promoção é de 5 dias', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				} else if (error.response?.data.message === undefined) {
					setIsSubmiting(false);
					enqueueSnackbar('Verifique o tamanho da imagem.(Máximo: 4mb)', {
						variant: 'error',
						autoHideDuration: 1500,
					});
				} else {
					setIsSubmiting(false);
					enqueueSnackbar(
						'Ocorreu um erro  verifique as informações e tente novamente.',
						{
							variant: 'error',
							autoHideDuration: 1500,
						}
					);
				}
			});
	};

	const handleEditPromotion = (): void => {
		setIsSubmiting(true);

		const {
			title,
			description,
			tag_id,
			promotion_initial_date,
			promotion_expires_date,
		} = values;

		const promotion_image =
			values.promotion_image === promotion?.image.url
				? ''
				: values.promotion_image;

		const initialPriceRaw = values.initial_price;
		const promotionalPriceRaw = values.promotional_price;

		const initial_price = unMaskReais(initialPriceRaw);
		const promotional_price = unMaskReais(promotionalPriceRaw);
		{
			getPromotion &&
				id &&
				editPromotion({
					promotion: {
						title,
						description,
						initial_price,
						promotional_price,
						tag_id,
						promotion_initial_date,
						promotion_expires_date,
						promotion_image,
					},
					id,
				})
					.then(() => {
						setIsSubmiting(false);
						getPromotion();

						handleClosePromotionConfirmModal();
						handleOpenPromotionModal();
						enqueueSnackbar('Promoção editada com sucesso.', {
							variant: 'success',
							autoHideDuration: 1500,
						});
					})
					.catch((error: AxiosError) => {
						if (error) {
							setIsSubmiting(false);
							enqueueSnackbar('Ocorreu um erro tente novamente.', {
								variant: 'error',
								autoHideDuration: 1500,
							});
						}
					});
		}
	};

	return (
		<StyledDialog
			open={openPromotionConfirmModal}
			onClose={handleClosePromotionConfirmModal}
			fullWidth
			maxWidth="lg"
		>
			<StyledIconButton onClick={handleClosePromotionConfirmModal}>
				<CloseIcon />
			</StyledIconButton>
			<StyledModalTitleContainer>
				<ConfirmIcon />
				<StyledTitleContainer>
					<StyledModalTitle>{ButtonText}</StyledModalTitle>
					<StyledModalSubtitle>
						Revise os detalhes da sua promoção antes de postar
					</StyledModalSubtitle>
				</StyledTitleContainer>
			</StyledModalTitleContainer>
			<StyledDialogContent>
				<SyledPromotionImageContainer>
					<SyledPromotionImage>
						<img src={promotion_image} />
					</SyledPromotionImage>
					<StyledImgInfo>
						<StyledLightIcon />
						<StyledImgInfoText>
							Dica: Aproveite para conferir a qualidade da sua imagem, como a
							iluminação e nitidez.
						</StyledImgInfoText>
					</StyledImgInfo>
				</SyledPromotionImageContainer>
				<StyledPromotionInfo>
					<StyledPromotionTitleContainer>
						<StyledPromotionTitle>{title}</StyledPromotionTitle>
						<StyledPromotionTag>
							<StyledPurpleDot />
							<StyledPromotionTagIcon>
								{getCategoriesIcons(tag_name, 24, 18)}
							</StyledPromotionTagIcon>
							{tag_name}
						</StyledPromotionTag>
					</StyledPromotionTitleContainer>
					<StyledPromotionDescription>{description}</StyledPromotionDescription>
					<StyledPriceAndCoupounsContainer>
						<StyledPromotionPriceContainer>
							<StyledPromotionText>De</StyledPromotionText>
							<StyledPromotionPrice>
								<StyledOldPriceIcon />
								{moneyMask(initial_price)}
							</StyledPromotionPrice>
						</StyledPromotionPriceContainer>
						<StyledPromotionPriceContainer>
							<StyledPromotionText>Por</StyledPromotionText>
							<StyledPromotionPrice>
								<StyledSalePriceIcon />
								{moneyMask(promotional_price)}
							</StyledPromotionPrice>
						</StyledPromotionPriceContainer>
						{has_coupons && (
							<>
								<StyledPromotionCouponContainer>
									<StyledPromotionText>Cupons</StyledPromotionText>
									<StyledPromotionPrice>
										<StyledCouponIcon />
										{`${qtd_coupons} cupons  `}
									</StyledPromotionPrice>
								</StyledPromotionCouponContainer>
								<StyledPromotionCouponContainer>
									<StyledPromotionText>Duração</StyledPromotionText>
									<StyledPromotionPrice>
										{`${coupon_duration} horas `}
									</StyledPromotionPrice>
								</StyledPromotionCouponContainer>
							</>
						)}
					</StyledPriceAndCoupounsContainer>

					<StyledPromotionDateContainer>
						<StyledDateContainer>
							<StyledPromotionText>Ínicio da promoção</StyledPromotionText>
							<StyledPromotionDate>
								<StyledCalendarIcon />
								{promotion_initial_date}
							</StyledPromotionDate>
						</StyledDateContainer>
						<StyledDateContainer>
							<StyledPromotionText></StyledPromotionText>
							<StyledPromotionHour>
								{hourFormat(promotion_initial_hour)}
							</StyledPromotionHour>
						</StyledDateContainer>
						<StyledDateContainer>
							<StyledPromotionText>Fim da promoção</StyledPromotionText>
							<StyledPromotionDate>
								<StyledCalendarIcon />
								{dateFormat(promotion_expires_date)}
							</StyledPromotionDate>
						</StyledDateContainer>
						<StyledDateContainer>
							<StyledPromotionText></StyledPromotionText>
							<StyledPromotionHour>
								{promotion_expires_hour}
							</StyledPromotionHour>
						</StyledDateContainer>
					</StyledPromotionDateContainer>
					<StyledButtonContainer>
						<StyledButton onClick={handlePromotion} disabled={isSubmiting}>
							<StyledTypography>
								{isSubmiting ? (
									<CircularProgress color="secondary" size={SIZE_BUTTON} />
								) : (
									ButtonText
								)}
							</StyledTypography>
						</StyledButton>
					</StyledButtonContainer>
				</StyledPromotionInfo>
			</StyledDialogContent>
		</StyledDialog>
	);
};

export default PromotionConfirmModal;
