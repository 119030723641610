import { Button, styled, Typography } from '@mui/material';

interface BoxStyledProp {
	isPurple?: boolean;
}

export const StyledButton = styled(Button, {
	shouldForwardProp: (prop) => prop !== 'isPurple',
})<BoxStyledProp>(({ theme, isPurple }) => ({
	height: 50,
	width: '100%',
	minWidth: '189.062px',
	borderRadius: 8,
	backgroundColor: isPurple
		? theme.palette.primary.main
		: theme.palette.neutral.light,
	'&:hover': {
		backgroundColor: isPurple
			? theme.palette.primary.main
			: theme.palette.neutral.light,
	},
	'@media only screen and (max-width: 1400px)': {
		height: 40,
		width: '100%',
		minWidth: '189.062px',
	},
}));

export const StyledTypography = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'isPurple',
})<BoxStyledProp>(({ theme, isPurple }) => ({
	textTransform: 'none',
	fontSize: 18,
	color: isPurple ? theme.palette.neutral.light : theme.palette.primary.main,
	fontFamily: 'Rubik',
	fontWeight: 700,
	lineHeight: '20px',
	'@media only screen and (max-width: 1400px)': {
		fontSize: 14,
	},
}));
