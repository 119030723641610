import {
	Box,
	Button,
	CircularProgress,
	DialogProps,
	FormControl,
} from '@mui/material';
import { Formik, FormikValues } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as CarIcon } from '../../../assets/images/carIcon.svg';
import { IAuth } from '../../../auth/interface/auth.interface';
import { IReducers } from '../../../auth/store/store';
import { getCityDistricts } from '../../../utils/api/get/getCityDistricts';
import { getDeliveryPrices } from '../../../utils/api/get/getDeliveryPrices';
import { createDeliveryPrices } from '../../../utils/api/post/createDeliveryPrices';
import { moneyMask, unMaskReais } from '../../../utils/mask.util';
import DistrictList, {
	DataProps,
	DataPropsSend,
} from './DistrictList/DistrictList';
import VALIDATION_DELIVERY_SCHEMA from './constants/delivery-validationSchema';
import {
	DeliveryModalProps,
	IDistricts,
} from './interface/modalDisablePromotion.interface';
import {
	StyledButtonsContainer,
	StyledCancelButton,
	StyledDeliveryValue,
	StyledDialog,
	StyledDisableButton,
	StyledDistrictContainer,
	StyledIconBox,
	StyledModalSubtitle,
	StyledModalText,
	StyledModalTextCenter,
	StyledModalTextHead,
	StyledModalTextHeadInitial,
	StyledModalTitle,
	StyledModalTitleContainer,
	StyledTimeTextField,
	StyledTimeTextFieldContainer,
	StyledTitleContainer,
	StyledTitleTextContainer,
	StyledValueTextField,
} from './styles';

const DeliveryModal: React.FC<DeliveryModalProps> = ({
	handleCloseDeliveryModal,
	openDeliveryModal,
}: DeliveryModalProps): JSX.Element => {
	const [value, setValue] = useState<any>(0);
	const [time, setTime] = useState<string>('');
	const [districts, setDistricts] = useState<IDistricts[]>();
	const [newInitialValues, setNewInitialValues] = useState<DataProps[]>();
	const [loader, setLoader] = useState(true);

	const { advertiser_id }: IAuth = useSelector(
		(state: IReducers) => state.auth
	);

	const getDistricts = () => {
		getCityDistricts()
			.then((data) => {
				setDistricts(data);
			})
			.catch((error) => {
				console.error('Error fetching districts:', error);
			});
	};
	const handleClose: DialogProps['onClose'] = (event, reason) => {
		if (reason && reason === 'backdropClick') return;
		handleCloseDeliveryModal();
	};

	const modifyDistrict = districts?.map((item) => ({
		districtId: item.id,
		name: item.name,
		price: 0,
		deliveryTime: '',
		deliveryPermission: true,
	}));

	const updateArray = () => {
		if (newInitialValues) {
			const uptdateArray = newInitialValues?.map((item) => ({
				...item,
				price: value,
				deliveryTime: time,
				deliveryPermission: true,
			}));
			setNewInitialValues(uptdateArray);
		}
	};

	const handleDeliveryManager = (
		values: FormikValues,
		setSubmit: (isSubmitting: boolean) => void
	): void => {
		const data = values.data.map(
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			({ name, id, advertiserId, ...item }: DataPropsSend) => ({
				...item,
				price: unMaskReais(item.price),
			})
		);

		createDeliveryPrices(data)
			.then(() => {
				handleCloseDeliveryModal();
			})
			.catch((err) => {
				console.error(err);
				setSubmit(false);
			});
	};

	const getDeliveryByAdvertiser = () => {
		getDeliveryPrices(advertiser_id)
			.then((data) => {
				data?.data.length ? setNewInitialValues(data?.data) : '';
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setLoader(false);
			});
	};

	useEffect(() => {
		getDistricts();
		getDeliveryByAdvertiser();
	}, []);

	useEffect(() => {
		if (!newInitialValues) {
			setNewInitialValues(modifyDistrict);
		}
	}, [districts]);

	return (
		<StyledDialog
			open={openDeliveryModal}
			onClose={handleClose}
			fullWidth
			maxWidth="sm"
			style={{ right: 0, margin: 0 }}
		>
			<StyledModalTitleContainer>
				<StyledIconBox>
					<CarIcon color="#6029B1" />
				</StyledIconBox>
				<StyledTitleTextContainer>
					<StyledModalTitle>Gerenciamento de entrega</StyledModalTitle>
					<StyledModalSubtitle>
						Defina valor, tempo e disponibilidade de entrega para cada bairro.
					</StyledModalSubtitle>
				</StyledTitleTextContainer>
			</StyledModalTitleContainer>
			<Box display={'flex'} width={'100%'}>
				<StyledTitleContainer>
					<StyledModalText>Definir padrão</StyledModalText>
					<StyledModalSubtitle>
						Estabeleça um valor e tempo <br />
						padrão para todos os bairros.
					</StyledModalSubtitle>
				</StyledTitleContainer>
				<StyledDeliveryValue>
					<StyledModalTextCenter>Valor</StyledModalTextCenter>
					<FormControl>
						<StyledValueTextField
							id="outlined-basic"
							value={moneyMask(value as number)}
							placeholder="R$ 0,00"
							variant="outlined"
							onChange={(event) => setValue(event.target.value)}
						/>
					</FormControl>
				</StyledDeliveryValue>
				<StyledTimeTextFieldContainer>
					<StyledModalTextCenter>Tempo</StyledModalTextCenter>
					<FormControl>
						<StyledTimeTextField
							id="outlined-basic"
							value={time}
							placeholder="0 minutos"
							variant="outlined"
							onChange={(event) => setTime(event.target.value)}
						/>
					</FormControl>
				</StyledTimeTextFieldContainer>
				<Button
					variant="contained"
					color="primary"
					style={{
						textTransform: 'none',
						fontSize: 16,
						borderRadius: 8,
						height: 42,
						width: 117,
						alignSelf: 'self-end',
						marginLeft: 24,
					}}
					onClick={() => updateArray()}
				>
					Definir
				</Button>
			</Box>
			<Box display={'flex'} width={'100%'} marginTop={4} marginBottom={1}>
				<StyledTitleContainer>
					<StyledModalTextHeadInitial>Bairro</StyledModalTextHeadInitial>
				</StyledTitleContainer>
				<StyledDeliveryValue>
					<StyledModalTextHead>Valor</StyledModalTextHead>
				</StyledDeliveryValue>
				<StyledTimeTextFieldContainer>
					<StyledModalTextHead>Tempo</StyledModalTextHead>
				</StyledTimeTextFieldContainer>
				<StyledDistrictContainer>
					<StyledModalTextHead>Atender Bairro</StyledModalTextHead>
				</StyledDistrictContainer>
			</Box>

			{loader ? (
				<CircularProgress color="primary" size={25} style={{ marginTop: 10 }} />
			) : (
				<Formik
					initialValues={{
						data: newInitialValues,
					}}
					validationSchema={VALIDATION_DELIVERY_SCHEMA}
					validateOnChange={false}
					enableReinitialize
					onSubmit={(values, { setSubmitting }) => {
						handleDeliveryManager(values, setSubmitting);
					}}
				>
					{({ handleSubmit }) => (
						<form
							id="formDelivery"
							onSubmit={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
							style={{ height: 350, width: '100%', overflowY: 'auto' }}
						>
							<DistrictList />
						</form>
					)}
				</Formik>
			)}

			<StyledButtonsContainer>
				<StyledCancelButton onClick={() => handleCloseDeliveryModal()}>
					Cancelar
				</StyledCancelButton>
				<StyledDisableButton form="formDelivery" type="submit">
					Salvar alterações
				</StyledDisableButton>
			</StyledButtonsContainer>
		</StyledDialog>
	);
};

export default DeliveryModal;
