import * as yup from 'yup';

const VALIDATION_OPENING_HOURS_SCHEMA = yup.object({
	openingHours: yup.array().of(
		yup.object().shape({
			opening_hour: yup.string().required('Campo obrigatório'),
			close_hour: yup.string().required('Campo obrigatório'),
		})
	),
	deliveryOptions: yup.object({
		pickUpTimeStart: yup
			.number()
			.moreThan(0, 'maior que 0')
			.typeError('Campo obrigatório')
			.required('Campo obrigatório'),
		pickUpTimeEnd: yup
			.number()
			.moreThan(0, 'maior que 0')
			.typeError('Campo obrigatório')
			.required('Campo obrigatório'),
	}),
});

export default VALIDATION_OPENING_HOURS_SCHEMA;
