import { Box, Button, Divider, styled, Typography } from "@mui/material";

export const Container = styled(Box)(() => ({
  width: "40%",
  height: "100%",
  paddingLeft: "80px",
}));

export const AlignCloseButton = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  height: "50px",
  alignItems: "center",
  justifyContent: "flex-end",
}));

export const CloseButton = styled(Button)(() => ({
  marginTop: "16px",
}));

export const ChosenPlanContent = styled(Box)(({ theme }) => ({
  width: "483px",
  height: "300px",
  marginTop: "45px",
  borderRadius: "12px",
  border: `1px solid ${theme.palette.neutral.light2}`,
}));

export const TitleChosenPlan = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 500,
  marginTop: "18px",
  marginLeft: "18px",
  color: theme.palette.common.black,
}));

export const AlignTypeAndeValue = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginTop: "18px",
  marginLeft: "18px",
}));

export const NameAndValidity = styled(Box)(() => ({
  marginLeft: "16px",
}));

export const TypePlan = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.common.black,
}));

export const ValidityPlan = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontFamily: "Rubik",
  fontWeight: 400,
  color: theme.palette.neutral.dark1,
}));

export const AlignValue = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  width: "50%",
}));

export const PlanValue = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.primary.main,
}));

export const TextValue = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.neutral.dark1,
}));

export const DividerStyled = styled(Divider)(() => ({
  marginTop: "18px",
}));

export const InfoContent = styled(Box)(() => ({
  marginLeft: "24px",
}));

export const InfoTitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  marginTop: "15px",
  fontWeight: 500,
  color: theme.palette.common.black,
}));

export const InfoContentText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontFamily: "Rubik",
  marginTop: "5px",
  fontWeight: 400,
  color: theme.palette.neutral.dark1,
}));

export const ButtonChangePlan = styled(Button)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: "122px",
  marginTop: "10px",
  width: "191px",
  height: "44px",
  borderRadius: "8px",
  backgroundColor: theme.palette.primary.main,
}));

export const ChangePlanText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  textTransform: "initial",
  fontWeight: 700,
  color: theme.palette.common.white,
}));

export const SubscriptionInformation = styled(Box)(({ theme }) => ({
  width: "483px",
  height: "150px",
  borderRadius: "12px",
  marginTop: "24px",
  border: `1px solid ${theme.palette.neutral.light2}`,
}));

export const SubscriptionContent = styled(Box)(() => ({
  marginLeft: "24px",
}));

export const SubscriptionHeader = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  width: "455px",
  marginTop: "20px",
}));

export const SubscriptionText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.common.black,
}));

export const ValuesAndCharge = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "23px",
}));

export const OldValue = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.neutral.dark1,
  textDecoration: "line-through",
}));

export const NewValue = styled(Typography)(({ theme }) => ({
  fontSize: "32px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.common.black,
}));

export const SubscriptionCharge = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "155px",
  height: "32px",
  marginTop: "25px",
  marginRight: "24px",
  borderRadius: "32px",
  backgroundColor: theme.palette.neutral.light3,
}));

export const ChargeText = styled(Box)(({ theme }) => ({
  fontSize: "12px",
  fontFamily: "Rubik",
  fontWeight: 400,
  color: theme.palette.primary.dark,
}));

export const ForwardButton = styled(Button)(({ theme }) => ({
  width: "483px",
  height: "56px",
  marginTop: "34px",
  borderRadius: "8px",
  backgroundColor: theme.palette.primary.main,
}));

export const ForwardText = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontFamily: "Rubik",
  fontWeight: 700,
  textTransform: "initial",
  color: theme.palette.neutral.light,
}));
