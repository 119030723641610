import {
	Box,
	Button,
	Table,
	TextField,
	Typography,
	styled,
} from '@mui/material';

export const StyledMenu = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	height: '100vh',
	overflow: 'hidden',
	backgroundColor: theme.palette.neutral.light2,
}));

export const StyledMenuTopBar = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
}));

export const StyledMenuContent = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
	alignItems: 'center',
	justifyContent: 'center',
	padding: '0px 50px',
}));

export const StyledMenuListContainer = styled(Box)(({ theme }) => ({
	display: 'block',
	boxSizing: 'border-box',
	width: '100%',
	backgroundColor: theme.palette.neutral.light,
	borderRadius: 20,
	height: 700,
	overflow: 'hidden',
	padding: '16px 0px 16px 0px',
}));

export const HeaderMenu = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	height: '20%',
	justifyContent: 'space-evenly',
	padding: '0px 44px',
}));

export const TitleAndButtonsWrapper = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
}));

export const StyledMenuTitleContainer = styled(Box)(() => ({
	display: 'block',
}));

export const StyledMenuTitleTypography = styled(Typography)(({ theme }) => ({
	fontSize: 20,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.neutral.dark,
	textAlign: 'left',
	'@media (max-width: 1200px)': {
		fontSize: 16,
	},
}));

export const StyledMenuSubTitleTypography = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.neutral.dark1,
	textAlign: 'left',
	'@media (max-width: 1200px)': {
		fontSize: 12,
	},
}));

export const StyledSearchMenu = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
}));

export const StyledSearchTextField = styled(TextField)(() => ({
	width: '96%',
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 48,
		fontFamily: 'Rubik',
		fontWeight: 500,
		fontSize: 18,

		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},

	'@media (max-width: 1200px)': {
		'& .MuiOutlinedInput-root ': {
			fontSize: 14,
		},
	},
}));

export const StyledCategoriesTextField = styled(TextField)(() => ({
	'& .MuiOutlinedInput-root ': {
		borderRadius: 8,
		height: 48,
		width: 300,
		'@media only screen and (max-width: 1500px)': {
			fontSize: 14,
			height: 48,
			width: 180,
		},
		[`&.Mui-focused fieldset`]: {
			borderColor: '#6029B1',
		},
	},
	'& .MuiFormHelperText-root': {
		fontSize: '12px!important',
	},
	'@media only screen and (max-width: 1500px)': {
		'& .MuiFormLabel-root': {
			fontSize: '13px',
		},
	},
}));

export const StyledTitleMenu = styled(Box)(({ theme }) => ({
	display: 'flex',
	fontSize: 24,
	fontFamily: 'Rubik',
	fontWeight: 500,
	color: theme.palette.neutral.dark,
}));

export const InnerListContainer = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	height: '80%',
	overflow: 'auto',
}));

export const WrapperContent = styled(Box)(() => ({
	padding: '0px',

	'css-1wev1ip-MuiTable-root': {
		marginBottom: '0px!important',
	},
	'.css-11xur9t-MuiPaper-root-MuiTableContainer-root': {
		boxShadow: 'none !important',
	},
	'.css-xso64x-MuiTableCell-root': {
		padding: '16px 22px 16px 22px',
		borderBottom: 'none',
	},
	'.css-1qwrgou-MuiTableCell-root': {
		paddingLeft: '70px',
		paddingTop: '40px',
	},
	'.css-okw2ps-MuiTableCell-root': {
		paddingTop: '40px',
	},
	'.css-1dahgcr-MuiTableCell-root': {
		padding: '25px 0px',
	},
	'.css-1ryqp3e-MuiTableCell-root': {
		padding: '0px',
	},
}));

export const StyledMenuTable = styled(Table)(() => ({}));

export const StyledLoadingContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	width: '100%',
	flex: 1,
	marginTop: theme.spacing(10),
	paddingBottom: theme.spacing(10),
}));

export const StyledButtonContainer = styled(Box)(() => ({
	display: 'flex',
	gap: '20px',
}));

export const AddCategoryButton = styled(Button)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: 220,
	height: 52,
	border: `1px solid ${theme.palette.neutral.dark1}`,
	borderRadius: 8,
	backgroundColor: theme.palette.primary.main,
	textTransform: 'initial',
}));
export const ReorderButton = styled(Button)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: 220,
	height: 52,
	border: `1px solid ${theme.palette.neutral.dark1}`,
	borderRadius: 8,
	backgroundColor: theme.palette.common.white,
	textTransform: 'initial',
}));
