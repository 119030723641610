import { AxiosResponse } from 'axios';
import { BASE_URL } from '../api';
import { request } from '../base-request/base-request';
import { CreateCategoryItem } from '../interfaces';

export const createOrEditCategoryItem = async ({
	values,
	isEditting,
	itemId,
}: CreateCategoryItem): Promise<AxiosResponse<any> | undefined> => {
	try {
		return await request({
			url: !isEditting ? `${BASE_URL}/itens` : `${BASE_URL}/itens/${itemId}`,
			method: !isEditting ? 'post' : 'patch',
			data: { ...values },
		});
	} catch (error) {
		return Promise.reject(error);
	}
};
