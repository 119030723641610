import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LogoutIcon } from '../../../../assets/images/logoutIcon.svg';
import { IAuth } from '../../../../auth/interface/auth.interface';
import { ISideNav } from '../../../../auth/interface/sideNav.interface';
import { IReducers } from '../../../../auth/store/store';
import {
	BoxStyled,
	ListItemStyledOpen,
	ListItemTextOpen,
	StyledAdvertiserAvatar,
	TypographyText,
	TypographyTitle,
} from './styles';

export interface ILogoutButtonOpen {
	isHovered: boolean;
	openModalConfirmLogout: () => void;
	setIndexOfSideNavButton: (sideNav: ISideNav) => void;
}

const LogoutButtonOpen: React.FC<ILogoutButtonOpen> = ({
	isHovered,
	openModalConfirmLogout,
	setIndexOfSideNavButton,
}: ILogoutButtonOpen): JSX.Element => {
	const { advertiserImg, advertiserName }: IAuth = useSelector(
		(state: IReducers) => state.auth
	);
	const navigate = useNavigate();

	const navigateToProfile = () => {
		navigate('/profile');
		setIndexOfSideNavButton({
			showSide: true,
			buttonIndex: 4,
		});
	};

	return (
		<>
			<ListItemStyledOpen isHovered={isHovered}>
				<StyledAdvertiserAvatar>
					<label
						style={{
							position: 'absolute',
							justifyContent: 'center',
							display: 'flex',
							width: '100%',
							height: '100%',
						}}
					>
						<img src={advertiserImg} style={{ cursor: 'pointer' }} />
					</label>
				</StyledAdvertiserAvatar>
				{isHovered && (
					<>
						<ListItemTextOpen>
							<TypographyTitle>{advertiserName}</TypographyTitle>
							<TypographyText onClick={() => navigateToProfile()}>
								Ver perfil
							</TypographyText>
						</ListItemTextOpen>

						<BoxStyled onClick={openModalConfirmLogout}>
							<LogoutIcon />
						</BoxStyled>
					</>
				)}
			</ListItemStyledOpen>
		</>
	);
};

export default LogoutButtonOpen;
