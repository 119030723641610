import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Tooltip } from '@mui/material';
import { ICardInfo } from '../../../pages/StoreFinance/interface';
import {
	StyledBillingChartLineContainer,
	StyledBillingNumber,
	StyledBillingStatusDisplay,
	StyledBillingText,
	StyledIconBox,
} from './styles';

interface IBillingCard {
	card: ICardInfo;
}

const MonthlyBillingCard: React.FC<IBillingCard> = ({
	card,
}: IBillingCard): JSX.Element => {
	return (
		<StyledBillingStatusDisplay>
			<StyledBillingChartLineContainer>
				<Box display={'flex'} alignItems={'center'}>
					<StyledIconBox>{card.icon}</StyledIconBox>
					<StyledBillingText>{card.title}</StyledBillingText>
				</Box>
				<Tooltip title={'texto provisorio'} arrow placement="top-end">
					<HelpOutlineIcon
						color="disabled"
						fontSize="inherit"
						style={{ height: 27, width: 27 }}
					/>
				</Tooltip>
			</StyledBillingChartLineContainer>

			{card.value >= 0 && (
				<StyledBillingNumber>{`R$ ${card.value ?? 0}`}</StyledBillingNumber>
			)}
		</StyledBillingStatusDisplay>
	);
};

export default MonthlyBillingCard;
