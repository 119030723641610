import { Box, styled, Button, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Form } from 'formik';
import {
	IButtonsProps,
	IFormButtonProps,
	StyledTabProps,
} from '../Interfaces/interfaces';

export const ContainerForLoading = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
	height: '100%',
	alignItems: 'center',
	justifyContent: 'center',
}));

export const StyledProfile = styled(Box)(({ theme }) => ({
	width: '100%',
	height: '100vh',
	backgroundColor: theme.palette.neutral.light2,
}));

export const StyledProfileTopBar = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
}));

export const StyledProfileContainer = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.neutral.light2,
	width: '100%',
}));

export const StyledProfileContent = styled(Box)(() => ({
	display: 'flex',
	padding: '0px 50px 50px 50px',
}));

export const StyledCreateProfileContainer = styled(Box)(() => ({
	width: '100%',
}));

export const StyledForm = styled(Form)<IFormButtonProps>(({ theme }) => ({
	borderRadius: '20px',
	padding: '30px 70px 50px 30px',
	backgroundColor: theme.palette.common.white,
	overflow: 'auto',
}));

export const StepInformation = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 400,
	marginLeft: '15px',
	color: theme.palette.neutral.dark1,
}));

export const GoBackInformation = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	height: '10%',
}));

export const GoBackButton = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: 25,
	height: 25,
	borderRadius: '50%',
	backgroundColor: theme.palette.primary.main,
	cursor: 'pointer',
}));

export const GoBackArrow = styled(ArrowBackIosNewIcon)(({ theme }) => ({
	color: theme.palette.common.white,
	fontSize: 15,
}));

export const StyledTabsContainer = styled(Box)(() => ({
	display: 'flex',
	height: 52,
	'@media only screen and (max-width: 1400px)': {
		height: 40,
	},
}));

export const ProfileTabs = styled(Tabs)({
	display: 'flex',
});

export const ProfileTab = styled((props: StyledTabProps) => (
	<Tab disableRipple {...props} />
))(({ theme }) => ({
	textTransform: 'none',
	fontSize: 20,
	display: 'none',
	fontWeight: 500,
	marginRight: 32,
	color: theme.palette.neutral.dark1,
	borderRadius: '20px 20px 0 0',
	padding: '16px 32px 16px 32px',
	fontFamily: 'Rubik',
	'&.Mui-selected': {
		backgroundColor: theme.palette.neutral.light,
		color: theme.palette.primary.main,
	},
	'@media only screen and (max-width: 1400px)': {
		fontSize: 16,
		padding: '10px 18px 10px 18px',
		marginRight: 22,
	},
}));

export const AddProfileTab = styled((props: StyledTabProps) => (
	<Tab disableRipple {...props} />
))(() => ({
	display: 'none',
}));

export const ActionButtons = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
	justifyContent: 'flex-end',
	padding: '50px 0px 0px 0px',
}));

export const CancelButton = styled(Button)(({ theme }) => ({
	width: 272,
	height: 56,
	marginRight: '1%',
	border: `1px solid ${theme.palette.primary.main}`,
	borderRadius: '8px',
	textTransform: 'initial',
}));
export const ActionsButtonsText = styled(Typography)<IButtonsProps>(
	({ theme, namebutton }) => ({
		fontSize: 14,
		fontFamily: 'Rubik',
		fontWeight: 450,
		color:
			namebutton === 'Cancelar'
				? theme.palette.primary.main
				: theme.palette.common.white,
	})
);
export const ButtonContinue = styled(Button)(({ theme }) => ({
	width: 272,
	height: 56,
	borderRadius: '8px',
	backgroundColor: theme.palette.primary.main,
	textTransform: 'initial',
}));
