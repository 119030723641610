import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
	Button,
	ClickAwayListener,
	Grow,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Popper,
} from '@mui/material';
import React from 'react';
import { MyPopperMenuProps } from './interfaces';

const modifiers = [
	{
		name: 'flip',
		enabled: false,
	},
];

const MenuPopperButton: React.FC<MyPopperMenuProps> = ({
	open,
	anchorRef,
	menuItems,
	handleItemClick,
	handleClose,
	handleListKeyDown,
	handleToggle,
}) => {
	return (
		<>
			<Button
				size="small"
				ref={anchorRef}
				aria-haspopup="true"
				onClick={handleToggle}
				aria-controls={open ? 'menu-list-grow' : undefined}
			>
				<MoreVertIcon />
			</Button>

			<Popper
				transition
				open={open}
				disablePortal
				modifiers={modifiers}
				style={{ zIndex: 3 }}
				placement="bottom-start"
				anchorEl={anchorRef.current}
			>
				{({ TransitionProps }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: 'center top',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList
									autoFocusItem={open}
									id="menu-list-grow"
									onKeyDown={handleListKeyDown}
								>
									{menuItems.map((item) => (
										<MenuItem
											key={item.action}
											onClick={() => handleItemClick(item.action)}
										>
											<ListItemText
												primary={item.label}
												style={{
													textAlign: 'start',
												}}
											/>
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};

export default MenuPopperButton;
