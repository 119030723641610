import { Skeleton } from "@mui/material";
import moment from "moment";
import React from "react";
import EmptyCard from "../../../Dashboard/EmptyCard/EmptyCard";
import {
  CouponsPromotionModalUsedCardProps,
  UsedCouponsListProps,
} from "./interface/couponsList.interface";
import {
  StyledListContainer,
  StyledUsedCouponCode,
  StyledUsedCouponDate,
  StyledUsedCouponsContainer,
  StyledUsedCouponsContainerList,
  StyledUsedCouponsContainerTitle,
} from "./styles";

const UsedCouponsListSkeleton: React.FC = (): JSX.Element => (
  <StyledListContainer>
    <StyledUsedCouponCode>
      <Skeleton
        sx={{ height: "20px", width: "150px", borderRadius: 5 }}
        animation="wave"
        variant="rectangular"
      />
    </StyledUsedCouponCode>
    <StyledUsedCouponDate>
      <Skeleton
        sx={{ height: "20px", width: "150px", borderRadius: 5 }}
        animation="wave"
        variant="rectangular"
      />
    </StyledUsedCouponDate>
  </StyledListContainer>
);

const UsedCouponsList: React.FC<UsedCouponsListProps> = ({
  lastUsedCoupons: { coupon_name, updated_at },
}: UsedCouponsListProps): JSX.Element => (
  <StyledListContainer>
    <StyledUsedCouponCode>Cupom: {coupon_name}</StyledUsedCouponCode>
    <StyledUsedCouponDate>
      Utilizado em {moment(updated_at).format("DD/MM/YYYY")}
    </StyledUsedCouponDate>
  </StyledListContainer>
);

const CouponsPromotionModalUsedCard: React.FC<
  CouponsPromotionModalUsedCardProps
> = ({
  lastUsedCoupons,
  loaderUsedCard,
}: CouponsPromotionModalUsedCardProps): JSX.Element => (
  <StyledUsedCouponsContainer>
    <StyledUsedCouponsContainerTitle>
      Utilizados recentemente
    </StyledUsedCouponsContainerTitle>
    <StyledUsedCouponsContainerList>
      {loaderUsedCard ? (
        Array(4)
          .fill(null)
          .map((_, index) => <UsedCouponsListSkeleton key={index} />)
      ) : (
        <>
          {lastUsedCoupons &&
            lastUsedCoupons.length > 0 &&
            lastUsedCoupons?.map((lastUsedCoupons, index) => (
              <UsedCouponsList key={index} lastUsedCoupons={lastUsedCoupons} />
            ))}
          {lastUsedCoupons && lastUsedCoupons.length == 0 && (
            <EmptyCard
              text="O histórico de cupons utilizados
aparecerá aqui."
              image="promotion"
            />
          )}
        </>
      )}
    </StyledUsedCouponsContainerList>
  </StyledUsedCouponsContainer>
);

export default CouponsPromotionModalUsedCard;
