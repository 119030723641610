import { useEffect, useState } from 'react';
import { IButtons } from './interfaces/button.interface';
import {
	BoxSoonStyled,
	BoxStyled,
	ListItemStyledOpen,
	ListItemTextOpen,
	StyledOrangeDot,
	TypographyText,
} from './styles';

const ButtonsOpen: React.FC<IButtons> = ({
	item,
	index,
	indexButton,
	lastComment,
	isHovered,
}: IButtons): JSX.Element => {
	const [isSelected, setIsSelected] = useState<boolean>(false);

	useEffect(() => {
		if (index === indexButton && isHovered === false) {
			setIsSelected(true);
		}
		if (isHovered === true) {
			setIsSelected(false);
		}
		if (index !== indexButton) {
			setIsSelected(false);
		}
	}, [isHovered, indexButton]);

	return (
		<>
			<ListItemStyledOpen
				isSelected={isSelected}
				isPlan={false}
				isListHovered={isHovered}
			>
				<BoxStyled>
					{item.icon}
					{isHovered
						? item.label === 'Comentários' &&
						  !lastComment && <StyledOrangeDot />
						: null}
				</BoxStyled>
				{isHovered ? (
					<ListItemTextOpen>
						<TypographyText>{item.label} </TypographyText>
					</ListItemTextOpen>
				) : null}
			</ListItemStyledOpen>

			{isHovered
				? item.label === 'Planos' && <BoxSoonStyled>Em breve!</BoxSoonStyled>
				: null}
		</>
	);
};

export default ButtonsOpen;
