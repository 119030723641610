import { Divider, List } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as SideNavLogoCollapsed } from '../../assets/images/sideNavLogo$.svg';
import { ReactComponent as SideNavLogo } from '../../assets/images/sideNavLogo.svg';
import { addAuth, removeAuth } from '../../auth/actions/auth.action';
import { addNav } from '../../auth/actions/sideNav.action';
import { AuthAction, IAuth } from '../../auth/interface/auth.interface';
import { ISideNav } from '../../auth/interface/sideNav.interface';
import { IReducers } from '../../auth/store/store';
import { CommentsParams } from '../../pages/Dashboard/interface/lastComments.interface';
import { getAdvertiser } from '../../utils/api/get/getAdvertiser';
import { getLastComments } from '../../utils/api/get/getLastComments';
import DashboardModal from '../Dashboard/DashboardModal/DashboardModal';
import ButtonsOpen from './ButtonsMenu/ButtonsOpen';
import LogoutButtonOpen from './ButtonsMenu/LogoutButton/LogoutButtonOpen';
import { IItem } from './ButtonsMenu/interfaces/item.interface';
import ConfirmLogoutPage from './ModalConfirmLogout/ModalConfirmLogout';
import { BUTTON_ICONS } from './constants/buttons-value.constants';
import { PATHNAME_BUTTONS } from './enum/pathname.enum';
import {
	Drawer,
	DrawerHeader,
	StyledButtonContainer,
	StyledContainerOpenImage,
	StyledLougoutButton,
} from './styles';

const SideNav: React.FC = (): JSX.Element => {
	const { buttonIndex }: ISideNav = useSelector(
		(state: IReducers) => state.sideNav
	);
	const [openDashboardModal, setOpenDashboardModal] = useState(false);
	const [showModalConfirmLogout, setShowModalConfirmLogout] = useState(false);
	const [lastComment, setLastComment] = useState(true);
	const [isHovered, setIsHovered] = useState(false);

	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	const { advertiser_id, advertiserComplete }: IAuth = useSelector(
		(state: IReducers) => state.auth
	);

	const pathname = PATHNAME_BUTTONS;

	const location = useLocation();

	const auth: IAuth = useSelector((state: IReducers) => state.auth);

	const setAuth = (auth: IAuth): AuthAction => dispatch(addAuth(auth));

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleCloseDashboardModal = (): void => setOpenDashboardModal(false);

	const handleOpenDashboardModal = (): void => setOpenDashboardModal(true);

	const getLastCommentsByAdvertiser = async (): Promise<void> => {
		const params: CommentsParams = { page: 1, perPage: 1 };
		getLastComments(advertiser_id, params).then(async (res) => {
			res.data[0] ? setLastComment(res.data[0].was_read) : '';
		});
	};

	const getCurrentAdvertiser = async () => {
		const response = await getAdvertiser(advertiser_id);
		if (response) {
			const { description, address, advertiserOpeningHours } = response.data;
			const validateAdvertiser =
				!description || !address || !advertiserOpeningHours.length;

			setAuth({
				...auth,
				advertiserComplete: !validateAdvertiser,
			});
			return validateAdvertiser;
		}
	};

	const clearAuth = (auth: IAuth): void => {
		dispatch(removeAuth(auth));
	};

	const setIndexOfSideNavButton = (sideNav: ISideNav): void => {
		dispatch(addNav(sideNav));
	};

	const openModalConfirmLogout = (): void => {
		setShowModalConfirmLogout(true);
	};

	const closeModalConfirmLogout = (): void => {
		setShowModalConfirmLogout(false);
	};

	const navigateToDashboard = (): void => {
		navigate('/dashboard');
		setIndexOfSideNavButton({
			showSide: true,
			buttonIndex: 0,
		});
	};

	const handleLougout = (): void => {
		clearAuth({
			accessToken: undefined,
			advertiser_id: '',
		}),
			setIndexOfSideNavButton({
				showSide: true,
				buttonIndex: 0,
			});
		navigate('/login');
	};

	const handleClickButton = async (item: IItem) => {
		switch (item.label) {
			case 'Dashboard':
				setIndexOfSideNavButton({
					showSide: true,
					buttonIndex: 0,
				});
				break;
			case 'Promoções':
				advertiserComplete
					? setIndexOfSideNavButton({
							showSide: true,
							buttonIndex: 1,
					  })
					: undefined;

				break;
			case 'Pedidos':
				setIndexOfSideNavButton({
					showSide: true,
					buttonIndex: 2,
				});
				break;
			case 'Cardápio':
				advertiserComplete
					? setIndexOfSideNavButton({
							showSide: true,
							buttonIndex: 3,
					  })
					: undefined;
				break;
			case 'Meus cupons':
				setIndexOfSideNavButton({
					showSide: true,
					buttonIndex: 4,
				});
				break;
			case 'Meu perfil':
				setIndexOfSideNavButton({
					showSide: true,
					buttonIndex: 5,
				});
				break;
			case 'Planos':
				setIndexOfSideNavButton({
					showSide: true,
					buttonIndex: 6,
				});
				break;
			case 'Financeiro':
				setIndexOfSideNavButton({
					showSide: true,
					buttonIndex: 7,
				});
				setLastComment(true);
				break;
			case 'Comentários':
				setIndexOfSideNavButton({
					showSide: true,
					buttonIndex: 8,
				});
				setLastComment(true);
				break;

			default:
				break;
		}

		if (
			(item?.url === '/promotion' || item?.url === '/menu') &&
			!advertiserComplete
		) {
			const validateAdvertiser = await getCurrentAdvertiser();

			if (validateAdvertiser) {
				handleOpenDashboardModal();
			} else {
				navigate(item?.url);
				setIndexOfSideNavButton({
					showSide: true,
					buttonIndex: 1,
				});
			}
		} else {
			navigate(item?.url ? item.url : '');
		}
	};

	useEffect(() => {
		getLastCommentsByAdvertiser();
	}, []);

	useEffect(() => {
		handleClickButton({
			label: pathname[location.pathname as keyof typeof PATHNAME_BUTTONS],
			url: location.pathname,
		});
	}, []);

	return (
		<>
			<Drawer
				variant="permanent"
				className={isHovered ? 'expanded' : 'collapsed'}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<DrawerHeader>
					{isHovered ? (
						<StyledContainerOpenImage>
							<SideNavLogo onClick={(): void => navigateToDashboard()} />
						</StyledContainerOpenImage>
					) : (
						<StyledContainerOpenImage>
							<SideNavLogoCollapsed
								onClick={(): void => navigateToDashboard()}
							/>
						</StyledContainerOpenImage>
					)}
				</DrawerHeader>
				<List
					sx={{
						display: 'inline-grid',
					}}
				>
					{BUTTON_ICONS.map((item: IItem, index) => (
						<React.Fragment key={item.label}>
							<StyledButtonContainer
								onClick={() => handleClickButton(item)}
								disableTouchRipple
								disableFocusRipple
								disableRipple
							>
								<ButtonsOpen
									index={index}
									indexButton={buttonIndex as number}
									item={item}
									lastComment={lastComment}
									isHovered={isHovered}
								/>
							</StyledButtonContainer>
							{index === 0 && (
								<Divider
									component="li"
									sx={{
										borderColor: '#c9c9c999',
										marginLeft: '8px',
										marginRight: '8px',
									}}
									variant="middle"
								/>
							)}
							{index === 4 && (
								<Divider
									component="li"
									sx={{
										borderColor: '#c9c9c999',
										marginLeft: '8px',
										marginRight: '8px',
									}}
									variant="middle"
								/>
							)}
						</React.Fragment>
					))}
					<StyledLougoutButton isHovered={isHovered}>
						<LogoutButtonOpen
							isHovered={isHovered}
							openModalConfirmLogout={openModalConfirmLogout}
							setIndexOfSideNavButton={setIndexOfSideNavButton}
						/>
					</StyledLougoutButton>
				</List>
			</Drawer>
			{showModalConfirmLogout ? (
				<ConfirmLogoutPage
					close={closeModalConfirmLogout}
					confirm={handleLougout}
				/>
			) : null}
			<DashboardModal
				handleCloseDashboardModal={handleCloseDashboardModal}
				openDashboardModal={openDashboardModal}
			/>
		</>
	);
};

export default SideNav;
