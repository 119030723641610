import { Box, CircularProgress, useTheme } from '@mui/material';
import { Formik, FormikValues } from 'formik';
import { default as moment } from 'moment';
import 'moment/locale/pt-br';
import { useEffect, useState } from 'react';
import { getFilteredOrders } from '../../../utils/api/get/getFilteredOrders';

import React from 'react';
import { ReactComponent as CheckIcon } from '../../../assets/images/CheckIcon.svg';
import { ReactComponent as WarningIcon } from '../../../assets/images/warningIcon.svg';
import { ReactComponent as CancelledIcon } from '../../../assets/images/xRedIcon.svg';
import {
	addLeadingZero,
	dateFormat,
	hourFormat,
	replaceDot,
} from '../../../utils/mask.util';
import FilterDrawer, {
	FilterDrawerFormProps,
} from '../FilterDrawer/FilterDrawer';
import NoOrders from '../NoOrders/NoOrders';
import { IOrderlistProps, OrderData } from '../interfaces/interfaces';
import FILTER_ORDERS_INITIAL_VALUES from './constants/filterOrders-initialValues';
import {
	AlignText,
	BoldStatusText,
	ButtonText,
	Container,
	FilterButton,
	FilterContent,
	FirstAlignText,
	MiddleContent,
	NumberText,
	OrderText,
	QuantityOrders,
	RightArrowIcon,
	Status,
	StatusText,
	StyledLoadingContainer,
	TableHeader,
	Title,
	TitleText,
	TotalSum,
	WrapperHistoryDay,
	WrapperStatusOrder,
} from './styles';

const OrderList: React.FC<IOrderlistProps> = ({
	data,
	loading,
	handleOrderDetailsById,
	handleDetailsDrawer,
}: IOrderlistProps): JSX.Element => {
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
	const [newData, setNewData] = useState<OrderData[] | undefined>(data);

	const theme = useTheme();

	const handleToggleDrawer = () => {
		setIsDrawerOpen(!isDrawerOpen);
	};

	const mapPaymentMethod = (
		methods: { paymentsMethod: string; is_active: boolean }[]
	): string[] => {
		const mappedMethods: string[] = [];
		methods.forEach((method) => {
			if (method.is_active) {
				switch (method.paymentsMethod) {
					case 'Pix':
						mappedMethods.push('pix');
						break;
					case 'Cartão de crédito':
						mappedMethods.push('debit_card', 'credit_card');
						break;
					case 'Dinheiro':
						mappedMethods.push('cash');
						break;
					default:
						break;
				}
			}
		});
		return mappedMethods;
	};

	const mapOrderStatus = (
		statuses: { text: string; is_active: boolean }[]
	): string[] => {
		const mappedStatuses: string[] = [];
		statuses.forEach((status) => {
			if (status.is_active) {
				switch (status.text) {
					case 'Pedido concluído':
						mappedStatuses.push('concluded');
						break;
					case 'Pedido cancelado':
						mappedStatuses.push('canceled');
						break;
					case 'Pedido em andamento':
						mappedStatuses.push('inProgress');
						break;
					default:
						break;
				}
			}
		});
		return mappedStatuses;
	};

	const generateQueryString = (filters: FilterDrawerFormProps): string => {
		const queryStringParams: string[] = [];

		const formattedStartDate = moment(filters.startDate).format('YYYY-MM-DD');
		const formattedEndDate = moment(filters.endDate).format('YYYY-MM-DD');

		queryStringParams.push(`period[start]=${formattedStartDate}`);
		queryStringParams.push(`period[end]=${formattedEndDate}`);

		const activePaymentMethods = mapPaymentMethod(filters.paymentsMethods);
		if (activePaymentMethods.length > 0) {
			activePaymentMethods.forEach((paymentMethod) => {
				queryStringParams.push(
					`paymentMethod=${encodeURIComponent(paymentMethod)}`
				);
			});
		}

		const isOnlineOrderActive = filters.orderType.some(
			(order) => order.type === 'Online' && order.is_active
		);
		const isPresencialOrderActive = filters.orderType.some(
			(order) => order.type === 'Presencial' && order.is_active
		);

		if (isOnlineOrderActive && isPresencialOrderActive) {
			queryStringParams.push('paymentType=true&paymentType=false');
		} else if (isOnlineOrderActive) {
			queryStringParams.push('paymentType=true');
		} else if (isPresencialOrderActive) {
			queryStringParams.push('paymentType=false');
		}

		queryStringParams.push(`valueRange[start]=${filters.range[0]}`);
		queryStringParams.push(`valueRange[end]=${filters.range[1]}`);

		const activeOrderStatuses = mapOrderStatus(filters.orderStatus);
		if (activeOrderStatuses.length > 0) {
			activeOrderStatuses.forEach((status) => {
				queryStringParams.push(`status=${encodeURIComponent(status)}`);
			});
		}

		return queryStringParams.join('&');
	};

	const handleFilter = (
		values: FormikValues,
		setSubmit: (isSubmitting: boolean) => void
	): void => {
		const newFilter = generateQueryString(values as FilterDrawerFormProps);
		getFilteredOrders(newFilter).then((res) => {
			setNewData(res.data);
			setSubmit(false);
			handleToggleDrawer();
		});
	};

	const getStatusColor = (status: string) => {
		if (status === 'Cancelado' || status === 'Cancelamento Solicitado') {
			return '#EB3333';
		}
		if (
			status === 'Novo Pedido' ||
			status === 'Aguardando Pagamento' ||
			status === 'Preparando' ||
			status === 'Pronto' ||
			status === 'Pronto para Retirada' ||
			status === 'Saiu para Entrega' ||
			status === 'Concluído'
		) {
			return theme.palette.success.main;
		}
		if (
			status === 'Reembolso Solicitado' ||
			status === 'Reembolso Pendente' ||
			status === 'Reembolso Cancelado' ||
			status === 'Reembolsado'
		) {
			return theme.palette.tertiary.main;
		}
		return '';
	};

	useEffect(() => {
		setNewData(data);
	}, [data]);

	return (
		<Container>
			<FilterContent>
				<FilterButton onClick={handleToggleDrawer}>
					<ButtonText>Filtrar</ButtonText>
				</FilterButton>
			</FilterContent>
			<TableHeader>
				<FirstAlignText>
					<TitleText>Horário</TitleText>
				</FirstAlignText>
				<AlignText>
					<TitleText>Pedido</TitleText>
				</AlignText>
				<AlignText>
					<TitleText>Status</TitleText>
				</AlignText>
				<AlignText>
					<TitleText>Pagamento</TitleText>
				</AlignText>
				<AlignText>
					<TitleText>Total do pedido</TitleText>
				</AlignText>
				<AlignText>
					<TitleText>Comissão</TitleText>
				</AlignText>
				<AlignText>
					<TitleText>Total líquido</TitleText>
				</AlignText>
			</TableHeader>

			{loading ? (
				<StyledLoadingContainer>
					<CircularProgress color="primary" />
				</StyledLoadingContainer>
			) : !newData?.length ? (
				<NoOrders />
			) : (
				newData?.map((info, index) => (
					<Box key={index}>
						<WrapperHistoryDay key={index}>
							<Title>{dateFormat(info.date)}</Title>
							<MiddleContent>
								<QuantityOrders>{info.qtdOrders} pedidos • </QuantityOrders>
								<TotalSum>Total bruto: R${info.grossValue}</TotalSum>
							</MiddleContent>
						</WrapperHistoryDay>

						{info.orders.map((order, index) => (
							<WrapperStatusOrder key={index}>
								<FirstAlignText>
									<OrderText>{hourFormat(order.createdAt)}</OrderText>
								</FirstAlignText>
								<AlignText>
									<OrderText>{order.orderNumber}</OrderText>
								</AlignText>
								<AlignText>
									<Status>
										{(order.status === 'Cancelado' ||
											order.status === 'Cancelamento Solicitado') && (
											<CancelledIcon />
										)}
										{(order.status === 'Novo Pedido' ||
											order.status === 'Aguardando Pagamento' ||
											order.status === 'Preparando' ||
											order.status === 'Pronto' ||
											order.status === 'Pronto para Retirada' ||
											order.status === 'Saiu para Entrega' ||
											order.status === 'Concluído') && <CheckIcon />}
										{(order.status === 'Reembolso Solicitado' ||
											order.status === 'Reembolso Pendente' ||
											order.status === 'Reembolso Cancelado' ||
											order.status === 'Reembolsado') && (
											<WarningIcon style={{ color: '#F4AD21' }} />
										)}

										<StatusText style={{ color: getStatusColor(order.status) }}>
											&nbsp;
											{order.status}
										</StatusText>
									</Status>
								</AlignText>
								<AlignText>
									<OrderText>{order.paymentName}</OrderText>
								</AlignText>
								<AlignText>
									<NumberText
										isCancelled={
											order.status === 'Cancelado' ||
											order.status === 'Cancelamento Solicitado' ||
											order.status === 'Reembolsado'
										}
									>
										R$&nbsp;{addLeadingZero(replaceDot(order.orderTotPrice))}
									</NumberText>
								</AlignText>
								<AlignText>
									<NumberText
										isCancelled={
											order.status === 'Cancelado' ||
											order.status === 'Cancelamento Solicitado' ||
											order.status === 'Reembolsado'
										}
									>
										R$&nbsp;{addLeadingZero(replaceDot(order.transferValue))}
									</NumberText>
								</AlignText>
								<AlignText>
									<BoldStatusText
										isCancelled={
											order.status === 'Cancelado' ||
											order.status === 'Cancelamento Solicitado' ||
											order.status === 'Reembolsado'
										}
									>
										R$&nbsp;{addLeadingZero(replaceDot(order.totNet))}
									</BoldStatusText>
								</AlignText>

								<AlignText onClick={() => handleOrderDetailsById(order.id)}>
									<RightArrowIcon onClick={handleDetailsDrawer} />
								</AlignText>
							</WrapperStatusOrder>
						))}
					</Box>
				))
			)}
			<Formik
				enableReinitialize
				validateOnChange={false}
				initialValues={FILTER_ORDERS_INITIAL_VALUES}
				onSubmit={(values, { setSubmitting }) => {
					handleFilter(values, setSubmitting);
				}}
			>
				<FilterDrawer
					open={isDrawerOpen}
					onClose={handleToggleDrawer}
					initialValues={FILTER_ORDERS_INITIAL_VALUES}
				/>
			</Formik>
		</Container>
	);
};

export default OrderList;
