import { Box, FormControlLabel, FormHelperText, Radio } from '@mui/material';
import { FieldArray, FieldArrayRenderProps, getIn } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import {
	IComplementsGroupProps,
	IOptionsGroupProps,
} from '../../../../../pages/AddItem/Interfaces/interfaces';
import { ComplementaryComponent } from '../../ComplementaryItems/ComplementaryComponent/ComplementaryComponent';
import {
	AddMax,
	AddMin,
	AlignCounters,
	ButtonText,
	ComplementsGroupName,
	CreateButton,
	FormControlStyled,
	IconButton,
	InputCount,
	InputCountName,
	NumberMax,
	NumberMin,
	OptionsComplements,
	RadioGroupStyled,
	RemoveMax,
	RemoveMin,
	Subtitle,
	Title,
	WrapperButton,
	WrapperRadioGroup,
} from './styles';

export interface IDetailsGroupProps {
	index: number;
	name: string;
	minimum: string;
	maximum: string;
	handleChange: {
		(e: React.ChangeEvent<any>): void;
		<T = string | React.ChangeEvent<any>>(
			field: T
		): T extends React.ChangeEvent<any>
			? void
			: (e: string | React.ChangeEvent<any>) => void;
	};
	optionGroups: IComplementsGroupProps[];
	optionsGroup: IComplementsGroupProps;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
	optional: string;
	position: number;
	options: IOptionsGroupProps[];
	optionGroupsArrayHelpers: FieldArrayRenderProps;
	optionGroupEditId?: number;
}

export const DetailsComponent: React.FC<IDetailsGroupProps> = ({
	index,
	minimum,
	maximum,
	handleChange,
	name,
	setFieldValue,
	optionsGroup,
	optionGroups,
	optional,
	position,
	optionGroupsArrayHelpers,
	optionGroupEditId,
}: IDetailsGroupProps): JSX.Element | null => {
	const [isDisable, setIsDisable] = useState(true);
	const [isSelected, setIsSelected] = useState(!optionsGroup.optional ?? false);

	const touchedName = getIn(optionGroupsArrayHelpers.form.touched, name);
	const errorName = getIn(optionGroupsArrayHelpers.form.errors, name);

	const createNewGroup = () => {
		if (optionsGroup.name) {
			optionGroupsArrayHelpers.push({
				name: '',
				description: '',
				minimum: 0,
				maximum: 0,
				available: true,
				optional: true,
				options: [
					{
						name: '',
						description: '',
						available: false,
						optionImage: '',
						optionPrice: 0,
					},
				],
			});
		}
	};

	const handleNewComplement = useCallback(
		(optionArrayHelpers: FieldArrayRenderProps): void => {
			optionArrayHelpers.push({
				name: '',
				description: '',
				available: false,
				optionImage: '',
				optionPrice: 0,
			});
		},
		[optionGroups[index].options]
	);

	const handleDisable = () => {
		if (optionsGroup.optional) {
			setIsDisable(false);

			setFieldValue(minimum, 1);

			setFieldValue(maximum, 1);
		} else {
			setFieldValue(minimum, 0);
			setIsDisable(true);
		}
	};
	const handleIncrementMin = () => {
		if (optionsGroup.minimum < optionsGroup.maximum) {
			setFieldValue(minimum, optionsGroup.minimum + 1);
		} else {
			return;
		}
	};

	const handleDeCrementMin = () => {
		if (!optionsGroup.minimum || (optional && optionsGroup.minimum === 1)) {
			return;
		} else {
			setFieldValue(minimum, optionsGroup.minimum - 1);
		}
	};

	const handleIncrementMax = () => {
		setFieldValue(maximum, optionsGroup.maximum + 1);
	};

	const handleDeCrementMax = () => {
		if (optionsGroup.maximum <= optionsGroup.minimum) {
			handleDeCrementMin();
		}
		if (!optionsGroup.maximum || (optional && optionsGroup.maximum === 1)) {
			return;
		} else {
			setFieldValue(maximum, optionsGroup.maximum - 1);
		}
	};

	useEffect(() => {
		if (optionGroupEditId !== undefined) {
			setIsSelected(!(optionsGroup.optional as unknown as boolean));
			return;
		} else {
			createNewGroup();
		}
	}, []);

	return (
		<Box key={index}>
			{!position ? (
				<>
					<Title>Detalhes do grupo de complementos</Title>
					<Subtitle>Especifique os detalhes deste grupo</Subtitle>
					<ComplementsGroupName
						id="outlined-basic"
						type="text"
						label="Nome do grupo de complementos"
						value={optionsGroup.name}
						name={name}
						inputProps={{ maxLength: 80 }}
						onChange={handleChange}
						error={touchedName && Boolean(errorName)}
						helperText={touchedName && errorName}
					/>
					<Title>Obrigatoriedade</Title>
					<Subtitle>
						Indique se a escolha de complementos é opcional ou obrigatória para
						completar o pedido.
					</Subtitle>

					<OptionsComplements>
						<FormControlStyled>
							<RadioGroupStyled
								aria-labelledby="demo-radio-buttons-group-label"
								name="radio-buttons-group"
								value={optionsGroup.optional}
							>
								<WrapperRadioGroup>
									<Box>
										<FormControlLabel
											value={true}
											disabled={!isSelected}
											control={
												<Radio
													onClick={() => {
														handleDisable();
														setFieldValue(optional, true);
														setIsSelected(false);
													}}
												/>
											}
											label="Opcional"
										/>
										<FormHelperText>
											O cliente pode adicionar ou não complementos a este item
										</FormHelperText>
									</Box>
								</WrapperRadioGroup>
								<WrapperRadioGroup>
									<Box>
										<FormControlLabel
											value={false}
											disabled={isSelected}
											control={
												<Radio
													onClick={() => {
														handleDisable();
														setFieldValue(optional, false);
														setIsSelected(true);
													}}
												/>
											}
											label="Obrigatório"
										/>
										<FormHelperText>
											O cliente deve selecionar 1 ou mais complementos para
											concluir o pedido
										</FormHelperText>
									</Box>
								</WrapperRadioGroup>
							</RadioGroupStyled>
						</FormControlStyled>
					</OptionsComplements>
					<Title>Quantidade</Title>
					<Subtitle>Quantos complementos devem ser selecionados?</Subtitle>
					<AlignCounters>
						<Box>
							<InputCountName>Mínimo</InputCountName>
							<InputCount>
								<IconButton onClick={handleDeCrementMin} disabled={isDisable}>
									<RemoveMin someMin={optionsGroup.minimum} />
								</IconButton>
								<NumberMin someMin={optionsGroup.minimum}>
									{optionsGroup.minimum}
								</NumberMin>
								<IconButton onClick={handleIncrementMin} disabled={isDisable}>
									<AddMin someMin={optionsGroup.minimum} />
								</IconButton>
							</InputCount>
						</Box>
						<Box>
							<InputCountName>Máximo</InputCountName>
							<InputCount>
								<IconButton onClick={handleDeCrementMax}>
									<RemoveMax />
								</IconButton>
								<NumberMax>{optionsGroup.maximum}</NumberMax>
								<IconButton onClick={handleIncrementMax}>
									<AddMax />
								</IconButton>
							</InputCount>
						</Box>
					</AlignCounters>
				</>
			) : (
				<>
					<Title>Itens Complementares</Title>
					<Subtitle>Defina os itens e valores dos complementos</Subtitle>

					<FieldArray name={`optionGroups[${index}].options`}>
						{(optionArrayHelpers) => (
							<React.Fragment>
								{optionGroups[index].options?.map((options, idx) => {
									const name = `optionGroups[${index}].options[${idx}].name`;
									const available = `optionGroups[${index}].options[${idx}].available`;
									const description = `optionGroups[${index}].options[${idx}].description`;
									const optionImage = `optionGroups[${index}].options[${idx}].optionImage`;
									const optionPrice = `optionGroups[${index}].options[${idx}].optionPrice`;

									return (
										<ComplementaryComponent
											key={idx}
											index={index}
											name={name}
											option={options}
											handleChange={handleChange}
											setFieldValue={setFieldValue}
											available={available}
											description={description}
											optionImage={optionImage}
											optionPrice={optionPrice}
											optionArrayHelpers={optionArrayHelpers}
										/>
									);
								})}
								<WrapperButton>
									<CreateButton
										onClick={() => handleNewComplement(optionArrayHelpers)}
									>
										<ButtonText>+ Adicionar item complementar</ButtonText>
									</CreateButton>
								</WrapperButton>
							</React.Fragment>
						)}
					</FieldArray>
				</>
			)}
		</Box>
	);
};

export default DetailsComponent;
