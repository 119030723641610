import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	RadioGroup,
	Typography,
	styled,
} from '@mui/material';

export const TitleAndButton = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: '0px 18px 30px 0px',
	marginTop: '20px',
}));

export const ScreeenTitles = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontFamily: 'Rubik',
	fontWeight: 500,
	marginTop: '2%',
	color: theme.palette.common.black,
}));

export const ScreenSubTitle = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	fontFamily: 'Rubik',
	fontWeight: 400,
	color: theme.palette.common.black,
}));

export const CopyButton = styled(Button)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: 250,
	height: 52,
	borderRadius: 8,
	border: `1px solid ${theme.palette.neutral.dark2}`,
}));

export const ButtonText = styled(Typography)(({ theme }) => ({
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 700,
	marginLeft: '10px',
	color: theme.palette.primary.main,
	textTransform: 'initial',
}));

export const OptionsComplements = styled(Box)(() => ({
	display: 'flex',

	'& .css-ahj2mt-MuiTypography-root': {
		fontSize: '14px',
		fontFamily: 'Rubik',
		fontWeight: 400,
	},
}));

export const FormStyled = styled(FormControl)(() => ({
	width: '100%',
}));

export const RadioGroupStyled = styled(RadioGroup)(() => ({
	width: '100%',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
}));

export const FormControlLabelStyled = styled(FormControlLabel)(({ theme }) => ({
	width: '45%',
	height: 80,
	display: 'flex',
	border: `1px solid ${theme.palette.neutral.dark2}`,
	borderRadius: 8,
	marginLeft: 10,
	paddingLeft: 10,
}));
