import {
	StyledCouponsStatusDisplay,
	StyledCouponsChartLineContainer,
	StyledOrderFooterText,
	StyledCouponsNumber,
	StyledCouponsText,
} from './styles';
import { Skeleton, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { OrderStatusCardProps } from '../interfaces/interfaces';

const OrderStatusCard: React.FC<OrderStatusCardProps> = ({
	value,
	text,
	tooltip,
	footerText,
	money,
	descending,
	handleFoooterIcon,
}: OrderStatusCardProps): JSX.Element => {
	return (
		<StyledCouponsStatusDisplay>
			<StyledCouponsChartLineContainer>
				<StyledCouponsText>{text}</StyledCouponsText>
				<Tooltip title={tooltip} arrow placement="top-end">
					<HelpOutlineIcon
						color="disabled"
						fontSize="inherit"
						style={{ height: 27, width: 27 }}
					/>
				</Tooltip>
			</StyledCouponsChartLineContainer>

			{value >= 0 && (
				<StyledCouponsNumber>
					{money ? `R$ ${value ?? 0}` : value}
				</StyledCouponsNumber>
			)}

			<StyledCouponsChartLineContainer>
				<StyledOrderFooterText descending={descending}>
					{footerText}
				</StyledOrderFooterText>

				{handleFoooterIcon}
				{value === undefined && (
					<StyledCouponsNumber>
						<Skeleton
							sx={{
								height: 36,
								width: 80,
								borderRadius: 2,
							}}
							animation="wave"
							variant="rectangular"
						/>
					</StyledCouponsNumber>
				)}
			</StyledCouponsChartLineContainer>
		</StyledCouponsStatusDisplay>
	);
};

export default OrderStatusCard;
