import { ReactComponent as CheckIcon } from "../../../assets/images/CheckIcon.svg";
import { PlansProps } from "../constants";
import { IAllPlansComponentProps } from "../interfaces";
import {
  AlignIconPlan,
  AlignNameAndIcon,
  ChoosePlanButton,
  ChoosePlanButtonText,
  Container,
  ContentTitle,
  FirstBenefit,
  IconPlanSVG,
  NewValue,
  OldValue,
  PlanCardContent,
  PlanContent,
  PlansCardSelectedBackground,
  PlanTypeAndValue,
  TextValue,
  Title,
  TitleAndOldValue,
  Type,
  TypeAndNewValue,
  Value,
} from "./styles";

export const ScreenAllPlans: React.FC<IAllPlansComponentProps> = ({
  handleChossePlanButtonText,
  setIndexPlan,
  indexPlan,
  navigateToSignPlan,
}: IAllPlansComponentProps): JSX.Element => (
  <Container>
    {PlansProps.map((item, index) => (
      <PlanContent key={item.id}>
        <PlanTypeAndValue>
          <AlignIconPlan>
            <IconPlanSVG isSelected={index === indexPlan}>
              {item.iconPlan}
            </IconPlanSVG>
          </AlignIconPlan>
          <TitleAndOldValue>
            <Title>Plano</Title>
            <OldValue>R$ {item.oldPrice}</OldValue>
          </TitleAndOldValue>
          <TypeAndNewValue>
            <Type>{item.typePlan}</Type>
            <NewValue>
              <Value>R$ {item.newPrice}</Value>
              <TextValue>/mes</TextValue>
            </NewValue>
          </TypeAndNewValue>
          <PlanCardContent>
            <ContentTitle>Benefícios</ContentTitle>
            {item.benefits.map((info) => (
              <AlignNameAndIcon key={info.id}>
                <CheckIcon />
                <FirstBenefit>{info.benefitText}</FirstBenefit>
              </AlignNameAndIcon>
            ))}
          </PlanCardContent>
          <ChoosePlanButton
            onClick={() => {
              setIndexPlan(index);
              navigateToSignPlan();
            }}
            indexPlan={indexPlan}
            index={index}
          >
            <ChoosePlanButtonText>
              {handleChossePlanButtonText(index)}
            </ChoosePlanButtonText>
          </ChoosePlanButton>
        </PlanTypeAndValue>
        <PlansCardSelectedBackground
          width="359"
          height="579"
          viewBox="0 0 359 579"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          indexPlan={indexPlan}
          index={index}
        >
          <path
            d="M357.949 537.17C357.979 442.518 358.04 200.209 357.961 41.9322C357.949 19.2991 339.596 1 316.966 1H277.675C271.512 1 265.489 2.83709 260.375 6.27663L231.139 25.9396C226.355 29.1572 220.72 30.8758 214.955 30.8758H141.825C136.06 30.8758 130.425 29.1572 125.641 25.9396L96.4048 6.27663C91.2907 2.83709 85.2674 1 79.1043 1H42C19.3563 1 1 19.3563 1 42V537C1 559.642 19.3052 578 41.9505 578H316.996C339.63 578 357.942 559.812 357.949 537.17Z"
            fill="current"
            stroke="current"
            strokeWidth="2"
          />
        </PlansCardSelectedBackground>
      </PlanContent>
    ))}
  </Container>
);

export default ScreenAllPlans;
