import { Box, styled, Typography } from '@mui/material';

export const StyledFormContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	rowGap: 24,
	width: '70.2%',
	maxHeight: 560.141,
	maxWidth: 620,
	position: 'relative',
	backgroundColor: theme.palette.neutral.light,
	borderRadius: 40,
	padding: '19px 63px 61px 64px',
	[theme.breakpoints.down('md')]: {
		position: 'none',
		maxWidth: '75%',
		margin: 'auto',
		padding: '20px 55px 20px 55px',
	},
}));

export const StyledBackArrow = styled(Box)(() => ({
	cursor: 'pointer',
	width: 32,
	height: 32,
}));

export const StyledFormText = styled(Box)(({ theme }) => ({
	fontSize: 24,
	lineHeight: '28px',
	color: theme.palette.primary.main,
	fontFamily: 'Rubik',
	fontWeight: 500,
}));
export const StyledFormSubText = styled(Box)(({ theme }) => ({
	fontSize: 18,
	lineHeight: '24px',
	color: theme.palette.neutral.dark1,
	fontFamily: 'Rubik',
	fontWeight: 500,
}));

export const StyledFieldsTitle = styled(Typography)(() => ({
	fontSize: 18,
	fontFamily: 'Rubik',
	fontWeight: 500,
}));

export const StyledSendButtonContainer = styled(Box)(() => ({
	display: 'flex',
	width: '70%',
	justifyContent: 'center',
	alignItems: 'center',
	margin: 'auto',
}));
export const StyledTypography = styled(Typography)(({ theme }) => ({
	textTransform: 'none',
	fontSize: 18,
	width: 264,
	color: theme.palette.neutral.light,
	fontFamily: 'Rubik',
	fontWeight: 700,
}));

export const FormFooter = styled(Box)(() => ({
	display: 'block',
	justifyContent: 'center',
	alignItems: 'center',
	flexGrow: 1,
	maxHeight: 50,
}));
