import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
	Box,
	IconButton,
	InputAdornment,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	tableCellClasses,
} from '@mui/material';
import { green } from '@mui/material/colors';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { pt } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import { ReactComponent as CalendarIcon } from '../../assets/images/calendarIcon.svg';
import DashboardTopBar from '../../components/DashboardTopBar/DashboardTopBar';
import { CARD_INFO } from './constants/card-values.constants';
import { ICardDataInfo, ICardInfo } from './interface';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MonthlyBillingCard from '../../components/StoreFinance/MonthlyBillingCard/MonthlyBillingCard';
import { getMonthlyBilling } from '../../utils/api/get/getMonthlyBilling';
import ArrowDownIcon from './CalendarIcon';
import {
	StyledContainer,
	StyledDatePicker,
	StyledDetails,
	StyledMonthTextField,
	StyledStoreFinance,
	StyledStoreFinanceContainer,
	StyledStoreFinanceContent,
	StyledStoreFinanceContentContainer,
	StyledStoreFinanceTopBar,
	StyledSubtitle,
	StyledTitle,
	StyledTitleContainer,
} from './styles';

const transactions = [
	{
		period: '01/01/24 a 31/01/2024',
		date: '01/02/2024',
		description: 'Banco do Brasil',
		status: 'Em aberto',
		amount: 'R$ 352,00',
	},
	{
		period: '01/12/23 a 31/01/2023',
		date: '01/01/2024',
		description: 'Banco do Brasil',
		status: 'Concluído',
		amount: 'R$ 368,00',
	},
];

const StoreFinance: React.FC = (): JSX.Element => {
	const [month, setMonth] = useState<Date>(new Date());
	const [openMonthCalendar, setOpenMonthCalendar] = useState(false);
	const [cardData, setCardData] = useState<ICardDataInfo>();

	const getMonthlyBillingData = (): void => {
		const currentMonth = month.getMonth() + 1;
		const currentYear = month.getFullYear();
		getMonthlyBilling(currentMonth, currentYear).then((res) => {
			setCardData(res?.data);
		});
	};

	const updateCardInfoValues = (): void => {
		if (cardData) {
			CARD_INFO.forEach((card) => {
				switch (card.title) {
					case 'Valor Bruto':
						card.value = cardData.grossValue;
						card.percent = cardData.grossValuePercent;
						break;
					case 'Recebido pela loja':
						card.value = cardData.receiverValueByAdvertiser;
						card.percent = cardData.receiverValueByAdvertiserPercent;
						break;
					case 'Recebido pelo dsconto':
						card.value = cardData.receiverValueByDsconto;
						card.percent = cardData.receiverValueByDscontoPercent;
						break;
					case 'Taxas e outros':
						card.value = cardData.taxesValue;
						break;
					case 'Valor líquido':
						card.value = cardData.netValue;
						card.percent = cardData.netValuePercent;
						break;
				}
			});
		}
	};

	useEffect(() => {
		updateCardInfoValues();
		getMonthlyBillingData();
	}, []);

	useEffect(() => {
		getMonthlyBillingData();
	}, [month]);
	useEffect(() => {
		updateCardInfoValues();
	}, [cardData]);

	return (
		<StyledStoreFinance>
			<StyledStoreFinanceContainer>
				<StyledStoreFinanceTopBar>
					<DashboardTopBar
						title={'Financeiro'}
						text={'Pagamentos e repasses'}
					/>
				</StyledStoreFinanceTopBar>
				<StyledStoreFinanceContentContainer>
					<StyledStoreFinanceContent>
						<StyledContainer>
							<Box display={'flex'} justifyContent={'space-between'}>
								<StyledTitleContainer>
									<StyledTitle>Faturamento mensal</StyledTitle>
									<StyledSubtitle>
										Esses são os valores referente ao mês selecionado
									</StyledSubtitle>
								</StyledTitleContainer>
								<Box display={'flex'}>
									<LocalizationProvider
										dateAdapter={AdapterDateFns}
										adapterLocale={pt}
									>
										<StyledDatePicker
											open={openMonthCalendar}
											onOpen={() => setOpenMonthCalendar(true)}
											onClose={() => setOpenMonthCalendar(false)}
											views={['month', 'year']}
											minDate={new Date('2023-02-02')}
											maxDate={new Date()}
											value={month}
											showToolbar={false}
											onChange={(newValue) => setMonth(newValue as Date)}
											aria-readonly
											InputProps={{
												startAdornment: (
													<InputAdornment position="start">
														<CalendarIcon color="#6029B1" />
													</InputAdornment>
												),
												disableUnderline: true,
											}}
											renderInput={(params) => (
												<StyledMonthTextField
													{...params}
													onClick={() => setOpenMonthCalendar(true)}
													InputProps={{
														...params.InputProps,
														...{
															readOnly: true,
															disableUnderline: true,
															onKeyDown: (event) => event.preventDefault(),
															inputMode: 'none',
														},
													}}
													helperText={null}
													variant="standard"
												/>
											)}
											components={{
												OpenPickerIcon: ArrowDownIcon,
											}}
											inputFormat="MMMM/yyyy"
										/>
									</LocalizationProvider>
									<StyledDetails>{'Ver faturamento >'}</StyledDetails>
								</Box>
							</Box>
							<Box display={'flex'} justifyContent={'space-between'}>
								{CARD_INFO.map((card: ICardInfo, index) => (
									<MonthlyBillingCard card={card} key={index} />
								))}
							</Box>
						</StyledContainer>
						<StyledContainer>
							<Box display={'flex'} justifyContent={'space-between'}>
								<StyledTitleContainer>
									<StyledTitle>Histórico de repasses</StyledTitle>
									<StyledSubtitle>
										Feitos a cada 30 dias no primeiro dia do mês.
									</StyledSubtitle>
								</StyledTitleContainer>
								<StyledDetails>{'Ver todos >'}</StyledDetails>
							</Box>
							<TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
								<Table
									aria-label="simple table"
									sx={{
										[`& .${tableCellClasses.root}`]: {
											borderBottom: 'none',
										},
									}}
								>
									<TableHead>
										<TableRow>
											<TableCell>Intervalo de apuração</TableCell>
											<TableCell align="center">
												Previsão de pagamento
											</TableCell>
											<TableCell align="center">Dados bancários</TableCell>
											<TableCell align="center">Status</TableCell>
											<TableCell align="center">Valor do repasse</TableCell>
											<TableCell align="right" width={'20%'}></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{transactions.map((row, index) => (
											<TableRow key={index}>
												<TableCell component="th" scope="row">
													{row.period}
												</TableCell>
												<TableCell align="center">{row.date}</TableCell>
												<TableCell align="center">{row.description}</TableCell>
												<TableCell align="center">
													<Box
														display="flex"
														justifyContent="center"
														color={
															row.status === 'Concluído'
																? green[500]
																: 'inherit'
														}
													>
														{row.status === 'Concluído' && (
															<CheckCircleOutlineIcon
																style={{ color: green[500], marginRight: 8 }}
															/>
														)}
														{row.status}
													</Box>
												</TableCell>
												<TableCell align="center">{row.amount}</TableCell>
												<TableCell align="right">
													<IconButton
														edge="end"
														size="small"
														onClick={() => console.log(row)}
													>
														<ArrowForwardIosIcon fontSize="inherit" />
													</IconButton>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</StyledContainer>
					</StyledStoreFinanceContent>
				</StyledStoreFinanceContentContainer>
			</StyledStoreFinanceContainer>
		</StyledStoreFinance>
	);
};

export default StoreFinance;
