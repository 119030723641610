import { Box, styled } from '@mui/material';
import { ReactComponent as CouponsChartLine } from '../../assets/images/couponChartLine.svg';
import { ReactComponent as CouponsChartRedLine } from '../../assets/images/couponsChartRedLine.svg';
import { IDrawerProps } from './interfaces';

export const Container = styled(Box)<IDrawerProps>(({ openModal }) => ({
	overflow: openModal ? 'hidden' : 'auto',
	width: '100%',
	height: '100%',
}));
export const StyledDashboardTopBar = styled(Box)(() => ({
	display: 'flex',
	width: '100%',
}));

export const CouponsChartSVG = styled(CouponsChartLine)(() => ({
	'@media only screen and (max-width: 1500px)': {
		width: 70,
	},
}));

export const CouponsChartRedSVG = styled(CouponsChartRedLine)(() => ({
	'@media only screen and (max-width: 1500px)': {
		width: 70,
	},
}));

export const ContentStyled = styled(Box)(({ theme }) => ({
	maxWidth: '100%',
	minHeight: '800px',
	padding: '40px',
	backgroundColor: theme.palette.neutral.light2,
}));

export const AlignHeader = styled(Box)(() => ({
	display: 'flex',
	width: '68%',
	height: '150px',
	justifyContent: 'space-between',
	flexDirection: 'row',
	paddingBottom: '40px',
}));
