import { Box, styled, Typography } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  width: "1367px",
  height: "210px",
  padding: "0px 28px 0px 28px",
  marginLeft: "55px",
  borderRadius: "8px",
  border: `1px solid ${theme.palette.neutral.light2}`,
}));

export const AlignIconAndName = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const AlignPlanValue = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginTop: "20px",
}));

export const InfoContent = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "25px",
}));

export const InfoText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 400,
  color: theme.palette.neutral.dark1,
  marginTop: "12px",
}));

export const InfoTitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.common.black,
}));

export const InitialContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "20px",
}));

export const NameAndSubtitle = styled(Box)(() => ({
  marginLeft: "16px",
}));

export const PlanName = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.common.black,
}));

export const PlanValue = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.primary.main,
}));

export const PlanValueText = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.neutral.dark1,
}));

export const Renovation = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "194px",
  height: "36px",
  marginTop: "50px",
  borderRadius: "30px",
  backgroundColor: theme.palette.neutral.light3,
}));

export const RenovationText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.primary.main,
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 400,
  color: theme.palette.neutral.dark1,
}));
