import React from 'react';
import { useNavigate } from 'react-router-dom';
import NavButton from '../../Buttons/NavButton/NavButton';
import {
	CenterBox,
	CenterBoxText,
	CenterBoxTitle,
	IntroductionContainer,
	StyledButtonBox,
} from './styles';

const Introduction: React.FC = (): JSX.Element => {
	const navigate = useNavigate();

	return (
		<IntroductionContainer>
			<CenterBox>
				<CenterBoxTitle>Transforme sua</CenterBoxTitle>
				<CenterBoxTitle>loja vendendo na</CenterBoxTitle>
				<CenterBoxTitle>nossa plataforma!</CenterBoxTitle>
				<CenterBoxText>
					Desfrute das melhores condições do mercado para elevar o nível dos
					seus faturamentos!
				</CenterBoxText>
				<StyledButtonBox onClick={() => navigate('/sign-up')}>
					<NavButton>Cadastrar loja</NavButton>
				</StyledButtonBox>
			</CenterBox>
		</IntroductionContainer>
	);
};

export default Introduction;
