import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import { ReactScrollLinkProps } from 'react-scroll/modules/components/Link';
import companySocials from '../../../shared/constants/companySocials';
import downloadApp from '../../../shared/constants/downloadApp';
import { TOPBAR_SCROLL_CONFIG } from '../TopBar/constants/topbar-scroll.constant';
import {
	SixthGridFirstColumn,
	SixthGridFourthColumn,
	SixthGridSecondColumn,
	SixthGridThirdColumn,
	StyledContainer,
	StyledFacebookIcon,
	StyledInstagramIcon,
	StyledSixthGridText,
	StyledSixthGridTitle,
	StyledSocialsContainer,
	StyledSocialsIcons,
	StyledWhatsappIcon,
} from './styles';

const BottomMenu: React.FC = (): JSX.Element => {
	const navigate = useNavigate();
	const { spy, smooth, offset, duration }: ReactScrollLinkProps =
		TOPBAR_SCROLL_CONFIG;

	return (
		<StyledContainer>
			<SixthGridFirstColumn>
				<StyledSixthGridTitle>Loja parceira</StyledSixthGridTitle>
				<StyledSixthGridText onClick={() => navigate('/login')}>
					Login
				</StyledSixthGridText>
				<StyledSixthGridText onClick={() => navigate('/sign-up')}>
					Cadastrar Loja
				</StyledSixthGridText>
				<StyledSixthGridText>Suporte</StyledSixthGridText>
			</SixthGridFirstColumn>
			<SixthGridSecondColumn>
				<StyledSixthGridTitle>Aplicativo</StyledSixthGridTitle>
				<StyledSixthGridText onClick={() => window.open(downloadApp.apple)}>
					Baixar no App Store
				</StyledSixthGridText>
				<StyledSixthGridText onClick={() => window.open(downloadApp.google)}>
					Baixar no Google Play
				</StyledSixthGridText>
			</SixthGridSecondColumn>
			<SixthGridThirdColumn>
				<StyledSixthGridTitle>Sobre nós</StyledSixthGridTitle>
				<StyledSixthGridText>
					<Link
						to="howItWorks"
						spy={spy}
						smooth={smooth}
						offset={offset}
						duration={duration}
					>
						Como Funciona
					</Link>
				</StyledSixthGridText>
				<StyledSixthGridText>
					<Link
						to="commomQuestions"
						spy={spy}
						smooth={smooth}
						offset={offset}
						duration={duration}
					>
						Perguntas Frequentes
					</Link>
				</StyledSixthGridText>
				<StyledSixthGridText>Termos de uso</StyledSixthGridText>
			</SixthGridThirdColumn>
			<SixthGridFourthColumn>
				<StyledSixthGridTitle>Sociais</StyledSixthGridTitle>
				<StyledSocialsContainer>
					<StyledSocialsIcons
						onClick={() => window.open(companySocials.facebook)}
					>
						<StyledFacebookIcon />
					</StyledSocialsIcons>
					<StyledSocialsIcons
						onClick={() => window.open(companySocials.instagram)}
					>
						<StyledInstagramIcon />
					</StyledSocialsIcons>
					<StyledSocialsIcons
						onClick={() => window.open(companySocials.whatsapp)}
					>
						<StyledWhatsappIcon />
					</StyledSocialsIcons>
				</StyledSocialsContainer>
			</SixthGridFourthColumn>
		</StyledContainer>
	);
};

export default BottomMenu;
