import { Box, Button, styled, Typography } from "@mui/material";
import { ReactComponent as MC_symbol } from "../../../assets/images/MC_symbol.svg";
import { IInputProps } from "../interfaces";

export const Container = styled(Box)(() => ({
  width: "60%",
  height: "100%",
  paddingTop: "44px",
  paddingLeft: "44px",
}));

export const Title = styled(Box)(() => ({}));

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.common.black,
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  marginTop: "44px",
  fontWeight: 500,
  color: theme.palette.common.black,
}));

export const FirstInputAddress = styled(Box)<IInputProps>(
  ({ theme, editingAddress }) => ({
    justifyContent: "space-between",
    width: "814px",
    height: editingAddress === true ? "336px" : "55px",
    paddingLeft: "24px",
    marginTop: "20px",
    borderRadius: "8px",
    border: `1px solid ${theme.palette.neutral.light2}`,
  })
);

export const FirstInputCard = styled(Box)<IInputProps>(
  ({ theme, editingCard }) => ({
    justifyContent: "space-between",
    width: "814px",
    height: editingCard ? "336px" : "55px",
    paddingLeft: "24px",
    marginTop: "20px",
    borderRadius: "8px",
    border: `1px solid ${theme.palette.neutral.light2}`,
  })
);

export const InitialContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginTop: "5px",
}));

export const LabelInput = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 500,
  color: theme.palette.common.black,
}));

export const InputContent = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontFamily: "Rubik",
  fontWeight: 400,
  marginLeft: "40px",
  color: theme.palette.neutral.dark1,
}));

export const EditButton = styled(Button)(() => ({
  marginRight: "15px",
}));

export const ButtonText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 400,
  color: theme.palette.primary.main,
  textDecoration: "underline",
  textTransform: "initial",
}));

export const SecondInputAddress = styled(Box)<IInputProps>(
  ({ theme, valueAddress }) => ({
    width: "814px",
    height: valueAddress === "addAddress" ? "336px" : "55px",
    paddingLeft: "24px",
    marginTop: "16px",
    borderRadius: "8px",
    border: `1px solid ${theme.palette.neutral.light2}`,
  })
);

export const SecondInputCard = styled(Box)<IInputProps>(
  ({ theme, valueCard }) => ({
    width: "814px",
    height: valueCard === "addCard" ? "255px" : "55px",
    paddingLeft: "24px",
    marginTop: "16px",
    borderRadius: "8px",
    border: `1px solid ${theme.palette.neutral.light2}`,
  })
);

export const ContentAndButtonAlignment = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const AlignLabelAndRadio = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginTop: "6px",
}));

export const SaveButton = styled(Button)(() => ({
  marginRight: "15px",
}));

export const CreditCardIcon = styled(MC_symbol)(() => ({
  marginRight: "16px",
}));
