import { AxiosResponse } from 'axios';
import { BASE_URL } from '../api';
import { request } from '../base-request/base-request';

export const getItemCategory = async (): Promise<
  AxiosResponse<any> | undefined
> => {
  try {
    return request({
      url: `${BASE_URL}/showcaseCategories`,
      method: 'get',
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
