import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import * as React from 'react';
import { ReactComponent as CanceledIcon } from '../../../assets/images/canceledIcon.svg';
import { ReactComponent as OrderDelivered } from '../../../assets/images/orderDelivered.svg';
import { ReactComponent as DeliveredIcon } from '../../../assets/images/orderDeliveredIcon.svg';
import { ReactComponent as OrderDetailsIcon } from '../../../assets/images/orderDetailsIcon.svg';
import { ReactComponent as TruckIcon } from '../../../assets/images/truckIcon.svg';
import { ReactComponent as WarningIcon } from '../../../assets/images/warningIcon.svg';
import { dateFullFormatWithDot, hourFormat } from '../../../utils/mask.util';
import DetailsTable from '../DetailsTable/DetailsTable';
import StatusDetailsCard from '../StatusDatailsCard/StatusDetailsCard';
import { IModalDetailsProps } from '../interfaces/interfaces';
import OrderSkeleton from './OrderSkeleton/OrderSkeleton';
import {
	AlignCards,
	AlignDateAndStatus,
	ApprovedIcon,
	BoxContent,
	ButtonClose,
	CanceledText,
	CanceledValue,
	CloseBox,
	Container,
	ContentAboutPayment,
	ErrorButton,
	HeaderDetail,
	IconClose,
	NegativeSubText,
	NegativeTitleText,
	NegativeValue,
	OderText,
	OrderAndNumber,
	PositiveText,
	PositiveValue,
	Status,
	StatusText,
	SubText,
	SubTextBold,
	SubTotalPrice,
	SubTotalPriceBold,
	TitleAndPrice,
	TitleText,
	TotalItems,
	TotalPrice,
	TotalSubText,
	TotalText,
	WarningText,
	WrapperButton,
	WrapperTotalItems,
} from './styles';

const OrderDetails: React.FC<IModalDetailsProps> = ({
	orderDetails: {
		order: {
			order_number,
			status,
			is_delivery,
			created_at,
			itemOrder,
			orderStatusHistory,
			advertiserWallet,
			canceledBy,
		},
		deliveryPrice,
		totItemPrice,
	},
	handleDetailsDrawer,
	loadDetails,
	handleOpenOrderRefundModal,
}: IModalDetailsProps): JSX.Element => {
	const {
		confirmed_time,
		dispatched_or_pickup_time,
		placed_time,
		canceled_time,
	} = orderStatusHistory;

	const handleAboutPayment = () => {
		if (status === 'Cancelado') {
			return (
				<CanceledValue>
					<CanceledText>Esse pedido foi cancelado</CanceledText>
				</CanceledValue>
			);
		} else if (Number(advertiserWallet.transfer_value) < 0) {
			return (
				<NegativeValue>
					<NegativeTitleText>Por que o valor está negativo?</NegativeTitleText>
					<NegativeSubText>
						Você já recebeu o valor desse pedido. O valor negativo representa a
						comissão do d$conto e será descontado no seu repasse.
					</NegativeSubText>
				</NegativeValue>
			);
		} else {
			return (
				<PositiveValue>
					<PositiveText>Você receberá o total do valor líquido</PositiveText>
				</PositiveValue>
			);
		}
	};

	return loadDetails ? (
		<OrderSkeleton />
	) : (
		<Container>
			<HeaderDetail>
				<CloseBox>
					<ButtonClose onClick={handleDetailsDrawer}>
						<IconClose />
					</ButtonClose>
				</CloseBox>
				<OrderAndNumber>
					<OderText>Pedido {order_number}</OderText>
				</OrderAndNumber>
				<AlignDateAndStatus>
					<SubText>Feito em {dateFullFormatWithDot(created_at)}</SubText>
					<Status>
						{status === 'Cancelado' ? <CanceledIcon /> : <ApprovedIcon />}

						<StatusText status={status}>{status}</StatusText>
					</Status>
				</AlignDateAndStatus>
			</HeaderDetail>
			<Divider />
			<BoxContent>
				<AlignCards>
					{placed_time && (
						<StatusDetailsCard
							icon={<OrderDetailsIcon />}
							title="Recebido"
							subtitle="Pedido recebido"
							time={hourFormat(placed_time)}
						/>
					)}

					{confirmed_time && (
						<StatusDetailsCard
							icon={<OrderDelivered />}
							title="Confirmado"
							subtitle={'Produto confirmado'}
							time={hourFormat(confirmed_time)}
						/>
					)}

					{status === 'Cancelado' && (
						<StatusDetailsCard
							icon={<CanceledIcon />}
							title="Cancelado"
							subtitle={
								canceledBy === 'USER'
									? 'Pedido cancelado pelo usuário'
									: canceledBy === 'STORE'
									? 'Pedido cancelado pela loja'
									: 'Pedido cancelado'
							}
							time={hourFormat(canceled_time)}
						/>
					)}
					{status === 'Reembolso Pendente' && (
						<StatusDetailsCard
							icon={<WarningIcon color="#F4AD21" />}
							title="Reembolso pendente"
							subtitle="Saiba mais"
							handleOpenOrderRefundModal={handleOpenOrderRefundModal}
						/>
					)}

					{status === 'Reembolso Cancelado' && (
						<StatusDetailsCard
							icon={<CanceledIcon />}
							title="Reembolso cancelado"
							subtitle="Saiba mais"
							handleOpenOrderRefundModal={handleOpenOrderRefundModal}
						/>
					)}

					{status === 'Reembolsado' && (
						<StatusDetailsCard
							icon={<OrderDelivered />}
							title="Reembolso concluído"
							subtitle="Saiba mais"
							handleOpenOrderRefundModal={handleOpenOrderRefundModal}
						/>
					)}

					{dispatched_or_pickup_time &&
						status !== 'Reembolsado' &&
						status !== 'Reembolso Cancelado' &&
						status !== 'Reembolso Pendente' && (
							<StatusDetailsCard
								icon={is_delivery ? <TruckIcon /> : <DeliveredIcon />}
								title={is_delivery ? 'Despachado' : 'Retirado'}
								subtitle={is_delivery ? 'Pedido despachado' : 'Pedido retirado'}
								time={hourFormat(dispatched_or_pickup_time)}
							/>
						)}
				</AlignCards>
				<TitleText>O pedido foi assim:</TitleText>
				<DetailsTable itemOrder={itemOrder} />
				<WrapperTotalItems>
					<Box>
						<TotalText>Total dos itens</TotalText>
						<TotalSubText>
							{itemOrder.length}{' '}
							{itemOrder.length < 1 ? 'itens incluidos' : 'item incluido'}
						</TotalSubText>
					</Box>
					<TotalPrice>R${totItemPrice}</TotalPrice>
				</WrapperTotalItems>
			</BoxContent>

			<Box>
				<TitleText>Valor bruto</TitleText>
				<TitleAndPrice>
					<SubText>Total dos itens</SubText>
					<SubTotalPrice>R${totItemPrice}</SubTotalPrice>
				</TitleAndPrice>
			</Box>
			<Divider />
			<Box>
				<TitleAndPrice>
					<SubText>Taxa de entrega</SubText>
					<SubTotalPrice>R${deliveryPrice}</SubTotalPrice>
				</TitleAndPrice>
			</Box>
			<TotalItems>
				<TotalText>Total do valor bruto</TotalText>
				<TotalPrice>R${totItemPrice + deliveryPrice}</TotalPrice>
			</TotalItems>

			<Box>
				<TitleText>Valor líquido</TitleText>
				<TitleAndPrice>
					<SubText>Valor bruto total da venda</SubText>
					<SubTotalPrice>R${totItemPrice + deliveryPrice}</SubTotalPrice>
				</TitleAndPrice>
			</Box>
			<Divider />
			<Box>
				<TitleAndPrice>
					<SubText>Recebido pelo d$conto</SubText>
					<SubTotalPrice>R${totItemPrice + deliveryPrice}</SubTotalPrice>
				</TitleAndPrice>
			</Box>
			<Divider />
			<Box>
				<TitleAndPrice>
					<SubTextBold>Comissão do d$conto</SubTextBold>
					<SubTotalPriceBold>
						R${advertiserWallet.transfer_value}
					</SubTotalPriceBold>
				</TitleAndPrice>
			</Box>
			<TotalItems>
				<TotalText>Total do valor líquido </TotalText>
				<TotalPrice>R${advertiserWallet.net_value}</TotalPrice>
			</TotalItems>
			<ContentAboutPayment>{handleAboutPayment()}</ContentAboutPayment>

			<WrapperButton>
				<ErrorButton>
					<WarningIcon color="#747476" />
					<WarningText>Algo de errado por aqui?</WarningText>
				</ErrorButton>
			</WrapperButton>
		</Container>
	);
};

export default OrderDetails;
